import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fundDetailsService } from "services/funddetails";
import FundDetails from "../funddetails";
import Overview from "../funddetailsoverview";
import Peers from "../peers";
import Portfolio from "../portfolio";
import styles from "./styles";

export const CssTabs = withStyles(theme => ({
  root: {
    minHeight: "30px"
  },
  indicator: {
    backgroundColor: "#8DB1D2"
  },
}))(Tabs)

const tabs = ["OVERVIEW", "PORTFOLIO", "PEERS"]

export function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.box}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function getFundTopDetails(data, schemeCode, dialogData) {
  const { schemeName, rating, nav, navChange, navDate, navChangePercentage, isinNumber } = data
  let obj = {
    schemeName,
    ratings: rating,
    navValue: nav,
    navChange,
    navDate,
    navPercentChange: navChangePercentage,
    isinNumber,
    schemeCode
  }
  if (dialogData && dialogData.product) {
    obj["product"] = dialogData.product || ""
  }
  if (dialogData && dialogData.goalId) {
    obj["goalId"] = dialogData.goalId || ""
  }
  if (dialogData && dialogData.goalName) {
    obj["goalName"] = dialogData.goalName || ""
  }
  if (dialogData && dialogData.isSipAllowed) {
    obj["isSipAllowed"] = dialogData.isSipAllowed || ""
  }
  if (dialogData && dialogData.purchaseAllowed) {
    obj["purchaseAllowed"] = dialogData.purchaseAllowed || ""
  }
  return obj
}

function FundDetailsPopup(props) {
  const { classes, fundDetails, selectedScheme, dialogData, propData } = props

  const [currentData, setCurrentData] = useState(propData || dialogData);
  const [value, setValue] = useState(0)
  const [detailsData, setDetailsData] = useState("Loading...")

  useEffect(() => {
    if (propData)
      setCurrentData(propData);
  }, [propData]);

  async function getDetails(isinNumber) {
    try {
      const details = await fundDetailsService.getFundDetails({ isinNumber });
      if (details) {
        setDetailsData(details)
      } else {
        setDetailsData("No Data Found")
      }
    } catch (error) { 
      setDetailsData("There is some technical issue, please try again later")
    }
  }

  useEffect(() => {
    if (currentData && currentData.isinNumber) {
      if (selectedScheme && selectedScheme.isinNumber !== currentData.isinNumber) {
        getDetails(currentData.isinNumber || "")
      } else {
        setDetailsData(fundDetails)
      }
    }
  }, [currentData, selectedScheme, fundDetails])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.mainDiv}>
      {
        typeof (detailsData) === "string" ?
          <div style={{ height: "350px" }}>
            <Placeholder
              loading={detailsData === "Loading..." ? true : false}
              loadingMsg={""}
              error={detailsData === "Loading..." ? "" : detailsData}
            />
          </div>
          :
          <Grid container>
            <Grid item xs={12} className={classes.topDetails}>
              <FundDetails
                popup={true}
                propScheme={(detailsData && detailsData.fundtop &&
                  getFundTopDetails(detailsData.fundtop[0], currentData && currentData.schemeCode, dialogData || {})) || {}}
                propSchemeFundDetails={detailsData}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "0px 4px" }}>
              <Paper elevation={2} className={classes.paper}>
                <CssTabs
                  value={value}
                  onChange={handleChange}
                  className={classes.tabs}
                  aria-label="full width tabs example"
                >
                  {
                    tabs.map((ele, index) => (
                      <Tab key={index} className={value === index ? classes.activeTab : classes.tab} tabIndex={index} label={ele} {...a11yProps(index)} />
                    ))
                  }
                </CssTabs>
                <TabPanel value={value} index={0} classes={classes}>
                  <Overview propSchemeFundDetails={detailsData} />
                </TabPanel>
                <TabPanel value={value} index={1} classes={classes}>
                  <Portfolio popup={true} propSchemeFundDetails={detailsData} />
                </TabPanel>
                <TabPanel value={value} index={2} classes={classes}>
                  <Peers propSchemeFundDetails={detailsData} />
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { common: { fundDetails, selectedScheme }, dialog: { data } } = state;
  return { fundDetails, selectedScheme, dialogData: data || {} };
}

export default connect(mapStateToProps)(withStyles(styles)(FundDetailsPopup))