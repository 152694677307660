import React from "react"
import { withStyles } from '@mui/styles';
import styles from "../styles"
import { Button, Grid, Typography } from "@mui/material"
// import { addBankMandate } from "helpers/formRedirect"

function OrderSuccess(props) {
  const { classes, data, onCancel } = props

  const HeaderDiv = () => {
    return (
      <Grid container className={classes.valueHeaderGrid}>
        <Grid item xs={3}>
          <Typography variant="caption" align="left" className={classes.blueText}>Orders</Typography>
        </Grid>
        <Grid item xs={3} className={classes.centerContent}>
          <Typography variant="caption" align="center" className={classes.blueText}>Order Count</Typography>
        </Grid>
        <Grid item xs={6} className={classes.centerContent}>
          <Typography variant="caption" align="center" className={classes.blueText}>Status</Typography>
        </Grid>
      </Grid>
    )
  }

  const getText = (text, type) => {
    let allTexts = text.split(" ")
    return <Typography variant="caption" align="left" className={classes.primaryText}><span style={{ marginRight: "2px" }} className={[classes.primaryText, type].join(" ")}>{allTexts[0]}</span>{text.replace(allTexts[0], "")}</Typography>
  }

  const ValueDiv = () => {
    return (
      <Grid container className={classes.confirmGridItem}>
        {
          Object.keys(data).length > 0
            ?
            <>
              {
                Object.keys(data).map((ele, index) => (
                  <Grid item xs={12} className={classes.valueGridItem} key={index} style={index === Object.keys(data).length - 1 ? { borderBottom: 0 } : {}}>
                    <Grid container className={classes.centerContent}>
                      <Grid item xs={3}>
                        {getText(data[ele].label, data[ele].type)}
                      </Grid>
                      <Grid item xs={3} className={classes.centerContent}>
                        <Typography variant="caption" align="center" className={classes.primaryText}>{`${data[ele].count}/${data[ele].totalCount}`}</Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.centerContent} style={{ flexDirection: "column" }}>
                        <Typography variant="caption" align="center" className={classes.blueText}>{data[ele].message}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
            </>
            :
            <Grid item xs={12} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.primaryText}>{"No Data Found"}</Typography>
            </Grid>
        }
      </Grid >
    )
  }

  return (
    <div className={classes.form}>
      <Grid container className={classes.confirmValueGrid}>
        <Grid item xs={12}>
          {HeaderDiv()}
        </Grid>
        <Grid item xs={12}>
          {ValueDiv()}
        </Grid>
      </Grid>
      <Grid container className={classes.buttonGrid}>
        <Grid item xs={3}>
          <Button variant="contained" style={{ marginLeft: 0 }} className={classes.sendOrder} onClick={onCancel}>Ok</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(OrderSuccess)