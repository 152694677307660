import { store } from 'helpers/store';
import { CommonActions } from 'redux/Common';

export function canCall(shortCode) {
    if (!shortCode) return false;// Invalid shortCode
    const holidays = store.getState().common.holidays;
    if (!holidays) return false;// Holidays not found
    const market = holidays[shortCode];
    if (!market) return false; // Cannot call for this shortCode, market object not found
    if (market.holiday) {
        // Cannot call for this shortCode, market holiday
        return false;
    }
    if (notMarketHours(market.startMil, market.endMil)) {
        // Update the status for the given shortCode, market is closed
        CommonActions.updateHolidays(shortCode, false);
        return false;
    }
    // Can call for this shortCode, returning true
    return true;
}

export function notMarketHours(start, end) {
    const today = new Date();
    const todayMil = today.getTime();
    return todayMil < start || todayMil > end;
}

export function isAnyMarketOpen() {
    // debugger
    const holidays = store.getState().common.holidays;
    if (!holidays) return false;
    let isOpen = false;
    for (const key in holidays) {
        const { status } = holidays[key];
        if (canCall(key)) {
            // debugger
            isOpen = true;
            break;
        }
    }
    return isOpen;
}
