export default theme => ({
  mainDiv: {
    width: "100%",
    height: "100%",
    overflowY: "auto"
  },
  root: {
    display: "flex",
    flexGrow: 1,
    height: '100%',
    margin: '0px 15px',
    [theme.breakpoints.down('xl')]: {
      margin: "0px",
    },
  },
  grid: {
    height: '100%',
    alignContent: "center",
    margin: "0px",
    display: 'flex',
    minWidth: "1140px",
    minHeight: "8vh",
    padding: 16,
    [theme.breakpoints.down('xl')]: {
      minWidth: "948px",
      minHeight: "10vh",
      padding: "16px"
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: "740px",
      minHeight: "12vh",
      padding: "10px"
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "650px",
    },
  },
  paper: {
    padding: 15,
    color: theme.palette.color15.default,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    [theme.breakpoints.down('xl')]: {
      padding: "12px 16px"
    },
  },
  grey: {
    color: theme.palette.color15.default,
    marginLeft: 22,
    fontSize: 14,
    [theme.breakpoints.down('xl')]: {
      marginLeft: "16px",
      fontSize: 12,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: "12px",
      fontSize: 10
    },
  },
  highlight: {
    color: theme.palette.color12.default,
    whiteSpace: "initial",
  },
  button: {
    textTransform: "none",
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
  },
  ratingBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  ratingSpan: {
    display: "flex",
    alignItems: "center",
    "&.margin": {
      marginLeft: "30px",
      [theme.breakpoints.down('xl')]: {
        marginLeft: "56px",
      },
      [theme.breakpoints.only('md')]: {
        fontSize: "11px",
      },
      [theme.breakpoints.only('md')]: {
        marginLeft: "36px",
      },
    },
    "&.textMargin": {
      marginBottom: "8px",
      [theme.breakpoints.down('xl')]: {
        marginBottom: "4px",
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: "6px",
        marginTop: "-6px"
      }
    }
  },
  primaryText: {
    color: theme.palette.text.primary,
    [theme.breakpoints.only('md')]: {
      fontSize: "11px",
    },
  },
  secondaryText: {
    color: theme.palette.color15.default,
    [theme.breakpoints.only('md')]: {
      fontSize: "11px",
    },
  },
  textDiv: {
    padding: "0px 12px",
    borderRight: `1px dashed ${theme.palette.color15.default}`,
    "&.noBorder": {
      borderRight: "0px"
    },
    [theme.breakpoints.down('xl')]: {
      padding: "0px 16px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "0px 12px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "0px 8px",
    },
  },
  returnsPadding: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: theme.palette.background.secondary,
    [theme.breakpoints.down('xl')]: {
      padding: "16px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "8px",
    }
  },
  returnsValue: {
    textAlign: "center",
    marginBottom: "8px",
    color: "#4582B8"
  },
  select: {
    background: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    borderRadius: "20px",
    '&:hover': {
      backgroundColor: theme.palette.background.primary
    }
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '4px !important'
  }
})
