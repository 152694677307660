import { store } from 'helpers/store';
import { LayoutActions } from 'redux/Layout';
import { getToken } from './cookies';

export const StorageHelper = {
    compareSub,

    setSelectedList,
    getSelectedList,

    setSelectedListMF,
    getSelectedListMF,

    setSelectedWatchist,
    getSelectedWatchlist,

    setSortOption,
    getSortOption,

    setSortOptionMF,
    getSortOptionMF,

    setCustomSort,
    getCustomSort,

    // setCustomSortMF,
    // getCustomSortMF,

    setGridList,
    getGridList,

    setOrderView,
    getOrderView,

    setOrderViewPosition,
    getOrderViewPosition,

    setAdvisoryNotification,
    getAdvisoryNotification,

    setLayoutJson,
    getLayoutJson,

    // setSorting,
    // getSorting,

    setColorTheme,
    getColorTheme,

    saveColumnOrder,
    getColumnOrder
};

function setSelectedList(value) {
    localStorage.setItem('selectedList', value);
}

function setSelectedListMF(value) {
    localStorage.setItem('selectedListMF', value);
}

function getSelectedList(defaultVal) {
    if (compareSub()) {
        return localStorage.getItem('selectedList') || defaultVal || null;
    }
    return null;
}

function getSelectedListMF(defaultVal) {
    if (compareSub()) {
        return localStorage.getItem('selectedListMF') || defaultVal || null;
    }
    return null;
}

function setSelectedWatchist(value) {
    localStorage.setItem('selectedWatchlist', JSON.stringify(value));
}

function getSelectedWatchlist() {
    // if (!compareSub()) return;
    let selectedList = localStorage.getItem('selectedWatchlist');
    if (selectedList) {
        return JSON.parse(selectedList);
    }
    return null;
}

function setCustomSort(id, custom) {
    if (id == -1) return;
    localStorage.setItem(`watchlist_custom_${id}`, JSON.stringify(custom));
}

function getCustomSort(id) {
    if (compareSub()) {
        let sorting = localStorage.getItem(`watchlist_custom_${id}`) || null;
        if (sorting) return JSON.parse(sorting);
    }
    return [];
}

function setGridList(type) {
    localStorage.setItem('gridList', type);
}

function getGridList() {
    if (compareSub()) {
        return localStorage.getItem('gridList') || 0;
    }
    return;
}

function setOrderView(type) {
    localStorage.setItem('orderView', type);
}

function getOrderView() {
    return Number(localStorage.getItem('orderView')) || 1;
}

function setOrderViewPosition(position) {
    if (position)
        localStorage.setItem('orderViewPosition', JSON.stringify(position));
}

function getOrderViewPosition() {
    let position = localStorage.getItem('orderViewPosition');
    if (position) {
        return JSON.parse(position);
    } else {
        return { x: 0, y: 0 };
    }
}

function setAdvisoryNotification(notification) {
    if (notification != null)
        localStorage.setItem('advisoryNotification', JSON.stringify(notification));
}

function getAdvisoryNotification() {
    const localRaw = localStorage.getItem('advisoryNotification');
    const localItem = localRaw == null ? 1 : Number(localRaw);
    return localItem;
}

function setLayoutJson(layout, updateRedux) {
    const { layout: { layoutType }, common: { pathName } } = store.getState();
    if (layout) {
        localStorage.setItem(`layout_${pathName == 'mf' ? pathName : layoutType}`, JSON.stringify(layout));
        if (updateRedux) {
            LayoutActions.layoutUpdated(layout);
        }
    }
}

function getLayoutJson(isMF) {
    const user = `${process.env.REACT_APP_USER}`;
    let userType = user == 'admin' ? 'admin' : 'client';
    try {
        userType = store ? store.getState().layout.layoutType : 'client';
    } catch (error) {
        console.log(error);
    }
    let layout = localStorage.getItem(`layout_${isMF ? 'mf' : userType}`);
    if (layout) return JSON.parse(layout);
    return null;
}

function setSortOption({ type, direction }) {
    localStorage.setItem(
        'sortOption',
        JSON.stringify({
            type,
            direction
        })
    );
}

function getSortOption() {
    if (compareSub()) {
        let sortOption = localStorage.getItem('sortOption');
        if (sortOption) {
            return JSON.parse(sortOption);
        }
    }
    return { type: -1, direction: -1 };
}

function setSortOptionMF({ type, direction }) {
    localStorage.setItem(
        'sortOptionMF',
        JSON.stringify({
            type,
            direction
        })
    );
}

function getSortOptionMF() {
    if (compareSub()) {
        let sortOption = localStorage.getItem('sortOptionMF');
        if (sortOption) {
            return JSON.parse(sortOption);
        }
    }
    return { type: -1, direction: -1 };
}

function setColorTheme(value) {
    localStorage.setItem('selectedTheme', value);
}

function getColorTheme() {
    if (compareSub()) {
        return localStorage.getItem('selectedTheme') == 0 ? 0 : 1;
    }
    return -1;
}

function setSub() {
    let access_token = getToken(true);
    if (!access_token) return;
    let tkDetail = JSON.parse(atob(access_token.split('.')[1]));
    localStorage.setItem('sub', tkDetail.sub);
    return tkDetail.sub;
}

function getSub() {
    let savedSub = localStorage.getItem('sub');
    if (!savedSub) {
        savedSub = setSub();
    }
    return savedSub;
}

function compareSub() {
    let savedSub = getSub();
    if (!savedSub) return false;
    let access_token = getToken(true);
    if (!access_token) return false;
    let tkDetail = JSON.parse(atob(access_token.split('.')[1]));
    let currentSub = tkDetail.sub;
    if (savedSub != currentSub) {
        localStorage.clear();
        setSub();
    }
    return savedSub == currentSub;
}

export function setItem(key, value) {
    localStorage.setItem(key, value);
}

export function getItem(key, defaultVal) {
    return localStorage.getItem(key) || defaultVal || null;
}

function saveColumnOrder(table, columnOrder) {
    if (!columnOrder) {
        localStorage.removeItem(`${table}_table`);
        return;
    }
    localStorage.setItem(`${table}_table`, JSON.stringify(columnOrder));
}

function getColumnOrder(table) {
    const customOrder = localStorage.getItem(`${table}_table`);
    // if (!customOrder) return null;
    if (!customOrder) return;
    return JSON.parse(customOrder);
}

export function isDefaultOrder(order) {
    if (!order) return true;
    let index = 0;
    order.forEach((element) => {
        if (element != index) return false;
        index++;
    });
    return true;
}

export function compareOrder(a, b) {
    if (!a || !b) return true;
    if (a && b) {
        if (a.length != b.length) return true;
        let index = 0;
        a.forEach((element) => {
            if (element != index) return false;
            index++;
        });
    }
    return true;
}
