import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import axios from "helpers/interceptor";
import Placeholder from "components/Placeholder";
import { Grid, Typography, FormControl, Button, CircularProgress, Tooltip, FormControlLabel, RadioGroup, Radio, MenuItem } from "@mui/material";
import { openConfirmDialog, closeConfirmDialog } from "redux/ConfirmDialog";
import { SelectOutlinedInput, CssSelect, CssOutlinedInput } from "widgets/order/customfields";
import { KeyboardArrowDown as Arrow } from "@mui/icons-material";
import SvgComponent from "helpers/svgcomponent";
import styles from './styles';

const URL = {
  "payment": "/ms-mf-lumpsumorder/lumpsum/paymentNow",
  "accountDetails": "/ms-mf-lumpsumorder/lumpsum/getAccountAndMandateDetails"
}

function PayNow(props) {

  const { classes, closeConfirmDialog, openConfirmDialog, onCancel, row } = props

  const [loading, setLoading] = useState({ load: true, error: "Loading...", submitting: false })
  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}${URL.accountDetails}`).then(resp => {
      if (resp.data.success?.accountDetails) {
        setAccountData(resp.data.success.accountDetails)
        resp.data.success.accountDetails[0] && onAccountChange(resp.data.success.accountDetails[0].accountNumber, resp.data.success.accountDetails)
      } else {
        setAccountData([])
      }
      setLoading({ submitting: false, load: false, error: "" })
    }).catch(error => {
      setLoading({ submitting: false, load: false, error: "" })
    })
  }, [])

  let schema = Yup.object().shape({
    accountDetails: Yup.string()
      .required("Please select account number"),
    paymentMode: Yup.mixed()
      .oneOf(["NETBANKING", "UPI"])
      .required("Please select payment mode"),
    upiId: Yup.string()
      .when("paymentMode", {
        is: "UPI",
        then: Yup.string()
          .required("Please enter UPI Id")
      }),
  })

  const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
    initialValues: {
      accountDetails: "",
      paymentMode: "",
      upiId: ""
    },
    validationSchema: schema,
    onSubmit: values => {
      handlePayNow()
    }
  })

  function onAccountChange(value, data) {
    let account = (data.length > 0 && data.find(ele => ele.accountNumber == value)) || {};
    setFieldValue("accountDetails", value)
    setFieldValue("ifscCode", account.ifscCode || '')
    setFieldValue("bankID", account.netID || '')
    setFieldValue("bankUpiID", account.upiID || '')
    setFieldValue("bankPaymentMode", account.paymentMode || '')
  }

  function handlePayNow() {
    setLoading({ submitting: true, load: false, error: "" })
    let obj = {
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: closeConfirmDialog
    }
    let payload = {
      orderNo: row.bseorderNo,
      orderAmount: row.amount,
      accountNumber: values.accountDetails || '',
      ifscCode: values.ifscCode || '',
      modeofpayment: values.paymentMode === "UPI" ? values.paymentMode : values.bankPaymentMode,
      bankID: values.paymentMode === "UPI" ? values.bankUpiID : values.bankID || '',
      id: row.id,
      logOutURL: window.location.href,
      vpaID: (values.paymentMode === "UPI" && values.upiId) || "",
    }
    axios.post(`${process.env.REACT_APP_API_URL}${URL.payment}`, payload).then(resp => {
      onCancel()
      if (resp.data.success) {
        if (values.paymentMode === "NETBANKING") {
          var opened = window.open("about:blank", "_self");
          opened.document.open();
          opened.document.write(resp.data.success);
          opened.document.close();
        } else {
          obj.content = `${resp.data.success.id && resp.data.success.id !== "" ? (`${resp.data.success.msg || "Order has been placed successfully"}. Order Id is ${resp.data.success.id}.${values.paymentMode === "UPI" ? " You will receive a payment collection request on your UPI app. Please complete the payment to process your order" : ""}` || "Order has been placed successfully") : (`${resp.data.success.msg || "Order has been placed successfully"}.${values.paymentMode === "UPI" ? " You will receive a payment collection request on your UPI app. Please complete the payment to process your order" : ""}`)}`
          openConfirmDialog(obj)
        }
      } else {
        obj.content = resp.data.error || "Error while doing payment, Please try again after some time."
        openConfirmDialog(obj)
      }
    }).catch(error => {
      obj.content = error.message || "Error while doing payment, Please try again after some time."
      openConfirmDialog(obj)
    })
  }

  const accountDetailsField = () => {
    return (
      <Grid item xs={9}>
        <FormControl variant="outlined" className={classes.fieldDiv}>
          <Typography className={classes.fieldText}>Account Number</Typography>
          <CssSelect
            labelId="demo-simple-select-filled-label"
            id="accountDetails"
            name="accountDetails"
            value={values.accountDetails}
            onChange={(e) => onAccountChange(e.target.value, accountData)}
            error={touched.accountDetails && errors.accountDetails ? true : false}
            onKeyUp={() => setFieldTouched("accountDetails", true)}
            className={classes.select}
            input={<Tooltip title={values.accountDetails} aria-label={values.accountDetails} className={""}><SelectOutlinedInput /></Tooltip>}
            variant="standard"
            IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
          >
            {
              accountData.map((ele) => (
                <MenuItem key={ele.accountNumber} value={ele.accountNumber} className={classes.menuItem}>{ele.bankName} ({ele.accountNumber})</MenuItem>
              ))
            }
          </CssSelect>
          {errors.accountDetails && touched.accountDetails && (<p className={classes.errorText}>{errors.accountDetails}</p>)}
        </FormControl>
      </Grid>
    )
  }

  const paymentFields = () => {
    return <>
      <Grid item xs={10}>
        <FormControl variant="outlined" className={classes.fieldDiv}>
          <Typography className={classes.fieldText}>Payment mode</Typography>
          <RadioGroup
            row
            size="small"
            aria-labelledby="demo-row-radio-buttons-group-label"
            id="paymentMode"
            name="paymentMode"
            value={values.paymentMode}
            onChange={handleChange}
          >
            <FormControlLabel classes={{ label: classes.radioLabel }} value="NETBANKING" control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label="Net Banking" />
            <FormControlLabel style={{ marginRight: 0 }} classes={{ label: classes.radioLabel }} value="UPI" control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label={<SvgComponent iconname={"UPI"} className={classes.svg} />} />
          </RadioGroup>
          {errors.paymentMode && touched.paymentMode && (<p className={classes.errorText}>{errors.paymentMode}</p>)}
        </FormControl>
      </Grid>
      {values.paymentMode === "UPI" &&
        <>
          <Grid item xs={9}>
            <FormControl variant="outlined" className={classes.fieldDiv} style={{ marginBottom: 2 }}>
              <Typography className={classes.fieldText}>Enter your UPI Id</Typography>
              <CssOutlinedInput
                id="upiId"
                name="upiId"
                classes={{ input: classes.input }}
                onChange={handleChange}
                error={touched.upiId && errors.upiId ? true : false}
                value={values.upiId}
                onKeyUp={() => setFieldTouched("upiId", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.upiId && touched.upiId && (<p className={classes.errorText}>{errors.upiId}</p>)}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.belowText}>
              Kindly use the UPI Id associated with the selected bank. Else payment will fail
            </Typography>
          </Grid>
        </>}
    </>
  }

  const form = () => {
    return (
      <form autoComplete={"off"} onSubmit={handleSubmit}>
        <Grid container>
          {loading && loading.showError && <div className={classes.errorDiv} style={{ width: '100%' }}><Typography>{loading.error}</Typography></div>}
          <Grid item xs={12} className={classes.convertFormFrid}>
            <Grid container>
              {accountDetailsField()}
              {paymentFields()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Button type="submit" disabled={loading && loading.submitting ? true : false} className={classes.button} autoFocus={true}>{loading && loading.submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Pay</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <div className={classes.form}>
      {
        loading.load ?
          <div className={classes.loadingDiv}>
            <Placeholder
              loading={loading.load}
              loadingMsg={""}
              error={loading.load ? "" : loading.error}
            />
          </div>
          :
          form()
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return { row: state.dialog.data }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PayNow))