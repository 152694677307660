import { Cancel as CancelIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Button, Divider, IconButton, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import SvgComponent from "helpers/svgcomponent";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Tour from 'reactour';
import { LayoutActions } from 'redux/Layout';
import { TourActions } from "redux/Tour";
import { Description, InitContainer, InitDesc, InitTitle, StepContainer, StepCount, StepTitleContainer, TextButton, Title } from "./components";
import './styles.css';
import stepsData from './tourSteps';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.primary
    },
    maskClassName: {
        backgroundColor: 'red'
    },
    highlightedMaskClassName: {
        backgroundColor: 'pink'
    },
    mainClassName: {
        backgroundColor: 'blue'
    },
    frofNt: {
        backgroundColor: 'pink'
    },
    stepContainer: {
        padding: 10,
        backgroundColor: '#4E55AF',
        // borderRadius: 15
    },
    title: {
        fontSize: 22,
        color: theme.palette.common.white,
        whiteSpace: 'break-spaces'
    },
    desc: {
        fontSize: 18,
        color: theme.palette.common.white,
        whiteSpace: 'break-spaces'
    }
});

function TourGuide(props) {
    const theme = useTheme();
    const bgColor = theme.palette.common.neutral;

    const {
        classes,
        location: { pathname },
        keyRightPressCount,
        keyLeftPressCount,
        isTourOpen,
        section
    } = props;
    // debugger
    const [initial, setInitial] = useState(false);
    const [allSteps, setAllSteps] = useState([]);
    const [currentKeys, setCurrentKeys] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    // const [isTutorialOpen, setIsTutorialOpen] = useState(false);
    const [dragCompleted, setDragCompleted] = useState(false);

    useEffect(() => {
        const userType = `${process.env.REACT_APP_USER}`;
        if (userType === 'client') {
            TourActions.initTour();
        }
        // let local = localStorage.getItem('tour-guide');
        // console.log('data', local);
        // if (!local) {
        //     TourActions.openTour('init');
        // }
    }, []);

    const markTourTaken = () => {
        localStorage.setItem('tour-guide', 1);
    }

    useEffect(() => {
        if (isTourOpen) {
            setAllSteps(getSteps());
            setCurrentStep(0);
        }
        // debugger
    }, [section, isTourOpen]);

    useEffect(() => {
        console.log('currentStep', currentStep);
        // debugger
    }, [currentStep]);

    // useEffect(() => {
    //     console.log('allSteps', allSteps);
    //     debugger
    // }, [allSteps]);

    useEffect(() => {
        if (props.dragCompleted) {
            setDragCompleted(true);
        }
    }, [props.dragCompleted]);

    useEffect(() => {
        // if (keyRightPressCount > 0) onNext();
        if (keyRightPressCount > 0) {
            if (currentStep < currentKeys.length - 1) {
                setCurrentStep(currentStep + 1);
            }
        }
    }, [keyRightPressCount]);

    useEffect(() => {
        // if (keyLeftPressCount > 0) onPrev();
        if (keyLeftPressCount > 0) {
            if (currentStep > 0) {
                setCurrentStep(currentStep - 1);
            }
        }
    }, [keyLeftPressCount]);

    const gotoStep = (step) => {
        setCurrentStep(step);
    }

    const onNext = () => {
        // debugger
        // if (currentStep < allSteps.length - 1) {
        // setCurrentStep(currentStep + 1);
        // setDragCompleted(props.dragCompleted);
        // }
    };

    const onPrev = () => {
        // if (currentStep > 0) {
        //     setCurrentStep(currentStep - 1);
        //     setDragCompleted(props.dragCompleted);
        // }
    };

    const afterOpen = () => {
        LayoutActions.setActiveTab('tourguide');
    };

    const beforeClose = () => {
        LayoutActions.setActiveTab('previousTab');
    };

    const getSteps = () => {
        if (isTourOpen) {
            if (section == 'init') {
                return [{
                    content: () => (
                        <InitContainer>
                            <SvgComponent iconname={"WelcomeImage"} />
                            <InitTitle>{'Welcome to Tradebulls Net'}</InitTitle>
                            <InitDesc>
                                {'Take a tour to learn about the robust features of\nTrading Terminal'}
                            </InitDesc>
                            <StepTitleContainer>
                                <Button
                                    variant="contained"
                                    // className={classes.containedBlue}
                                    style={{
                                        fontSize: 25,
                                        color: '#FFF',
                                        backgroundColor: theme.palette.common.blue,
                                        padding: '0 25px',
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                        TourActions.closeTour();
                                        markTourTaken();
                                        setTimeout(() => {
                                            TourActions.openTour();
                                        }, 500);
                                    }}
                                >
                                    {'Get Started'}
                                </Button>
                                <Button
                                    variant="text"
                                    style={{
                                        color: '#FFF',
                                        margin: '0 15px',
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                        TourActions.closeTour();
                                        markTourTaken();
                                    }}
                                >
                                    {'Skip'}
                                </Button>
                            </StepTitleContainer>
                        </InitContainer>
                    )
                }]
            }
            let tourKeys = Object.keys(stepsData)
            if (section) {
                switch (section) {
                    case 1://Sidebar
                        tourKeys = ['search', 'equity', 'mutualFund', 'portfolio', 'eodReports', 'invest']
                        break;
                    case 2://Topbar
                        tourKeys = ['limit', 'addMoney', 'saveLayout']
                        break;
                    case 3://Watchlist
                        tourKeys = ['watchlistMain', 'scripDetails', 'watchlistChange', 'watchlistActions', 'watchlistView']
                        break;
                    default:
                        // tourKeys = ['search', 'equity']
                        break;
                }
            }
            return tourKeys.map((key, index) => {
                // debugger
                const stepData = stepsData[key];
                // debugger
                return {
                    selector: '[data-tut="' + stepData.key + '"]',
                    content: () => (
                        <StepContainer>
                            <StepTitleContainer>
                                <StepCount>{index + 1}</StepCount>
                                <Title>{stepData.title || 'Title'}</Title>
                                <IconButton style={{ height: 22, width: 22 }}
                                    onClick={closeTour}>
                                    <CancelIcon style={{ color: 'white' }} />
                                </IconButton>
                            </StepTitleContainer>
                            <Divider style={{ width: '100%', height: 1, backgroundColor: '#ccc', margin: '10px 0' }} />
                            <Description>{stepData.value}</Description>
                            <StepTitleContainer style={{ marginTop: 10 }}>
                                {index !== 0 &&
                                    <TextButton onClick={() => gotoStep(0)}>{'Back to Start?'}</TextButton>
                                }
                                <Divider style={{ flex: 1, backgroundColor: 'transparent' }} />
                                {index !== 0 && <IconButton
                                    /* style={{ backgroundColor: 'white' }} */
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        marginRight: 8,
                                        padding: 5,
                                        height: 'fit-content',
                                    }}
                                    onClick={() => gotoStep(index - 1)}>
                                    <ChevronLeftIcon style={{ color: theme.palette.common.blue }} />
                                </IconButton>}
                                {index !== tourKeys.length - 1 && <IconButton
                                    onClick={() => gotoStep(index + 1)}
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        padding: 5,
                                        height: 'fit-content',
                                    }}>
                                    <ChevronRightIcon style={{ color: theme.palette.common.blue }} />
                                </IconButton>}
                            </StepTitleContainer>
                        </StepContainer>
                    ),
                    style: { backgroundColor: bgColor }
                };
            });
        } else {
            return []
        }
    };

    const closeTour = () => {
        TourActions.closeTour();
    };

    const DoneButton = () => {
        return (
            <Button variant="contained" color="primary">Done</Button>
        )
    }

    return (
        // <div onKeyDown={(e) => console.log('keyPress', e.key)}>
        <Tour
            accentColor={'red'}
            // accentColor={theme.palette.common.blue}
            // className={classes.mainClassName}
            className={'helper'}
            // maskClassName={'mask'}
            // highlightedMaskClassName={'mask'}
            // maskClassName={classes.maskClassName}
            highlightedMaskClassName={classes.highlightedMaskClassName}
            isOpen={isTourOpen && allSteps.length > 0}
            // steps={getSteps()}
            steps={allSteps}
            rounded={theme.spacing(1)}
            // showButtons={currentStep != 2 || dragCompleted}
            // showNavigation={currentStep != 2 || dragCompleted}
            showCloseButton={false}
            closeWithMask={false}
            // disableDotsNavigation={true}
            // update={pathname}
            onRequestClose={closeTour}
            showNumber={false}
            onAfterOpen={afterOpen}
            onBeforeClose={beforeClose}
            nextStep={onNext}
            prevStep={onPrev}
            disableFocusLock={true}
            goToStep={currentStep}
            // disableInteraction={currentStep != 2 || dragCompleted}
            ArrowProps={{}}
            // lastStepNextButton={<DoneButton />}
            CustomHelper={(data) => {
                // debugger
                return data.content
            }}
        // children={() =><Text>Hello Workd</Text>}
        />
        // </div >
    )
}

const mapStateToProps = (state) => {
    const {
        common: { keyLeftPressCount, keyRightPressCount },
        layout: { activeTab },
        watchlist: { dragCompleted },
        tour: { isOpen: isTourOpen, section }
    } = state;
    return { keyLeftPressCount, keyRightPressCount, activeTab, dragCompleted, isTourOpen, section };
};

export default connect(mapStateToProps)(
    withRouter(withStyles(styles)(TourGuide))
);
