export const faqData = [
  {
    question: "Will my order will be square off by broker same day ?",
    answer: `Yes, Margin/Intraday trading allows you to trade in Equities or Derivatives segment on an "intraday" basis i.e. margin orders placed during the day are squared off within 10 minutes prior to market close or 70% and above MTM loss of margin throughout day.`
  },
  {
    question: "Can I do short selling ?",
    answer: "Yes, You may take long buy or short sell position in select stocks."
  },
  {
    question: "The margin percentage for a stock may be uniform for all stocks or may vary for each stock ?",
    answer: "Yes it varies, margin provided to client are based on VaR Margin"
  },
  {
    question: "Stock bought on one stock exchange (for example BSE) and sold on another stock exchange  (for example NSE), will it qualify as an intraday trade ?",
    answer: "No, it will not be considered as intraday and if product type is margin then system will square off all open positions in both the exchanges."
  },
  {
    question: "Can I convert Margin or Intraday position to delivery or carry forward position ?",
    answer: "Yes, Client can convert its existing open position under margin or intraday product to delivery visa versa any time prior to 10 minutes of market closure."
  },
  {
    question: "Can I place orders without opting option of intraday product ?",
    answer: "Yes, Client can place order by selecting delivery option while placing order."
  },
  {
    question: "Do I require to maintain clear credit to opt for intraday product ?",
    answer: "Multiplier will be set on deposit calculated and in case if client is having carry forwarded position then client need to convert open position to intraday in order to release his margin blocked."
  },
  {
    question: "Up to what extent I can carry forward positions ?",
    answer: "In case of equity market client can take delivery as per VaR margin against deposit calculated and 1 time in case of derivative market."
  },
  {
    question: "When will my positions be squared off ?",
    answer: "The position will be squared off 10 Mins. Before closing of the market or if your total MTM reaches 70%  of the available margin then all open positions will be squared off, at best possible effort."
  },
  {
    question: "Can I trade in Intraday/ Margin product after 3:20 pm ?",
    answer: "No, Product will be disabled 10 minutes prior to market closure, however clients can place order by selecting delivery or carry forward option."
  },
  {
    question: "Whether my pending order will cancel before square off by system ?",
    answer: "Yes, all pending order will be cancelled by system before square off initiated. And all Tradebulls will attempt to square off all your existing open positions, irrespective of whether the positions are making a profit or loss."
  },
  {
    question: "Where can I see whether what product is selected while placing order ?",
    answer: "Same can be viewed in both Alt+F6 (Consolidated net position) and F8 (trade book) image given below."
  },
  {
    question: "What if scrip hits circuit filter or system is not able to square off option position due to technical failure ?",
    answer: "In case if margin/Intraday position could not be squared off for any reasons (for example, lower liquidity in the scrip, scrip hitting circuit filter, internal system issues or problems at Exchange’s end, market freezing by the Exchanges / SEBI etc.) your open position will be settled on a delivery basis."
  }
]

export const conditionsData = [
  "Yes, I/We am/are registered with you and dealing in Capital Market / Derivative Market / Currency Market/ Commodities Market having client code as mentioned above. I/We am/are interested in availing of the facility offered by you in Intraday trading in the exchange segments in which I/We am/are registered with you for trading.",
  "I/We confirm that I/We am/are aware of the Intraday Product and its features and have clearly understood the risks associated with Intraday Trading. I/We understand that we are allowed a higher leverage in the Intraday Trading Facility as compared to trades in the regular market positions and, therefore, while the opportunity for making profits on the investment is magnified, the risk of loss would also be enlarged correspondingly.",
  "I/We understand that Intraday Trading would be allowed only if the margin required under the Facility is already available in my/our account with you. I/We agree and accept that enlisting me/us for the Intraday Trading Facility shall not oblige you to place Intraday Orders requested by me in any scrips even though margin required for placing a trade order under the Facility is available in my/our account. I/we also understand and agree that the option to convert my/our intraday trade positions to carry forward positions is subject to full margin being made available upfront unless such margin is already lying to the credit of my/our account with you.",
  "I/We am/are aware that under the Facility, unless the positions are carried forward as stated above, all open positions shall be squared off by Risk Management Team any time between 10 minutes prior to market close on the same day of their acquisition unless the positions are sooner squared off upon the positions incurring a loss to the extent of 70% of margin available or more as may be determined by you from time to time.",
  "I/we agree and accept that if for any reason beyond your control, like force majeure causes, disruptions in the communication network, system failure, slow or delayed response from system, trading halts, or the Exchange applying circuit filters because of which the open Intraday positions could not be squared off and are carried forward, you are expected only to square them off on a best effort basis, as soon as may be, and any and all losses arising from such events will be to my/our account.",
  "I/We agree and accept that I/We will not hold Tradebulls Securities (P) Limited/ Tradebulls Commodities Broking Private Limited. & their Directors, Officers or employees liable for any loss that I/we may sustain as a consequence of availing of this Facility.",
  "I/We agree and accept that I/We will not receive any kind of communication from Tradebulls Securities (P) Limited. / Tradebulls Commodities Broking (P) Limited & their Directors, Officers or employees with respect to intimation of percentage of loss and square off positions. All the terms and conditions of the agreement",
  "I/We have executed with you shall remain effective and in force in all respect until terminated in terms thereof. I have gone through the product FAQ’s along with its logic and I/we have fully understood the same. Client should have registered email id and mobile number registered with Tradebulls may take long buy or short sell position in select stocks."
]