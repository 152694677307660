import { store } from "helpers";

//Actions
const OPEN_CONFIRM_DIALOG = 'OPEN_CONFIRM_DIALOG';
const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG';

export const DialogActions = {
  confirmDialogOpen,
  confirmDialogClose,
}

const InitialState = {
  confirmData: null,
  isConfirmOpen: false
}

function confirmDialogOpen(child, data) {
  store.dispatch(openConfirmDialog(data));
}

function confirmDialogClose() {
  store.dispatch(closeConfirmDialog());
}

//Actions Creators
export function openConfirmDialog(data) {
  return { type: OPEN_CONFIRM_DIALOG, data };
}

export function closeConfirmDialog() {
  return { type: CLOSE_CONFIRM_DIALOG };
}


//Reducer
export function confirmDialog(state = InitialState, action) {
  switch (action.type) {
    case OPEN_CONFIRM_DIALOG:
      return {
        ...state,
        confirmData: action.data,
        isConfirmOpen: true
      };
    case CLOSE_CONFIRM_DIALOG:
      return {
        ...state,
        confirmData: null,
        isConfirmOpen: false
      };;
    default:
      return state
  }
}
