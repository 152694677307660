import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, CircularProgress, Grid, IconButton, InputAdornment, Link, Typography } from "@mui/material";
import Placeholder from "components/Placeholder";
import React, { useState } from "react";
import { CssOutlinedInput } from 'widgets/order/customfields';

function VerifyTotp(props) {
  const { classes, loading, data, onCancel, verifyTotp } = props;
  const [totp, setTotp] = useState({ otp: '', password: '' });
  const [showKey, setShowKey] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function getError() {
    if (totp?.otp) {
      if (!Number(totp.otp)) {
        return 'Please enter valid otp'
      } else if (Number(totp.otp) && totp.otp.length > 6) {
        return 'Only 6 digits allowed'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  return (
    <div className={classes.contentDiv}>
      <Typography variant='h1' className={[classes.blueText, classes.heading].join(' ')}>2 Factor TOTP - <span className={classes.secondaryText} style={{ fontWeight: 500 }}>Disabled</span></Typography>
      <Grid container className={classes.containerGrid}>
        <Grid item xl={4} lg={5} xs={4} className={classes.qrGrid}>
          {
            data?.totpqrcode ?
              <img src={data?.totpqrcode || ''} className={classes.image} />
              :
              <div className={classes.image} style={{ height: 200 }} >
                <Placeholder
                  loading={true}
                  loadingMsg={''}
                  error={''}
                />
              </div>
          }
          {
            showKey ?
              <Typography variant='h5' className={classes.primaryText} style={{ paddingTop: 20 }}>{data?.totpSecretKey || ''}</Typography>
              :
              <Typography variant='h5' className={classes.keyText} onClick={() => setShowKey(!showKey)}>Can't scan? Copy the key.</Typography>
          }
        </Grid>
        <Grid item xl={8} lg={7} xs={8}>
          <Typography variant='h4' className={classes.primaryText}>Scan the QR code on your authenticator app</Typography>
          <div className={classes.step4Description}>
            <Typography variant='h5' className={classes.secondaryText}>Once scanned, the app should give you a 6 digit TOTP.</Typography>
            <Typography variant='h5' className={classes.secondaryText}>Enter it here. <span><Link underline="hover" target="_blank" href={`${process.env.REACT_APP_CORPORATE_URL}/enable-totp`}>Need help?</Link></span></Typography>
          </div>
          <Typography variant='h5' className={classes.secondaryText}>6 digit TOTP</Typography>
          <div className={classes.totpInputDiv}>
            <CssOutlinedInput
              id="otp"
              name="otp"
              className={classes.totpInputField}
              classes={{ input: classes.input }}
              value={totp.otp}
              autoFocus={true}
              error={totp?.otp ? (totp.otp.length > 6 || !Number(totp.otp)) : false}
              onChange={(e) => setTotp({ ...totp, otp: e.target.value })}
            />
            <CssOutlinedInput
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your login password to confirm"
              className={classes.passwordInput}
              classes={{ input: classes.input }}
              value={totp.password}
              onChange={(e) => setTotp({ ...totp, password: e.target.value })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    style={{ padding: 0 }}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff style={{ opacity: "0.6" }} /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          {totp?.otp ? <Typography className={classes.error}>{getError()}</Typography> : <></>}
          <Typography variant='h5' className={classes.note}><span className={classes.primaryText} style={{ fontWeight: 'bold' }}>Important :</span> Once TOTP is setup, you have to use the same mobile authenticator app to generate a new 6-digit TOTP every time you login.</Typography>
          <div className={classes.buttonDiv}>
            <Button
              disabled={
                (loading.step === 4 && loading.submit) || !totp.password || !Number(totp.otp) ||
                totp?.password?.length < 8 && (totp?.password && String(totp.password).trim().length < 8) ||
                (totp?.otp && (!Number(totp.otp) || totp.otp.length > 6 || totp.otp.length < 6 ||
                  String(totp.otp).trim().length > 6 || String(totp.otp).trim().length < 6))}
              className={classes.button}
              onClick={() => verifyTotp(totp)}
              variant="contained"
            >
              {loading.step === 4 && loading.submit && <CircularProgress size={20} style={{ marginRight: "12px", color: '#999999' }} />}
              Enable
            </Button>
            <Button disabled={(loading.step === 4 && loading.submit)} className={classes.cancelButton} onClick={onCancel} variant="outlined">Cancel</Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default VerifyTotp;