import { Box, Grid, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { currentBrowser, isSupported } from "helpers/envHelper";
import React from 'react';
import ChromeIcon from "./icons/Chrome";
import FirefoxIcon from "./icons/Firefox";
import IEIcon from "./icons/IE";
import SafariIcon from "./icons/Safari";

const styles = () => ({
    root: {
        height: '100vh',
        width: '100%',
        background: '#fbdad8',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    versionInfo: {
        fontSize: 24,
        marginTop: 20,
        marginBottom: 20,
    },
    desc: {
        fontSize: 40,
        marginTop: 20,
        marginBottom: 20,
    },
    browsersGrid: {
        marginTop: 20,
        marginBottom: 20,
        width: '80vw'
    },
    gridItemBox: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
    },
});

function UnsupportedBrowser(props) {
    const { classes } = props;

    const browser = currentBrowser(window);
    const isSupporting = isSupported(browser);

    const gridItem = (name, icon) => {
        return (<Grid item xs={3}>
            <Box className={classes.gridItemBox}>
                {icon || null}
                <Typography className={classes.versionInfo}>{name}</Typography>
            </Box>
        </Grid>)
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.versionInfo}>
                Current browser: {browser.name}, Version: {browser.version}
            </Typography>
            <Typography className={classes.desc}>
                Your browser is not supported.
            </Typography>
            <Typography className={classes.versionInfo}>
                Please upgrade your browser to use our website.
            </Typography>
            <Typography className={classes.desc} style={{ marginTop: 50 }}>
                Supported Browsers
            </Typography>
            <Grid container className={classes.browsersGrid}>
                {gridItem("Chrome", <ChromeIcon />)}
                {gridItem("Firefox", <FirefoxIcon />)}
                {gridItem("Safari", <SafariIcon />)}
                {gridItem("IE>=11", <IEIcon />)}
            </Grid>
        </Box>
    )
}

export default (withStyles(styles)(UnsupportedBrowser))
