import React from "react";
import { flexRender } from "@tanstack/react-table";

const Footer = (props) => {
  const { classes, table } = props;

  return (
    table.getFooterGroups().map(footerGroup => (
      <tr key={footerGroup.id} style={{ display: 'flex', height: 30 }}>
        {footerGroup.headers.map(header => {
          return !header.column?.columnDef?.hideColumn && <th
            key={header.id}
            colSpan={header.colSpan}
            className={`${classes.cell} ${classes.footerCell} ${header.column?.columnDef?.className || ''}`}
            style={{
              ...header.column.columnDef.cellStyle || {},
              width: header.getSize() || 100,
              maxWidth: header.getSize() || 100
            }}
          >
            {flexRender(
              header.column.columnDef.footer,
              header.getContext()
            )}
          </th>
        })}
      </tr>
    ))
  )
}

export default Footer;