import { store } from 'helpers/store';
import { getLayout, resetLayout, saveLayout, setLayout } from "views/Dashboard/layouts/layoutHelper";

const LAYOUT_CHANGED = 'LAYOUT_CHANGED';
const LAYOUT_UPDATED = 'LAYOUT_UPDATED';
const LAYOUT_SAVED = 'LAYOUT_SAVED';
const LAYOUT_CUSTOM = 'LAYOUT_CUSTOM';
const LAYOUT_RESET = 'LAYOUT_RESET';
const LAYOUT_NEW = 'LAYOUT_NEW';
const LAYOUT_TAB_MIN_MAX = 'LAYOUT_TAB_MIN_MAX';
const LAYOUT_LOAD = 'LAYOUT_LOAD';
const OPEN_TRADE_MSGS = 'OPEN_TRADE_MSGS';
const SET_TRADE_MSGS_STATE = 'SET_TRADE_MSGS_STATE';
const OPEN_ADVISORY_MSGS = 'OPEN_ADVISORY_MSGS';
const SET_ADVISORY_MSGS_STATE = 'SET_ADVISORY_MSGS_STATE';
const OPEN_TRADEBULLS_MSGS = 'OPEN_TRADEBULLS_MSGS';
const SET_TRADEBULLS_MSGS_STATE = 'SET_TRADEBULLS_MSGS_STATE';
const OPEN_CHART = 'OPEN_CHART';
const SET_CHART_STATE = 'SET_CHART_STATE';
const OPEN_PRICE_ALERTS = 'OPEN_PRICE_ALERTS';
const SET_PRICE_ALERTS_STATE = 'SET_PRICE_ALERTS_STATE';
const SET_CHART_DATA = 'SET_CHART_DATA';
const SET_CHART_NODE_ID = 'SET_CHART_NODE_ID';
const SET_NODE_ID = 'SET_NODE_ID';
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const TOGGLE_SCRIP_INFO = 'TOGGLE_SCRIP_INFO';
const SET_ARROW_ACTION = 'SET_ARROW_ACTION';
const CLEAR_LAYOUT = 'CLEAR_LAYOUT';
const SET_FULL_VIEW = 'SET_FULL_VIEW';

export const LayoutActions = {
    layoutChanged,
    layoutUpdated,
    onSaveLayout,
    onResetLayout,
    setCustom,
    onTabMaxed,
    loadLayout,
    openTradeMsgs,
    setTradeMsgsState,
    openAdvisoryMsgs,
    setAdvisoryMsgsState,
    openChart,
    setChartData,
    setChartState,
    setChartNodeId,
    toggleScripInfo,
    setNodeId,
    setActiveTab,
    setArrowAction,
    clearLayout,
    setFullView,
    openPriceAlerts,
    setPriceAlertsState,
    openTradebullsMsgs,
    setTradebullsMsgsState,
};
//ACTIONS
function layoutChanged(json) {
    let oldLayout = getLayout();
    if (!(!json) && JSON.stringify(json) !== JSON.stringify(oldLayout)) {
        setLayout(json);
        store.dispatch({ type: LAYOUT_CHANGED });
    } else {
        // alert('No change');
    }
}
function layoutUpdated(data) {
    store.dispatch({ type: LAYOUT_UPDATED, data });
}
function onSaveLayout(userType) {
    saveLayout(userType);
    store.dispatch({ type: LAYOUT_SAVED });
}
function onResetLayout(userType) {
    resetLayout(userType);
    store.dispatch({ type: LAYOUT_RESET });
}
function setCustom() {
    try {
        store.dispatch({ type: LAYOUT_CUSTOM });//Error here
    } catch (error) {
        console.log(error);
    }
}
function onTabMaxed(data) {
    store.dispatch({ type: LAYOUT_TAB_MIN_MAX, data });
}

function loadLayout(data) {
    store.dispatch({ type: LAYOUT_LOAD, data });
}

function openTradeMsgs(data) {
    store.dispatch({ type: OPEN_TRADE_MSGS, data });
}

function setTradeMsgsState(data) {
    store.dispatch({ type: SET_TRADE_MSGS_STATE, data });
}

function openAdvisoryMsgs(data) {
    store.dispatch({ type: OPEN_ADVISORY_MSGS, data });
}

function setAdvisoryMsgsState(data) {
    store.dispatch({ type: SET_ADVISORY_MSGS_STATE, data });
}

function openPriceAlerts(data) {
    store.dispatch({ type: OPEN_PRICE_ALERTS, data });
}

function setPriceAlertsState(data) {
    store.dispatch({ type: SET_PRICE_ALERTS_STATE, data });
}

function openTradebullsMsgs(data) {
    store.dispatch({ type: OPEN_TRADEBULLS_MSGS, data });
}

function setTradebullsMsgsState(data) {
    store.dispatch({ type: SET_TRADEBULLS_MSGS_STATE, data });
}

function openChart(data) {
    store.dispatch({ type: OPEN_CHART, data });
}

function setChartData(data) {
    store.dispatch({ type: SET_CHART_DATA, data });
}

function setChartState(data) {
    store.dispatch({ type: SET_CHART_STATE, data });
}

function setChartNodeId(data) {
    store.dispatch({ type: SET_CHART_NODE_ID, data });
}

function toggleScripInfo(isOpen, nodeId) {
    store.dispatch({ type: TOGGLE_SCRIP_INFO, isOpen, nodeId });
}

function setNodeId(component, nodeId) {
    store.dispatch({ type: SET_NODE_ID, component, nodeId });
}

function setActiveTab(component) {
    store.dispatch({ type: SET_ACTIVE_TAB, component });
}

function setArrowAction(arrowAction) {
    store.dispatch({ type: SET_ARROW_ACTION, arrowAction });
}

function clearLayout() {
    store.dispatch({ type: CLEAR_LAYOUT });
}

function setFullView(data) {
    store.dispatch({ type: SET_FULL_VIEW, data });
}
//REDUCER
export function layoutReducer(state = initialState, action) {
    switch (action.type) {
        case LAYOUT_NEW:
            return {
                ...state,
                isEditing: true,
                isNew: true,
                layoutVersion: 0,
                saveVersion: ++state.saveVersion,
                resetVersion: 0,
            };
        case LAYOUT_CHANGED:
            return {
                ...state,
                isEditing: true,
                layoutVersion: ++state.layoutVersion,
                isCustomLayout: true
            };
        case LAYOUT_UPDATED:
            return {
                ...state,
                layoutJson: action.data
            };
        case LAYOUT_SAVED:
            return {
                ...state,
                isEditing: false,
                layoutVersion: 0,
                saveVersion: ++state.saveVersion,
                isCustomLayout: true
            };
        case LAYOUT_CUSTOM:
            return { ...state, isCustomLayout: true };
        case LAYOUT_RESET:
            return {
                ...state,
                isEditing: false,
                layoutVersion: 0,
                isCustomLayout: false,
                resetVersion: ++state.resetVersion
            };
        case LAYOUT_TAB_MIN_MAX:
            return { ...state, maxedLayout: action.data };
        case LAYOUT_LOAD:
            return { ...state, layoutType: action.data };
        case OPEN_TRADE_MSGS:
            return {
                ...state,
                tradeMsgsOpen: true,
                tradeMsgsOpenCounter: state.tradeMsgsOpenCounter + 1,
            };
        case SET_TRADE_MSGS_STATE:
            return {
                ...state,
                tradeMsgsOpen: action.data,
            };
        case OPEN_ADVISORY_MSGS:
            return {
                ...state,
                advisoryMsgsOpen: true,
                advisoryMsgsOpenCounter: state.advisoryMsgsOpenCounter + 1,
            };
        case SET_ADVISORY_MSGS_STATE:
            return {
                ...state,
                advisoryMsgsOpen: action.data,
            };
        case OPEN_PRICE_ALERTS:
            return {
                ...state,
                priceAlertsOpen: true,
                priceAlertsOpenCounter: state.priceAlertsOpenCounter + 1,
            };
        case SET_PRICE_ALERTS_STATE:
            return {
                ...state,
                priceAlertsOpen: action.data,
            };
        case OPEN_TRADEBULLS_MSGS:
            return {
                ...state,
                tradebullsMsgsOpen: true,
                tradebullsMsgsOpenCounter: state.tradebullsMsgsOpenCounter + 1,
            };
        case SET_TRADEBULLS_MSGS_STATE:
            return {
                ...state,
                tradebullsMsgsOpen: action.data,
            };
        case OPEN_CHART:
            return {
                ...state,
                chartOpen: true /* action.data *//* !state.chartOpen */,
                chartOpenCounter: state.chartOpenCounter + 1,/* (action.data ? 1 : 0) */
                chartData: action.data
            };
        case SET_CHART_DATA:
            return {
                ...state,
                chartData: action.data
            };
        case SET_CHART_STATE:
            return {
                ...state,
                chartOpen: action.data,
                chartData: action.data ? state.chartData : null
            };
        case SET_CHART_NODE_ID:
            return { ...state, chartNodeId: action.data };
        case SET_NODE_ID:
            return { ...state, [action.component]: action.nodeId };
        case SET_ACTIVE_TAB:
            return { ...state, activeTab: action.component, activeTabId: state[action.component] };
        case TOGGLE_SCRIP_INFO:
            return {
                ...state,
                isScripInfoOpen: action.isOpen,
                scripInfoNodeId: action.nodeId,
            };
        case SET_ARROW_ACTION:
            return {
                ...state,
                [action.arrowAction]: state[action.arrowAction] + 1,
            };
        case CLEAR_LAYOUT:
            return {
                ...initialState
            };
        case SET_FULL_VIEW:
            return {
                ...state,
                maximizedView: action.data
            };
        default:
            return state;
    }
}

const initialState = {
    activeTab: null,
    // layoutType: 'admin',
    layoutType: `${process.env.REACT_APP_USER}` == 'admin' ? 'ctclClient' : 'client',
    layoutJson: null,
    maxedLayout: "",
    isEditing: false,
    isNew: false,
    layoutVersion: 0,
    saveVersion: 0,
    resetVersion: 0,
    isCustomLayout: false,
    tradeMsgsOpen: false,
    tradeMsgsOpenCounter: 0,
    advisoryMsgsOpen: false,
    advisoryMsgsOpenCounter: 0,
    priceAlertsOpen: false,
    priceAlertsOpenCounter: 0,
    tradebullsMsgsOpen: false,
    tradebullsMsgsOpenCounter: 0,
    chartOpen: false,
    chartOpenCounter: 0,
    chartNodeId: null,
    isScripInfoOpen: false,
    scripInfoNodeId: null,
    arrowUp: 0,
    arrowDown: 0,
    maximizedView: false
};
