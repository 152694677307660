// Material helpers
import { createTheme } from '@mui/material/styles';
import overrides from './overrides';
import { darkPalette, lightPalette } from './palettes';
import typography from './typography';

export const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

const getBaseTheme = (palette) =>
  createTheme({
    palette,
    breakpoints: customBreakpoints
  });

function getTheme(theme) {
  const palette = theme.paletteType === 'light' ? lightPalette : darkPalette;
  const baseTheme = getBaseTheme(palette);
  return createTheme({
    ...baseTheme,
    typography: typography(baseTheme),
    components: {
      ...overrides(baseTheme)
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100
    }
  });
}

export default getTheme;
