import { Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import styles from "./styles";
import Table from "components/MaterialTable";

function Brokerage(props) {
  const { brokerage, classes/* , isMaximized */ } = props
  return (
    // isMaximized ?
      <div className={classes.tabPanel}>
        <div className={classes.tableDiv}>
          <Typography variant="h3" className={classes.heading}>EQUITY BROKERAGE</Typography>
          {
            brokerage && brokerage.equity && (Object.keys(brokerage.equity).length > 0 || (brokerage.equity.columns && brokerage.equity.columns.length > 0)) ?
              <Table
                maxHeight={450}
                data={brokerage.equity.filterdata || []}
                columns={brokerage.equity.columns || []}
              />
              :
              <div className={classes.noDataDiv}>
                No data found
            </div>
          }
        </div>
        <div className={classes.tableDiv}>
          <Typography variant="h3" className={classes.heading}>COMMODITY BROKERAGE</Typography>
          {
            brokerage && brokerage.commodity && (Object.keys(brokerage.commodity).length > 0 || (brokerage.commodity.columns && brokerage.commodity.columns.length > 0)) ?
              <Table
                maxHeight={450}
                data={brokerage.commodity.filterdata || []}
                columns={brokerage.commodity.columns || []}
              />
              :
              <div className={classes.noDataDiv}>
                No data found
            </div>
          }
        </div>
      </div>
      // :
      // <div className={classes.noData} style={{height: "285px"}}>
      //   No data found
      // </div>
  )
}

export default withStyles(styles)(Brokerage)