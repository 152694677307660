export default (theme) => ({
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: '0px',
    height: '25px',
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: '#497CAC',
    fontWeight: 600,
    lineHeight: '24px'
  },
  content: {
    padding: '0px !important',
    overflow: 'hidden',
    marginTop: '40px',
    backgroundColor: theme.palette.color17.default,
    width: 600,
    height: 400,
    padding: 20,
    [theme.breakpoints.down('md')]: {
      width: 400,
      padding: 6
    },
    [theme.breakpoints.down('sm')]: {
      height: 350,
      padding: 0
    }
  },
  containerGrid: {
    paddingTop: 32,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 24
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 12
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 8
    }
  },
  closeIcon: {
    float: 'right',
    height: '24px',
    padding: 0,
    background: 'none'
  },
  contentDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 20px)',
    overflowY: 'auto',
    [theme.breakpoints.down('xl')]: {
      height: 'calc(100% - 14px)'
    }
  },
  heading: {
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  doneImage: {
    width: '100px',
    height: '160px',
    marginTop: '-40px',
    [theme.breakpoints.down('xl')]: {
      width: '95px',
      height: '140px'
    }
  },
  resend: {
    textAlign: 'center',
    paddingTop: '6px'
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%"
  },
  blueText: {
    color: '#497CAC',
    fontWeight: 'bold'
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  email: {
    padding: '32px 0px',
    color: theme.palette.text.primary
  },
  inputDiv: {
    display: 'flex',
    padding: '32px 0px 4px'
  },
  otpInputField: {
    width: 300,
    marginRight: 12,
    [theme.breakpoints.down('lg')]: {
      width: 250
    },
    [theme.breakpoints.down('md')]: {
      width: 200
    },
    [theme.breakpoints.down('sm')]: {
      width: 150
    }
  },
  input: {
    padding: 12,
    fontSize: 18,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: 10,
      fontSize: 16
    },
    [theme.breakpoints.down('md')]: {
      padding: 8,
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
      fontSize: 12
    },
    '&:disabled': {
      background: '#c5c6c8',
      color: '#808080'
    }
  },
  error: {
    color: '#f54040'
  },
  button: {
    color: '#ffffff',
    padding: '8px 32px',
    height: 46,
    backgroundColor: '#497CAC',
    '&:hover': {
      backgroundColor: '#497CACB5'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '6px 28px',
      height: 40
    },
    [theme.breakpoints.down('md')]: {
      height: 32
    },
    [theme.breakpoints.down('sm')]: {
      height: 30
    },
    '&:disabled': {
      backgroundColor: '#ffffff1f',
      color: '#ffffff4d'
    }
  },
  otpText: {
    paddingTop: 32,
    color: theme.palette.color15.default
  },
  doneText: {
    padding: '12px 0px 20px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      padding: '8px 0px 12px'
    }
  },
  doneButton: {
    padding: '8px 48px',
    marginTop: 36,
    color: '#ffffff',
    backgroundColor: '#497CAC',
    '&:hover': {
      backgroundColor: '#497CACB5'
    }
  },
  errorDiv: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '1px solid #f5c6cb',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '4px',
    textAlign: 'center',
    margin: '-12px -16px 12px -16px',
    [theme.breakpoints.down('lg')]: {
      padding: '3px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px'
    }
  },
  errorDivText: {
    color: '#721c24'
  }
});
