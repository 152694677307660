import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Input, List, Paper, MenuItem, InputAdornment, FormControl, Button, CircularProgress, debounce } from '@mui/material';
import { getUniqueKey, getSegment, getBseScripCode, hasScripDataChanged } from "helpers/scripHelper";
import { Search, Close } from "@mui/icons-material";
import styles from './styles';
import { connect } from 'react-redux';
import { openConfirmDialog, closeConfirmDialog } from "redux/ConfirmDialog";
import Top from 'widgets/order/top';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { feedService } from 'services/feed';
import Placeholder from 'components/Placeholder';
import { CssOutlinedInput } from 'widgets/order/customfields';
import { CommonActions } from 'redux/Common';
import { getSocketPayload } from "helpers/subscription";
import useInterval from 'helpers/useInterval';
import { canCall } from 'helpers/pollingHelper';
import { quotesSchema } from 'helpers/schemaHelper';
import { WebsocketClientContext } from 'Main';

const REFRESH_INTERVAL = 1000;

export function getModifiedScrip(scrip, value) {
  const { segment, exchange, token, bseScripCode, shortCode } = scrip;
  const toReturn = {
    ...scrip,
    exchange: value ? value : exchange,
    token: (value && value === 'BSE') || (!value && exchange === 'BSE') ? bseScripCode : token,
    shortCode: value && segment === 'EQ' ? value === 'BSE' ? 'BCM' : 'NCM' : shortCode,
  }; return toReturn;
}

const exchangeValues = ["EQ", "NCM", "BCM",/* "CURR", "COMM" */]

function PriceAlert(props) {

  const { reduxState, classes, onCancel, openConfirmDialog, closeConfirmDialog, priceAlert, marketStatus } = props;
  const { action, grtThnPrc, lessThnPrc, exchange, segment, additionalInfo, alertId } = reduxState || {};

  const [currentScrip, setCurrentScrip] = useState({})
  const [scrip, setScrip] = useState({})
  const [loading, setLoading] = useState({ load: true, error: "Loading..." })
  const [scripList, setScripList] = useState([])
  const [scripName, setScripName] = useState("")
  const [anchorEl, setAnchorEl] = useState()

  const mounted = useRef();
  const socket = useContext(WebsocketClientContext);

  function getScrip(payload) {
    feedService.getFullFeed([payload]).then(feedScrips => {
      if (feedScrips.length > 0) {
        if (feedScrips[0].error) {
          setLoading({ load: true, error: (feedScrips[0].error) || "Scrip data not found" });
        } else {
          setScrip({ ...feedScrips[0] })
          setCurrentScrip({ ...feedScrips[0] })
          setLoading({ load: false, error: "" })
        }
      }
    }).catch(error => {
      setLoading({ load: true, error: (error.length > 0 && error[0].error) || "Scrip data not found" })
    })
  }

  useEffect(() => {
    if (reduxState && Object.keys(reduxState).length > 0) {
      if (action === 'U') {
        getScrip(reduxState)
      } else {
        setScrip(reduxState)
        setCurrentScrip(reduxState)
        setLoading({ load: false, error: "" })
      }
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    if (currentScrip && Object.keys(currentScrip).length > 0) {
      if (canCall(currentScrip.shortCode) && socket.connected) {//Socket
        const payload = getSocketPayload([currentScrip]);
        socket.emit('feed:subscribe', {
          request: 'SUBSCRIBE',
          message: '',
          channel: 'feed',
          topic: 'popupQuotes',
          payload: payload,
        });
        socket.on('feed/popupQuotes', onQuotes);
        return () => {
          socket.emit('feed:unsubscribe', {
            request: 'UNSUBSCRIBE',
            message: '',
            channel: 'feed',
            topic: 'popupQuotes',
            payload: payload,
          });
          socket.off('feed/popupQuotes', onQuotes)
        };
      }
    }
  }, [currentScrip, socket.connected, marketStatus]);

  const onQuotes = data => {
    const msg = quotesSchema.decode(data);
    const feedData = msg;
    if (currentScrip.token === feedData.token && currentScrip.shortCode === feedData.shortCode) {
      if (hasScripDataChanged(feedData, currentScrip)) {
        if (mounted.current) {
          const updatedData = { ...currentScrip, ...feedData };
          setScrip(updatedData)
        }
      }
    }
  };

  useInterval(() => {
    if (socket.disconnected) {
      if (currentScrip && Object.keys(currentScrip).length > 0 && canCall(currentScrip.shortCode)) {
        getScrip(currentScrip);
      }
    }
  }, REFRESH_INTERVAL);

  const handleSearch = (value) => {
    if (value && value.length > 1) {//TODO: 2-> 1
      setLoading({ scripError: true, error: "Searching..." })
      axios.post(`${process.env.REACT_APP_API_URL}/ms-web-search/search`, { text: value, segment: 'all', isTrading: true }).then(resp => {
        if (resp.data.success) {
          resp.data.success.length > 0 ? searchSuccess(resp.data.success) : setLoading({ scripError: true, error: "No Suggestions found" })
        } else {
          setLoading({ scripError: true, error: resp.data.error })
        }
      }).catch(error => {
        setLoading({ scripError: true, error: "Please try again later" })
        setScripList([])
      })
    } else if (value === '') {
      setScripList([]);
      setLoading({ scripError: true, error: "" })
    }
  }

  const debounceHandleSearch = useCallback(debounce(handleSearch, 1000), []);

  const searchSuccess = (list) => {
    setScripList(list)
    setLoading({ scripError: true, error: "" })
  }

  Yup.addMethod(Yup.number, "isDecimal", function (args) {
    const message = args;
    return this.test("isDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        var splitted = String(options.originalValue).split(".");
        if (splitted.length === 2) {
          if (splitted[1].length === 0) {
            return createError({ path: path, message: "Value is expected after decimal" })
          } else if (scrip && scrip.segment === "CURR") {
            if (splitted[1].length > 4) {
              return createError({ path: path, message: "Maximum four decimal places are allowed" })
            } else {
              return true
            }
          } else if (splitted[1].length > 2) {
            return createError({ path: path, message: "Maximum two decimal places are allowed" })
          } else {
            return true
          }
        } else if (splitted.length === 1) {
          return true;
        }
      }
      return createError({ path: path, message: message })
    })
  })

  let schema = Yup.object().shape({
    belowPrice: Yup.number()
      .when('abovePrice', {
        is: val => !val,
        then: Yup.number().typeError("Invalid number")
          .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
          .max(10000000, "Price should be less than 10000000")
          .required("Price is required")
          .isDecimal(),
        otherwise: Yup.number()
          .notRequired()
      }),
    abovePrice: Yup.number()
      .when('belowPrice', {
        is: val => !val,
        then: Yup.number().typeError("Invalid number")
          .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
          .max(10000000, "Price should be less than 10000000")
          .required("Price is required")
          .isDecimal(),
        otherwise: Yup.number()
          .notRequired()
      }),
    note: Yup.string()
      .min(3, 'Minimum 3 characters required')
      .max(25, 'Maximum characters limit exceeded')
      .notRequired(),
  }, ['belowPrice', 'abovePrice', 'note']);


  const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched, resetForm } = useFormik({
    initialValues: {
      belowPrice: action !== 'C' ? lessThnPrc : '',
      abovePrice: action !== 'C' ? grtThnPrc : '',
      exchange: (segment && exchangeValues.includes(segment)) ? exchange : 'NSE',
      note: action !== 'C' ? additionalInfo : ''
    },
    validationSchema: schema,
    onSubmit: values => {
      handlePriceAlert()
    }
  })

  function handlePriceAlert() {
    setLoading({ submitting: true, load: false, error: "" })
    let payload = {
      action: action || 'C',
      shortCode: scrip?.shortCode || '',
      scripId: scrip?.scripId || '',
      token: scrip?.token || '',
      grtThnPrc: values.abovePrice || 0,
      lessThnPrc: values.belowPrice || 0,
      additionalInfo: values.note || ''
    };
    if (action === 'U') {
      payload.alertId = alertId;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/ms-alert-notification/alert/price`, payload).then(resp => {
      if (resp.data.success) {
        setLoading({ load: false, error: "", submitting: false })
        setScrip({})
        onCancel()
        let obj = {
          content: resp.data.success || "--",
          hideCloseButton: true,
          okButtonText: "Ok",
          centerActions: true,
          onClose: () => {
            CommonActions.setPriceAlert()
            closeConfirmDialog()
          }
        }
        openConfirmDialog(obj)
      }
      else {
        setLoading({ submitError: true, error: resp.data.error, submitting: false })
      }
    }).catch(error => {
      setLoading({ submitError: true, error: "There is some technical issue, please try again later", submitting: false })
    })
  }

  const scripSearch = () => {
    return (
      <>
        <Input
          id="search"
          name="search"
          autoComplete="off"
          value={scripName}
          style={{ width: "100%" }}
          placeholder={"Search"}
          onClick={e => setAnchorEl(e.currentTarget)}
          onChange={(e) => { setScripName(e.currentTarget.value); setLoading({ scripError: false, error: "" }); setScripList([]); debounceHandleSearch(e.currentTarget.value) }}
          endAdornment={scripName.length === 0 ?
            <InputAdornment position="end">
              <Search disabled />
            </InputAdornment>
            :
            <InputAdornment position="end">
              {scripName.length > 2 && <Close style={{ cursor: "pointer" }} onClick={() => { setScripName(""); setLoading({ scripError: false, error: "" }); setScripList([]) }} />}
            </InputAdornment>}
        />
        {loading.scripError && scripList.length === 0 && <Typography color="primary">{loading.error}</Typography>}
        {
          scripList.length > 0 && <Paper elevation={2}><List className={classes.searchList} style={anchorEl ? { width: anchorEl.offsetWidth, } : {}}>
            {
              scripList.map((ele, index) => (
                <MenuItem key={index} tabIndex={0} className={classes.item} onClick={getUniqueKey(scrip) == getUniqueKey(ele) ? () => { setScripName(""); setScripList([]) } : () => { setScrip(ele); setScripName(ele.scripName); setScripList([]) }}>
                  <div>
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: "4px" }}
                      className={classes.itemText}>
                      {ele.scripName || ele.symbol}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={[classes.itemText, classes.itemSubText].join(" ")}>
                      {ele.exchange} <span style={{ paddingLeft: "4px", paddingRight: "4px" }}>{getSegment(ele)} </span>{getBseScripCode(ele)}
                    </Typography>
                  </div>
                </MenuItem>
              ))
            }
          </List></Paper>
        }
      </>
    )
  }

  const topGrid = () => {
    return (
      <Grid container>
        <Grid item lg={action === 'U' ? 12 : 8} md={action === 'U' ? 12 : 9} sm={action === 'U' ? 12 : 8} className={classes.top}>
          <Top
            reduxForm={{}}
            selectedScrip={scrip || {}}
            activeStep={0}
            values={values}
            showExchange={false}
            compactView={false}
            isMarketIndex={false}
          />
        </Grid>
        {action === 'C' && <Grid item lg={4} md={3} sm={4}>
          <Grid container className={classes.listContainer}>
            <Grid item md={12} sm={12} className={classes.searchGrid}>
              {scripSearch()}
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    )
  }

  function resetFormValues() {
    if (action === 'C') {
      return { belowPrice: '', abovePrice: '', note: '' }
    } else {
      return { belowPrice: lessThnPrc || '', abovePrice: grtThnPrc || '', note: additionalInfo || '' }
    }
  }

  const alertForm = () => {
    return (
      <Grid container className={classes.form}>
        {((action === 'U' && grtThnPrc) || action === 'C') && <Grid item xs={((action === 'U' && lessThnPrc) || action === 'C') ? 6 : 12}>
          <FormControl variant="outlined" className={classes.fieldDiv} style={action === 'U' ? {} : { paddingRight: 20 }}>
            <Typography className={classes.fieldText}>Price = Or Above</Typography>
            <CssOutlinedInput
              id="abovePrice"
              name="abovePrice"
              classes={{ input: classes.input }}
              onChange={handleChange}
              error={touched.abovePrice && errors.abovePrice ? true : false}
              value={values.abovePrice}
              onKeyUp={() => setFieldTouched("abovePrice", true)}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
            {errors.abovePrice && touched.abovePrice && (<p className={classes.errorText}>{errors.abovePrice}</p>)}
          </FormControl>
        </Grid>}
        {((action === 'U' && lessThnPrc) || action === 'C') && <Grid item xs={((action === 'U' && grtThnPrc) || action === 'C') ? 6 : 12}>
          <FormControl variant="outlined" className={classes.fieldDiv} style={(action === 'C' || grtThnPrc) ? { paddingLeft: 20 } : {}}>
            <Typography className={classes.fieldText}>Price = Or Below</Typography>
            <CssOutlinedInput
              id="belowPrice"
              name="belowPrice"
              autoFocus={true}
              classes={{ input: classes.input }}
              onChange={handleChange}
              error={touched.belowPrice && errors.belowPrice ? true : false}
              value={values.belowPrice}
              onKeyUp={() => setFieldTouched("belowPrice", true)}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
            {errors.belowPrice && touched.belowPrice && (<p className={classes.errorText}>{errors.belowPrice}</p>)}
          </FormControl>
        </Grid>}
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.fieldDiv} style={{ paddingTop: 8 }}>
            <Typography className={classes.fieldText}>Add a note (optional)</Typography>
            <CssOutlinedInput
              id="note"
              name="note"
              multiline={true}
              rows={3}
              inputProps={{ className: classes.input }}
              style={{ padding: 0 }}
              onChange={handleChange}
              error={touched.note && errors.note ? true : false}
              value={values.note}
              onKeyUp={() => setFieldTouched("note", true)}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
            <div>
              {errors.note && touched.note && (<p className={classes.errorText}>{errors.note}</p>)}
              <Typography align="right" variant="caption" className={classes.defaultText}>Maximum 25 characters only</Typography>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item md={3} xs={4} style={{ marginRight: 12 }}>
              <Button onClick={() => resetForm({ values: resetFormValues() })} disabled={loading.submitting} className={classes.cancel}>Reset</Button>
            </Grid>
            <Grid item md={loading.submitting ? 4 : 3} xs={4}>
              <Button type={"submit"} autoFocus={true} disabled={loading.submitting} className={[classes.order, classes.containedBlue].join(" ")}>{loading.submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}{action === "C" ? "Set Alert" : "Update Alert"}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <form autoComplete={"off"} onSubmit={handleSubmit} className={action === 'U' ? classes.modifyForm : classes.root}>
      {
        loading.load ?
          <div className={classes.loadingDiv}>
            <Placeholder
              loading={loading.error === "Loading..." ? true : false}
              loadingMsg={""}
              error={loading.error === "Loading..." ? "" : loading.error}
            />
          </div>
          :
          <Grid container>
            <Grid item xs={12}>
              {loading && loading.submitError && <div className={classes.errorDiv}><Typography className={classes.errorDivText}>{loading.error}</Typography></div>}
              {topGrid()}
            </Grid>
            {
              loading && loading.searchError ?
                <div className={classes.searchErrorDiv}>
                  <Placeholder
                    loading={false}
                    loadingMsg={""}
                    error={loading.error}
                  />
                </div>
                :
                alertForm()
            }
          </Grid>
      }
    </form>
  );
}

const mapStateToProps = (state) => {
  const { common: { priceAlert, marketStatus } } = state;
  return { reduxState: state.dialog.data || { action: 'C' }, priceAlert, marketStatus }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PriceAlert))
