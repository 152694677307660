import { getDate } from "helpers/dateHelper";
import { notMarketHours } from "helpers/pollingHelper";
import { getUniqueKey, hasScripDataChanged } from "helpers/scripHelper";
import { store } from 'helpers/store';
import { LayoutActions } from "redux/Layout";
import { PrefsService } from "services/prefs";

const SERVER_TIME = 'SERVER_TIME';
const SCHEME_SELECTED = 'SCHEME_SELECTED';
const SCRIP_SELECTED = 'SCRIP_SELECTED';
const SCRIP_SELECTED_UPDATE = 'SCRIP_SELECTED_UPDATE';
const SEARCH_OPEN = 'SEARCH_OPEN';
const ON_KEY_PRESSED = 'ON_KEY_PRESSED';
const ON_ACTIVE_TAB_SCRIP = 'ON_ACTIVE_TAB_SCRIP';
const ON_FILTER = 'ON_FILTER';
const ORDER_VIEW = 'ORDER_VIEW';
const MARKET_STATUS = 'MARKET_STATUS';
const UPDATE_MARKET_STATUS = 'UPDATE_MARKET_STATUS';
const SET_HOLIDAYS = 'SET_HOLIDAYS';
const UPDATE_HOLIDAYS = 'UPDATE_HOLIDAYS';
const SEARCH_SELECTED_SCRIP = 'SEARCH_SELECTED_SCRIP';
const QUOTE_SELECTED_SCRIP = 'QUOTE_SELECTED_SCRIP';
const SET_PATH_NAME = 'SET_PATH_NAME';
const SET_FUND_DETAILS = 'SET_FUND_DETAILS';
const SET_REFRESH_REPORT = 'SET_REFRESH_REPORT';
const SET_PRICE_ALERT = 'SET_PRICE_ALERT';

export const CommonActions = {
    setServerTime,
    onScripSelected,
    onSchemeSelected,
    updateScripSelected,
    setSearchOpen,
    onKeyPressed,
    onActiveTabScrip,
    setFilter,
    setOrderFormView,
    setMarketStatus,
    updateMarketStatus,
    setHolidays,
    updateHolidays,
    setSearchSelectedScrip,
    setQuoteSelectedScrip,
    setPathName,
    setFundDetails,
    setRefreshReport,
    setPriceAlert
};
//ACTIONS
function setServerTime(data) {
    store.dispatch({ type: SERVER_TIME, data });
}

function onScripSelected(data) {    // console.log('onScripSelected\n' + JSON.stringify(data));
    let selectedScripIndex = -1;
    store.getState().watchlist.scrips.filter((scrip, index) => {
        if (getUniqueKey(scrip) == getUniqueKey(data)) {
            selectedScripIndex = index;
        }
    });
    store.dispatch({ type: SCRIP_SELECTED, data, selectedScripIndex });
    // LayoutActions.setChartData(data);
}

function updateScripSelected(data) {
    // console.log(JSON.stringify(data[0]));
    // debugger
    store.dispatch({ type: SCRIP_SELECTED_UPDATE, data });
}

function onSchemeSelected(data) {
    store.dispatch({ type: SCHEME_SELECTED, data });
}

function setSearchOpen(flag) {
    if (flag != store.getState().common.searchOpen)
        store.dispatch({ type: SEARCH_OPEN, flag });
}

function setFundDetails(data) {
    store.dispatch({ type: SET_FUND_DETAILS, data });
}

function onKeyPressed(key) {
    let keyName;
    switch (key) {
        case 'ArrowUp':
            keyName = 'keyUpPressCount';
            break;
        case 'ArrowDown':
            keyName = 'keyDownPressCount';
            break;
        case 'ArrowLeft':
            keyName = 'keyLeftPressCount';
            break;
        case 'ArrowRight':
            keyName = 'keyRightPressCount';
            break;
    }
    store.dispatch({ type: ON_KEY_PRESSED, keyName });
}

function onActiveTabScrip(data) {
    store.dispatch({ type: ON_ACTIVE_TAB_SCRIP, data })
}

function setFilter(flag) {
    if (flag != store.getState().common.showFilter)
        store.dispatch({ type: ON_FILTER, flag })
}

function setOrderFormView(data) {
    store.dispatch({ type: ORDER_VIEW, data })
}

function setMarketStatus(data) {
    store.dispatch({ type: MARKET_STATUS, data })
}

function updateMarketStatus(data) {
    store.dispatch({ type: UPDATE_MARKET_STATUS, data })
}

function setHolidays(data) {
    let holidays = {};
    if (data) {
        for (const key in data) {
            const { tradingHolidayInd, marketStartTime, marketEndTime } = data[key];
            const holiday =/* true;// */ tradingHolidayInd && tradingHolidayInd.toLowerCase() == 'y';
            const start = getDate(marketStartTime);
            const end = getDate(marketEndTime);
            const startMil = start.getTime();
            const endMil = end.getTime();
            holidays[key] = {
                status: !holiday && !notMarketHours(startMil, endMil),
                holiday,
                start: marketStartTime,
                end: marketEndTime,
                startMil,
                endMil
            };
            // debugger
        }
        // debugger
        console.log('holidays', holidays);
        store.dispatch({
            type: SET_HOLIDAYS,
            holidays
        });
    }
}

function updateHolidays(shortCode, status) {
    if (shortCode) {
        store.dispatch({
            type: UPDATE_HOLIDAYS,
            shortCode,
            status
        })
    }
}

function setSearchSelectedScrip(data) {
    store.dispatch({ type: SEARCH_SELECTED_SCRIP, data })
}

function setQuoteSelectedScrip(data) {
    store.dispatch({ type: QUOTE_SELECTED_SCRIP, data })
}

function setPathName(data) {
    store.dispatch({ type: SET_PATH_NAME, data })
}

function setRefreshReport(data) {
    store.dispatch({ type: SET_REFRESH_REPORT, data })
}

function setPriceAlert() {
    store.dispatch({ type: SET_PRICE_ALERT })
}

//REDUCER
export function commonReducer(state = initialState, action) {
    switch (action.type) {
        case SERVER_TIME:
            return {
                ...state,
                serverTime: action.data,
            };
        case SCRIP_SELECTED:
            return {
                ...state,
                selectedScrip: action.data,
                selectedScripIndex: action.selectedScripIndex,
            };
        case SCRIP_SELECTED_UPDATE:
            let updatedScrip = action.data.find(x => getUniqueKey(x) == getUniqueKey(state.selectedScrip));
            if (updatedScrip && hasScripDataChanged(updatedScrip, state.selectedScrip)) {
                return {
                    ...state,
                    selectedScrip: updatedScrip
                };
            }
            return state;
        case SCHEME_SELECTED:
            return {
                ...state,
                selectedScheme: action.data
            };
        case SEARCH_OPEN:
            return {
                ...state,
                searchOpen: action.flag
            };
        case ON_KEY_PRESSED:
            return {
                ...state,
                [action.keyName]: state[action.keyName] + 1
            };
        case ON_ACTIVE_TAB_SCRIP:
            return {
                ...state,
                activeTabScrip: action.data
            };
        case ON_FILTER:
            return {
                ...state,
                showFilter: action.flag
            };
        case ORDER_VIEW:
            return {
                ...state,
                orderView: action.data
            };
        case MARKET_STATUS:
            if (JSON.stringify(state.marketStatus) !== JSON.stringify(action.data)) {
                return {
                    ...state,
                    marketStatus: action.data,
                    // market: action.market,
                };
            } else {
                return {
                    ...state,
                    // marketStatus: action.data,
                    // market: action.market,
                };
            }
        case UPDATE_MARKET_STATUS:
            return {
                ...state,
                marketStatus: { ...state.marketStatus, [action.data.key]: action.data.data },
            };
        case SET_HOLIDAYS:
            return {
                ...state,
                holidays: action.holidays,
            };
        case UPDATE_HOLIDAYS:
            return {
                ...state,
                holidays: {
                    ...state.holidays,
                    [action.shortCode]: {
                        ...state.holidays[action.shortCode],
                        status: action.status
                    }
                },
            };
        case SEARCH_SELECTED_SCRIP:
            return {
                ...state,
                searchSelectedScrip: action.data
            };
        case QUOTE_SELECTED_SCRIP:
            return {
                ...state,
                quoteSelectedScrip: action.data
            };
        case SET_PATH_NAME:
            return {
                ...state,
                pathName: action.data
            };
        case SET_FUND_DETAILS:
            return {
                ...state,
                fundDetails: action.data
            };
        case SET_REFRESH_REPORT:
            return {
                ...state,
                refreshReport: action.data
            };
        case SET_PRICE_ALERT:
            return {
                ...state,
                priceAlert: ++state.priceAlert
            };
        default:
            return state;
    }
}

const initialState = {
    serverTime: 0,
    holidays: {},
    marketStatus: {},
    searchOpen: false,
    selectedScrip: {},
    selectedScripIndex: -1,
    selectedScheme: {},
    keyUpPressCount: 0,
    keyDownPressCount: 0,
    keyRightPressCount: 0,
    keyLeftPressCount: 0,
    activeTabScrip: {},
    showFilter: false,
    orderView: PrefsService.getOrderView() === 2 ? 2 : 1,
    searchSelectedScrip: {},
    quoteSelectedScrip: {},
    pathName: '',
    fundDetails: {},
    refreshReport: "",
    priceAlert: 0
};
