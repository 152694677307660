import { Info as InfoIcon } from '@mui/icons-material';
import { Divider, List, ListItem, Paper, Tooltip, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import ClientSearchBox from "components/Search/ClientSearchBox";
import axios from "helpers/interceptor";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        minHeight: '50vh',
        height: '100%',
        width: "30vw",
        margin: '10px -5px',
        backgroundColor: theme.palette.background.secondary,
    },
    itemRoot: {
        display: "flex",
        flexDirection: "row",
        padding: "10px 20px",
        [theme.breakpoints.down('xl')]: {
            padding: "6px 16px",
        },
        [theme.breakpoints.down('md')]: {
            padding: "4px 12px"
        }
    },
    itemDivider: {
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
    },
    textDefault: {
        color: theme.palette.text.primary,
        fontWeight: "normal",
        display: "flex",
        alignItems: "center",
    },
    textSecondary: {
        color: theme.palette.text.secondary,
        fontWeight: "500",
    },
    textRed: {
        color: theme.palette.primary.main,
        // fontWeight: "500",
    },
    textDefaultBold: {
        fontSize: 18,
        color: theme.palette.text.primary,
        fontWeight: "bold",
        [theme.breakpoints.down('xl')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10
        }
    },
    MuiDivider: {
        root: {
            margin: 10
        }
    },
    icon: {
        marginLeft: 5,
    },
}));

function LimitStatement(props) {
    const {
        selectedScrip: { open, low, high, close, avgPrice },
        selectedClient,
        layoutType
    } = props;

    const ClientCode = selectedClient && selectedClient.ClientCode;

    const classes = useStyles();

    const [data, setData] = useState({});
    const [clientCode, setClientCode] = useState(ClientCode);


    let isAdmin = `${process.env.REACT_APP_USER}` == "admin" || layoutType == "nextadmin";

    useEffect(() => {
        if (!isAdmin || isAdmin && clientCode) {
            let url = !isAdmin ? "/limit" : `/limit/${clientCode}`;
            axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REPORTS_URI}${url}`).then(resp => {
                if (resp.data.success) {
                    setData(resp.data.success)
                }
            });
        }
    }, [clientCode]);

    const getItem = (key, value, showDivider, tSecondary, vBold, titleBold, tooltip) => {
        let keyClass = /* tSecondary ? classes.textSecondary : */ classes.textDefault,
            valueClass = /* value < 0 ? classes.textRed : */
                vBold ? classes.textDefaultBold : classes.textDefault;
        return (
            <>
                <ListItem className={classes.itemRoot}>
                    <Typography className={keyClass} style={{ flex: 1, fontWeight: titleBold ? "bold" : "normal" }}>
                        {key}
                        {tooltip && <Tooltip title={tooltip}>
                            <InfoIcon className={classes.icon} />
                        </Tooltip>}
                    </Typography>
                    <Typography
                        className={valueClass}>
                        {!isNaN(parseFloat(value)) ? parseFloat(value).toLocaleString('en-IN') : " - "}
                    </Typography>
                </ListItem>
                {showDivider && <Divider className={classes.itemDivider} />}
            </>
        )
    }

    const { openBal, cashCollateral, intraFundTran, payoutReq, intradayBPL,
        openMTM, limitAgShares, prevSettLimit, ncl, blockMarginCASH,
        blockMarginFNO, optPremium, exchSpecialMarg, netAmout, mfMarginUtilised, blockFund, brokerage
    } = data;

    return (
        <Paper className={classes.root}>
            {isAdmin &&
                <div>
                    <ClientSearchBox
                        placeholder={clientCode/*  && selectedClient.ClientName */}
                        setClient={false}
                        onSelect={(item) => {
                            if (item && item.ClientCode) {
                                setClientCode(item.ClientCode);
                            }
                        }}
                    />
                    <Divider className={classes.itemDivider} />
                </div>
            }
            {(!isAdmin || isAdmin && clientCode) &&
                <List>
                    {getItem("Opening Balance", openBal, true, true)}
                    {getItem("Cash Collateral", cashCollateral, true, true)}
                    {getItem("Intraday Funds Transfer", intraFundTran, true, true)}
                    {getItem("Payout Request", payoutReq, true, true)}
                    {getItem("Blocked Fund", blockFund, true, true, false, false, 'Provision for debits like MTF, DPC and Demat Charges etc.')}
                    {getItem("Limit against shares", limitAgShares, true, true)}
                    {getItem("Non Cash Limit", ncl, true, true)}
                    {getItem("Block Margin (Mutual Fund)", mfMarginUtilised, true, true)}
                    {getItem("Intraday BPL", intradayBPL, true, true)}
                    {/* {getItem("Open MTM", openMTM, true, true)} */}
                    {getItem("Previous Settlement Margin", prevSettLimit, true, true)}
                    {getItem("Intraday Block Margin (Cash)", blockMarginCASH, true)}
                    {getItem("Intraday Block Margin (FNO)", blockMarginFNO, true)}
                    {getItem("Option Premium", optPremium, true)}
                    {getItem("Exchange Special Margin", exchSpecialMarg, true, true)}
                    {getItem("Brokerage", brokerage, true)}
                    {getItem("Net Amount", netAmout, false, false, true, true)}
                </List>
            }
        </Paper>
    );
}

LimitStatement.defaultProps = {
    selectedScrip: {}
};

const mapStateToProps = (state) => {
    const { client: { selectedClient }, layout: { layoutType }} = state;
    return { selectedClient, layoutType };
}

export default connect(mapStateToProps)(LimitStatement);
