export default (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '20vh',
    maxHeight: '80vh',
    height: '100%',
    width: '600px',
    margin: '10px -5px',
    backgroundColor: theme.palette.background.secondary
  },
  itemRoot: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    [theme.breakpoints.down('xl')]: {
      padding: '6px 16px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 12px'
    }
  },
  itemDivider: {
    borderBottom: `1px solid ${theme.palette.text.secondary}`
  },
  textDefault: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    textAlign: 'center',
    padding: '20px',
    marginTop: '10px'
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    fontWeight: '500'
  },
  textRed: {
    color: theme.palette.primary.main
    // fontWeight: "500",
  },
  textDefaultBold: {
    fontSize: 18,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10
    }
  },
  MuiDivider: {
    root: {
      margin: 10
    }
  },
  icon: {
    marginLeft: 5
  },
  buttonDiv: {
    textAlign: 'center',
    margin: 10,
    padding: 10
  },
  buttonColor: {
    margin: 5,
    '&:disabled': {
      background: '#c5cbce',
      color: '#7d7d7d'
    }
  },
  cellText: {
    color: 'inherit',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  errorDiv: {
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c6cb',
    borderRadius: '4px',
    padding: '4px',
    textAlign: 'center',
    margin: '0px 8px',
    [theme.breakpoints.down('xl')]: {
      padding: '3px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '2px'
    }
  },
  errorDivText: {
    color: '#721c24'
  },
  description: {
    textAlign: 'justify',
    padding: "8px 12px",
    color: theme.palette.color15.default
  }
});
