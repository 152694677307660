export default ({ palette }) => ({
  styleOverrides: {
    root: {
      backgroundColor: 'transparent'
    },
    inputRoot: {
      backgroundColor: palette.color.default,
      '&&[class*="MuiOutlinedInput-root"] $input': {
        padding: 1
      }
    },
    input: {
      color: palette.text.primary
    },
    listbox: {
      color: palette.text.primary,
      backgroundColor: palette.color.default
    },
    noOptions: {
      color: palette.text.primary,
      backgroundColor: palette.color.default
    }
  }
});
