export const ACCESS_TOKEN = "token";
export const REFRESH_TOKEN = "refresh_token";
export const TWOFA = "2FA";
export const R_ACCESS_TOKEN = "r_token";
export const R_REFRESH_TOKEN = "r_refresh_token";
export const T_ACCESS_TOKEN = "t_token";
export const T_REFRESH_TOKEN = "t_refresh_token";
export const REFERER = "referer";

let envProd = null;
// let isTradingClient = false;

export function setTokens(accessToken, refreshToken) {
  let accessTokenKey = R_ACCESS_TOKEN, refreshTokenKey = R_REFRESH_TOKEN;
  let tokenDetails = JSON.parse(atob(accessToken.split(".")[1]));
  if (tokenDetails && tokenDetails.isTradingClient) {
    accessTokenKey = T_ACCESS_TOKEN;
    refreshTokenKey = T_REFRESH_TOKEN;
  }
  accessToken && setCookieNew(accessTokenKey, accessToken);
  refreshToken && setCookieNew(refreshTokenKey, refreshToken);
}

export function getToken(isAccessToken) {
  return isAccessToken
    ?
    getCookie(R_ACCESS_TOKEN) || getCookie(T_ACCESS_TOKEN) || ""
    :
    getCookie(R_REFRESH_TOKEN) || getCookie(T_REFRESH_TOKEN) || "";
}

export function deleteTokens() {
  deleteCookie(R_ACCESS_TOKEN);
  deleteCookie(R_REFRESH_TOKEN);
  deleteCookie(T_ACCESS_TOKEN);
  deleteCookie(T_REFRESH_TOKEN);
  deleteCookie(TWOFA);
}

export function set2FA(twoFA) {
  setCookieNew(TWOFA, twoFA);
}

export function get2FA() {
  return getCookie(TWOFA);
}

export function setReferer() {
  let referer = window.location.href.split("?referer=")[1];
  if (referer) {
    setCookieNew(REFERER, referer);
  }
}

export function getReferer() {
  return getCookie(REFERER);
}

export function setCookie(cname, cvalue, expiry, path, domain) {
  var expires = "max-age=" + expiry;
  var dm = domain ? "domain=" + domain : ""
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path + ";" + dm;
}

export function getCookie(cName) {
  var name = cName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookieNew(cName, cValue) {
  if (!isProd()) {
    //Dev
    document.cookie = cName + "=" + cValue + ";path=/;domain=tradebulls.in";
  } else {
    //Prod
    document.cookie = cName + "=" + cValue + ";path=/;secure;domain=tradebulls.in";
  }
}

export function deleteCookie(cName) {
  var d = new Date();
  // console.log("Before:", d.getTime())
  d.setTime(d.getTime() - 360000);
  // console.log("After:", d.getTime())
  var expires = "expires=" + d.toUTCString();
  var value = cName + "=" + "" + ";" + expires + ";path=/" + (isProd() ? ";secure;domain=tradebulls.in" : "")
  // console.log("Delete Cookie:", value, d.getTime())
  document.cookie = value;
}

function isProd() {
  if (envProd == null) {
    envProd = process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'production';
  }
  return envProd;
}

export default getCookie;