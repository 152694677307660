export default theme => ({
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  value: {
    color: theme.palette.color12.default,
    padding: "4px 0px",
    fontWeight: 600,
    "&.negative": {
      color: theme.palette.priceColor.red
    },
    "&.positive": {
      color: theme.palette.priceColor.green
    },
    "&.neutral": {
      color: "#FFC300"
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '11px',
      lineHeight: '14px'
    },
    [theme.breakpoints.down('md')]: {
      padding: "0px",
    },
  },
  valueText: {
    // fontSize: "12px",
    color: theme.palette.color15.default,
    [theme.breakpoints.only('md')]: {
      fontSize: '11px',
      lineHeight: '14px'
    },
  },
  rupee: {
    fontFamily: "Arial, sans-serif",
    // color: theme.palette.color12.default,
    padding: "0px"
  },
  span: {
    borderRadius: "15px",
    padding: "0 5px",
    fontSize: "14px",
    marginLeft: "8px",
    color: theme.palette.color12.default,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '16px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '11px',
      lineHeight: '14px',
      marginLeft: '4px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '14px',
      marginLeft: '8px'
    }
  },
  legendColor: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "50%",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5)
    },
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      marginTop: theme.spacing(1.5),
      marginLeft: "4px"
    },
  },
  legendValue: {
    marginTop: theme.spacing(1),
    color: theme.palette.color12.default,
    [theme.breakpoints.only('lg')]: {
      marginTop: "6px",
      fontSize: "10px",
      lineHeight: "14px"
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "8px",
      fontSize: "9px",
      lineHeight: "13px"
    }
  },
  textGrid: {
    flexDirection: "column",
    borderRight: `1px dashed ${theme.palette.color15.default}`
  },
  loader: {
    color: theme.palette.text.primary
  },
  loaderDiv: {
    height: "28px",
    [theme.breakpoints.down('xl')]: {
      height: "24px",
    },
    [theme.breakpoints.down('md')]: {
      height: "15px",
    },
  },
  totalValue: {
    fontSize: 14,
    marginLeft: "10px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      fontSize: 12,
      marginLeft: "6px",
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 10,
      marginLeft: "4px"
    }
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  countBorder: {
    borderLeft: `1px dashed ${theme.palette.color15.default}`,
    display: "flex",
    justifyContent: "space-between"
  },
  count: {
    fontSize: 14,
    marginLeft: "10px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      fontSize: 12,
      marginLeft: "4px",
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 10,
      marginLeft: "2px"
    }
  },
  countGrid: {
    marginTop: "6px",
    marginBottom: "4px",
    display: "flex",
    justifyContent: "space-between",
  }
})