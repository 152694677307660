export default (theme) => ({
  root: {
    padding: 40,
    margin: '8px 0px',
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 0px 12px 0px ${theme.palette.color14.primary}`,
    border: `1px solid ${theme.palette.color14.primary}`,
    borderRadius: '4px',
    width: '64vw',
    [theme.breakpoints.down('lg')]: {
      padding: 28
    },
    [theme.breakpoints.down('md')]: {
      width: '75vw',
      padding: 20
    },
    [theme.breakpoints.down('sm')]: {
      width: '75vw'
    }
  },
  headerDiv: {
    borderBottom: `2px solid #666666`,
    marginBottom: 28,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 20
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 16
    }
  },
  listBullet: {
    listStyle: 'none',
    paddingInlineStart: 16,
    marginLeft: '3px',
    '&>li': {
      display: 'flex',
      paddingBottom: 12,
      '&:before': {
        content: "'\\2022'",
        display: 'flex',
        fontSize: '2em',
        marginLeft: '-1em',
        color: '#299D00',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  },
  listText: {
    paddingLeft: 20,
    fontSize: 18,
    [theme.breakpoints.down('xl')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 16,
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 12
    }
  },
  link: {
    color: '#497CAC',
    fontSize: 16,
    [theme.breakpoints.down('xl')]: {
      fontSize: 15
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 13,
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    }
  },
  linkDiv: {
    padding: '16px 0px 48px',
    [theme.breakpoints.down('lg')]: {
      padding: '14px 0px 36px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '12px 0px 28px'
    }
  },
  blueText: {
    color: theme.palette.common.blue,
    marginBottom: 20,
    fontSize: 32,
    [theme.breakpoints.down('xl')]: {
      fontSize: 30,
      marginBottom: 16
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    }
  },
  button: {
    color: '#fff',
    backgroundColor: theme.palette.common.blue,
    padding: '4px 40px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    }
  }
});
