import React, { useContext } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { UserProfileContext } from "Main";
import Placeholder from "components/Placeholder";

const profileKeys = {
  empCode: 'Employee Code',
  name: 'Name',
  email: 'Email ID',
  PAN: 'PAN',
  BranchCode: 'Branch Code',
  BranchName: 'Branch Name',
  Address: 'Address',
  CTCLDealerID: 'CTCL Dealer ID'
}

function AdminProfile(props) {
  const { classes } = props;
  const profile = useContext(UserProfileContext);

  return (
    <Paper elevation={2} className={classes.paper}>
      <div className={classes.list}>
        <Grid container>
          {
            Object.keys(profile)?.length > 0 ? Object.keys(profile).map(ele => (
              profileKeys[ele] && <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" className={classes.secondaryText}>
                      {profileKeys[ele]}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" className={classes.primaryText}>
                      {profile[ele] || '--'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))
              :
              <div className={classes.loadingDiv}>
                <Placeholder
                  loading={true}
                  loadingMsg={""}
                  error={''}
                />
              </div>
          }
        </Grid>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(AdminProfile);