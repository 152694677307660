import { lazy } from 'react';
import { LazyLoad } from 'components/LazyLoad';

export const OrderReportMF = LazyLoad(lazy(() => import("./order")));
export const RegisteredSIPReportMF = LazyLoad(lazy(() => import("./registeredsip")));
export const StatementsMF = LazyLoad(lazy(() => import("./statements")));
export const FundTransferReportMF = LazyLoad(lazy(() => import("./fundtransfer")));
export const TransactionHistoryMF = LazyLoad(lazy(() => import("./transactionhistory")));
export const ScheduledSIPMF = LazyLoad(lazy(() => import("./scheduledsip")));
export const BankMandateMF = LazyLoad(lazy(() => import("./bankmandate")));
export const DematHoldingsMF = LazyLoad(lazy(() => import("./dematholdings")));
export const BseBankMandateMF = LazyLoad(lazy(() => import("./bsebankmandate")));