export default ({ palette }) => ({
  styleOverrides: {
    root: {
      color: palette.common.muted,
      '&.Mui-selected': {
        backgroundColor: palette.type === 'light' ? `#00000014` : '#ffffff29',
        '&.Mui-focusVisible': {
          background: '#ffffff29'
        },
        '&:hover': {
          backgroundColor: palette.type === 'light' ? `#00000014` : '#ffffff29',
        }
      },
      '&:hover': {
        backgroundColor: palette.type === 'light' ? '#0000000a' : '#ffffff14'
      }
    }
  }
});
