import { withStyles } from '@mui/styles';
import React from 'react';

const styles = (theme) => ({
    main: { height: '100%' }
});

const DataTut = ({ tourKey, Component, classes }) => {
    // console.log('Dashboard DataTut', tourKey);
    return (
        <div data-tut={tourKey} className={classes.main}>
            <Component />
        </div>
    );
};

export default withStyles(styles)(DataTut);
