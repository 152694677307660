import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import React from "react";
import styles from "./styles";

function ConfirmOrder(props) {

  const { classes, values, handleBack, pushOrder, scheme, errorObj, switchToScheme } = props

  const valueGrid = (label, value, hideBorder) => {
    return (
      <div className={classes.valueGrid} style={hideBorder ? { borderBottom: "0px" } : {}}>
        <Typography variant="subtitle2" className={classes.defaultColor}>{label || ""}</Typography>
        <Typography variant="subtitle1" className={classes.approxValue}>{value || "--"}</Typography>
      </div>
    )
  }

  const switchConfirm = () => {
    return (
      <Grid item xs={12}>
        <Grid container className={[classes.confirmFieldSet, classes.switchConfirm].join(" ")}>
          <div className={classes.switchGrid}>
            <div className={classes.schemeDiv}>
              <Typography variant="subtitle2" className={classes.schemeHeading}>Scheme Name</Typography>
              <Typography style={{ fontWeight: 600 }} className={[classes.text, "switch"].join(" ")}>{scheme && scheme.schemeName || "--"}</Typography>
              {valueGrid("Folio No.", values.folioNumber)}
              {valueGrid("Switch Units", values.switchAmount, true)}
            </div>
            <div>
              <Typography variant="subtitle2" className={[classes.schemeHeading, "second"].join(" ")} >Switch to Scheme Name</Typography>
              <Typography style={{ fontWeight: 600 }} className={[classes.text, "switch"].join(" ")}>{switchToScheme && switchToScheme.label || ""}</Typography>
              {valueGrid("Order Date", String(new Date().toLocaleString("en-IN", { dateStyle: "medium" })).split('-').join(' '))}
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.rootConfirmGrid}>
      {
        values.type === "SWITCH" ?
          switchConfirm()
          :
          <Grid item xs={12}>
            <div className={classes.colorDiv} />
            <Grid container className={classes.confirmFieldSet}>
              <Grid item xs={12} className={classes.confirmTop}>
                <Typography variant="h3" className={classes.text}>{scheme && scheme.schemeName || "--"}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.confirmValueCont}>
                {values.folioNumber == "" || values.folioNumber == "new" ? <></> : valueGrid("Folio No.", values.folioNumber)}
                {
                  values.type === "REDEEM" ?
                    <>
                      {valueGrid(`Redeem ${values.subType === "amount" ? "Amount" : "Units"}`, `${values.subType === "amount" ? "₹ " : ""} ${values.redeemAmount}`)}
                    </>
                    :
                    <>
                      {valueGrid("Investment Amount", `₹ ${values.amount}`)}
                      {

                        values.type === "OT" ?
                          <>
                          </>
                          :
                          <>
                            {values.bankMandate == "" || values.bankMandate == "new" ? <></> : valueGrid("Bank Mandate", values.bankMandate)}
                            {valueGrid("Frequency", values.frequency)}
                            {valueGrid("Start Date", String(new Date(values.startDate).toLocaleString("en-IN", { dateStyle: "medium" })).split('-').join(' '))}
                            {valueGrid("No. of Installments", values.installments)}
                          </>
                      }

                    </>
                }
                {valueGrid("Order Date", String(new Date().toLocaleString("en-IN", { dateStyle: "medium" })).split('-').join(' '))}
                {values.type !== "SIP" && valueGrid("Expected Settlement Date", values.settlementDate && String(new Date(values.settlementDate).toLocaleString("en-IN", { dateStyle: "medium" })).split('-').join(' ') || "--")}
              </Grid>
            </Grid>
          </Grid>
      }
      <Grid item md={3} sm={3} xs={4} style={{ marginRight: "24px" }}>
        <Button onClick={handleBack} disabled={errorObj && errorObj.submitting} className={classes.cancel}>Back</Button>
      </Grid>
      <Grid item md={3} sm={3} xs={4}>
        <Button style={{ marginTop: "8px" }} disabled={errorObj && errorObj.submitting} onClick={pushOrder} className={[classes.order, classes.containedBlue].join(" ")}>{errorObj && errorObj.submitting && <CircularProgress size={20} style={{ marginRight: "12px" }} />}Confirm</Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(combineStyles(styles, commonStyles))(ConfirmOrder)