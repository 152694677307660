import { Button, Grid, Rating, Typography, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import Placeholder from 'components/Placeholder';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CommonActions } from "redux/Common";
import { DialogActions } from 'redux/Dialog';
import { fundDetailsService } from 'services/funddetails';
import { MODAL_CHILD } from 'widgets/FormDialog';
import { NumberFormatter } from "widgets/Portfolio/data";
import { formatDate } from "../funddetailsoverview";
import styles from "./styles";

function FundDetails(props) {

    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { selectedScheme, propScheme, classes, schemeFundDetails, propSchemeFundDetails, popup } = props;

    let fundDetails = propSchemeFundDetails || schemeFundDetails
    let currentScheme = propScheme || selectedScheme;
    const { schemeName, ratings, navValue, navChange, navDate, navPercentChange, isinNumber, isSipAllowed, purchaseAllowed } = currentScheme;

    async function getDetails() {
        CommonActions.setFundDetails("Loading...")
        try {
            const details = await fundDetailsService.getFundDetails({ isinNumber });
            if (details) {
                CommonActions.setFundDetails(details)
            } else {
                CommonActions.setFundDetails("No Data Found")
            }
        } catch (error) {
            CommonActions.setFundDetails("There is some technical issue, please try again later")
            console.log(error)
            return;
        }
    }

    useEffect(() => {
        if (popup) {
            // return {}
        } else if (isinNumber) {
            getDetails()
        } else {
            CommonActions.setFundDetails("Please select scheme")
        }
    }, [isinNumber])

    const openMfOrderPopup = type => {
        CommonActions.setSearchOpen(false);
        DialogActions.dialogOpen(MODAL_CHILD.MFORDER, { form: { status: 'New', type }, scheme: currentScheme });
    };

    return (
        <div className={classes.mainDiv}>
            {
                typeof (fundDetails) === "string" ?
                    <Placeholder
                        loading={fundDetails === "Loading..." ? true : false}
                        loadingMsg={""}
                        error={fundDetails === "Loading..." ? "" : fundDetails}
                    />
                    :
                    <div className={classes.root}>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} className={classes.ratingSpan} style={{ padding: 4, paddingLeft: 0, paddingBottom: 0 }}>
                                <Typography component="div" style={{ display: "flex" }}>
                                    <Typography component="div" display="inline" variant="h3" className={classes.highlight}><span>{schemeName || '--'}</span></Typography>
                                    {!matchesMd && <div className={[classes.ratingBox, classes.secondaryText].join(" ")}>
                                        <Typography variant="caption" style={{ marginLeft: "6px" }}>( Morningstar® rating:</Typography>
                                        <Rating name="read-only" value={Number(ratings)} readOnly />
                                        <Typography variant="caption">)</Typography>
                                    </div>}
                                </Typography>
                            </Grid>
                            <Grid item xl={9} lg={9} md={9} sm={9} style={{ padding: 0 }} className={classes.ratingSpan}>
                                <div>
                                    <div style={{ marginBottom: "4px" }}>
                                        {matchesMd && <span className={[classes.ratingSpan, classes.secondaryText].join(" ")}>Morningstar® rating:   <Rating style={{ marginLeft: "4px" }} name="read-only" value={Number(ratings)} readOnly /></span>}
                                    </div>
                                    <div className={classes.ratingSpan}>
                                        <div>
                                            <Typography variant="h4">
                                                <span className={classes.highlight}>₹ {(navValue && Number(navValue).toFixed(4)) || "0.0000"}</span><span className={classes.grey}>{(navChange && Number(navChange).toFixed(4)) || "0.0000"} ({(navPercentChange && Number(navPercentChange).toFixed(2)) || "0.00"}%)</span>
                                            </Typography>
                                            <div className={classes.secondaryText}>
                                                As on {(navDate && formatDate(navDate)) || "--"}
                                            </div>
                                        </div>
                                        <div className={[classes.ratingSpan, "margin"].join(" ")}>
                                            <div className={classes.textDiv}>
                                                <Typography className={classes.primaryText} variant="h6">
                                                    {(fundDetails && fundDetails.fundtop && fundDetails.fundtop[0] && fundDetails.fundtop[0].fundType) || "--"}
                                                </Typography>
                                                <Typography className={classes.secondaryText} variant="caption">Category</Typography>
                                            </div>
                                            <div className={classes.textDiv}>
                                                <Typography className={classes.primaryText} variant="h6">
                                                    {(fundDetails && fundDetails.fundtop && fundDetails.fundtop[0] && fundDetails.fundtop[0].subCategory) || "--"}
                                                </Typography>
                                                <Typography className={classes.secondaryText} variant="caption">Sub-Category</Typography>
                                            </div>
                                            <div className={[classes.textDiv, "noBorder"].join(" ")}>
                                                <Typography className={classes.primaryText} variant="h6">₹ {(fundDetails && fundDetails.fundtop && fundDetails.fundtop[0] && fundDetails.fundtop[0].fundsize && NumberFormatter(Number(fundDetails.fundtop[0].fundsize))) || "0.00"}</Typography>
                                                <Typography className={classes.secondaryText} variant="caption">
                                                    Fund Size(As on {(fundDetails && fundDetails.fundtop && fundDetails.fundtop[0] && fundDetails.fundtop[0].fundSizeDate && formatDate(fundDetails.fundtop[0].fundSizeDate)) || "--"})</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={3} className={[classes.ratingSpan, classes.centerContent].join(" ")}>
                                <div>
                                    <Button className={classes.button} disabled={(isSipAllowed && isSipAllowed === "N") || false} style={{ marginRight: "12px" }} variant="contained" color='primary' onClick={() => {
                                        openMfOrderPopup("SIP")

                                    }}>SIP</Button>
                                    <Button className={classes.button} disabled={(purchaseAllowed && purchaseAllowed === "N") || false} variant="contained" color='primary' onClick={() => {
                                        openMfOrderPopup("OT")
                                    }}>One Time</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    const { common: { selectedScheme, fundDetails }, dialog, hCommon } = state;
    return { selectedScheme, dialog, hCommon, schemeFundDetails: fundDetails };
}

export default connect(mapStateToProps)(withStyles(styles)(FundDetails))
