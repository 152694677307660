import React, { useEffect } from "react";
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import axios from "helpers/interceptor";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "7px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
  buttonColor: {
    backgroundColor: '#006CA8',
    color: '#fff',
    padding: '2px 12px',
    textTransform: 'none',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: '#006CA8' + 80
    }
  },
  outlinedButton: {
    color: '#006CA8',
    backgroundColor: 'transparent',
    border: `1px solid #006CA8`,
    padding: '2px 12px',
    marginLeft: 10,
    textTransform: 'none',
    '&:hover': {
      border: `1px solid #006CA880`,
      backgroundColor: '#006CA8' + 12
    }
  }
};

const OrderType = {
  "Limit": "LIMIT",
  "Market": "MKT",
  "StopLoss": "SL"
}

let USER = `${process.env.REACT_APP_USER}`;

function CustomToolbarSelect(props) {
  const { classes, selectedRows, profile, isNotClient, openConfirmDialog, closeConfirmDialog, getOrderReport, setSelectedRows, tableData, handleCancelAll, setSelectAllRows, selectAllRows, setSelectedRowsIndex, isNextAdmin } = props;

  useEffect(() => {
    if (selectedRows) {
      if (Object.keys(selectedRows).length > 0) {
        // !selectAllRows && setSelectAllRows(true)
      } else {
        selectAllRows && setSelectAllRows(false)
      }
    }
  }, [selectedRows])

  const handleCancelAllOrder = () => {
    let obj = {
      content: "Are you sure, you want to cancel all orders ?",
      okButtonText: "No",
      cancelButtonText: "Yes",
      onClose: handleClose
    }
    openConfirmDialog(obj)
  }

  const pushApi = (payload) => {
    return new Promise(function (resolve, reject) {
      axios.post(`${process.env.REACT_APP_API_URL}/ms-order-placement/push`, payload).then(resp => {
        if (resp.data) {
          resolve(resp.data)
        }
      }).catch(error => {
        resolve({})
      })
    });
  }

  const handleClose = (newValue) => {
    closeConfirmDialog()
    if (newValue === 'cancel') {
      let payload = [], apiPayloadArray = [];
      let rows = /* isNotClient ? ( */Object.keys(selectedRows) || []/* ) : (selectedRows?.data || []); */
      rows.forEach(item => {
        let obj = (tableData?.length > 0 && tableData[/* isNotClient ? */ item /* : item.dataIndex */]) || {}
        if (Object.keys(obj).length > 0 && (obj.orderStatus === 'Pending' || obj.orderStatus === 'PartiallyExecuted' || obj.orderStatus === 'AMO')) {
          let ele = {
            requestStatus: "Cancel",
            ex: obj.shortCode || "",
            seg: obj.segment || "",
            sId: obj.scripId || "",
            tk: obj.token || "",
            dpAccNo: (profile && profile.dematAcc) || "",
            buySell: obj.buySell || "",
            qty: Number(obj.remQTY) || 0,
            price: obj.orderPrice | 0,
            type: (obj.orderType && OrderType[obj.orderType]) || "",
            orderId: obj.tbOrderId || "",
            disQty: Number(obj.disclosedQty) || 0,
            tPrice: obj.trigPrice || 0,
            val: obj.validity || "",
            pId: obj.productType || "",
            goalId: "",
            valDate: (obj.valDate && obj.valDate !== "0" && obj.valDate) || 0,
            userId: USER != "client" ? obj.clientCode : ""
          }
          payload.push(ele)
        }
      })
      if (payload.length === 0) {
        let obj = {
          content: "No orders available",
          hideCloseButton: true,
          okButtonText: "Ok",
          centerActions: true,
          onClose: closeConfirmDialog
        }
        openConfirmDialog(obj)
      } else {
        payload.map((ele) => {
          let submitData = pushApi(ele)
          apiPayloadArray.push(submitData)
        })
        Promise.all(apiPayloadArray).then(response => {
          if (response) {
            let obj = {
              content: "Orders has been taken by Tradebulls.",
              hideCloseButton: true,
              okButtonText: "Ok",
              centerActions: true,
              onClose: () => {
                setSelectedRows()
                selectAllRows && setSelectAllRows(false)
                closeConfirmDialog();
                getOrderReport()
              }
            }
            openConfirmDialog(obj)
          }
        })
      }
    }
  }

  return (
    <div className={classes.iconContainer}>
      {tableData?.length > 0 && !isNextAdmin && <Button
        variant={"contained"}
        onClick={(/* isNotClient &&  */selectedRows && Object.keys(selectedRows).length === 0) ? handleCancelAll : handleCancelAllOrder}
        className={classes['buttonColor']}>
        Cancel All
      </Button>}
    </div>
  );
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);
