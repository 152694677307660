export default theme => ({
    root: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.secondary,
        // width: '100%',
        maxWidth: "max-content",
        // backgroundColor: '#0fc',
        // borderColor: '#B2B2B2' + '30',
        // border: `1px solid ${theme.palette.background.default}`,
        borderRadius: '50vh',
        // borderWidth: '.5px',
        // borderStyle: 'solid',
        paddingLeft: '1vh',
        paddingRight: '1vh',
        spacing: '5px',
        // '&$focused': {
        //     backgroundColor: theme.palette.background.secondary,
        // },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: 'none',
        },
    
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
        },
    },
    rootDark: {
        backgroundColor: theme.palette.background.default,
    }
});
