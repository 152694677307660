import React, { useEffect } from "react"
import { FieldArray, Form, Formik } from "formik";
import { withStyles } from '@mui/styles';
import styles from "../styles"
import * as Yup from "yup";
import { Grid, Typography, FormControl, Button, FormControlLabel, Link, MenuItem } from "@mui/material";
import { getDate, SelectOutlinedInput, CssOutlinedInput, CssCheckbox, CssSelect } from "../data";
import { KeyboardArrowDown as Arrow } from "@mui/icons-material";

function MfSIP(props) {
  const { classes, formData, handleFormValues, formValues, handleBack, activeStep, buySell, sipData, mandateData, addBankMandate } = props

  const modifyFormData = (data, filled) => {
    let formArray = []; let formObj = {};
    let frequency = filled ? formValues.frequency : (sipData && Object.keys(sipData).length > 0 && Object.keys(sipData)[0]) || ""
    data.map((ele, index) => {
      formArray.push({
        amount: filled ? ele.amount : ele.current_price,
        startDate: filled ? ele.startDate :
          (formData[index].sip[frequency] && formData[index].sip[frequency].sipStartDate && formData[index].sip[frequency].sipStartDate.length > 0 && formData[index].sip[frequency].sipStartDate[0]) || ""
      })
    })
    if (filled) {
      formObj.terms = formValues.terms
      formObj.bankMandate = formValues.bankMandate
      formObj.installments = formValues.installments
    } else {
      formObj.terms = false
      formObj.bankMandate = (mandateData?.length > 0 && mandateData.find(ele => ele.status)?.mandateCode)  || ""
      formObj.installments = (sipData[frequency] && sipData[frequency].installments) || 0
    }
    formObj.frequency = frequency
    formObj.mfSip = [...formArray]
    return formObj
  }

  const sumOf = (values) => {
    let sum = 0
    if (values.length > 0) {
      values.forEach((ele, index) => {
        sum = sum + Number(ele.amount)
      })
    }
    return Number(sum).toFixed(2)
  }

  Yup.addMethod(Yup.number, "amountTest", function (args) {
    const message = args;
    return this.test("amountTest", message, function (value) {
      const { path, createError, options } = this;
      const { originalValue, index, from } = options
      if (this.parent.amount === undefined || this.parent.amount === 0) {
        return true
      } else if (originalValue) {
        if (formData && formData[index] && formData[index].sip && from.length > 0 && from[1] && from[1].value && from[1].value.frequency && formData[index].sip[from[1].value && from[1].value.frequency] &&
          formData[index].sip[from[1].value && from[1].value.frequency].minAmount && Number(originalValue) < Number(formData[index].sip[from[1].value && from[1].value.frequency].minAmount)) {
          return createError({
            path: path,
            message: `Amount should be greater than ${formData[index].sip[from[1].value && from[1].value.frequency].minAmount}`,
          })
        } else {
          return true
        }
      }
    })
  })

  Yup.addMethod(Yup.number, "startDateTest", function (args) {
    const message = args;
    return this.test("startDateTest", message, function (value) {
      const { path, createError, options } = this;
      const { originalValue, index, from } = options
      if (this.parent.amount === undefined || this.parent.amount === 0) {
        return true
      } else if (this.parent.amount && Number(this.parent.amount) > 0) {
        if (originalValue) {
          return true
        } else {
          return createError({
            path: path,
            message: "Start date is required",
          })
        }
      }
    })
  })

  Yup.addMethod(Yup.number, "isNotDecimal", function (args) {
    const message = args;
    return this.test("isNotDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        if (String(options.originalValue).includes(".")) {
          return createError({ path: path, message: "Decimal is not allowed" })
        } else {
          return true
        }
      }
      return true
    })
  })

  function onFrequencyChange(value, setFieldValue, formValue) {
    setFieldValue("frequency", value)
    setFieldValue("installments", sipData[value].installments)
    setFieldValue("bankMandate", (sipData[value].mandate && sipData[value].mandate.length > 0 && sipData[value].mandate[0] && sipData[value].mandate[0].mandateNumber) || "")
    formValue.map((ele, index) => {
      if (formData && formData.length > 0 && formData[index] && formData[index][value] && formData[index][value].sipStartDate && formData[index][value].sipStartDate.length > 0) {
        setFieldValue(`mfSip.${index}.startDate`, formData[index][value].sipStartDate[0])
      } else {
        setFieldValue(`mfSip.${index}.startDate`, "")
      }
    })
  }

  const FormFields = (props) => {
    const { values, handleChange, setFieldTouched, resetForm, errors, touched, setFieldValue } = props
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.headerPadding}>
              <Grid item xs={/* 2 */6} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Schemes</Typography>
              </Grid>
              <Grid item xs={2} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>NAV</Typography>
              </Grid>
              <Grid item xs={4} className={classes.centerContent}>
                <Grid container>
                  <Grid item xs={/* 3 */6} className={classes.centerContent}>
                    <Typography variant="caption" align="center" className={classes.blueText}>SIP Amount</Typography>
                  </Grid>
                  <Grid item xs={/* 3 */6} className={classes.centerContent}>
                    <Typography variant="caption" align="center" className={classes.blueText}>Start Date</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FieldArray
          name="mfSip"
          render={arrayHelpers => {
            return (
              <Grid item xs={12} className={classes.gridHeight}>
                {
                  values && values.mfSip && values.mfSip.length > 0
                    ? values.mfSip.map((user, index) => (
                      <Grid container key={index} className={classes.gridItem}>
                        <Grid item xs={/* 2 */6}>
                          <Typography variant="subtitle1" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].fund_name) || "--"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="subtitle1" style={{ textAlign: "center" }} className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].nav_price && Number(formData[index].nav_price).toFixed(4)) || "--"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid item xs={6}>
                              <FormControl variant="outlined" className={classes.formField}>
                                <CssOutlinedInput
                                  id={`mfSip.${index}.amount`}
                                  name={`mfSip.${index}.amount`}
                                  autoFocus={index === 0 ? true : false}
                                  classes={{ input: classes.input }}
                                  onChange={handleChange}
                                  value={values.mfSip[index].amount}
                                  onKeyUp={() => setFieldTouched("amount", true)}
                                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                              </FormControl>
                              {errors && errors.mfSip && errors.mfSip.length > 0 && errors.mfSip[index] && errors.mfSip[index].amount &&
                                <p className={classes.errorText}>{errors.mfSip[index].amount}</p>}
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl variant="outlined" className={classes.formField}>
                                <CssSelect
                                  id={`mfSip.${index}.startDate`}
                                  name={`mfSip.${index}.startDate`}
                                  value={values.mfSip[index].startDate}
                                  onChange={handleChange}
                                  onKeyUp={() => setFieldTouched("startDate", true)}
                                  className={classes.select}
                                  input={<SelectOutlinedInput />}
                                  variant="standard"
                                  IconComponent={() => <Arrow />}
                                >
                                  {
                                    formData && formData.length > 0 && formData[index] && formData[index].sip && formData[index].sip[values.frequency] &&
                                    formData[index].sip[values.frequency].sipStartDate && formData[index].sip[values.frequency].sipStartDate.length > 0 &&
                                    formData[index].sip[values.frequency].sipStartDate.map((ele) => (
                                      <MenuItem key={ele} value={ele} className={classes.menuItem}>{getDate(ele)}</MenuItem>
                                    ))
                                  }
                                </CssSelect>
                              </FormControl>
                              {errors && errors.mfSip && errors.mfSip.length > 0 && errors.mfSip[index] && errors.mfSip[index].startDate &&
                                <p className={classes.errorText}>{errors.mfSip[index].startDate}</p>}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                    : null
                }
              </Grid>
            );
          }}
        />
        <Grid item xs={12}>
          <Grid container className={classes.approxDiv}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.secondaryText}>Total SIP Amount</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="outlined" className={classes.outlinedButton} style={{ padding: 0 }} onClick={() => resetForm({ values: modifyFormData(formData) })}>Reset</Button>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" className={classes.primaryText}>{(sumOf(values.mfSip)) || "--"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.bottomInfoDiv} style={{ paddingTop: 0 }}>
            {/* <Grid item xs={6}>
              <Typography variant="caption" className={classes.primaryText}>Approx Total (EQ + MF)</Typography>
            </Grid> */}
            {/* <Grid item xs={6}> */}
            {/* <Typography variant="subtitle1" style={{ fontWeight: 600, justifyContent: "flex-end" }} className={[classes.primaryText, classes.centerContent].join(" ")}> */}
            {/* Number(Number(sumOf(values.mf)) + Number(formValues.eq.sumOf)).toFixed(2) *//* "--" */}
            {/* </Typography> */}
            {/* </Grid> */}
            <Grid item xs={3}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <Typography className={classes.fieldText}>Frequency</Typography>
                <CssSelect
                  id={`frequency`}
                  name={`frequency`}
                  value={values.frequency}
                  onChange={(e) => onFrequencyChange(e.target.value, setFieldValue, values.mfSip)}
                  onKeyUp={() => setFieldTouched("frequency", true)}
                  className={classes.select}
                  input={<SelectOutlinedInput />}
                  variant="standard"
                  IconComponent={() => <Arrow />}
                >
                  {
                    Object.keys(sipData).map((ele) => (
                      <MenuItem key={ele} value={ele} className={classes.menuItem}>{ele}</MenuItem>
                    ))
                  }
                </CssSelect>
              </FormControl>
              {errors && errors.frequency && <p className={classes.errorText}>{errors.frequency}</p>}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <Typography className={classes.fieldText}>No. of Installments</Typography>
                <CssOutlinedInput
                  id={"installments"}
                  name={"installments"}
                  classes={{ input: classes.input }}
                  onChange={handleChange}
                  disabled={true}
                  value={values.installments}
                  onKeyUp={() => setFieldTouched("installments", true)}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
              </FormControl>
              {errors && errors.installments && <p className={classes.errorText}>{errors.installments}</p>}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {
                (mandateData.length > 0 && mandateData.find(ele => ele.status === true))  ?
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <Typography className={classes.fieldText}>Bank Mandate</Typography>
                    <CssSelect
                      id={`bankMandate`}
                      name={`bankMandate`}
                      value={values.bankMandate}
                      onChange={handleChange}
                      onKeyUp={() => setFieldTouched("bankMandate", true)}
                      className={classes.select}
                      input={<Tooltip title={values.bankMandate} aria-label={values.bankMandate} className={""}><SelectOutlinedInput /></Tooltip>}
                      variant="standard"
                      IconComponent={() => <Arrow />}
                    >
                      {
                        mandateData.map((ele) => (
                          ele.status && <MenuItem key={ele.mandateCode} value={ele.mandateCode} className={classes.menuItem}>{`${ele.bankName} (${ele.mandateCode}) - Approved`}</MenuItem>
                        ))
                      }
                    </CssSelect>
                  </FormControl>
                  :
                  <Button variant="contained" className={classes.mandateButton} onClick={addBankMandate}>Add Bank Mandate</Button>
              }
              {errors && errors.bankMandate && <p className={classes.errorText}>{mandateData.length > 0 ? errors.bankMandate : "Create new mandate to place order"}</p>}
            </Grid>
            <Grid item xs={12} className={classes.approxDiv} style={{ background: "#E5FCFF", padding: 6, marginTop: 12 }}>
              <Typography variant="caption" style={{ color: "#000", }}>It will take 2-3 business days for MF units to reflect in your Products Portfolio.</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.termsGrid}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <div className={classes.termsDiv}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  id="terms"
                  name="terms"
                  control={<CssCheckbox
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    color="primary"
                    checked={values.terms}
                    onKeyUp={() => setFieldTouched("terms", true)}
                    onChange={handleChange}
                  />}
                  label={""}
                  labelPlacement="end"
                />
                <Typography style={{ marginLeft: "-16px" }} variant="subtitle2" className={[classes.secondaryText, classes.checkboxMargin].join(" ")}>By continuing, you agree to &nbsp;
                  <span>
                    <Link
                      className={classes.blueText}
                      target="_blank"
                      href="/tnc/TnCMF.pdf"
                      rel="noopener"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                  &nbsp; of Tradebulls.
                </Typography>
              </div>
              {errors.terms && touched.terms && <p className={classes.errorText}>{errors.terms}</p>}
            </div>
          </FormControl>
        </Grid>
        {
          activeStep !== 0 ?
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="outlined" className={classes.cancel} onClick={() => handleBack(0, "Equity")}>{"Back"}</Button>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="contained" style={{ marginLeft: 0 }} className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
        }
      </>
    )
  }

  const form = () => {
    return (
      <Formik
        initialValues={formValues && formValues && formValues.data && formValues.data.length > 0 ? modifyFormData(formValues.data, true) : modifyFormData(formData)}
        validationSchema={Yup.object({
          mfSip: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().integer("Decimal is not allowed").typeError("Invalid number")
                .min(0, "Amount should be greater than or equal to Zero")
                .max(990000000, "Amount should be less than 990000000")
                .amountTest(),
              startDate: Yup.number().typeError("Invalid date")
                .startDateTest()
            })
          ),
          bankMandate: Yup.string()
            .required("Please select mandate"),
          frequency: Yup.mixed()
            .required("Frequency is required"),
          installments: Yup.number().typeError("Invalid number")
            .required("No. of Installments is required"),
          terms: Yup.boolean()
            .required("The terms and conditions must be accepted.")
            .oneOf([true], "The terms and conditions must be accepted."),
        })
        }
        onSubmit={values => {
          let data = []
          values.mfSip.map((ele, index) => {
            data.push({
              schemeName: formData[index].fund_name,
              nav: formData[index].nav_price,
              amount: ele.amount ? Number(ele.amount) : 0,
              schemeCode: formData[index].schemeCode,
              startDate: ele.startDate || "",
              isinNumber: formData[index].isinNumber
            })
          })
          let obj = {
            sumOf: sumOf(values.mfSip),
            terms: values.terms,
            frequency: values.frequency || "",
            bankMandate: values.bankMandate || "",
            installments: Number(values.installments || 0),
            action: buySell,
            data
          }
          handleFormValues(obj)
        }}
      >
        {({ values, resetForm, handleChange, errors, touched, setFieldTouched, setFieldValue }) => (
          <Form>
            <FormFields
              errors={errors}
              values={values}
              touched={touched}
              resetForm={resetForm}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Form>
        )}
      </Formik >
    )
  }

  return (
    <div>
      {form()}
    </div>
  )
}

export default withStyles(styles)(MfSIP)