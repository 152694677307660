import { store } from 'helpers/store';

//Actions
const INFO = 'ALERT_INFO';
const SUCCESS = 'ALERT_SUCCESS';
const ERROR = 'ALERT_ERROR';
const CLEAR = 'ALERT_CLEAR';

export const AlertActions = { info, success, error, clear };

//Actions Creators

export function info(message, vertical, horizontal) {
  store.dispatch({ type: INFO, message, vertical, horizontal });
}

export function success(message) {
  store.dispatch({ type: SUCCESS, message });
}

export function error(message) {
  store.dispatch({ type: ERROR, message });
}

export function clear() {
  store.dispatch({ type: CLEAR });
}

//Reducer
export function alertReducer(state = initialState, action) {
  switch (action.type) {
    case INFO:
      return {
        ...state,
        type: 'alert-info',
        sType: 'info',
        message: action.message,
        vertical: action.vertical,
        horizontal: action.horizontal,
      };
    case SUCCESS:
      // debugger
      return {
        ...state,
        type: 'alert-success',
        sType: 'success',
        message: action.message
      };
    case ERROR:
      // debugger
      return {
        ...state,
        type: 'alert-danger',
        sType: 'error',
        message: action.message
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

const initialState = { test: "init" };