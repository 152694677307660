const color1 = '#126CA8';

export default (theme) => ({
  dialogRoot: {
    backgroundColor: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    // marginTop: "25px",
    minHeight: '7vh',
    // minWidth: '20vw',
    // maxWidth: ' 32vw',
    width: '24vw',
    [theme.breakpoints.down('xl')]: {
      width: '28vw'
    },
    [theme.breakpoints.down('lg')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '55vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '70vw'
    },
  },
  list: {
    alignSelf: 'stretch'
  },
  listItem: {
    paddingBottom: '16px'
  },
  text: {
    color: theme.palette.color15.default,
    marginTop: '5px'
  },
  title: {
    fontSize: 15,
    color: color1,
    fontWeight: 600,
    lineHeight: '20px'
  },
  listItemIcon: {
    // // backgroundColor: 'red',
    // '& path': {
    //     fill: 'red',
    // },
  },
  arrowIcon: {
    '& path': {
      fill: theme.palette.primary.main
    }
  },
  collapse: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.background.secondary,
    paddingBottom: theme.spacing(1)
  },
  textContiner: {
    overflow: 'auto',
    maxHeight: 250
  },
  dot: {
    color: color1,
    height: 15,
    width: 15,
    marginTop: 3,
    marginLeft: 5,
    marginRight: 5
  },
  textHeader: {
    color: theme.palette.text.primary,
    // color: theme.palette.text.secondary,
    fontWeight: 600,
    letterSpacing: 1
  },
  textContent: {
    // color: theme.palette.text.secondary,
    // color: theme.palette.text.primary,
    // color: theme.palette.type === 'light' ? '#666' : '#B2BEDB',
    color: theme.palette.type === 'light' ? '#333' : '#A5AAB7'
    // color: theme.palette.color15.default,
  },
  pText: {
    fontSize: '14px',
    lineHeight: '20px',
    paddingBottom: '20px',
    letterSpacing: '0.2px',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '18px',
      paddingBottom: '16px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '16px',
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
      paddingBottom: '12px'
    },
  },
  textDiv: {
    borderTop: '2px solid #66788a4d',
    borderBottom: '2px solid #66788a4d',
    padding: '4px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px'
    }
  },
  innerTextDiv: {
    padding: '12px 0px',
    height: '350px',
    overflowY: 'auto',
    [theme.breakpoints.down('xl')]: {
      padding: '8px 0px',
      height: '300px'
    },
    [theme.breakpoints.down('lg')]: {
      height: '280px'
    },
    [theme.breakpoints.down('md')]: {
      height: '250px'
    }
  },
  paper: {
    padding: '6px',
    margin: '8px 0px',
    backgroundColor: theme.palette.background.default,
    // boxShadow: `0px 0px 12px 0px ${theme.palette.color14.primary}`,
    // border: `1px solid ${theme.palette.color14.primary}`,
    borderRadius: '4px',
    width: '50vw',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 0px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '0px',
      width: '60vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '70vw'
    },
    '&.message': {
      width: '25vw',
      [theme.breakpoints.down('xl')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('md')]: {
        width: '50vw'
      },
    }
  },
  checkboxRoot: {
    color: theme.palette.text.primary,
    marginRight: 0
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    borderRadius: theme.spacing(1) * 1.5
  },
  bottomDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: '4px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    }
  },
  termsText: {
    fontSize: '16px',
    marginLeft: '-16px',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  submitButton: {
    textTransform: 'none',
    fontSize: '16px',
    padding: '6px 48px',
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
      padding: '4px 40px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      padding: '4px 32px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      padding: '4px 28px'
    }
  },
  questionDiv: {
    display: 'flex',
    paddingBottom: '28px',
    [theme.breakpoints.down('xl')]: {
      paddingBottom: '16px'
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '8px'
    }
  },
  question: {
    fontSize: '14px',
    color: theme.palette.common.blue,
    paddingBottom: '6px',
    fontWeight: 600,
    lineHeight: '20px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '18px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '16px',
      paddingBottom: '4px'
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: '12px'
    }
  },
  answer: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '18px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: '12px'
    }
  },
  questionNumber: {
    height: '28px',
    padding: '6px',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    background: '#66788a4d',
    borderRadius: '4px',
    justifyContent: 'center',
    fontSize: '14px',
    marginRight: '20px',
    color: theme.palette.common.blue,
    fontWeight: 600,
    [theme.breakpoints.down('xl')]: {
      height: '24px',
      padding: '4px',
      fontSize: '12px',
      marginRight: '16px'
    },
    [theme.breakpoints.down('lg')]: {
      height: '20px',
      padding: '4px',
      marginRight: '4px',
      fontSize: '10px',
      marginRight: '12px'
    },
    [theme.breakpoints.down('md')]: {
      height: '16px',
      fontSize: '8px'
    }
  },
  linkButton: {
    padding: '4px 16px',
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    }
  },
  textPadding: {
    padding: '20px 0px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '18px',
    [theme.breakpoints.down('xl')]: {
      padding: '16px',
      fontSize: '12px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '16px 0px'
    }
  },
  textPadding1: {
    textAlign: 'center',
    padding: '50px 10px',
    [theme.breakpoints.down('lg')]: {
      padding: '40px 10px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '36px 10px'
    }
  }
});