import { Grid, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import Placeholder from 'components/Placeholder';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { contractDetailsService } from 'services/contractdetails';
import styles from "./styles";

function Contracts(props) {
  const { selectedScrip, propScrip, classes } = props;
  let currentScrip = propScrip || selectedScrip;
  const { segment, shortCode, scripId, exchange } = currentScrip;
  const [contractDetails, setContractDetails] = useState({})

  const horizontalItem = [classes.horizontalDivider, classes.item].join(" ")
  const verticalItem = [classes.paper, classes.verticalDivider].join(" ")

  async function getDetails() {
    try {
      const data = await contractDetailsService.getContractDetails({ shortCode, scripId })
      if (data) {
        setContractDetails(data)
      } else {
        setContractDetails({})
      }
    } catch (error) {
      console.log(error)
      return;
    }
  }

  useEffect(() => {
    if (segment === "COMM") {
      getDetails()
    }
  }, [scripId, exchange])

  return (
    <div className={classes.mainDiv}>
      {
        segment === "COMM" ?
          <div className={classes.root}>
            <Grid container className={[classes.grid, "gridHeight"].join(" ")}>
              <Grid item xs={12} className={horizontalItem}>
                <Paper className={classes.paper}>
                  <div>{(contractDetails && contractDetails.remark) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#00bbfe' }}>Contract Details</div>
                </Paper>
              </Grid>
              <Grid item xs={6} className={horizontalItem}>
                <Paper className={verticalItem}>
                  <div>{(contractDetails && contractDetails.dpr) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#00bbfe' }}>DPR</div>
                </Paper>
              </Grid>
              <Grid item xs={6} className={horizontalItem}>
                <Paper className={classes.paper}>
                  <div>{(contractDetails && contractDetails.marketLot) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#8166F9' }}>Market Lot</div>
                </Paper>
              </Grid>
              <Grid item xs={6} className={horizontalItem}>
                <Paper className={verticalItem}>
                  <div>{(contractDetails && contractDetails.priceTick) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#FC940B' }}>Price Tick</div>
                </Paper>
              </Grid>
              <Grid item xs={6} className={horizontalItem}>
                <Paper className={classes.paper}>
                  <div>{(contractDetails && contractDetails.priceQuotation) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#BC2E94' }}>Price Quotation</div>
                </Paper>
              </Grid>
              <Grid item xs={6} className={horizontalItem}>
                <Paper className={verticalItem}>
                  <div>{(contractDetails && contractDetails.startDate) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#654ECC' }}>Start Date</div>
                </Paper>
              </Grid>
              <Grid item xs={6} className={horizontalItem}>
                <Paper className={classes.paper}>
                  <div>{(contractDetails && contractDetails.endDate) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#497CAC' }}>End Date</div>
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.item}>
                <Paper className={classes.paper}>
                  <div>{(contractDetails && contractDetails.tenderPeriod) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#089475' }}>Tender Period</div>
                </Paper>
              </Grid>
              {/* <Grid item xs={6} className={classes.item}>
                <Paper className={classes.paper}>
                  <div>{(contractDetails && contractDetails.maxOrderSize) || "--"}</div>
                  <div className={[classes.text, classes.textMargin].join(" ")} style={{ color: '#FC6B0B' }}>Max Order Size</div>
                </Paper>
              </Grid> */}
            </Grid>
          </div>
          :
          <Placeholder
            loading={false}
            loadingMsg={""}
            error={"Not Applicable"}
          />
      }
    </div >
  );
}

Contracts.propTypes = {
  selectedScrip: PropTypes.object.isRequired
};

Contracts.defaultProps = {
  selectedScrip: {}
};

const mapStateToProps = (state) => {
  return { selectedScrip: state.common.selectedScrip }
};

export default connect(mapStateToProps)(withStyles(styles)(Contracts))
