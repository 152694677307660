import { Button, Grid, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getBseScripCode, getFixedValue, getSegment } from 'helpers/scripHelper';
import React from 'react';
import { DialogActions, openDialog } from 'redux/Dialog';
import { CommonActions } from "redux/Common";
import Notification from '@mui/icons-material/NotificationsActiveOutlined';
import { MODAL_CHILD } from 'widgets/FormDialog';
import { store } from 'helpers';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    mainDiv: {
        width: "100%",
        height: "100%",
        overflowY: "auto"
    },
    root: {
        display: "flex",
        flexGrow: 1,
        height: '100%',
        margin: '0px 15px',
        [theme.breakpoints.down('lg')]: {
            margin: '0px',
        },
    },
    paper: {
        padding: 15,
        color: theme.palette.color15.default,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 0
    },
    grey: {
        color: theme.palette.color15.default,
        // fontSize: 17
    },
    highlight: {
        color: theme.palette.color12.default,
        // whiteSpace: "nowrap",
        whiteSpace: "initial"
    },
    grid: {
        minHeight: '8vh',
        height: '100%',
        alignSelf: "center",
        alignItems: 'center',
        margin: 0,
        display: 'flex'
    },

    item: {
        padding: 0
    },
    svgIcon: {
        '& svg': {
            width: 15,
            height: 10
        }
    },
    greenColor: {
        color: theme.palette.priceColor.green,
    },
    redColor: {
        color: theme.palette.priceColor.red,
    },
    button: {
        margin: theme.spacing(1),
    },
    normalColor: {
        color: theme.palette.text.primary
    },
    iconButton: {
        color: '#006CA8',
        border: '1px solid #006CA8',
        borderRadius: 5,
        padding: 5,
        margin: 8,
        [theme.breakpoints.down('lg')]: {
            padding: 4,
        },
        [theme.breakpoints.down('md')]: {
            padding: 3,
        }
    }
}));

function ScripInfo(props) {

    const classes = useStyles();

    const { selectedScrip, propScrip } = props;
    let currentScrip = propScrip || selectedScrip || {};
    const { scripName, segment, exchange, symbol, ltp, change, percentChange, ltt, isMarketIndex } = currentScrip;

    const openOrderPopup = type => {
        DialogActions.openOrderForm({ form: { status: 'New', buySell: type }, scrip: currentScrip });
    };

    return (
        <div className={classes.mainDiv}>
            <div className={classes.root}>
                <Grid container spacing={1.5} className={classes.grid}>
                    <Grid item xs={12} sm={8} style={{ padding: 12 }}>
                        <Grid item xs container direction="column">
                            <Grid item xs={12}>
                                <Typography component="div" display="block" gutterBottom>
                                    <Typography component="div" display="inline" variant="h3" className={classes.highlight}><span>{scripName || symbol || '--'}</span></Typography>
                                    <Typography component="div" display="inline" variant="caption"><span style={{ marginLeft: 10 }}>( {exchange || "--"} {getSegment(currentScrip)} {getBseScripCode(currentScrip)} )</span></Typography>
                                </Typography><Typography component="div" display="block" gutterBottom >
                                    <Typography component="div" display="inline" variant="h4" className={Number(percentChange) > 0 ? classes.greenColor : Number(percentChange) < 0 ? classes.redColor : classes.normalColor}>{getFixedValue(ltp, segment) || 0.00}</Typography>
                                    <Typography component="div" display="inline" variant="subtitle1" className={classes.grey} style={{ marginLeft: 10 }}>{getFixedValue(change, segment) || "0.00"} ({getFixedValue(percentChange, segment === "CURR" ? null : segment)/*  && Number(percentChange).toFixed(2) */ || "0.00"}%)</Typography>
                                    <Typography component="div" display="inline" variant="caption" className={classes.grey} /* style={{fontSize: 12}} */>&nbsp;&nbsp;|&nbsp;&nbsp;As on {ltt || "--"}</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
                        {
                            !isMarketIndex && <>
                                <Grid item>
                                    <Button variant="contained" color='primary' className={classes.button} onClick={() => {
                                        CommonActions.setSearchOpen(false); openOrderPopup('B')
                                    }}>Buy</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color='secondary' className={classes.button} onClick={() => {
                                        CommonActions.setSearchOpen(false); openOrderPopup('S')
                                    }}>Sell</Button>
                                </Grid>
                            </>
                        }
                        <Grid item>
                            <IconButton title={'Alert'} className={classes.iconButton} onClick={() => {
                                CommonActions.setSearchOpen(false);
                                store.dispatch(openDialog(MODAL_CHILD.PRICE_ALERT, { ...currentScrip, action: 'C' }));
                            }}>
                                <Notification />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { common: { selectedScrip }, dialog } = state;
    return { selectedScrip, dialog };
}

export default connect(mapStateToProps)(ScripInfo);
