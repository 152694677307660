import styled from "styled-components";

export default styled.text`
    flex: 1;
    font-size: 30px;
    color: white;
    white-space: break-spaces;
    line-height: 30px;
    padding: 0 0 20px 0;
`;
