import { FilterList as FilterIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = theme => ({
  totalText: {
    color: theme.palette.color15.default,
  },
  primaryText: {
    color: theme.palette.text.primary,
  },
  actionsDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 12,
    alignItems: 'center',
    height: 40
  },
  icon: {
    paddingBottom: 0,
    paddingTop: 0
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionText: {
    paddingLeft: 12
  }
})

function Toolbar(props) {
  const { classes, toggleFilter, refreshing, totalRecords, totalSelectedRecords, toolbarProps } = props;
  const { filter, refresh, onRefresh, showTotalRecords, showTotalSelectedRecords, toolbarLeftContent, toolbarRightContent } = toolbarProps || {};

  return (
    toolbarProps ?
      <div className={classes.actionsDiv}>
        <div className={classes.centerContent}>
          {toolbarLeftContent && toolbarLeftContent()}
          {showTotalRecords && totalRecords > 0 && <Typography variant='h6' className={classes.totalText}>
            {`Total Records: `}<span className={classes.primaryText}>{totalRecords}</span>
          </Typography>}
          {showTotalSelectedRecords && totalSelectedRecords > 0 &&
            <Typography variant='h6' className={`${classes.totalText} ${classes.selectionText}`}>
              {`Selected Records: `}<span className={classes.primaryText}>{totalSelectedRecords}</span>
            </Typography>}
        </div>
        <div className={classes.centerContent}>
          {filter &&
            <Tooltip title={"Filter table"} disableFocusListener>
              <IconButton
                aria-label={'Filter table'}
                onClick={toggleFilter}
                disabled={refreshing}
                className={classes.icon}
              >
                <FilterIcon />
              </IconButton>
            </Tooltip>
          }
          {refresh &&
            <Tooltip title={'Refresh'} disableFocusListener>
              <IconButton
                aria-label={'Refresh'}
                disabled={refreshing}
                onClick={onRefresh}
                className={classes.icon}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          }
          {toolbarRightContent && toolbarRightContent()}
        </div>
      </div>
      :
      <></>
  )
}

export default withStyles(styles)(Toolbar);