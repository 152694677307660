import { Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import SvgComponent from "helpers/svgcomponent";
import React from "react";
import styles from "./styles";

const data = {
  0: [
    { label: "InActive Days", key: "inactiveDays", icon: "InactiveDaysIcon", color: "color1" },
    // { label: "Open Position", key: "openPosition", icon: "OpenPositionIcon", color: "color2" },
    // { label: "Holdings", key: "holdings", icon: "HoldingIcon", color: "color3" },
    // { label: "Margin Shortage", key: "marginShortageStatement", icon: "MarginShortageIcon", color: "color4" },
    // { label: "Funds Available", key: "fundsAvailable", icon: "FundsAvailableIcon", color: "color5" },
    { label: "MOS", key: "mos", icon: "MOSIcon", border: false, color: "color6" }
  ],
  1: [
    { label: "Bank Name (Default)", key: "bankName", color: "color1" },
    { label: "Bank A/C Number", key: "bankAcc", color: "color2" },
    { label: "IFSC Code", key: "bankIFSC", color: "color3" },
    { label: "KYC Status", key: "kyc", border: false, color: "color4" },
  ],
  2: [
    { label: "Plan Name", key: "planName", color: "color1" },
    { label: "Equity Delivery", key: "eqDelivery", color: "color2" },
    { label: "Equity Intraday", key: "eqIntraDay", color: "color3" },
    { label: "Equity Options", key: "eqOptions", color: "color4" },
    // { label: "", key: "-" },
    { label: "CURR Futures", key: "-", color: "color5" },
    { label: "CURR Options", key: "-", color: "color6" },
    { label: "Equity Options", key: "-", border: false, color: "color1" },
  ],
  3: [],
  4: []
}

function ClientListItem(props) {
  const { icon, showIcon, iconClass, hideBorder, text, value, classes, keyIndex  } = props
  return (
    <ListItem key={keyIndex} className={classes.listItem} style={hideBorder ? { borderBottom: 0 } : {}}>
      {
        showIcon &&
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar classes={{ colorDefault: classes.avatar }}>
            <SvgComponent iconname={icon} className={classes.listIcon} />
          </Avatar>
        </ListItemAvatar>
      }
      <ListItemText
        classes={{ primary: iconClass }}
        primary={text || ""}
        className={classes.text}
      />
      <ListItemSecondaryAction className={classes.value}>
        <Typography className={classes.value}>{value || "--"}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

function DetailList(props) {
  const { classes, basicInfo, value, height } = props
  return (
    <div className={classes.list} style={{height: height}}>
      {
        data[value].length > 0 ?
          <List dense={false} style={{paddingTop: 0, paddingBottom: 0}}>
            {
              data[value].map((ele, index) => (
                <ClientListItem keyIndex={index} key={index} icon={ele.icon} showIcon={ele.icon ? true : false} text={ele.label} value={basicInfo[ele.key]} classes={classes} iconClass={classes[ele.color]} hideBorder={ele.border === false} />
              ))
            }
          </List>
          :
          <div className={classes.noData}>No data found</div>
      }
    </div>
  )
}

export default withStyles(styles)(DetailList)