import { black, white } from "common/colors";

export default {
  orderStatusColor: {
    cancelled: '#915757',
    executed: '#67AAE3',
    expired: '#989292',
    freezed: '#597A8A',
    inProcess: '#71B20F',
    partiallyExecuted: '#4CB5F3',
    pending: '#FF9913',
    rejected: '#FF5757',
    triggered: '#9F5F92'
  },
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
    blue: '#006CA8'
  },
  primary: {
    contrastText: white,
    main: '#f54040',
    light: '#F6F9FD',
    dark: '#b70000'
  },
  secondary: {
    contrastText: white,
    main: '#7d58ff',
    light: '',
    dark: '#37248F'
  },
  success: {
    contrastText: white,
    main: '#41F900', //'#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08'
  },
  table: {
    main: '#F5F5F5',
    tableHeadingText: '#686868'
  },
  divider: '#DFE3E8'
};
