import { InfoOutlined as InfoIcon, KeyboardArrowDown as Arrow } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Typography, useMediaQuery, Tooltip, Paper, Collapse, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import styles from './styles'
import ClientSearchBox from "components/Search/ClientSearchBox";
import { CssOutlinedInput, CssSelect, SelectOutlinedInput, CssSwitch } from "./customfields";
import React, { useRef } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from 'react';
import BrokerageCharges from "./brokeragecharges";

const USER = `${process.env.REACT_APP_USER}`

function CompactOrderForm(props) {
  const { reduxForm, setFieldTouched, setFieldValue, setInfoPopup, selectedClient, selectClient, setSelectClient, setClientError, handleExchangeChange, setOrderType, values, errors, touched, handleChange, classes, showExchange, showClientError, initializeSelectClient, scrip, validityDateData, submitting, marketStatus, setHelpPopup, approxMarginData, handleRefreshApproxMargin, showBrokerageCharges, brokerageCharges, setShowBrokerageCharges, compactView } = props
  const { exchange, lot, price, triggerPrice, quantity, disclosedQuantity, product, validTill, orderType, validityDate, approxMargin } = values
  const { type, status, buySell, netQty, productType, orderStatus } = reduxForm
  const { isTBApproved, segment, isMTFApproved, optionType } = scrip

  const theme = createTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'))
  const orderFocus = useRef(null);

  function onProductChange(value) {
    if (value === "Intraday" && (!isTBApproved || isTBApproved === "false")) {
      setInfoPopup({ show: true, message: "This scrip is not allowed to trade in intraday." })
    } else if (value === "MTF" && (!isMTFApproved || isMTFApproved === "false")) {
      setInfoPopup({ show: true, message: "This scrip is not allowed to trade in MTF." })
    } else {
      setFieldValue("product", value)
      if (segment === "COMM" && (validTill === "GTC" || validTill === "GTD")) {
        setFieldValue("validTill", "GFD")
      }
    }
  }

  function onValidityChange(value) {
    if (value === "IOC" && (marketStatus && marketStatus[scrip.shortCode] && marketStatus[scrip.shortCode].isAfterHourMarket)) {
      setInfoPopup({ show: true, message: "IOC order not allowed in AMO." })
    } else if (segment === "COMM" && value === "IOC" && orderType === "SL" && scrip?.exchange === "MCX") {
      setInfoPopup({ show: true, message: "Stop Loss not allowed for IOC order." })
    } else {
      setFieldValue("validTill", value)
    }
  }

  function handleFocus(item) {
    if (item && item.ClientCode) {
      orderFocus.current.focus();
    }
  }

  function handleBrokerageCharges() {
    if (USER !== "client" && Object.keys(selectClient).length === 0) {
      setInfoPopup({ show: true, message: "Please select client." })
    } else {
      setShowBrokerageCharges(!showBrokerageCharges)
    }
  }

  function handleTypeDisabled() {
    if (exchange === 'BSE' && segment === "FNO" && reduxForm.orderType !== 'SL') {
      return true
    } else if (((exchange === "BSE" && segment === "EQ") || (segment === "COMM")) && (reduxForm.orderType === "LIMIT" || reduxForm.orderType === "MKT")) {
      return true
    } else {
      return false
    }
  }

  const firstRow = () => {
    return (
      <>
        <FormControl variant="outlined" className={[classes.fieldDiv, "select"].join(" ")} style={USER !== "client" && segment == "EQ" && type !== "sell" && type !== "squareOff" ? { width: "130px" } : {}}>
          <Typography className={classes.fieldText}>Type</Typography>
          <CssSelect
            labelId="demo-simple-select-label"
            id="type"
            name="type"
            value={values.type}
            onChange={(e) => setOrderType(e.target.value)}
            error={touched.type && errors.type ?
              true : false}
            onKeyUp={() => setFieldTouched("type", true)}
            className={values.type === "B" ? classes.activeButton : classes.activeSell}
            input={<SelectOutlinedInput />}
            variant="standard"
            IconComponent={() => <Arrow />}
          >
            <MenuItem style={USER !== "client" && segment === "EQ" && type !== "sell" && type !== "squareOff" ? { width: "130px" } : { width: "81px" }} value={"B"} disabled={(type === "squareOff" && netQty && buySell === "S") ||
              type === "sell" ||
              (status === "Modify" && (buySell === "S" || buySell === "SS"))}>BUY</MenuItem>
            <MenuItem value={"S"} disabled={(type === "squareOff" && netQty && buySell === "B") ||
              (status === "Modify" && (buySell === "B" || buySell === "SS"))}>SELL</MenuItem>
            {
              (USER !== "client" && segment === "EQ" && type !== "sell" && type !== "squareOff") ?
                <MenuItem value={"SS"} disabled={(type === "squareOff" && buySell === "B") ||
                  (status === "Modify" && (buySell === "B" || buySell === "S"))}>SHORT SELL</MenuItem>
                : null
            }
          </CssSelect>
          {errors.type && touched.type && (<p className={classes.errorText}>{errors.type}</p>)}
        </FormControl>
        {
          showExchange ?
            <FormControl variant="outlined" className={[classes.fieldDiv, segment === "COMM" ? "selectLarge" : "select"].join(" ")}>
              <Typography className={classes.fieldText}>Exchange</Typography>
              <CssSelect
                labelId="demo-simple-select-filled-label"
                id="exchange"
                name="exchange"
                value={exchange}
                onChange={(e) => handleExchangeChange(e.target.value)}
                error={touched.exchange && errors.exchange ? true : false}
                onKeyUp={() => setFieldTouched("exchange", true)}
                variant="standard"
                className={values.type === "B" ? classes.activeButton : classes.activeSell}
                input={<SelectOutlinedInput />}
                IconComponent={() => <Arrow />}
              >
                <MenuItem
                  style={{ width: "81px" }}
                  value={/* segment === "COMM" ? "MCX" :  */"NSE"}
                  disabled={status === "Modify" && exchange === /* (segment === "COMM" ? "NCDEX" :  */"BSE"/* ) */}
                >
                  {/* segment === "COMM" ? "MCX" :  */"NSE"}
                </MenuItem>
                <MenuItem
                  value={/* segment === "COMM" ? "NCDEX" :  */"BSE"}
                  disabled={status === "Modify" && exchange === /* ( segment === "COMM" ? "MCX" :  */"NSE"/* ) */}
                >
                  {/* segment === "COMM" ? "NCDEX" :  */"BSE"}
                </MenuItem>
              </CssSelect>
              {errors.exchange && touched.exchange && (<p className={classes.errorText}>{errors.exchange}</p>)}
            </FormControl>
            :
            <></>
        }
        <FormControl variant="outlined" className={[classes.fieldDiv, "selectLarge"].join(" ")}>
          <Typography className={classes.fieldText}>Order Type</Typography>
          <CssSelect
            labelId="demo-simple-select-filled-label"
            id="orderType"
            name="orderType"
            autoFocus={status === "New" ? true : false}
            value={orderType}
            onChange={(e) => {
              if (e.target.value === "SL" && validTill === "IOC" && segment === "COMM" && scrip?.exchange === "MCX") {
                setInfoPopup({ show: true, message: "Stop Loss not allowed for IOC order." })
              } else {
                setOrderType(e.target.value)
              }
            }}
            variant="standard"
            error={touched.orderType && errors.orderType ? true : false}
            onKeyUp={() => setFieldTouched("orderType", true)}
            className={values.type === "B" ? classes.activeButton : classes.activeSell}
            input={<SelectOutlinedInput />}
            IconComponent={() => <Arrow />}
          >
            <MenuItem style={{ width: "101px" }}
              // disabled={status == "Modify" && orderType === "SL"}
              value={"LIMIT"}>Limit</MenuItem>
            <MenuItem
              // disabled={status == "Modify" && (orderType === "SL" || orderType === "LIMIT")}
              value={"MKT"}>Market</MenuItem>
            <MenuItem
              disabled={(status === "Modify" && handleTypeDisabled())/*  || bracketOrder */} // change while integrating bracket order
              // disabled={status == "Modify" && (orderType === "LIMIT" || orderType === "MKT")}
              value={"SL"}>Stop Loss</MenuItem>
          </CssSelect>
          {errors.orderType && touched.orderType && (<p className={classes.errorText}>{errors.orderType}</p>)}
        </FormControl>
        {
          (segment === "CURR" || segment === "FNO" || segment === "COMM") ?
            <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")}>
              <Typography className={classes.fieldText}>Lot</Typography>
              <CssOutlinedInput
                id="lot"
                name="lot"
                classes={{ input: classes.input }}
                onChange={handleChange}
                error={touched.lot && errors.lot ? true : false}
                value={lot}
                autoFocus={status === "Modify" ? true : false}
                onKeyUp={() => setFieldTouched("lot", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.lot && touched.lot && (<p className={classes.errorText}>{errors.lot}</p>)}
            </FormControl>

            : <></>
        }
        {segment !== "CURR" && <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")}>
          <Typography className={classes.fieldText}>Quantity</Typography>
          <CssOutlinedInput
            id="quantity"
            name="quantity"
            classes={{ input: classes.input }}
            onChange={handleChange}
            error={touched.quantity && errors.quantity ? true : false}
            value={quantity}
            autoFocus={status === "Modify" && (segment !== "CURR" && segment !== "FNO") ? true : false}
            // disabled={ (segment === "CURR" || segment === "FNO")}
            onKeyUp={() => setFieldTouched("quantity", true)}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          {errors.quantity && touched.quantity && (<p className={classes.errorText}>{errors.quantity}</p>)}
        </FormControl>}
        {
          matchesSm && USER === "client" && ((segment === "CURR" || segment === "FNO")) ?
            <></>
            :
            <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")}>
              <Typography className={classes.fieldText}>Price</Typography>
              <CssOutlinedInput
                id="price"
                name="price"
                onChange={handleChange}
                disabled={orderType === "MKT"}
                error={touched.price && errors.price ? true : false}
                value={price}
                classes={{ input: classes.input }}
                onKeyUp={() => setFieldTouched("price", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.price && touched.price && (<p className={classes.errorText}>{errors.price}</p>)}
            </FormControl>
        }
        {
          matchesSm && validTill === "GTD" ?
            <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")} style={{ paddingRight: "0px" }}>
              <Typography className={classes.fieldText}>Trigger Price</Typography>
              <CssOutlinedInput
                id="triggerPrice"
                name="triggerPrice"
                disabled={orderType === "LIMIT" || orderType === "MKT" || (status === "Modify" && reduxForm.orderType === "SL" && orderStatus == "Triggered")}
                onChange={handleChange}
                error={touched.triggerPrice && errors.triggerPrice ? true : false}
                value={values.triggerPrice}
                classes={{ input: classes.input }}
                onKeyUp={() => setFieldTouched("triggerPrice", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.triggerPrice && touched.triggerPrice && (<p className={classes.errorText}>{errors.triggerPrice}</p>)}
            </FormControl>
            :
            <></>
        }
      </>
    )
  }

  const secondRow = () => {
    return (
      <>
        {
          matchesSm && USER === "client" && ((segment === "CURR" || segment === "FNO")) ?
            <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")}>
              <Typography className={classes.fieldText}>Price</Typography>
              <CssOutlinedInput
                id="price"
                name="price"
                onChange={handleChange}
                disabled={orderType === "MKT"}
                error={touched.price && errors.price ? true : false}
                value={price}
                classes={{ input: classes.input }}
                onKeyUp={() => setFieldTouched("price", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.price && touched.price && (<p className={classes.errorText}>{errors.price}</p>)}
            </FormControl>
            :
            <></>
        }
        {
          (segment === "FNO") ?
            <></>
            :
            <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")}>
              <Typography className={[classes.fieldText, classes.longText].join(" ")}>Disclosed Qty</Typography>
              <CssOutlinedInput
                id="disclosedQuantity"
                name="disclosedQuantity"
                classes={{ input: classes.input }}
                onChange={handleChange}
                error={touched.disclosedQuantity && errors.disclosedQuantity ? true : false}
                value={disclosedQuantity}
                onKeyUp={() => setFieldTouched("disclosedQuantity", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.disclosedQuantity && touched.disclosedQuantity && (<p className={classes.errorText}>{errors.disclosedQuantity}</p>)}
            </FormControl>
        }
        {
          matchesSm && validTill === "GTD" ?
            <></>
            :
            <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")}>
              <Typography className={classes.fieldText}>Trigger Price</Typography>
              <CssOutlinedInput
                id="triggerPrice"
                name="triggerPrice"
                disabled={orderType === "LIMIT" || orderType === "MKT"/*  ||
                  (status === "Modify" && orderType == "SL" && orderStatus == "Triggered") */}
                onChange={handleChange}
                error={touched.triggerPrice && errors.triggerPrice ? true : false}
                value={triggerPrice}
                classes={{ input: classes.input }}
                onKeyUp={() => setFieldTouched("triggerPrice", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.triggerPrice && touched.triggerPrice && (<p className={classes.errorText}>{errors.triggerPrice}</p>)}
            </FormControl>
        }
        <FormControl variant="outlined" className={[classes.fieldDiv, "selectLarge"].join(" ")}>
          <Typography className={classes.fieldText}>Product</Typography>
          <CssSelect
            labelId="demo-simple-select-filled-label"
            id="product"
            name="product"
            value={product}
            onChange={(e) => onProductChange(e.target.value)}
            error={touched.product && errors.product ? true : false}
            onKeyUp={() => setFieldTouched("product", true)}
            className={values.type === "B" ? classes.activeButton : classes.activeSell}
            input={<SelectOutlinedInput />}
            variant="standard"
            IconComponent={() => <Arrow />}
          >
            <MenuItem style={{ width: "111px" }} value={"Delivery"} disabled={
              (status === "Modify" && productType !== "Delivery")
              || (type === "squareOff" && netQty && productType !== "Delivery")}>
              Delivery
            </MenuItem>
            <MenuItem value={"Intraday"} disabled={
              (status === "Modify" && productType !== "Intraday") || type === "SS"
              || (type === "squareOff" && netQty && productType !== "Intraday")}>
              Intraday
            </MenuItem>
            <MenuItem value={"MTF"} disabled={
              (type === "squareOff" && netQty) ? (scrip.segment === "EQ" && exchange === "NSE" && productType === "MTF") ? false : true
                :
                ((status === "Modify" && productType !== "MTF") || values.type !== "B" || scrip.segment !== "EQ" || (scrip.segment === "EQ" && exchange !== "NSE"))}>
              {/* <Tooltip title="Margin Traded Fund">
                <Typography className={classes.mtf}>MTF</Typography>
              </Tooltip> */}
              MTF
            </MenuItem>
          </CssSelect>
          {errors.product && touched.product && (<p className={classes.errorText}>{errors.product}</p>)}
        </FormControl>
        <FormControl variant="outlined" className={[classes.fieldDiv, "select"].join(" ")}>
          <Typography className={classes.fieldText}>Valid Till</Typography>
          <CssSelect
            labelId="demo-simple-select-filled-label"
            id="validTill"
            name="validTill"
            value={validTill}
            onChange={(e) => onValidityChange(e.target.value)}
            error={touched.validTill && errors.validTill ? true : false}
            onKeyUp={() => setFieldTouched("validTill", true)}
            className={values.type === "B" ? classes.activeButton : classes.activeSell}
            input={<SelectOutlinedInput />}
            variant="standard"
            IconComponent={() => <Arrow />}
          >
            <MenuItem style={{ width: "81px" }} value={"GFD"}>GFD</MenuItem>
            <MenuItem value={"IOC"}>IOC</MenuItem>
            {segment === "COMM" && product !== "Intraday" && <MenuItem value={"GTC"}>GTC</MenuItem>}
            {segment === "COMM" && product !== "Intraday" && <MenuItem value={"GTD"}>GTD</MenuItem>}
          </CssSelect>
          {errors.validTill && touched.validTill && (<p className={classes.errorText}>{errors.validTill}</p>)}
        </FormControl>
        {
          validTill === "GTD" &&
          <FormControl variant="outlined" className={[classes.fieldDiv, "selectLarge"].join(" ")} style={USER !== "client" ? { width: "130px" } : { paddingRight: 0, width: "130px" }}>
            <Typography className={classes.fieldText}>Validity Date</Typography>
            <CssSelect
              labelId="demo-simple-select-filled-label"
              id="validityDate"
              name="validityDate"
              value={validityDate}
              onChange={handleChange}
              error={touched.validityDate && errors.validityDate ? true : false}
              onKeyUp={() => setFieldTouched("validityDate", true)}
              className={classes.select}
              input={<SelectOutlinedInput />}
              variant="standard"
              IconComponent={typeof (validityDateData) === "string" ? () => (<CircularProgress style={{ marginRight: "4px" }} color="inherit" size={16} />) : () => (<Arrow />)}
            >
              {
                validityDateData && typeof (validityDateData) !== "string" && validityDateData.length > 0 ?
                  validityDateData.map((ele) => (
                    <MenuItem value={ele} className={classes.menuItem}>{ele}</MenuItem>
                  ))
                  :
                  <MenuItem value={""} className={classes.menuItem}>No Result Found</MenuItem>
              }
            </CssSelect>
            {errors.validityDate && touched.validityDate && (<p className={classes.errorText}>{errors.validityDate}</p>)}
          </FormControl>
        }
        {
          USER !== "client" ?
            <>
              <FormControl variant="outlined" className={[classes.fieldDiv, "compact"].join(" ")} style={{ width: "160px", paddingRight: 0 }}>
                <Typography className={[classes.fieldText, classes.longText].join(" ")}>Client Code</Typography>
                <ClientSearchBox
                  customWidth={true}
                  className={[classes.searchBox, showClientError ? "error" : ""].join(" ")}
                  setClient={false}
                  // placeholder={(status === "Modify" || type === "sell" || type === "squareOff") ? clientCode : selectedClient !== null && selectedClient.ClientCode ? `${selectedClient.ClientCode} - ${selectedClient.ClientName}` : ""}
                  placeholder={Object.keys(selectClient).length > 0 ? selectClient.ClientCode !== "" ? selectClient.ClientName !== "" ? `${selectClient.ClientName} - ${selectClient.ClientCode}` : selectClient.ClientCode : "" : ""}
                  disabled={status === "Modify" || type === "sell" || type === "squareOff" || selectedClient !== null}
                  onSelect={(item) => { setSelectClient(item); setClientError(false); handleFocus(item) }}
                  noAutoFocus={true}
                  clientChange={() => initializeSelectClient()}
                />
                {showClientError && (<p className={classes.errorText}>{"Client Code is required"}</p>)}
              </FormControl>
            </>
            :
            <></>
        }
      </>
    )
  }

  const approxGrid = () => {
    return (
      <Grid item xl={6} lg={8} md={7} xs={12} className={classes.approxMarginDiv}>
        <FormControl variant="outlined" style={{ margin: 0 }}>
          <FormControlLabel
            id="approxMargin"
            name="approxMargin"
            control={
              <CssSwitch
                checked={approxMargin}
                onChange={!isMTFApproved ? () => setInfoPopup({ show: true, message: "This scrip is not allowed to trade in MTF." }) : handleChange}
                className={classes.switchIcon}
              />
            }
            label=""
            classes={{ root: classes.switchField, disabled: classes.switchText }}
            disabled={(type === "squareOff" && netQty) || status === "Modify"}
          />
        </FormControl>
        <Typography className={classes.switchText}>Buy upto 4 times of your available balance with just 0.06% interest per day</Typography>
      </Grid>
    )
  }

  const calculateApproxMargin = (show) => {
    return (
      <Grid item md={!show ? 12 : 6} xs={!show ? 12 : 6} style={{ marginBottom: 0, display: 'flex', justifyContent: 'space-between', marginTop: show ? 8 : 0 }}>
        <div className={classes.centerContent}>
          <Typography variant="caption" style={{ paddingLeft: 8 }} className={classes.belowText}>Approx. Margin: {approxMarginData.margin || 0} {segment === "EQ" && `(${approxMarginData.multiple} x)`} </Typography>
          {
            (optionType && (optionType !== "XX" && values.type === "B")) || segment === "EQ" ?
              <Tooltip title={'Refresh Approx Margin'}>
                <IconButton
                  aria-label={'refresh'}
                  style={{ padding: '4px 0px 0px 4px' }}
                  onClick={handleRefreshApproxMargin}
                >
                  <RefreshIcon className={classes.refreshIcon} />
                </IconButton>
              </Tooltip>
              :
              <></>
          }
        </div>
        {segment === "CURR" && <Typography variant="caption" className={[classes.belowText, "lot"].join(" ")}>1 Lot = 1000 Qty </Typography>}
      </Grid>
    )
  }

  return (
    <>
      <div className={[classes.elementSpacing, classes.compactConfirm].join(" ")} style={{ paddingBottom: "4px", position: 'relative', flexDirection: "column" }}>
        {
          matchesSm ?
            <>
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {firstRow()}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {secondRow()}
                </div>
                <Grid container className={classes.approxGrid}>
                  {USER === "client" && scrip.segment === "EQ" && approxGrid()}
                  {calculateApproxMargin(USER === "client" && scrip.segment === "EQ")}
                </Grid>
              </div>
              <span className={classes.orderHelpCompact}>
                <IconButton onClick={() => setHelpPopup(true)} className={classes.infoButton}>
                  <InfoIcon color="primary" className={classes.info} />
                </IconButton>
              </span>
            </>
            :
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              {firstRow()}
              {secondRow()}
              <span className={classes.orderHelpCompact}>
                <IconButton onClick={() => setHelpPopup(true)} className={classes.infoButton}>
                  <InfoIcon color="primary" className={classes.info} />
                </IconButton>
              </span>
            </div>
        }
        {!matchesSm &&
          <Grid container className={classes.approxGrid}>
            {USER === "client" && scrip.segment === "EQ" && approxGrid()}
            {calculateApproxMargin(USER === "client" && scrip.segment === "EQ")}
          </Grid>
        }
      </div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item lg={2} md={3} sm={3} xs={6}>
          <Button ref={orderFocus} disabled={submitting} className={[classes.order, classes.containedBlue].join(" ")} type="submit">
            {submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}{status === "Modify" ? "Modify Order" : "Place Order"}
          </Button>
        </Grid>
        <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
          <Typography onClick={handleBrokerageCharges} variant="h6" style={{ cursor: "pointer", fontWeight: 600, justifyContent: "flex-end" }} className={[classes.confirmBlueText, classes.centerContent].join(" ")}>
            Brokerage Charges <span style={{ padding: "4px 0px 0px 4px" }}><ExpandMoreIcon /></span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BrokerageCharges
            compactView={compactView}
            brokerageCharges={brokerageCharges}
            showBrokerageCharges={showBrokerageCharges}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(combineStyles(styles, commonStyles))(CompactOrderForm)