const onSingleClick = (e, callback) => {
  const { detail } = e;
  if (detail > 1) {
      e.preventDefault();
      e.stopPropagation();
      console.log(`Button clicked more than once: ${detail}, className: ${e.target.className}`);
  } else {
      //Actual code here
      callback && callback();
  }
}

export default {
  onSingleClick,
}