import { MODAL_CHILD } from "widgets/FormDialog";
import { store } from "helpers";
import { bannerService } from "services/banners";

//Actions
const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const DialogActions = {
  openAccount,
  openCreateWatchList,
  openEditWatchList,
  openSettings,
  openOrderHistory,
  openOrderForm,
  dialogOpen,
  dialogClose,
  openKeyboardShortcuts,
  openFundExplorer,
  openHelp,
  openTourDialog,
  openAddLimit,
  openEnableTotp,
  openAdminProfile,
  openFnoRiskPopup,
  openNomineePopup,
  openBannersPopup
}

const InitialState = {
  modalChild: null,
  data: null,
  isOpen: false
};

function openOrderForm(data) {
  store.dispatch(openDialog(MODAL_CHILD.ORDER, data));
}

function openOrderHistory(data) {
  store.dispatch(openDialog(MODAL_CHILD.ORDER_HISTORY, data));
}

function openAccount() {
  store.dispatch(openDialog(MODAL_CHILD.ACCOUNT));
}

function openAdminProfile() {
  store.dispatch(openDialog(MODAL_CHILD.ADMIN_PROFILE));
}

function openCreateWatchList() {
  store.dispatch(openDialog(MODAL_CHILD.WATCHLIST.CREATE));
}

function openEditWatchList() {
  store.dispatch(openDialog(MODAL_CHILD.WATCHLIST.EDIT));
}

function openSettings() {
  store.dispatch(openDialog(MODAL_CHILD.SETTINGS));
}

function openKeyboardShortcuts() {
  store.dispatch(openDialog(MODAL_CHILD.KEYBOARD_SHORTCUTS));
}

function openFundExplorer() {
  store.dispatch(openDialog(MODAL_CHILD.FUND_EXPLORER));
}

function openTourDialog() {
  store.dispatch(openDialog(MODAL_CHILD.TOUR_DIALOG));
}

function dialogOpen(child, data) {
  store.dispatch(openDialog(child, data));
}

function dialogClose() {
  store.dispatch(closeDialog());
}

function openHelp(data) {
  store.dispatch(openDialog(MODAL_CHILD.HELP, data));
}

function openAddLimit(data) {
  store.dispatch(openDialog(MODAL_CHILD.ADD_LIMIT, data));
}

function openEnableTotp(data) {
  store.dispatch(openDialog(MODAL_CHILD.TOTP, data));
}

function openFnoRiskPopup() {
  store.dispatch(openDialog(MODAL_CHILD.FNO_RISK_POPUP));
}

function openNomineePopup() {
  store.dispatch(openDialog(MODAL_CHILD.NOMINEE_POPUP));
}

async function openBannersPopup() {
  // const response = await bannerService.getBanners()
  // if (response?.data.success?.bannerList?.length > 0) {
  //   store.dispatch(openDialog(MODAL_CHILD.BANNERS_POPUP, response?.data.success?.bannerList))
  // }
  return; 
}

//Actions Creators
export function openDialog(child, data) {
  return { type: OPEN_DIALOG, modalChild: child, data };
}

export function closeDialog() {
  return { type: CLOSE_DIALOG };
}


//Reducer
export function dialog(state = InitialState, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        modalChild: action.modalChild,
        data: action.data,
        isOpen: true
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        modalChild: null,
        data: null,
        isOpen: false
      };;
    default:
      return state
  }
}
