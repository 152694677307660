export default ({ palette }) => ({
  styleOverrides: {
    fixedHeaderCommon: {
      borderLeft: `1px solid ${palette.type === 'light' ? '#EDF1F4' : '#3C455A'}`,
      zIndex: 90
    },
    headerCell: {
      zIndex: 99,
      backgroundColor: palette.background.tableHead
    }
  }
});
