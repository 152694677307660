import { Button, CircularProgress, Typography } from "@mui/material";
import ResendButton from "components/ResendButton";
import React, { useState } from "react";
import { CssOutlinedInput } from "widgets/order/customfields";

function SendTotp(props) {
  const { classes, loading, emailId, mobileNumber, resendOtp, verifyOtp } = props;

  const [value, setValue] = useState('');

  function getError() {
    if (value) {
      if (!Number(value)) {
        return 'Please enter valid otp'
      } else if (Number(value) && value.length > 6) {
        return 'Only 6 digits allowed'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  return (
    <div>
      <Typography variant='h1' className={[classes.blueText, classes.heading].join(' ')}>E-mail & Mobile No. Verification</Typography>
      <div className={classes.containerGrid}>
        <Typography variant='h4' className={classes.secondaryText}>Enter the OTP sent to your registered Mobile No. & Email ID</Typography>
        <Typography variant='h4' className={classes.email}>{emailId || ''}</Typography>
        <Typography variant='h4' className={classes.primaryText}>{mobileNumber || ''}</Typography>
        <div className={classes.inputDiv}>
          <div>
            <CssOutlinedInput
              id="otp"
              name="otp"
              autoFocus={true}
              className={classes.otpInputField}
              classes={{ input: classes.input }}
              value={value}
              error={value ? (value.length > 6 || !Number(value)) : false}
              onChange={(e) => setValue(e.target.value)}
            />
            {value ? <Typography className={classes.error}>{getError()}</Typography> : <></>}
          </div>
          <Button
            className={classes.button}
            onClick={() => verifyOtp({ otp: value })}
            disabled={
              (loading.step === 3 && loading.submit) ||
              !Number(value) || (value && ((value.length < 6 || value.length > 6)
                || (String(value).trim().length < 6 || String(value).trim().length > 6)))}
            variant="contained">
            {loading.step === 3 && loading.submit && <CircularProgress size={20} style={{ marginRight: "12px", color: '#999999' }} />}
            Verify
          </Button>
        </div>
        <div>
          <Typography variant='h5' className={classes.otpText}>Didn't receive OTP ? <span className={classes.resend}><ResendButton onClick={() => resendOtp()} resendLimit={1000} timeOut={59} /></span>{/*  : 00:00 */}</Typography>
        </div>
      </div>
    </div>
  )
}

export default SendTotp;