import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import { alertReducer } from 'redux/Alert';
import { clientReducer } from 'redux/Client';
import { commonReducer } from 'redux/Common';
import { confirmDialog } from 'redux/ConfirmDialog';
import { dialog } from 'redux/Dialog';
import { fundExplorerReducer } from "redux/FundExplorer";
import { layoutReducer } from 'redux/Layout';
import { authentication } from 'redux/Login';
import { popper } from 'redux/Popper';
import { prefsReducer } from 'redux/Prefs';
import { tourReducer } from "redux/Tour";
import { updatesReducer } from "redux/Updates";
import { watchListReducer } from 'redux/Watchlist';
import { watchListMFReducer } from "redux/WatchlistMF";
import { CommonReducer } from "./CommonReducer/common";
import { gridReducer } from 'redux/NewGrid';
import { reportsReducer } from "redux/Reports";

//const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
    authentication,
    alert: alertReducer,
    form: formReducer,
    watchlist: watchListReducer,
    watchlistMF: watchListMFReducer,
    dialog,
    confirmDialog,
    layout: layoutReducer,
    common: commonReducer,
    hCommon: CommonReducer,
    popper,
    client: clientReducer,
    updates: updatesReducer,
    prefs: prefsReducer,
    fundExplorer: fundExplorerReducer,
    tour: tourReducer,
    newGrid: gridReducer,
    reports: reportsReducer,
});

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware
        //loggerMiddleware
    )
);





