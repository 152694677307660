export default ({ palette }) => ({
  styleOverrides: {
    root: {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        // border: 'none',
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          // border: 'none',
        },
      },
    },
    input: {
      padding: '6px 0 7px 6px'
    }
  }
});
