export default theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        // borderColor: theme.palette.background.default + '30',
        // borderColor: theme.palette.border.default,
        // borderWidth: '.5px',
        // borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.secondary,
        margin: "0 5px",
        width: '100%',
        [theme.breakpoints.down('xl')]: {
            height: '30px'
        },
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        color: theme.palette.color15.default,
        width: theme.spacing(7),
        height: '100%',
        // position: 'absolute',
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        '&:disabled': {
            color: theme.palette.common.muted
        }
    },
    inputRoot: {
        // color: 'inherit',
        width: '100%',
    },
    inputInput: {
        fontSize: 18,
        '&::placeholder': {
            // textOverflow: 'ellipsis !important',
            color: theme.palette.text.primary,
        },
        color: theme.palette.text.primary,
        // padding: theme.spacing(1, 1, 1, 7),
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.down('xl')]: {
            fontSize: 14
        }, 
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        },  
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            // '&:focus': {
            //     width: 200,
            // },
        },
    },
})