import styled from "styled-components";

export default styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: #006CA8;
    display: flex;
    flex-direction: column;
    width: 25vw;
`;
