import React/* , { useEffect, useState } */ from 'react';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { Paper } from '@mui/material';
import { connect } from 'react-redux';
// import { bannerService } from 'services/banners';
// import Placeholder from 'components/Placeholder';
import Carousel from 'nuka-carousel';
import { NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { setCookieNew, getCookie } from 'helpers/cookies';

const NavigateBefore = withStyles(theme => ({
  root: {
    color: theme.palette.color15.default
  }
}))(NavigateBeforeIcon)

const NavigateNext = withStyles(theme => ({
  root: {
    color: theme.palette.color15.default
  }

}))(NavigateNextIcon)


function Banners(props) {
  const { classes, banners } = props;
  // const [banners, setBanners] = useState("Loading...")

  // async function getBannersData() {
  //   const response = await bannerService.getBanners()
  //   if (response?.data.success?.bannerList?.length > 0) {
  //     setBanners(response?.data.success?.bannerList || [])
  //   } else {
  //     setBanners(typeof response?.data?.error === "string" ? response?.data.error : "No Data Found")
  //   }
  // }

  // useEffect(() => {
  //   getBannersData();
  // }, []);

  return (
    <Paper className={[classes.root, banners?.length === 1 ? classes.centerContent : ""].join(" ")}>
      {
        // typeof banners === 'string' ?
        //   <div className={classes.loadingDiv}>
        //     <Placeholder loading={banners === 'Loading...' ? true : false} error={banners || ''} />
        //   </div>
        //   :
        banners?.length > 0 &&
        <Carousel
          autoplay={banners?.length > 1 ? true : false}
          showDots={banners?.length > 1 ? true : false}
          slidesToShow={1}
          renderCenterLeftControls={({ previousSlide, previousDisabled }) => (
            banners?.length > 1 &&
            <div
              className={[classes.arrow, previousDisabled ? "disabled" : ""].join(" ")}
              onClick={previousSlide}
            >
              <NavigateBefore className={[classes.svg].join(" ")} />
            </div>
          )}
          renderCenterRightControls={({ nextSlide, nextDisabled }) => (
            banners?.length > 1 &&
            <div
              className={[classes.arrow, nextDisabled ? "disabled" : ""].join(" ")}
              onClick={nextSlide}
            >
              <NavigateNext className={[classes.svg, "right"].join(" ")} />
            </div>
          )}
          defaultControlsConfig={banners?.length > 1 ? {
            pagingDotsContainerClassName: classes.pagingDotsContainer,
            pagingDotsClassName: classes.pagingDots,
          } : {
            pagingDotsStyle: { display: 'none' }
          }}
        >
          {banners.map(ele => (
            ((ele.imageFilePath && ele.imageFilePath !== "null") || (ele.mobileImageFilePath && ele.mobileImageFilePath !== "null")) &&
            <div className={classes.imageDiv}>
              <img
                onClick={() => {
                  if (ele.redirectUrl && ele.redirectUrl !== "null") {
                    if (getCookie("showBanner") === "Yes") {
                      setCookieNew("showBanner", "No")
                    }
                    window.open(ele.redirectUrl, "_blank")
                  } else {
                  }
                }}
                src={`${process.env.REACT_APP_STRAPI_URL}uploads/${ele.imageFilePath || ele.mobileImageFilePath}`}
                className={classes.image}
              />
            </div>
          ))}
        </Carousel>
      }
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return { banners: state.dialog.data || [] }
};

export default connect(mapStateToProps)(withStyles(styles)(Banners));