import { Box } from "@mui/material";
import { getPayload1 } from "helpers/subscription";

export function TabPanel(props) {
  const { children, value, index, boxClass, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={boxClass}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%", overflowY: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const transactionColumns = [
  {
    label: 'Date',
    name: 'tradeDate'
  },
  {
    label: `Quantity`,
    name: 'quantity',
    align: "right"
  },
  {
    label: 'Price',
    name: 'price',
    align: "right",
    toFixed: true
  },
  {
    label: 'Total',
    name: 'total',
    align: "right",
    toFixed: true
  },
  {
    label: 'Action',
    name: 'action',
    align: "center"
  }
]

export const portfolioTabs = ["PRODUCT VIEW", "SEGMENT VIEW"]
export const productTabs = ["ALL", "SELF", "GUIDED"]
export const segmentTabs = ["SUMMARY", "EQUITY", "MUTUAL FUND"]


export const eqTabs = ["By Name", "By Sector"/* , "By Industry" */]
export const mfTabs = ["By AMC", "By Scheme"/* , "By Investment Type", "By Current Value" */]

export const selfTabs = ["By Name", "By Sector"/* , "By Industry" */]
export const guidedTabs = ["By AMC", "By Scheme"/* , "By Investment Type", "By Current Value" */]

export const mfData = {
  "totalProfitInPerc": 0,
  "clientCode": "A0379",
  "purchaseValue": 0,
  "totalProfit": 0,
  "todaysProfit": 0,
  "todaysProfitInPerc": 0,
  "clientName": "ANITA NILESH JAIN",
  "currentValue": 0
}

export const overallData = {
  "totalProfitInPerc": 0,
  "clientCode": "A0379",
  "purchaseValue": 0,
  "totalProfit": 0,
  "todaysProfit": 0,
  "todaysProfitInPerc": 0,
  "clientName": "ANITA NILESH JAIN",
  "currentValue": 0
}

export const chartKeys = {
  equity: {
    0: {
      key: "scrip_wise",
      value: "scripAllocation",
      label: "scripName",
      color: ["#FAC174", "#F39CC3", "#27A4DD", "#F1646C", "#9DD5C0", "#9CB6F3"],
      topString: "TOP 5 Scrip"
    },
    1: {
      key: "sector_wise",
      value: "scripSectorAllocation",
      label: "scripSector",
      color: ["#FAC174", "#F39CC3", "#27A4DD", "#F1646C", "#9DD5C0", "#9CB6F3"],
      topString: "TOP 5 Sector"
    },
    /* 2: {
      key: "industry_wise",
      value: "",
      label: "",
      color: ["#FAC174", "#F39CC3", "#27A4DD", "#F1646C", "#9DD5C0"],
      topString: "TOP 5 Industry"
    } */
  },
  mf: {
    0: {
      key: "amc_wise",
      value: "amcallocation",
      label: "amcname",
      color: ["#5DB493", "#FFC551", "#9CB6F3", "#C091FF", "#27A4DD","#F1646C"],
      topString: "TOP 5 AMC"
    },
    1: {
      key: "scheme_wise",
      value: "schemeAllocation",
      label: "schemeName",
      color: ["#5DB493", "#FFC551", "#9CB6F3", "#C091FF", "#27A4DD", "#F1646C"],
      topString: "TOP 5 Scheme"
    },
    /*  2: {
       key: "investment_wise",
       value: "scripSectorAllocation",
       label: "scripSector",
       color: ["#5DB493", "#FFC551", "#9CB6F3", "#C091FF", "#27A4DD"]
     },
     3: {
       key: "value_wise",
       value: "scripSectorAllocation",
       label: "scripSector",
       color: ["#5DB493", "#FFC551", "#9CB6F3", "#C091FF", "#27A4DD"]
     } */
  },
  overall: {
    0: {
      key: "overall",
      value: "assetValue",
      label: "assetAllocation",
      color: ["#9DD5C0", "#FAC174"]
    }
  }
}

export const productChartKeys = {
  self: {
    value: "value",
    label: "label",
    color: ["#9DD5C0", "#FAC174"],
    total: "totalValue"
  },
  guided: {
    value: "value",
    label: "label",
    color: ["#9DD5C0", "#FAC174"],
    total: "totalValue"
  },
  overall: {
    value: "value",
    label: "label",
    color: ["#135E7A", "#3E96B7"],
    total: "totalValue"
  }
}

export const getArray = (data, key, valueKey, count) => {
  let array = [];
  let otherTotalValue = 0;
  data.forEach((ele, index) => {
    if (valueKey) {
      if (count && index + 1 > count) {
        if (index == data.length - 1) {
          array.push("Others")
        } else {}
      } else {
        array.push(ele[key])
      }
    } else {
      if (count && index + 1 > count) {
        if (index == data.length - 1) {
          array.push(otherTotalValue)
        } else {
          otherTotalValue = otherTotalValue + Number(ele[key])
        }
      } else {
        array.push(ele[key])
      }
    }
  })
  return array || []
}

export const getLegendArray = (data, valueKey, count, label) => {
  let array = [];
  let otherTotalValue = 0;
  data.forEach((ele, index) => {
    if (count && index + 1 > count) {
      if (index == data.length - 1) {
        otherTotalValue = otherTotalValue + Number(ele[valueKey])
        array.push({ [valueKey]: otherTotalValue, [label]: 'Others' })
      } else {
        otherTotalValue = otherTotalValue + Number(ele[valueKey])
      }
    } else {
      array.push(ele)
    }
  })
  return array || []
}

export const truncateNumber = (num) => {
  num = num.toString()
  num = num.slice(0, (num.indexOf(".")) + 3)
  return Number(num).toFixed(2)
}

const truncateNumberCrore = (num) => {
  num = num.toString()
  num = num.slice(0, (num.indexOf(".")) + 3)
  num = Number(num).toFixed(2)
  return Number(num).toLocaleString("en-IN")
}

export const NumberFormatter = (number) => {
  if (number >= 10000000) {
    return truncateNumberCrore(number / 10000000)/* .toFixed(2).replace(/\.0$/, '') */ + ' ' + 'Cr';
  }
  if (number >= 100000) {
    return truncateNumber(number / 100000)/* .toFixed(2).replace(/\.0$/, '') */ + ' ' + 'L';
  }
  if (number >= 1000) {
    return truncateNumber(number / 1000)/* .toFixed(2).replace(/\.0$/, '') */ + ' ' + 'K';
  }
  return Number(number).toFixed(2)
}

export const NumberSeparator = (number) => {
  if (number) {
    let value = Number(number).toFixed(2)
    let numValue = Number(value.split(".")[0]).toLocaleString('en-IN') + "." + value.split(".")[1]
    return numValue;
  }
  return "0.00"
}

export const feedValuesCalculation = (data, key, mfData) => {
  let currentValue = key === "equity" ? data.ltp * parseInt(data.quantity) : key === "mf" ? sumOfValues('currentValue', data) + Number(mfData.currentValue || 0) : sumOfValues('currentValue', data)
  let value = key === "equity" ? parseFloat(data.closingRate) * parseInt(data.quantity) : sumOfValues('value', data)
  let purchaseValue = key === "equity" ? Number(data.purchaseValue) : key === "mf" ? sumOfValues('purchaseValue', data) + Number(mfData.purchaseValue || 0) : sumOfValues('purchaseValue', data)
  let obj = {
    currentValue,
    value,
    purchaseValue,
    todaysProfit: key === "mf" ? ((sumOfValues('currentValue', data) - value) + mfData.todaysProfit) : currentValue - value,
    todaysProfitInPerc: key === "mf" ? ((((sumOfValues('currentValue', data) - value) + mfData.todaysProfit) / currentValue) * 100) : currentValue > 0 ? ((currentValue / value) - 1) * 100 : 0.00,
    totalProfit: currentValue - purchaseValue,
    totalProfitInPerc: currentValue > 0 ? ((currentValue / purchaseValue) - 1) * 100 : 0.00
  }
  return obj
}

const sumOfValues = (key, data) => {
  if (data && data.length > 0) {
    let value = 0
    data.forEach(ele => {
      value = value + Number(ele[key])
    })
    return value
  }
  return null
}

export const fundColors = {
  equity: "#5FBB4E",
  mf: "#ED9406",
  both: "#0388ED"
}

export const api = {
  product: {
    portfolio: "/ms-trading-portfolio/trading/portfolio/product",
    details: "/ms-trading-portfolio/trading/portfolio/goalTracker",
    registeredSip: "/ms-mf-onlinereport/mutualfund/report/mfsip"
  }
}

export const riskText = {
  moderateHigh: "Moderately High",
  moderateLow: "Moderately Low",
  moderate: "Moderate",
  low: "Low",
  high: "High"
}

export function overallProductChart(data) {
  let arr = []
  if (data.selfPer) {
    arr.push({
      value: data.selfPer,
      label: "Self",
      counts: {
        count1: data.selfStock,
        count2: data.selfScheme
      }
    })
  }
  if (data.guidedPer) {
    arr.push({
      value: data.guidedPer,
      label: "Guided",
      counts: {
        count1: data.guidedStock,
        count2: data.guidedScheme
      }
    })
  }
  return arr
}

export function productChartValues(data) {
  let arr = []
  if (data.equityPercentage) {
    arr.push({
      value: data.equityPercentage,
      totalValue: data.equity,
      totalLabel: "Purchase Value",
      label: "Equity"
    })
  }
  if (data.mfPercentage) {
    arr.push({
      value: data.mfPercentage,
      totalValue: data.mf,
      label: "Mutual Fund"
    })
  }
  return arr
}

export function getFeedPayload(data) {
  var formatted = [];
  data.map((scrip) => formatted.push(getPayload1(scrip, true)));
  return formatted;
}

export function getSellEquityObj(ele) {
  return {
    stock_name: ele.scripName,
    symbol: ele.symbol,
    exchange: ele.exchange,
    segment: ele.segment,
    stock_price: ele.ltp,
    min_qty: ele.quantity,
    token: ele.token,
    scripId: ele.scripID,
    shortCode: ele.shortCode
  }
}

export function getSellMfObj(ele) {
  return {
    fund_name: ele.schemeName,
    symbol: ele.symbol,
    free_units: ele.balanceUnits,
    nav_price: ele.nav,
    current_price: ele.nav * ele.balanceUnits,
    schemeCode: ele.schemeCode,
    isinNumber: ele.isinNumber,
    minAmount: ele.minRedemptionAmount,
    maxAmount: ele.nav * ele.balanceUnits,
    minimumRedeemUnits: ele.minRedemptionQty
  }
}