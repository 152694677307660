import { Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import styles from "./styles";
import Table from "components/MaterialTable";

function Bank(props) {
  const { bankdp, classes/* , isMaximized */ } = props
  return (
    // isMaximized ?
      <div className={classes.tabPanel}>
        <div className={classes.tableDiv}>
          <Typography variant="h3" className={classes.heading}>BANK INFORMATION</Typography>
          {
            bankdp && bankdp.bankInfo && (Object.keys(bankdp.bankInfo).length > 0 || (bankdp.bankInfo.columns && bankdp.bankInfo.columns.length > 0)) ?
              <Table
                maxHeight={450}
                data={bankdp.bankInfo.filterdata || []}
                columns={bankdp.bankInfo.columns || []}
              />
              :
              <div className={classes.noDataDiv}>
                No data found
            </div>
          }
          
        </div>
        <div className={classes.tableDiv}>
          <Typography variant="h3" className={classes.heading}>DEMAT ACCOUNT INFORMATION</Typography>
          {
            bankdp && bankdp.dpInfo && (Object.keys(bankdp.dpInfo).length > 0 || (bankdp.dpInfo.columns && bankdp.dpInfo.columns.length > 0)) ?
              <Table
                maxHeight={450}
                data={bankdp.dpInfo.filterdata || []}
                columns={bankdp.dpInfo.columns || []}
              />
              :
              <div className={classes.noDataDiv}>
                No data found
            </div>
          }
        </div>
      </div>
      // :
      // <div className={classes.noData} style={{height: "285px"}}>
      //   No data found
      // </div>
  )
}

export default withStyles(styles)(Bank)