export default theme => ({
  activeOrder: {
    width: "99px",
    color: '#000000de',
    borderRadius: "5px 0px 0px 5px",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    '&.activeColor': {
      color: "#fff"
    },
    '&.active': {
      color: "#fff",
      backgroundColor: "#006CA8"
    },
    [theme.breakpoints.down('xl')]: {
      width: "85px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "80px",
      padding: "3px 8px"
    },
    [theme.breakpoints.down('md')]: {
      width: "70px"
    },
  },
  text: {
    // fontSize: "22px",
    fontWeight: 500,
    marginBottom: "6px",
    color: theme.palette.color12.default,
    "&.switch": {
      paddingBottom: "12px",
      fontSize: "18px",
      [theme.breakpoints.down('xl')]: {
        paddingBottom: "6px",
        fontSize: "14px",
      },
      [theme.breakpoints.down('lg')]: {
        paddingBottom: "4px",
        fontSize: "12px",
      },
    }
  },
  order: {
    width: "100%",
    textTransform: "none",
    color: "#fff",
    marginBottom: theme.spacing(1),
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "3px 8px"
    },
  },
  default: {
    // letterSpacing: "0.8px",
    // fontSize: theme.spacing(2),
    color: theme.palette.color15.default,
  },
  errorDiv: {
    color: " #721c24",
    backgroundColor: "#f8d7da",
    borderColor: "#f5c6cb",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "4px",
    textAlign: "center",
    "&.warning": {
      color: "#856404",
      backgroundColor: "#fff3cd",
      borderColor: "#ffeeb",
      marginBottom: "8px"
    }
  },
  cancel: {
    width: "100%",
    background: "#ffffff",
    textTransform: "none",
    border: "1px solid #006ca84a",
    color: "#006CA8",
    boxShadow: "0px 3px 5px 0px #006ca863",
    marginTop: "8px",
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#e6eaf1a6"
    },
    [theme.breakpoints.down('xl')]: {
      padding: "5px 8px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px 8px"
    },
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  defaultColor: {
    color: theme.palette.color15.default,
  },
  value: {
    marginLeft: theme.spacing(1),
    color: theme.palette.color12.default,
    // fontSize: theme.spacing(2.5),
    // letterSpacing: "0.8px"
  },
  grid: {
    textAlign: "right"
  },
  fieldDiv: {
    marginBottom: "16px",
    width: "100%",
    [theme.breakpoints.down('xl')]: {
      marginBottom: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: "8px",
    },
  },
  belowText: {
    color: theme.palette.color15.default,
    // fontSize: "12px"
  },
  elementSpacing: {
    display: "flex",
    justifyContent: "space-between"
  },
  checkboxRoot: {
    color: theme.palette.text.primary,
    padding: "0px 8px"
  },
  fieldText: {
    marginBottom: theme.spacing(0.25),
    color: theme.palette.color15.default,
    fontSize: "12px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px"
    }
  },
  top: {
    padding: "8px 8px 16px",
    [theme.breakpoints.down('xl')]: {
      padding: "4px 8px 12px",
    },
  },
  legend: {
    position: "relative",
    marginLeft: "28px",
    [theme.breakpoints.down('xl')]: {
      marginLeft: "24px",
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: "16px",
    },
  },
  fieldSet: {
    margin: "0px 8px 16px 8px",
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default
  },
  approxDiv: {
    padding: "8px 0px 20px 0px",
    borderBottom: `2px dashed #66788a4d`
  },
  select: {
    border: "1px solid #66788a4d",
    // width: "220px",
    color: theme.palette.color12.default
  },
  errorText: {
    color: "#f54040",
    marginTop: "4px"
  },
  activeSell: {
    background: "#EEBABA",
    color: "#f54040",
    '&:hover': {
      backgroundColor: "#c9b8b8"
    }
  },
  confirmFieldSet: {
    margin: "0px 20px 8px 0px",
    borderRadius: "0px 0px 6px 6px",
    borderLeft: `1px solid ${theme.palette.color5.default}`,
    borderRight: `1px solid ${theme.palette.color5.default}`,
    borderBottom: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.darker
  },
  valueGrid: {
    height: "40px",
    paddingLeft: theme.spacing(1),
    borderBottom: `1px solid #66788a4d`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('xl')]: {
      height: "36px"
    },
    [theme.breakpoints.down('lg')]: {
      height: "32px"
    },
    [theme.breakpoints.down('md')]: {
      height: "28px"
    },
  },
  colorDiv: {
    height: "8px",
    borderRadius: "20px 20px 0px 0px",
    background: "#299D00"
  },
  confirmTop: {
    margin: "24px 20px 0px 20px",
    paddingBottom: "20px",
    borderBottom: `2px dashed ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      margin: "16px 16px 0px 16px",
      paddingBottom: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      margin: "12px 16px 0px 16px",
      paddingBottom: "8px"
    },
    [theme.breakpoints.down('md')]: {
      margin: "16px 16px 0px 16px",
      paddingBottom: "6px"
    },
  },
  balance: {
    fontSize: "18px",
    lineHeight: "20px",
    margin: "10px 0px 30px 0px",
    color: theme.palette.color12.default,
    [theme.breakpoints.down('xl')]: {
      fontSize: "16px",
      lineHeight: "18px",
      margin: "6px 0px 24px 0px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "12px",
      lineHeight: "14px",
    }
  },
  link: {
    color: "#497CAC"
  },
  defaultButton: {
    background: theme.palette.background.darker,
    color: theme.palette.color15.default,
    border: `1px solid #66788a4d`,
    height: theme.spacing(3.5),
    fontSize: theme.spacing(1.5),
    textTransform: "none",
    marginBottom: theme.spacing(2.5),
    padding: "6px",
    marginRight: "8px",
    marginTop: "26px",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    "&.active": {
      background: "#BAEEE2",
      color: "#046E57",
      '&:hover': {
        backgroundColor: "#d8f3ed99"
      }
    },
    "&.activeRedeem": {
      background: "#EEBABA",
      color: "#f54040",
      '&:hover': {
        backgroundColor: "#c9b8b8"
      }
    },
    [theme.breakpoints.down('xl')]: {
      marginRight: "6px",
      height: "24px",
      marginTop: "20px",
      fontSize: "10px",
      marginBottom: "10px"
    },
    [theme.breakpoints.only('lg')]: {
      minWidth: "54px"
    },
    [theme.breakpoints.only('md')]: {
      minWidth: "50px"
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: "18px",
      marginBottom: "12px",
      height: "22px",
      fontSize: "9px"
    },
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? "#292e3e0a": "#ffffff14",
      border: `1px solid #66788a4d`,
    }
  },
  divMargin: {
    marginRight: theme.spacing(2.5)
  },
  activeButton: {
    background: "#BAEEE2",
    color: "#046E57",
    '&:hover': {
      backgroundColor: "#d8f3ed99"
    }
  },
  mainGrid: {
    padding: "12px 28px 6px 28px",
    [theme.breakpoints.down('xl')]: {
      padding: "12px 24px 6px 24px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "8px 20px 4px 20px"
    },
    [theme.breakpoints.down('md')]: {
      padding: "8px 16px 4px 16px"
    },
  },
  input: {
    padding: "8px",
    // background: "#EBEEF5",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#4e4c4c",
      '-webkit-text-fill-color': "#4e4c4c"
    }
  },
  loadingDiv: {
    width: "100%",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  contentText: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  approxValue: {
    color: theme.palette.color12.default
  },
  bottomGrid: {
    marginTop: theme.spacing(2.5)
  },
  borderBottom: {
    marginTop: theme.spacing(1.5),
    borderTop: `1px dashed ${theme.palette.color10.default}`,
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.spacing(1),
    },
  },
  textPadding: {
    paddingTop: "16px",
    [theme.breakpoints.down('xl')]: {
      paddingTop: "10px",
    },
  },
  gridBorder: {
    borderRight: `1px dashed ${theme.palette.color10.default}`
  },
  schemeDiv: {
    padding: "0px 0px 28px 0px",
    borderBottom: `2px dashed ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      padding: "0px 0px 16px 0px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "0px 0px 12px 0px",
    },
  },
  formWidth: {
    width: "610px" /* "30vw" */,
    [theme.breakpoints.down('xl')]: {
      width: "530px", /* "38vw" */
      marginLeft: "-6px",
      marginRight: "-6px"
    },
    [theme.breakpoints.down('lg')]: {
      width: "470px", /*"46vw" */
      marginLeft: "-8px",
      marginRight: "-8px"
    },
    [theme.breakpoints.down('md')]: {
      width: "450px", /*  "56vw" */
    }
  },
  confirmFormWidth: {
    width: "25vw",
    [theme.breakpoints.down('xl')]: {
      width: "32vw",
    },
    [theme.breakpoints.down('lg')]: {
      width: "40vw",
    },
    [theme.breakpoints.down('md')]: {
      width: "46vw",
    }
  },
  termsDiv: {
    marginBottom: "8px",
    marginTop: "6px"
  },
  gridMargin: {
    marginLeft: "24px",
    [theme.breakpoints.down('xl')]: {
      marginLeft: "16px",
    }
  },
  fieldMargin: {
    marginTop: "16px",
    [theme.breakpoints.down('xl')]: {
      marginTop: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "16px",
    }
  },
  checkboxMargin: {
    marginLeft: "-16px",
    [theme.breakpoints.down('lg')]: {
      marginLeft: "-20px",
    }
  },
  confirmValueCont: {
    margin: "12px 20px",
    [theme.breakpoints.down('xl')]: {
      margin: "8px 16px",
    },
    [theme.breakpoints.down('lg')]: {
      margin: "6px 16px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "4px 16px",
    }
  },
  rootConfirmGrid: {
    padding: "10px 8px 0px 8px",
    [theme.breakpoints.down('xl')]: {
      padding: "10px 8px 0px 8px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "6px 0px 0px 0px"
    }
  },
  switchConfirm: {
    borderRadius: "6px",
    borderTop: `1px solid ${theme.palette.color5.default}`
  },
  switchGrid: {
    margin: "16px 20px",
    width: "100%"
  },
  schemeHeading: {
    paddingBottom: "8px",
    color: theme.palette.color15.default,
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "4px"
    },
    "&.second": {
      paddingTop: "20px",
      [theme.breakpoints.down('xl')]: {
        paddingTop: "16px"
      },
    }
  },
  innerLoadingDiv: {
    height: "200px",
    "SIP": {
      height: "340px"
    },
    "OT": {
      height: "215px"
    },
    "REDEEM": {
      height: "250px"
    },
    "SWITCH": {
      height: "260px"
    }
  },
  popper: {
    zIndex: 1000,
    width: '100%',
    transform: "translate3d(-0px, 41px, 0px)",
    marginTop: "2px"
  },
  menuList: {
    maxHeight: 100,
    overflow: 'auto',
    padding: "0px",
    "&:focus": {
      outline: "none"
    }
  },
  mandateButton: {
    textTransform: "none",
    color: '#FFF',
    marginTop: "20px",
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    [theme.breakpoints.down('xl')]: {
      marginTop: "18px"
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "16px"
    }
  },
  addMoney: {
    marginTop: "20px",
    [theme.breakpoints.down('xl')]: {
      marginTop: "16px"
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "18px"
    }
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  menuItem: {
    padding: "6px 12px",
    // backgroundColor: theme.palette.background.default
  },
  switchMenuItem: {
    padding: "8px 12px",
    // backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.background.default}`
  },
  tooltip: {
    color: theme.palette.color15.default,
    fontSize: "10px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "8px"
    }
  },
  info: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: theme.palette.color15.default
  },
  radio: {
    '&$checked': {
      color: '#006CA8'
    }
  },
  checked: {},
  radioLabel: {
    color: theme.palette.text.primary
  },
  svg: {
    width: 50,
    [theme.breakpoints.down('xl')]: {
      width: 48,
    },
    [theme.breakpoints.down('lg')]: {
      width: 42,
    },
  },
  adornedEnd: {
    paddingRight: 4
  },
  endAdornment: {
    marginLeft: '0px !important',
  },
  dateInput: {
    padding: "8px 0px 8px 8px",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#4e4c4c",
    }
  },
  errorDivText: {
    color: "#721c24",
  },
})