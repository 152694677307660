import { Grid, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import axios from "helpers/interceptor";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { closeConfirmDialog, openConfirmDialog } from "redux/ConfirmDialog";
import ConfirmOrder from "./ConfirmOrder";
import MfOrderStates from "./MfOrderHOC";
import OrderForm from "./OrderForm";
import styles from './styles';
import { MODAL_CHILD } from "widgets/FormDialog"
import { openDialog } from "redux/Dialog"

function MfOrder(props) {
  const { activeStep, loading, touched, errors, errorObj, values, setFieldTouched, handleSubmit, classes, handleChange, setFieldValue, handleBack, pushOrder, handlePlace, reduxState, setOrderType, formData, onFrequencyChange, handleBankMandate, handleAddMoneyClick, onAccountChange } = props

  const { scheme } = reduxState || {}
  const { schemeName, navValue, navChange, navPercentChange } = scheme || {}

  const [schemeList, setSchemeList] = useState([])
  const [switchToScheme, setSwitchToScheme] = useState({})
  const [sortAnchor, setSortAnchor] = useState('');

  useEffect(() => {
    if (values.type === "REDEEM") {
      setFieldTouched("switchTo", false)
      setSwitchToScheme({})
      setSchemeList([])
      setSortAnchor(null)
    }
  }, [values.type])

  const top = () => {
    return (
      <Grid container className={classes.top}>
        <Grid item xs={8}>
          <Typography variant="h3" className={classes.text}>{schemeName || "--"}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography variant="h4" className={classes.value}>₹ {navValue && Number(navValue).toFixed(4) || "0.0000"}</Typography>
          <Typography variant="h6" className={classes.default}>{navChange && Number(navChange).toFixed(4) || "0.0000"} ({navPercentChange && Number(navPercentChange).toFixed(2) || "0.00"}%)</Typography>
        </Grid>
      </Grid>
    )
  }

  function onSwitchToChange(e) {
    setFieldTouched("switchTo", false)
    let value = e.currentTarget.value
    setSwitchToScheme({ label: value })
    if (value && value.length > 2) {
      setSortAnchor(e.currentTarget)
      setSchemeList("Searching...")
      axios.post(`${process.env.REACT_APP_API_URL}/ms-mf-switch/mfswitch/getAutoCompleteSchemes/0/0`, {
        schemeCode: reduxState && reduxState.scheme && reduxState.scheme.schemeCode,
        amccode: reduxState && reduxState.scheme && reduxState.scheme.amcCode,
        schemeName: value
      }).then((response) => {
        if (response.data.success && response.data.success.length > 0) {
          setSchemeList(response.data.success || [])
        } else {
          setSchemeList(response.data.error || "No Results found")
        }
      }).catch(() => {
        setSchemeList("No Results found")
      })
    } else if (value === '' || (value && value.length < 3)) {
      setSchemeList([])
      setSortAnchor(null)
    }
  }

  function handleSwitchToItem(item) {
    setSwitchToScheme(item || {})
    setSchemeList([])
    setSortAnchor(null)
    setFieldValue("switchTo", item.value)
  }

  function onCancelSwitchScheme() {
    setSchemeList([])
    setSwitchToScheme({})
    setFieldValue("switchTo", "")
  }

  const form = () => {
    return (
      <OrderForm
        values={values}
        errors={errors}
        touched={touched}
        reduxState={reduxState}
        handleChange={handleChange}
        handlePlace={handlePlace}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        setOrderType={setOrderType}
        formData={formData}
        loading={loading}
        onFrequencyChange={onFrequencyChange}
        onSwitchToChange={onSwitchToChange}
        schemeList={schemeList}
        switchToScheme={switchToScheme}
        sortAnchor={sortAnchor}
        setSortAnchor={setSortAnchor}
        handleSwitchToItem={handleSwitchToItem}
        onCancelSwitchScheme={onCancelSwitchScheme}
        addBankMandate={handleBankMandate}
        handleAddMoneyClick={handleAddMoneyClick}
        onAccountChange={onAccountChange}
      />
    )
  }

  const confirm = () => {
    return (
      <ConfirmOrder
        values={values}
        scheme={reduxState && reduxState.scheme || {}}
        handleSubmit={handleSubmit}
        handleBack={handleBack}
        pushOrder={pushOrder}
        switchToScheme={switchToScheme}
        errorObj={errorObj}
      />
    )
  }

  return (
    <form autoComplete={"off"} onSubmit={handleSubmit} className={activeStep === 1 ? classes.confirmFormWidth : classes.formWidth}>
      {
        loading.show ?
          <div className={classes.loadingDiv}>
            <Placeholder
              loading={loading.error == "Loading..." ? true : false}
              loadingMsg={""}
              error={loading.error == "Loading..." ? "" : loading.error}
            />
          </div>
          :
          activeStep === 0 ?
            <>
              {errorObj && errorObj.error && <div className={classes.errorDiv}><Typography className={classes.errorDivText}>{errorObj.message}</Typography></div>}
              {top()}
              {form()}
            </>
            :
            activeStep === 1 ?
              confirm()
              : null
      }
    </form>
  )
}


const mapStateToProps = (state) => {
  return { selectedScrip: state.common.selectedScrip, reduxState: state.dialog.data, selectedClient: state.client && state.client.selectedClient || null }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog()),
  openAddMandateDialog: (data) => dispatch(openDialog(MODAL_CHILD.ADD_MANDATE, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MfOrderStates(withStyles(styles)(MfOrder)))
