import React from "react";
import styles from './styles';
import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";

function SplitPopup(props) {
  const { classes, open, onClose, splitPopup, scrip } = props;
  const { splitQty, remainingQty } = splitPopup;

  return (
    <Dialog open={open} maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11} className={classes.headerGrid}>
            <Typography className={classes.title}>{"Alert"}</Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} >
            <Tooltip title="Close" aria-label="close">
              <IconButton aria-label="close" onClick={() => onClose(false)} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.contentGrid}>
          <div>
            <Typography className={classes.secondaryText}>The Order Qty per order is higher than the maximum Qty set by the exchange.</Typography>
            <Typography className={classes.secondaryText}>As a result, your order will be split while being sent to the exchange.</Typography>
            <Typography className={classes.secondaryText}>Here, there are <span className={classes.primaryText}>{splitQty} {splitQty > 1 ? "orders" : "order"} of {scrip?.volumeFreezeQty} Qty</span>{remainingQty > 0 && <> and <span className={classes.primaryText}>1 order of {remainingQty} Qty.</span></>}</Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center", padding: "20px 0px 0px" }}>
            <Button autoFocus={true} className={classes.orderButton} style={{ marginRight: 8 }} onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button className={classes.orderButton} onClick={() => onClose(true)}>
              Place Order
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(SplitPopup);