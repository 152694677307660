export default theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    // margin: "6px",
    [theme.breakpoints.down('xl')]: {
      // margin: "4px",
    },
    [theme.breakpoints.down('lg')]: {
      // margin: "4px",
    },
  },
  cellText: {
    color: 'inherit',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
})