import { store } from 'helpers';

export const getUniqueKey = (scripData = {}) => {
  const { symbol, scripName, exchange, segment, shortCode } = scripData;
  return `item_${segment == 'EQ' ? symbol : scripName}_${shortCode}`;
  // return `item_${symbol}_${scripName}_${exchange}_${segment}`;
};

export const compareDepth = (scripData = {}, rows) => {
  return getUniqueKey(scripData) == getUniqueKey(rows);
};

export const getSegment = (scripData = {}) => {
  if (!scripData) return '--';
  const { bseScripSeries, exchange, scripSeries, segment } = scripData || {};
  return exchange == 'NSE'
    ? scripSeries || segment
    : exchange == 'BSE'
      ? bseScripSeries || segment
      : segment || '--';
};

export const getBseScripCode = (scripData = {}) => {
  const { exchange, bseScripCode } = scripData;
  return exchange == 'BSE' && (bseScripCode || '');
};

export const getHeatColor = (scripData = {}) => {
  let heatColor = '#ffffff';
  const { percentChange } = scripData;
  if (percentChange > 4) {
    heatColor = '#30BE01';
  } else if (percentChange > 3) {
    heatColor = '#35D200';
  } else if (percentChange > 2) {
    heatColor = '#66FF66';
  } else if (percentChange > 1) {
    heatColor = '#99FF99';
  } else if (percentChange > 0) {
    heatColor = '#CCFFCC';
  } else if (percentChange < -4) {
    heatColor = '#E2133C';
  } else if (percentChange < -3) {
    heatColor = '#FE4848';
  } else if (percentChange < -2) {
    heatColor = '#FF6666';
  } else if (percentChange < -1) {
    heatColor = '#FF9999';
  } else if (percentChange < 0) {
    heatColor = '#FFCCCC';
  }
  return heatColor;
};

export const getFixedValue = (num, segment) => {
  let number;
  if (num) {
    if (segment === 'CURR') {
      number = Number(num).toFixed(4);
    } else {
      number = Number(num).toFixed(2);
    }
    return number;
  } else {
    if (segment === 'CURR') {
      return '0.0000';
    } else {
      return '0.00';
    }
  }
};

const fixedColumnKeys = {
  watchlist: ['ltp', 'bidPrice', 'askPrice', 'open', 'close', 'high', 'low', 'avgPrice', 'lowerCircuit', 'upperCircuit', 'yearlyLow', 'yearlyHigh', 'change', 'percentChange', 'oiPercentChange'],
  order: ['orderPrice', 'execPrice', 'trigPrice'],
  position: ['mktRate', 'buyValue', 'sellValue', 'avgRate', 'avgBuyRate', 'avgSellRate'],
  dpsr: ['holdValue', 'holdPrice', 'currentPrice', 'currentValue', 'profitLoss', 'percentageChange'],
  trade: ['execPrice', 'tradeValue'],
  margin: ['additionalMargin'],
  previousSettlement: ['buyValue', 'sellValue', 'bpl', 'limitOnPosition', 'netLimit'],
  portfolioEquity: ['ltp', 'currentValue', 'purchaseValue', 'todaysProfit', 'totalProfit', 'avgPrice', 'percentChange'],
  portfolioMf: [],
  exceptions: ['oiPercentChange', 'percentChange', 'percentageChange'],
  watchlistShort: ['ltp', 'bidPrice', 'askPrice','change', 'percentChange']
};

export const getFixedValueObject = (row, widget) => {
  let data = { ...row };
  fixedColumnKeys[widget].map((ele) => {
    if (fixedColumnKeys.exceptions.indexOf(ele) == -1) {
      data[ele] = getFixedValue(data[ele], data.segment);
    }
    else {
      data[ele] = getFixedValue(data[ele], null);
    }
  });
  return { ...data };
};

export const getFormattedDate = (date) => {
  if (date) {
    let currentDate = new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate()
    let month = new Date(date).getMonth() < 9 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    return currentDate + "-" + month + "-" + year
  }
  return "--"
}

export const getFormattedStrDate = (date) => {
  if (date) {
    let newDate = (date).toString()
    let dateArray = newDate.split("-")
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]
  }
  return "--"
}

export const hasScripDataChanged = (newScrip, oldScrip) => {
  if (!newScrip || !oldScrip) return true;
  for (const [key, value] of Object.entries(newScrip)) {
    if (value !== oldScrip[key]) {
      return true;
    }
  }
  return false;
}

export const isSameScrip = (scrip1, scrip2) => {
  if (scrip1 && scrip2) {
    if (scrip1.token == scrip2.token /* && scrip1.shortCode == scrip2.shortCode */) {
      return true;
    }
  }
  return false;
}

export const isInWatchlist = (scripToCheck) => {
  if (!scripToCheck) return false;
  const { watchlist: { scrips } } = store.getState();
  if (!scrips) return false;
  if (scrips.length < 1) return false;
  let scripMatch = false;
  scrips.find(scrip => {
    if (scrip) {
      if (scrip.token == scripToCheck.token && scrip.shortCode == scripToCheck.shortCode) {
        scripMatch = true;
      }
    }
  });
  return scripMatch;
}
