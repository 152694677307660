export default theme => ({
  root: {
    padding: theme.spacing(4),
    color: `${theme.palette.text.primary}`,
    height: "100%"
  },
  item: {
    height: '100%'
  },
  flexlayout__layout: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden'
  },
  flexlayout__splitter: {
    backgroundColor: theme.palette.border.default, // '#1F2434',
    '&:hover': {
      backgroundColor: '#ddd'
    }
  },
  flexlayout__splitter_border: {
    zIndex: 10
  },
  flexlayout__splitter_drag: {
    borderRadius: 5,
    backgroundColor: '#444',
    zIndex: 1000
  },
  flexlayout__outline_rect: {
    position: 'absolute',
    cursor: 'move',
    border: '2px solid #cfe8ff',
    boxShadow: 'inset 0 0 60px rgba(0, 0, 0, .2)',
    borderRadius: 5,
    zIndex: 1000,
    boxSizing: 'border-box'
  },
  flexlayout__outline_rect_edge: {
    cursor: 'move',
    border: '2px solid #b7d1b5',
    boxShadow: 'inset 0 0 60px rgba(0, 0, 0, .2)',
    borderRadius: 5,
    zIndex: 1000,
    boxSizing: 'border-box'
  },
  flexlayout__edge_rect: {
    position: 'absolute',
    zIndex: 1000,
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, .2)',
    backgroundColor: 'lightgray'
  },
  flexlayout__drag_rect: {
    position: 'absolute',
    cursor: 'move',
    border: '2px solid #aaaaaa',
    boxShadow: 'inset 0 0 60px rgba(0, 0, 0, .3)',
    borderRadius: 5,
    zIndex: 1000,
    boxSizing: 'border-box',
    backgroundColor: '#eeeeee',
    opacity: 0.9,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 10,
    wordWrap: 'break-word',
    fontSize: 'medium',
    fontSize: 'small',
    fontFamily: 'Roboto, Arial, sans-serif'
  },
  flexlayout__tabset: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default, //'#292e3e'
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.tab_border.default}`,
    fontSize: 'medium',
    fontSize: 'small',
    fontFamily: 'Roboto, Arial, sans-serif'
  },

  flexlayout__tabset_header: {
    position: 'absolute',
    left: 0,
    right: 0,
    color: '#eee',
    backgroundColor: theme.palette.background.default, //'#212121',
    padding: '3px 3px 3px 5px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid #262626`,
    boxShadow: 'inset 0 0 3px 0 rgba(136, 136, 136, 0.54)'
  },

  flexlayout__tabset_header_content: {
    flexGrow: 1
  },

  flexlayout__tabset_tabbar_outer: {
    backgroundColor: theme.palette.color6.default,
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
    // borderBottom: '1px solid #cccccc24'
  },

  flexlayout__tabset_tabbar_outer_top: {
    borderTop: '1px solid #333333',
  },

  flexlayout__tabset_tabbar_outer_bottom: {
    // boxSizing: 'border-box',
    borderTop: '1px solid #333333',
  },

  flexlayout__tabset_tabbar_inner: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden'
  },

  flexlayout__tabset_tabbar_inner_tab_container: {
    display: 'flex',
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 10000
  },

  flexlayout__tabset_tabbar_inner_tab_container_top: {
    // borderTop: '2px solid transparent'
  },

  flexlayout__tabset_tabbar_inner_tab_container_bottom: {
    // borderBottom: '2px solid transparent'
  },

  flexlayout__tabset_header_inner_top: {
    //borderTop: '2px solid transparent',
    boxSizing: 'border-box',
    display: 'flex',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 10000,
  },
  flexlayout__tabset_header_inner_bottom: {
    borderBottom: '2px solid transparent',
    boxSizing: 'border-box',
    display: 'flex',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 10000,
  },

  flexlayout__tabset_selected: {
    //backgroundColor: theme.palette.background.default, 
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
  },
  flexlayout__tabset_maximized: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
  },
  flexlayout__tab: {
    overflow: 'hidden',
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default, //'#292e3e',
    color: 'white',
    border: `1px solid ${theme.palette.tab_border.default}`,
    borderTop: '0px'
  },
  flexlayout__tab_button: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '2px 8px 3px 8px',
    margin: 0,
    //float: 'left',
    verticalAlign: 'top',
    boxSizing: 'border-box',
  },

  flexlayout__tab_button_selected: {
    color: theme.palette.color4.default,
    backgroundColor: theme.palette.background.default, //'#292e3e',
  },

  flexlayout__tab_button_unselected: {
    color: theme.palette.color9.default,
    backgroundColor: theme.palette.color3.default //'#383E4F'
  },

  flexlayout__tab_button_top: {
    boxShadow: 'inset -2px 0px 5px rgba(0, 0, 0, 0.1)',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3
  },

  flexlayout__tab_button_bottom: {
    boxShadow: 'inset -2px 0px 5px rgba(0, 0, 0, 0.1)',
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3
  },

  flexlayout__tab_button_leading: {
    //float: 'left',
    display: 'inline-block',
    //paddingTop: 2
  },
  flexlayout__tab_button_content: {
    display: 'inline-block',
    //padding: 2
  },
  flexlayout__tab_button_textbox: {
    //float: 'left',
    border: 'none',
    //color: 'lightgreen',
    //backgroundColor: '#292e3e',
    '&:focus': {
      outline: 'none',
    }
  },
  flexlayout__tab_button_trailing: {
    //float: 'left',
    display: 'inline-block',
    marginRight: 4,
    marginTop: 6,
    minWidth: 8,
    minHeight: 8,
    background: 'transparent'
  },
  // flexlayout__tab_button: {
  //   '&:hover':{
  //    '& flexlayout__tab_button_trailing': {
  //       background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //     }
  //   },
  //   '&--selected':{
  //     '& flexlayout__tab_button_trailing': {
  //        background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //      }
  //    }
  // },

  flexlayout__tab_button_overflow: {
    float: 'left',
    width: 20,
    height: 15,
    marginTop: 2,
    paddingLeft: 12,
    border: 'none',
    fontSize: 10,
    color: 'lightgray',
    fontFamily: 'Arial, sans-serif',
    background: ' transparent'
  },
  //
  flexlayout__tab_toolbar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0
  },
  flexlayout__tab_toolbar_button: {
    minWidth: 20,
    minHeight: 20,
    border: 'none',
    outline: 'none'
  },
  flexlayout__tab_toolbar_button_min: {
    width: 20,
    height: 20,
    border: 'none',
    outlineWidth: 0,
    background: 'transparent'
  },
  flexlayout__tab_toolbar_button_max: {
    width: 20,
    height: 20,
    border: 'none',
    outlineWidth: 0,
    background: 'transparent'
  },
  flexlayout__tab_toolbar_button_float: {
    width: 20,
    height: 20,
    border: 'none',
    outlineWidth: 0,
    background: 'transparent'
  },

  flexlayout__tab_floating: {
    overflow: 'auto',
    position: 'absolute',
    boxSizing: 'border-box',
    color: 'white',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexlayout__tab_floating_inner: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  // flexlayout__tab_floating_inner div {
  //   marginBottom:5,
  //   textAlign:'center'
  // },
  // flexlayout__tab_floating_inner div a {
  //   color: 'royalblue'
  // }

  flexlayout__popup_menu: {
    fontSize: 'medium',
    fontFamily: 'Roboto, Arial, sans - serif'
  },
  flexlayout__popup_menu_item: {
    padding: '2px 10px 2px 10px',
    color: '#ddd',
    '&:hover': {
      backgroundColor: '#444444'
    }
  },
  flexlayout__popup_menu_container: {
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, .15)',
    border: '1px solid #555',
    background: theme.palette.background.default, //'#292e3e'
    borderRadius: 3,
    position: 'absolute',
    zIndex: 1000
  },
  flexlayout__border: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    fontSize: 'small',
    fontFamily: 'Roboto, Arial, sans-serif',
    backgroundColor: '#1a1a1a'
  },
  flexlayout__border_top: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    borderBottom: '8px solid',
    borderBottomColor: `${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center'
  },
  flexlayout__border_bottom: {
    backgroundColor: theme.palette.border.default, //'#1F2434'
    // borderTop: '8px solid',
    borderTopColor: `${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center'
  },
  flexlayout__border_left: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    borderRight: '8px solid',
    borderRightColor: `${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    //alignItems: 'center'
    flexDirection: 'column',
    alignContent: 'center'
  },
  flexlayout__border_right: {
    backgroundColor: theme.palette.border.default, //'#1F2434'
    // borderLeft: '8px solid',
    borderLeftColor: `${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    //alignItems: 'center'
    flexDirection: 'column',
    alignContent: 'center'
  },
  flexlayout__border_inner: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1
  },
  flexlayout__border_inner_tab_container: {
    whiteSpace: 'nowrap',
    display: 'flex',
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 10000
  },
  flexlayout__border_inner_tab_container_right: {
    transformOrigin: 'top left',
    transform: 'rotate(90deg)'
  },
  flexlayout__border_inner_tab_container_left: {
    flexDirection: 'row-reverse',
    transformOrigin: 'top right',
    transform: 'rotate(-90deg)'
  },
  flexlayout__border_inner_bottom: {
    display: 'flex'
  },
  flexlayout__border_inner_left: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    right: 23,
    transformOrigin: 'top right',
    transform: 'rotate(-90deg)'
  },
  // flexlayout__border_inner_right: {
  //   position: 'absolute',
  //   whiteSpace: 'nowrap',
  //   left: 23,
  //   transformOrigin: 'top left',
  //   transform: 'rotate(90deg)'
  // },
  flexlayout__border_button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '3px 8px',
    margin: 2,
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    boxSizing: 'inset 0 0 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 3,
    backgroundColor: theme.palette.background.default, //'#292e3e'
    color: 'white',
    // padding: '2px 8px 3px 8px',
    // verticalAlign: 'top',
  },
  flexlayout__border_button_selected: {
    color: '#ddd',
    backgroundColor: theme.palette.background.default, //'#292e3e'

  },
  flexlayout__border_button_unselected: {
    color: 'gray'
  },
  flexlayout__border_button_leading: {
    // float: 'left',
    display: 'inline'
  },
  flexlayout__border_button_content: {
    display: 'inline-block'
  },
  flexlayout__border_button_textbox: {
    float: 'left',
    border: 'none',
    color: 'green',
    backgroundColor: '#ddd',
    '&:focus': {
      outline: 'none'
    }
  },
  flexlayout__border_button_trailing: {
    display: 'inline-block',
    marginLeft: 5,
    marginTop: 3,
    width: 8,
    height: 8,
    background: 'transparent'
  },
  // flexlayout__border_button: {
  //   '&:hover':{
  //    '& flexlayout__border_button_trailing': {
  //       background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //     }
  //   },
  //   '&--selected':{
  //     '& flexlayout__border_button_trailing': {
  //        background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //      }
  //    }
  // },

  flexlayout__border_toolbar: {
    display: 'flex',
    alignItems: 'center',
  },

  flexlayout__border_toolbar_left: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0
  },
  flexlayout__border_toolbar_right: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0
  },
  flexlayout__border_toolbar_top: {
    position: 'absolute',
    display: 'flex',
    //flexDirection: 'row-reverse',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0
  },
  flexlayout__border_toolbar_bottom: {
    position: 'absolute',
    display: 'flex',
    //flexDirection: 'row-reverse',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0
  },
  flexlayout__border_toolbar_button: {
    minWidth: 20,
    minHeight: 20,
    border: 'none',
    outline: 'none'
  },
  flexlayout__border_toolbar_button_float: {
    background: 'transparent url("../images/popout.png") no-repeat center'
  },
  flexlayout__border_toolbar_button_overflow: {
    border: 'none',
    paddingLeft: 12,
    color: 'gray',
    fontSize: 'inherit',
    background: 'transparent url("../images/more2.png") no-repeat left',
  },
  flexlayout__border_toolbar_button_overflow_top: {
    marginLeft: 10
  },
  flexlayout__border_toolbar_button_overflow_bottom: {
    marginLeft: 10
  },
  flexlayout__border_toolbar_button_overflow_right: {
    paddingRight: 0,
    marginTop: 5
  },
  flexlayout__border_toolbar_button_overflow_left: {
    paddingRight: 0,
    marginTop: 5
  },

  flexlayout__floating_window_body: {
    height: '100%',
  },
  flexlayout__floating_window_content: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  flexlayout__floating_window_tab: {
    overflow: 'auto',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: 'black',
    color: 'white',
  },
  flexlayout__tab_floating: {
    overflow: 'auto',
    position: 'absolute',
    boxSizing: 'border-box',
    color: 'white',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexlayout__tab_floating_inner: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // flexlayout__tab_floating_inner div: {
  //   margin-bottom: 5px,
  //   text-align: center,
  // },
  // flexlayout__tab_floating_inner div a: {
  //   color: royalblue,
  // },
  flexlayout__error_boundary_container: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
  },
  flexlayout__error_boundary_content: {
    display: 'flex',
    alignItems: 'center',
  },
  flexlayout__tabset_sizer: {
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 'medium',
    fontFamily: 'Roboto, Arial, sans-serif'
  },
  flexlayout__tabset_header_sizer: {
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 'medium',
    fontFamily: 'Roboto, Arial, sans-serif'
  },
  flexlayout__border_sizer: {
    paddingTop: 6,
    paddingBottom: 5,
    fontSize: 'medium',
    fontFamily: 'Roboto, Arial, sans-serif'
  }
});
