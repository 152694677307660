export default theme => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    search: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.secondary,
        margin: '0 5px',
        width: '100%',
        height: '35px',
        [theme.breakpoints.down('xl')]: {
            height: '30px'
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto'
        }
    },
    searchIcon: {
        color: theme.palette.color15.default,
        width: theme.spacing(7),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center'
    },
    inputRoot: {
        width: '100%'
    },
    inputInput: {
        fontSize: 18,
        '&::placeholder': {
            color: theme.palette.text.primary
        },
        color: theme.palette.text.primary,
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.down('xl')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        }
    },
    popper: {
        zIndex: 1000,
        minWidth: '100%'
    }
});
