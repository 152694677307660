import { EditOutlined as EditIcon } from '@mui/icons-material';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import React, { useState } from "react";
import EditInfoPopup from "./EditInfoPopup";
import DetailList from "./list";
import styles from "./styles";

const USER = `${process.env.REACT_APP_USER}`;

let verifyField = "";

function Infodiv(props) {
  const { label, text, color, hideBorder, classes, showEdit, onEditClick } = props
  return (
    <div className={[classes.info, hideBorder ? "" : classes.infoBorder].join(" ")}>
      <Typography variant="subtitle2" className={[classes.infoText, hideBorder ? classes.textPadding1 : classes.textPadding].join(" ")} style={{ color: color, paddingBottom: "4px" }} >{label} </Typography>
      {
        showEdit ?
          <div className={classes.editDiv}>
            <Typography variant="subtitle2" className={[classes.value, hideBorder ? classes.textPadding1 : classes.textPadding].join(" ")}>{text || "--"}</Typography>
            <EditIcon color="primary" className={classes.editIcon} onClick={() => onEditClick()} />
          </div>
          :
          <Typography variant="subtitle2" className={[classes.value, hideBorder ? classes.textPadding1 : classes.textPadding].join(" ")}>{text || "--"}</Typography>
      }
    </div>
  )
}

function Basic(props) {
  const { isMaximized, basicInfo, value, classes, clientName, account } = props

  const [editInfoPopup, setEditInfoPopup] = useState(false)
  const [activeStep, setActiveStep] = useState(1)

  const theme = createTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'))
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'))

  const onEditClick = (info) => {
    setEditInfoPopup(true)
    verifyField = info || ""
  }

  const dealerTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small"></TableCell>
            <TableCell size="small">Introducer</TableCell>
            <TableCell size="small">Dealer</TableCell>
            {account && <TableCell size="small">Contact</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell size="small">Equity</TableCell>
            <TableCell size="small">{basicInfo.eqIntro || "--"}</TableCell>
            <TableCell size="small">{basicInfo.eqDealer || "--"}</TableCell>
            {account && <TableCell size="small">{"022 4000 1000"}</TableCell>}
          </TableRow>
          <TableRow>
            <TableCell size="small">Commodity</TableCell>
            <TableCell size="small">{basicInfo.commIntro || "--"}</TableCell>
            <TableCell size="small">{basicInfo.commDealer || "--"}</TableCell>
            {account && <TableCell size="small">{"022 4000 1000"}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const maximizedView = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            {!account && <Grid item lg={3} md={account ? 4 : 3} sm={12} xs={12}>
              <Typography className={classes.heading} variant="h3" style={{ marginBottom: "8px" }}>TRADING INFO</Typography>
              <DetailList
                height={matchesSm ? "240px" : "300px"}
                basicInfo={basicInfo}
                value={value}
              />
            </Grid>}
            <Grid item lg={account ? 12 : 9} md={account ? 12 : 9} sm={12}>
              <div className={account ? "" : classes.mainGrid}>
                <Typography className={classes.heading} variant="h3" style={{ marginBottom: "12px" }}>PERSONAL INFO</Typography>
                <div className={classes.detailDiv}>
                  <Grid container className={classes.bottomGrid}>
                    <Grid item lg={3} md={account ? 4 : 3} sm={3} xs={12} className={classes.firstRow}>
                      <Infodiv text={clientName} label={"Full name"} color={"#547BF8"} classes={classes} hideBorder />
                    </Grid>
                    <Grid item lg={3} md={account ? 4 : 3} sm={3} xs={12} className={classes.firstRow}>
                      <Infodiv text={basicInfo.mobileNumber} label={"Registered Mobile No."}/*  onEditClick={() => onEditClick("mobile")} */ /* showEdit={USER === 'client' ? true : false} */ color={"#3C63B9"} classes={classes} />
                    </Grid>
                    {
                      account && matchesMd && !matchesSm ?
                        <></>
                        :
                        <Grid item lg={3} md={account ? 4 : 3} sm={3} xs={12} className={classes.firstRow}>
                          <Infodiv text={basicInfo.emailId} label={"Registered Email Id."}/*  onEditClick={() => onEditClick("email")} */ /* showEdit={USER === 'client' ? true : false} */ color={"#299D00"} classes={classes} />
                        </Grid>
                    }
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.firstRow}>
                      <Infodiv text={basicInfo.dob} label={"DOB"} color={"#BC2E94"} classes={classes} />
                    </Grid>
                  </Grid>
                  {
                    account && matchesMd && !matchesSm ?
                      <Grid container className={classes.bottomGrid}>
                        <Grid item md={6} className={classes.rowPadding}>
                          {/* <Infodiv text={basicInfo.emailId} label={"Registered Email Id."} onEditClick={onEditClick("email")} showEdit={USER === 'client' ? true : false} color={"#299D00"} classes={classes} hideBorder /> */}
                          <Infodiv text={basicInfo.emailId} label={"Registered Email Id."} /* onEditClick={() => onEditClick("email")} showEdit={USER === 'client' ? true : false} */ color={"#299D00"} classes={classes} hideBorder />
                        </Grid>
                        <Grid item md={3} className={classes.rowPadding}>
                          <Infodiv text={basicInfo.city} label={"City"} color={"#299D00"} classes={classes} />
                        </Grid>
                        <Grid item md={3} className={classes.rowPadding}>
                          <Infodiv text={basicInfo.state} label={"State"} color={"#EB30A6"} classes={classes} />
                        </Grid>
                      </Grid>
                      :
                      <></>
                  }
                  <Grid container className={classes.bottomGrid}>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.type} label={"Type"} color={"#654ECC"} classes={classes} hideBorder />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.status || "" /* ? "Active" : "Not Active" */} label={"Status"} color={"#FF4395"} classes={classes} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.planName} label={"Plan Name"} color={"#006CAB"} classes={classes} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.marginAmount} label={"Margin Amount"} color={"#AF7ECE"} classes={classes} />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.bottomGrid}>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.panNumber} label={"PAN No."} color={"#299D0"} classes={classes} hideBorder />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.gstNumber} label={"GST No."} color={"#707070"} classes={classes} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.clientType} label={"Client Type"} color={"#654ECC"} classes={classes} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.rowPadding}>
                      <Infodiv text={basicInfo.pincode} label={"Pin"} color={"#FF5520"} classes={classes} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} md={account ? 12 : 6} sm={6} xs={12} className={classes.lastRow}>
                      <Infodiv text={basicInfo.address} label={"Address"} color={"#2078CE"} classes={classes} hideBorder />
                    </Grid>
                    {account && matchesMd && !matchesSm ?
                      <></> :
                      <>
                        <Grid item lg={3} md={3} sm={3} xs={12} className={classes.lastRow}>
                          <Infodiv text={basicInfo.city} label={"City"} color={"#299D00"} classes={classes} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12} className={classes.lastRow}>
                          <Infodiv text={basicInfo.state} label={"State"} color={"#EB30A6"} classes={classes} />
                        </Grid>
                      </>
                    }
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "12px", marginRight: "4px" }}>
          <Typography className={classes.heading} variant="h3" style={{ marginBottom: "12px" }}>INTRODUCER/ DEALER</Typography>
          {dealerTable()}
          {account && <Typography className={classes.note} variant="subtitle2">Note: To contact RM, dial above number from your registered mobile number.</Typography>}
        </Grid>
      </Grid >
    )
  }
  return (
    <>
      {

        isMaximized ?
          <div className={classes.tabPanel}>
            {maximizedView()}
          </div>
          :
          <DetailList

            basicInfo={basicInfo}
            value={value}
          />
      }
      {
        editInfoPopup &&
        <EditInfoPopup
          verifyField={verifyField}
          open={editInfoPopup}
          activeStep={activeStep}
          mobileNo={basicInfo.mobileNumber || ""}
          emailId={basicInfo.emailId || ""}
          clientCode={basicInfo.clientCode || ""}
          setActiveStep={(val) => setActiveStep(val)}
          onClose={() => {
            setEditInfoPopup(false); setTimeout(() => {
              setActiveStep(1)
            }, 1000)
          }}
        />
      }
    </>
  )
}

export default withStyles(styles)(Basic)