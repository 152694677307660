export default theme => ({
  root: {
    width: "900px",
    margin: "6px -8px",
    [theme.breakpoints.down('xl')]: {
      width: "700px",
      margin: "4px -8px 0px -8px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "650px",
      margin: "3px -8px 0px -8px",
    },
    [theme.breakpoints.down('md')]: {
      width: "600px",
      margin: "4px -8px 0px -8px",
    },
  },
  root1: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  minWidthRoot: {
    minWidth: "450px",
    [theme.breakpoints.down('xl')]: {
      minWidth: "360px",
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: "320px",
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "280px",
    }
  },
  divHeight: {
    height: "500px",
    [theme.breakpoints.down('xl')]: {
      height: "400px"
    },
    [theme.breakpoints.down('lg')]: {
      height: "400px"
    },
    [theme.breakpoints.down('md')]: {
      height: "350px"
    },
  },
  grid: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(0.5),
    padding: "12px",
    [theme.breakpoints.down('xl')]: {
      padding: "10px",
    }
  },
  blueText: {
    color: theme.palette.common.blue,
    marginBottom: "24px",
    [theme.breakpoints.down('xl')]: {
      marginBottom: "16px",
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: "12px",
    },
    "&.note": {
      marginBottom: "12px",
      [theme.breakpoints.down('xl')]: {
        marginBottom: "8px",
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: "12px",
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: "10px",
      },
    }
  },
  select: {
    border: "1px solid #66788a4d",
    color: theme.palette.color12.default
  },
  menuItem: {
    padding: "6px 12px"
  },
  input: {
    padding: "8px",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#4e4c4c",
    }
  },
  defaultText: {
    color: theme.palette.color15.default
  },
  svg: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
    marginBottom: "4px",
    color: "#000",
    [theme.breakpoints.down('xl')]: {
      height: "36px",
      width: "36px",
    },
    [theme.breakpoints.down('lg')]: {
      height: "32px",
      width: "32px",
    },
    [theme.breakpoints.down('md')]: {
      height: "28px",
      width: "28px",
    },
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  emojiDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  fieldDiv: {
    width: "100%"
  },
  order: {
    width: "100%",
    textTransform: "none",
    color: "#fff",
    marginTop: "8px",
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "3px 8px"
    },
  },
  cancel: {
    width: "100%",
    background: "#ffffff",
    textTransform: "none",
    border: "1px solid #006ca84a",
    color: "#006CA8",
    boxShadow: "0px 3px 5px 0px #006ca863",
    marginTop: "8px",
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#c5c6c826"
    },
    [theme.breakpoints.down('xl')]: {
      padding: "5px 8px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px 8px"
    },
  },
  errorText: {
    color: "#f54040",
  },
  uploadWrapper: {
    border: `1px dashed ${theme.palette.color5.default}`,
    padding: "5px",
    borderRadius: "5px",
    width: "350px",
    height: "55px",
    [theme.breakpoints.down('xl')]: {
      width: "280px",
      height: "45px"
    },
    [theme.breakpoints.down('lg')]: {
      height: "42px",
      width: "260px",
    },
    [theme.breakpoints.down('md')]: {
      height: "36px",
      width: "260px",
    }
  },
  uploadDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "calc(100% - 48px)",
    [theme.breakpoints.down('xl')]: {
      height: "calc(100% - 36px)",
    },
    [theme.breakpoints.down('lg')]: {
      height: "calc(100% - 30)",
    },
    [theme.breakpoints.down('md')]: {
      height: "calc(100% - 28px)",
    }
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  deleteButtonDiv: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#000',
    opacity: 0.7,
    top: "40px",
    zIndex: 999,
    marginTop: '-30px',
    position: 'relative',
    cursor: 'pointer',
    [theme.breakpoints.down('xl')]: {
      width: '30px',
      height: '30px',
      top: "32px",
    },
    [theme.breakpoints.down('lg')]: {
      top: "32px",
    },
    [theme.breakpoints.down('md')]: {
      top: "32px",
    }
  },
  deleteIcon: {
    color: theme.palette.text.primary,
    marginTop: '5px',
    marginLeft: '6px'
  },
  image: {
    width: "380px",
    height: "300px",
    border: `2px solid ${theme.palette.common.blue}`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down('xl')]: {
      width: "300px",
      height: "220px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "280px",
      height: "250px",
    },
    [theme.breakpoints.down('md')]: {
      width: "260px",
      height: "200px",
    }
  },
  deleteMainDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '12%',
    [theme.breakpoints.down('xl')]: {
      marginRight: "10%"
    },
    [theme.breakpoints.down('md')]: {
      marginRight: "8%"
    }
  },
  image1: {
    width: "125px",
    height: "150px",
    marginBottom: "28px",
    [theme.breakpoints.down('xl')]: {
      marginBottom: "20px"
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: "16px"
    },
    [theme.breakpoints.down('md')]: {
      width: "100px",
      height: "100px",
    },
  },
  closeButton: {
    textTransform: "none",
    color: "#fff",
    marginTop: "24px",
    padding: "4px 8px",
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    [theme.breakpoints.down('xl')]: {
      marginTop: "16px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "3px 8px",
      marginTop: "12px"
    }
  },
  errorDiv: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    border: "1px solid #f5c6cb",
    borderRadius: "4px",
    padding: "4px",
    textAlign: "center",
    marginBottom: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "3px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px",
    }
  },
  heading: {
    paddingBottom: "20px",
    color: theme.palette.common.blue,
    fontSize: "24px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xl')]: {
      fontSize: "20px",
      marginTop: "4px",
      paddingBottom: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "12px",
      marginTop: "0px",
      paddingBottom: "8px"
    },
  },
  closeIcon: {
    float: "right",
    height: "25px",
    marginRight: theme.spacing(1),
    padding: 0
  },
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: "0px",
    height: "25px",
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: "#497CAC",
    fontWeight: 600,
    lineHeight: "24px"
  }
})