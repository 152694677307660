import { withStyles } from '@mui/styles';
import { Button, Chip } from '@mui/material';
import clsx from 'clsx';
import commonStyles from 'common/styles';
import combineStyles from 'common/styles/combineStyles';
import React, { useState } from 'react';
import { connect } from "react-redux";
import { FundExplorerActions } from 'redux/FundExplorer';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        height: 'fit-content',
        '& > *': {
            margin: theme.spacing(0.5)
        }
    },
    buttonHeight: {
        height: 30
    }
});

function FilterSelection(props) {
    const {
        classes,
        selection,
        onFilterChange,
        className,
        showClear,
        searchCriteria,
        topFunds,
        setTopFunds,
    } = props;

    // const [topFunds, setTopFundsState] = useState(searchCriteria && searchCriteria.topFunds !== undefined ? searchCriteria.topFunds : false);

    const onDelete = (value, group) => {
        console.log(`Delete ${value} from ${group}`);
        let newSelection = { ...selection };
        let toDeleteFrom = newSelection[group];
        if (toDeleteFrom) {
            let index = toDeleteFrom.indexOf(value);
            if (index != -1) {
                toDeleteFrom.splice(index, 1);
                onFilterChange({ ...newSelection, [group]: toDeleteFrom });
            }
        }
    };

    return (
        <>
            {selection || topFunds ? (
                <div className={clsx(classes.root, className)}>
                    {topFunds && <Chip label={'Top Funds'} />}
                    {selection && Object.keys(selection).map((item) => {
                        let currentItem = selection[item];
                        if (!currentItem || item === 'topFunds') return <></>;
                        console.log('currentItem', currentItem);
                        return currentItem.map((chip) => {
                            let canDelete = item != 'amc' && item != 'category';
                            return (
                                <Chip
                                    label={chip}
                                    onDelete={canDelete ? () => onDelete(chip, item) : null}
                                />
                            );
                        });
                    })}
                    {showClear && (
                        <Chip
                            component={() => (
                                <Button
                                    className={clsx(classes.containedBlue, classes.buttonHeight)}
                                    onClick={() => {
                                        // debugger
                                        setTopFunds();
                                        FundExplorerActions.setFilterSelection(null, false);
                                        FundExplorerActions.setSearchString(null, true);
                                    }}>
                                    Clear All
                                </Button>
                            )}
                        />
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    const {
        fundExplorer: { searchCriteria }
    } = state;
    return { searchCriteria };
};

export default connect(mapStateToProps)(withStyles(combineStyles(styles, commonStyles))(FilterSelection));
