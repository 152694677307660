export default theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderRadius: "4px",
    color: "#fff",
    "&.success": {
      backgroundColor: "#299D00",
    },
    "&.error": {
      backgroundColor: "#F54040",
    },
    "&.FullyExecuted": {
      backgroundColor: "#299D00",
    },
    "&.Cancelled": {
      backgroundColor: theme.palette.orderStatusColor.cancelled,
    },
    "&.Executed": {
      backgroundColor: theme.palette.orderStatusColor.executed,
    },
    "&.Expired": {
      backgroundColor: theme.palette.orderStatusColor.expired,
    },
    "&.Freezed": {
      backgroundColor: theme.palette.orderStatusColor.freezed,
    },
    "&.InProcess": {
      backgroundColor: theme.palette.orderStatusColor.inProcess,
    },
    "&.PartiallyExecuted": {
      backgroundColor: theme.palette.orderStatusColor.partiallyExecuted,
    },
    "&.Pending": {
      backgroundColor: theme.palette.orderStatusColor.pending,
    },
    "&.Rejected": {
      backgroundColor: theme.palette.orderStatusColor.rejected,
    },
    "&.Triggered": {
      backgroundColor: theme.palette.orderStatusColor.triggered,
    },
    "&.default": {
      backgroundColor: theme.palette.color3.default
    },
    [theme.breakpoints.down('xl')]: {
      padding: "6px 14px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "4px 12px",
    },
  },
  titleBar:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 5,
  },
  title: {
    // flex: 1,
    marginRight: 8,
    fontSize: 16,
    fontWeight: "bold",
    color: "#24B7CC",
    "&.FullyExecuted": {
      color: "#299D00",
    },
    "&.Cancelled": {
      color: theme.palette.orderStatusColor.cancelled,
    },
    "&.Executed": {
      color: theme.palette.orderStatusColor.executed,
    },
    "&.Expired": {
      color: theme.palette.orderStatusColor.expired,
    },
    "&.Freezed": {
      color: theme.palette.orderStatusColor.freezed,
    },
    "&.InProcess": {
      color: theme.palette.orderStatusColor.inProcess,
    },
    "&.PartiallyExecuted": {
      color: theme.palette.orderStatusColor.partiallyExecuted,
    },
    "&.Pending": {
      color: theme.palette.orderStatusColor.pending,
    },
    "&.Rejected": {
      color: theme.palette.orderStatusColor.rejected,
    },
    "&.Triggered": {
      color: theme.palette.orderStatusColor.triggered,
    },
  },
  dateTime: {
    fontSize: 10,
    marginRight: 5,
    color: "#fff",
    "&.default": {
      color: theme.palette.text.primary
    }
  },
  message: {
    fontSize: 14,
    color: "#fff",
    "&.default": {
      color: theme.palette.text.primary
    }
  },
  closeIcon: {
    color: "#fff",
    width: "14px",
    height: "14px",
    "&.default": {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down('xl')]: {
      width: "12px",
      height: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "10px",
      height: "10px",
    },
  }
})