import { FiberManualRecord } from "@mui/icons-material";
import { Box, Button, Collapse, Dialog, DialogContent, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { withStyles } from '@mui/styles';
import classNames from "classnames";
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import SvgComponent from "helpers/svgcomponent";
import React, { useState } from 'react';
import styles from "./styles";

function OrderHelp({ open, setOpen, ...props }) {
  const { classes } = props;

  const [openItem, setOpenItem] = useState('orders');

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen && setOpen(false);
    }
  }

  const OrderTypes = () => {
    return (
      <div className={classes.textContiner}>
        <Typography className={classes.textContent}>
          <Typography className={classes.textHeader}>Limit Order</Typography>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Box>
              <Typography className={classes.textContent}>
                Limit orders allows you to place an order at a specific price or better.
              </Typography>
              <Typography className={classes.textContent}>
                For example, if a trader is looking to buy XYZ's stock which is trading at Rs. 150 but places an order with limit price at Rs. 145, the order will be executed at a price of Rs. 145 or lower.
              </Typography>
            </Box>
          </Box>
          <Typography className={classes.textHeader}>Market Order</Typography>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Typography className={classes.textContent}>
              Market orders can be selected, if you want to buy / sell the stock at whatever the Market Price is available. It will be bought or sold at the best possible price.
            </Typography>
          </Box>
          <Typography className={classes.textHeader}>Stop loss Order</Typography>
          <Box>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />
              <Typography className={classes.textContent}>
                Stop loss Orders are like “you can buy or sell a specific stock once the stock reaches a certain price.
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />

              <Box>
                <Typography className={classes.textContent}>
                  To limit your losses, if prices go against trade, you can place a stop-loss order. In a Stop loss order, you need to enter a Trigger Price and Limit Price. Once the market price reaches the Trigger price, your order gets activated.
                </Typography>
                <Typography className={classes.textHeader}>Stop-loss Limit Order (SL-L):</Typography>
                <Box display="flex" flexDirection="row">
                  <FiberManualRecord className={classes.dot} />
                  <Typography className={classes.textContent}>
                    Under such type of orders, a limit price has to be mentioned so that when the order gets triggered it goes as a limit order
                  </Typography>
                </Box>
                <Typography className={classes.textHeader}>Stop-loss Market Order (SL-M):</Typography>
                <Box display="flex" flexDirection="row">
                  <FiberManualRecord className={classes.dot} />
                  <Box>
                    <Typography className={classes.textContent}>
                      In this case, when the order gets triggered it goes as a Market order to the exchange. Zero has to be entered in Limit Price to place a Stop loss Market order.
                    </Typography>
                    <Typography className={classes.textContent}>
                      The chances of execution for Stop loss market orders are higher than that of Stop loss limit order.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} style={{ opacity: 0 }} />
              <Typography className={classes.textContent}>
                So for example, if you have bought a stock at Rs. 100 and you want to limit the loss at 95, you can place an order in the system to sell the stock as soon as the stock comes to 95 by entering 95 in Trigger Price. Such an order is called as a Stop loss order.
              </Typography>
            </Box>
          </Box>
          {/* <Typography className={classes.textHeader}>Product wise</Typography>
                    <Typography className={classes.textHeader}>AMO Orders</Typography> */}
        </Typography>
      </div>);
  }

  const DisclosedQuantity = () => {
    return (
      <div className={classes.textContiner}>
        <Typography className={classes.textContent}>
          <Typography className={classes.textContent}>
            <Typography className={classes.textHeader}>Disclosed Quantity</Typography>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />
              <Typography className={classes.textContent}>
                A part of the quantity will be disclosed in the market. Minimum 10% of the total quantity can be entered for cash segment.
              </Typography>
            </Box>
          </Typography>
          <Typography className={classes.textContent}>
            <Typography className={classes.textHeader}>Trigger Price</Typography>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />
              <Typography className={classes.textContent}>
                Trigger price is the price at which your buy or sell order becomes active for execution at the exchange. In other words, once the price of the stock hits the trigger price set by you, the order is sent to the exchange.
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />
              <Box>
                <Typography className={classes.textContent}>
                  The trigger price is part of a Stop Loss order.
                </Typography>
                <Typography className={classes.textHeader}>For Stoploss with Limit</Typography>
                <Box display="flex" flexDirection="row">
                  <FiberManualRecord className={classes.dot} />
                  <Typography className={classes.textContent}>
                    When you place a Stop Loss order with Limit, you need to enter 2 types of prices; Trigger Price and Limit Price. Your Stop Loss order gets activated when the price of a security reaches or crosses the Trigger Price. The order is executed at the limit price mentioned by you.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <FiberManualRecord className={classes.dot} />
                  <Typography className={classes.textContent}>
                    For example, you buy 100 shares at a price of Rs 100. You put a Stop Loss order to minimize your losses in case the share price goes down. Your trigger price is Rs 98 and the limit price is Rs 96. Now as soon as the share price reaches 98 or goes below, a Sell order will be automatically placed by the system. The order will be executed till the share price reaches Rs 96.
                  </Typography>
                </Box>
                <Typography className={classes.textHeader}>For Stop-loss with Market</Typography>
                <Box display="flex" flexDirection="row">
                  <FiberManualRecord className={classes.dot} />
                  <Typography className={classes.textContent}>
                    When you place a Stop Loss order, you need to enter Trigger Price and Limit Price must be zero (for market order). Your Stop Loss order gets activated when the price of a security reaches or crosses the Trigger Price and the order gets executed at market price.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <FiberManualRecord className={classes.dot} />
                  <Typography className={classes.textContent}>
                    For example, you buy 100 shares at a price of Rs 100. You put a Stop Loss order to minimize your losses in case the share price goes down. Your trigger price is Rs 98 and the limit price is zero (market). Now as soon as the share price reaches 98 or goes below, a Sell order will be automatically placed by the system and the order will be executed at market price.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />
              <Typography className={classes.textContent}>
                Trigger Price must be less than or equal to Limit Price in case of Buy Stoploss orders
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <FiberManualRecord className={classes.dot} />
              <Typography className={classes.textContent}>
                Trigger Price must be greater than or equal to Limit Price in case of Sell Stoploss orders
              </Typography>
            </Box>
          </Typography>
        </Typography>
      </div >);
  }

  const Product = () => {
    return (
      <div className={classes.textContiner}>
        <Typography className={classes.textContent}>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Typography className={classes.textContent}><span className={classes.textHeader}>Delivery, </span>where you carry stock for next day. Ideal for long term investment.</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Typography className={classes.textContent}><span className={classes.textHeader}>Intraday, </span>positions need to be squared off same day, before market closes.</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Typography className={classes.textContent}><span className={classes.textHeader}>MTF orders, </span>through which you can buy and hold stocks overnight with funding from Tradebulls. Good for Traders.</Typography>
          </Box>
        </Typography>
      </div>);
  }

  const Validity = () => {
    return (
      <div className={classes.textContiner}>
        <Typography className={classes.textContent}>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Typography className={classes.textContent}><span className={classes.textHeader}>Day orders </span>are valid for the day, If not executed; it gets cancelled at the end of the day.</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <FiberManualRecord className={classes.dot} />
            <Typography className={classes.textContent}><span className={classes.textHeader}>IOC orders </span>(Immediate OR Cancel) is an order wherein it is executed immediately (buy or sell) or cancelled, if it cannot be executed immediately.</Typography>
          </Box>
        </Typography>
      </div>);
  }

  const AMOOrders = () => {
    return (
      <div className={classes.textContiner}>
        <Box display="flex" flexDirection="row">
          <FiberManualRecord className={classes.dot} />
          <Typography className={classes.textContent}>After Markets Orders are orders, which are taken during non-market hours, and sent to the exchange, when the markets opens next. Timings of AMO orders for cash segments are from 4 pm till 8.50am of the next working day, and are pushed to the exchange at 9am.</Typography>
        </Box>
        <Typography className={classes.textContent}>

        </Typography>
      </div>);
  }

  const HelpListItem = ({ title, value, CollapsedComponent }) => {
    const theme = useTheme();
    return (
      <>
        <ListItem
          button
          disableGutters
          divider={openItem !== value}
          onClick={() => {
            setOpenItem(openItem != value ? value : null);
          }}>
          <ListItemText classes={{ primary: classes.title }}>{title}</ListItemText>
          <ListItemIcon className={classes.listItemIcon}>
            {openItem === value ?
              <SvgComponent iconname={theme.palette.type === 'light' ? 'HelpArrowUpIconLight' : 'HelpArrowUpIconDark'} />
              : <SvgComponent iconname={theme.palette.type === 'light' ? 'HelpArrowDownIconLight' : 'HelpArrowDownIconDark'} />}
          </ListItemIcon>
        </ListItem>
        <Collapse
          in={openItem === value}
          timeout="1000"
          unmountOnExit
          className={classes.collapse}>
          {<CollapsedComponent />}
        </Collapse>
      </>
    );
  }

  return (
    <div>
      <Dialog open={open} /* maxWidth={"xs"} */ onClose={onClose} classes={{ paper: classes.dialogRoot }}>
        <DialogContent className={classes.content}>
          {/* <Accordion>
                        <AccordionSummary><Typography className={classes.title}>This is title</Typography></AccordionSummary>
                        <AccordionDetails><OrderTypes /></AccordionDetails>
                    </Accordion> */}
          <List className={classes.list}>
            <HelpListItem title={'Types of Orders'} value={'orders'} CollapsedComponent={OrderTypes} />
            <HelpListItem title={'Disclosed Quantity & Trigger Price'} value={'disclosed'} CollapsedComponent={DisclosedQuantity} />
            <HelpListItem title={'Product (Delivery, Intraday, MTF)'} value={'product'} CollapsedComponent={Product} />
            <HelpListItem title={'Valid Till (Day,IOC)'} value={'validTill'} CollapsedComponent={Validity} />
            <HelpListItem title={'AMO Orders'} value={'amoOrders'} CollapsedComponent={AMOOrders} />
          </List>
          <Button
            // variant="contained"
            className={classNames(classes.outlinedBlue, classes.button)}
            onClick={onClose}>
            {'Back'}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default React.memo(withStyles(combineStyles(styles, commonStyles))(OrderHelp));
