import { getFixedValueObject } from 'helpers/scripHelper';
import { store } from 'helpers/store';
import { reportService } from 'services/reports';
import { getLayout, resetLayout, saveLayout, setLayout } from "views/Dashboard/layouts/layoutHelper";

const GET_ORDER_REPORTS = 'GET_ORDER_REPORTS';
const GET_ORDER_REPORTS_SUCCESS = 'GET_ORDER_REPORTS_SUCCESS';
const GET_ORDER_REPORTS_FAILURE = 'GET_ORDER_REPORTS_FAILURE';

let lastUpdated = 0;
const staticClient = 'all';
const CUSTOM_INTERVAL = 5000;

export const ReportsActions = {
  getReportsOrder,
};
//PRIVATE
// const getClientCode = () => {
//   const { client: { selectedClient } } = store.getState();

//   let selectedClientCode = 'all';
//   if (selectedClient)
//     selectedClientCode = selectedClient.ClientCode;
//   return selectedClientCode ? `${selectedClientCode}` : undefined;
// }

// const logId = (type, row) => {
//   if (row.clientCode == staticClient || staticClient == 'all') {
//     console.log('Incremental:: ', type, row.clientCode, row.tbOrderId, row.orderStatus, row.VarCurrentTime);
//   }
// }

const getConcatenatedRecords = ({ oldData, newData }) => {
  let splicedOldData = oldData;
  if (newData.length > 0) {
    newData.map((newRow, index) => {
      // logId('newData map', newRow);
      newRow = getFixedValueObject(newRow, "order");
      splicedOldData = splicedOldData.filter(oldRow => {
        // logId('Splice filter', oldRow);
        return newRow.tbOrderId != oldRow.tbOrderId;
      });
      newData[index] = newRow;
    });
  } /* else {
    splicedOldData = oldData;
  } */

  // console.log(`Incremental:: new:${newData.length}, old:${oldData.length}, spliced:${splicedOldData.length}`);
  if (newData && newData.length === 0) return oldData;
  for (let i = newData.length; i > 0; i--) {
    const object = newData[i - 1];
    splicedOldData.unshift(object);
  }
  // console.log(`Incremental:: new:concat::${splicedOldData.length}`);
  return [...splicedOldData];
}
//ACTIONS
function getReportsOrder() {
  onRequest();
  reportService.getOrderReports(
    // getClientCode()
    staticClient
    , lastUpdated, 'ALL').then(response => {
      onSuccess(response.data);

      if (lastUpdated == 0) {
        setInterval(() => {
          getReportsOrder();
        }, CUSTOM_INTERVAL);
      }
      const { common: { serverTime } } = store.getState();
      // lastUpdated = serverTime;
      if (response.data.length > 0) {
        lastUpdated = response.data[0].VarCurrentTime;
      }
    }).catch(error => {
      onFailure(error);
    });

  function onRequest() { store.dispatch({ type: GET_ORDER_REPORTS }); }
  function onSuccess(data) { store.dispatch({ type: GET_ORDER_REPORTS_SUCCESS, data, /* storedList */ }); }
  function onFailure(error) { store.dispatch({ type: GET_ORDER_REPORTS_FAILURE, error }); }
}
//REDUCER
export function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDER_REPORTS:
      return {
        ...state,
        fetching: true
      };
    case GET_ORDER_REPORTS_SUCCESS:
      const concatData = getConcatenatedRecords({ oldData: state.orders, newData: action.data });
      return {
        ...state,
        fetching: false,
        orders: concatData
      };
    case GET_ORDER_REPORTS_FAILURE:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
}

const initialState = {
  orders: []
};
