import { Close as CloseIcon } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Step, StepConnector, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import { Event } from "components/Event";
import Placeholder from "components/Placeholder";
import axios from "helpers/interceptor";
import SvgComponent from "helpers/svgcomponent";
import React, { useEffect, useState } from "react";
import NumericTextBox from "components/NumericTextBox";
import ResendButton from "components/ResendButton";

const api = {
  getRelations: "/ms-dkyc-process/process/modification/report/",
  getOtp: "/ms-dkyc-process/process/modification/sendOtp",
  verifyOtp: "/ms-dkyc-process/process/modification/verifyDeatils"
}

const styles = (theme) => ({
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: "0px",
    height: "25px",
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: "#497CAC",
    fontWeight: 600,
    lineHeight: "24px"
  },
  content: {
    padding: "0px !important",
    overflow: "hidden",
    width: "30vw",
    height: "32vh",
    marginTop: "40px",
    backgroundColor: theme.palette.color17.default,
    [theme.breakpoints.down('xl')]: {
      width: "36vw",
      height: "42vh",
      marginTop: "36px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "48vw",
      height: "44vh"
    },
    [theme.breakpoints.down('md')]: {
      width: "55vw",
      height: "34vh"
    },
  },
  iconDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  closeIcon: {
    float: "right",
    height: "24px",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: 0,
    background: "none"
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 'calc(100% - 20px)',
    overflowY: "auto",
    [theme.breakpoints.down('xl')]: {
      height: 'calc(100% - 14px)',
    }
  },
  verifyImage: {
    height: "130px",
    width: "160px",
    [theme.breakpoints.down('xl')]: {
      width: "130px",
      height: "100px"
    },
  },
  heading: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  buttonGrid: {
    justifyContent: "center",
    display: "flex"
  },
  sendOtp: {
    width: "100%",
    textTransform: "none",
    color: "#fff",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  textDiv: {
    border: `1px solid ${theme.palette.color5.default}`,
    borderRadius: "4px",
    padding: "18px",
    marginLeft: "12px",
    marginRight: "12px",
    marginBottom: "28px",
    display: "flex",
    alignItems: "center",
    paddingRight: "12px"
  },
  numberDiv: {
    width: "62px",
    height: "28px",
    borderRadius: "50%",
    background: theme.palette.background.secondary,
    boxShadow: "0px 0px 6px 0px #a5aaad63",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginLeft: "-30px",
    [theme.breakpoints.down('xl')]: {
      width: "58px",
      height: "24px",
    },
    '&.one': {
      width: "72px",
      [theme.breakpoints.down('xl')]: {
        width: "68px"
      },
    }
  },
  headingPadding: {
    padding: "8px 0px"
  },
  inputPadding: {
    paddingBottom: "16px"
  },
  relationshipPadding: {
    paddingBottom: "8px"
  },
  relationshipDiv: {
    flexDirection: "row",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2px",
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "20px"
    }
  },
  relationshipIconDiv: {
    border: `1px dashed #999999`,
    borderRadius: "4px",
    width: "65px",
    height: "60px",
    marginLeft: "4px",
    marginRight: "4px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&.colorDiv": {
      '& path': {
        fill: "#fff"
      },
      border: "1px dashed #006CA8",
      backgroundColor: "#006CA8"
    },
    "&:focus": {
      '& path': {
        fill: "#fff"
      },
      border: "1px dashed #006CA8",
      backgroundColor: "#006CA8",
      outline: "none"
    }
  },
  relationshipText: {
    color: "#999999",
    fontSize: "9px",
    lineHeight: "11px",
    textAlign: "center",
    paddingTop: "4px",
    "&.colorText": {
      color: "#fff"
    },
    "&:focus": {
      color: "#fff"
    }
  },
  step: {
    fontSize: "30px",
    color: "#F54040",
    fontWeight: 600,
    paddingBottom: "14px",
    paddingTop: "2px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "28px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "26px"
    },
  },
  doneImage: {
    width: "100px",
    height: "160px",
    marginTop: "-40px",
    [theme.breakpoints.down('xl')]: {
      width: "95px",
      height: "140px",
    },
  },
  resend: {
    textAlign: "center",
    paddingTop: "6px"
  },
  numericTextBoxPadding: {
    padding: "40px 0px",
    [theme.breakpoints.down('xl')]: {
      padding: "34px 0px"
    },
    [theme.breakpoints.down('md')]: {
      padding: "34px 0px 28px 0px"
    },    
  },
  verifiedValue: {
    fontWeight: 500,
    fontSize: "18px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "14px"
    },
  },
  verifiedText: {
    color: "#046E57"
  },
  verifiedDiv: {
    border: `1px dashed ${theme.palette.color10.default}`,
    padding: "10px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  errorText: {
    color: "#f54040",
    marginTop: "4px",
    textAlign: "center"
  },
  step2Padding: {
    paddingBottom: "40px"
  },
  verifiedInfoDiv: {
    margin: "36px 0px",
    [theme.breakpoints.down('xl')]: {
      margin: "28px 0px 26px 0px",
    },

  }
})

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 0,
    left: 'calc(-50%)',
    right: 'calc(50%)',
  },
  active: {
    '& $line': {
      borderColor: '#FF3B1C',
      backgroundColor: '#FF3B1C'
    },
  },
  completed: {
    '& $line': {
      borderColor: '#FF3B1C',
      backgroundColor: '#FF3B1C'
    },
  },
  line: {
    borderColor: theme.palette.background.default,
    borderTopWidth: 5,
    borderRadius: 0
  },
}))(StepConnector)

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiInput-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.text.primary
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent"
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      }
    }
  }
}))(TextField);

const steps = [1, 2, 3, 4, 5, 6]

const relationIcons = {
  "Self": "Self",
  "Spouse": "Spouse",
  "Dependent Child": "DependentChild",
  "Dependent Parent": "DependentParent"
}

const modifyArray = (arr, field) => {
  let fieldValue = field === "email" ? "Email" : "Mobile"
  return arr.filter(ele => ele.RelationType === fieldValue)
}

function Step1(props) {
  const { verifyField, validateValue, classes, relation, setRelation, value, setValue, error, activeStep, relations } = props

  const onKeyDown = (e, ele) => {
    if (e.keyCode == 13) {
      setRelation(ele["Relationship"])
    }
  }

  return (
    <div className={classes.contentDiv}>
      {
        relations.loading ?
          <Placeholder
            loading={relations.loading ? true : false}
            loadingMsg={""}
            error={relations.loading ? "Loading..." : relations.message}
          />
          :
          <>
            {/* <SvgComponent iconname={verifyField === "email" ? "VerifyEmail" : "VerifyMobile"} className={classes.verifyImage} /> */}
            <Typography variant="h5" className={[classes.heading, classes.headingPadding].join(" ")}>Add New {verifyField === "email" ? "Email ID" : "Mobile Number"}</Typography>
            <CssTextField
              className={classes.inputPadding}
              label=""
              id="standard-start-adornment"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              InputProps={{
                startAdornment: verifyField === "email" ? <></> : <InputAdornment position="start">+91</InputAdornment>,
              }}
            />
            {error.show && activeStep === 1 ? <p className={classes.errorText} style={{ marginTop: "-10px", paddingBottom: "16px" }}>{error.message}</p> : ""}
            <Typography variant="h5" className={[classes.heading, classes.relationshipPadding].join(" ")}>Relationship</Typography>
            <div className={classes.relationshipDiv}>
              {
                relations.error ?
                  <Placeholder
                    loading={false}
                    loadingMsg={""}
                    error={relations.message}
                  />
                  :
                  <>
                    {
                      relations && relations.data && relations.data.length > 0 &&
                      relations.data.map((ele) => (
                        <div tabIndex={0} className={[classes.relationshipIconDiv, relation === ele["Relationship"] ? "colorDiv" : ""].join(" ")} onKeyDown={(e) => onKeyDown(e, ele)} onClick={() => setRelation(ele["Relationship"])}>
                          <SvgComponent iconname={relationIcons[ele["Relationship"]]} />
                          <Typography className={[classes.relationshipText, relation === ele["Relationship"] ? "colorText" : ""].join(" ")}>{ele["Relationship"]}</Typography>
                        </div>
                      ))
                    }
                  </>
              }
            </div>
            {relations.error ? <></> :
              <>
                <Typography variant="h5" className={classes.heading}> Verify your {verifyField === "email" ? "Email ID" : "Mobile Number"}</Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container className={classes.buttonGrid}>
                      <Grid item xs={5}>
                        <Button disabled={/* error.loading || */ (verifyField === "email" ? value.length == 0 : value.length < 10)} className={[classes.sendOtp, classes.containedBlue].join(" ")} onClick={() => validateValue(2)}>{/* {error && error.loading && activeStep === 1 && <CircularProgress size={16} color="inherit" style={{ marginRight: "12px" }} />} */}Sent OTP</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }
          </>
      }
    </div >
  )
}

function Step2(props) {
  const { classes, handleVerification, error, activeStep } = props
  return (
    <div className={classes.contentDiv}>
      {/* <SvgComponent iconname="ThumbsUp" className={classes.doneImage} style={{ marginTop: "-12px" }} /> */}
      <Typography variant="h4" className={classes.heading}>Welcome to</Typography>
      <Typography variant="h4" className={[classes.heading, classes.step2Padding].join(" ")}>2 Step Verification</Typography>
      <Grid container className={classes.centerContent} style={{ flexDirection: "row" }}>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.textDiv}>
                <div className={[classes.numberDiv, "one"].join(" ")}><Typography variant="h5" className={classes.heading}>1</Typography></div>
                <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500, paddingLeft: "6px" }}>Kindly verify existing number with the OTP sent on existing mobile number/email id.</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.textDiv}>
                <div className={classes.numberDiv}><Typography variant="h5" className={classes.heading}>2</Typography></div>
                <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500, paddingLeft: "6px" }}>Verify the New Number with second OTP sent on new mobile number</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={5}>
              <Button disabled={error.loading} className={[classes.sendOtp, classes.containedBlue].join(" ")} onClick={() => handleVerification({ type: "old" }, 3, "get")}>{error && error.loading && activeStep === 2 && <CircularProgress size={16} color="inherit" style={{ marginRight: "12px" }} />}OK</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

function Step3(props) {
  const { classes, handleVerification, activeStep, error } = props
  const [oldDataOtp, setOldDataOtp] = useState("")

  useEffect(() => {
    if (oldDataOtp.length < 6)
      document.getElementById("submit3Focus").disabled = true;
  }, [oldDataOtp])

  useEffect(() => {
    if (error && error.show) {
      setOldDataOtp("")
    }
  }, [error])

  function handleSubmitFocus() {
    document.getElementById("submit3Focus").disabled = false;
    document.getElementById("submit3Focus").focus();
  }

  return (
    <div className={classes.contentDiv}>
      <Typography className={classes.step}>Step 1</Typography>
      <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500 }}>Enter OTP sent to your</Typography>
      <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500 }}>existing Mobile Number / Email ID</Typography>
      <div className={classes.numericTextBoxPadding}>
        <NumericTextBox maxLength={6} setVal={setOldDataOtp} val={oldDataOtp} count={6} value={oldDataOtp} setFocus={handleSubmitFocus} />
        {error.show && activeStep === 3 ? <p className={classes.errorText}>{error.message}</p> : ""}
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={5}>
              <Button disabled={activeStep === 3 && error.loading} id={"submit3Focus"} variant={"contained"} color={"secondary"} className={classes.sendOtp} onClick={() => handleVerification({ type: "old", otp: oldDataOtp }, 4, "verify")}>{error && error.loading && activeStep === 3 && <CircularProgress size={16} color="inherit" style={{ marginRight: "12px" }} />}Submit</Button>
              <div className={classes.resend}>
                <ResendButton onClick={() => { setOldDataOtp(""); handleVerification({ type: "old" }, 4, "get", true) }} timeOut={300} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

function Step4(props) {
  const { classes, handleVerification, verifyField, mobileNo, email, activeStep, error } = props
  return (
    <div className={classes.contentDiv}>
      <Typography className={classes.step}>Step 1</Typography>
      <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500 }}>Enter OTP sent to your</Typography>
      <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500 }}>existing Mobile Number / Email ID</Typography>
      <Grid container>
        <div style={{ width: "100%"}} className={classes.verifiedInfoDiv}>
          <Grid item xs={12}>
            <Grid container className={classes.buttonGrid}>
              <Grid item xs={5} className={classes.verifiedDiv}>
                <Typography className={[classes.heading, classes.verifiedValue].join(" ")} style={{ fontWeight: 500 }}>{verifyField === "email" ? email : `+91${mobileNo}`}</Typography>
                <SvgComponent iconname="Verified" style={{ margin: "6px 0px 2px" }} />
                <Typography variant="h6" className={classes.verifiedText}>Verified</Typography>
              </Grid>
            </Grid>
          </Grid>
          {error.show && activeStep === 4 ? <p className={classes.errorText}>{error.message}</p> : ""}
        </div>
        <Grid item xs={12}>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={5}>
              <Button disabled={activeStep === 4 && error.loading} autoFocus={true} className={[classes.sendOtp, classes.containedBlue].join(" ")} onClick={() => handleVerification({ type: "new" }, 5, "get")}>{error && error.loading && activeStep === 4 && <CircularProgress size={16} color="inherit" style={{ marginRight: "12px" }} />}NEXT</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

function Step5(props) {
  const { classes, handleVerification, verifyField, error, activeStep } = props
  const [newDataOtp, setNewDataOtp] = useState("")

  useEffect(() => {
    if (newDataOtp.length < 6)
      document.getElementById("submit5Focus").disabled = true;
  }, [newDataOtp])

  useEffect(() => {
    if (error && error.show) {
      setNewDataOtp("")
    }
  }, [error])

  function handleSubmitFocus() {
    document.getElementById("submit5Focus").disabled = false;
    document.getElementById("submit5Focus").focus();
  }

  return (
    <div className={classes.contentDiv}>
      <Typography className={classes.step}>Step 2</Typography>
      <Typography variant="h4" className={classes.heading} style={{ fontWeight: 500 }}>Enter OTP sent to your</Typography>
      <Typography variant="h4" className={classes.heading} style={{ fontWeight: 500 }}>New {verifyField === "email" ? "Email ID" : "Mobile Number"}</Typography>
      <div className={classes.numericTextBoxPadding}>
        <NumericTextBox maxLength={6} setVal={setNewDataOtp} val={newDataOtp} count={6} value={newDataOtp} setFocus={() => handleSubmitFocus()} />
        {error.show && activeStep === 5 ? <p className={classes.errorText}>{error.message}</p> : ""}
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.buttonGrid}>
            <Grid item xs={5}>
              <Button disabled={error.loading} id={"submit5Focus"} variant={"contained"} color={"secondary"} className={classes.sendOtp} onClick={() => handleVerification({ type: "new", otp: newDataOtp }, 6, "verify")}>{error && error.loading && activeStep === 5 && <CircularProgress size={16} color="inherit" style={{ marginRight: "12px" }} />}Submit</Button>
              <div className={classes.resend}>
                <ResendButton onClick={() => { setNewDataOtp(""); handleVerification({ type: "new" }, 6, "get", true) }} timeOut={300} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

function Step6(props) {
  const { classes, verifyField } = props
  return (
    <div className={classes.contentDiv} style={{ justifyContent: "center" }}>
      {/* <SvgComponent iconname={"ThumbsUp"} className={classes.doneImage} /> */}
      <Typography variant="h3" className={[classes.heading, classes.inputPadding].join(" ")}>Congratulations !</Typography>
      <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500 }}>Your new {verifyField === "email" ? "email id" : "mobile number"}</Typography>
      <Typography variant="h5" className={classes.heading} style={{ fontWeight: 500 }}>is updated in our system</Typography>
    </div>
  )
}

function EditInfoPopup(props) {
  const { classes, open, onClose, verifyField, activeStep, setActiveStep, mobileNo, emailId, clientCode } = props

  const [value, setValue] = useState("")
  const [error, setError] = useState({})
  const [relation, setRelation] = useState("")
  const [relations, setRelations] = useState({ loading: true, data: [] })

  useEffect(() => {
    if (clientCode) {
      getRelations()
    } else {
      setRelations({ loading: false, data: [], error: true, message: "Relationship Data not found" })
    }
  }, [])

  function getRelations() {
    axios.get(`${process.env.REACT_APP_API_URL}${api.getRelations}${clientCode}`).then(resp => {
      if (resp.data.success && resp.data.success.relationMaster && resp.data.success.relationMaster.length > 0) {
        let relationData = modifyArray(resp.data.success.relationMaster, verifyField)
        setRelations({ loading: false, data: relationData })
        let relation = relationData.find(element => element.Relationship == "Self")
        if (relation) {
          setRelation(relation.Relationship)
        } else {
          setRelation(relationData[0].Relationship)
        }
      } else {
        setRelations({ loading: false, data: [], error: true, message: "Relationship Data not found" })
      }
    }).catch(error => {
      setRelations([{ loading: false, data: [], error: true, message: error.message }])
    })
  }

  const validateValue = (step) => {
    if (verifyField === "email") {
      if (value && (!value.includes("@") || !value.includes(".") || !(/^[a-zA-Z0-9]+([-_\.]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})+$/).test(value))) {
        setError({ show: true, step: 1, message: "Not a valid email" })
        setTimeout(() => {
          setError({ show: false, step: 1 })
        }, 5000)
        // return true
      } else {
        // setError({ show: false, message: "" })
        // return false
        handleNextStep(step)
      }
    } else {
      if (value && Number(value) === NaN) {
        setError({ show: true, step: 1, message: "Not a valid number" })
        setTimeout(() => {
          setError({ show: false, step: 1 })
        }, 5000)
        // return true
      } else if (value && String(value).length === 10 && String(value).split("").filter((x) => { return String(value)[0] !== x }).length === 0) {
        setError({ show: true, activeStep: 1, message: "All digits cannot be same" })
        setTimeout(() => {
          setError({ show: false, step: 1 })
        }, 5000)
        // return true
      } else if (value && (String(value).length < 10 || value && String(value).length > 10)) {
        setError({ show: true, step: 1, message: "Not a valid number" })
        setTimeout(() => {
          setError({ show: false, step: 1 })
        }, 5000)
        // return true
      }
      else {
        // setError({ show: false, message: "" })
        // return false
        handleNextStep(step)
      }
    }
  }

  function verify(data, step, type, resend) {
    // if (activeStep === 1 && validateValue()) {
    //   return true
    // }
    !resend && setError({ loading: true, step })
    resend && error.show && setError({ show: false })
    let payload = {
      clientCode: clientCode,
      mode: verifyField,
      relation: relation,
      type: data.type
    }
    if (type === "verify") {
      payload = {
        ...payload,
        otp: data.otp
      }
    }
    if (data.type === "new") {
      payload = {
        ...payload,
        [verifyField]: value
      }
    } else {
      payload = {
        ...payload,
        "mobile": mobileNo,
        "email": emailId
      }
    }
    axios.post(`${process.env.REACT_APP_API_URL}${type === "get" ? api.getOtp : api.verifyOtp}`, payload).then(resp => {
      if (resp.data.success) {
        if (resend) {
          const otpSuccessEvent = new Event("OTP_SUCCESS");
          otpSuccessEvent.sendMessage()
          setError({ show: true, message: resp.data.success })
          setTimeout(() => {
            setError({ show: false, step })
          }, 10000)
        } else {
          handleNextStep(step)
          setError({ loading: false })
        }
      } else {
        setError({ show: true, loading: false, step, message: resp.data.error })
        setTimeout(() => {
          setError({ show: false, step })
        }, 10000)
      }
    }).catch(error => {
      setError({ show: true, loading: false, step, message: error.message })
      setTimeout(() => {
        setError({ show: false, step })
      }, 10000)
    })
  }

  function handleNextStep(step) {
    setActiveStep(step)
  }

  function getStepContent(step) {
    switch (step) {
      case 1:
        return <Step1
          {...props}
          value={value}
          error={error}
          setValue={setValue}
          relation={relation}
          relations={relations}
          activeStep={activeStep}
          setRelation={setRelation}
          handleVerification={verify}
          validateValue={validateValue}
        />
      case 2:
        return <Step2
          {...props}
          error={error}
          handleVerification={verify}
        />
      case 3:
        return <Step3
          {...props}
          activeStep={activeStep}
          handleVerification={verify}
          error={error}
        />
      case 4:
        return <Step4
          {...props}
          error={error}
          email={emailId}
          mobileNo={mobileNo}
          activeStep={activeStep}
          handleVerification={verify}
        />
      case 5:
        return <Step5
          {...props}
          error={error}
          activeStep={activeStep}
          handleVerification={verify}
        />
      case 6:
        return <Step6
          {...props}
          handleNextStep={handleNextStep}
        />
      default:
        return <></>

    }
  }

  return (
    <Dialog open={open} onClose={(event, reason) => reason != 'backdropClick' && onClose && onClose(event, reason)} maxWidth="lg">
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11} className={classes.headerGrid}>
            <Typography className={classes.title}>{"Edit Mobile/Email ID"}</Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} >
            <Tooltip title="Close" aria-label="close">
              <IconButton aria-label="close" onClick={onClose} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Stepper style={{ padding: 0, display: "none" }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label} />
          ))}
        </Stepper>
        {getStepContent(activeStep)}
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(combineStyles(styles, commonStyles))(EditInfoPopup)
