export default (theme) => ({
  root: {
    padding: '8px 0px 12px 0px'
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  content: {
    backgroundColor: theme.palette.color17.default,
    padding: '8px 12px !important',
    width: 350,
    [theme.breakpoints.down('xl')]: {
      padding: '16px !important',
      width: 300
    },
    [theme.breakpoints.down('md')]: {
      width: 250
    }
  },
  header: {
    textAlign: 'center',
    paddingTop: 20,
    padding: '0px'
  },
  contentGrid: {
    borderRadius: '4px',
    padding: '16px 12px',
    [theme.breakpoints.down('xl')]: {
      padding: '12px 8px'
    }
  },
  progressBar: {
    height: 8,
    borderRadius: 4
  },
  count: {
    textAlign: 'center',
    color: theme.palette.color15.default,
    paddingTop: 12
  },
  barColorPrimary: {
    backgroundColor: '#299d00',
  }
});
