import { Button, Checkbox, CircularProgress, CssBaseline, FormControl, FormControlLabel, Grid, Input, InputLabel, Link, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { login, logout } from 'redux/Login';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    width:'100%'
  },
  grid: {
    height: '100%'
  },
  main: {
    width: '100%',
    marginLeft: theme.spacing(3), 
    marginRight: theme.spacing(3),
    
    [theme.breakpoints.up(990)]: {
      width: '25%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  topDiv:{
    height:"25px",
    width:"100%",
    background:theme.palette.secondary.dark
  },
  appBar:{
    height:"50px",
    minHeight:"50px"
  },
  verticalMiddle:{
    display:"grid",
    alignItems:"center",
    minHeight:"600px"
  },
  link:{
    textDecoration: "none",
    position: "relative",
    float: "right",
    textAlign: "right",
    color: "rgba(0, 0, 0, 0.54)",
    top: "20px",
    right: "-15px",
    transform: "scale(0.75)",
    zIndex: "999",
    height:"0px"
  },
  logo:{
    height: "40px",
    marginLeft: "10px",
    marginTop: "5px"
  },
  helpBlock:{
    color:"#f44336",
    padding:"5px 0px 0px 0px"
  },
  MuiFormLabel: {
        "&$focused": {
          color: "#b71c1c"
    }
  },
  sideImage: {
    backgroundColor: theme.palette.common.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/sign_up_1.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    // flexBasis: '600px'
  },
  quoteText1: {
    color: theme.palette.common.white,
    fontWeight: 300,
    fontSize: 50
  },
  quoteText2: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 50
  },
  quoteText3: {
    color: theme.palette.common.white,
    fontWeight: 300,
    fontSize: 32
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.common.white
  },
});



class SignIn extends React.Component {
  constructor(props) {
      super(props);

      // reset login status
      this.props.dispatch(logout());

      this.state = {
          username: '',
          password: '',
          submitted: false,
          submitting:false
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
  }

  handleSubmit(e) {
      e.preventDefault(true);
      this.setState({ submitted: true });
      const { username, password } = this.state;
      const { dispatch } = this.props;
      if (username && password) {
          this.setState({submitting:true})
          dispatch(login(username, password));
      }
      else{
        return false
      }
  }

  render() {
      
      const { username, password, submitted } = this.state;
      const { classes } = this.props;
      return (
         <div className={classes.root}>
          
          
          <Grid className={classes.grid} container>
                
                
                <Grid item md={12} className={classes.verticalMiddle}>
                <main className={[classes.main].join(",")}>
                    <CssBaseline />  
                   
                        <Typography component="h1" variant="h4">
                          Login
                        </Typography>
                        <Typography variant="caption">
                          Please enter your username and password to continue
                        </Typography>
                        <form name="form" onSubmit={(e)=>(this.handleSubmit(e))}>
                            <div className={classes.helpBlock} style={{padding:"5px 0px"}}>{this.props.alert&&this.props.alert.message}</div>
                            <FormControl margin="normal" error={submitted && !username } required fullWidth className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                              <InputLabel className={classes.MuiFormLabel} htmlFor="username">Username</InputLabel>
                              <Input tabIndex={1} autoFocus autoComplete={"off"} name="username" className={"muiInputCustom"} value={username} onChange={this.handleChange}/>
                                {submitted && !username ?
                                    <div className={classes.helpBlock}> ⚠️ Username is required</div>
                                    :<div className="help-block">&nbsp;</div>
                                }
                            </FormControl>  
                            <Link href="/" tabIndex={4} className={classes.link}>
                                  Forgot Password ?
                            </Link>
                            <FormControl margin="normal" error={submitted && !password } required fullWidth className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                              <InputLabel htmlFor="password">Password</InputLabel>
                              <Input  tabIndex={2}  autoComplete={"off"} name="password" className={"muiInputCustom"} type="password" id="password" value={password} onChange={this.handleChange}  />
                              {submitted && !password ?
                                    <div className={classes.helpBlock}> ⚠️Password is required</div>
                                    :<div className="help-block">&nbsp;</div>
                                }
                            </FormControl>
                            <FormControlLabel
                              control={<Checkbox value="remember" color="primary" />}
                              label="Remember me"
                            />
                            <Button
                              type="submit" 
                              fullWidth
                              tabIndex={3}
                              variant="contained"
                              color="primary"
                              onClick={(e)=>{this.handleSubmit(e)}}
                              className={[classes.submit,"muiButtonSpl"].join(" ")}
                              disabled={this.state.submitting}
                            >
                              {this.state.submitting?<CircularProgress style={{height:"15px",width:"15px",marginRight:"7px"}}></CircularProgress>:""}
                              Sign in
                            </Button>
                            
                        </form>
                    
                    </main>  
                </Grid>
          </Grid>
          
          </div>
         
      );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const  alert  = state.alert
  return {
      loggingIn,
      alert
  };
}

const connectedLoginPage = connect(mapStateToProps)(withStyles(styles)(SignIn));
export { connectedLoginPage as SignIn };
