export default ({ palette }) => ({
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      color: palette.common.blue,
      textDecorationColor: palette.common.blue,
      cursor: 'pointer'
    }
  }
});
