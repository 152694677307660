export default theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  hoverSection: {
    display: 'flex',
    color: theme.palette.color15.default,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    backgroundColor: theme.palette.common.blue,
    color: "#fff",
    fontWeight: 500,
    fontSize: "12px",
    padding: "4px 12px",
    margin: "0px 4px",
    borderRadius: "4px",
    textTransform: "none",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    '&:hover': {
      backgroundColor: "#006CA880"
    },
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px",
      margin: "0px 2px"
    }
  },
  errorText: {
    color: "#f54040",
    marginTop: "2px"
  },
  menuItem: {
    padding: "6px 12px",
  },
  select: {
    border: "1px solid #66788a4d",
    color: theme.palette.color12.default
  },
  root: {
    width: 400,
    [theme.breakpoints.down('lg')]: {
      width: 350,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  form: {
    margin: '8px 0px',
    padding: 16,
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      padding: 12
    },
  },
  fieldDiv: {
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
  fieldText: {
    marginBottom: theme.spacing(0.25),
    color: theme.palette.color15.default,
    fontSize: "12px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px"
    }
  },
  cancel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.75),
  },
  errorDiv: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    borderColor: "1px solid #f5c6cb",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "4px",
    textAlign: "center",
    marginBottom: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "3px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px",
    }
  },
  errorDivText: {
    color: "#721c24",
  },
})