// import { store } from 'helpers/store';
import { useEffect, useRef } from 'react';
// import { isAnyMarketOpen } from "./pollingHelper";

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback]
  );

  useEffect(
    () => {
      const handler = (...args) => {
        // const { common: { marketStatus } } = store.getState();
        // if (isAnyMarketOpen()) {
        //   debugger
          savedCallback.current(...args);
        // }
        // else {
        //   debugger
        //   console.log('Market Closed');
        // }
      }

      if (delay !== null) {
        const id = setInterval(handler, delay);
        return () => clearInterval(id);
      }
    },
    [delay]
  );
};

export default useInterval;