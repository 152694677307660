import { TextField } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";

const styles = theme => ({
  textbox: {
    margin: theme.spacing(0.25),
    cursor: 'none',
    boxShadow: "0px 0px 8px 0px #a5aaad63",
    width: "60px",
    "& input": {
      textAlign: 'center',
      userSelect: 'none',
      color: theme.palette.text.primary,
      fontSize: "28px",
      height: "15px",
      [theme.breakpoints.down('lg')]: {
        fontSize: "24px",
      }
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    [theme.breakpoints.down('md')]: {
      width: "54px"
    },
  },
})

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent !important',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.26) !important',
        borderWidth: 1
      },
    },
  },
})(TextField);

function NumericTextBox({ count, value, val, setVal, maxLength, setFocus, ...props }) {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const handleFocus = event => {
    event.preventDefault();
    const { target } = event;
    const extensionStarts = target.value.lastIndexOf('.');
    if (value && event.target.value)
      document.getElementById(value.length - 1).focus();
    /*  target.focus();
     target.setSelectionRange(0, extensionStarts); */
  }

  const handleOnChange = (event, i) => {
    document.getElementById(i).onpaste = e => {
      e.preventDefault()
      return false;
    }
    if (event.keyCode === 13 && i == count - 1) {
      // props.setStep(value)
      document.getElementById(0).focus()
    }
    if (event.keyCode === 8) {
      if (i !== 0 && i < count) {
        if (value.length === count) {
          document.getElementById(i).focus()
        } else {
          document.getElementById(i - 1).focus()
        }
      } else if (i === 0 && i < count) {
        document.getElementById(i).focus()
      } else if (i == count) {
        return
      }
      setVal(val.substr(0, val.length - 1))
      // setVal(val.substr(0, i) + " " + val.substr(i + 1));
      return
    } else if (i < count - 1) {
      if (event.key >= 0 && event.key <= 9)
        document.getElementById(i + 1).focus()
    }
    if (event.key >= 0 && event.key <= 9 && val.length < count) {
      let Value = val + event.key
      setVal(val + event.key)
      if (Value.length === count) {
        setFocus && setFocus()
      }
    }
  }

  if (count > 0) {
    const textBoxes = []
    const digits = val != undefined ? val.toString().split('').map(Number) : ''
    for (let i = 0; i < count; i++) {
      textBoxes.push(
        <CssTextField
          key={i}
          // tabIndex={1 + 1}
          autoFocus={i == 0}
          id={i}
          disabled={i != 0 && i >= value.length + 1}
          required={true}
          inputProps={{ min: 0, max: 9, maxLength: 1 }}
          className={props.classes.textbox}
          value={digits[i] != undefined ? digits[i] : ""}
          onKeyUp={(e) => handleOnChange(e, i)}
          onFocus={handleFocus}
          variant="outlined"
        />
      )

    }

    // if (matches) {
    //   return <TextField
    //     type={"number"}
    //     maxLength={maxLength}
    //     style={{ textAlign: "center", fontSize: "130%" }}
    //     InputProps={{ style: { width: "70%", margin: "auto" } }}
    //     inputProps={{ "maxlength": 6, pattern: "\d*", style: { textAlign: "center", fontSize: "130%" } }}
    //     onChange={e => {
    //       if (!isNaN(Number(e.target.value)) && e.target.value.length <= 6)
    //         setVal(e.target.value)
    //       else
    //         e.target.value = val
    //     }}
    //     onPaste={e => e.preventDefault()}
    //   ></TextField>
    // } else {
    return textBoxes
    // }
  }
}

export default withStyles(styles)(NumericTextBox);
