export default theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    margin: "0px",
    [theme.breakpoints.down('xl')]: {
      margin: "0px",
    }
  },
  rootGrid: {
    height: '100%',
    alignSelf: "center",
    display: 'flex',
    minWidth: "950px",
    padding: "16px",
    [theme.breakpoints.down('xl')]: {
      minWidth: "780px",
      padding: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: "750px",
      padding: "8px",
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "340px",
      padding: "8px",
    },
  },
  button: {
    textTransform: "none",
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    }
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: "16px",
    [theme.breakpoints.down('xl')]: {
      paddingBottom: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "10px",
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: "8px",
    },
    '&.valuePadding': {
      paddingBottom: "4px"
    }
  },
  secondaryText: {
    color: theme.palette.color15.default,
  },
  greenColor: {
    color: "#248E33"
  },
  greenBar: {
    backgroundColor: "#56CF66"
  },
  blueBar: {
    backgroundColor: "#56CBCF"
  },
  infoGrid: {
    padding: "16px",
    margin: "6px",
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.down('xl')]: {
      padding: "12px",
      margin: "4px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "10px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "8px",
    },
  },
  progressDiv: {
    paddingBottom: "12px",
    [theme.breakpoints.down('xl')]: {
      paddingBottom: "10px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "8px",
    },
    "&.divHeight": {
      height: "88px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down('xl')]: {
        height: "72px",
      },
      [theme.breakpoints.down('lg')]: {
        height: "64px",
      },
      [theme.breakpoints.down('md')]: {
        height: "60px",
      },
    }
  },
  legendColor: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "4px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      borderRadius: "3px"
    },
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      marginTop: theme.spacing(1.5),
      marginLeft: "4px",
      borderRadius: "1px"
    },
  },
  legendValue: {
    marginTop: theme.spacing(1),
    color: theme.palette.color12.default,
    [theme.breakpoints.only('lg')]: {
      marginTop: "6px",
      fontSize: "10px",
      lineHeight: "14px"
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "8px",
      fontSize: "9px",
      lineHeight: "13px"
    }
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  textGridBorder: {
    borderBottom: `1px dashed ${theme.palette.color15.default}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&.all": {
      borderLeft: `1px dashed ${theme.palette.color15.default}`,
      borderRight: `1px dashed ${theme.palette.color15.default}`
    },
  },
  placeholderDiv: {
    width: "100%",
    height: "100px",
    "&.popup": {
      height: "calc(100% - 32px)",
      [theme.breakpoints.down('xl')]: {
        height: "calc(100% - 24px)"
      },
      [theme.breakpoints.down('lg')]: {
        height: "calc(100% - 20px)"
      },
      [theme.breakpoints.down('md')]: {
        height: "calc(100% - 16px)",
      },
    },
    "&.data": {
      height: "440px",
      [theme.breakpoints.down('xl')]: {
        height: "360px"
      },
      [theme.breakpoints.down('lg')]: {
        height: "300px"
      },
      [theme.breakpoints.down('md')]: {
        height: "100px",
      },
    },
  }
})