import { store } from "helpers";
import axios from "helpers/interceptor";


export const FundExplorerService = {
    getDefaultData,
    getSearchAutoComplete,
    getFundByText,
    getFundFilters,
    getFundByFilters,
    getFundByCode
}

const defaultUrl = '/ms-mf-research/mutualfund/exploretopfund';
const autoCompleteUrl = '/ms-mf-fundsearch/search/autocomplete/1/10';
const filtersUrl = '/ms-mf-fundsearch/fundfilters';
const fundByFiltersUrl = '/ms-mf-fundsearch/fundbyfilters/0/0';
const searchUrl = '/ms-mf-fundsearch/fundbytext/';
const searchCodeUrl = '/ms-mf-fundsearch/fundbyCode/';

function getDefaultData() {
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}${defaultUrl}`,
    };
    return axios(options)
        .then((response) => {
            return response.data.success;
        }).catch((error) => {
            console.log(error);
        })
}

function getFundByCode(text) {
    const options = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}${searchCodeUrl}${text}`
    };
    // axios.get(`${process.env.REACT_APP_API_URL}${searchUrl}${key}/0/0`, { text: key, segment: "all", isTrading: true })
    return axios(options)
      .then((response) => {
        return response.data.success.result;
      })
      .catch((error) => {
        console.log('Search Error', error);
      });
  }

function getFundByText(text) {
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}${searchUrl}${text}/0/0`,
    };
    // axios.get(`${process.env.REACT_APP_API_URL}${searchUrl}${key}/0/0`, { text: key, segment: "all", isTrading: true })
    return axios(options)
        .then(response => {
            return response.data.success.result;
        })
        .catch(error => {
            console.log('Search Error', error);
        });
}

function getFundByFilters(payload) {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}${fundByFiltersUrl}`,
        data: payload
    };
    return axios(options)
        .then((response) => {
            return response.data.success.result;
        })
        .catch((error) => {
            console.log(error);
        });
}

function getSearchAutoComplete(text) {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}${autoCompleteUrl}`,
        data: [{ type: 'scheme', key: text }]
    };
    return axios(options)
        .then((response) => {
            return response.data.success;
        })
        .catch((error) => {
            console.log('Search Error', error);
        });
}

function getFundFilters(text) {
    const { searchString } = store.getState().fundExplorer;
    text = text || searchString && searchString.value || null;
    // debugger
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}${filtersUrl}${text ? '/' + text : ''}`,
    };
    // let url = `${process.env.REACT_APP_API_URL}${filtersUrl}${searchString && searchString.value ? '/' + searchString.value : ''
    //     }`;
    return axios(options)
        .then((response) => {
            return response.data.success;
        })
        .catch((error) => {
            console.log('Search Error', error);
        });
}
