import React, { forwardRef, useRef, useEffect } from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledCheckBox = withStyles(theme => ({
  root: {
    padding: '2px 3px'
  },
  disabled: {
    color: "#66666666 !important"
  },
}))(MuiCheckbox)

const Checkbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <StyledCheckBox
    color="primary"
    ref={resolvedRef}
    {...rest}
    checked={rest.disabled ? false : rest.checked}
  />
})

export default Checkbox;
