var broadCastStack = {}
export function Event(name = "") {
    if (name === "")
        throw "Event should have first parameter as its name"
    if (Object.keys(broadCastStack).indexOf(name) === -1)
        broadCastStack[name] = {}
    return {
        sendMessage: function (data) {
            for (var component in broadCastStack[name]) {
                broadCastStack[name][component](data);
            }
        }
    }
}

export function EventReciever(name = "", reciever = "") {
    if (name === "")
        throw "EventReciever should have first parameter as its name"
    if (reciever === "")
        throw "EventReciever should have second parameter as reciever name"
    if (Object.keys(broadCastStack).indexOf(name) === -1)
        //throw "Event with name :"+name+" is not created"
        broadCastStack[name] = {}
    return {
        onEvent: function (fn) {
            broadCastStack[name][reciever] = fn;
        }
    }
}
