import { ArrowRightAlt as ArrowIcon, Check as CheckIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, InputAdornment, Link, OutlinedInput, Stepper, Tooltip, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import axios from "axios";
import SvgComponent from "helpers/svgcomponent";
import React, { useState } from "react";
import styles from "./styles";

const CssOutlinedInput = withStyles(theme => ({
  root: {
    width: "100%",
    paddingRight: "0px",
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$error $notchedOutline": {
      borderWidth: 1,
      borderColor: "#f54040"
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {
    borderColor: "#66788a4d"
  }
}))(OutlinedInput)

const url = {
  cams: "https://mfs.kfintech.com/investor/General/ConsolidatedAccountStatement",
  generate: "/ms-mf-portfolio/importCASPortFolio",
  upload: "/ms-mf-portfolio/uploadPortFolioPDF"
}

function Step1(props) {
  const { classes, handleStep } = props

  return (
    <div className={classes.centerContent} style={{ height: "100%" }}>
      <SvgComponent iconname={"ImportPortfolio"} className={classes.image} />
      <Typography variant="h4" align="center" className={[classes.blueText, classes.textPadding].join(" ")}>Import Portfolio</Typography>
      <Typography variant="h6" align="center" className={classes.primaryText}>Consolidate and track all your mutual</Typography>
      <Typography variant="h6" align="center" className={classes.primaryText}>fund investment in one place</Typography>
      <Button variant="contained" color={"secondary"} className={classes.startButton} onClick={() => handleStep(1, 2)}>Get Started</Button>
      <Typography variant="h6" align="center" className={classes.primaryText}>Already have a</Typography>
      <Typography variant="h6" className={classes.primaryText}>Consolidated Account Statment</Typography>
      <Button variant="outlined" className={classes.uploadButton} onClick={() => handleStep(1, 4)} >Upload it here</Button>
    </div>
  )
}

function Step2(props) {
  const { classes, error, handleStep, setInputValue, value, onGeneratePortfolio, onBackClick } = props

  return (
    <div className={classes.step2Div}>
      <Typography variant="h4" className={[classes.blueText, classes.step2Heading].join(" ")}>Follow 3 easy steps to import Portfolio</Typography>
      <div className={classes.pointDiv}>
        <div className={classes.pointNumber}>
          1
        </div>
        <div style={{ width: "100%" }}>
          <Typography className={[classes.primaryText, classes.pointHeading].join(" ")}>Download from CAMS Website/Generate PDF</Typography>
          <Grid container>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={5} className={classes.flexColumn}>
                  <Typography variant="caption" className={classes.secondaryText}>Visit CAMS Website select the</Typography>
                  <Typography variant="caption" className={classes.secondaryText} style={{ marginBottom: "4px" }}>following options</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <CheckIcon className={classes.checkIcon} />
                    </span>
                    <Typography variant="caption" className={classes.secondaryText}>Statement Type <span className={classes.blueText}>(Detailed)</span></Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <CheckIcon className={classes.checkIcon} />
                    </span>
                    <Typography variant="caption" className={classes.secondaryText}>Period <span className={classes.blueText}>(Specified Period)</span></Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <CheckIcon className={classes.checkIcon} />
                    </span>
                    <Typography variant="caption" className={classes.blueText}>Without Zero Balance Folio</Typography>
                  </div>
                  <div className={[classes.linkDiv, "margin"].join(" ")}>
                    <Link underline="always" style={{ color: "#006CA8" }} href={url.cams} target="_blank" className={classes.link}>Visit CAMS website</Link><ArrowIcon style={{ color: "#006CA8" }} />
                  </div>
                </Grid>
                <Grid item xs={1} className={classes.centerContent}>
                  <Typography variant="h6" className={classes.blueText} >OR</Typography>
                </Grid>
                <Grid item xs={6} className={classes.flexColumn} style={{ paddingLeft: "20px" }}>
                  <Typography variant="caption" className={classes.secondaryText}>Enter your email id and click generate. </Typography>
                  <Typography variant="caption" className={classes.secondaryText} style={{ marginBottom: "4px" }}>then we will do the rest</Typography>
                  <Typography variant="caption" className={classes.secondaryText} style={{ marginBottom: "2px" }}>Enter Your Email Id</Typography>
                  <CssOutlinedInput
                    id="email"
                    name="email"
                    // autoFocus={true}
                    classes={{ input: classes.input }}
                    value={value}
                    onChange={(e) => setInputValue(e.target.value, "email")}
                  />
                  <Typography variant="caption" className={classes.secondaryText} style={{ marginTop: "2px" }}>Your PDF password is your PAN in Caps</Typography>
                  {
                    error && error.step === 2 && error.email && <Typography variant="caption" style={{ color: "#f54040", marginTop: "2px" }}>{error.message}</Typography>
                  }
                  <Button variant="contained" disabled={error && error.step === 2 && error.loading} className={classes.generateButton} onClick={onGeneratePortfolio}>{error && error.step === 2 && error.loading && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Generate</Button>
                </Grid>
                <Grid item xs={12} className={classes.firstGrid} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.pointDiv}>
        <div className={classes.pointNumber}>
          2
        </div>
        <div style={{ width: "100%" }}>
          <Typography className={[classes.primaryText, classes.pointHeading].join(" ")}>Download Statement</Typography>
          <Typography variant="caption" className={classes.secondaryText}>Download statement you received on your registered email id.</Typography>
          <Grid container>
            <Grid item xs={11} className={classes.secondGrid} />
          </Grid>
        </div>
      </div>
      <div className={classes.pointDiv}>
        <div className={classes.pointNumber}>
          3
        </div>
        <div style={{ width: "100%" }}>
          <Typography className={[classes.primaryText, classes.pointHeading].join(" ")}>Upload/Email Statement</Typography>
          <Grid container>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={5} className={classes.flexColumn}>
                  <Typography variant="caption" className={classes.secondaryText}>Upload your statement, enter</Typography>
                  <Typography variant="caption" className={classes.secondaryText}>password and submit</Typography>
                  <div className={classes.linkDiv} onClick={() => handleStep(2, 4)}>
                    <Link className={classes.link}>Upload PDF</Link><ArrowIcon style={{ color: "#f54040" }} />
                  </div>
                </Grid>
                <Grid item xs={1} className={classes.centerContent}>
                  <Typography variant="h6" className={classes.blueText} >OR</Typography>
                </Grid>
                <Grid item xs={6} className={classes.flexColumn} style={{ paddingleft: "20px" }}>
                  <Typography variant="caption" className={classes.secondaryText}>Email to <Link to="#" href="mailto:mfcentral@tradebulls.in">mfcentral@tradebulls.in</Link> and we</Typography>
                  <Typography variant="caption" className={classes.secondaryText}>will update your portfolio within few minutes.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button size="small" variant="outlined" onClick={onBackClick} className={classes.backButton}>Back</Button>
      </div>
    </div>
  )
}

function Step3(props) {
  const { classes, onCancel } = props

  return (
    <div className={classes.centerContent} style={{ height: "100%" }}>
      <SvgComponent iconname={"GeneratePdf"} className={classes.image1} />
      <div className={classes.lastTextDiv}>
        <Typography align="center" className={classes.primaryText}>You will receive an email with</Typography>
        <Typography align="center" className={classes.primaryText}>consolidated account statement(*CAS)</Typography>
        <Typography align="center" className={classes.primaryText}>PDF from  <span><Link to="#" href="mailto:donotreply@camsoline.com">donotreply@camsoline.com</Link></span> shortly</Typography>
      </div>
      <Button variant="contained" color={"secondary"} style={{ margin: 0 }} className={classes.startButton} onClick={onCancel}>OK</Button>
    </div>
  )
}

function Step4(props) {
  const { classes, value, pdf, error, onBackClick, readPdf, uploadPdf, setInputValue, pdfDelete, password } = props
  const upload = React.useRef(null);
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={classes.centerContent} style={{ height: "100%" }}>
      <SvgComponent iconname={"ImportPortfolio"} className={classes.image} />
      <Typography variant="h4" style={{ forntWeight: 600, paddingTop: 0 }} className={[classes.blueText, classes.textPadding].join(" ")}>Upload CAMS Statement</Typography>
      <div className={[classes.uploadWrapper, classes.step4InputWidth].join(" ")} onClick={() => upload.current.click()} style={{ display: 'flex' }}>
        <input ref={upload} disabled={pdf && pdf === "Loading"} accept={".pdf"} type={"file"} style={{ display: "none" }} onChange={(e) => readPdf(e)} />
        <div className={classes.wrapperInnerDiv}>
          <SvgComponent iconname={"Pdf"} style={{ marginRight: "6px" }} />
          <Typography align="center" variant="h6" style={{ fontWeight: 600 }} className={classes.primaryText}>Upload PDF{pdf && pdf === "Loading" && <CircularProgress size={16} color="inherit" style={{ marginLeft: "50px" }} />}</Typography>
        </div>
      </div>
      <Typography variant="caption" align="center" className={classes.secondaryText} style={{ marginTop: "2px" }}>PDF only less than 5Mb</Typography>
      {
        error && error.step === 4 && error.pdf ?
          <div className={classes.centerContent}>
            <Typography variant="caption" style={{ color: "#f54040", marginTop: "2px" }}>{error.message}</Typography>
          </div>
          :
          pdf && pdf.name ?
            <div className={classes.pdfTextDiv}>
              <div style={{ display: "flex" }}>
                <Tooltip title={pdf.name}><Typography variant="h6" style={{ color: "#06A706" }}>{pdf.name.length > 50 ? `${pdf.name.slice(0, 50)}...` : pdf.name}</Typography></Tooltip>
                <Typography variant="h6" style={{ color: "#06A706" }}>{` (${Number(pdf.size / 1000000).toFixed(2)}Mb)`}</Typography>
              </div>
              <Typography variant="variant">{" is uploaded "}<Link onClick={pdfDelete} underline="always" style={{ color: "#006CA8" }} className={classes.link}>Delete</Link></Typography>
            </div>
            :
            <></>
      }
      <div className={[classes.passwordDiv, classes.step4InputWidth].join(" ")}>
        <Typography align="center" variant="h6" className={classes.secondaryText} style={{ marginBottom: "2px" }}>Enter password to open PDF</Typography>
        <CssOutlinedInput
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          // autoFocus={true}
          classes={{ input: classes.input }}
          value={value}
          onChange={(e) => setInputValue(e.target.value, "password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                disabled={password === ""}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      {error && error.step === 4 && error.password && <Typography align="center" variant="caption" style={{ color: "#f54040", marginBottom: "6px" }}>{error.message}</Typography>}
      <Button variant="contained" disabled={error && error.step === 4 && error.loading} onClick={uploadPdf} color={"secondary"} style={{ marginBottom: "12px", marginTop: 0 }} className={[classes.startButton, classes.step4InputWidth].join(" ")}>{error && error.step === 4 && error.loading && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Submit</Button>
      <Typography variant="h6" style={{ color: "#f54040" }}>OR</Typography>
      <Typography variant="caption" className={classes.secondaryText}>Email to <Link to="#" href="mailto:mfcentral@tradebulls.in">mfcentral@tradebulls.in</Link> and we will</Typography>
      <Typography variant="caption" className={classes.secondaryText}>update your portfolio witnin few minutes.</Typography>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button size="small" variant="outlined" onClick={onBackClick} className={classes.backButton}>Back</Button>
      </div>
    </div>
  )
}

function Step5(props) {
  const { classes, onCancel } = props

  return (
    <div className={classes.centerContent} style={{ height: "100%" }}>
      <SvgComponent iconname={"UpdatePortfolio"} className={classes.image1} />
      <div className={classes.lastTextDiv}>
        <Typography align="center" className={classes.primaryText}>We will update your portfolio soon.</Typography>
        <Typography align="center" className={classes.primaryText}>You will receive a notification</Typography>
      </div>
      <Button variant="contained" color={"secondary"} style={{ margin: 0 }} className={classes.startButton} onClick={onCancel}>OK</Button>
    </div>
  )
}

function ImportPortfolio(props) {
  const { classes, onCancel } = props
  const [step, setStep] = useState({ previousStep: 1, activeStep: 1 })
  const [error, setError] = useState({})
  const [email, setEmail] = useState('')
  const [pdf, setPdf] = useState({})
  const [password, setPassword] = useState('')

  function handleStep(previous, current) {
    setStep({ previousStep: previous, activeStep: current })
  }

  function onBackClick() {
    setError({})
    if (pdf && Object.keys(pdf).length > 0) {
      setPdf({})
      setPassword("")
    }
    if (email) {
      setEmail('')
    }
    setStep({ previousStep: step.previousStep > 1 ? step.previousStep - 1 : 1, activeStep: step.previousStep })
  }

  function setInputValue(value, type) {
    if (type === "email") {
      setEmail(value)
    } else {
      if (value && value.length > 25) {
        setError({ step: 4, password: true, message: "Password should be less than 25 characters" })
      } else {
        if (error && Object.keys(error).length > 0) {
          setError({})
        }
        setPassword(value)
      }
    }
  }

  function validateEmail(value) {
    if (value === "") {
      setError({ step: 2, email: true, message: "Please enter email id" })
      return true
    } else if (value && (!value.includes("@") || !value.includes(".") || !(/^[a-zA-Z0-9]+([-_\.]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})+$/).test(value))) {
      setError({ step: 2, email: true, message: "Please enter valid email id" })
      /* setTimeout(() => {
        setError({})
      }, 5000) */
      return true
    } else {
      return false
    }
  }

  function onGeneratePortfolio() {
    if (!validateEmail(email)) {
      setError({ step: 2, loading: true })
      axios.post(`${process.env.REACT_APP_API_URL}${url.generate}`, { emailID: email }).then(response => {
        if (response.data.success) {
          handleStep(2, 3)
          setError({})
          setEmail("")
        } else {
          setError({ step: 2, email: true, message: response.data.error })
        }
      }).catch(error => {
        setError({ step: 2, email: true, message: "There is some technical issue, please try again later" })
      })
    }
  }

  function validatePdf() {
    if (password === "") {
      setError({ step: 4, password: true, message: "Please enter password" })
      return true
    } else if (pdf && !pdf.base64) {
      setError({ step: 4, pdf: true, message: "Please select pdf" })
      return true
    } else {
      return false
    }
  }

  function uploadPdf() {
    if (!validatePdf()) {
      setError({ step: 4, loading: true })
      let base64 = (pdf.base64 && pdf.base64.split(",").length === 2 && pdf.base64.split(",")[1]) || ""
      let payload = {
        fileName: pdf.name || "",
        fileFormat: pdf.format || "",
        base64Format: base64 || "",
        password
      }
      axios.post(`${process.env.REACT_APP_API_URL}${url.upload}`, payload).then(response => {
        if (response.data.success) {
          handleStep(4, 5)
          setError({})
          setPdf({})
        } else {
          setError({ step: 4, password: true, message: response.data.error })
        }
      }).catch(error => {
        setError({ step: 4, password: true, message: "There is some technical issue, please try again later" })
      })
    }
  }

  function readPdf(input) {
    setPdf("Loading")
    let file = {
      content: input.target.files[0],
      name: input.target.files[0].name.split(".")[0],
      format: input.target.files[0].name.split(".")[1],
      size: input.target.files[0].size
    }
    if (file.size <= 5000000) {
      let reader = new FileReader();
      reader.readAsDataURL(file.content);
      reader.onload = function (readBytes) {
        setPdf({ base64: readBytes.currentTarget.result, name: file.name, size: file.size, format: file.format })
      };
    } else {
      setError({ step: 4, pdf: true, message: "Please upload pdf less than 5Mb" })
      setPdf("")
    }
  }

  function getStepContent(step) {
    switch (step) {
      case 1:
        return <Step1
          classes={classes}
          handleStep={handleStep}
        />
      case 2:
        return <Step2
          step={step}
          error={error}
          value={email}
          classes={classes}
          handleStep={handleStep}
          onBackClick={onBackClick}
          setInputValue={setInputValue}
          onGeneratePortfolio={onGeneratePortfolio}
        />
      case 3:
        return <Step3
          classes={classes}
          onCancel={onCancel}
        />
      case 4:
        return <Step4
          pdf={pdf}
          step={step}
          error={error}
          value={password}
          classes={classes}
          readPdf={readPdf}
          password={password}
          pdfDelete={() => setPdf({})}
          uploadPdf={uploadPdf}
          onBackClick={onBackClick}
          setInputValue={setInputValue}
        />
      case 5:
        return <Step5
          classes={classes}
          onCancel={onCancel}
        />
      default:
        return <></>

    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={step.activeStep} children={<></>} style={{ display: "none" }} />
      {getStepContent(step.activeStep)}
    </div>
  )
}

export default withStyles(styles)(ImportPortfolio)
