export default theme => ({
  mainDiv: {
    margin: "8px 0px 0px 0px",
    width: "550px",
    [theme.breakpoints.down('lg')]: {
      width: "450px",
    },
    [theme.breakpoints.down('md')]: {
      width: "420px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "350px",
    }
  },
  root: {
    margin: "0px",
    borderRadius: "6px",
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    padding: "24px",
    [theme.breakpoints.down('lg')]: {
      padding: "20px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "16px",
    },
    [theme.breakpoints.down('sm')]: {
      padding: "12px",
    },
    "&.client": {
      padding: "24px 24px 12px 24px",
      [theme.breakpoints.down('lg')]: {
        padding: "20px 20px 8px 20px",
      },
      [theme.breakpoints.down('md')]: {
        padding: "16px 16px 4px 16px",
      },
      [theme.breakpoints.down('sm')]: {
        padding: "12px 12px 2px 12px",
      },
    }
  },
  scripDiv: {
    width: "100%",
    paddingBottom: "20px",
    borderBottom: `1px dashed ${theme.palette.color10.default}`,
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "16px"
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: "12px"
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "10px"
    }

  },
  highlight: {
    color: theme.palette.color12.default,
    whiteSpace: "initial"
  },
  cancel: {
    width: "100%",
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.color5.default}`,
    textTransform: "none",
    color: theme.palette.color15.default,
    // boxShadow: "0px 3px 5px 0px #006ca863",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    '&:hover': {
      backgroundColor: "#c5c6c826"
    }
  },
  order: {
    width: "100%",
    textTransform: "none",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  valueDiv: {
    paddingBottom: "16px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "12px"
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: "10px"
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "8px"
    }
  },
  firstGrid: {
    margin: "24px 0px 0px 0px",
    paddingBottom: "12px",
    [theme.breakpoints.down('lg')]: {
      margin: "16px 0px 0px 0px"
    },
    [theme.breakpoints.down('md')]: {
      margin: "14px 0px 0px 0px"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "12px 0px 0px 0px"
    }
  },
  secondGrid: {
    borderTop: `1px dashed ${theme.palette.color10.default}`,
    padding: "12px 0px 0px 0px",
    display: "flex"
  },
  firstDiv: {
    width: "100%",
    marginRight: "12px",
    borderRight: `1px dashed ${theme.palette.color15.default}`,
    [theme.breakpoints.down('lg')]: {
      marginRight: "10px"
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: "8px"
    }
  },
  secondDiv: {
    width: "100%",
    marginLeft: "12px",
    [theme.breakpoints.down('lg')]: {
      marginLeft: "10px"
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: "8px"
    }
  }
})