export default ({ palette }) => ({
  styleOverrides: {
    root: {
      backgroundColor: palette.background.default,
      '&:hover': {
        backgroundColor: palette.background.secondary
      },
      '&$focused': {
        backgroundColor: palette.background.secondary
      }
    }
  }
});
