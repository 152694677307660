export default (theme) => ({
  heading: {
    paddingBottom: '20px',
    color: theme.palette.common.blue,
    fontSize: '24px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
      marginTop: '4px',
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginTop: '0px',
      paddingBottom: '8px'
    }
  },
  closeIcon: {
    float: 'right',
    height: '25px',
    marginRight: theme.spacing(1),
    padding: 0
  },
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: '0px',
    height: '25px',
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  content: {
    backgroundColor: theme.palette.background.default,
    marginTop: '25px',
    minHeight: '7vh',
    minWidth: '20vw'
  },
  listItem: {
    paddingBottom: '16px'
  },
  text: {
    color: theme.palette.color15.default,
    marginTop: '5px'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: '#497CAC',
    fontWeight: 600,
    lineHeight: '24px'
  },
  pText: {
    fontSize: '14px',
    lineHeight: '20px',
    paddingBottom: '20px',
    letterSpacing: '0.2px',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      lineHeight: '18px',
      paddingBottom: '16px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '16px',
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '12px',
      paddingBottom: '12px'
    }
  },
  textDiv: {
    borderTop: '2px solid #66788a4d',
    borderBottom: '2px solid #66788a4d',
    padding: '4px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px'
    }
  },
  innerTextDiv: {
    padding: '12px 0px',
    height: '350px',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 0px',
      height: '300px'
    },
    [theme.breakpoints.down('md')]: {
      height: '280px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px'
    }
  },
  paper: {
    padding: '6px',
    margin: '8px 0px',
    backgroundColor: theme.palette.background.default,
    // boxShadow: `0px 0px 12px 0px ${theme.palette.color14.primary}`,
    // border: `1px solid ${theme.palette.color14.primary}`,
    borderRadius: '4px',
    width: '50vw',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 0px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px',
      width: '60vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '70vw'
    },
    '&.message': {
      width: '25vw',
      [theme.breakpoints.down('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '50vw'
      }
    }
  },
  checkboxRoot: {
    color: theme.palette.text.primary,
    marginRight: 0
  },
  button: {
    // background: "#ffffff",
    fontWeight: 600,
    textTransform: 'none',
    border: `1px solid ${theme.palette.color14.primary}`,
    color: '#006CA8',
    fontSize: '16px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '2px 2px 2px 12px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#c5c6c826'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      marginTop: '12px'
    }
  },
  bottomDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: '4px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    }
  },
  termsText: {
    fontSize: '16px',
    marginLeft: '-16px',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  submitButton: {
    textTransform: 'none',
    fontSize: '16px',
    padding: '6px 48px',
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      padding: '4px 40px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      padding: '4px 32px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      padding: '4px 28px'
    }
  },
  questionDiv: {
    display: 'flex',
    paddingBottom: '28px',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '16px'
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '8px'
    }
  },
  question: {
    fontSize: '14px',
    color: theme.palette.common.blue,
    paddingBottom: '6px',
    fontWeight: 600,
    lineHeight: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      lineHeight: '18px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '16px',
      paddingBottom: '4px'
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '12px'
    }
  },
  answer: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      lineHeight: '18px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '12px'
    }
  },
  questionNumber: {
    height: '28px',
    padding: '6px',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    background: '#66788a4d',
    borderRadius: '4px',
    justifyContent: 'center',
    fontSize: '14px',
    marginRight: '20px',
    color: theme.palette.common.blue,
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      height: '24px',
      padding: '4px',
      fontSize: '12px',
      marginRight: '16px'
    },
    [theme.breakpoints.down('md')]: {
      height: '20px',
      padding: '4px',
      marginRight: '4px',
      fontSize: '10px',
      marginRight: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '16px',
      fontSize: '8px'
    }
  },
  linkButton: {
    padding: '4px 16px',
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    }
  },
  textPadding: {
    padding: '20px 0px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '18px',
    [theme.breakpoints.down('lg')]: {
      padding: '16px',
      fontSize: '12px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 0px'
    }
  },
  textPadding1: {
    textAlign: 'center',
    padding: '50px 10px',
    [theme.breakpoints.down('md')]: {
      padding: '40px 10px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '36px 10px'
    }
  }
});
