import { Stepper } from "@mui/material";
import { useFormik } from "formik";
import { addMoneyNew } from "helpers/formRedirect";
import axios from "helpers/interceptor";
import { UserProfileContext } from "Main";
import React, { useContext, useEffect, useState } from "react";
import { CommonActions } from "redux/Common";
import * as Yup from "yup";
import { getMandateData } from "./OrderForm";

const orderType = {
  SIP: {
    label: "SIP",
    place: "/ms-mf-sip/mfsip/placeXSipOrder",
    details: "/ms-mf-sip/mfsip/sipdetails/new"
  },
  OT: {
    label: "One Time",
    place: "/ms-mf-lumpsumorder/lumpsum/place/order",
    modify: "/ms-mf-lumpsumorder/lumpsum/modify",
    details: "/ms-mf-lumpsumorder/lumpsum/orderFormDetails"
  },
  REDEEM: {
    label: "Redeem",
    place: "/ms-mf-redeemorder/redeem/place/new",
    accountDetails: "/ms-mf-redeemorder/redeem/getAccountDetails"
  },
  SWITCH: {
    label: "Switch",
    place: "/ms-mf-switch/mfswitch/switchOrder"
  }
}

function MfOrderStates(Component) {
  return (props) => <HOC {...props} Component={Component} />
}

const HOC = (props => {
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState({ show: true, error: "Loading..." })
  const [error, setError] = useState({ error: false, message: "" })
  const [formData, setFormData] = useState({})

  const userProfile = useContext(UserProfileContext)

  const { Component, reduxState, onCancel, openAddMandateDialog } = props

  function handleBankMandate() {
    onCancel();
    openAddMandateDialog()
  }

  function handleAddMoneyClick() {
    addMoneyNew(true)
    // onAddMoneyClick(clientCode, true)
  }

  Yup.addMethod(Yup.number, "isNotDecimal", function (args) {
    const message = args;
    return this.test("isNotDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        if (String(options.originalValue).includes(".")) {
          return createError({ path: path, message: "Decimal is not allowed" })
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "isDecimal", function (args) {
    const message = args;
    return this.test("isDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        var splitted = String(options.originalValue).split(".");
        if (splitted.length === 2) {
          if (splitted[1].length === 0) {
            return createError({ path: path, message: "Value is expected after decimal" })
          } else if (splitted[1].length > 4) {
            return createError({ path: path, message: "Maximum four decimal places are allowed" })
          } else {
            return true
          }
        } else if (splitted.length === 1) {
          return true;
        }
      }
      return createError({ path: path, message: message })
    })
  })

  Yup.addMethod(Yup.number, "checkInvestmentAmount", function (args) {
    const message = args;
    return this.test("checkInvestmentAmount", message, function (value) {
      const { path, createError } = this;
      if (formData && formData[this.parent.type === "SIP" ? this.parent.frequency : 0]) {
        if (value < formData[this.parent.type === "SIP" ? this.parent.frequency : 0][this.parent.type === "OT" ? formData && formData[0].folio && formData[0].folio.length > 0 && values.folioNumber !== "new" ? "additionalPurchaseAmountMultiple" : "minPurchaseAmount" : "minPurchaseAmount"]) {
          return createError({ path: path, message: `Investment amount should be greater than ${formData[this.parent.type === "SIP" ? this.parent.frequency : 0][this.parent.type === "OT" ? formData && formData[0].folio && formData[0].folio.length > 0 && values.folioNumber !== "new" ? "additionalPurchaseAmountMultiple" : "minPurchaseAmount" : "minPurchaseAmount"]}` })
        }/*  else if (value > formData[this.parent.type === "SIP" ? this.parent.frequency : 0].maximumPurchaseAmount) {
          return createError({ path: path, message: `Investment amount should be less than ${formData[this.parent.type === "SIP" ? this.parent.frequency : 0].maximumPurchaseAmount}` })
        }  */else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "checkInstallments", function (args) {
    const message = args;
    return this.test("checkInstallments", message, function (value) {
      const { path, createError } = this;
      if (formData && formData[this.parent.frequency]) {
        if (value < formData[this.parent.frequency].minimumInstallmentNumbers) {
          return createError({ path: path, message: `No. of installments should be greater than ${formData[this.parent.frequency].minimumInstallmentNumbers}` })
        } else if (value > formData[this.parent.frequency].maximumInstallmentNumbers) {
          return createError({ path: path, message: `No. of installments should be less than ${formData[this.parent.frequency].maximumInstallmentNumbers}` })
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "checkRedeemUnits", function (args) {
    const message = args;
    return this.test("checkRedeemUnits", message, function (value) {
      const { path, createError } = this;
      if (reduxState && reduxState.scheme) {
        if (value < /* reduxState.scheme.minimumRedeemUnits */ 0) {
          return createError({ path: path, message: `Redeem units should be greater than ${/* reduxState.scheme.minimumRedeemUnits */ 0}` })
        } else if (value > reduxState.scheme.maximumRedeemUnits) {
          return createError({ path: path, message: `Redeem units  should be less than ${reduxState.scheme.maximumRedeemUnits}` })
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "checkSwitchUnits", function (args) {
    const message = args;
    return this.test("checkSwitchUnits", message, function (value) {
      const { path, createError } = this;
      if (reduxState && reduxState.scheme) {
        if (value < reduxState.scheme.minimumSwitchUnits) {
          return createError({ path: path, message: `Switch units should be greater than ${reduxState.scheme.minimumSwitchUnits}` })
        } else if (value > reduxState.scheme.maximumSwitchUnits) {
          return createError({ path: path, message: `Switch units should be less than ${reduxState.scheme.maximumSwitchUnits}` })
        } else {
          return true
        }
      }
      return true
    })
  })

  // Yup.addMethod(Yup.string, "checkBankMandate", function (args) {
  //   const message = args;
  //   return this.test("checkBankMandate", message, function (value) {
  //     const { path, createError } = this;
  //     if (this.parent.type === "SIP" && !this.parent.newMandate && formData && formData[this.parent.frequency] && formData[this.parent.frequency].mandate && formData[this.parent.frequency].mandate.length > 0) {
  //       if (value === undefined || value === "") {
  //         return createError({ path: path, message: "Bank Mandate is required" })
  //       } else {
  //         return true
  //       }
  //     }
  //     return true
  //   })
  // })

  Yup.addMethod(Yup.string, "checkBankMandate", function (args) {
    const message = args;
    return this.test("checkBankMandate", message, function (value) {
      const { path, createError } = this;
      if (this.parent.type === "OT" && formData && formData[0]?.mandate?.length > 0) {
        if (getMandateData(this.parent, formData).length > 0) {
          if (value === undefined || value === "") {
            return createError({ path: path, message: "Bank Mandate is required" })
          } else {
            return true
          }
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.string, "checkAccountDetails", function (args) {
    const message = args;
    return this.test("checkAccountDetails", message, function (value) {
      const { path, createError } = this;
      if (this.parent.type === "SIP" && !this.parent.existingMandate && formData && formData.accountDetails && formData.accountDetails.length > 0) {
        if (value === undefined || value === "") {
          return createError({ path: path, message: "Account Number is required" })
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.string, "checkFolio", function (args) {
    const message = args;
    return this.test("checkFolio", message, function (value) {
      const { path, createError } = this;
      if (this.parent.type === "SIP" || this.parent.type === "OT") {
        if (formData && formData[this.parent.type === "SIP" ? this.parent.frequency : 0] && formData[this.parent.type === "SIP" ? this.parent.frequency : 0].folio && formData[this.parent.type === "SIP" ? this.parent.frequency : 0].folio.length > 0) {
          if (value === undefined || value === "") {
            return createError({ path: path, message: "Please select folio no" })
          } else {
            return true
          }
        }
        return true
      } else if (this.parent.type === "REDEEM") {
        if (reduxState.scheme && reduxState.scheme.folioNumber && reduxState.scheme.folioNumber !== "") {
          if (value === undefined || value === "") {
            return createError({ path: path, message: "Please select folio no" })
          } else {
            return true
          }
        } else {
          return true
        }
      }
      return true
    })
  })

  let schema = Yup.object().shape({
    type: Yup.mixed()
      .oneOf(["SIP", "OT", "REDEEM", "SWITCH"]),
    folioNumber: Yup.string()
      .when("type", {
        is: "SIP",
        then: Yup.string()
          .checkFolio()
      })
      .when("type", {
        is: "OT",
        then: Yup.string()
          .checkFolio()
      })
      .when("type", {
        is: "REDEEM",
        then: Yup.string()
          .checkFolio()
      })
      .when("type", {
        is: "SWITCH",
        then: Yup.string()
          .required("Please select folio no"),
      }),
    frequency: Yup.mixed()
      .when("type", {
        is: "SIP",
        then: Yup.mixed()
          .required("Frequency is required"),
      }),
    amount: Yup.number().typeError("Invalid number")
      .when("type", {
        is: "SIP",
        then: Yup.number().typeError("Invalid number")
          .required("Please enter amount")
          .isDecimal()
          .checkInvestmentAmount()
      })
      .when("type", {
        is: "OT",
        then: Yup.number().typeError("Invalid number")
          .required("Please enter Investment amount")
          .isDecimal()
          .checkInvestmentAmount()
      }),
    installments: Yup.number()
      .when("type", {
        is: "SIP",
        then: Yup.number().typeError("Invalid number")
          .required("No. of Installments is required")
          .isNotDecimal()
          .checkInstallments()
      }),
    bankMandate: Yup.string()
      .when("type", {
        is: "SIP",
        then: Yup.string()
          .required("Please select mandate")
      })
      .when("type", {
        is: "OT",
        then: Yup.string()
          // .required("Please select mandate")
          .checkBankMandate()
      }),
    cancelled: Yup.boolean(),
    terms: Yup.boolean()
      .oneOf([true], "The terms and conditions must be accepted."),
    startDate: Yup.number()
      .when("type", {
        is: "SIP",
        then: Yup.number().typeError("Invalid date")
          .required("Start date is required")
      }),
    subType: Yup.mixed()
      .when("type", {
        is: "REDEEM",
        then: Yup.mixed()
          .oneOf(["units", "all"])
          .required("Redemption by is required"),
      })
      .when("type", {
        is: "SWITCH",
        then: Yup.mixed()
          .oneOf(["units", "all"])
          .required("Switch by is required"),
      }),
    units: Yup.number().typeError("Invalid number"),
    redeemAmount: Yup.number()
      .when("type", {
        is: "REDEEM",
        then: Yup.number().typeError("Invalid number")
          .required("Redemption Units is required")
          .isDecimal()
          .checkRedeemUnits()
      }),
    switchAmount: Yup.number()
      .when("type", {
        is: "SWITCH",
        then: Yup.number().typeError("Invalid number")
          .required("Switch Units is required")
          .isDecimal()
          .checkSwitchUnits()
      }),
    switchTo: Yup.string()
      .when("type", {
        is: "SWITCH",
        then: Yup.string()
          .required("Please select Switch to scheme")
      }),
    firstOrderToday: Yup.boolean()
      .oneOf([true, false]),
    accountDetails: Yup.string()
      .when("type", {
        is: "SIP",
        then: Yup.string()
          .required("Please select account number")
      })
      .when("type", {
        is: "OT",
        then: Yup.string()
          .required("Please select account number")
      })
      .when("type", {
        is: "REDEEM",
        then: Yup.string()
          .required("Please select account number")
      }),
    paymentMode: Yup.mixed()
      .oneOf(["NETBANKING", "UPI", "MANDATE"])
      .when('type', {
        is: 'OT',
        then: Yup.string()
          .required("Please select payment mode")
      })
      .when('type', {
        is: 'SIP',
        then: Yup.string()
          .when("firstOrderToday", {
            is: true,
            then: Yup.string()
              .required("Please select payment mode")
          })
      }),
    upiId: Yup.string()
      .when("paymentMode", {
        is: "UPI",
        then: Yup.string()
          .required("Please enter UPI Id")
      }),
    // existingMandate: Yup.boolean()
    //   .when("type", {
    //     is: "SIP",
    //     then: Yup.boolean()
    //       .when("newMandate", {
    //         is: false,
    //         then: Yup.boolean()
    //           .oneOf([true], "Field required"),
    //       })
    //   }),
    // newMandate: Yup.boolean()
    //   .when("type", {
    //     is: "SIP",
    //     then: Yup.boolean()
    //       .when("existingMandate", {
    //         is: false,
    //         then: Yup.boolean()
    //           .oneOf([true], "Field required"),
    //       })
    //   })
  })

  const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
    initialValues: {
      type: (reduxState && reduxState.form && reduxState.form.type) || "SIP",
      folioNumber: "",
      amount: 0,
      frequency: "",
      startDate: "",
      installments: 1,
      cancelled: false,
      bankMandate: "",
      terms: false,
      units: 1,
      redeemAmount: 0,
      switchAmount: 0,
      subType: "all",
      valueTotalUnits: 0,
      valueRedemptionUnit: 1,
      switchTo: "",
      settlementDate: "",
      accountDetails: "",
      // existingMandate: false,
      // newMandate: false,
      firstOrderToday: (reduxState && reduxState.scheme && reduxState.scheme.product) ? false : true,
      paymentMode: "",
      upiId: ""
    },
    validationSchema: schema,
    onSubmit: values => {
      setError({ error: false, message: "" })
      setActiveStep(activeStep + 1)
    }
  })

  function getMandate(data, accountNumber, type) {
    if (data?.mandate) {
      let mandate = {};
      if (type === "SIP" && values.firstOrderToday) {
        mandate = data?.mandate?.length > 0 && accountNumber && data.mandate.find(ele => ele.bankAaccountNumber == accountNumber);
        return mandate?.mandateCode || "new"
      } else {
        mandate = data?.mandate?.length > 0 && data.mandate.find(ele => (ele.bankAaccountNumber == accountNumber && ele.status == true));
        return mandate?.mandateCode || ""
      }
    }
  }

  function onFrequencyChange(value, data) {
    setFieldValue("frequency", value)
    if (data[value].folio && data[value].folio.length > 0) {
      setFieldValue("folioNumber", data[value].folio[0])
    } else {
      setFieldValue("folioNumber", "")
    }
    if (data[value][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"] && data[value][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"].length > 0) {
      setFieldValue("startDate", data[value][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"][0])
    } else {
      setFieldValue("startDate", "")
    }
    setFieldValue("installments", data[value].minimumInstallmentNumbers || 0)
    setFieldValue("amount", data[value].minPurchaseAmount || 0)
  }

  function onAccountChange(value, accountData, data, type) {
    let account = (accountData.length > 0 && accountData.find(ele => ele.accountNumber == value)) || {};
    setFieldValue("accountDetails", value)
    setFieldValue("ifscCode", account.ifscCode || '')
    setFieldValue("bankID", account.netID || '')
    setFieldValue("bankUpiID", account.upiID || '')
    setFieldValue("bankPaymentMode", account.paymentMode || '')
    let bankMandate = getMandate(data, account.accountNumber, type)
    /* type === "SIP" &&  */data && setFieldValue("bankMandate", bankMandate)
    if (!bankMandate) {
      setFieldValue('paymentMode', '')
    }
  }

  function lumpsumOrderDetails(load) {
    load && setLoading({ loadFormData: true, show: false, error: "" })
    load && setError({ error: false, message: "" })
    let payload = {
      schemeCode: reduxState.scheme.schemeCode
    }
    axios.post(`${process.env.REACT_APP_API_URL}${orderType.OT.details}`, payload).then(response => {
      if (response.data.success && response.data.success.length > 0) {
        let obj = { 0: response.data.success[0] }
        setFormData({ ...obj })
        if (obj[0].folio && obj[0].folio.length > 0) {
          setFieldValue("folioNumber", obj[0].folio[0])
          setFieldValue("amount", obj[0].additionalPurchaseAmountMultiple || 0)
        } else {
          setFieldValue("folioNumber", "")
          setFieldValue("amount", obj[0].minPurchaseAmount || 0)
        }
        setFieldValue("settlementDate", obj[0].settlementDate || "")
        onAccountChange((obj[0].accountDetails && obj[0].accountDetails[0] && obj[0].accountDetails[0].accountNumber) || '', obj[0].accountDetails, obj[0], "OT")
        setLoading({ show: false, error: "" })
        setFieldValue("paymentMode", "")
      } else {
        // if (load) {
        setLoading({ loadFormData: true, show: false, error: response.data.error || "Scheme data not found" })
        /*   } else {
            setLoading({ show: true, error: response.data.error || "Scheme data not found" })
          } */
      }
    }).catch(error => {
      // setError({ error: true, message: "There is some technical issue, please try again later" })
      setLoading({ loadFormData: true, show: false, error: error.message || "There is some technical issue, please try again later" })
    })
  }

  function sipOrderDetails(load) {
    load && setLoading({ loadFormData: true, show: false, error: "" })
    load && setError({ error: false, message: "" })
    let payload = {
      schemeCode: reduxState.scheme.schemeCode,
      isProductOrder: (reduxState && reduxState.scheme && reduxState.scheme.product) ? true : false
    }
    axios.post(`${process.env.REACT_APP_API_URL}${orderType.SIP.details}`, payload).then(response => {
      if (response.data.success?.data?.length > 0) {
        let obj = {};
        response.data.success.data.map((ele) => {
          obj[ele.frequency] = ele
        })
        obj['accountDetails'] = response.data.success.accountDetails || []
        obj['mandate'] = response.data.success.mandate || []
        setFormData({ ...obj })
        onFrequencyChange(Object.keys(obj)[0], obj)
        setFieldValue("installments", obj[Object.keys(obj)[0]].maximumInstallmentNumbers)
        onAccountChange(obj.accountDetails[0]?.accountNumber || '', obj.accountDetails, obj, "SIP")
        setLoading({ show: false, error: "" })
      } else {
        // if (load) {
        setLoading({ loadFormData: true, show: false, error: response.data.error || "Scheme data not found" })
        /*  } else {
           setLoading({ show: true, error: response.data.error || "Scheme data not found" })
         } */
      }
    }).catch(error => {
      // setError({ error: true, message: "There is some technical issue, please try again later" })
      setLoading({ loadFormData: true, show: false, error: error.message || "There is some technical issue, please try again later" })
    })
  }

  function getAccountDetails(load) {
    load && setLoading({ loadFormData: true, show: false, error: "" })
    load && setError({ error: false, message: "" })
    let payload = {
      clientCode: reduxState.scheme.clientCode,
    }
    axios.post(`${process.env.REACT_APP_API_URL}${orderType.REDEEM.accountDetails}`, payload).then(response => {
      if (response.data.success?.length > 0) {
        setFormData(response.data.success)
        onAccountChange(response.data.success[0]?.accountNumber || '', response.data.success[0] || {}, null, "OT")
        setLoading({ show: false, error: "" })
      } else {
        // if (load) {
        setLoading({ loadFormData: true, show: false, error: response.data.error || "Account details data not found" })
        /*  } else {
           setLoading({ show: true, error: response.data.error || "Scheme data not found" })
         } */
      }
    }).catch(error => {
      // setError({ error: true, message: "There is some technical issue, please try again later" })
      setLoading({ loadFormData: true, show: false, error: error.message || "There is some technical issue, please try again later" })
    })
  }

  useEffect(() => {
    if (formData && formData[values.frequency])
      if (values.cancelled) {
        setFieldValue("installments", formData[values.frequency].maximumInstallmentNumbers)
      } else {
        setFieldValue("installments", formData[values.frequency].maximumInstallmentNumbers > 240 ? 240 : formData[values.frequency].maximumInstallmentNumbers)
      }
  }, [values.cancelled])

  /* useEffect(() => {
    if (values.newMandate && values.existingMandate) {
      setFieldValue("existingMandate", false)
    }
  }, [values.newMandate])

  useEffect(() => {
    if (values.existingMandate && values.newMandate) {
      setFieldValue("newMandate", false)
    }
  }, [values.existingMandate]) */

  useEffect(() => {
    if (values.frequency) {
      setFieldValue("startDate", formData && formData[values.frequency] &&
        formData[values.frequency][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"].length > 0 &&
        formData[values.frequency][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"][0])
    }
    Object.keys(formData).length > 0 && setFieldValue("bankMandate", getMandate(formData, values.accountDetails, values.type))
  }, [values.firstOrderToday])

  useEffect(() => {
    if (values.type === "OT") {
      setFieldValue("amount", formData && formData[0] && formData[0][formData[0].folio.length > 0 && values.folioNumber !== "new" ? "additionalPurchaseAmountMultiple" : "minPurchaseAmount"])
    }
  }, [values.folioNumber])

  function initializeForm() {
    if (reduxState && reduxState.scheme) {
      setFieldValue("folioNumber", reduxState.scheme.folioNumber || "")
      setFieldValue("units", reduxState.scheme.units || "0.0000")
      setFieldValue("valueTotalUnits", reduxState.scheme.totalUnits || "0.0000")
      setFieldValue("valueRedemptionUnit", reduxState.scheme.value || "0.0000")
      setFieldValue(reduxState.form.type === "REDEEM" ? "redeemAmount" : "switchAmount", reduxState.scheme.units || "0.0000")
      setFieldValue("settlementDate", reduxState.scheme.settlementDate || "")
    }
  }

  useEffect(() => {
    if (reduxState && reduxState.form) {
      if (reduxState.form.status === "New") {
        if (reduxState.form.type === "SIP") {
          sipOrderDetails()
        } else if (reduxState.form.type === "OT") {
          lumpsumOrderDetails()
        } else if (reduxState.form.type === "REDEEM") {
          getAccountDetails()
          initializeForm()
        } else if (reduxState.form.type === "SWITCH") {
          initializeForm()
          setLoading({ show: false, error: "" })
        } else {
          setLoading({ show: false, error: "" })
        }
      }
    }
  }, [])

  function onConfirmPopupCLose(type) {
    props.closeConfirmDialog();
    if (type === "SIP") {
      CommonActions.setRefreshReport("registeredsip")
    } else {
      CommonActions.setRefreshReport("orderreportmf")
    }
  }

  const pushOrder = () => {
    setError({ submitting: true, error: false, message: "" })
    let payload = {}
    if (values.type === "SIP") {
      payload = {
        schemeCode: (reduxState && reduxState.scheme && reduxState.scheme.schemeCode) || "",
        amount: values.amount,
        startDate: values.startDate,
        frequency: values.frequency,
        isChecked: values.cancelled,
        installmentNo: values.installments,
        folio: (values.folioNumber === "new" || values.folioNumber == "") ? "" : values.folioNumber || "",
        goalName: (reduxState && reduxState.scheme && reduxState.scheme.goalName) || "",
        firstOrderToday: values.firstOrderToday,
        accountNumber: values.accountDetails,
        ifscCode: values.ifscCode || '',
        bankID: values.paymentMode === "UPI" ? values.bankUpiID : values.bankID || '',
        bankMandate: values.bankMandate || "",
        ...(values.firstOrderToday ? {
          paymentMode: (values.paymentMode === "UPI" || values.paymentMode === "MANDATE") ? values.paymentMode : values.bankPaymentMode || '',
          vpaID: (values.paymentMode === "UPI" && values.upiId) || "",
          logOutURL: window.location.href,
        } : {})
      }
    } else if (values.type === "OT") {
      payload = {
        schemeCode: (reduxState && reduxState.scheme && reduxState.scheme.schemeCode) || "",
        orderAmount: values.amount,
        folioNo: values.folioNumber,
        settlementDate: values.settlementDate,
        accountNumber: values.accountDetails || '',
        ifscCode: values.ifscCode || '',
        paymentMode: (values.paymentMode === "UPI" || values.paymentMode === "MANDATE") ? values.paymentMode : values.bankPaymentMode || '',
        bankID: values.bankID || '',
        logOutURL: window.location.href,
        vpaID: (values.paymentMode === "UPI" && values.upiId) || "",
        ...(values.paymentMode === "MANDATE" ? { bankMandate: values.bankMandate } : {}),
      }
    } else if (values.type === "REDEEM") {
      payload = {
        schemeCode: (reduxState && reduxState.scheme && reduxState.scheme.schemeCode) || "",
        redemptionType: values.subType,
        settlementDate: values.settlementDate,
        accountNumber: values.accountDetails || ''
      }
      if (reduxState && reduxState.form && reduxState.form.isDematHolding) {
        payload["isDematHolding"] = reduxState.form.isDematHolding
      }
      if (values.subType === "units") {
        payload["redemptionUnit"] = values.redeemAmount
      }/*  else if (values.subType === "amount") {
        payload["redemptionAmount"] = values.redeemAmount
      } */
    } else if (values.type === "SWITCH") {
      payload = {
        switchInSchemeCode: values.switchTo || "",
        switchOutSchemeCode: (reduxState && reduxState.scheme && reduxState.scheme.schemeCode) || "",
        switchType: values.subType,
        units: values.switchAmount,
        folio: values.folioNumber,
        settlementDate: values.settlementDate
      }
    }
    if (values.type !== "SWITCH") {
      payload["goalId"] = (reduxState && reduxState.scheme && reduxState.scheme.goalId) || ""
      payload["product"] = (reduxState && reduxState.scheme && reduxState.scheme.product) || ""
    }
    axios.post(`${process.env.REACT_APP_API_URL}${orderType[values.type].place}`, payload).then(resp => {
      if (resp.data.success) {
        onCancel()
        if (values.paymentMode === "NETBANKING" && (values.type === "OT" || (values.type === "SIP" && values.firstOrderToday))) {
          //const wnd = window.open("about:blank", "_self");
          // wnd.document.write(resp.data.success);
          var opened = window.open("about:blank", "_self");
          opened.document.open();
          opened.document.write(resp.data.success);
          opened.document.close();
        } else {
          let obj = {
            content: `${resp.data.success.id && resp.data.success.id !== "" ? (`${resp.data.success.msg || "Your order has been placed successfully"}. Order Id is ${resp.data.success.id}.${values.paymentMode === "UPI" ? " You will receive a payment collection request on your UPI app. Please complete the payment to process your order" : ""}` || "Order has been placed successfully") : (`${resp.data.success.msg || "Order has been placed successfully"}.${values.paymentMode === "UPI" ? " You will receive a payment collection request on your UPI app. Please complete the payment to process your order" : ""}`)}`,
            //  resp.data.success.id && resp.data.success.id !== "" ? resp.data.success.msg || "Your Order has been placed successfully" + ". Order Id is " + resp.data.success.id + "." + "You will receive a payment collection request on your UPI app. Please complete the payment to process your order" : resp.data.success.msg || "Order has been placed successfully",
            hideCloseButton: true,
            okButtonText: "Ok",
            centerActions: true
          }
          if ((window.location.href).split("/").includes("portfolio")) {
            obj = {
              ...obj,
              onClose: props.closeConfirmDialog,
              reportsButton: {
                show: true,
                onClick: () => window.top.location.href = `${process.env.REACT_APP_TRADING_URL}mf`,
                buttonText: "Go To Reports"
              }
            }
          } else {
            obj = {
              ...obj,
              onClose: () => onConfirmPopupCLose(values.type)
            }
          }
          /* if (values.type === "SIP" && formData && formData[values.frequency] && formData[values.frequency].mandate && formData[values.frequency].mandate.length === 0) {
            obj = {
              ...obj,
              showBelowData: true,
              belowData: {
                heading: "Your easy pay activation pending. It autopays your SIP installments every month and hence is a mandatory one time set up.",
                buttonText: "Add Bank Mandate",
                buttonClick: handleBankMandate
              }
            }
          } */
          setError({ submitting: false, error: false, message: "" })
          props.openConfirmDialog(obj)
        }
      } else {
        setActiveStep(activeStep - 1)
        setError({ error: true, message: resp.data.error })
      }
    }).catch(error => {
      setActiveStep(activeStep - 1)
      setError({ error: true, message: "There is some technical issue, please try again later" })
    })
  }

  const setState = (action) => {
    switch (action) {
      case "OT":
        setFieldValue("type", "OT")
        setFieldTouched("amount", false)
        setFieldTouched("folioNumber", false)
        // setFieldValue("firstOrderToday", false)
        lumpsumOrderDetails(true)
        break;
      case "SIP":
        setFieldValue("type", "SIP")
        setFieldTouched("amount", false)
        setFieldTouched("folioNumber", false)
        sipOrderDetails(true)
        break;
      case "SWITCH":
        setFieldValue("type", "SWITCH")
        setFieldValue("subType", "all")
        setFieldValue("switchAmount", reduxState && reduxState.scheme && reduxState.scheme.units)
        setFieldTouched("switchAmount", false)
        setError({ error: false, message: "" })
        break;
      case "REDEEM":
        setFieldValue("type", "REDEEM")
        setFieldValue("subType", "all")
        setFieldValue("switchTo", "")
        setFieldValue("redeemAmount", reduxState && reduxState.scheme && reduxState.scheme.units)
        setFieldTouched("redeemAmount", false)
        getAccountDetails(true)
        break;
      /*  case "amount":
         setFieldValue("subType", action)
         setFieldTouched("redeemAmount", false)
         setFieldValue("redeemAmount",
           values.type === "REDEEM" ?
             reduxState && reduxState.scheme && reduxState.scheme.minimumRedeemAmount || 100 :
             reduxState && reduxState.scheme && reduxState.scheme.minimumSwitchAmount || 100)
         break; */
      case "units":
        setFieldValue("subType", action)
        setFieldTouched("redeemAmount", false)
        setFieldValue("redeemAmount",
          values.type === "REDEEM" ?
            reduxState && reduxState.scheme && reduxState.scheme.minimumRedeemUnits :
            reduxState && reduxState.scheme && reduxState.scheme.minimumSwitchUnits)
        break;
      case "all":
        setFieldValue("subType", action)
        setFieldTouched("redeemAmount", false)
        setFieldValue("redeemAmount",
          values.type === "REDEEM" ?
            reduxState && reduxState.scheme && reduxState.scheme.maximumRedeemUnits :
            reduxState && reduxState.scheme && reduxState.scheme.maximumSwitchUnits)
        break;
      default:
        break;
    }
  }

  return <>
    <Stepper activeStep={activeStep} children={<></>} style={{ display: "none" }} />
    <Component
      activeStep={activeStep}
      handleBack={() => setActiveStep(activeStep - 1)}
      pushOrder={pushOrder}
      handlePlace={() => setActiveStep(activeStep + 1)}
      handleSubmit={handleSubmit}
      setFieldTouched={setFieldTouched}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      setOrderType={setState}
      values={values}
      errors={errors}
      touched={touched}
      reduxState={reduxState}
      errorObj={error}
      loading={loading}
      formData={formData || {}}
      onFrequencyChange={onFrequencyChange}
      handleBankMandate={handleBankMandate}
      handleAddMoneyClick={handleAddMoneyClick}
      onAccountChange={onAccountChange}
    />
  </>
})

export default MfOrderStates
