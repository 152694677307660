import { OutlinedInput, Select, Switch, Checkbox } from "@mui/material";
import { withStyles } from "@mui/styles";

export const CssOutlinedInput = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px"
    },
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$error $notchedOutline": {
      borderWidth: 1,
      borderColor: "#f54040"
    }
  },
  error: {},
  input: {},
  focused: {},
  notchedOutline: {
    borderColor: "#66788a4d"
  }
}))(OutlinedInput)

export const CssSelect = withStyles(theme => ({
  root: {
    // padding: "8px 0px 8px 7px",
    [theme.breakpoints.down('lg')]: {
      padding: "4px 0px 4px 6px"
    },
    [theme.breakpoints.down('md')]: {
      padding: "3px 0px 3px 6px"
    }
    /*  "&:hover $notchedOutline": {
       borderWidth: 2,
       borderColor: "#fff"
     },
     "&$focused $notchedOutline": {
       borderWidth: 2,
       borderColor: "#fff"
     },
     "&$error $notchedOutline": {
       borderWidth: 2,
       borderColor: "#fff"
     } */
  },
  select: {
    paddingRight: "0px !important",
    padding: "8px 0px 8px 7px",
    borderBottom: "none !important",
    [theme.breakpoints.down('xl')]: {
      padding: "4px 0px 4px 6px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "4px 0px 4px 6px"
    },
    [theme.breakpoints.down('md')]: {
      padding: "3px 0px 3px 6px"
    }
  },
  outlined: {}
}))(Select)

export const SelectOutlinedInput = withStyles(theme => ({
  root: {
    fontSize: "14px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px"
    },
    "& $notchedOutline": {
      borderWidth: 0
    },
    "&:hover $notchedOutline": {
      borderWidth: 0
    },
    "&$focused $notchedOutline": {
      borderWidth: 0
    }
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {}
}))(OutlinedInput)

export const CssSwitch = withStyles((theme) => ({
  switchBase: {
    '&$checked': {
      //   transform: 'translateX(16px)',
      color: '#006CA8 !important',
      '& + $track': {
        backgroundColor: '#006CA8 !important',
        opacity: "0.3 !important",
      },
    },
    '&$focusVisible $thumb': {
      backgroundColor: "#006CA8"
    },
  },
  thumb: {},
  track: {
    backgroundColor: "#80807a !important",
    opacity: "1 !important",
    borderRadius: 10
  },
  checked: {},
  focusVisible: {},
}))(Switch)

export const CssCheckbox = withStyles({
  root: {
    color: "#497CAC",
    '&$checked': {
      color: "#497CAC",
    },
  },
  checked: {},
})(Checkbox)