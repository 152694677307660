export default (theme) => ({
  root: {
    width: 650,
    [theme.breakpoints.down('lg')]: {
      width: 550
    },
    [theme.breakpoints.down('md')]: {
      width: 450
    }
  },
  modifyForm: {
    width: 500,
    [theme.breakpoints.down('lg')]: {
      width: 400
    },
    [theme.breakpoints.down('sm')]: {
      width: 350
    }
  },
  form: {
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    marginTop: 12,
    padding: '16px 24px',
    [theme.breakpoints.down('lg')]: {
      marginTop: 8,
      padding: '12px 20px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '12px 16px'
    }
  },
  fieldText: {
    marginBottom: theme.spacing(0.25),
    color: theme.palette.color15.default,
    fontSize: '12px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px'
    }
  },
  errorText: {
    color: '#f54040',
    marginTop: '2px'
  },
  input: {
    padding: '8px',
    // background: "#EBEEF5",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: '6px'
    },
    '&:disabled': {
      background: '#c5c6c8',
      color: '#808080'
    }
  },
  containedBlue: {
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    }
  },
  order: {
    width: '100%',
    textTransform: 'none',
    color: '#fff',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2.5),
    }
  },
  cancel: {
    width: '100%',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.color5.default}`,
    textTransform: 'none',
    color: theme.palette.color15.default,
    // boxShadow: "0px 3px 5px 0px #006ca863",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    '&:hover': {
      backgroundColor: '#c5c6c826'
    },
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2.5),
    }
  },
  searchList: {
    padding: '0px',
    zIndex: 999,
    maxHeight: '200px',
    overflowY: 'auto',
    marginRight: '24px',
    position: 'absolute',
    background: theme.palette.background.WLselectedItem,
    boxShadow: `0px 1px 6px -1px ${theme.palette.background.WLselectedItem}`,
    '&.compact': {
      maxHeight: 145
    }
  },
  item: {
    // padding: "0px",
    paddingRight: '8px',
    whiteSpace: 'unset',
    border: `1px solid ${theme.palette.color10.default}`,
    overflow: 'inherit',
    backgroundColor: theme.palette.background.primary
  },
  itemText: {
    color: theme.palette.text.primary
  },
  itemSubText: {
    fontSize: 11,
    [theme.breakpoints.down('md')]: {
      fontSize: 10
    }
  },
  top: {
    padding: '0px 0px 6px 0px'
  },
  listContainer: {
    justifyContent: 'flex-end'
  },
  searchGrid: {
    paddingTop: '20px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '12px',
    }
  },
  errorDiv: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '1px solid #f5c6cb',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '4px',
    textAlign: 'center',
    marginBottom: '4px',
    [theme.breakpoints.down('lg')]: {
      padding: '3px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px'
    }
  },
  fieldDiv: {
    width: '100%'
  },
  loadingDiv: {
    height: 250
  }
});
