import React from 'react';
import { Divider, IconButton, Paper, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { DialogActions } from 'redux/Dialog';
import { MODAL_CHILD } from 'widgets/FormDialog';
import styles from './styles';
import { addMoneyNew } from 'helpers/formRedirect';
import { setCookieNew } from 'helpers/cookies';

const OrderType = {
  "Limit": "LIMIT",
  "Market": "MKT",
  "StopLoss": "SL"
}

export function modifyDate(date) {
  if (date) {
    let dt = new Date()
    dt = (dt.getDate() <= 9 ? "0" + dt.getDate() : dt.getDate()) + "-" + (dt.getMonth() <= 9 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) + "-" + dt.getFullYear()
    return dt
  }
}

export function status(data, status) {
  let orderStatus = data.orderStatus;
  let marketStatus = status[data.shortCode];
  if (orderStatus === 'AMO') {
    if (marketStatus.isAfterHourMarket || marketStatus.isLiveMarket) {
      return false
    }
    return true
  } else {
    if (orderStatus === 'PartiallyExecuted' || orderStatus === 'Pending' || orderStatus === 'Triggered' || orderStatus === 'Freeze' || orderStatus === 'AMO') {
      return false
    }
    return true
  }
}

export function repeatOrderStatus(data) {
  let orderStatus = data?.orderStatus;
  if (orderStatus === 'Rejected') {
    return false
  }
  return true
}

export const modifyTableData = (action, row, repeat) => {
  if (row && Object.keys(row).length > 0) {
    let obj = {
      status: action,
      ...(repeat ? { type: "squareOff" } : {}),
      ...row
    }
    obj.orderType = OrderType[obj.orderType || "Limit"]
    obj.orderQty = (/* bracketOrder ||  */repeat) ? row.orderQty : row.remQTY
    obj.orderPrice = Number(row.orderPrice)
    obj.trigPrice = Number(row.trigPrice)
    obj.valDate = row.valDate || null,
    obj.approxMargin = row.productType === "MTF"
    // if (row.orderStatus === "PartiallyExecuted") {
    //   obj.orderQty = Number(row.orderQty) - Number (row.execQty)
    // }
    return obj
  }
}

function ActionsRow(props) {
  const { classes, profile, marketStatus, rowData: data, bracketOrder, openDialog, closeDialog } = props;
  let funds = data?.remark.includes("Insuffcient funds") ? true : false

  const openOrderPopup = () => {
    DialogActions.openOrderForm({ form: modifyTableData("Modify", data || {}) });
  };

  const openCancelPopup = () => {
    DialogActions.dialogOpen(MODAL_CHILD.CANCEL_ORDER, { form: data || {} });
  }

  const openRepeatOrderPopup = () => {
    let scripData = { form: modifyTableData("New", data || {}, /* bracketOrder,  */true) }
    if (funds) {
      setCookieNew("tbEqSingleOrder", btoa(JSON.stringify(scripData)))
      addMoneyNew()
    } else {
      DialogActions.openOrderForm(scripData);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.hoverSection}>
        <Tooltip title="Modify" aria-label="modify">
          <span>
            <IconButton
              onClick={openOrderPopup}
              disabled={status(data || {}, marketStatus)}
              className={classes.button}>
              Modify
            </IconButton>
          </span>
        </Tooltip>
        <Divider
          orientation="vertical"
          style={{ backgroundColor: 'transparent' }}
        />
        <Tooltip title="Cancel" aria-label="cancel">
          <span>
            <IconButton
              onClick={openCancelPopup}
              disabled={status(data || {}, marketStatus)}
              className={classes.button}>
              Cancel
            </IconButton>
          </span>
        </Tooltip>
        {
          /* !bracketOrder &&  */<Tooltip title={funds ? 'Add funds & Repeat Order' : 'Repeat Order'}>
            <span>
              <IconButton
                onClick={openRepeatOrderPopup}
                disabled={repeatOrderStatus(data)}
                style={{ width: 162 }}
                className={classes.button}>
                {funds ? 'Add funds & Repeat Order' : 'Repeat Order'}
              </IconButton>
            </span>
          </Tooltip>
        }

      </Paper>
    </div>
  );
}

export default withStyles(styles)(ActionsRow)