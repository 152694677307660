import { withStyles } from '@mui/styles';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import commonStyles from 'common/styles';
import combineStyles from 'common/styles/combineStyles';
import Placeholder from 'components/Placeholder';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FilterSelection from './FilterSelection';

const styles = (theme) => ({
    splitContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1
    },
    filterSel: {
        flex: 1
    },
    divider: {
        backgroundColor: 'transparent',
        width: 5
        // height: 1,
    },
    list: {
        height: '100%'
    },
    subList: {
        maxHeight: '25vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.secondary,
    },
    listHeader: {
        borderBottom: `1px solid ${theme.palette.background.secondary}`,
        // paddingLeft: 24,
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.background.primary}`,
        // paddingLeft: 24,
    },
    listSubHeader: {
        borderBottom: `1px solid ${theme.palette.background.primary}`,
        paddingLeft: 24,
    },
    listSubItem: {
        backgroundColor: theme.palette.background.primary,
        borderBottom: `1px solid ${theme.palette.background.secondary}`,
        paddingLeft: 24,
    },
    error: {
        margin: '0 10px',
        fontSize: 14,
        color: theme.palette.danger.main
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 5
    },
    buttonHeight: {
        height: 30
    }
});

function FilterPanel(props) {
    const {
        fetchingFilters,
        filterSelection,
        filterResults,
        propFilterResults,
        classes,
        searchString,
        onFilterChange,
        splitView,
        // searchCriteria: filterSelection,
        setTopFunds: setPropTopFunds,
    } = props;

    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState();
    const [topFunds, setTopFundsState] = useState(!filterSelection?.topFunds ? true : filterSelection.topFunds);
    const [openMenu, setOpenMenu] = useState('category');
    const [openMenuCat, setOpenMenuCat] = useState('Equity');
    // const [selection, setSelection] = useState(filterSelection);
    const [selection, setSelection] = useState(filterSelection || { category: ['EQUITY'] });
    const [amcs, setAmcs] = useState([]);
    const [selectedAmc, setSelectedAmc] = useState([]);
    const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    const [returns, setReturns] = useState([]);
    const [ages, setAges] = useState([]);
    const [risks, setRisks] = useState([]);
    const [fundSizes, setFundSizes] = useState([]);
    const [ratings, setRatings] = useState([]);

    // useEffect(() => {
    //     getFilters();
    // }, [searchString]);

    useEffect(() => {
        if (filterResults) readFilters(filterResults);
    }, [filterResults]);

    useEffect(() => {
        if (propFilterResults) readFilters(propFilterResults);
    }, [propFilterResults]);

    // useEffect(() => {
    //     if (topFunds !== propsTopFunds) {
    //         setPropTopFunds(topFunds);
    //     }
    // }, [topFunds]);

    const setTopFunds = (value, updateParent) => {
        setTopFundsState(value);
        updateParent && setPropTopFunds && setPropTopFunds(value);
    }

    const isSelectionEmpty = () => {
        if (selection) {
            // const { amc, category } = selection;
            let hasData = false;

            for (const [key, value] of Object.entries(selection)) {
                hasData = selection[key]?.length > 0;
                if (hasData) {
                    break;
                }
            }

            return !(searchString || hasData || topFunds);
        } else if (topFunds) {
            return false;
        }
        return true;
    }

    const readFilters = (data) => {
        const {
            ageoffunds,
            amc,
            category,
            fundsize,
            options,
            ratings,
            returns,
            risk
        } = data;
        // debugger
        setAges(ageoffunds || []);
        setAmcs(amc || []);
        setCategories(category || []);
        setFundSizes(fundsize || []);
        setOptions(options || []);
        setRatings(ratings || []);
        setReturns(returns || []);
        setRisks(risk || []);
    };

    const onCheckChange = (key, value) => {
        setError();
        let newSelection = { ...selection };
        let current = newSelection[key];
        if (!current) {
            current = [];
        }
        if (current.indexOf(value) == -1) {
            current.push(value);
        } else {
            current.splice(current.indexOf(value), 1);
        }
        setSelection({ ...newSelection, [key]: [...current] });
    };

    const TopFundsCheck = () => {
        return (<ListItem
            button
            className={classes.nested}
            onClick={() => {
                setTopFunds(!topFunds);
                // onCheckChange('topFunds', !topFunds || undefined);
            }}
        >
            <ListItemIcon>
                <Checkbox checked={topFunds} />
            </ListItemIcon>
            <ListItemText primary={'Top Funds'} />
        </ListItem>);
    }

    const getAMCItem = () => {
        let title = 'AMC';
        let key = 'amc';
        let data = amcs;
        return (
            <>
                <ListItem
                    button
                    className={classes.listHeader}
                    onClick={() => {
                        setOpenMenu(openMenu != key ? key : null);
                    }}>
                    <ListItemText>{title}</ListItemText>
                    {openMenu == key ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={openMenu == key}
                    timeout="auto"
                    unmountOnExit
                    className={classes.subList}>
                    {data.length > 0 ? (
                        <List component="div" disablePadding>
                            {data.map((item) => {
                                let checked = false;
                                if (selection && selection[key]) {
                                    let index = selection[key].indexOf(item.amccode);
                                    checked = index != -1;
                                }
                                return (
                                    <ListItem
                                        button
                                        className={classes.listItem}
                                        onClick={() => onCheckChange(key, item.amccode)}>
                                        <ListItemIcon>
                                            <Checkbox checked={checked} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.amcname} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                        <ListItem>
                            <Typography color="primary">No AMCs available</Typography>
                        </ListItem>
                    )}
                </Collapse>
            </>
        );
    };
    const getCategoryItem = () => {
        let title = 'Category';
        let key = 'category';
        let data = categories;
        return (
            <>
                <ListItem
                    button
                    className={classes.listHeader}
                    onClick={() => {
                        setOpenMenu(openMenu != key ? key : null);
                    }}>
                    <ListItemText>{title}</ListItemText>
                    {openMenu == key ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={openMenu == key}
                    timeout="auto"
                    unmountOnExit
                    className={classes.subList}>
                    {data.length > 0 ? (
                        <List component="div" disablePadding>
                            {data.map((item) => {
                                // debugger
                                return Object.keys(item).map((subKey, index) => {
                                    let currentItem = item[subKey];
                                    // if (index == 0 && !openMenuCat) {
                                    //     setOpenMenuCat(subKey);
                                    // }
                                    return (
                                        <>
                                            <ListItem
                                                button
                                                className={classes.listSubHeader}
                                                onClick={() => {
                                                    setOpenMenuCat(openMenuCat != subKey ? subKey : null);
                                                }}>
                                                <ListItemText>{subKey}</ListItemText>
                                                {openMenuCat == subKey ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </ListItem>
                                            <Collapse
                                                in={openMenuCat == subKey}
                                                timeout="auto"
                                                unmountOnExit
                                            // className={classes.subList}
                                            >
                                                <List component="div" disablePadding>
                                                    {currentItem.map((item, index) => {
                                                        let checked = false;
                                                        if (selection && selection[key]) {
                                                            let index = selection[key].indexOf(item);
                                                            checked = index != -1;
                                                        }
                                                        return (
                                                            <ListItem
                                                                button
                                                                className={classes.nested}
                                                                className={classes.listSubItem}
                                                                onClick={() => onCheckChange(key, item)}>
                                                                <ListItemIcon>
                                                                    <Checkbox checked={checked} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={item} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Collapse>
                                        </>
                                    );
                                });
                            })}
                        </List>
                    ) : (
                        <ListItem>
                            <Typography color="primary">No AMCs available</Typography>
                        </ListItem>
                    )}
                </Collapse>
            </>
        );
    };
    const getMainListItem = (title, key, data) => {
        return (
            <>
                <ListItem
                    button
                    className={classes.listHeader}
                    onClick={() => {
                        setOpenMenu(openMenu != key ? key : null);
                    }}>
                    <ListItemText>{title}</ListItemText>
                    {openMenu == key ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={openMenu == key}
                    timeout="auto"
                    unmountOnExit
                    className={classes.subList}>
                    {data.length > 0 ? (
                        <List component="div" disablePadding>
                            {data.map((item) => {
                                let checked = false;
                                if (selection && selection[key]) {
                                    let index = selection[key].indexOf(item);
                                    checked = index != -1;
                                }
                                return (
                                    <ListItem
                                        button
                                        className={classes.listItem}
                                        onClick={() => onCheckChange(key, item)}>
                                        <ListItemIcon>
                                            <Checkbox checked={checked} />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                        <ListItem>
                            <Typography color="primary">No Data available</Typography>
                        </ListItem>
                    )}
                </Collapse>
            </>
        );
    };

    return (
        <>
            {fetchingFilters ? (
                // {fetching ? (
                <Placeholder loading={fetchingFilters} />
            ) : (
                // <Placeholder loading={fetching} />
                <Box className={classes.splitContainer}>
                    <Box className={classes.listContainer}>
                        <List className={classes.list}>
                            <TopFundsCheck />
                            {getCategoryItem()}
                            {getAMCItem()}
                            {getMainListItem('Returns', 'returns', returns)}
                            {getMainListItem('Age of Funds', 'age', ages)}
                            {getMainListItem('Risk', 'risk', risks)}
                            {getMainListItem('FundSize', 'fundSize', fundSizes)}
                            {getMainListItem('Ratings', 'ratings', ratings)}
                            {/* {getMainListItem('Options', 'options', options)} */}
                        </List>
                        {!splitView && (
                            <FilterSelection
                                selection={selection}
                                setSelection={setSelection}
                            />
                        )}
                        {error && (
                            <Typography className={classes.error}>{error}</Typography>
                        )}
                        {
              /* !splitView &&  */ <Box className={classes.actionButtons}>
                                <Button
                                    className={clsx(classes.outlinedBlue, classes.buttonHeight)}
                                    onClick={() => {
                                        setError();
                                        setTopFunds(undefined, true);
                                        onFilterChange && onFilterChange(null, false);
                                    }}>
                                    {'Clear'}
                                </Button>
                                <Divider className={classes.divider} />
                                <Button
                                    className={clsx(classes.containedBlue, classes.buttonHeight)}
                                    onClick={() => {
                                        if (!isSelectionEmpty()) {
                                            setTopFunds(topFunds, true);
                                            onFilterChange && onFilterChange(selection, topFunds);
                                        } else {
                                            setError('At least 1 filter is required');
                                        }
                                    }}>
                                    {'Apply'}
                                </Button>
                            </Box>
                        }
                    </Box>
                    {splitView && <Divider className={classes.divider} />}
                    {splitView && (
                        <FilterSelection
                            className={classes.filterSel}
                            topFunds={topFunds}
                            selection={selection}
                            setSelection={setSelection}
                        />
                    )}
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    const {
        watchlistMF: { scrips, selectedList },
        common: { selectedScrip, selectedScripIndex, showFilter },
        layout: { maxedLayout, layoutType },
        fundExplorer
    } = state;
    return { scrips, selectedList, showFilter, maxedLayout, ...fundExplorer };
};

export default connect(mapStateToProps)(
    withStyles(combineStyles(styles, commonStyles))(FilterPanel)
);
