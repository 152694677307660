export default theme => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.color5.default}`,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderRadius: "4px 4px 0px 0px"
  },
  tab: {
    color: theme.palette.color15.default,
    // borderBottom: `1px solid ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    }
  },
  activeTab: {
    color: "#8DB1D2 !important",
    borderBottom: `2px solid #8DB1D2`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    }
  },
  mainDiv: {
    width: "1156px",
    margin: "-8px -8px 0px -8px",
    [theme.breakpoints.down('xl')]: {
      width: "956px"
    },
    [theme.breakpoints.down('lg')]: {
      width: "750px"
    },
    [theme.breakpoints.down('md')]: {
      width: "650px"
    }
  },
  paper: {
    backgroundColor: theme.palette.background.default
  },
  box: {
    overflowY: "auto",
    width: "calc(100% + 3px)",
    height: "490px",
    [theme.breakpoints.down('xl')]: {
      height: "356px"
    },
    [theme.breakpoints.down('lg')]: {
      height: "calc(100vh - 262px)"
    },
    [theme.breakpoints.down('md')]: {
      height: "480px"
    }
  },
  topDetails: {
    height: "116px",
    // margin: "0px -10px 8px",
    margin: "8px 4px",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('xl')]: {
      height: "79px"
    },
    [theme.breakpoints.down('lg')]: {
      height: "100px"
    },
    [theme.breakpoints.down('md')]: {
      height: "90px"
    }
  }
})