import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Grid, Tooltip, Typography, CircularProgress } from "@mui/material";
import { withStyles } from '@mui/styles';
import { CssOutlinedInput } from "widgets/order/customfields";
import { Close as CloseIcon } from "@mui/icons-material";
import ResendButton from "components/ResendButton";
import axios from 'helpers/interceptor';
import SvgComponent from "helpers/svgcomponent";
import styles from "./otpstyles";
import { setCookieNew } from "helpers/cookies";
import Placeholder from "components/Placeholder";
import { Event } from "components/Event";

function OtpPopup(props) {
  const { classes, freezeTradingPopup, setFreezeTradingPopup, profile } = props;

  const otpSuccessEvent = new Event("OTP_SUCCESS");

  const [data, setData] = useState({ sendOtpError: "Loading" });
  const { otp, submit, freezeUnfreeze, error, sendOtpError } = data || {};

  useEffect(() => {
    sendOtp(true)
  }, [])


  function sendOtp(firstCall, resend) {
    axios.post(`${process.env.REACT_APP_API_URL}/ms-backoffice-client-master/backoffice/clientmaster/sendOTPforFreezeUnfreeze`, {
      isFreezeTrading: !profile?.isFreezeTrading
    }).then(resp => {
      if (resp.data.error) {
        if (firstCall) {
          setData({ ...data, sendOtpError: resp.data?.error || 'There is some technical issue, please try again later' })
        } else {
          setData({ ...data, error: resp.data?.error || 'There is some technical issue, please try again later' })
          setTimeout(() => {
            setData({ ...data, error: '' })
          }, 3000)
        }
      } else {
        setData({})
        resend && otpSuccessEvent.sendMessage();
      }
    }).catch(error => {
    })
  }

  function verifyOtp() {
    setData({ ...data, submit: true })
    axios.post(`${process.env.REACT_APP_API_URL}/ms-backoffice-client-master/backoffice/clientmaster/verifyOTPforFreezeUnfreeze`, {
      otp,
      isFreezeTrading: !profile?.isFreezeTrading
    }).then(resp => {
      if (resp.data.success) {
        setData({ ...data, submit: false, freezeUnfreeze: resp.data.success })
      } else {
        setData({ ...data, error: resp.data?.error || 'There is some technical issue, please try again later' })
        setTimeout(() => {
          setData({ ...data, error: '' })
        }, 3000)
      }
    }).catch(error => {
      setData({ ...data, error: error?.message || 'There is some technical issue, please try again later' })
    })
  }

  function handleClose() {
    setFreezeTradingPopup(!freezeTradingPopup)
  }

  function getError(value) {
    if (value) {
      if (!Number(value)) {
        return 'Please enter valid otp'
      } else if (Number(value) && value.length > 6) {
        return 'Only 6 digits allowed'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  const done = () => {
    return (
      <div className={classes.centerContent}>
        <SvgComponent iconname={"GraphicsDone"} style={{ paddingBottom: 30 }} />
        {/* <Typography variant='h2' className={classes.doneText}>Done</Typography> */}
        <Typography variant='h4' style={{ textAlign: 'center' }} className={classes.secondaryText}>{freezeUnfreeze || `Your Trading is now frozen`}</Typography>
        <Button autoFocus={true} className={classes.doneButton} variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </div>
    )
  }

  const form = () => {
    return (
      <div>
        {error && <div className={classes.errorDiv}><Typography className={classes.errorDivText}>{error || '--'}</Typography></div>}
        <Typography variant='h2' className={[classes.blueText, classes.heading].join(' ')}>OTP Verification</Typography>
        <div className={classes.containerGrid}>
          <Typography variant='h5' className={classes.secondaryText}>Enter the OTP sent to your registered mobile number</Typography>
          {/* <Typography variant='h4' className={classes.email}>{profile?.maskEmailId || ''}</Typography> */}
          <Typography variant='h4' className={classes.primaryText}>{profile?.maskMobileNumber || ''}</Typography>
          <div className={classes.inputDiv}>
            <div>
              <CssOutlinedInput
                id="otp"
                name="otp"
                // disabled={submit}
                autoFocus={true}
                className={classes.otpInputField}
                classes={{ input: classes.input }}
                value={otp}
                error={otp ? (otp.length > 6 || !Number(otp)) : false}
                onChange={(e) => setData({ ...data, otp: e.target.value })}
              />
              {otp ? <Typography className={classes.error}>{getError(otp)}</Typography> : <></>}
            </div>
            <Button
              className={classes.button}
              onClick={() => verifyOtp({ otp })}
              disabled={
                submit ||
                !Number(otp) || (otp && ((otp.length < 6 || otp.length > 6)
                  || (String(otp).trim().length < 6 || String(otp).trim().length > 6)))}
              variant="contained">
              {submit && <CircularProgress size={20} style={{ marginRight: "12px", color: '#999999' }} />}
              Verify
            </Button>
          </div>
          <div>
            <Typography variant='h5' className={classes.otpText}>Didn't receive OTP ? <span className={classes.resend}>
              <ResendButton onClick={() => sendOtp(false, true)} resendLimit={1000} timeOut={59} />
            </span>
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      open={freezeTradingPopup}
      maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography className={classes.title}>{"Freeze/Unfreeze Trading"}</Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <Tooltip title="Close" aria-label="close">
              <IconButton aria-label="close" onClick={handleClose} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {
          sendOtpError ?
            <Placeholder
              loading={sendOtpError === "Loading" ? true : false}
              loadingMsg={''}
              error={sendOtpError === "Loading" ? '' : sendOtpError}
            />
            :
            freezeUnfreeze ? done() : form()
        }
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(OtpPopup)