import React, { useRef } from "react"
import { withStyles } from "@mui/styles"
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import { InfoOutlined as InfoIcon, KeyboardArrowDown as Arrow } from "@mui/icons-material";
import { Grid, Button, FormControl, Typography, Tooltip, CircularProgress, MenuItem, IconButton, FormControlLabel } from '@mui/material'
import styles from './styles'
import ClientSearchBox from "components/Search/ClientSearchBox";
import { CssOutlinedInput, CssSelect, SelectOutlinedInput, CssSwitch } from "./customfields";
import RefreshIcon from '@mui/icons-material/Refresh';

const USER = `${process.env.REACT_APP_USER}`

const tabs = [
  {
    label: "Limit",
    value: "LIMIT",
    disabled: [""]
    //   disabled: ["SL"]
  },
  {
    label: "Market",
    value: "MKT",
    disabled: [""]
    //   disabled: ["LIMIT", "SL"]
  },
  {
    label: "Stop Loss",
    value: "SL",
    disabled: [""]
    //   disabled: ["LIMIT", "MKT"]
  }
]

function OrderForm(props) {

  const { reduxForm, setFieldTouched, setFieldValue, values, errors, touched, handleChange, setSelectClient, classes, showClientError, selectedClient, selectClient, setClientError, setInfoPopup, initializeSelectClient, scrip, validityDateData, submitting, marketStatus, setHelpPopup, approxMarginData, handleRefreshApproxMargin, showBrokerageCharges, setShowBrokerageCharges } = props

  const { exchange, lot, price, triggerPrice, quantity, disclosedQuantity, product, validTill, orderType, validityDate, approxMargin } = values
  const { type, status, buySell, netQty, productType, orderStatus } = reduxForm
  const { isTBApproved, segment, isMTFApproved, optionType } = scrip

  const deliveryFocus = useRef(null);

  function handleTypeDisabled() {
    if (exchange === 'BSE' && segment === "FNO" && reduxForm.orderType !== 'SL') {
      return true
    } else if (((exchange === "BSE" && segment === "EQ") || (segment === "COMM")) && (reduxForm.orderType === "LIMIT" || reduxForm.orderType === "MKT")) {
      return true
    } else {
      return false
    }
  }

  function handleProductFocus(item) {
    if (product && item && item.ClientCode) {
      deliveryFocus.current.focus();
    }
  }

  function setProductType() {
    setFieldValue("product", "Intraday")
    if (segment === "COMM" && (validTill === "GTC" || validTill === "GTD")) {
      setFieldValue("validTill", "GFD")
    }
  }

  function handleBrokerageCharges() {
    if (USER !== "client" && Object.keys(selectClient).length === 0) {
      setInfoPopup({ show: true, message: "Please select client." })
    } else {
      setShowBrokerageCharges(true)
    }
  }

  return (
    <>
      <fieldset className={classes.fieldSet}>
        <legend className={classes.legend}>
          <Button
            onClick={() => { props.setOrderType("B") }}
            disabled={(type === "squareOff" && netQty && buySell === "S") ||
              type === "sell" ||
              (status === "Modify" && (buySell === "S" || buySell === "SS"))}
            variant="contained"
            // focusVisible
            color={values.type === "B" ? "primary" : "inherit"}
            className={[classes.buyButton, values.type === "B" ? "active" : ""].join(" ")}
          >
            Buy
          </Button>
          <Button
            onClick={() => { props.setOrderType("S") }}
            disabled={(type === "squareOff" && netQty && buySell === "B") ||
              (status === "Modify" && (buySell === "B" || buySell === "SS"))}
            variant="contained"
            // focusVisible
            color={values.type === "S" ? "secondary" : "inherit"}
            className={[classes.sellButton, values.type === "S" ? "active" : ""].join(" ")}
            style={USER != "client" && segment == "EQ" && type !== "sell" && type !== "squareOff" ? { borderRadius: "0px", boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" } : {}}
          >
            Sell
          </Button>
          {
            USER != "client" && segment == "EQ" && type !== "sell" && type !== "squareOff" ?
              <Button
                onClick={() => { props.setOrderType("SS") }}
                disabled={
                  //     (type === "squareOff" && buySell === "B") ||
                  (status === "Modify" && (buySell === "B" || buySell === "S"))}
                variant="contained"
                // focusVisible="true"
                color={values.type === "SS" ? "secondary" : "inherit"}
                // style={{ padding: "6px", width: "105px" }}
                className={[classes.sellButton, values.type === "SS" ? "active" : "", classes.shortSell].join(" ")}
              >
                Short Sell
              </Button>
              :
              <></>
          }
        </legend>
        <Grid container className={classes.mainGrid}>
          <Grid item xs={12}>
            <Grid container>
              {tabs.map((ele, i) => (
                <Button
                  key={i}
                  onClick={() => {
                    if (ele.value === "SL" && validTill === "IOC" && segment === "COMM" && scrip?.exchange === "MCX") {
                      setInfoPopup({ show: true, message: "Stop Loss not allowed for IOC order." })
                    } else {
                      props.setOrderType(ele.value)
                    }
                  }}
                  autoFocus={status === "New" && i == 0 ? true : false}
                  className={[classes.buttonTab, orderType === ele.value ?
                    (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                    classes.defaultButton, i === 0 ? classes.tabRight : i === tabs.length - 1 ?
                      classes.tabLeft : classes.tabLeft, classes.tabRight].join(" ")}
                  // disabled={i === 2 && status === "Modify" && ((exchange === "BSE" && segment === "EQ") || (segment === "COMM")) && (reduxForm.orderType && reduxForm.orderType === "LIMIT" || reduxForm.orderType === "MKT")}
                  // disabled={i === 2 ? (status === "Modify" && handleTypeDisabled())/*  || bracketOrder */ : false/* status === "Modify" && secondLeg && reduxForm.orderType !== ele.value */}
                  disabled={i === 2 && status === "Modify" && handleTypeDisabled(ele)}
                >
                  {ele.label || ""}
                </Button>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {
                (segment === "CURR" || segment === "FNO" || segment === "COMM") ?
                  <>
                    <Grid item xs={5}>
                      <FormControl variant="outlined" className={classes.fieldDiv}>
                        <Typography className={classes.fieldText}>Lot</Typography>
                        <CssOutlinedInput
                          id="lot"
                          name="lot"
                          autoFocus={status === "Modify" ? true : false}
                          classes={{ input: classes.input }}
                          onChange={handleChange}
                          error={touched.lot && errors.lot ? true : false}
                          value={lot}
                          onKeyUp={() => setFieldTouched("lot", true)}
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                        {segment === "CURR" && <Typography variant="caption" className={classes.belowText}>1 Lot = 1000 Qty </Typography>}
                        {errors.lot && touched.lot && (<p className={classes.errorText}>{errors.lot}</p>)}
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} />
                  </>
                  : <></>
              }
              {segment !== "CURR" && <Grid item xs={5}>
                <FormControl variant="outlined" className={classes.fieldDiv}>
                  <Typography className={classes.fieldText}>Quantity</Typography>
                  <CssOutlinedInput
                    id="quantity"
                    name="quantity"
                    classes={{ input: classes.input }}
                    autoFocus={status === "Modify" && (segment !== "CURR" && segment !== "FNO" && segment != "COMM") ? true : false}
                    onChange={handleChange}
                    error={touched.quantity && errors.quantity ? true : false}
                    value={quantity}
                    // disabled={(segment === "CURR" || segment === "FNO")}
                    onKeyUp={() => setFieldTouched("quantity", true)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                  {errors.quantity && touched.quantity && (<p className={classes.errorText}>{errors.quantity}</p>)}
                </FormControl>
              </Grid>}
              {
                ((segment === "CURR" || segment === "FNO" || segment === "COMM")) ?
                  <></>
                  :
                  <Grid item xs={1} />
              }
              <Grid item xs={5}>
                <FormControl variant="outlined" className={classes.fieldDiv}>
                  <Typography className={classes.fieldText}>Price</Typography>
                  <CssOutlinedInput
                    id="price"
                    name="price"
                    onChange={handleChange}
                    disabled={orderType === "MKT"}
                    error={touched.price && errors.price ? true : false}
                    value={price}
                    classes={{ input: classes.input }}
                    onKeyUp={() => setFieldTouched("price", true)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                  {
                    <div className={classes.approxGrid} style={{ margin: 0 }}>
                      <Typography variant="caption" className={classes.belowText}>Approx. Margin: {approxMarginData.margin || 0} {segment === "EQ" && `(${approxMarginData.multiple} x)`} </Typography>
                      {
                        (optionType && (optionType !== "XX" && values.type === "B")) || segment === "EQ" ?
                          <Tooltip title={'Refresh Approx Margin'}>
                            <IconButton
                              aria-label={'refresh'}
                              style={{ padding: '4px 0px 0px 4px' }}
                              onClick={handleRefreshApproxMargin}
                            >
                              <RefreshIcon className={classes.refreshIcon} />
                            </IconButton>
                          </Tooltip>
                          :
                          <></>
                      }
                    </div>
                  }
                  {errors.price && touched.price && (<p className={classes.errorText}>{errors.price}</p>)}
                </FormControl>
              </Grid>
              {
                ((/* segment === "CURR" || */ segment === "FNO" || segment === "COMM")) ?
                  <>
                    <Grid item xs={1} />
                  </>
                  :
                  <>
                    <Grid item xs={5}>
                      <FormControl variant="outlined" className={classes.fieldDiv}>
                        <Typography className={classes.fieldText}>Disclosed Quantity</Typography>
                        <CssOutlinedInput
                          id="disclosedQuantity"
                          name="disclosedQuantity"
                          classes={{ input: classes.input }}
                          onChange={handleChange}
                          error={touched.disclosedQuantity && errors.disclosedQuantity ? true : false}
                          value={disclosedQuantity}
                          onKeyUp={() => setFieldTouched("disclosedQuantity", true)}
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        />
                        {errors.disclosedQuantity && touched.disclosedQuantity && (<p className={classes.errorText}>{errors.disclosedQuantity}</p>)}
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} />
                  </>
              }
              <Grid item xs={5}>
                <FormControl variant="outlined" className={classes.fieldDiv}>
                  <Typography className={classes.fieldText}>Trigger Price</Typography>
                  <CssOutlinedInput
                    id="triggerPrice"
                    name="triggerPrice"
                    disabled={orderType === "LIMIT" || orderType === "MKT"/*  ||
                                            (status === "Modify" && orderType == "SL" && orderStatus == "Triggered") */}
                    onChange={handleChange}
                    error={touched.triggerPrice && errors.triggerPrice ? true : false}
                    value={triggerPrice}
                    classes={{ input: classes.input }}
                    onKeyUp={() => setFieldTouched("triggerPrice", true)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                  {errors.triggerPrice && touched.triggerPrice && (<p className={classes.errorText}>{errors.triggerPrice}</p>)}
                </FormControl>
              </Grid>
              {
                USER !== "client" ?
                  <>
                    <Grid item xs={(/* segment === "CURR" || */ segment === "COMM") ? 5 : 11}>
                      <FormControl variant="outlined" className={classes.fieldDiv} style={{ width: "100%" }}>
                        <Typography className={classes.fieldText}>Client Code</Typography>
                        <ClientSearchBox
                          className={[classes.searchBox, showClientError ? "error" : ""].join(" ")}
                          setClient={false}
                          placeholder={Object.keys(selectClient).length > 0 ? selectClient.ClientCode !== "" ? selectClient.ClientName !== "" ? `${selectClient.ClientName} - ${selectClient.ClientCode}` : selectClient.ClientCode : "" : ""}
                          disabled={status === "Modify" || type === "sell" || type === "squareOff" || selectedClient !== null}
                          onSelect={(item) => { setSelectClient(item); setClientError(false); handleProductFocus(item) }}
                          noAutoFocus={true}
                          clientChange={() => initializeSelectClient()}
                        />
                        {showClientError && (<p className={classes.errorText}>{"Client Code is required"}</p>)}
                      </FormControl>
                    </Grid>
                  </>
                  :
                  <></>
              }
              {
                (/* segment === "CURR" || */ segment === "COMM") &&
                <>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <FormControl variant="outlined" className={classes.fieldDiv}>
                      <Typography className={classes.fieldText}>Disclosed Quantity</Typography>
                      <CssOutlinedInput
                        id="disclosedQuantity"
                        name="disclosedQuantity"
                        classes={{ input: classes.input }}
                        onChange={handleChange}
                        error={touched.disclosedQuantity && errors.disclosedQuantity ? true : false}
                        value={disclosedQuantity}
                        onKeyUp={() => setFieldTouched("disclosedQuantity", true)}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      />
                      {errors.disclosedQuantity && touched.disclosedQuantity && (<p className={classes.errorText}>{errors.disclosedQuantity}</p>)}
                    </FormControl>
                  </Grid>
                </>
              }
            </Grid>
            <Grid container>
              <Grid item xs={11} className={classes.approxDiv}>
                {/* <Typography className={classes.approxValue}>
                  <span style={{ color: "#A4A4A4" }}>Approximate Value. </span>
                  Rs. {(orderType == "MKT" ? Object.keys(scrip).length > 0 && scrip.ltp && Number(`${Number(scrip.ltp) * Number(quantity)}`).toFixed(segment == "CURR" ? 4 : 2) : Number(Number(price) * Number(quantity)).toFixed(segment == "CURR" ? 4 : 2)) || 0.00}
                </Typography> */}
              </Grid>
              <Grid item lg={11} xs={11}>
                <Grid container className={classes.bottomGrid}>
                  <Grid item xs={segment == "COMM" ? 12 : 6}>
                    <Grid container>
                      <Grid item xs={12} className={classes.productBorder} style={segment === "COMM" ? { borderRight: 0 } : {}}>
                        <Typography className={classes.text}>Product :
                        </Typography>
                        <Grid container>
                          <Button
                            ref={deliveryFocus}
                            onClick={() => setFieldValue("product", "Delivery")}
                            className={[classes.buttonTab, classes.tabRight, product === "Delivery" ?
                              (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                              classes.defaultButton].join(" ")}
                            disabled={(status === "Modify" || (type === "squareOff" && netQty)) && productType !== "Delivery"}
                          >
                            Delivery
                          </Button>
                          <Button
                            onClick={(!isTBApproved || isTBApproved == "false") ? () => setInfoPopup({ show: true, message: "This scrip is not allowed to trade in intraday." }) : () => setProductType()}
                            className={[classes.buttonTab, classes.tabLeft, classes.tabRight, product === "Intraday" ?
                              (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                              classes.defaultButton].join(" ")}
                            disabled={(status === "Modify" || (type === "squareOff" && netQty)) && productType !== "Intraday" || type === "SS"}
                          >
                            Intraday
                          </Button>
                          {scrip.segment === "EQ" && <Tooltip title="Margin Traded Fund">
                            <Button
                              onClick={!isMTFApproved ? () => setInfoPopup({ show: true, message: "This scrip is not allowed to trade in MTF." }) : () => setFieldValue("product", "MTF")}
                              className={[classes.buttonTab, classes.tabLeft, product === "MTF" ?
                                (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                                classes.defaultButton].join(" ")}
                              disabled={type === "squareOff" && netQty ? scrip.segment === "EQ" && exchange === "NSE" && productType === "MTF" ? false : true
                                : (/* values.type !== "B" || scrip.segment !== "EQ" || (scrip.segment === "EQ" && exchange !== "NSE") ||  */(status === "Modify" && productType !== "MTF"))}>
                              MTF
                            </Button>
                          </Tooltip>}
                        </Grid>
                        {errors.product && (<p className={classes.errorText}>{"Product type is required"}</p>)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={segment == "COMM" ? 12 : 6} style={segment == "COMM" ? { marginTop: "8px" } : {}}>
                    <Grid container>
                      {/* {segment == "COMM" ? <></> : <Grid item xl={2} lg={1} md={1} sm={1} />} */}
                      <Grid item xl={10} lg={11} md={11} sm={11} className={segment == "COMM" ? "" : classes.productGrid}>
                        <Typography className={classes.text}>Valid Till :
                        </Typography>
                        <Grid container>
                          <Button
                            onClick={() => setFieldValue("validTill", "GFD")}
                            className={[classes.buttonTab, classes.tabRight, validTill === "GFD" ?
                              (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                              classes.defaultButton].join(" ")}
                          >
                            Day
                          </Button>
                          <Button
                            onClick={(marketStatus && marketStatus[scrip.shortCode] && marketStatus[scrip.shortCode].isAfterHourMarket) ?
                              () => setInfoPopup({ show: true, message: "IOC order not allowed in AMO." })
                              :
                              (orderType === "SL" && segment === "COMM" && scrip?.exchange === "MCX") ?
                                () => setInfoPopup({ show: true, message: "Stop Loss not allowed for IOC order." })
                                :
                                () => setFieldValue("validTill", "IOC")}
                            className={[classes.buttonTab, classes.tabLeft, validTill === "IOC" ?
                              (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                              classes.defaultButton].join(" ")}
                          >
                            IOC
                          </Button>
                          {
                            segment == "COMM" && product !== "Intraday" ?
                              <>
                                <Button
                                  onClick={() => setFieldValue("validTill", "GTC")}
                                  className={[classes.buttonTab, classes.commProdType, validTill === "GTC" ?
                                    (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                                    classes.defaultButton].join(" ")}
                                >
                                  GTC
                                </Button>
                                <Button
                                  onClick={() => setFieldValue("validTill", "GTD")}
                                  className={[classes.buttonTab, validTill === "GTD" ?
                                    (values.type === "S" || values.type === "SS") ? classes.activeSell : classes.activeButton :
                                    classes.defaultButton].join(" ")}
                                >
                                  GTD
                                </Button>
                              </>
                              :
                              <></>
                          }
                        </Grid>
                        {errors.validTill && (<p className={classes.errorText}>{"Validity is required"}</p>)}
                      </Grid>
                    </Grid>
                  </Grid>
                  {USER === "client" && scrip.segment === "EQ" && <Grid item xs={12} className={classes.approxMarginDiv}>
                    <FormControl variant="outlined">
                      <FormControlLabel
                        id="approxMargin"
                        name="approxMargin"
                        control={
                          <CssSwitch
                            checked={approxMargin}
                            onChange={!isMTFApproved ? () => setInfoPopup({ show: true, message: "This scrip is not allowed to trade in MTF." }) : handleChange}
                            className={classes.switchIcon}
                          />
                        }
                        label=""
                        classes={{ root: classes.switchField, disabled: classes.switchText }}
                        disabled={(type === "squareOff" && netQty) || status === "Modify"}
                      />
                    </FormControl>
                    <Typography className={classes.switchText}>Buy upto 4 times of your available balance with just 0.06% interest per day</Typography>
                  </Grid>}
                </Grid>
                {
                  segment === "COMM" && validTill === "GTD" &&
                  <>
                    <Grid item xs={5}>
                      <FormControl variant="outlined" className={classes.fieldDiv}>
                        <Typography className={classes.fieldText}>Validity Date</Typography>
                        <CssSelect
                          labelId="demo-simple-select-filled-label"
                          id="validityDate"
                          name="validityDate"
                          value={validityDate}
                          onChange={handleChange}
                          error={touched.validityDate && errors.validityDate ? true : false}
                          onKeyUp={() => setFieldTouched("validityDate", true)}
                          className={classes.select}
                          input={<SelectOutlinedInput />}
                          variant="standard"
                          IconComponent={typeof (validityDateData) === "string" ? () => (<CircularProgress style={{ marginRight: "4px" }} color="inherit" size={16} />) : () => (<Arrow style={{ marginRight: "4px" }} />)}
                        >
                          {
                            validityDateData && typeof (validityDateData) !== "string" && validityDateData.length > 0 ?
                              validityDateData.map((ele) => (
                                <MenuItem value={ele} className={classes.menuItem}>{ele}</MenuItem>
                              ))
                              :
                              <MenuItem value={""} className={classes.menuItem}>No Result Found</MenuItem>
                          }
                        </CssSelect>
                        {errors.validityDate && touched.validityDate && (<p className={classes.errorText}>{errors.validityDate}</p>)}
                      </FormControl>
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <Grid container className={classes.orderButtonGrid}>
                    <Grid item xs={5}>
                      <Button disabled={submitting} className={[classes.order, classes.containedBlue].join(" ")} type="submit">
                        {submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}{status === "Modify" ? "Modify Order" : "Place Order"}
                      </Button>
                    </Grid>
                    <Typography onClick={handleBrokerageCharges} variant="h6" style={{ cursor: "pointer", fontWeight: 600, justifyContent: "flex-end" }} className={[classes.confirmBlueText, classes.centerContent].join(" ")}>
                      Brokerage Charges
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <span className={classes.orderHelp}>
          <IconButton onClick={() => setHelpPopup(true)} className={classes.infoButton}>
            <InfoIcon color="primary" className={classes.info} />
          </IconButton>
        </span>
      </fieldset >
    </>
  )
}

export default withStyles(combineStyles(styles, commonStyles))(OrderForm)