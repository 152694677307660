import CssBaseline from '@mui/material/CssBaseline';
// Material helpers
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import ConfirmationDialogRaw from 'components/ConfirmationDialog';
import NoInternetDialog from "components/NoInternet/NoInternetDialog";
import TourGuide from "components/Tour";
import getToken, { deleteCookie, getCookie } from 'helpers/cookies';
import { goToContentWebsite, goToLogin } from "helpers/navHelpers";
// import { Dashboard as DashboardLayout } from 'layouts';

import React, { Component, Suspense, lazy } from 'react';
import { IdleTimer } from 'components/IdleTimer';

import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { PrefsService } from "services/prefs";
import { profileService } from 'services/profile';
import { pprService } from 'services/ppr';
import './assets/scss/index.scss';
// Routes
import Routes from './Routes';
// Theme
import getTheme from './theme';

// import { ReactNotifications } from 'react-notifications-component';
// import 'react-notifications-component/dist/theme.css'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { Link, Typography } from '@mui/material';
import { LayoutActions } from "redux/Layout";
import { io } from "socket.io-client";
import Loader from "components/Loader";
import customParser from 'socket.io-json-parser';
import { DialogActions } from 'redux/Dialog';

const Dashboard = lazy(() => import('layouts/Dashboard'));

export const UserProfileContext = React.createContext({});
export const UserThemeContext = React.createContext({
  theme: 'dark',
  toggleTheme: () => { }
});
export const PPRDataContext = React.createContext({});

//console.log("Access Token "+getToken("t_token"))
var accessToken = getToken("t_token")

// var client = Stomp.over(new SockJS(`${process.env.REACT_APP_WS_URL}` + "?token=" + accessToken + "&ch=WEB&appid="+`${process.env.REACT_APP_WS_APPID}`));
//client.debug = function(str) {};
// Create a Context
export const WebsocketClientContext = React.createContext();
const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  transports: ["websocket"], // use WebSocket first, if available,
  parser: customParser
});

// const socket = io("https://10.34.0.129:9001", {
//   transports: ["websocket"], // use WebSocket first, if available,
// });

// const socket = io("http://localhost:9001", {
//   transports: ["websocket"], // use WebSocket first, if available,
//   parser: customParser
// });

class Main extends Component {

  constructor(props) {
    super(props);

    this.toggleTheme = () => {
      // let newTheme = this.state.theme == 'light' ? 'dark' : 'light';
      let newTheme = this.state.theme == 0 ? 1 : 0;
      // this.setState({ theme: newTheme });
      PrefsService.setColorTheme(newTheme);
      // localStorage.setItem('activeTheme', newTheme);
    }
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      userProfile: { name: "Guest" },
      // theme: localStorage.getItem('activeTheme') || 'dark',
      theme: props.colorTheme,
      toggleTheme: this.toggleTheme,
      connected: false,
      //timeout:1000 * 60 * 15,
      showModal: false,
      isTimedOut: false,
      pprData: []
    }

    //Idle Timeout Setting
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.colorTheme != prevState.theme) {
      // debugger
      console.log('setTheme');
      return { theme: nextProps.colorTheme };
    }
    return null;
  }

  _onAction(e) {
    //console.log('user did something', e)
    if (this.state.isTimedOut)
      this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    console.log('user is active', e)
    if (this.state.isTimedOut)
      this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      this.props.history.push('/')
    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
      getCookie('fnorisk') && deleteCookie('fnorisk')
      getCookie('showBanner') && deleteCookie('showBanner')
    }
  }

  handleClose = (newValue) => {
    if (localStorage.getItem("intradayProduct")) {
      localStorage.removeItem("intradayProduct");
    }
    if (localStorage.getItem("mtfStatus")) {
      localStorage.removeItem("mtfStatus");
    }
    this.setState({ showModal: false })

    if (newValue === 'confirm')
      goToLogin();

  }

  handleProAccessClose = (newValue) => {
    goToContentWebsite();
  }

  async getProfile() {
    const USER = `${process.env.REACT_APP_USER}`;
    const PLATFORM = `${process.env.REACT_APP_PLATFORM}`;

    try {
      const userProfile = await profileService.getProfile();
      if (USER === 'admin') {
        if (userProfile.isHOUser && PLATFORM == 'nextplus') {
          LayoutActions.loadLayout('nextadmin');
        } else {
          LayoutActions.loadLayout('ctclClient');
        }
      } else {
        LayoutActions.loadLayout('client');
      }
      if (userProfile && Object.keys(userProfile).length > 0) {
        if (userProfile?.isProAccess || USER == 'admin') {
          this.setState({
            userProfile: userProfile
          });
        } else {
          this.setState({
            notProAccess: true
          });
          // goToContentWebsite();
        }
      }
      let nominee = getCookie('nominee') && new Date(Number(getCookie('nominee'))) || false;
      if (userProfile?.fnoProduct && USER === 'client' && !getCookie('fnorisk')) {
        DialogActions.openFnoRiskPopup()
      } else if (!userProfile.isNominee && USER === 'client' && (!nominee || (nominee && (`${nominee.getDate()}-${nominee.getMonth()}` !== `${new Date().getDate()}-${new Date().getMonth()}`)))) {
        DialogActions.openNomineePopup()
      } else if (userProfile.isNominee && nominee) {
        deleteCookie('nominee')
      } else if (getCookie("showBanner") === "Yes") {
        DialogActions.openBannersPopup()
      }
      // if(USER === 'client' && getCookie('totp') && getCookie('totp') != 1) {
      //   DialogActions.openEnableTotp('profile')
      // }
    } catch (error) {
      return;
    }
  }

  async getPPRData() {
    try {
      const data = await pprService.getPprData();
      this.setState({
        pprData: data
      });
    } catch (error) {
      return;
    }
  }

  componentDidMount() {
    this.getProfile();
    this.getPPRData();
    socket.on('connect', () => {
      this.setState({
        connected: true
      });
      console.log("Websocket connected")

    });
    socket.on('disconnect', () => {
      this.setState({
        connected: false
      });
      console.log("Websocket disconnected")
    });
  }

  componentWillUnmount() {
    socket.off('connect');
    socket.off('disconnect');

  }

  render() {
    //console.log(this.state)
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getTheme({ paletteType: this.state.theme == 0 ? 'light' : 'dark' })}>
          <CssBaseline />
          {/* {this.state.connected ? */} <WebsocketClientContext.Provider value={socket}>
            {/* <ReactNotifications /> */}
            <ToastContainer newestOnTop style={{ width: 'auto' }} />
            <UserThemeContext.Provider value={this.state}>
              <UserProfileContext.Provider value={this.state.userProfile}>
                {this.state.notProAccess &&
                  <ConfirmationDialogRaw
                    id="AlertProAccess"
                    dialogTitle={"Alert"}
                    Component={<Typography variant="h4" color="textPrimary">You do not have Access to Tradebulls Trading platform. To get access please email on <Link to="#" href="mailto:online@tradebulls.in" underline='hover'>online@tradebulls.in</Link> from your registered email-id or contact your Relationship Manager.</Typography>}
                    keepMounted
                    open={this.state.notProAccess}
                    hideCloseButton={true}
                    onClose={this.handleProAccessClose}
                  />
                }
                {this.state.userProfile.name != 'Guest' ? <PPRDataContext.Provider value={this.state.pprData}>
                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    timeout={1000 * 60 * 60} >
                    <Suspense fallback={<div></div>}>
                      <Dashboard>
                        <Route component={Routes} />
                        <NoInternetDialog openDialog={true} />
                        <TourGuide />
                      </Dashboard>
                    </Suspense>
                  </IdleTimer>
                  <ConfirmationDialogRaw
                    id="Idle Logout"
                    keepMounted
                    content="Idle Timeout. System will logout."
                    hideCloseButton={true}
                    open={this.state.showModal}
                    onClose={this.handleClose}
                  />
                </PPRDataContext.Provider> : <Loader />}
              </UserProfileContext.Provider>
            </UserThemeContext.Provider>
          </WebsocketClientContext.Provider>
          {/* :<Loader/>} */}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { colorTheme } = state.prefs;
  return { colorTheme };
}

export default connect(mapStateToProps)(Main)