import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import SvgComponent from "helpers/svgcomponent";
import React from "react";
import { connect } from "react-redux";
import { closeConfirmDialog, openConfirmDialog } from "redux/ConfirmDialog";

const styles = (theme) => ({
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: "0px",
    height: "25px",
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: "#497CAC",
    fontWeight: 600
  },
  contentContainer: {
    backgroundColor: theme.palette.background.secondary,
    padding: "0px 16px 8px 16px",
    paddingTop: "0px !important"
  },
  actionsContainer: {
    backgroundColor: theme.palette.background.secondary,
    // padding: `0 0 ${theme.spacing(1)} 0`,
  },
  contentText: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  headerGrid: {
    display: "flex",
    alignItems: "center",
    height: "25px"
  },
  closeIcon: {
    float: "right",
    height: "25px",
    marginRight: theme.spacing(1),
    padding: 0
  },
  contentAlign: {
    justifyContent: "center",
    display: "flex"
  },
  dialog: {
    padding: "24px 24px 0px 24px",
    background: theme.palette.background.secondary,
  },
  belowMainDiv: {
    // margin: "0px 24px 16px 24px",
    background: theme.palette.background.secondary,
    borderTop: `2px dashed ${theme.palette.background.default}`
  },
  belowDataDiv: {
    padding: "12px 24px",
    background: theme.palette.background.default,
    margin: "12px 0px 20px 0px",
    borderRadius: "4px"
  },
  belowDataText: {
    paddingBottom: "16px",
    textAlign: "center",
    lineHeight: "21px",
    color: theme.palette.color12.default
  },
  belowDataButton: {
    color: "#fff",
    backgroundColor: theme.palette.common.blue,
    textTransform: "none",
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    }
  },
  reportsButtonDiv: {
    width: "100%",
    marginBottom: "20px",
    textAlign: "center"
  }
});

function ConfirmDialog(props) {
  const { classes, isConfirmOpen, confirmData: { onClose, content, dialogTitle, hideCloseButton, okButtonText, cancelButtonText, centerActions, image, imageStyle, showBelowData, belowData, reportsButton, contentHeading } } = props;
  const { header, headerGrid, title, closeIcon, contentContainer, contentText, actionsContainer, belowMainDiv, belowDataDiv, belowDataText, belowDataButton, reportsButtonDiv } = classes;

  function handleCancel(event, reason) {
    if (reason != 'backdropClick') {
      onClose("cancel");
    }
  }

  function handleOk() {
    onClose("confirm");
  }

  return (isConfirmOpen &&
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={isConfirmOpen}
      onClose={handleCancel}
    // {...other}
    >
      <div className={classes.dialog}>
        {
          dialogTitle &&
          <DialogTitle id="form-dialog-title" className={header}>
            <Grid container>
              <Grid item lg={11} md={11} sm={11} xs={11} className={headerGrid}>
                <Typography className={title}>{dialogTitle}</Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1} >
                <Tooltip title="Close" aria-label="close">
                  <IconButton aria-label="close" onClick={() => handleCancel()} className={closeIcon}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
        }
        <DialogContent className={contentContainer} style={{ marginTop: dialogTitle ? "25px" : null }}>
          {
            image &&
            <div className={classes.contentAlign}>
              <SvgComponent iconname={image} style={imageStyle || {}} />
            </div>
          }
          {contentHeading && <Typography className={contentText} style={{ textAlign: 'left' }}>{contentHeading || ''}</Typography>}
          <Typography className={contentText} style={contentHeading ? { textAlign: 'left' } : {}}>{content || 'Do you want to Logout ?'}</Typography>
        </DialogContent>
        <DialogActions className={[actionsContainer, centerActions ? classes.contentAlign : ""]}>
          {hideCloseButton ? <></> : <Button onClick={handleCancel} color="secondary">{cancelButtonText ? cancelButtonText : "Cancel"}</Button>}
          <Button autoFocus={true} onClick={handleOk} color="secondary">{okButtonText ? okButtonText : "Ok"}</Button>
        </DialogActions>
        {
          reportsButton && reportsButton.show &&
          <div className={reportsButtonDiv}>
            <Button style={{ textTransform: "none", padding: "4px 16px" }} onClick={reportsButton.onClick} variant="contained" color="secondary">{reportsButton.buttonText || "Go To Reports"}</Button>
          </div>
        }
        {showBelowData &&
          <div className={belowMainDiv}>
            <div className={belowDataDiv}>
              <Typography variant="subtitle2" className={belowDataText}>{belowData && belowData.heading}</Typography>
              <div style={{ textAlign: "center" }}>
                <Button className={belowDataButton} onClick={belowData && belowData.buttonClick} variant="contained">{belowData && belowData.buttonText || ""}</Button>
              </div>
            </div>
          </div>}
      </div>
    </Dialog>
  );
}

// ConfirmationDialogRaw.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired
// };

const mapStateToProps = (state) => {
  return { isConfirmOpen: state.confirmDialog.isConfirmOpen, confirmData: state.confirmDialog.confirmData || {} }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: () => dispatch(openConfirmDialog()),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfirmDialog))
