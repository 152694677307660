export default theme => ({
  form: {
    width: 400,
    margin: '8px 0px',
    padding: 16,
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('lg')]: {
      width: 350,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
      padding: 12
    },
  },
  loadingDiv: {
    height: 200
  },
  radio: {
    '&$checked': {
      color: '#006CA8'
    }
  },
  checked: {},
  radioLabel: {
    color: theme.palette.text.primary
  },
  svg: {
    width: 50,
    marginTop: 4,
    [theme.breakpoints.down('lg')]: {
      width: 48,
      marginTop: 2
    },
    [theme.breakpoints.down('md')]: {
      width: 42,
      marginTop: 4
    },
  },
  input: {
    padding: "8px",
    // background: "#EBEEF5",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#808080",
    }
  },
  belowText: {
    color: theme.palette.color15.default,
    // fontSize: "12px"
  },
  fieldDiv: {
    marginBottom: "16px",
    width: "100%",
    [theme.breakpoints.down('lg')]: {
      marginBottom: "12px",
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: "8px",
    },
  },
  fieldText: {
    marginBottom: theme.spacing(0.25),
    color: theme.palette.color15.default,
    fontSize: "12px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    }
  },
  errorText: {
    color: "#f54040",
    marginTop: "4px"
  },
  button: {
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    width: "100%",
    textTransform: "none",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  menuItem: {
    padding: "6px 12px",
  },
  select: {
    border: "1px solid #66788a4d",
    color: theme.palette.color12.default
  },
})