export default theme => ({
  button: {
    border: `1px solid ${theme.palette.color15.default}`,
    padding: "0px 12px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px 4px 0px 0px",
    color: theme.palette.color15.default,
    fontSize: "14px",
    margin: "4px",
    [theme.breakpoints.down('xl')]: {
      fontSize: '11px',
      height: '24px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      height: '20px',
      padding: "0px 8px",
    }
  },
  text: {
    color: theme.palette.color15.default,
    fontSize: "14px",
    lineHeight: "35px",
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '20px',
    },
  },
  listItem: {
    margin: "0px 16px 0px 0px",
    // borderBottom: `1px dashed ${theme.palette.color5.default}`,
    padding: "12px 0px",
    display: "flex",
    [theme.breakpoints.down('xl')]: {
      margin: "0px 8px 0px 0px",
      padding: "6px 0px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "0px 0px 0px 0px"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "0px 8px 0px 0px",
      padding: "8px 0px",
    },
  },
  item: {
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "180px",
    [theme.breakpoints.down('xl')]: {
      width: "155px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "125px",
    },
  },
  list: {
    // height: "600px",
    overflowY: "auto"
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "-4px",
    flexWrap: "wrap",
    width: "200px",
    [theme.breakpoints.down('xl')]: {
      width: "155px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "115px",
    },
    [theme.breakpoints.down('md')]: {
      width: "155px"
    },
  },
  paper: {
    // width: "450px",
    margin: "10px 0px",
    backgroundColor: theme.palette.background.default
  },
  bottomDiv: {
    border: `1px solid ${theme.palette.color15.default}`,
    height: "4px",
    margin: "-4px 4px 4px 4px",
    borderRadius: "0px 0px 4px 4px"
  },
  commaDiv: {
    paddingTop: theme.spacing(3),
    color: theme.palette.color15.default,
    fontSize: theme.spacing(2.5),
    [theme.breakpoints.down('xl')]: {
      paddingTop: "18px",
      fontSize: "18px",
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
  },
  gridItem: {
    paddingRight: "16px",
    paddingLeft: "16px",
    borderRight: `1px dashed ${theme.palette.color5.default}`,
    [theme.breakpoints.down('xl')]: {
      paddingRight: "8px",
      paddingLeft: "8px",
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: "4px",
      paddingLeft: "4px",
      borderRight: "0px"
    },
  },
  gridContainer: {
    padding: "12px 0px",
    [theme.breakpoints.down('md')]: {
      padding: "8px 0px"
    },
    [theme.breakpoints.down('sm')]: {
      padding: "4px 0px"
    },
  }
})