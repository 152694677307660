import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import { getDate } from "../data";
import styles from "../styles";

function ConfirmMfOrder(props) {
  const { classes, formValues, loading, handleStep, handleBack, orderType, lastStep, sip } = props

  const HeaderDiv = () => {
    return (
      <Grid container className={classes.valueHeaderGrid}>
        <Grid item xs={12}>
          <Grid container className={classes.centerContent}>
            <Grid item xs={formValues.action === "Sell" ? 4 : 5}>
              <Typography variant="caption" align="center" className={classes.blueText}>Schemes</Typography>
            </Grid>
            <Grid item xs={sip ? 1 : 2} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>NAV</Typography>
            </Grid>
            <Grid item xs={1} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>Action</Typography>
            </Grid>
            {
              sip ?
                <></>
                :
                <Grid item xs={2} className={classes.centerContent}>
                  <Typography variant="caption" align="center" className={classes.blueText}>{formValues.action === "Sell" ? "Redeem Units" : "Settlement Date"}</Typography>
                </Grid>
            }
            <Grid item xs={sip ? 1 : formValues.action === "Sell" ? 3 : 2} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>{formValues.action === "Sell" ? "Value of Redeem Units" : "Amount"}</Typography>
            </Grid>
            {
              sip ?
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={5} className={classes.centerContent}>
                      <Typography variant="caption" align="center" className={classes.blueText}>{"Start Date"}</Typography>
                    </Grid>
                    <Grid item xs={7} className={classes.centerContent}>
                      <Typography variant="caption" align="center" className={classes.blueText}>{"No. of Installments"}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <>
                </>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const ValueDiv = () => {
    return (
      <Grid container className={classes.confirmGridItem}>
        {
          formValues && formValues.data && formValues.data.length > 0 ?
            formValues.data.map((ele, index) => (
              <Grid item xs={12} className={classes.valueGridItem} key={index} style={index === formValues.data.length - 1 ? { borderBottom: 0 } : {}}>
                <Grid container className={classes.centerContent}>
                  <Grid item xs={formValues.action === "Sell" ? 4 : 5}>
                    <Typography variant="subtitle1" className={classes.primaryText}>{ele.schemeName || "--"}</Typography>
                  </Grid>
                  <Grid item xs={sip ? 1 : 2}>
                    <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(ele.nav && Number(ele.nav).toFixed(4)) || "--"}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle1" align="center" className={[classes.primaryText, formValues.action].join(" ")}>{formValues.action || "--"}</Typography>
                  </Grid>
                  {
                    sip ?
                      <></>
                      :
                      <Grid item xs={2}>
                        <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(formValues.action === "Sell" ? ele.units : ele.settlementDate && getDate(ele.settlementDate)) || "--"}</Typography>
                      </Grid>
                  }
                  <Grid item xs={sip ? 1 : formValues.action === "Sell" ? 3 : 2}>
                    <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(ele.amount && Number(ele.amount)) || "0.00"}</Typography>
                  </Grid>
                  {
                    sip ?
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid item xs={5} className={classes.centerContent}>
                            <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(ele.startDate && getDate(ele.startDate)) || "--"}</Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography variant="subtitle1" align="center" className={classes.primaryText}>{formValues.installments || "--"}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      :
                      <></>
                  }
                </Grid>
              </Grid>
            ))
            :
            <></>
        }
      </Grid>
    )
  }

  return (
    <>
      <Grid container className={classes.confirmValueGrid}>
        <Grid item xs={12}>
          {HeaderDiv()}
        </Grid>
        <Grid item xs={12}>
          {ValueDiv()}
        </Grid>
      </Grid>
      <Grid container className={classes.buttonGrid}>
        <Grid item xs={3}>
          <Button variant="outlined" disabled={loading.submitting} className={classes.cancel} onClick={handleBack}>Back</Button>
        </Grid>
        <Grid item xs={lastStep ? 4 : 3}>
          <Button variant="contained" disabled={loading.submitting} className={lastStep ? classes.sendOrder : classes.order} onClick={handleStep}>
            {
              lastStep ?
                <>
                  {loading && loading.submitting && <CircularProgress size={20} style={{ marginRight: "12px", color: "#7d7d7d" }} />}Confirm and Send Orders
                </>
                :
                "Confirm"
            }
          </Button>
        </Grid>
        <Grid item xs={lastStep ? 5 : 6}>
          <Grid container className={classes.endElement}>
            {
              formValues.action !== "Sell" &&
              <Grid item lg={6} xs={6} className={classes.endElement}>
                <Typography variant="caption" className={classes.blueText}>Order Type<span className={classes.spanValue}>{orderType || "--"}</span></Typography>
              </Grid>
            }
            {
              formValues.action === "Buy" && !sip &&
              <Grid item lg={6} xs={6} className={classes.endElement}>
                <Typography variant="caption" className={classes.blueText}>Order Date<span className={classes.spanValue}>{getDate(new Date())}</span></Typography>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(ConfirmMfOrder)