import React, { useRef, useState } from "react";
import { withStyles } from "@mui/styles";
import { Select, OutlinedInput, Checkbox, FormControl, FormControlLabel, Link, Typography, Grid, Button, Popover, MenuItem, Tooltip, InputAdornment, CircularProgress, Popper, Paper, ClickAwayListener, MenuList, RadioGroup, Radio, IconButton } from "@mui/material";
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import Placeholder from "components/Placeholder";
import SvgComponent from "helpers/svgcomponent";
// import DatePicker from 'components/DatePicker';
import styles from "./styles";
import { Search, Close, KeyboardArrowDown as Arrow, InfoOutlined as InfoIcon, DateRange as Calendar } from "@mui/icons-material";

const CssTooltip = withStyles((theme) => ({
  arrow: {
    fontSize: "10px"
  },
  tooltip: {
    backgroundColor: theme.palette.background.darker,
    color: theme.palette.color15.default,
    padding: "8px",
    maxWidth: "150px",
    fontSize: "10px",
    border: `1px solid #66788a4d`,
    marginRight: "-50px"
  }
}))(Tooltip)

const typeTabs = [
  /*  {
     label: "Amount",
     value: "amount"
   }, */
  {
    label: "Units",
    value: "units"
  },
  {
    label: "All",
    value: "all"
  }
]

const CssCheckbox = withStyles({
  root: {
    color: "#497CAC",
    '&$checked': {
      color: "#497CAC",
    },
  },
  checked: {},
})(Checkbox)

const CssOutlinedInput = withStyles(theme => ({
  root: {
    width: "100%",
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$error $notchedOutline": {
      borderWidth: 1,
      borderColor: "#f54040"
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {
    borderColor: "#66788a4d"
  }
}))(OutlinedInput)

const CssSelect = withStyles(theme => ({
  root: {
    padding: "8px 0px 8px 7px",
    [theme.breakpoints.down('xl')]: {
      padding: "4px 0px 4px 6px"
    },
    [theme.breakpoints.down('xl')]: {
      padding: "3px 0px 3px 6px"
    }
  },
  select: {
    paddingRight: "0px !important"
  },
  outlined: {}
}))(Select)

const SelectOutlinedInput = withStyles(theme => ({
  root: {
    fontSize: "14px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
    "& $notchedOutline": {
      borderWidth: 0
    },
    "&:hover $notchedOutline": {
      borderWidth: 0
    },
    "&$focused $notchedOutline": {
      borderWidth: 0
    }
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {}
}))(OutlinedInput)

const FirstOrderInfo = (props) => {
  const { classes } = props
  return (
    <>
      <Typography className={classes.tooltip}>Place First Order Today</Typography>
    </>
  )
}

function getDate(date) {
  if (date) {
    return new Date(date).getDate() + " " + new Date(date).toLocaleString('default', { date: 'numeric', month: 'short', year: 'numeric' })
  }
  return ''
}

export function getMandateData(values, formData) {
  let mandateData = values.type === "SIP" ? formData?.mandate || [] : (formData && formData[0]?.mandate) || []
  if (mandateData?.length > 0 && values.accountDetails) {
    let data = [];
    mandateData.filter(man => man.bankAaccountNumber === values.accountDetails).forEach(ele => {
      if (values.type === "SIP" && values.firstOrderToday) {
        data.push(ele)
      } else {
        if (ele.status) {
          data.push(ele)
        }
      }
    })
    return data || []
  }
  return []
}

function OrderForm(props) {
  const { classes, values, setFieldValue, handleChange, touched, errors, setFieldTouched, setOrderType, formData, onFrequencyChange, loading, reduxState, onSwitchToChange, schemeList, switchToScheme, sortAnchor, setSortAnchor, handleSwitchToItem, onCancelSwitchScheme, addBankMandate, handleAddMoneyClick, onAccountChange } = props

  const listItemRef = useRef(null);

  const onInputKeyDown = event => {
    const { keyCode } = event;
    if (keyCode === 40 && schemeList && typeof (schemeList) !== "string" && schemeList.length > 0) {
      listItemRef && listItemRef.current.focus();
    }
  }

  function checkAmount() {
    if (formData && formData[values.type === "SIP" ? values.frequency : 0]) {
      if (values.type === "OT") {
        if (formData && formData[0] && formData[0].folio && formData[0].folio.length > 0) {
          return formData[0].additionaAmount || []
        } else {
          return formData[0].amount || []
        }
      } else {
        return formData[values.frequency].amount || []
      }
    }
    return []
  }

  const getPopper = () => {
    return (
      <Popper className={classes.popper} open={Boolean(sortAnchor)} anchorEl={sortAnchor} role={undefined} transition disablePortal placement="bottom-start">
        <Paper>
          <ClickAwayListener onClickAway={() => { setSortAnchor(null) }}>
            <MenuList ref={listItemRef} id="menu-list-grow" className={classes.menuList}>
              {
                schemeList.map((ele, index) => (
                  <MenuItem key={index} tabIndex={0} className={classes.switchMenuItem} onClick={() => handleSwitchToItem(ele)}>
                    <div className={classes.menuItemDiv}>
                      <Typography
                        variant="subtitle1"
                        style={{ textOverflow: "ellipsis", whiteSpace: "initial" }}
                        className={classes.primaryText}>
                        {ele.label}
                      </Typography>
                    </div>
                  </MenuItem>
                ))
              }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    )
  }

  const getLegend = () => {
    return (
      <legend className={classes.legend}>
        {
          values.type === "REDEEM" || values.type === "SWITCH" ?
            <>
              <Button
                onClick={values.type === "REDEEM" ? () => { } : () => setOrderType("REDEEM")}
                variant="contained"
                disabled={(reduxState && reduxState.scheme && reduxState.scheme.isRedemptionAllowed && reduxState.scheme.isRedemptionAllowed === "N") || false}
                style={{ textTransform: "none" }}
                color={values.type === "REDEEM" ? "secondary" : "inherit"}
                className={[classes.activeOrder, values.type === "REDEEM" ? "activeColor" : ""].join(" ")}
              >
                Redeem
              </Button>
              <Button
                onClick={values.type === "SWITCH" ? () => { } : () => setOrderType("SWITCH")}
                variant="contained"
                disabled={(reduxState && reduxState.scheme && reduxState.scheme.isSwitchAllowed && reduxState.scheme.isSwitchAllowed === "N") || false}
                style={{ textTransform: "none", borderRadius: "0px 4px 4px 0px" }}
                color={values.type === "SWITCH" ? "secondary" : "inherit"}
                className={[classes.activeOrder, values.type === "SWITCH" ? "active" : ""].join(" ")}
              >
                Switch
              </Button>
            </>
            :
            <>
              <Button
                onClick={values.type === "SIP" ? () => { } : () => setOrderType("SIP")}
                variant="contained"
                disabled={(reduxState && reduxState.scheme && reduxState.scheme.isSipAllowed && reduxState.scheme.isSipAllowed === "N") || false}
                color={values.type === "SIP" ? "primary" : "inherit"}
                className={[classes.activeOrder, values.type === "SIP" ? "activeColor" : ""].join(" ")}
              >
                SIP
              </Button>
              <Button
                onClick={values.type === "OT" ? () => { } : () => setOrderType("OT")}
                variant="contained"
                disabled={(reduxState && reduxState.scheme && reduxState.scheme.purchaseAllowed && reduxState.scheme.purchaseAllowed === "N") || false}
                style={{ borderRadius: "0px 5px 5px 0px", textTransform: "none" }}
                color={values.type === "OT" ? "primary" : "inherit"}
                className={[classes.activeOrder, values.type === "OT" ? "activeColor" : ""].join(" ")}
              >
                One Time
              </Button>
            </>
        }
      </legend>
    )
  }

  const getLoadingDiv = () => {
    return (
      <div className={[classes.innerLoadingDiv, values.type].join(" ")}>
        <Placeholder
          loading={loading.error === "" ? true : false}
          loadingMsg={""}
          error={loading.error === "" ? "" : loading.error}
        />
      </div>
    )
  }

  const leftFormFields = () => {
    return (
      <Grid container>
        {
          (values.type === "SIP" || values.type === "OT") ?
            formData && formData[values.type === "SIP" ? values.frequency : 0] && formData[values.type === "SIP" ? values.frequency : 0].folio && formData[values.type === "SIP" ? values.frequency : 0].folio.length > 0 &&
            <Grid item xs={11}>
              <FormControl variant="outlined" className={classes.fieldDiv}>
                <Typography className={classes.fieldText}>Folio Number</Typography>
                <CssSelect
                  labelId="demo-simple-select-filled-label"
                  id="folioNumber"
                  name="folioNumber"
                  value={values.folioNumber}
                  onChange={handleChange}
                  error={touched.folioNumber && errors.folioNumber ? true : false}
                  onKeyUp={() => setFieldTouched("folioNumber", true)}
                  className={classes.select}
                  input={<SelectOutlinedInput />}
                  variant="standard"
                  IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
                >
                  {
                    formData[values.type === "SIP" ? values.frequency : 0].folio.map((ele) => (
                      <MenuItem key={ele} value={ele} className={classes.menuItem}>{ele}</MenuItem>
                    ))
                  }
                  <MenuItem key={"new"} value={"new"} className={classes.menuItem}>Create new folio</MenuItem>
                </CssSelect>
                {errors.folioNumber && touched.folioNumber && (<p className={classes.errorText}>{errors.folioNumber}</p>)}
              </FormControl>
            </Grid>
            :
            reduxState.scheme && reduxState.scheme.folioNumber && reduxState.scheme.folioNumber !== "" &&
            <Grid item xs={11}>
              <FormControl variant="outlined" className={classes.fieldDiv}>
                <Typography className={classes.fieldText}>Folio Number</Typography>
                <CssSelect
                  labelId="demo-simple-select-filled-label"
                  id="folioNumber"
                  name="folioNumber"
                  value={values.folioNumber}
                  onChange={handleChange}
                  error={touched.folioNumber && errors.folioNumber ? true : false}
                  onKeyUp={() => setFieldTouched("folioNumber", true)}
                  className={classes.select}
                  input={<SelectOutlinedInput />}
                  variant="standard"
                  IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
                >
                  <MenuItem value={(reduxState && reduxState.scheme && reduxState.scheme.folioNumber) || ""} className={classes.menuItem}>{(reduxState && reduxState.scheme && reduxState.scheme.folioNumber) || ""}</MenuItem>
                </CssSelect>
                {errors.folioNumber && touched.folioNumber && (<p className={classes.errorText}>{errors.folioNumber}</p>)}
              </FormControl>
            </Grid>
        }
        {
          values.type === "REDEEM" || values.type === "SWITCH" ?
            <>
            </>
            :
            <Grid item xs={11}>
              <FormControl variant="outlined" className={classes.fieldDiv}>
                <Typography className={classes.fieldText}>Investment  Amount</Typography>
                <CssOutlinedInput
                  id="amount"
                  name="amount"
                  classes={{ input: classes.input }}
                  onChange={handleChange}
                  error={touched.amount && errors.amount ? true : false}
                  value={values.amount}
                  onKeyUp={() => setFieldTouched("amount", true)}
                />
                <Typography variant="caption" className={classes.belowText}>Minimum ₹ {formData && formData[values.type === "SIP" ? values.frequency : 0] && formData[values.type === "SIP" ? values.frequency : 0][values.type === "OT" ? formData && formData[0] && formData[0].folio && formData[0].folio.length > 0 && values.folioNumber !== "new" ? "additionalPurchaseAmountMultiple" : "minPurchaseAmount" : "minPurchaseAmount"]}*</Typography>
                {errors.amount && touched.amount && (<p className={classes.errorText}>{errors.amount}</p>)}
              </FormControl>
              {
                checkAmount().length > 0 &&
                checkAmount().map((ele, i) => (
                  <Button
                    key={i}
                    onClick={() => setFieldValue("amount", Number(ele))}
                    className={[classes.defaultButton, values.amount == ele ? "active" : ""].join(" ")}
                    style={i === 2 ? { marginRight: "0px", marginTop: "0px" } : { marginTop: "0px" }}
                  >
                    ₹ {ele || ""}
                  </Button>
                ))
              }
            </Grid>
        }
        {
          values.type === "SIP" ?
            (reduxState && reduxState.scheme && reduxState.scheme.product) ?
              <></>
              :
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.fieldDiv}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      id="firstOrderToday"
                      name="firstOrderToday"
                      control={<CssCheckbox
                        classes={{
                          root: classes.checkboxRoot
                        }}
                        checked={values.firstOrderToday}
                        color="primary"
                        onChange={handleChange}
                      />}
                      label={""}
                      labelPlacement="end"
                    />
                    <Typography variant="subtitle2" style={{ display: "flex", alignItems: "center" }} className={[classes.defaultColor, classes.checkboxMargin].join(" ")}>
                      Start SIP Today
                      <CssTooltip title={<FirstOrderInfo classes={classes} />} arrow>
                        <InfoIcon className={classes.info} />
                      </CssTooltip>
                    </Typography>
                  </div>
                  {errors.firstOrderToday && touched.firstOrderToday && (<p className={classes.errorText}>{errors.firstOrderToday}</p>)}
                </FormControl>
              </Grid>
            :
            <></>
        }
        {
          values.type === "SIP" ?
            <>
              <Grid item xs={11}>
                <FormControl variant="outlined" className={classes.fieldDiv}>
                  <Typography className={classes.fieldText}>Frequency</Typography>
                  <CssSelect
                    labelId="demo-simple-select-filled-label"
                    id="frequency"
                    name="frequency"
                    value={values.frequency}
                    onChange={(e) => onFrequencyChange(e.target.value, formData)}
                    error={touched.frequency && errors.frequency ? true : false}
                    onKeyUp={() => setFieldTouched("frequency", true)}
                    className={classes.select}
                    input={<SelectOutlinedInput />}
                    variant="standard"
                    IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
                  >
                    {
                      formData && Object.keys(formData).length > 0 && Object.keys(formData).map((ele) => (
                        (ele === 'accountDetails' || ele === 'mandate') ? <></> : <MenuItem key={ele} value={ele} className={classes.menuItem}>{ele[0].toUpperCase() + ele.slice(1).toLowerCase()}</MenuItem>
                      ))
                    }
                  </CssSelect>
                  {errors.frequency && touched.frequency && (<p className={classes.errorText}>{errors.frequency}</p>)}
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl variant="outlined" className={classes.fieldDiv}>
                  <Typography className={classes.fieldText}>Start Date</Typography>
                  <CssSelect
                    labelId="demo-simple-select-filled-label"
                    id="startDate"
                    name="startDate"
                    value={values.startDate}
                    onChange={handleChange}
                    error={touched.startDate && errors.startDate ? true : false}
                    onKeyUp={() => setFieldTouched("startDate", true)}
                    className={classes.select}
                    input={<SelectOutlinedInput />}
                    variant="standard"
                    IconComponent={() => <Arrow />}
                  >

                    {
                      formData && formData[values.frequency] && formData[values.frequency][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"] &&
                      formData[values.frequency][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"].length > 0 &&
                      formData[values.frequency][values.firstOrderToday ? "sipStartDate" : "unCheckStartDate"].map((ele) => (
                        <MenuItem key={ele} value={ele} className={classes.menuItem}>{getDate(ele || "")}</MenuItem>
                      ))
                    }
                  </CssSelect>
                  {errors.startDate && touched.startDate && (<p className={classes.errorText}>{errors.startDate}</p>)}
                </FormControl>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <FormControl variant="outlined" className={classes.fieldDiv} style={{ marginBottom: 0 }}>
                  <Typography className={classes.fieldText}>No. of Installments</Typography>
                  <CssOutlinedInput
                    id="installments"
                    name="installments"
                    classes={{ input: classes.input }}
                    onChange={handleChange}
                    disabled={values.cancelled}
                    error={touched.installments && errors.installments ? true : false}
                    value={values.installments}
                    onKeyUp={() => setFieldTouched("installments", true)}
                  />
                  <Typography variant="caption" className={classes.belowText}>Minimum {formData && formData[values.frequency] && formData[values.frequency].minimumInstallmentNumbers}</Typography>
                  {errors.installments && touched.installments && (<p className={classes.errorText}>{errors.installments}</p>)}
                </FormControl>
              </Grid>
              <Grid item xs={10} /* style={{ marginTop: "8px", marginBottom: "4px" }} */>
                <FormControl variant="outlined">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      id="cancelled"
                      name="cancelled"
                      control={<CssCheckbox
                        classes={{
                          root: classes.checkboxRoot
                        }}
                        checked={values.cancelled}
                        color="primary"
                        onChange={handleChange}
                      />}
                      label={""}
                      labelPlacement="end"
                    />
                    <Typography variant="subtitle2" className={[classes.defaultColor, classes.checkboxMargin].join(" ")}>Until Cancelled</Typography>
                  </div>
                  {errors.cancelled && touched.cancelled && (<p className={classes.errorText}>{errors.cancelled}</p>)}
                </FormControl>
              </Grid>
            </>
            :
            values.type === "REDEEM" || values.type === "SWITCH" ?
              <>
                <Grid item xs={11}>
                  <FormControl variant="outlined" className={classes.fieldDiv}>
                    <Typography className={classes.fieldText}>Free Available Units</Typography>
                    <CssOutlinedInput
                      id="units"
                      name="units"
                      classes={{ input: classes.input }}
                      onChange={handleChange}
                      error={touched.units && errors.units ? true : false}
                      value={values.units}
                      disabled={true}
                      onKeyUp={() => setFieldTouched("units", true)}
                    />
                    {errors.units && touched.units && (<p className={classes.errorText}>{`Free Available ${errors.units}`}</p>)}
                  </FormControl>
                </Grid>
                <Grid item xs={11}>
                  <FormControl variant="outlined" className={classes.fieldDiv}>
                    <Typography className={classes.fieldText}>Total Units</Typography>
                    <CssOutlinedInput
                      id="valueTotalUnits"
                      name="valueTotalUnits"
                      classes={{ input: classes.input }}
                      onChange={handleChange}
                      disabled={true}
                      error={touched.valueTotalUnits && errors.valueTotalUnits ? true : false}
                      value={values.valueTotalUnits}
                      onKeyUp={() => setFieldTouched("valueTotalUnits", true)}
                    />
                    {errors.valueTotalUnits && touched.valueTotalUnits && (<p className={classes.errorText}>{errors.valueTotalUnits}</p>)}
                  </FormControl>
                </Grid>
                <Grid item xs={11}>
                  <FormControl variant="outlined" className={classes.fieldDiv}>
                    <Typography className={classes.fieldText}>Value of Total Units</Typography>
                    <CssOutlinedInput
                      id="valueRedemptionUnit"
                      name="valueRedemptionUnit"
                      classes={{ input: classes.input }}
                      onChange={handleChange}
                      disabled={true}
                      error={touched.valueRedemptionUnit && errors.valueRedemptionUnit ? true : false}
                      value={values.valueRedemptionUnit}
                      onKeyUp={() => setFieldTouched("valueRedemptionUnit", true)}
                    />
                    <Typography variant="caption" className={classes.belowText}>{`Based on latest: NAV of ${(reduxState && reduxState.scheme && reduxState.scheme.navValue) || 0} as on ${String(new Date().toLocaleString("en-IN", { dateStyle: "medium" })).split('-').join(' ')}`}</Typography>
                    {errors.valueRedemptionUnit && touched.valueRedemptionUnit && (<p className={classes.errorText}>{errors.valueRedemptionUnit}</p>)}
                  </FormControl>
                </Grid>
              </>
              :
              <></>
        }
      </Grid>
    )
  }

  const accountDetailsField = (accountData) => {
    return (
      <FormControl variant="outlined" className={classes.fieldDiv}>
        <Typography className={classes.fieldText}>Account Number</Typography>
        <CssSelect
          labelId="demo-simple-select-filled-label"
          id="accountDetails"
          name="accountDetails"
          value={values.accountDetails}
          onChange={(e) => onAccountChange(e.target.value, accountData, values.type === "OT" ? formData[0] : formData, values.type)}
          error={touched.accountDetails && errors.accountDetails ? true : false}
          onKeyUp={() => setFieldTouched("accountDetails", true)}
          className={classes.select}
          input={<Tooltip title={values.accountDetails} aria-label={values.accountDetails} className={""}><SelectOutlinedInput /></Tooltip>}
          variant="standard"
          IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
        >
          {
            accountData.map((ele) => (
              <MenuItem key={ele.accountNumber} value={ele.accountNumber} className={classes.menuItem}>{ele.bankName} ({ele.accountNumber})</MenuItem>
            ))
          }
        </CssSelect>
        {errors.accountDetails && touched.accountDetails && (<p className={classes.errorText}>{errors.accountDetails}</p>)}
      </FormControl>
    )
  }

  const paymentFields = () => {
    return <>
      <Grid item xs={11}>
        <FormControl variant="outlined" className={classes.fieldDiv}>
          <Typography className={classes.fieldText}>{values.type === "OT" ? "Payment Mode" : "First Order Payment"}</Typography>
          <RadioGroup
            row
            size="small"
            aria-labelledby="demo-row-radio-buttons-group-label"
            id="paymentMode"
            name="paymentMode"
            value={values.paymentMode}
            onChange={handleChange}
          >
            <FormControlLabel classes={{ label: classes.radioLabel }} value="NETBANKING" control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label="Net Banking" />
            <FormControlLabel style={{ marginRight: 0 }} classes={{ label: classes.radioLabel }} value="UPI" control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label={<SvgComponent iconname={"UPI"} className={classes.svg} />} />
            {values.type === "SIP" && values.firstOrderToday ?
              <></>
              :
              <FormControlLabel classes={{ label: classes.radioLabel }}
                disabled={(values.type === "SIP" ? formData?.mandate?.length === 0 : getMandateData(values, formData).length === 0)}
                value="MANDATE" control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label="BSE Mandate" />}
          </RadioGroup>
          {errors.paymentMode && touched.paymentMode && (<p className={classes.errorText}>{errors.paymentMode}</p>)}
        </FormControl>
      </Grid>
    </>
  }

  const rightFormFields = () => {
    return (
      (values.type === "SIP" || values.type === "OT") ?
        <Grid container className={classes.gridMargin}>
          {(values.firstOrderToday || values.type === "OT") && paymentFields()}
          <Grid item xs={11}>
            {accountDetailsField(values.type === "OT" ? formData[0]?.accountDetails : formData.accountDetails)}
          </Grid>
          <Grid item xs={11}>
            {
              (getMandateData(values, formData)?.length === 0 && ((values.type === "SIP" && !values.firstOrderToday) || values.type === "OT")) ?
                values.type === "OT" ?
                  <></>
                  :
                  <div>
                    <Button variant="contained" className={classes.mandateButton} onClick={addBankMandate}>Add Bank Mandate</Button>
                  </div>
                :
                (values.type === "OT" && values.paymentMode !== "MANDATE") ?
                  <></>
                  :
                  <FormControl variant="outlined" className={classes.fieldDiv}>
                    <Typography className={classes.fieldText}>Bank Mandate</Typography>
                    <CssSelect
                      labelId="demo-simple-select-filled-label"
                      id="bankMandate"
                      name="bankMandate"
                      value={values.bankMandate}
                      onChange={handleChange}
                      error={touched.bankMandate && errors.bankMandate ? true : false}
                      onKeyUp={() => setFieldTouched("bankMandate", true)}
                      className={classes.select}
                      input={<Tooltip title={values.bankMandate} aria-label={values.mandateCode} className={""}><SelectOutlinedInput /></Tooltip>}
                      variant="standard"
                      IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
                    >
                      {
                        getMandateData(values, formData).map((ele) => (
                          <MenuItem key={ele.mandateCode} value={ele.mandateCode} className={classes.menuItem}>{`${ele.bankName} (${ele.mandateCode}) - ${ele.status ? 'Approved' : 'Pending'}`}</MenuItem>
                        ))
                      }
                      {values.type == "SIP" && values.firstOrderToday && <MenuItem key={"new"} value={"new"} className={classes.menuItem}>Create new mandate</MenuItem>}
                    </CssSelect>
                    {errors.bankMandate && touched.bankMandate && (<p className={classes.errorText}>{errors.bankMandate}</p>)}
                  </FormControl>
            }
          </Grid>
          {
            (values.firstOrderToday || values.type === "OT") && values.paymentMode === "UPI" && <Grid item xs={11}>
              <FormControl variant="outlined" className={classes.fieldDiv}>
                <Typography className={classes.fieldText}>Enter your UPI Id</Typography>
                <CssOutlinedInput
                  id="upiId"
                  name="upiId"
                  classes={{ input: classes.input }}
                  onChange={handleChange}
                  error={touched.upiId && errors.upiId ? true : false}
                  value={values.upiId}
                  onKeyUp={() => setFieldTouched("upiId", true)}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
                <Typography variant="caption" className={classes.belowText}>
                  Kindly use the UPI Id associated with the selected bank. Else payment will fail
                </Typography>
                {errors.upiId && touched.upiId && (<p className={classes.errorText}>{errors.upiId}</p>)}
              </FormControl>
            </Grid>
          }
        </Grid>
        :
        (values.type === "REDEEM" || values.type === "SWITCH") ?
          <Grid container className={classes.gridMargin}>
            <Grid item xs={11}>
              {
                typeTabs.map((ele, i) => (
                  <Button
                    key={i}
                    onClick={() => setOrderType(ele.value)}
                    className={[classes.defaultButton, values.subType === ele.value ? "activeRedeem" : ""].join(" ")}
                    style={i === 2 ? { marginRight: "0px" } : {}}
                  >
                    {ele.label || ""}
                  </Button>
                ))
              }
            </Grid>
            <Grid item xs={11}>
              <FormControl variant="outlined" className={classes.fieldDiv}>
                <Typography className={classes.fieldText}>
                  {values.type === "REDEEM" ? "Redeem" : "Switch"} {/* values.subType === "units" ? */ "Units"/*  : values.subType === "amount" ? "Amount" : "Amount / Units" */}
                </Typography>
                <CssOutlinedInput
                  id={values.type === "REDEEM" ? "redeemAmount" : "switchAmount"}
                  name={values.type === "REDEEM" ? "redeemAmount" : "switchAmount"}
                  classes={{ input: classes.input }}
                  onChange={handleChange}
                  disabled={values.subType === "all"}
                  error={values.type === "REDEEM" ? touched.redeemAmount && errors.redeemAmount ? true : false : touched.switchAmount && errors.switchAmount ? true : false}
                  value={values.type === "REDEEM" ? values.redeemAmount : values.switchAmount}
                  onKeyUp={() => setFieldTouched(values.type === "REDEEM" ? "redeemAmount" : "switchAmount", true)}
                />
                {values.type === "SWITCH" && <Typography variant="caption" className={classes.belowText}>
                  Minimum {/* values.subType === "amount" ?
                    `₹ ${reduxState.scheme[values.type === "REDEEM" ? "minimumRedeemAmount" : "minimumSwitchAmount"]}`
                    : */
                    `${reduxState.scheme[/* values.type === "REDEEM" ? "minimumRedeemUnits" :  */"minimumSwitchUnits"] || '--'} units`
                  }
                </Typography>}

                {
                  values.type === "REDEEM" ?
                    <>
                      {errors.redeemAmount && touched.redeemAmount && (<p className={classes.errorText}>{errors.redeemAmount}</p>)}
                    </>
                    :
                    <>
                      {errors.switchAmount && touched.switchAmount && (<p className={classes.errorText}>{errors.switchAmount}</p>)}
                    </>
                }
              </FormControl>
            </Grid>
            {
              values.type === "SWITCH" ?
                <Grid item xs={11}>
                  <FormControl variant="outlined" className={classes.fieldDiv}>
                    <Typography className={classes.fieldText}>{"Switch To"}</Typography>
                    <CssOutlinedInput
                      id={"switchTo"}
                      name={"switchTo"}
                      classes={{ input: classes.input }}
                      onChange={onSwitchToChange}
                      error={touched.switchTo && errors.switchTo ? true : false}
                      value={(switchToScheme && switchToScheme.label) || ""}
                      // onKeyUp={() => setFieldTouched("switchTo", true)}
                      onKeyDown={onInputKeyDown}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      endAdornment={
                        schemeList === "Searching..." ?
                          <InputAdornment position="end" style={{ marginRight: -10 }}>
                            <CircularProgress color="inherit" size={16} />
                          </InputAdornment>
                          : Object.keys(switchToScheme).length < 2 ?
                            <InputAdornment style={{ marginRight: -10 }} position="end">
                              <Search disabled />
                            </InputAdornment>
                            :
                            <InputAdornment position="end">
                              {switchToScheme && switchToScheme.label && switchToScheme.label.length > 2 && <Close style={{ cursor: "pointer" }} onClick={onCancelSwitchScheme} />}
                            </InputAdornment>
                      }
                    />
                    {typeof (schemeList) !== "string" && schemeList.length > 0 && getPopper()}
                    {typeof (schemeList) === "string" && schemeList !== "Searching..." && <p className={classes.errorText}>{schemeList}</p>}
                    {errors.switchTo && touched.switchTo && (<p className={classes.errorText}>{errors.switchTo}</p>)}
                  </FormControl>
                </Grid>
                :
                <Grid item xs={11}>
                  {accountDetailsField(formData)}
                </Grid>
            }
          </Grid>
          :
          <></>
    )
  }

  const bottomInfoGrid = () => {
    return (
      <>
        <Grid item xs={12} className={classes.borderBottom}>
          {
            values.type === "REDEEM" || values.type === "SWITCH" ?
              <></>
              :
              <Typography className={[classes.defaultColor, classes.textPadding].join("  ")} variant="subtitle2">
                {/* As you are doing transaction on a holiday(today), your money will get invested on Monday(next working day) and you will get EOD's price(NAV). */}
                If you are doing transaction on a holiday,Your money will get invested on next working day and you will get next working EOD's price(NAV).
              </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <div className={classes.termsDiv}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  id="terms"
                  name="terms"
                  control={<CssCheckbox
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    color="primary"
                    checked={values.terms}
                    onChange={handleChange}
                  />}
                  label={""}
                  labelPlacement="end"
                />
                <Typography style={{ marginLeft: "-16px" }} variant="subtitle2" className={[classes.defaultColor, classes.checkboxMargin].join(" ")}>By continuing, you agree to &nbsp;
                  <span>
                    <Link
                      className={classes.link}
                      target="_blank"
                      href="/tnc/TnCMF.pdf"
                      rel="noopener"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                  &nbsp; of Tradebulls.
                </Typography>
              </div>
              {errors.terms && touched.terms && (<p className={classes.errorText}>{errors.terms}</p>)}
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5}>
              <Button className={[classes.order, classes.containedBlue].join(" ")} type="submit">{values.type === "SIP" ? "Start SIP" : values.type === "OT" ? "One-Time" : values.type === "REDEEM" ? "Redeem" : "Switch"}</Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const form = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} className={classes.gridBorder}>
              {leftFormFields()}
            </Grid>
            <Grid item xs={6}>
              {rightFormFields()}
            </Grid>
          </Grid>
        </Grid>
        {bottomInfoGrid()}
      </>
    )
  }

  return (
    <fieldset className={classes.fieldSet}>
      {getLegend()}
      <div className={classes.mainGrid}>
        {loading.loadFormData ? getLoadingDiv() : form()}
      </div >
    </fieldset >
  )
}

export default withStyles(combineStyles(styles, commonStyles))(OrderForm)
