export default ({ breakpoints }) => ({
  styleOverrides: {
    body: {
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '-0.04px',
      [breakpoints.down('xl')]: {
        fontSize: '10px',
        lineHeight: '12px'
      },
      [breakpoints.down('lg')]: {
        fontSize: '8px',
        lineHeight: '12px'
      },
    }
  }
});
