import React, { useEffect, useState } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@mui/styles';

// Material components
import { Tabs, Tab, Box, Paper } from '@mui/material';
import Activation from "widgets/profile/activation"
import Facility from "widgets/profile/facility"
import Bank from "widgets/profile/bank"
import Basic from "widgets/profile/basic"
import Brokerage from "widgets/profile/brokerage"
import TopInfo from "widgets/profile/topInfo"
import Links from "widgets/profile/links"
import styles from "./styles"
import axios from 'helpers/interceptor';
import Placeholder from 'components/Placeholder';

const CssTabs = withStyles(theme => ({
  root: {
    minHeight: "30px"
  },
  indicator: {
    backgroundColor: "#8DB1D2"
  },
}))(Tabs)

const tabs = ["BASIC", "BANK/DP", "BROKERAGE", "ACTIVATION", "FACILITY", "LINKS"]

function TabPanel(props) {
  const { children, value, index, isMaximized, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0, height: isMaximized ? "calc(100vh - 329px)" : "calc(100vh - 680px)", overflowY: !isMaximized && index === 0 ? "auto" : "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Account(props) {
  const { classes } = props;
  const [value, setValue] = useState(0)
  const [userProfile, setUserProfile] = useState("Loading...")

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/ms-trading-customer-profile/profile`).then(response => {
      if (response.data.success) {
        setUserProfile(response.data.success)
      } else {
        setUserProfile({})
      }
    }).catch(error => {
      setUserProfile({})
    })
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {
        userProfile === "Loading..." ?
          <div className={classes.loadingDiv}>
            <Placeholder
              loading={true}
              loadingMsg={"Loading..."}
              error={""}
            />
          </div>
          :
          <>
            <Paper elevation={2} className={classes.topPaper}>
              <TopInfo
                basicInfo={userProfile && userProfile.basic && userProfile.basic.basicInfo || {}}
                isMaximized={true}
                clientName={userProfile && userProfile.basic && userProfile.basic.basicInfo && userProfile.basic.basicInfo.client && userProfile.basic.basicInfo.client.split("-")[1].trim()}
                clientCode={userProfile && userProfile.basic && userProfile.basic.basicInfo && userProfile.basic.basicInfo.client && userProfile.basic.basicInfo.client.split("-")[0].trim()}
                account={true}
                showChangePassword={true}
              />
            </Paper>
            <div style={{ width: "100%" }}>
              <Paper elevation={2} className={classes.paper}>
                <CssTabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  {
                    tabs.map((ele, index) => (
                      <Tab className={value === index ? classes.activeTab : classes.tab} label={ele} {...a11yProps(index)} />
                    ))
                  }
                </CssTabs>
                <TabPanel value={value} index={0} isMaximized={true}>
                  <Basic
                    account={true}
                    value={value}
                    clientName={userProfile && userProfile.basic && userProfile.basic.basicInfo && userProfile.basic.basicInfo.client && userProfile.basic.basicInfo.client.split("-")[1].trim()}
                    isMaximized={true}
                    basicInfo={userProfile && userProfile.basic && userProfile.basic.basicInfo || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} isMaximized={true}>
                  <Bank
                    isMaximized={true}
                    bankdp={userProfile && userProfile.bankdp || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} isMaximized={true}>
                  <Brokerage
                    isMaximized={true}
                    brokerage={userProfile && userProfile.brokerage || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} isMaximized={true}>
                  <Activation
                    isMaximized={true}
                    activation={userProfile && userProfile.activation || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={4} isMaximized={true}>
                  <Facility
                    isMaximized={true}
                    facility={userProfile && userProfile.facility || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={5} isMaximized={true}>
                  <Links
                    isMaximized={true}
                  />
                </TabPanel>
              </Paper>
            </div>
          </>
      }
    </div>
  )
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
