export default ({ palette, breakpoints }) => ({
  styleOverrides: {
    root: {
      padding: '0px 20px',
      fontSize: '14px',
      height: '30px',
      color: palette.text.secondary,
      border: '1px solid rgba(255, 255, 255, 0.12)',
      '&:selected': {
        color: 'white'
      },
      [breakpoints.down('xl')]: {
        padding: '0px 16px',
        fontSize: '12px',
        height: '28px'
      },
      [breakpoints.down('md')]: {
        padding: '4px 12px',
        fontSize: '10px',
        height: '26px'
      }
    },
    sizeSmall: {
      padding: '4px 12px',
      fontSize: '13px',
      height: '26px',
      [breakpoints.down('xl')]: {
        padding: '4px 12px',
        fontSize: '10px',
        height: '24px'
      },
      [breakpoints.down('md')]: {
        padding: '4px 8px',
        fontSize: '8px',
        height: '22px'
      }
    }
  }
});
