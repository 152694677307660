export default (theme) => ({
  root: {
    padding: '8px 0px 12px 0px'
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  heading: {
    color: '#ddd7d7'
  },
  mainGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 8
  },
  chargesGrid: {
    marginBottom: 8
  },
  gridBorder: {
    border: `1px solid ${theme.palette.color5.default}`,
    padding: '16px 12px',
    borderRadius: 4
  },
  content: {
    backgroundColor: theme.palette.color17.default,
    padding: '20px !important',
    marginTop: '25px',
    width: 350,
    [theme.breakpoints.down('xl')]: {
      padding: '16px !important',
      width: 300,
    },
    [theme.breakpoints.down('md')]: {
      width: 250,
    }
  },
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: '0px',
    height: '25px',
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: '#497CAC',
    fontWeight: 600,
    lineHeight: '24px'
  },
  closeIcon: {
    float: 'right',
    height: '25px',
    marginRight: theme.spacing(1),
    padding: 0
  },
  contentGrid: {
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    padding: '16px 12px',
    [theme.breakpoints.down('xl')]: {
      padding: '12px 8px'
    }
  },
  gridItem: {
    padding: '0px 8px 20px 8px',
    [theme.breakpoints.down('xl')]: {
      padding: '0px 8px 16px 8px'
    }
  },
  totalGrid: {
    backgroundColor: theme.palette.background.tableHead,
    borderRadius: 4,
    padding: '6px 8px'
  },
  orderButton: {
    width: '100%',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: theme.palette.common.blue,
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    },
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    }
  }
});
