export default ({ breakpoints }) => ({
  styleOverrides: {
    root: {
      [breakpoints.down('xl')]: {
        fontSize: '12px',
        padding: '4px 8px',
        minWidth: '56px'
      },
      [breakpoints.down('md')]: {
        fontSize: '10px',
        padding: '4px 6px',
        minWidth: '48px'
      }
    },
    containedPrimary: {
      backgroundColor: '#299D00',
      [breakpoints.down('xl')]: {
        fontSize: '12px',
        padding: '4px 8px',
        minWidth: '56px'
      },
      [breakpoints.down('md')]: {
        fontSize: '10px',
        padding: '4px 6px',
        minWidth: '48px'
      },
      '&:hover': {
        backgroundColor: '#299D0080'
      }
    },
    containedSecondary: {
      backgroundColor: '#F54040',
      [breakpoints.down('xl')]: {
        fontSize: '12px',
        padding: '4px 8px',
        minWidth: '56px'
      },
      [breakpoints.down('md')]: {
        fontSize: '10px',
        padding: '4px 6px',
        minWidth: '48px'
      },
      '&:hover': {
        backgroundColor: '#F5404080'
      }
    },
    outlinedPrimary: {
      color: '#299D00',
      border: '1px solid #299D00',
      '&:hover': {
        border: '1px solid #299D0080',
        backgroundColor: '#299D0012'
      }
    },
    outlined: {
      [breakpoints.down('xl')]: {
        fontSize: '12px',
        padding: '4px 8px',
        minWidth: '56px'
      },
      [breakpoints.down('md')]: {
        fontSize: '10px',
        padding: '4px 6px',
        minWidth: '48px'
      }
    },
    outlinedSecondary: {
      color: '#F54040',
      border: '1px solid #F54040',
      '&:hover': {
        border: '1px solid #F5404080',
        backgroundColor: '#F5404012'
      }
    },
    textPrimary: {
      color: '#299D00',
      '&:hover': {
        backgroundColor: '#299D0012'
      }
    },
    textSecondary: {
      color: '#F54040',
      '&:hover': {
        backgroundColor: '#F5404012'
      }
    },
    containedSizeSmall: {
      [breakpoints.down('xl')]: {
        fontSize: '12px',
        padding: '4px 8px',
        minWidth: '56px'
      },
      [breakpoints.down('md')]: {
        fontSize: '10px',
        padding: '2px 6px',
        minWidth: '48px'
      }
    }
  }
});
