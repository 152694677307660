import { KeyboardArrowDown as Arrow } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, Grid, Link, MenuItem, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import Placeholder from "components/Placeholder";
import { useFormik } from "formik";
import axios from "helpers/interceptor";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { closeConfirmDialog, openConfirmDialog } from "redux/ConfirmDialog";
import { CssSelect, SelectOutlinedInput } from "widgets/order/customfields";
import * as Yup from "yup";
import styles from './styles';

const URL = {
  "mandate": "/ms-mf-bankmandate/mandate/create",
  "accountDetails": "/ms-mf-bankmandate/mandate/getClientAccountDetails"
}

function AddMandate(props) {

  const { classes, closeConfirmDialog, openConfirmDialog, onCancel } = props

  const [loading, setLoading] = useState({ load: true, error: "Loading...", submitting: false })
  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}${URL.accountDetails}`).then(resp => {
      if (resp.data.success?.length > 0) {
        setAccountData(resp.data.success)
        onAccountChange(resp.data.success[0].accountNumber, resp.data.success)
      } else {
        setAccountData([])
      }
      setLoading({ submitting: false, load: false, error: "" })
    }).catch(error => {
      setLoading({ submitting: false, load: false, error: "" })
    })
  }, [])

  let schema = Yup.object().shape({
    accountDetails: Yup.string()
      .required("Please select account number")
  })

  const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
    initialValues: {
      accountDetails: ""
    },
    validationSchema: schema,
    onSubmit: values => {
      createMandate()
    }
  })

  function onAccountChange(value, data) {
    let account = (data.length > 0 && data.find(ele => ele.accountNumber == value)) || {};
    setFieldValue("accountDetails", value)
    setFieldValue("ifscCode", account.ifscCode || '')
  }

  function createMandate() {
    setLoading({ submitting: true, load: false, error: "" })
    let obj = {
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: closeConfirmDialog
    }
    let payload = {
      accountNumber: values.accountDetails || '',
      ifscCode: values.ifscCode || ''
    }
    axios.post(`${process.env.REACT_APP_API_URL}${URL.mandate}`, payload).then(resp => {
      onCancel()
      if (resp.data.success) {
        obj.content = "You will receive a link from BSE Star on your registered email/SMS to create the e-mandate.\n Request you to complete the registration for hassle free SIP investments."
        openConfirmDialog(obj)
      } else {
        obj.content = resp.data.error || "Error while creating mandate, Please try again after some time."
        openConfirmDialog(obj)
      }
    }).catch(error => {
      obj.content = error.message || "Error while creating mandate, Please try again after some time."
      openConfirmDialog(obj)
    })
  }

  const accountDetailsField = () => {
    return (
      <Grid item xs={9}>
        <FormControl variant="outlined" className={classes.fieldDiv}>
          <Typography className={classes.fieldText}>Account Number</Typography>
          <CssSelect
            labelId="demo-simple-select-filled-label"
            id="accountDetails"
            name="accountDetails"
            value={values.accountDetails}
            onChange={(e) => onAccountChange(e.target.value, accountData)}
            error={touched.accountDetails && errors.accountDetails ? true : false}
            onKeyUp={() => setFieldTouched("accountDetails", true)}
            className={classes.select}
            input={<Tooltip title={values.accountDetails} aria-label={values.accountDetails} className={""}><SelectOutlinedInput /></Tooltip>}
            variant="standard"
            IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
          >
            {
              accountData.map((ele) => (
                <MenuItem key={ele.accountNumber} value={ele.accountNumber} className={classes.menuItem}>{ele.bankName} ({ele.accountNumber})</MenuItem>
              ))
            }
          </CssSelect>
          {errors.accountDetails && touched.accountDetails && (<p className={classes.errorText}>{errors.accountDetails}</p>)}
        </FormControl>
      </Grid>
    )
  }

  const form = () => {
    return (
      <form autoComplete={"off"} onSubmit={handleSubmit}>
        <Grid container>
          {loading && loading.showError && <div className={classes.errorDiv} style={{ width: '100%' }}><Typography>{loading.error}</Typography></div>}
          <Grid item xs={12} className={classes.convertFormFrid}>
            <Grid container>
              {accountDetailsField()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Button type="submit" disabled={loading && loading.submitting ? true : false} className={classes.button} autoFocus={true}>
                  {loading && loading.submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}
                  Create Mandate
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.defaultColor}>* Check the list of banks supporting e-Mandates on &nbsp;
              <span>
                <Link
                  className={classes.link}
                  target="_blank"
                  href="https://www.npci.org.in/PDF/nach/live-members-e-mandates/Live-Banks-in-API-E-Mandate.pdf"
                >
                  NPCI's website
                </Link>
              </span>
              &nbsp;
            </Typography>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <div className={classes.form}>
      {
        loading.load ?
          <div className={classes.loadingDiv}>
            <Placeholder
              loading={loading.load}
              loadingMsg={""}
              error={loading.load ? "" : loading.error}
            />
          </div>
          :
          form()
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return { row: state.dialog.data }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddMandate))