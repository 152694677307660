import React, { useContext, useState } from "react";
import { FormControl, FormControlLabel, Link, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import styles from "./styles";
import Table from "components/MaterialTable";
import { CssSwitch } from "widgets/order/customfields";
import { UserProfileContext } from "Main";
import Otppopup from "./otppopup";

const links = {
  columns: [
    {
      columnKey: "exchange",
      label: "Exchange"
    },
    {
      columnKey: "link",
      label: "Link",
      accessor: (row) => (
        <Link href={row.link} target="_blank">{row.link}</Link>
      )
    }
  ],
  filterdata: [
    {
      exchange: "SEBI Scores- INDIA",
      link: "https://scores.gov.in/scores/Welcome.html"
    },
    {
      exchange: "NSE - INDIA",
      link: "https://www.nseindia.com"
    },
    {
      exchange: "BSE - INDIA",
      link: "https://www.bseindia.com"
    },
    {
      exchange: "MCX - INDIA",
      link: "https://www.mcxindia.com"
    },
    {
      exchange: "NCDEX - INDIA",
      link: "https://www.ncdex.com/"
    },
  ]
}

const USER = `${process.env.REACT_APP_USER}`;

function Links(props) {
  const { /* isMaximized,  */ classes } = props;

  const profile = useContext(UserProfileContext)

  const [freezeTrading, setFreezeTrading] = useState(profile?.isFreezeTrading || false)
  const [freezeTradingPopup, setFreezeTradingPopup] = useState(false)

  return (
    // isMaximized ?
    <>
      <div className={classes.tabPanel}>
        <div className={classes.spaceContent}>
          <Typography variant="h3" style={{ marginBottom: 0 }}>IMPORTANT LINKS</Typography>
          {USER === "client" && <div className={classes.centerContent}>
            <Typography variant="h6" style={{ paddingRight: 12 }}>{profile?.isFreezeTrading ? 'Unfreeze' : 'Freeze'} Trading</Typography>
            <FormControl variant="outlined">
              <FormControlLabel
                id="freezeTrading"
                name="freezeTrading"
                control={
                  <CssSwitch
                    checked={freezeTrading}
                    onChange={() => setFreezeTradingPopup(!freezeTradingPopup)}
                    className={classes.switchIcon}
                  />
                }
                label=""
                classes={{ root: classes.switchField, disabled: classes.switchDisabled }}
              />
            </FormControl>
          </div>}
        </div>
        <Table
          maxHeight={450}
          data={links.filterdata || []}
          columns={links.columns || []}
        />
      </div>
      {
        freezeTradingPopup && <Otppopup
          profile={profile}
          setFreezeTrading={setFreezeTrading}
          freezeTradingPopup={freezeTradingPopup}
          setFreezeTradingPopup={setFreezeTradingPopup}
        />
      }
    </>
    // :
    // <div className={classes.noData} style={{ height: "285px" }}>
    //   No data found
    // </div>
  )
}

export default withStyles(styles)(Links)