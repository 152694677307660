export default (theme) => ({
  heading: {
    paddingBottom: '20px',
    color: theme.palette.common.blue,
    fontSize: '24px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
      marginTop: '4px',
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginTop: '0px',
      paddingBottom: '8px'
    }
  },
  closeIcon: {
    float: 'right',
    height: '25px',
    marginRight: theme.spacing(1),
    padding: 0
  },
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: '0px',
    height: '25px',
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  headingText: {
    color: theme.palette.text.primary
  },
  content: {
    backgroundColor: theme.palette.background.default,
    marginTop: '25px',
    minHeight: '7vh',
    minWidth: '20vw',
    width: '100%',
    paddingTop: 20,
    '&.otp': {
      width: 600,
      paddingTop: 28,
      paddingBottom: 28
    }
  },
  actionGrid: {
    backgroundColor: theme.palette.background.default,
    paddingRight: 24
  },
  title: {
    marginLeft: theme.spacing(1),
    color: '#497CAC',
    fontWeight: 600,
    lineHeight: '24px'
  },
  pText: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    textAlign: 'justify',
    color: theme.palette.color15.default,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      lineHeight: '18px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '12px'
    }
  },
  button: {
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    }
  },
  input: {
    padding: 8
  },
  inputFieldDiv: {
    marginTop: 20,
    marginRight: 16
  },
  errorDiv: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '1px solid #f5c6cb',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '4px',
    textAlign: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('lg')]: {
      padding: '3px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px'
    }
  },
  errorText: {
    color: '#f54040',
    marginTop: 8,
    textAlign: 'center'
  },
  numericTextBoxPadding: {
    padding: '40px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '34px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '34px 0px 28px 0px'
    }
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  resendDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
