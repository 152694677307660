import { TextField, InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function Filter(props) {
  const { classes, column } = props;
  const columnFilterValue = column.getFilterValue();

  return (
    <TextField
      type="text"
      variant='outlined'
      fullWidth
      placeholder="All"
      autoFocus={column?.columnDef?.autoFocus || false}
      value={(columnFilterValue ?? '')}
      onChange={e => column.setFilterValue(e.target.value || undefined)}
      className={classes.input}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <Close className={classes.icon} onClick={e => column.setFilterValue(undefined)} />
        </InputAdornment>,
        classes: {
          adornedEnd: classes.adornedEnd
        }
      }}
    />
  )
}