export default (theme) => ({
    header: {
        backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
        padding: "0px",
        height: "25px",
        backgroundColor: theme.palette.background.default, //'#292e3e'
        position: 'absolute',
        left: 0,
        right: 0,
        overflow: 'hidden',
        borderBottom: '1px solid #cccccc24',
        cursor: 'move'
    },
    title: {
        marginLeft: theme.spacing(1),
        color: "#497CAC",
        // color: theme.palette.color4.default,
        fontWeight: 600
    },
    content: {
        marginTop: "25px",
        backgroundColor: theme.palette.background.WLselectedItem,
    },
    headerGrid: {
        display: "flex",
        alignItems: "center",
        height: "25px"
    },
    closeIcon: {
        float: "right",
        height: "25px",
        marginRight: theme.spacing(1),
        padding: 0
    }
})