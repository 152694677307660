import { Close as CloseIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import styles from './styles';

function Toast(props) {
    const { classes, message, type, status, toastStyle, title, dateTime, onAction, closeToast } = props;
    return (
        <div
            className={[classes.root, type || '',].join(' ')}
            style={toastStyle || {}}>
            <div className={classes.content}>
                <div className={classes.titleBar}>
                    <Typography
                        variant="h6"
                        className={[classes.title, type || '', status || ''].join(' ')}>
                        {title || 'Alert'}
                    </Typography>
                    <Typography
                        variant="h6"
                        className={[classes.dateTime, type || ''].join(' ')}>
                        {dateTime || ''}
                    </Typography>
                </div>
                <Typography
                    variant="h6"
                    onClick={onAction}
                    className={[classes.message, type || ''].join(' ')}>
                    {message}
                </Typography>
            </div>
            <div>
                <CloseIcon className={[classes.closeIcon, type || ''].join(' ')} onClick={closeToast ? closeToast : () => { }} />
            </div>
        </div>
    );
}

export default withStyles(styles)(Toast);
