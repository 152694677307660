export default ({ palette }) => ({
  styleOverrides: {
    root: {
      color: palette.common.muted
    },
    '&$focused': {
      color: '#b71c1c'
    }
  }
});
