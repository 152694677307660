export function getSchemeKey(scheme) {
    const { schemeID, schemeCode, shortCode } = scheme;
    return `item_${schemeID}_${schemeCode}_${shortCode}`;
}

export function getSchemePayload(scheme) {
    const { schemeID, schemeCode, shortCode } = scheme;
    return {
        schemeID,
        schemeCode,
        shortCode
    };
}
