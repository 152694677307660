import MuiAutocomplete from './MuiAutocomplete';
import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiCssBaseline from './MuiCssBaseline';
import MUIDataTable from './MUIDataTable';
import MUIDataTableBody from './MUIDataTableBody';
import MUIDataTableFilterList from './MUIDataTableFilterList';
import MUIDataTableHeadCell from './MUIDataTableHeadCell';
import MUIDataTableSelectCell from './MUIDataTableSelectCell';
import MUIDataTableToolbarSelect from './MUIDataTableToolbarSelect';
import MuiDialog from './MuiDialog';
import MuiDialogContent from './MuiDialogContent';
import MuiDivider from './MuiDivider';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiFormLabel from './MuiFormLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiLink from './MuiLink';
import MuiList from './MuiList';
import MuiListItem from './MuiListItem';
import MuiListItemIcon from './MuiListItemIcon';
import MuiListItemText from './MuiListItemText';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiPopover from './MuiPopover';
import MuiRating from './MuiRating';
import MuiSelect from './MuiSelect';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiToggleButton from './MuiToggleButton';
import MuiToolbar from './MuiToolbar';
// import MuiTypography from './MuiTypography';

export default (baseTheme) => {
  return {
    MuiAutocomplete: MuiAutocomplete(baseTheme),
    MuiButton: MuiButton(baseTheme),
    MuiChip: MuiChip(baseTheme),
    MuiCssBaseline: MuiCssBaseline(baseTheme),
    MUIDataTable: MUIDataTable(baseTheme),
    MUIDataTableBody: MUIDataTableBody(baseTheme),
    MUIDataTableFilterList: MUIDataTableFilterList(baseTheme),
    MUIDataTableHeadCell: MUIDataTableHeadCell(baseTheme),
    MUIDataTableSelectCell: MUIDataTableSelectCell(baseTheme),
    MUIDataTableToolbarSelect: MUIDataTableToolbarSelect(baseTheme),
    MuiDialog: MuiDialog(baseTheme),
    MuiDialogContent: MuiDialogContent(baseTheme),
    MuiDivider: MuiDivider(baseTheme),
    MuiFab: MuiFab(baseTheme),
    MuiFilledInput: MuiFilledInput(baseTheme),
    MuiFormLabel: MuiFormLabel(baseTheme),
    MuiIconButton: MuiIconButton(baseTheme),
    MuiInputBase: MuiInputBase(baseTheme),
    MuiInputAdornment: MuiInputAdornment(baseTheme),
    MuiLinearProgress: MuiLinearProgress(baseTheme),
    MuiLink: MuiLink(baseTheme),
    MuiList: MuiList(baseTheme),
    MuiListItem: MuiListItem(baseTheme),
    MuiListItemIcon: MuiListItemIcon(baseTheme),
    MuiListItemText: MuiListItemText(baseTheme),
    MuiMenuItem: MuiMenuItem(baseTheme),
    MuiOutlinedInput: MuiOutlinedInput(baseTheme),
    MuiPaper: MuiPaper(baseTheme),
    MuiPopover: MuiPopover(baseTheme),
    MuiRating: MuiRating(baseTheme),
    MuiSelect: MuiSelect(baseTheme),
    MuiSvgIcon: MuiSvgIcon(baseTheme),
    MuiTab: MuiTab(baseTheme),
    MuiTableCell: MuiTableCell(baseTheme),
    MuiTableRow: MuiTableRow(baseTheme),
    MuiTabs: MuiTabs(baseTheme),
    MuiToggleButton: MuiToggleButton(baseTheme),
    MuiToolbar: MuiToolbar(baseTheme),
    // MuiTypography: MuiTypography(baseTheme)
  };
};
