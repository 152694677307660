export default theme => ({
    containedBlue: {
        color: '#FFF',
        backgroundColor: theme.palette.common.blue,
        '&:hover': {
            backgroundColor: theme.palette.common.blue + 80
        }
    },
    outlinedBlue: {
        color: theme.palette.common.blue,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.common.blue}`,
        '&:hover': {
            border: `1px solid ${theme.palette.common.blue}80`,
            backgroundColor: theme.palette.common.blue + 12
        }
    },
    textBlue: {
        color: theme.palette.common.blue + 80,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.common.blue + 12
        }
    },
    iconBtn: {
        fontSize: 18,
        height: theme.spacing(4),
        width: theme.spacing(4),
        [theme.breakpoints.down('xl')]: {
            height: "26px",
            width: "26px",
            fontSize: 16
        },
        [theme.breakpoints.down('lg')]: {
            height: "24px",
            width: "24px",
            fontSize: 14
        },
        [theme.breakpoints.down('md')]: {
            height: "22px",
            width: "22px",
            fontSize: 12
        },
        '&:disabled': {
            color: '#ffffff4d',
            border: '1px solid #ffffff4d'
        }
    },
    iconBtnPrimary: {
        color: "#fff",
        backgroundColor: '#299D00',
        border: `1px solid #299D00`,
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#299D0080',
        },
    },
    iconBtnSecondary: {
        color: "#fff",
        backgroundColor: '#F54040',
        border: `1px solid #F54040`,
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#F5404080',
        },
    },
    iconBtnBlue: {
        color: theme.palette.common.blue,
        border: `1px solid ${theme.palette.common.blue}`,
        borderRadius: 5,
        "&:hover": {
            backgroundColor: theme.palette.common.blue + 12,
        },
    },
    iconBtnGrey: {
        color: theme.palette.common.muted,
        border: `1px solid ${theme.palette.common.muted}`,
        borderRadius: 5,
        "&:hover": {
            backgroundColor: theme.palette.common.muted + 12,
        },
    },
});
