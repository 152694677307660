export default ({ palette }) => ({
  styleOverrides: {
    select: {
      "&.MuiSelect-select": {
        paddingRight: '24px',
        minHeight: '1.1876em'
      }
    },
    standard: {
      // borderBottom: `1px solid ${palette.text.primary}`
    },
    outlined: {
      border: 'none'
    },
    icon: {
      color: palette.text.primary
    }
  }
});
