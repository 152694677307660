import React from 'react'
import Chart from 'react-apexcharts'

function DonutChart(props) {
  const { data, height, colors, labels } = props

  let options = {
    colors: colors,
    labels: labels,
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: true,
        blur: 3,
        opacity: 0.8
      },
      style: {
        fontSize: '15px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: "bold",
      },
      formatter: function (val, opts) {
        return ""
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: false,
              fontSize: '21px',
              color: undefined,
              offsetY: 0,
              formatter: function (val) {
                return val + '%'
              }
            }
          }
        }
      }
    },
    legend: {
      show: false,
      fontSize: '16px',
      horizontalAlign: 'right',
      itemMargin: {
        horizontal: 7,
        vertical: 0
      },
    },
    series: data,
    tooltip: {
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
      y: {
        formatter: undefined,
        formatter: function (leads) {
          return (leads && Number(leads).toFixed(2) || '0.00') + '%'
        },
      },
    },
    stroke: {
      width: 1
    }
  }

  return (
    <Chart
      // key={key}
      options={options}
      series={data}
      type="donut"
      height={height}
    />
  )
}

export default DonutChart
