export default (theme) => ({
  mainDiv: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  root: {
    flexGrow: 1,
    height: 'calc(100% - 16px)',
    margin: '8px 15px',
    // minWidth: 350,
    // minHeight: 250,
    // overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      margin: '6px 12px',
      height: 'calc(100% - 12px)'
    }
  },
  paper: {
    padding: 0,
    textAlign: 'center',
    color: theme.palette.color9.default,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '14px'
    }
  },
  grid: {
    height: '100%',
    alignContent: 'center',
    margin: 0,
    '&.gridHeight': {
      minHeight: '20vh',
      minWidth: '15vw',
      [theme.breakpoints.down('xl')]: {
        minHeight: '27vh',
        minWidth: '19vw'
      },
      [theme.breakpoints.down('lg')]: {
        minHeight: '26vh',
        minWidth: '21vw'
      },
      [theme.breakpoints.down('md')]: {
        minHeight: '19vh',
        minWidth: '26vw'
      }
    },
    '&.gridHeight1': {
      minHeight: '13vh',
      minWidth: '13vw',
      [theme.breakpoints.down('xl')]: {
        minHeight: '20vh',
        minWidth: '16vw'
      },
      [theme.breakpoints.down('lg')]: {
        minHeight: '20vh',
        minWidth: '20vw'
      },
      [theme.breakpoints.down('md')]: {
        minHeight: '12vh',
        minWidth: '22vw'
      }
    },
    '&.commGrid': {
      minHeight: '20vh',
      minWidth: '20vw',
      [theme.breakpoints.down('xl')]: {
        minHeight: '27vh',
        minWidth: '24vw'
      },
      [theme.breakpoints.down('lg')]: {
        minHeight: '26vh',
        minWidth: '28vw'
      },
      [theme.breakpoints.down('md')]: {
        minHeight: '19vh',
        minWidth: '34vw'
      }
    }
  },
  horizontalDivider: {
    borderBottom: `1px dashed ${theme.palette.color10.default}`
  },
  verticalDivider: {
    borderRight: `1px dashed ${theme.palette.color10.default}`
  },
  item: {
    padding: '12px 0 !important',
    [theme.breakpoints.down('xl')]: {
      padding: '6px 0 !important'
    }
  },
  highlight: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  text: {
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '14px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '12px'
    }
  },
  textMargin: {
    marginTop: '6px',
    [theme.breakpoints.down('xl')]: {
      marginTop: '4px'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2px'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '0px'
    }
  }
});
