import { Link } from '@mui/material';
import { withStyles } from '@mui/styles';
import { EventReciever } from 'components/Event';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = (theme) => ({
  clickable: {
    cursor: 'pointer',
    fontSize: '12px'
  },
  disabledCursor: {
    cursor: 'default',
    textDecoration: 'none',
    color: theme.palette.color15.default
  }
});

const OTP_EXPIRY = 50000;
let OTP_TIMEOUT_MS = 30000;
let OTP_TIMEOUT_S = OTP_TIMEOUT_MS / 1000;
let RESEND_LIMIT = -1;
let otpTimer = 0,
  resendCount = 0;
let timerId;

const otpSuccess = new EventReciever('OTP_SUCCESS', 'ResendButton');
const otpError = new EventReciever('OTP_ERROR', 'ResendButton');

function ResendButton(props) {
  const { classes, timeOut, resendLimit, onClick, onExceed } = props;

  useEffect(() => {
    startTimer();
    return () => {
      otpTimer = 0;
      resendCount = 0;
      clearInterval(timerId);
      timerId = null;
    };
  }, []);

  if (timeOut) {
    OTP_TIMEOUT_S = timeOut;
    OTP_TIMEOUT_MS = timeOut * 1000;
  }
  if (resendLimit) {
    RESEND_LIMIT = resendLimit;
  }
  const [otpRemaining, setOtpRemaining] = useState(OTP_TIMEOUT_S);
  const [enabled, setEnabled] = useState(false);

  const checkTimeout = () => {
    console.log('otpCounter', otpTimer);
    if (otpTimer < OTP_TIMEOUT_S) {
      otpTimer++;
      setOtpRemaining(OTP_TIMEOUT_S - otpTimer + 1);
      // otpCounter();
    } else {
      // otpTimer = 0;
      clearInterval(timerId);
      // timerId = null;
      setEnabled(true);
      setOtpRemaining(OTP_TIMEOUT_S);
      // setHandleResendOtpReq(true);
    }
  };

  const onResend = () => {
    if (RESEND_LIMIT == -1 || resendCount < RESEND_LIMIT) {
      // resendCount++;
      // otpTimer = 0;
      // timerId = null;
      // startTimer();
      setEnabled(false);
      onClick && onClick(onResendCallback);
    } else {
      onExceed && onExceed();
    }
  };

  otpSuccess.onEvent(() => {
    onResendCallback(true);
  });

  otpError.onEvent(() => {
    onResendCallback(false);
  });

  const onResendCallback = (success) => {
    if (RESEND_LIMIT == -1 || resendCount < RESEND_LIMIT) {
      success && resendCount++;
      otpTimer = 0;
      timerId = null;
      startTimer();
      setEnabled(false);
      // onClick && onClick();
    } else {
      onExceed && onExceed();
    }
  };

  const startTimer = () => {
    if (!timerId) {
      console.log('startTimer');
      checkTimeout();
      timerId = setInterval(checkTimeout, 1000);
    }
  };

  // startTimer();

  const getTime = (time) => {
    var m = Math.floor(time / 60);
    var s = time % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    return '' + m + ':' + s;
  };

  return (
    <>
      {enabled ? (
        // <a href="#" onClick={props.onClick}>Resend OTP</a>
        <Link className={classes.clickable} onClick={onResend}>
          Resend OTP
        </Link>
      ) : (
        <Link
          underline="none"
          color="textSecondary"
          className={classes.disabledCursor}>
          Resend OTP in {getTime(otpRemaining)}
          {/* s */}
        </Link>
      )}
    </>
  );
}

ResendButton.propTypes = {
  timeOut: PropTypes.number,
  resendLimit: PropTypes.number,
  onClick: PropTypes.func,
  onExceed: PropTypes.func
};

export default withStyles(useStyles)(ResendButton);