import { Close, KeyboardArrowRight as Arrow, Search } from "@mui/icons-material";
import { debounce, Grid, IconButton, Input, InputAdornment, List, MenuItem, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import ConfirmationDialog from 'components/ConfirmationDialog';
import Placeholder from 'components/Placeholder';
import axios from 'helpers/interceptor';
import { canCall } from "helpers/pollingHelper";
import { depthSchema, quotesSchema } from "helpers/schemaHelper";
import { getBseScripCode, getSegment, getUniqueKey, hasScripDataChanged, isSameScrip } from "helpers/scripHelper";
import { getSocketPayload } from "helpers/subscription";
import useInterval from 'helpers/useInterval';
import { WebsocketClientContext } from "Main";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { closeConfirmDialog, openConfirmDialog } from 'redux/ConfirmDialog';
import { feedService } from 'services/feed';
import { marketDepthService } from 'services/marketdepth';
import { PrefsService } from "services/prefs";
import MarketDepth from "widgets/marketdepth";
import Brokeragecharges from "./brokeragecharges";
import CompactOrderConfirm from "./CompactOrderConfirm";
import CompactOrderForm from "./CompactOrderForm";
import OrderHelp from './help';
import TermsAndConds from './intradayactivation';
import MTFActivation from './mtfactivation';
import OrderConfirm from './OrderConfirm';
import OrderForm from './OrderForm';
import OrderStates from './OrderHoc';
import styles from './styles';
import Top from "./top";
import SplitPopup from "./splitpopup";
import SplitProgressBar from "./splitprogressbar";

const USER = `${process.env.REACT_APP_USER}`
const REFRESH_INTERVAL = 1000;

function getPayload1(scrip, value, addToken) {
  const { symbol, scripName, segment, exchange, token } = scrip;
  return {
    symbol: segment === "EQ" ? symbol : scripName,
    segment,
    exchange: value ? value : exchange,
    token: addToken ? token : undefined
  };
}

export function getModifiedScrip(scrip, value) {
  const { segment, exchange, token, bseScripCode, shortCode } = scrip;
  const toReturn = {
    ...scrip,
    exchange: value ? value : exchange,
    token: (value && value === 'BSE') || (!value && exchange === 'BSE') ? bseScripCode : token,
    shortCode: value && segment === 'EQ' ? value === 'BSE' ? 'BCM' : 'NCM' : shortCode,
  }; return toReturn;
}

function OrderDialog(props) {
  const [scripList, setScripList] = useState([])
  const [scripName, setScripName] = useState("")
  const [showError, setShowError] = useState({ show: false, message: "" })
  const [compactView, setCompactView] = useState(false)
  const [helpPopup, setHelpPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState()
  const [rows, setRows] = useState({});
  const [approxMarginData, setApproxMarginData] = useState({})
  const [brokerageCharges, setBrokerageCharges] = useState({})

  const mounted = useRef();
  const socket = useContext(WebsocketClientContext);

  const { scrip, reduxState: { form }, pushOrder, loading, showExchange, selectClient, selectedClient, setSelectClient, setOrderType, handleExchangeChange, errorObj, onScripChange, setClientError, showClientError, classes, activeStep, handleBack, handleSubmit, setFieldValue, setFieldTouched, handleChange, values, touched, errors, termsPopup, setTermsPopup, setActiveStep, onScripDataChange, submitting, orderView, initializeSelectClient = { initializeSelectClient }, currentScrip, validityDateData, infoPopup, setInfoPopup, marketStatus, mtfPopup, setMtfPopup, onCancel, profile, showBrokerageCharges, setShowBrokerageCharges, splitPopup, closeSplitPopup, processSplitOrder } = props;
  const { lot, price, triggerPrice, quantity, product, orderType, type } = values
  const { ltp, segment, scripId, shortCode, marginPercentage, optionType, qtyMultiplier, qtyNumerator, qtyDenominator, mtfMargin, marketLot, instType, instrumentType } = scrip;

  useEffect(() => {
    if (PrefsService.getOrderView() === 2) {
      setCompactView(true)
    } else {
      setCompactView(false)
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  async function getBrokerageCharges() {
    let priceForBrokerage = 0;
    if (orderType === "LIMIT") {
      priceForBrokerage = price;
    } else if (orderType === "MKT") {
      priceForBrokerage = ltp;
    } else if (orderType === "SL") {
      priceForBrokerage = (Number(price) > Number(triggerPrice)) ? price : triggerPrice;
    }
    let payload = {
      shortCode: currentScrip?.shortCode || '',
      scripId: currentScrip?.scripId || '',
      ltp: priceForBrokerage,
      buySell: type,
      marketLot: currentScrip?.marketLot || '',
      ordQty: quantity,
      prdType: product === "MTF" ? "Delivery" : product,
      instType: currentScrip?.instType || currentScrip?.instrumentType || '',
      qtyNumerator: (currentScrip?.segment === "COMM" ? currentScrip?.qtyNumerator : currentScrip?.qtyMultiplier) || '1',
      qtyDenominator: currentScrip?.qtyDenominator || '1'
    };
    if (USER !== "client") {
      payload.clientCode = selectClient?.ClientCode || ''
    }
    if (USER !== "client" && Object.keys(selectClient).length === 0) {
      return null
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-margincalc/brokerage/detail`, payload).then(resp => {
      return { ...resp.data, price: priceForBrokerage }
    }).catch(error => {
      return {}
    })
  }

  useEffect(async () => {
    if (currentScrip && Object.keys(currentScrip).length > 0 && showBrokerageCharges) {
      if (brokerageCharges.scripId !== currentScrip.scripId || brokerageCharges.shortCode !== currentScrip.shortCode || brokerageCharges.orderType != orderType ||
        brokerageCharges.quantity != quantity || brokerageCharges.product != product || brokerageCharges.type != type || brokerageCharges.price != price ||
        (USER !== "client" && brokerageCharges.clientCode != selectClient?.ClientCode)) {
        let data = await getBrokerageCharges()
        if (data?.success) {
          setBrokerageCharges({ ...data.success, quantity, product, type, price: data.price, orderType, ...(USER !== "client" ? { clientCode: selectClient?.ClientCode } : {}) })
        } else {
          setBrokerageCharges({})
        }
      }
    }
  }, [product, quantity, type, price, orderType, showBrokerageCharges, currentScrip, selectClient])

  useEffect(() => {
    if (orderView === 2) {
      PrefsService.setOrderView(2)
      setCompactView(true)
    } else {
      PrefsService.setOrderView(1)
      setCompactView(false)
    }
  }, [orderView])

  useEffect(async () => {
    let data = await calculateApproxMargin()
    setApproxMarginData(data)
  }, [quantity, price, lot, triggerPrice, orderType, type, product]);

  async function handleRefreshApproxMargin() {
    let data = await calculateApproxMargin()
    setApproxMarginData(data)
  }

  async function marginPerLot() {
    if (shortCode && scripId) {
      return axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-admin/margin`, {
        shortCode: shortCode,
        scripId: scripId,
        flag: (type && String(type).toLocaleLowerCase()) || ""
      }).then(resp => {
        if (resp?.data?.success?.margin) {
          return Number(resp.data.success.margin)
        } else {
          return 0
        }
      }).catch(error => {
        return 0
      })
    }
  }

  async function calculateApproxMargin() {
    let value = 0, margin = 0, priceForMargin = 0;
    if (orderType === "LIMIT") {
      priceForMargin = price;
    } else if (orderType === "MKT") {
      priceForMargin = ltp;
    } else if (orderType === "SL") {
      priceForMargin = (Number(price) > Number(triggerPrice)) ? price : triggerPrice;
    }
    if (segment === "EQ") {
      value = (type === "S" && profile.isPOA) ? 0 : Number(quantity) * Number(priceForMargin)
      if (product === "Delivery") {
        margin = (type === "S" && profile.isPOA) ? 0 : value;
      } else {
        margin = (value * (product === "MTF" ? Number(mtfMargin) : Number(marginPercentage))) / 100
      }
    } else {
      if (optionType !== "XX" && type === "B") {
        if (segment === "CURR") {
          margin = Number(quantity) * Number(qtyMultiplier) * Number(priceForMargin)
        } else if (segment === "COMM") {
          margin = Number(quantity) * (Number(qtyNumerator) / Number(qtyDenominator)) * Number(priceForMargin)
        } else {
          margin = Number(quantity) * Number(priceForMargin)
        }
      } else {
        let perLotValue = await marginPerLot();
        margin = lot * perLotValue
      }
    }
    return {
      margin: Number(margin || 0).toFixed(2),
      multiple: Number((value / margin) || 0).toFixed(2)
    };
  }

  const searchSuccess = (list) => {
    setScripList(list)
    setShowError({ show: false, message: "" })
  }

  const handleSearch = (value) => {
    if (value && value.length > 1) {//TODO: 2-> 1
      setShowError({ show: true, message: "Searching..." })
      axios.post(`${process.env.REACT_APP_API_URL}/ms-web-search/search`, { text: value, segment: 'all', isTrading: true }).then(resp => {
        if (resp.data.success) {
          resp.data.success.length > 0 ? searchSuccess(resp.data.success) : setShowError({ show: true, message: "No Suggestion found" })
        } else {
          setShowError({ show: true, message: resp.data.error })
        }
      }).catch(error => {
        setShowError({ show: true, message: "Please try again later" })
        setScripList([])
      })
    } else if (value === '') {
      setScripList([]);
      setShowError({ show: false, message: "" })
    }
  }

  const debounceHandleSearch = useCallback(debounce(handleSearch, 1000), []);

  async function getScrip() {
    const feedScrips = await feedService.getFullFeed([getModifiedScrip(currentScrip, undefined)], currentScrip.exchange !== scrip.exchange ? false : true);
    if (feedScrips[0]?.error) return;
    let changedData = feedScrips ? feedScrips[0] : null;
    if (changedData && getUniqueKey(currentScrip) == getUniqueKey(changedData))
      onScripDataChange(changedData);
  }

  async function getDepth(noEmpty) {
    try {
      const depthData = await marketDepthService.getMarketDepth(getModifiedScrip(currentScrip, values.exchange));
      if (depthData.error) {
        setRows(depthData);
        return;
      }
      if (depthData) {
        setRows(depthData)
      } else {
        !noEmpty && setRows({})
      }
    } catch (error) {
      console.log(error)
      return;
    }
  }

  const onQuotes = data => {
    const msg = quotesSchema.decode(data);
    const feedData = msg;
    if (currentScrip.token === feedData.token && currentScrip.shortCode === feedData.shortCode) {
      if (hasScripDataChanged(feedData, currentScrip)) {
        if (mounted.current) {
          const updatedData = { ...currentScrip, ...feedData };
          onScripDataChange(updatedData);
        }
      }
    }
  };

  const onDepth = (data) => {
    const msg = depthSchema.decode(data);
    const depthData = data ? msg : {};
    if (isSameScrip(currentScrip, depthData)) {
      mounted.current && setRows(depthData);
    }
  };

  useEffect(() => {
    // if (socket.connected) {
    if (currentScrip && Object.keys(currentScrip).length > 0) {
      getScrip();
      !currentScrip.isMarketIndex && getDepth();
      if (canCall(currentScrip.shortCode) && socket.connected) {//Socket
        //Quotes
        const payload = getSocketPayload([currentScrip]);
        // const quotesPayload = getFormattedPayloadWatchlist([currentScrip]);
        socket.emit('feed:subscribe', {
          request: 'SUBSCRIBE',
          message: '',
          channel: 'feed',
          topic: 'popupQuotes',
          payload: payload,
        });
        socket.on('feed/popupQuotes', onQuotes);
        //Quotes
        //Depth
        // const depthPayload = [getPayload1(currentScrip, values.exchange, true)];
        if (!currentScrip.isMarketIndex) {
          socket.emit('feed:subscribe', {
            request: 'SUBSCRIBE',
            message: '',
            channel: 'feed',
            topic: 'popupDepth',
            payload: payload,
          });
          socket.on('feed/popupDepth', onDepth);
        }
        //Depth
        return () => {
          // Clean up the subscription
          socket.emit('feed:unsubscribe', {
            request: 'UNSUBSCRIBE',
            message: '',
            channel: 'feed',
            topic: 'popupQuotes',
            payload: payload,
          });
          socket.off('feed/popupQuotes', onQuotes);
          if (!currentScrip.isMarketIndex) {
            socket.emit('feed:unsubscribe', {
              request: 'UNSUBSCRIBE',
              message: '',
              channel: 'feed',
              topic: 'popupDepth',
              payload: payload,
            });
            socket.off('feed/popupDepth', onDepth);
          }
        };
      }//Socket
    }
    // }
  }, [currentScrip, socket.connected, marketStatus]);

  useInterval(() => {
    if (socket.disconnected) {
      if (currentScrip && Object.keys(currentScrip).length > 0 && canCall(currentScrip.shortCode)) {
        getScrip();
        !currentScrip.isMarketIndex && getDepth(true);
      }
    }
  }, REFRESH_INTERVAL);

  const scripSearch = () => {
    return (
      <>
        <Input
          id="search"
          name="search"
          autoComplete="off"
          value={scripName}
          style={{ width: "100%" }}
          placeholder={"Search"}
          onClick={e => setAnchorEl(e.currentTarget)}
          onChange={(e) => { setScripName(e.currentTarget.value); setShowError({ show: false, message: "" }); setScripList([]); debounceHandleSearch(e.currentTarget.value) }}
          endAdornment={scripName.length === 0 ?
            <InputAdornment position="end">
              <Search disabled />
            </InputAdornment>
            :
            <InputAdornment position="end">
              {scripName.length > 2 && <Close style={{ cursor: "pointer" }} onClick={() => { setScripName(""); setShowError({ show: false, message: "" }); setScripList([]) }} />}
            </InputAdornment>}
        />
        {showError.show && scripList.length === 0 && <Typography color="primary">{showError.message}</Typography>}
        {
          scripList.length > 0 && <Paper elevation={2}><List className={[classes.searchList, compactView ? 'compact' : ''].join(' ')} style={anchorEl ? { width: anchorEl.offsetWidth, } : {}}>
            {
              scripList.map((ele, index) => (
                <MenuItem key={index} tabIndex={0} className={classes.item} onClick={getUniqueKey(scrip) == getUniqueKey(ele) ? () => { setScripName(""); setScripList([]) } : () => { onScripChange(ele); /* onScripDataChange(ele); */ setScripName(ele.scripName); setScripList([]) }}>
                  <div>
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: "4px" }}
                      className={classes.itemText}>
                      {ele.scripName || ele.symbol}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={[classes.itemText, classes.itemSubText].join(" ")}>
                      {ele.exchange} <span style={{ paddingLeft: "4px", paddingRight: "4px" }}>{getSegment(ele)} </span>{getBseScripCode(ele)}
                    </Typography>
                  </div>
                </MenuItem>
              ))
            }
          </List></Paper>
        }
      </>
    )
  }

  const topGrid = () => {
    return (
      <Grid container>
        {
          compactView ?
            <Grid item xs={9}>
              <Grid container style={{ marginBottom: "8px" }}>
                <Grid item xs={12}>
                  <Top reduxForm={form || {}} selectedScrip={scrip || {}} activeStep={activeStep} compactView={compactView} values={values} handleExchangeChange={handleExchangeChange} showExchange={showExchange} />
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item md={6} sm={8} className={classes.top}>
              <Top reduxForm={form || {}} selectedScrip={scrip || {}} activeStep={activeStep} values={values} handleExchangeChange={handleExchangeChange} showExchange={showExchange} />
            </Grid>
        }
        <Grid item md={compactView ? 3 : 6} sm={compactView ? 3 : 4}>
          <IconButton className={classes.viewButton} variant="outlined" color="default" onClick={() => { setCompactView(!compactView); setShowBrokerageCharges(false); PrefsService.setOrderView(compactView ? 1 : 2); }}>{compactView ? "Classic View" : "Compact View"}<Arrow style={{ color: "#006CA8" }} /></IconButton>
          {
            showSearch() &&
            <Grid container className={classes.listContainer} style={compactView ? { marginBottom: "6px" } : {}}>
              <Grid item md={compactView ? 10 : 5} sm={12} className={classes.top} style={{ paddingRight: "0px", paddingTop: "0px" }}>
                {scripSearch()}
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }

  const orderForm = () => {
    return (
      compactView ?
        <Grid item xs={12}>
          <CompactOrderForm
            reduxForm={form || {}}
            scrip={scrip}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            setOrderType={setOrderType}
            classes={classes}
            showExchange={showExchange}
            handleExchangeChange={handleExchangeChange}
            showClientError={showClientError}
            selectedClient={selectedClient}
            setClientError={setClientError}
            selectClient={selectClient}
            termsPopup={termsPopup}
            setTermsPopup={setTermsPopup}
            setSelectClient={(data) => setSelectClient(data)}
            setInfoPopup={setInfoPopup}
            initializeSelectClient={initializeSelectClient}
            validityDateData={validityDateData}
            submitting={submitting}
            marketStatus={marketStatus}
            setHelpPopup={setHelpPopup}
            approxMarginData={approxMarginData}
            handleRefreshApproxMargin={handleRefreshApproxMargin}
            showBrokerageCharges={showBrokerageCharges}
            setShowBrokerageCharges={setShowBrokerageCharges}
            brokerageCharges={brokerageCharges}
            compactView={compactView}
          />
        </Grid>
        :
        <>
          <Grid item md={6} xs={12}>
            <OrderForm
              reduxForm={form || {}}
              scrip={scrip}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setOrderType={setOrderType}
              classes={classes}
              showClientError={showClientError}
              selectedClient={selectedClient}
              setClientError={setClientError}
              selectClient={selectClient}
              termsPopup={termsPopup}
              setTermsPopup={setTermsPopup}
              setSelectClient={(data) => setSelectClient(data)}
              setInfoPopup={setInfoPopup}
              initializeSelectClient={initializeSelectClient}
              validityDateData={validityDateData}
              submitting={submitting}
              marketStatus={marketStatus}
              setHelpPopup={setHelpPopup}
              approxMarginData={approxMarginData}
              handleRefreshApproxMargin={handleRefreshApproxMargin}
              showBrokerageCharges={showBrokerageCharges}
              setShowBrokerageCharges={setShowBrokerageCharges}
            />
          </Grid>
          <Grid item md={6} xs={12} className={[classes.fieldSet, classes.depthMargin].join(" ")}/*  style={{ margin: "17px 0px 10px 0px" }} */>
            <MarketDepth
              rows={rows}
              isIndex={Object.keys(rows).length > 0 ? false : true}
              depthError={Object.keys(rows).length > 0 ?
                rows.success ? "" : rows.error
                : 'Loading...'}
            />
          </Grid>
        </>
    )
  }

  const confirmGrid = () => {
    return (
      compactView ?
        <CompactOrderConfirm
          classes={classes}
          scrip={scrip}
          activeStep={activeStep}
          handleExchangeChange={handleExchangeChange}
          showExchange={showExchange}
          reduxForm={form || {}}
          values={values}
          pushOrder={pushOrder}
          handleBack={handleBack}
          selectedClient={selectedClient}
          selectClient={selectClient}
          compactView={compactView}
          submitting={submitting}
          splitPopup={splitPopup}
        />
        :
        <OrderConfirm
          classes={classes}
          scrip={scrip}
          activeStep={activeStep}
          handleExchangeChange={handleExchangeChange}
          showExchange={showExchange}
          reduxForm={form || {}}
          values={values}
          pushOrder={pushOrder}
          handleBack={handleBack}
          selectedClient={selectedClient}
          selectClient={selectClient}
          submitting={submitting}
          splitPopup={splitPopup}
        />
    )
  }

  const showSearch = () => {
    if (form.status === "Modify" || form.type === "sell" || form.type === "squareOff"/*  || form.type === "new" */) {
      return false
    } else {
      return true
    }
  }

  return (
    <form autoComplete={"off"} onSubmit={handleSubmit} className={compactView ? activeStep === 1 ? [classes.compactConfirmWidth, USER === "client" ? "client" : ""].join(" ") : [classes.compactFormWidth, USER === "client" ? "client" : ""].join(" ") : activeStep === 1 ? [classes.confirmWidth, USER === "client" ? "client" : ""].join(" ") : classes.formWidth} /* style={activeStep === 1 ? !compactView ? USER === "client" ? {} : {} : { marginBottom: "16px", width: USER !== "client" ? "1200px" : "1000px" } : compactView && USER === "client" ? { width: "1100px" } : {}} */>
      {
        loading.show ?
          <div className={[classes.loadingDiv, PrefsService.getOrderView() === 2 ? "compact" : ""].join(" ")}>
            <Placeholder
              loading={loading.error === "Loading..." ? true : false}
              loadingMsg={""}
              error={loading.error === "Loading..." ? "" : loading.error}
            />
          </div>
          :
          activeStep === 0 ?
            <>
              {
                infoPopup.show && <ConfirmationDialog
                  id="Information"
                  content={infoPopup.message}
                  centerActions={true}
                  hideCloseButton={true}
                  okButtonText={"Ok"}
                  open={infoPopup.show}
                  onClose={() => setInfoPopup({ show: false, message: "" })}
                />
              }
              <OrderHelp
                open={helpPopup}
                setOpen={setHelpPopup}
              />
              <TermsAndConds
                onClose={(value) => setTermsPopup({ show: false, value: value, step: termsPopup.step, submitted: termsPopup.submitted })}
                termsPopup={termsPopup}
                setTermsPopup={setTermsPopup}
                setFieldValue={setFieldValue}
                setActiveStep={setActiveStep}
                selectClient={selectClient}
              />
              <MTFActivation
                mtfPopup={mtfPopup}
                orderPopupClose={onCancel}
                onClose={() => setMtfPopup(false)}
              />
              <Grid container>
                <Grid item xs={12}>
                  {errorObj && errorObj.error && <div className={classes.errorDiv}><Typography className={classes.errorDivText}>{errorObj.message}</Typography></div>}
                  {loading && loading.searchError && compactView && <div className={classes.errorDiv}><Typography className={classes.errorDivText}>{loading.error}</Typography></div>}
                  {topGrid()}
                </Grid>
                {
                  loading && loading.searchError && !compactView ?
                    <div className={classes.searchErrorDiv}>
                      <Placeholder
                        loading={false}
                        loadingMsg={""}
                        error={loading.error}
                      />
                    </div>
                    :
                    orderForm()
                }
                {
                  showBrokerageCharges && <Brokeragecharges
                    compactView={compactView}
                    open={showBrokerageCharges}
                    onClose={() => setShowBrokerageCharges(false)}
                    brokerageCharges={brokerageCharges}
                  />
                }
                {
                  splitPopup && <SplitPopup
                    open={splitPopup.show}
                    onClose={closeSplitPopup}
                    splitPopup={splitPopup}
                    scrip={scrip}
                  />
                }
                {/* <Grid item xs={12}>
                  <div className={classes.infoDiv}>
                    <Typography className={classes.infoText}>
                      <span className={classes.infoTextBold}>After-Market Orders (AMO)</span>{' Timings 4.00pm till 8.50am next working day (Only Cash Segment)'}
                    </Typography>
                  </div>
                </Grid> */}
              </Grid>
            </>
            : activeStep === 1 ?
              <>
                {confirmGrid()}
                {
                  processSplitOrder?.show &&
                  <SplitProgressBar
                    processSplitOrder={processSplitOrder}
                  />
                }
              </>
              : null
      }
    </form>
  );
}


const mapStateToProps = (state) => {
  return { selectedScrip: state.common.selectedScrip, reduxState: state.dialog.data || { form: {}, scrip: {} }, selectedClient: (state.client && state.client.selectedClient) || null, orderView: state.common.orderView, marketStatus: state.common.marketStatus }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderStates(withStyles(combineStyles(styles, commonStyles))(OrderDialog)));
