import axios from 'helpers/interceptor';

const searchUrl = '/ms-web-search/search';
const searchUrlMF = '/ms-mf-fundsearch/fundbytext/';

export const SearchService = {
    getScripSearch,
    getSchemeSearch
};

function getScripSearch(key) {
    return axios
        .post(`${process.env.REACT_APP_API_URL}${searchUrl}`, {
            text: key,
            segment: 'all',
            isTrading: true
        })
        .then((response) => {
            // debugger;
            return response.data.success;
        })
        .catch((error) => {
            console.log('Scrip Search Error', error);
        });
}

function getSchemeSearch(key) {
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}${searchUrlMF}${key}/0/0`
    };
    
    return axios(options)
        .then((data) => {
            if (data.data.success) {
                if (data.data.success.result) {
                    return data.data.success.result;
                }
            }
            return [];
        })
        .catch((error) => {
            console.log('Scheme Search Error', error);
            return [];
        });
}
