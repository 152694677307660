import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Button, FormControl, FormControlLabel, Grid, Link, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { CssCheckbox, CssOutlinedInput, CssTooltip } from "../data";
import styles from "../styles";

const ValidTillInfo = (props) => {
  const { classes } = props
  return (
    <>
      <Typography className={classes.tooltip}>GFD: Good for Day stays active in the market until the end of the trading day.</Typography>
      <Typography className={classes.tooltip}>IOC: Immediate or Cancel is an order wherein it is executed immediately ( buy or sell) or cancelled, if it cannot be executed immediately.</Typography>
    </>
  )
}

function EquityOrder(props) {
  const { classes, formData, handleFormValues, formValues, handleBack, activeStep, buySell, lot, reduxState, productName } = props;
  const { clientCode, clientName } = reduxState || {};

  const modifyFormData = (data, filled) => {
    let obj = {}
    let arr = []
    data.map((ele) => {
      arr.push({
        quantity: filled ? lot != formValues.lot ? ele.minQty * (Number(lot) || 1) : ele.quantity
          : ele.min_qty * (Number(lot) || 1)
      })
    })
    if (filled) {
      obj.terms = formValues.terms
      obj.productType = formValues.productType
      obj.validTill = formValues.validTill
    } else {
      obj.terms = false
      obj.productType = "Delivery"
      obj.validTill = "GFD"
    }
    obj.eq = [...arr]
    return obj
  }

  const sumOf = (values) => {
    let sum = 0
    if (values.length > 0) {
      formData.forEach((ele, index) => {
        sum = sum + (Number(ele.stock_price) * Number(values[index].quantity))
      })
    }
    return Number(sum).toFixed(2)
  }

  Yup.addMethod(Yup.number, "quantityTest", function (args) {
    const message = args;
    return this.test("quantityTest", message, function (value) {
      const { path, createError, options } = this;
      const { originalValue, index } = options
      if (this.parent.quantity === undefined || this.parent.quantity === 0) {
        return true
      } else if (originalValue && buySell === "Sell") {
        if (formData && formData[index] && formData[index].min_qty && Number(originalValue) > Number(formData[index].min_qty)) {
          return createError({
            path: path,
            message: `Quantity should be less than ${formData && formData[index] && formData[index].min_qty}`,
          })
        } else {
          return true
        }
      } else {
        return true
      }
    })
  })

  const FormFields = (props) => {
    const { values, handleChange, setFieldTouched, resetForm, errors, touched, productName } = props

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.headerPadding}>
              <Grid item lg={4} sm={5} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Stocks</Typography>
              </Grid>
              <Grid item xs={3} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>LTP</Typography>
              </Grid>
              <Grid item lg={3} sm={2} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Quantity</Typography>
              </Grid>
              <Grid item xs={2} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Approx Value</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FieldArray
          name="eq"
          render={arrayHelpers => {
            return (
              <Grid item xs={12} className={classes.gridHeight}>
                {
                  values && values.eq && values.eq.length > 0
                    ? values.eq.map((user, index) => (
                      <Grid container key={index} className={classes.gridItem}>
                        <Grid item lg={4} sm={5}>
                          <Typography variant="subtitle1" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].stock_name) || "--"}</Typography>
                          <Typography variant="caption" className={classes.secondaryText}>
                            {((formData && formData.length > 0 && formData[index] && formData[index].exchange) || "--") + " " + ((formData && formData.length > 0 && formData[index] && formData[index].segment) || "EQ")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].stock_price && Number(formData[index].stock_price).toFixed(2)) || "--"}</Typography>
                        </Grid>
                        <Grid item lg={3} sm={2}>
                          <FormControl variant="outlined">
                            <CssOutlinedInput
                              id={`eq.${index}.quantity`}
                              name={`eq.${index}.quantity`}
                              autoFocus={(productName === 'buckets' || productName === 'baskets') ? false : index === 0}
                              classes={{ input: classes.input }}
                              onChange={handleChange}
                              value={values.eq[index].quantity}
                              onKeyUp={() => setFieldTouched("quantity", true)}
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                              disabled={productName === 'baskets'}
                            />
                          </FormControl>
                          {errors && errors.eq && errors.eq.length > 0 && errors.eq[index] && errors.eq[index].quantity &&
                            <p className={classes.errorText}>{errors.eq[index].quantity}</p>}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="subtitle1" align="right" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].stock_price && Number(Number(formData[index].stock_price) * Number(values.eq[index].quantity)).toFixed(2)) || "--"}</Typography>
                        </Grid>
                      </Grid>
                    ))
                    : null
                }
              </Grid>
            );
          }}
        />
        <Grid item xs={12}>
          <Grid container className={classes.approxDiv}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.secondaryText}>Approx Total</Typography>
            </Grid>
            <Grid item xs={3} className={classes.centerContent}>
              <Button variant="outlined" style={{ padding: 0 }} className={classes.outlinedButton} onClick={() => resetForm({ values: modifyFormData(formData) })}>Reset</Button>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" className={classes.primaryText}>{(values && values.eq && sumOf(values.eq)) || "--"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {clientCode && <Grid item xs={12} className={classes.clientGrid}>
          <Typography variant="caption" style={{ fontWeight: 600, width: 100 }} className={classes.primaryText}>Client Code:</Typography>
          <CssOutlinedInput
            id="clientName"
            name="clientName"
            disabled={true}
            value={`${clientCode || ''} - ${clientName || ''}`}
            classes={{ input: classes.input }}
          />
        </Grid>}
        <Grid item xs={12}>
          <Grid container className={classes.bottomInfoDiv}>
            <Grid item xs={4}>
              <Typography variant="caption" style={{ fontWeight: 600 }} className={classes.primaryText}>Product : {" "}<span className={classes.span}>{values.productType || "--"}</span></Typography>
            </Grid>
            <Grid item xs={4} className={classes.spacedContent} style={{ justifyContent: "flex-start" }}>
              <Typography variant="caption" style={{ fontWeight: 600 }} className={classes.primaryText}>ValidTill : {" "}<span className={classes.span}>{values.validTill || "--"}</span></Typography>
              <CssTooltip title={<ValidTillInfo classes={classes} />} arrow>
                <InfoIcon className={classes.info} />
              </CssTooltip>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.termsGrid}>
          <FormControl variant="outlined">
            <div className={classes.termsDiv}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  id="terms"
                  name="terms"
                  control={<CssCheckbox
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    color="primary"
                    checked={values.terms}
                    onKeyUp={() => setFieldTouched("terms", true)}
                    onChange={handleChange}
                  />}
                  label={""}
                  labelPlacement="end"
                />
                <Typography style={{ marginLeft: "-16px" }} variant="subtitle2" className={[classes.secondaryText, classes.checkboxMargin].join(" ")}>By continuing, you agree to &nbsp;
                  <span>
                    <Link
                      className={classes.blueText}
                      target="_blank"
                      href="/tnc/TnCMF.pdf"
                      rel="noopener"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                  &nbsp; of Tradebulls.
                </Typography>
              </div>
              {errors.terms && touched.terms && <p className={classes.errorText}>{errors.terms}</p>}
            </div>
          </FormControl>
        </Grid>
        {
          activeStep !== 0 ?
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="outlined" className={classes.cancel} onClick={() => handleBack(0, "Equity")}>{"Back"}</Button>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="contained" style={{ marginLeft: 0 }} className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
        }
      </>
    )
  }

  const form = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={formValues && formValues.data && formValues.data.length > 0 ? modifyFormData(formValues.data, true) : modifyFormData(formData)}
        validationSchema={Yup.object({
          eq: Yup.array().of(
            Yup.object().shape({
              quantity: Yup.number().integer("Decimal is not allowed").typeError("Invalid number")
                .min(0, "Quantity should be greater than or equal to Zero")
                .max(10000000, "Quantity should be less than 10000000")
                .quantityTest()
            })
          ),
          terms: Yup.boolean()
            .required("The terms and conditions must be accepted.")
            .oneOf([true], "The terms and conditions must be accepted."),
        })}
        onSubmit={values => {
          let data = []
          values.eq.map((ele, index) => {
            data.push({
              ltp: formData[index].stock_price,
              scripId: formData[index].scripId,
              scripName: formData[index].stock_name,
              symbol: formData[index].symbol,
              segment: formData[index].segment,
              exchange: formData[index].exchange,
              shortCode: formData[index].shortCode,
              minQty: formData[index].min_qty || 1,
              quantity: ele.quantity ? Number(ele.quantity) : 0,
              approxValue: formData[index].stock_price * Number(ele.quantity)
            })
          })
          let obj = {
            productType: "Delivery",
            validTill: "GFD",
            sumOf: sumOf(values.eq),
            orderType: "Market",
            action: buySell,
            terms: values.terms,
            lot: Number(lot || 1),
            data
          }
          if (productName === 'buckets' || productName === 'baskets') {
            (lot >= 1 && !String(lot).includes(".")) && handleFormValues(obj)
          } else {
            handleFormValues(obj)
          }
        }}
      >
        {({ values, resetForm, handleChange, errors, touched, setFieldTouched, handleReset }) => (
          <Form>
            <FormFields
              errors={errors}
              values={values}
              touched={touched}
              resetForm={resetForm}
              productName={productName}
              handleChange={handleChange}
              handleReset={handleReset}
              setFieldTouched={setFieldTouched}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div>
      {form()}
    </div>
  )
}

export default withStyles(styles)(EquityOrder)