import { Avatar, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import { goToChangePass } from "helpers/navHelpers";
import SvgComponent from "helpers/svgcomponent";
import React from "react";
import styles from "./styles";

function TopInfo(props) {
  const { callStarted, basicInfo, handleDialCall, handleEndCall, isMaximized, classes, clientName, clientCode, showCall, account, showChangePassword } = props
  const theme = createTheme();
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'))
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'))
  const matchesMd = useMediaQuery(theme.breakpoints.only('md'))

  const minimizedView = (matches) => {
    return (
      <div style={matches ? {width: "100%"} : {}}>
        {!matches && <Typography variant={matchesXl ? "h3" : "h4"} className={classes.name}>{clientName || ""}</Typography>}
        <Grid container>
          <Grid item xs={3} className={classes.minTopGrid}>
            <Typography variant="subtitle2" className={classes.color1}>{!matches ? "Client Code  " : "Branch "}</Typography>
            <Typography variant="subtitle2" className={classes.color2}>{!matches ? "Branch  " : "Sb Tag " }</Typography>
            <Typography variant="subtitle2" className={classes.color3}>{!matches ? "Category  " : "Plan Name "}</Typography>
          </Grid>
          <Grid item xs={account ? 5 : 6} className={classes.minTopGrid}>
            <Typography variant="subtitle2" className={classes.value}>{(!matches ? basicInfo.clientCode : basicInfo.branch) || "--"}</Typography>
            <Typography variant="subtitle2" className={classes.value}>{(!matches ? basicInfo.branch : basicInfo.sbtag) || "--"}</Typography>
            <Typography variant="subtitle2" className={classes.value}>{(!matches ? basicInfo.category : basicInfo.planName) || "--"}</Typography>
          </Grid>
          {matches ?
            showChangePassword ?
              <Grid item xs={4} style={{display: "flex", alignItems: "center"}}>
                <Button variant="contained" style={{ textTransform: "none" }} className={classes.containedBlue} onClick={() => goToChangePass()}>Change Password</Button>
              </Grid>
              :
              <></>
            :
            <Grid item xs={3} className={classes.iconDiv}>
              {
                callStarted ?
                  <SvgComponent iconname={"CallEndIcon"} className={classes.callIcon} onClick={handleEndCall} />
                  :
                  <SvgComponent iconname={"CallIcon"} className={classes.callIcon} onClick={handleDialCall} />
              }
            </Grid>}
        </Grid>
      </div>
    )
  }

  const maximizedView = () => {
    return (
      <div>
        <Grid container>
          <Grid item lg={account ? 4 : 5} md={account ? 5 : 6} sm={account ? 4 : 6} xs={6} style={{ display: "flex" }}>
            <Grid container>
              <Grid item lg={account ? 3 : 2} md={account ? 3 : 2} sm={account ? 3 : 2}>
                <Avatar style={{ width: "100%", height: "100%", background: "none" }}>
                  <SvgComponent className={classes.profileAvatar} /* style={account ? { width: "72px", height: "72px" } : {}} */ iconname="DefaultAvatar" />
                </Avatar>
              </Grid>
              <Grid item lg={8} md={8} sm={account ? 8 : 7} style={account && (matchesSm || matchesMd) ? {marginLeft: "4px"} : {}} className={[classes.nameDiv, account ? "account" : ""].join(" ")}>
                <Typography variant={matchesXl ? "h3" : "h4"} className={classes.name} style={{ paddingBottom: matchesXl ? "12px" : "4px" }}>{clientName || ""}</Typography>
                <Typography variant="subtitle2" className={classes.color1}>Client Code<span style={{ marginLeft: "20px" }} className={classes.value}>{clientCode || "--"}</span></Typography>
              </Grid>
              {
                showCall ?
                  <Grid item xs={2} className={classes.iconDiv}>
                    {
                      callStarted ?
                        <SvgComponent iconname={"CallEndIcon"} className={classes.callIcon} onClick={handleEndCall} />
                        :
                        <SvgComponent iconname={"CallIcon"} className={classes.callIcon} onClick={handleDialCall} />
                    }
                  </Grid> :
                  <></>
              }
            </Grid>
          </Grid>
          <Grid item lg={6} md={account ? 7 : 6} sm={account ? 8 : 6} xs={6}>
            {
              matchesSm || (account && matchesMd) ?
                <Grid container className={classes.smGrid} style={account ? {paddingRight: "0px"} : {}}>
                  {minimizedView(true)}
                </Grid>
                :
                <Grid container style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
                  <Grid item xs={3} className={classes.topGrid}>
                    <Typography variant="subtitle2" className={classes.color5} style={{ paddingBottom: "4px" }}>Branch </Typography>
                    <Typography variant="subtitle2" className={classes.value}>{basicInfo.branch || "--"}</Typography>
                  </Grid>
                  <Grid item xs={5} className={classes.topGrid} style={{ paddingBottom: "4px" }}>
                    <Typography variant="subtitle2" className={classes.color3} style={{ paddingBottom: "4px" }}>Sub Broker Tag </Typography>
                    <Typography variant="subtitle2" className={classes.value}>{basicInfo.sbtag || "--"}</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.topGrid} style={{ paddingBottom: "4px" }}>
                    <Typography variant="subtitle2" className={classes.color1}>Plan Name </Typography>
                    <Typography variant="subtitle2" className={classes.value}>{basicInfo.planName || "--"}</Typography>
                  </Grid>
                </Grid>
            }
          </Grid>
          {
            (!matchesSm && !matchesMd) && showChangePassword ?
              <div className={classes.iconDiv}>
                <Button variant="contained" style={{ textTransform: "none" }} className={classes.containedBlue} onClick={() => goToChangePass()}>Change Password</Button>
              </div>
              :
              <></>
          }
        </Grid>
      </div>
    )
  }

  return (
    isMaximized ? maximizedView() : minimizedView()
  )
}

export default withStyles(combineStyles(styles, commonStyles))(TopInfo)
