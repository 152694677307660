import { EventReciever } from 'components/Event';
import { sort as fastSort } from 'fast-sort';
import { EventConstants } from 'helpers/eventHelper';
import axios from 'helpers/interceptor';
import { getSchemeKey } from 'helpers/schemeHelper';
import { StorageHelper } from 'helpers/storageHelper';
import { store } from 'helpers/store';
import { AlertActions } from 'redux/Alert';
import { CommonActions } from 'redux/Common';
import { DialogActions } from 'redux/Dialog';
import { PrefsService } from 'services/prefs';

const ADD_LIST_MF = 'ADD_LIST_MF';
const EDIT_LIST_MF = 'EDIT_LIST_MF';
const DELETE_LIST_MF = 'DELETE_LIST_MF';
const CHANGE_LIST_MF = 'CHANGE_LIST_MF';
const DRAG_ITEM_MF = 'DRAG_ITEM_MF';
const SORT_LIST_MF = 'SORT_LIST_MF';
const SORT_END_MF = 'SORT_END_MF';

const GET_LISTS_MF = 'GET_LISTS_MF';
const GET_LISTS_SUCCESS_MF = 'GET_LISTS_SUCCESS_MF';
const GET_LISTS_FAILURE_MF = 'GET_LISTS_FAILURE_MF';

const GET_SCRIPS_MF = 'GET_SCRIPS_MF';
const GET_SCRIPS_SUCCESS_MF = 'GET_SCRIPS_SUCCESS_MF';
const GET_SCRIPS_FAILURE_MF = 'GET_SCRIPS_FAILURE_MF';

const ADD_SCRIP_STATUS_MF = 'ADD_SCRIP_STATUS_MF';
const DELETE_SCRIP_STATUS_MF = 'DELETE_SCRIP_STATUS_MF';

const CLEAR_ALERT_MF = 'CLEAR_ALERT_MF';

export const WatchListMFActions = {
    //Unused
    // DragItem,
    // DeleteItem,
    // AddItem,
    //Unused
    getLists,
    switchList,
    addList,
    deleteList,
    sortList,
    onSortEnd,
    getScrips,
    addScrip,
    deleteScrip,
    clearAlert
};

//EVENT RECIEVER
new EventReciever(EventConstants.ARROW_ACTION, 'xyz').onEvent(key => {
    const storeState = store.getState();
    if (storeState.layout.activeTab == 'watchlist') {
        changeScrip(storeState, key);
    }
});

new EventReciever(EventConstants.SWITCH_WATCHLIST, 'xyz').onEvent(index => {
    switchList(null, index);
});

function changeScrip(state, action) {
    let nextIndex;
    const { watchlistMF: { scrips }, common: { selectedScheme } } = state;
    let selectedScripIndex = scrips.map(scheme => getSchemeKey(scheme)).indexOf(getSchemeKey(selectedScheme));
    if (action.toLowerCase() === 'arrowup' /* || action.toLowerCase() === 'arrowleft' */) {
        //Previous scrip
        nextIndex = selectedScripIndex - 1;
        if (nextIndex < 0) {
            nextIndex = scrips.length - 1;
        }
    }
    if (action.toLowerCase() === 'arrowdown' /* || action.toLowerCase() === 'arrowright' */) {
        //Next scrip
        nextIndex = selectedScripIndex + 1;
        if (nextIndex > scrips.length - 1) {
            nextIndex = 0;
        }
    }
    nextIndex != null && CommonActions.onSchemeSelected(scrips[nextIndex]);
}
//EVENT RECIEVER

//ACTION CREATORS
function clearAlert() {
    store.dispatch({ type: CLEAR_ALERT_MF });
}

function getLists(lists, explorer) {
    onRequest();
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/get`
    };
    if (lists?.length > 0) {
        onSuccess(lists, explorer);
    } else {
        axios(options).then(response => {
            const { data: { success, error } } = response;
            if (success) {
                let mfFiltered = success.filter(item => item.segment == 'MF');
                let newList = mfFiltered.map(item => {
                    const { watchListId, watchListName } = item;
                    let newItem = {
                        ...item,
                        id: watchListId,
                        name: watchListName
                    };
                    return newItem;
                });
                onSuccess(newList, explorer);
                // getScrips(newList[0].id, newList[0].type);
            } else {
                onFailure(error);
            }
        }).catch(function (error) {
            console.log('getLists Error: ' + error);
            onFailure(error);
        });
    }
    function onRequest() { store.dispatch({ type: GET_LISTS_MF }); }
    function onSuccess(list, explorer) {
        let storedData = StorageHelper.getSelectedListMF();
        let storedValues = storedData ? storedData.split('_') : null;
        let storedList = storedValues ? { id: storedValues[0], type: storedValues[1] } : null;

        if (!storedList) {
            storedList = list && list.length > 0 ? list[0] : -1
        }

        if (storedList.id && !explorer) {
            let foundIndex = list.findIndex(item => item.id == storedList.id);
            if (foundIndex == -1) {
                storedList = list[0];
            }
            PrefsService.setLastviewedwatchlistMF({
                id: storedList.id, type: storedList.type
            });
            getScrips(storedList.id, storedList.type);
        }

        store.dispatch({ type: GET_LISTS_SUCCESS_MF, list, storedList });
    }
    function onFailure(error) { store.dispatch({ type: GET_LISTS_FAILURE_MF, error }); }
}

function switchList(watchlistId, byIndex) {
    let currentList, currentIndex;
    let lists = store.getState().watchlistMF.lists;
    // let index = lists.map(x => x.id).indexOf(watchlistId);
    // let currentList = lists[index];
    if (byIndex || byIndex == 0) {
        if (byIndex < lists.length) currentIndex = byIndex;
        else currentIndex = lists.length - 1;
    } else {
        currentIndex = lists.map(x => x.id).indexOf(watchlistId);
    }
    currentList = lists[currentIndex];

    if (currentIndex >= 0) {
        store.dispatch({ type: CHANGE_LIST_MF, list: currentList });
        PrefsService.setLastviewedwatchlistMF({
            id: currentList.id, type: currentList.type
        });
        getScrips(currentList.id, currentList.type);
    }
}

function addList({ watchListName, watchListId, newWatchListName }) {
    // debugger
    // return
    const endPoint = watchListId
        ? '/ms-feed-service/watchlist/edit'
        : '/ms-feed-service/watchlist/create';
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}${endPoint}`,
        data: {
            segment: 'MF',
            watchListName,
            watchListId,
            newWatchListName
        }
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            if (success) {
                onSuccess(success.watchListId, newWatchListName);
            } else {
                AlertActions.error(error && error.msg ? error.msg : "Something went wrong...");
            }
        })
        .catch(function (error) {
            console.log('addWatchlist error', error);
        });
    function onSuccess(id, name) {
        const isEdit = watchListId ? true : false;
        id = id || watchListId;
        name = name || watchListName;
        DialogActions.dialogClose();

        PrefsService.setLastviewedwatchlistMF({ id, type: 'client' });

        store.dispatch({
            type: watchListId ? EDIT_LIST_MF : ADD_LIST_MF,
            item: {
                id: id,
                name: name,
                type: 'client',
                watchListId: id,
                watchListName: name,
                sortType: -1,
                sortDesc: true,
                data: []
            },
            selectedList: id
        });
        !isEdit && getScrips(id, 'client');
    }
}

function deleteList(watchListId) {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/remove`,
        data: { watchListId }
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            if (success) {
                onSuccess();
            } else {
                AlertActions.error(error && error.msg ? error.msg : "Something went wrong...");
            }
        })
        .catch(function (error) {
            console.log('deleteWatchlist error', error);
        });
    function onSuccess() {
        let lists = store.getState().watchlistMF.lists;
        let toDeleteIdx = getIndex(lists, watchListId);

        lists.splice(toDeleteIdx, 1);

        let currList = lists.length > 0 ? lists[0] : null;

        if (currList)
            getScrips(currList.id, currList.type);

        PrefsService.setLastviewedwatchlistMF({ id: currList.id, type: currList.type });

        store.dispatch({
            type: DELETE_LIST_MF,
            item: {
                id: currList.id,
                type: currList.type,
                name: currList.name,
                lists: [...lists]
            }
        });
    }
}

function getScrips(watchListId, selectedListType) {
    onRequest();
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/custom/watchlist/feed/${selectedListType}/${watchListId}`
    };
    axios(options)
        .then(response => {
            let today = new Date().getTime();
            const { data: { success, error } } = response;
            if (success) {
                const { sortOption, customSort, list } = success;
                onSuccess(list, sortOption, customSort);
            } else {
                onFailure(error);
            }
        })
        .catch(function (error) {
        });
    function onRequest() { store.dispatch({ type: GET_SCRIPS_MF }); }
    function onSuccess(items, sortOption, customSort) {
        const sortedScrips = sortScrips(items, { id: watchListId, sortOption, customSort });
        store.dispatch({
            type: GET_SCRIPS_SUCCESS_MF,
            items,
            sortOption,
            customSort,
            selectedList: watchListId,
            selectedListType,
            sortedScrips
        });
    }
    function onFailure(error) { store.dispatch({ type: GET_SCRIPS_FAILURE_MF, error }); }
}

function DragItem(oldIndex, newIndex, watchListId) {
    var obj = {
        oldIndex: oldIndex,
        newIndex: newIndex,
        watchListId: watchListId
    };
    store.dispatch({
        type: DRAG_ITEM_MF,
        item: obj
    });
}

function sortList(watchListId, type, direction) {
    const { scrips } = store.getState().watchlistMF;
    const sortingOptions = {
        id: watchListId,
        customSort: [],
        sortOption: { type, direction }
    };

    const sortedScrips = sortScrips(scrips, sortingOptions);

    store.dispatch({
        type: SORT_LIST_MF,
        item: { watchListId, sortOption: { type, direction }, sortedScrips }
    });
}

function onSortEnd(oldIndex, newIndex, selectedList) {
    let scrips = store.getState().watchlistMF.scrips;
    let swappedScrips = swapScrips(selectedList, scrips, oldIndex, newIndex);

    store.dispatch({
        type: SORT_END_MF,
        data: swappedScrips
    });
}

function addScrip(payload) {
    const { watchListId, selectedListType, data } = payload;
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/create/scrip`,
        data
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            let statusMsg = "";
            if (success && success.length > 0) {
                const { selectedList } = store.getState().watchlistMF;
                if (selectedList == watchListId) {
                    getScrips(watchListId, 'client');
                }
                success.forEach(msg => {
                    statusMsg += msg.msg + "\n";
                });
                onSuccess(statusMsg, 'success');
            }
            if (error && error.length > 0) {
                statusMsg = "";
                error.forEach(msg => {
                    statusMsg += msg.msg + "\n";
                });
                setTimeout(() => {
                    onSuccess(statusMsg, 'error');
                }, success && success.length > 0 ? 2500 : 0);
            }
        })
        .catch(function (error) {
            console.log('addScripOptions', error);
        });
    function onSuccess(status, statusType) {
        store.dispatch({
            type: ADD_SCRIP_STATUS_MF,
            status,
            statusType,
        });
    }
}

function deleteScrip(
    watchListId,
    selectedListType,
    schemeId,
    schemeCode,
    shortCode
) {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/remove/scrip`,
        data: { watchListId, schemeId, schemeCode, shortCode: shortCode || 'BMS' }
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            let statusMsg = "";
            if (success && success.length > 0) {
                getScrips(watchListId, selectedListType);

                success.forEach(msg => {
                    statusMsg += msg.msg + "\n";
                });
                onSuccess(statusMsg, 'success');
            } else if (error && error.length > 0) {
                statusMsg = "";
                error.forEach(msg => {
                    statusMsg += msg.msg + "\n";
                });
                setTimeout(() => {
                    onSuccess(statusMsg, 'error');
                }, success && success.length > 0 ? 2500 : 0);
            }
            // getScrips(watchListId, selectedListType);
            // AlertActions.success(`Script ${scripId} deleted successfully`);
        })
        .catch(function (error) {
            console.log('addScripOptions error', error);
            // AlertActions.error(`Script ${scripId} delete error${error}`);
        });
    function onSuccess(status, statusType) {
        store.dispatch({
            type: DELETE_SCRIP_STATUS_MF,
            status,
            statusType,
        });
    }
}

function sortScrips(scrips, sortingOptions) {
    if (scrips && scrips.length == 0) return scrips;

    const { id, customSort, sortOption: { type, direction } } = sortingOptions;

    let sortedScrips;

    if (customSort && customSort.length > 0) {
        try {
            sortedScrips = customSort.map(seq => {
                const { schemeID: seqSchemeID, schemeCode: seqSchemeCode } = seq;
                let foundIndex = -1;
                const found = scrips.filter((scrip, scripIndex) => {
                    const { schemeID, schemeCode } = scrip;
                    if (schemeID == seqSchemeID
                        && schemeCode == seqSchemeCode) {
                        foundIndex = scripIndex;
                        return true;
                    };
                });
                let foundItem = null;
                if (found.length > 0) {
                    foundItem = found[0];
                    scrips.splice(foundIndex, 1);
                }
                return foundItem;
            });
        } catch (error) {
            console.log(error);
            // debugger
        }
        // remove nulls if previous sort had more values
        sortedScrips.filter(x => x != null);
        let toReturn = sortedScrips.filter(x => x != null);
        if (scrips && scrips.length > 0) {
            toReturn = scrips.concat(toReturn);
            try {
                PrefsService.setCustomSortMF(id, toReturn);
            } catch (error) {
                console.log(error)
                debugger
            }
        }
        return toReturn;
    }

    let sortCol;
    switch (type) {
        case 0:
            sortCol = 'schemeName';
            break;
        case 1:
            sortCol = 'percentReturns6mnth';
            break;
        case 2:
            sortCol = 'percentReturns1yr';
            break;
        case 3:
            sortCol = 'percentReturns3yr';
            break;
        case 4:
            sortCol = 'percentReturns5yr';
            break;
        case 5:
            sortCol = 'si';
            break;
        case 6:
            sortCol = 'fundSize';
            break;
    }
    if (sortCol) {
        if (direction === 1 || direction) {
            sortedScrips = fastSort([...scrips]).desc(u => sortType(sortCol, u[sortCol]));
            return sortedScrips;
        } else {
            sortedScrips = fastSort([...scrips]).asc(u => sortType(sortCol, u[sortCol]));
            return sortedScrips;
        }
    }
    return scrips;
}

function sortType(colName, data) {
    if (colName === 'fundSize' /* || colName === 'change' */)
        return Number(data)
    else
        return data
}

function swapScrips(id, scrips, oldIndex, newIndex) {
    let swappedScrips = [...scrips];
    let scrip = scrips[oldIndex];
    swappedScrips.splice(oldIndex, 1);
    swappedScrips.splice(newIndex, 0, scrip);
    PrefsService.setCustomSortMF(id, swappedScrips);
    return swappedScrips;
}

function getIndex(lists, selectedList) {
    return lists.map(x => x.id).indexOf(selectedList);
}

function cloneArray(array) {
    return [...array];
}

//Reducer
export function watchListMFReducer(state = initialState, action) {
    const { type } = action;
    const { lists } = state;
    switch (type) {
        case GET_LISTS_MF: {
            return { ...state, fetchingLists: true, };
        }
        case GET_LISTS_SUCCESS_MF: {
            const { list, storedList } = action;
            return {
                ...state,
                fetchingLists: false,
                lists: list,
                selectedList: storedList.id,
                selectedListType: storedList.type,
            };
        }
        case GET_LISTS_FAILURE_MF: {
            const { error } = action;
            return {
                ...state,
                fetchingLists: false,
                listsError: error,
                lists: [],
                selectedList: -1,
                alert: { message: "Error fetching WatchLists", type: 'error' }
            };
        }
        case GET_SCRIPS_MF: {
            return {
                ...state,
                fetchingLists: false,
                fetchingScrips: true,
            };
        }
        case GET_SCRIPS_SUCCESS_MF: {
            const { items, selectedList, sortOption, customSort, sortedScrips } = action;
            // let customSort = StorageHelper.getCustomSort(selectedList);
            var arr = cloneArray(lists);
            var index = getIndex(arr, selectedList);
            let currentList = arr[index];

            currentList.sortOption = sortOption;
            currentList.customSort = customSort;
            return {
                ...state,
                fetchingScrips: false,
                scrips: sortedScrips,
                // scrips: sortScrips(items, currentList),
                selectedList,
                selectedListName: currentList.name,
                selectedListType: currentList.type,
            };
        }
        case GET_SCRIPS_FAILURE_MF: {
            return {
                ...state,
                alert: { message: action.error.msg, type: 'error' }
            };
        }
        case ADD_SCRIP_STATUS_MF:
        case DELETE_SCRIP_STATUS_MF: {
            return {
                ...state,
                alert: { message: action.status, type: action.statusType }
            };
        }
        case DRAG_ITEM_MF: {
            var arr = [...lists];
            var index = arr.map(x => x.id).indexOf(action.item.watchListId);
            var item = arr[index].data;
            if (action.item.newIndex >= item.length) {
                var k = action.item.newIndex - item.length + 1;
                while (k--) {
                    item.push(undefined);
                }
            }
            item.splice(
                action.item.newIndex,
                0,
                item.splice(action.item.oldIndex, 1)[0]
            );
            arr[index].data = item;
            return { ...state, lists: arr };
        }
        case SORT_LIST_MF: {
            const { sortOption, sortedScrips } = action.item;
            const { type, direction } = sortOption;

            return {
                ...state,
                sortType: type,
                sortDesc: direction,
                scrips: sortedScrips,
                // scrips: sortScrips(state.scrips, { customSort: [], sortOption }),
            };
        }
        case SORT_END_MF: {
            return {
                ...state,
                scrips: action.data,
            };
        }
        case ADD_LIST_MF: {
            var arr = [...lists];
            arr[arr.length] = action.item;
            return {
                ...state,
                scrips: [],
                selectedList: action.item.id,
                selectedListName: action.item.name,
                selectedListType: 'client',
                lists: arr,
                alert: { message: `Watchlist ${action.item.name} added successfully.`, type: 'success' }
            };
        }
        case EDIT_LIST_MF: {
            var arr = [...lists];
            let idx = arr.findIndex(x => x.id == action.item.id);
            let oldItem = arr[idx];
            let newItem = action.item;
            oldItem.name = newItem.name;
            oldItem.watchListName = newItem.name;
            arr[idx] = oldItem;
            return {
                ...state,
                selectedListName: newItem.name,
                lists: arr,
                alert: { message: `Watchlist ${newItem.name} edited successfully.`, type: 'success' }
            };
        }
        case DELETE_LIST_MF: {
            const { selectedListName } = state;
            const { item: { id, type, name, lists: newLists } } = action;
            return {
                ...state,
                selectedList: id,
                selectedListType: type,
                selectedListName: name,
                lists: newLists,
                alert: { message: `Watchlist ${selectedListName} deleted successfully.`, type: 'error' }
            };
        }
        case CHANGE_LIST_MF: {
            const {
                list: { id, name, type }
            } = action;
            // let index = getIndex(state.lists, watchListId);
            // let currentList = state.lists[index];
            return {
                ...state,
                scrips: [],
                selectedList: id,
                selectedListName: name,
                selectedListType: type
            };
        }
        case CLEAR_ALERT_MF: {
            return {
                ...state,
                alert: {}
            };
        }

        default:
            return state;
    }
}

const initialState = {
    fetchingLists: true,
    listsError: null,
    fetchingScrips: false,
    scripsError: null,
    selectedList: -1,
    selectedListType: 'client',
    loading: true,
    lists: [],
    scrips: [],
    alert: {}
};
