import { Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import styles from "./styles";
import Table from "components/MaterialTable";

function Facility(props) {
  const { /* isMaximized,  */facility, classes } = props
  return (
    // isMaximized ?
      <div className={classes.tabPanel}>
        <div /*  className={classes.tableDiv} */>
          <Typography variant="h3" className={classes.heading}>FACILITY</Typography>

          {
            facility && (Object.keys(facility).length > 0 || (facility.columns && facility.columns.length > 0)) ?
              <Table
                maxHeight={450}
                data={facility.filterdata || []}
                columns={facility.columns || []}
              />
              :
              <div className={classes.noDataDiv}>
                No data found
              </div>
          }
        </div>
      </div>
      // :
      // <div className={classes.noData} style={{height: "285px"}}>
      //   No data found
      // </div>
  )
}

export default withStyles(styles)(Facility)