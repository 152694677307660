export default ({ breakpoints }) => ({
  styleOverrides: {
    padding: {
      [breakpoints.down('xl')]: {
        paddingTop: '4px',
        paddingBottom: '4px'
      }
    }
  }
});
