export default ({ palette }) => ({
  styleOverrides: {
    root: {
      borderRadius: '3px',
      overflow: 'hidden'
    },
    colorPrimary: {
      backgroundColor: palette.common.neutral
    }
  }
});
