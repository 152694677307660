import { IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { DialogActions } from 'redux/Dialog';
import { MODAL_CHILD } from 'widgets/FormDialog';

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  iconBtn: {
    fontSize: 16,
    height: "24px",
    width: "24px",
    color: theme.palette.common.blue,
    border: `1px solid ${theme.palette.common.blue}`,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: theme.palette.common.blue + 12,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      height: "22px",
      width: "22px",
      fontSize: 12
    },
  }
});

function ActionsRow(props) {
  const { classes, rowData } = props

  return (
    <div className={classes.root}>
      <Tooltip title="Fund Details" aria-label="fundDetails">
        <span>
          <IconButton
            onClick={e => {
              DialogActions.dialogOpen(MODAL_CHILD.FUND_DETAILS, {
                isinNumber: rowData?.isin || "",
                schemeCode: rowData?.schemeCode || ""
              })
            }}
            className={classes.iconBtn}>
            F
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

export default withStyles(styles)(ActionsRow)
