export default theme => ({
  mainDiv: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  root: {
    flexGrow: 1,
    height: 'calc(100% - 16px)',
    margin: '8px 15px',
    [theme.breakpoints.down('xl')]: {
      margin: '6px 12px',
      height: 'calc(100% - 12px)',
    },
  },
  paper: {
    padding: 0,
    textAlign: 'center',
    color: theme.palette.color9.default,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: "14px",
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  grid: {
    height: '100%',
    alignContent: 'center',
    margin: 0,
    minHeight: "25vh",
    minWidth: "15vw",
    [theme.breakpoints.down('xl')]: {
      minHeight: "34vh",
      minWidth: "19vw"
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: "32vh",
      minWidth: "21vw"
    },
    [theme.breakpoints.down('md')]: {
      minHeight: "25vh",
      minWidth: "26vw"
    },
  },
  horizontalDivider: {
    borderBottom: `1px dashed ${theme.palette.color10.default}`,
  },
  verticalDivider: {
    borderRight: `1px dashed ${theme.palette.color10.default}`
  },
  item: {
    padding: '12px 0 !important',
    [theme.breakpoints.down('xl')]: {
      padding: '6px 0 !important',
    }
  },
  text: {
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '12px',
    },
  },
  textMargin: {
    marginTop: "6px",
    [theme.breakpoints.down('xl')]: {
      marginTop: "4px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "2px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "0px",
    },
  }
})