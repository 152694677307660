import { Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { getBseScripCode, getFixedValue, getSegment } from "helpers/scripHelper";
import React from "react";

const styles = theme => ({
  button: {
    // padding: "4px 12px",
    // height: "28px",
    marginTop: "4px",
    background: "#e0e0e0",
    color: theme.palette.text.secondary,
    "&.active": {
      background: "#006CA8",
      color: "#fff"
    },
    "&:disabled": {
      background: "#c5cbce",
      color: "#7d7d7d"
    },
    "&.hover": {
      background: theme.palette.background.secondary,
    }
  },
  highlight: {
    color: theme.palette.color12.default,
    // whiteSpace: "nowrap",
    whiteSpace: "initial"
  },
  greenColor: {
    color: theme.palette.priceColor.green,
  },
  redColor: {
    color: theme.palette.priceColor.red,
  },
  grey: {
    color: theme.palette.color15.default,
    // fontSize: 17
  },
  normalColor: {
    color: theme.palette.text.primary
  }
})

function Top(props) {
  const { classes, selectedScrip: { scripName, segment, exchange, symbol, ltp, change, percentChange, ltt, isMarketIndex }, selectedScrip, compactView, reduxForm, values, handleExchangeChange, activeStep, showExchange } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography component="div" display="block" /* gutterBottom */>
          <Typography component="div" display="inline" variant="h3" className={classes.highlight}><span>{scripName || symbol || '--'}</span></Typography>
          <Typography component="div" display="inline" variant="caption"><span style={{ marginLeft: 10 }}>( {exchange || "--"} {getSegment(selectedScrip)} {getBseScripCode(selectedScrip)} )</span></Typography>
        </Typography>
        <Typography component="div" display="block" /* gutterBottom */ >
          <Typography component="div" display="inline" variant="h4" className={Number(percentChange) > 0 ? classes.greenColor : Number(percentChange) < 0 ? classes.redColor : classes.normalColor}>{getFixedValue(ltp, segment) || "0.00"}</Typography>
          <Typography component="div" display="inline" variant="subtitle1" className={classes.grey} style={{ marginLeft: 16 }}>{getFixedValue(change, segment) || "0.00"} ({getFixedValue(percentChange, segment === "CURR" ? null : segment) || "0.00"}%)</Typography>
          <Typography component="div" display="inline" variant="caption" className={classes.grey} /* style={{ fontSize: 12 }} */>&nbsp;&nbsp;|&nbsp;&nbsp;As on {ltt || "--"}</Typography>
        </Typography>
      </Grid>
      <div style={{ marginBottom: "6px" }}>
        {
          activeStep === 0 && showExchange && !compactView && !isMarketIndex &&
          <ToggleButtonGroup
            value={values.exchange}
            exclusive
            onChange={(e) => handleExchangeChange(e.currentTarget.value)}
            aria-label="text alignment"
          >
            <ToggleButton size="small" value={/* segment === "COMM" ? "MCX" :  */"NSE"} className={[classes.button, values.exchange === /* (segment === "COMM" ? "MCX" :  */"NSE"/* ) */ ? "active" : ""].join(" ")} disabled={reduxForm.status === "Modify" && values.exchange === /* (segment === "COMM" ? "NCDEX" :  */"BSE"/* ) */}>
              <span className="text">{/* segment === "COMM" ? "MCX" :  */"NSE"}</span>
            </ToggleButton>
            <ToggleButton size="small" value={/* segment === "COMM" ? "NCDEX" :  */"BSE"} className={[classes.button, values.exchange === /* (segment === "COMM" ? "NCDEX" :  */"BSE"/* ) */ ? "active" : ""].join(" ")} disabled={reduxForm.status === "Modify" && values.exchange ===/*  (segment === "COMM" ? "MCX" :  */"NSE"/* ) */}>
              <span className="text">{/* segment === "COMM" ? "NCDEX" :  */"BSE"}</span>
            </ToggleButton>
          </ToggleButtonGroup>
        }
      </div>
    </Grid>
  )
}

export default withStyles(styles)(Top)