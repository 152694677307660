export default theme => ({
  root: {
    width: "650px",
    height: "500px",
    [theme.breakpoints.down('xl')]: {
      width: "500px",
      height: "420px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "500px",
      height: "400px",
    },
    [theme.breakpoints.down('md')]: {
      width: "400px",
      height: "350px",
    },
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column"
  },
  image: {
    width: "150px",
    height: "120px",
    [theme.breakpoints.down('md')]: {
      width: "120px",
      height: "100px",
    },
  },
  image1: {
    width: "125px",
    height: "150px",
    [theme.breakpoints.down('md')]: {
      width: "100px",
      height: "100px",
    },
  },
  blueText: {
    color: theme.palette.common.blue
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  textPadding: {
    padding: "8px 0px",
  },
  startButton: {
    margin: "16px 0px 30px 0px",
    padding: "6px 60px",
    textTransform: "none",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    [theme.breakpoints.down('xl')]: {
      margin: "10px 0px 20px 0px",
      padding: "4px 52px"
    },
  },
  uploadButton: {
    marginTop: "6px",
    background: "white",
    textTransform: "none",
    // border: 0,
    padding: "4px 12px",
    fontSize: "10px",
    color: "#F54040",
    "&:hover": {
      background: "#b9b1b1",
    },
    [theme.breakpoints.down('xl')]: {
      padding: "2px 8px"
    },
  },
  lastTextDiv: {
    paddingTop: "32px",
    paddingBottom: "32px",
    [theme.breakpoints.down('xl')]: {
      paddingTop: "28px",
      paddingBottom: "28px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  pointDiv: {
    display: "flex"
  },
  pointNumber: {
    height: "18px",
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    width: "18px",
    borderRadius: "50%",
    color: "#fff",
    backgroundColor: theme.palette.common.blue,
    marginRight: "14px",
    padding: "6px",
    fontWeight: 600,
    marginTop: "2px",
    [theme.breakpoints.down('xl')]: {
      width: "16px",
      height: "16px",
      padding: "6px",
      fontSize: "11px",
      marginTop: "2px",
      marginRight: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "5px",
      fontSize: "10px",
      marginRight: "10px"
    },
    [theme.breakpoints.down('md')]: {
      width: "14px",
      height: "14px",
      padding: "4px",
      fontSize: "8px",
      marginTop: "0px",
      marginRight: "8px"
    }
  },
  checkIcon: {
    width: "16px",
    height: "16px",
    marginRight: "4px",
    color: theme.palette.common.blue,
  },
  pointHeading: {
    paddingBottom: "6px",
    [theme.breakpoints.down('lg')]: {
      paddingBottom: "4px",
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: "2px",
    },
  },
  firstGrid: {
    border: `1px dashed ${theme.palette.color5.default}`,
    marginBottom: "16px",
    [theme.breakpoints.down('xl')]: {
      marginBottom: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: "10px",
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: "8px",
    }
  },
  secondGrid: {
    border: `1px dashed ${theme.palette.color5.default}`,
    margin: "24px 0px 16px 0px",
    [theme.breakpoints.down('xl')]: {
      margin: "20px 0px 12px 0px",
    },
    [theme.breakpoints.down('lg')]: {
      margin: "16px 0px 8px 0px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "12px 0px px 0px",
    }
  },
  step2Div: {
    height: "100%",
    display: "flex",
    paddingLeft: "20px",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down('xl')]: {
      paddingLeft: "16px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: "8px",
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: "0px",
    }
  },
  step2Heading: {
    paddingBottom: "10px"
  },
  linkDiv: {
    display: "flex",
    alignItems: "center",
    "&.margin": {
      marginTop: "20px",
      [theme.breakpoints.down('xl')]: {
        marginTop: "6px",
      },
      [theme.breakpoints.down('lg')]: {
        marginTop: "4px",
      },
      [theme.breakpoints.down('md')]: {
        marginTop: "2px",
      },
    }
  },
  link: {
    fontSize: "14px",
    color: "#f54040",
    marginRight: "4px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      marginRight: "2px",
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "11px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px"
    }
  },
  input: {
    padding: "8px",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#808080",
      '-webkit-text-fill-color': "#808080"
    }
  },
  uploadWrapper: {
    border: `1px dashed ${theme.palette.color5.default}`,
    padding: "5px",
    borderRadius: "5px",
    width: "100%",
    height: "36px",
    [theme.breakpoints.down('lg')]: {
      height: "32px",
    },
    [theme.breakpoints.down('lg')]: {
      height: "28px",
    }
  },
  wrapperInnerDiv: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  backButton: {
    textTransform: "none",
    fontSize: "12px",
    color: theme.palette.common.blue,
    border: `1px solid ${theme.palette.common.blue}`,
    padding: "2px 32px",
    [theme.breakpoints.down('xl')]: {
      padding: "2px 28px",
      fontSize: "11px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px 24px",
      fontSize: "10px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "2px 20px",
      fontSize: "8px",
    }
  },
  step4Height: {
    height: "calc(100% - 40px)",
    [theme.breakpoints.down('lg')]: {
      height: "calc(100% - 30px)",
    },
    [theme.breakpoints.down('md')]: {
      height: "calc(100% - 24px)",

    },
  },
  passwordDiv: {
    width: "100%",
    padding: "12px 0px 8px 0px"
  },
  generateButton: {
    margin: "6px 0px 10px 0px",
    padding: "4px 60px",
    color: "#fff",
    backgroundColor: "#006CA8",
    textTransform: "none",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    '&:hover': {
      backgroundColor: "#006CA875",
    },
    [theme.breakpoints.down('xl')]: {
      margin: "4px 0px 8px 0px",
      padding: "4px 52px"
    },
  },
  pdfText: {
    color: "#06A706",
    width: "100px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    [theme.breakpoints.down('xl')]: {
      width: "70px",
    },
    [theme.breakpoints.down('md')]: {
      width: "80px",
    },
  },
  pdfTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  step4InputWidth: {
    width: "270px",
    [theme.breakpoints.down('xl')]: {
      width: "210px",
    },
  },
})