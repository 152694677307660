import { Grid, Paper, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import { connect } from "react-redux";
import styles from "./styles";

const KeyList = withStyles(styles)((props) => {
  const { classes, text, buttonText, hideBorder } = props
  return (
    <div className={classes.listItem} style={hideBorder ? { border: "0px" } : {}}>
      <div className={classes.item}>
        <Typography className={classes.text}>{text}</Typography>
      </div>
      {
        buttonText && buttonText.length > 0 &&
        <div className={classes.buttonDiv}>
          {
            buttonText.map((ele, index) => (
              <>
                <div key={index}>
                  <div className={classes.button}>
                    {ele}
                  </div>
                  <div className={classes.bottomDiv} />
                </div>
                {index < buttonText.length - 1 ? <div className={classes.commaDiv}>,</div> : ""}
              </>
            ))
          }
        </div>
      }
    </div >
  )
})

const USER = `${process.env.REACT_APP_USER}`

function KeyShortcuts(props) {
  const { classes, layoutType } = props;

  const isNextAdmin = layoutType === "nextadmin";
  const gridSpan = isNextAdmin ? 3 : 4;

  return (
    <Paper elevation={2} className={classes.paper}>
      <div className={classes.list}>
        <Grid container className={classes.gridContainer}>
          <Grid item sm={gridSpan} xs={12} className={classes.gridItem}>
            <KeyList text={"Help"} buttonText={["H", "h"]} />
            <KeyList text={"Buy"} buttonText={["f1"]} />
            <KeyList text={"Sell"} buttonText={["f2"]} />
            <KeyList text={"Order View"} buttonText={["C", "c"]} />
            <KeyList text={"Watchlist"} buttonText={["W", "w"]} />
            <KeyList text={"Create Watchlist"} buttonText={["F4"]} />
            {/* <KeyList text={"Add Scrips to Watchlist"} buttonText={["insert"]} /> */}
            <KeyList text={"Delete Scrip"} buttonText={["del"]} />
            <KeyList text={"Change Watchlist"} buttonText={["alt + 1", "alt + 2", "alt + 3", "alt + 4", "alt + 5"]} />
          </Grid>
          <Grid item sm={gridSpan} xs={12} className={classes.gridItem}>
            <KeyList text={"Order Book"} buttonText={["O", "o"]} />
            <KeyList text={"Position"} buttonText={["P", "p"]} />
            <KeyList text={"Holdings"} buttonText={["D", "d"]} />
            <KeyList text={"Trade"} buttonText={["T", "t"]} />
            <KeyList text={"Margin"} buttonText={["M", "m"]} />
            <KeyList text={"Previous Settlement"} buttonText={["U", "u"]} />
            <KeyList text={"Modify Order"} buttonText={["shift + f2"]} />
            <KeyList text={"Cancel Order"} buttonText={["shift + f1"]} />
            {USER === "client" && <KeyList text={"Repeat Order"} buttonText={["R", "r"]} />}
            <KeyList text={"Add Positions"} buttonText={["+"]} />
          </Grid>
          <Grid item sm={gridSpan} xs={12} className={classes.gridItem} style={isNextAdmin ? {} : { borderRight: "0px" }}>
            <KeyList text={"Square-off Order"} buttonText={["*"]} />
            <KeyList text={"Holdings Sell Order"} buttonText={["-"]} />
            {/* <KeyList text={"Chart"} buttonText={["C", "c"]} /> */}
            <KeyList text={"Filter"} buttonText={["F", "f"]} />
            <KeyList text={"Search"} buttonText={["S", "s"]} />
            <KeyList text={"Navigate"} buttonText={["←", "↑", "↓", "→"]} />
            <KeyList text={"Deselect a component"} buttonText={["esc"]} />
            <KeyList text={"Limit"} buttonText={["L", "l"]} />
            {USER === "client" && <KeyList text={"Profile"} buttonText={["alt + P", "alt + p"]} />}
            <KeyList text={"Fullview & Close Fullview"} buttonText={["alt + M", "alt + m"]} />
          </Grid>
          {isNextAdmin &&
            <Grid item sm={gridSpan} xs={12} className={classes.gridItem} style={{ borderRight: "0px" }}>
              <KeyList text={"Cancelled Orders"} buttonText={["ctrl + c"]} />
              <KeyList text={"Fully Executed Orders"} buttonText={["f5"]} />
              <KeyList text={"Expired Orders"} buttonText={["f6"]} />
              <KeyList text={"Freezed Orders"} buttonText={["f7"]} />
              <KeyList text={"InProcess Orders"} buttonText={["f9"]} />
              <KeyList text={"Partially Executed Orders"} buttonText={["ctrl + e"]} />
              <KeyList text={"Pending Orders"} buttonText={["f3"]} />
              <KeyList text={"Rejected Orders"} buttonText={["shift + f4"]} />
              <KeyList text={"Triggered Orders"} buttonText={["f10"]} />
              <KeyList text={"AMO Orders"} buttonText={["ctrl + a"]} />
              <KeyList text={"Trade Report"} buttonText={["f8"]} />
              <KeyList text={"Position Report"} buttonText={["ctrl + p"]} />
              <KeyList text={"Holdings Report"} buttonText={["ctrl + h"]} />
              <KeyList text={"Margin Report"} buttonText={["ctrl + m"]} />
              <KeyList text={"Previous Settlement Report"} buttonText={["ctrl + u"]} />
            </Grid>
          }
        </Grid>
      </div>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  const { layout: { layoutType } } = state;
  return { layoutType };
};

export default connect(mapStateToProps)(withStyles(styles)(KeyShortcuts));
