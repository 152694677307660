export default (theme) => ({
  root: {
    width: 900,
    height: 500,
    padding: 20,
    '&.step5': {
      width: 600,
      height: 400
    },
    [theme.breakpoints.down('lg')]: {
      width: 750,
      height: 450,
      padding: 12,
      '&.step5': {
        width: 400
      }
    },
    [theme.breakpoints.down('md')]: {
      width: 600,
      height: 400,
      padding: 6,
      '&.step5': {
        width: 400,
        height: 350
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: 520,
      height: 350,
      padding: 0,
      '&.step5': {
        width: 400,
        height: 350
      }
    }
  },
  heading: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 28
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    }
  },
  blueText: {
    color: '#497CAC',
    fontWeight: 'bold'
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  text: {
    color: theme.palette.text.primary,
    textAlign: 'justify'
  },
  imageGrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    color: '#ffffff',
    padding: '8px 32px',
    height: 46,
    backgroundColor: '#497CAC',
    '&:hover': {
      backgroundColor: '#497CACB5'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '6px 28px',
      height: 40
    },
    [theme.breakpoints.down('md')]: {
      height: 32
    },
    [theme.breakpoints.down('sm')]: {
      height: 30
    },
    '&:disabled': {
      backgroundColor: '#ffffff1f',
      color: '#ffffff4d'
    }
  },
  containerGrid: {
    paddingTop: 32,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 24
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 12
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 8
    }
  },
  description: {
    color: theme.palette.text.primary,
    fontSize: 16,
    textAlign: 'justify',
    paddingBottom: 24,
    paddingTop: 2,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  setp2Description: {
    color: theme.palette.text.primary,
    padding: '42px 0px'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  input: {
    padding: 12,
    fontSize: 18,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: 10,
      fontSize: 16
    },
    [theme.breakpoints.down('md')]: {
      padding: 8,
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
      fontSize: 12
    },
    '&:disabled': {
      background: '#c5c6c8',
      color: '#808080'
    }
  },
  resend: {
    color: '#497CAC',
    // textDecoration: 'underline',
    cursor: 'pointer'
  },
  inputDiv: {
    display: 'flex',
    padding: '32px 0px 4px'
  },
  otpInputField: {
    width: 300,
    marginRight: 12,
    [theme.breakpoints.down('lg')]: {
      width: 250
    },
    [theme.breakpoints.down('md')]: {
      width: 200
    },
    [theme.breakpoints.down('sm')]: {
      width: 150
    }
  },
  email: {
    padding: '32px 0px',
    color: theme.palette.text.primary
  },
  otpText: {
    paddingTop: 32,
    color: theme.palette.color15.default
  },
  keyText: {
    paddingTop: 20,
    color: '#006CA8',
    cursor: 'pointer'
  },
  step4Description: {
    padding: '20px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0px'
    }
  },
  buttonDiv: {
    paddingTop: 40,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 32
    }
  },
  cancelButton: {
    marginLeft: 16,
    padding: '8px 32px',
    color: '#006ca8',
    border: '1px solid #006ca8',
    [theme.breakpoints.down('lg')]: {
      padding: '6px 28px'
    },
    "&:hover": {
      backgroundColor: '#ffffff14',
      border: '1px solid #006ca8',
    }
  },
  passwordInput: {
    width: 315,
    [theme.breakpoints.down('md')]: {
      width: 260
    }
  },
  totpInputDiv: {
    padding: '12px 0px 8px 0px',
    display: 'flex'
  },
  totpInputField: {
    width: 150,
    marginRight: 12
  },
  note: {
    paddingTop: 36,
    color: theme.palette.color15.default,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 28
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 16
    }
  },
  qrGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center'
  },
  image: {
    width: 220,
    [theme.breakpoints.down('md')]: {
      width: 170
    },
    [theme.breakpoints.down('sm')]: {
      width: 150
    }
  },
  error: {
    color: '#f54040'
  },
  mobileImage: {
    [theme.breakpoints.down('md')]: {
      height: 300
    },
    [theme.breakpoints.down('sm')]: {
      height: 250
    }
  },
  errorDiv: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '1px solid #f5c6cb',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '4px',
    textAlign: 'center',
    margin: '-12px -16px 12px -16px',
    [theme.breakpoints.down('lg')]: {
      padding: '3px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px'
    }
  },
  errorDivText: {
    color: '#721c24'
  }
});