import { Button, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import buttonHelper from "helpers/buttonHelper";
import React from "react";
import styles from './styles';
import Top from "./top";

const displayValue = {
  type: {
    B: "Buy",
    S: "Sell",
    SS: "Short Sell"
  },
  orderType: {
    LIMIT: "Limit",
    MKT: "Market",
    SL: "Stop Loss"
  },
  validTill: {
    GFD: "Good For Day",
    IOC: "Immediate or Cancel",
    GTD: "Good Till Day",
    GTC: "Good Till Cancel",
  }
}

const USER = `${process.env.REACT_APP_USER}`

function OrderConfirm(props) {
  const { classes, scrip, activeStep, handleExchangeChange, showExchange, selectClient, reduxForm, values, pushOrder, handleBack, submitting, splitPopup } = props
  const { exchange, type, price, triggerPrice, quantity, disclosedQuantity, product, validTill, orderType, validityDate, lot } = values
  const { segment, volumeFreezeQty } = scrip;
  const { splitQty, remainingQty } = splitPopup;

  function splitQuantity() {
    if (volumeFreezeQty) {
      return (
        <>
          {splitQty > 0 && <Typography className={classes.approxValue}>{`${splitQty} ${splitQty > 1 ? "Orders" : "Order"} of ${volumeFreezeQty} Qty`}</Typography>}
          {remainingQty > 0 && <Typography className={classes.approxValue}>{`1 Order of ${remainingQty} Qty`}</Typography>}
        </>
      )
    } else {
      return (
        <Typography className={classes.approxValue}>--</Typography>
      )
    }
  }

  return (
    <Grid container style={{ margin: "8px 0px" }}>
      <Grid item xs={12}>
        <div style={{ background: type === "B" ? "#299D00" : "#f54040" }} className={classes.colorDiv}></div>
        <Grid container className={classes.confirmFieldSet}>
          <Grid item xs={12} className={classes.confirmTop}>
            <Top reduxForm={reduxForm} selectedScrip={scrip || {}} activeStep={activeStep} handleExchangeChange={handleExchangeChange} showExchange={showExchange} />
          </Grid>
          <Grid item xs={12} style={{ margin: "0px 16px" }}>
            <Grid container className={[classes.paddingGrid, "border"].join(" ")}>
              <Grid item xs={USER === "client" && segment !== "FNO" ? 4 : 3} className={classes.confirmGridItem}>
                <Typography className={classes.blueText}>Exchange</Typography>
                <Typography className={classes.approxValue}>{exchange}</Typography>
              </Grid>
              <Grid item xs={USER === "client" && segment !== "FNO" ? 4 : 3} className={classes.confirmGridItem}>
                <Typography className={classes.blueText}>Action</Typography>
                <Typography className={classes.action} style={{ color: type === "B" ? "#299D00" : "#f54040" }}>{displayValue.type[type]}</Typography>
              </Grid>
              {
                USER === "client" && segment !== "FNO" ?
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <Typography className={classes.blueText}>Trigger Price</Typography>
                    <Typography className={classes.approxValue}>₹ {Number(triggerPrice).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
                  </Grid>
                  :
                  <>
                    <Grid item xs={3} className={classes.confirmGridItem}>
                      <Typography className={classes.blueText}>Order Type</Typography>
                      <Typography className={classes.approxValue}>{displayValue.orderType[orderType]}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "center" }}>
                      <Typography className={classes.blueText}>Price</Typography>
                      <Typography className={classes.approxValue}>₹ {Number(price).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
                    </Grid>
                  </>
              }
            </Grid>
            <Grid container className={[classes.paddingGrid, USER === "client" && segment === "FNO" && segment === "BSE" ? "" : "border"].join(" ")}>
              {
                USER === "client" && segment !== "FNO" ?
                  <>
                    <Grid item xs={validTill === "GTD" ? 3 : 4} className={classes.confirmGridItem}>
                      <Typography className={classes.blueText}>Order Type</Typography>
                      <Typography className={classes.approxValue}>{displayValue.orderType[orderType]}</Typography>
                    </Grid>
                    <Grid item xs={validTill === "GTD" ? 3 : 4} className={classes.confirmGridItem}>
                      <Typography className={classes.blueText}>Price</Typography>
                      <Typography className={classes.approxValue}>₹ {Number(price).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
                    </Grid>
                    <Grid item xs={validTill === "GTD" ? 3 : 4} className={classes.confirmGridItem} style={validTill === "GTD" ? {} : { borderRight: 0 }}>
                      <Typography className={classes.blueText}>{segment === "CURR" ? "Lot" : "Quantity"}</Typography>
                      <Typography className={classes.approxValue}>{segment === "CURR" ? lot : quantity}</Typography>
                    </Grid>
                    {
                      validTill === "GTD" ?
                        <Grid item xs={3} style={{ textAlign: "center" }}>
                          <Typography className={classes.blueText}>Disclosed Qty</Typography>
                          <Typography className={classes.approxValue}>{disclosedQuantity || 0}</Typography>
                        </Grid>
                        :
                        <></>
                    }
                  </>
                  :
                  <>
                    <Grid item xs={3} className={classes.confirmGridItem}>
                      <Typography className={classes.blueText}>Trigger Price</Typography>
                      <Typography className={classes.approxValue}>₹ {Number(triggerPrice).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.confirmGridItem}>
                      <Typography className={classes.blueText}>{/* segment === "CURR" ? "Lot" :  */"Quantity"}</Typography>
                      <Typography className={classes.approxValue}>{/* segment === "CURR" ? lot :  */quantity}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.confirmGridItem}>
                      <Typography className={classes.blueText}>Product Type</Typography>
                      <Typography className={classes.approxValue}>{product}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "center" }}>
                      <Typography className={classes.blueText}>Validity</Typography>
                      <Typography className={classes.approxValue}>{displayValue.validTill[validTill]}</Typography>
                    </Grid>
                  </>
              }
            </Grid>
            {
              USER === "client" && segment === "FNO" ?
                /* exchange === "NSE" && */ (splitQty > 1 || (splitQty > 0 && remainingQty > 0)) ?
                  <Grid container className={classes.paddingGrid}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography className={classes.blueText}>Split Order</Typography>
                      {splitQuantity()}
                    </Grid>
                  </Grid>
                  :
                  <></>
                :
                <Grid container className={classes.paddingGrid}>
                  {
                    USER === "client" ?
                      <>
                        {
                          validTill === "GTD" ?
                            <></>
                            :
                            <Grid item xs={4} className={classes.confirmGridItem}>
                              <Typography className={classes.blueText}>Disclosed Qty</Typography>
                              <Typography className={classes.approxValue}>{disclosedQuantity || 0}</Typography>
                            </Grid>
                        }
                        <Grid item xs={4} className={classes.confirmGridItem}>
                          <Typography className={classes.blueText}>Product Type</Typography>
                          <Typography className={classes.approxValue}>{product}</Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.confirmGridItem} style={validTill === "GTD" ? {} : { borderRight: 0 }}>
                          <Typography className={classes.blueText}>Validity</Typography>
                          <Typography className={classes.approxValue}>{displayValue.validTill[validTill]}</Typography>
                        </Grid>
                        {
                          validTill === "GTD" ?
                            <Grid item xs={4} style={{ textAlign: "center" }}>
                              <Typography className={classes.blueText}>Validity Date</Typography>
                              <Typography className={classes.approxValue}>{validityDate || "--"}</Typography>
                            </Grid>
                            :
                            <></>
                        }
                      </>
                      :
                      segment === "FNO" ?
                        <>
                          {/* exchange === "NSE" &&  */splitQty > 0 && <Grid item xs={4} className={classes.confirmGridItem} style={{ textAlign: "center" }}>
                            <Typography className={classes.blueText}>Split Order</Typography>
                            {splitQuantity()}
                          </Grid>}
                          <Grid item xs={/* exchange === "NSE" && */ splitQty > 0 ? 8 : 12} style={{ textAlign: "center" }}>
                            <Typography className={classes.blueText}>Client Code</Typography>
                            <Typography className={classes.approxValue} style={{ paddingLeft: "6px" }}>
                              {Object.keys(selectClient).length > 0 && selectClient.ClientCode !== "" ? selectClient.ClientName !== "" ? `${selectClient.ClientName} - ${selectClient.ClientCode}` : selectClient.ClientCode : "--"}
                            </Typography>
                          </Grid>
                        </>
                        :
                        <>
                          <Grid item xs={validTill === "GTD" ? 3 : 4} className={classes.confirmGridItem}>
                            <Typography className={classes.blueText}>Disclosed Qty</Typography>
                            <Typography className={classes.approxValue}>{disclosedQuantity || 0}</Typography>
                          </Grid>
                          {
                            validTill === "GTD" ?
                              <Grid item xs={3} className={classes.confirmGridItem}>
                                <Typography className={classes.blueText}>Validity Date</Typography>
                                <Typography className={classes.approxValue}>{validityDate || 0}</Typography>
                              </Grid>
                              :
                              <></>
                          }
                          <Grid item xs={validTill === "GTD" ? 6 : 8} style={{ textAlign: "center" }}>
                            <Typography className={classes.blueText}>Client Code</Typography>
                            <Typography className={classes.approxValue} style={{ paddingLeft: "6px" }}>
                              {Object.keys(selectClient).length > 0 && selectClient.ClientCode !== "" ? selectClient.ClientName !== "" ? `${selectClient.ClientName} - ${selectClient.ClientCode}` : selectClient.ClientCode : "--"}
                            </Typography>
                          </Grid>
                        </>
                  }
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container className={classes.buttonGrid}>
              <Grid item lg={6} xs={8}>
                <Button onClick={handleBack} disabled={submitting} className={[classes.cancel, classes.buttonFixedWidth].join(" ")}>Back</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className={classes.buttonGrid}>
              <Grid item lg={6} xs={8} style={{ position: 'relative' }}>
                <Button onClick={(e) => buttonHelper.onSingleClick(e, pushOrder)} autoFocus={true} disabled={submitting} className={[classes.order, classes.containedBlue].join(" ")}>
                  {(submitting ? (splitQty > 1 || (splitQty > 0 && remainingQty > 0)) ? '' : <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} /> : '')}
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(combineStyles(styles, commonStyles))(OrderConfirm)