import React, { useState } from 'react';
import { FormControl, Button, Grid, Typography, CircularProgress, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import axios from 'helpers/interceptor';
import { openConfirmDialog, closeConfirmDialog } from 'redux/ConfirmDialog';
import { CommonActions } from 'redux/Common';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { CssSelect, SelectOutlinedInput } from 'widgets/order/customfields';
import { KeyboardArrowDown as Arrow } from "@mui/icons-material";
import styles from "./styles";

const URL = {
  "EQ": "/ms-mf-onlinereport/eqsip/cancel",
  // "MF": "/ms-mf-sip/mfsip/cancel_sip/changeSipOrder"
  "MF": "/ms-mf-sip/mfsip/cancel"
}

const cancelReasons = [
  {
    value: '07 - Goal Achieved',
    label: 'Goal Achieved'
  },
  {
    value: '10 - Modifications in bank/mandate/date etc',
    label: 'Modifications in bank/mandate/date etc'
  },
  {
    value: '05 - Wish to invest in other schemes',
    label: 'Wish to invest in other schemes'
  },
  {
    value: '02 - Scheme not performing',
    label: 'Scheme not performing'
  },
  {
    value: '01 - Non availability of Funds',
    label: 'Non availability of Funds'
  }
]

function CancelSip(props) {
  const { classes, rowData, closeConfirmDialog, openConfirmDialog, onCancel } = props

  const [loading, setLoading] = useState({ submit: false, error: "" })

  let schema = Yup.object().shape({
    reason: Yup.mixed()
      .oneOf(['07 - Goal Achieved', '10 - Modifications in bank/mandate/date etc', '05 - Wish to invest in other schemes',
        '02 - Scheme not performing', '01 - Non availability of Funds',])
      .required("Reason is required")
  })

  const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
    initialValues: {
      reason: '07 - Goal Achieved'
    },
    validationSchema: schema,
    onSubmit: values => {
      setLoading({ submit: true, error: "" })
      handleClose()
    }
  })

  const handleCancelConfirm = () => {
    closeConfirmDialog()
    CommonActions.setRefreshReport("registeredsip")
  }

  const handleClose = () => {
    let payload = {
      id: rowData.id,
      reason: values.reason || ""
    }
    let obj = {
      content: "Your sip has been cancelled successfully",
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: closeConfirmDialog
    }
    axios.post(`${process.env.REACT_APP_API_URL}${URL[rowData.category]}`, payload).then(resp => {
      if (resp.data.success) {
        obj.content = resp.data.success.orderId && resp.data.success.orderId != "0" ? resp.data.success.msg || "Your sip has been cancelled successfully" + " Order Id is " + resp.data.success.orderId + "." : resp.data.success.msg || "Your sip has been cancelled successfully."
        obj.onClose = handleCancelConfirm
        onCancel()
        openConfirmDialog(obj)
      } else {
        setLoading({ submit: false, error: resp.data.error || "Error in cancelling order, Please try again after some time." })
      }
    }).catch(error => {
      setLoading({ submit: false, error: "There is some technical issue, please try again later" });
    })
  }

  return (
    <div className={classes.root}>
      {loading?.error && <div className={classes.errorDiv} style={{ width: '100%' }}><Typography className={classes.errorDivText}>{loading.error || '--'}</Typography></div>}
      <div className={classes.form}>
        <form autoComplete={"off"} onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.fieldDiv}>
                <Typography className={classes.fieldText}>Select reason to cancel SIP</Typography>
                <CssSelect
                  id="reason"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  error={touched.reason && errors.reason ? true : false}
                  onKeyUp={() => setFieldTouched("reason", true)}
                  className={classes.select}
                  input={<SelectOutlinedInput />}
                  variant="standard"
                  IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
                >
                  {
                    cancelReasons.map((ele) => (
                      <MenuItem key={ele.value} value={ele.value} className={classes.menuItem}>{ele.label}</MenuItem>
                    ))
                  }
                </CssSelect>
                {errors.reason && touched.reason && (<p className={classes.errorText}>{errors.reason}</p>)}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button style={{ marginBottom: "8px" }} autoFocus={true} disabled={loading.submit} className={[classes.button, classes.cancel].join(" ")} type="submit">
                {loading.submit && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { rowData: state.dialog.data }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CancelSip))