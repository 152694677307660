import { Close as CloseIcon } from "@mui/icons-material";
import { Grid, IconButton, Paper, Popper, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect } from 'react';
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import { PopperActions, POPPERS } from 'redux/Popper';
import styles from "./styles";

function PaperDraggableComponent(props) {
    return (
        <Draggable
            handle="#form-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper
                style={{
                    position: 'absolute',
                    top: '25vh',
                    left: '30vw',
                    // height: '25vh',
                    // width: '25vw',
                }}
                {...props}
            />
        </Draggable>
    );
}

function CustomPopper(props) {
    const { isOpen, data, anchorEl, classes, popperTitle, popperChild } = props;

    const onClosePopper = () => {
        document.removeEventListener('keydown', onKeyDown, false);
        PopperActions.closeQuickQuote();
    }

    const onKeyDown = event => {
        if (event.keyCode === 27) {
            onClosePopper();
        }
    }

    useEffect(() => {
        if (isOpen)
            document.addEventListener('keydown', onKeyDown, false);
    }, [isOpen]);

    let ChildComponent;

    switch (popperChild) {
        // case value:

        //     break;

        default:
            ChildComponent = lazy(() => import('widgets/quickQuote'));
            break;
    }

    return (
        <Draggable handle="#popper-header" cancel={'[class*="MuiDialogContent-root"]'}>
            <Popper id={'popper-main'} open={isOpen} elevation={24} style={{ zIndex: 1000 }}>
                <PaperDraggableComponent>
                    <div id='popper-header' className={classes.header}>
                        <Grid container>
                            <Grid item lg={11} md={11} sm={11} xs={11} className={[/* classes.header, */ classes.headerGrid]}>
                                <Typography className={classes.title}>{popperTitle || 'Quick Quote'}</Typography>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1} >
                                <Tooltip title="Close" aria-label="close">
                                    <IconButton aria-label="close"
                                        className={classes.closeIcon}
                                        onClick={() => onClosePopper()}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.content}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <ChildComponent
                                propScrip={data || null}
                            /* onSubmit={onSubmit} onCancel={onCancel} modalChild={modalChild} data={data} */ />
                            {/* <Typography>The content of the Popper.</Typography> */}
                        </Suspense>
                    </div>
                </PaperDraggableComponent>
            </Popper>
        </Draggable>
    );
}

CustomPopper.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

CustomPopper.defaultProps = {
    isOpen: false,
};

const mapStateToProps = (state) => {
    const { popper: {
        [POPPERS.QUICK_QUOTE]: isOpen,
        [POPPERS.QUICK_QUOTE + '_data']: data
    } } = state;
    return { isOpen, data };
}

export default connect(mapStateToProps)(withStyles(styles)(CustomPopper));
