import sp from 'schemapack';

export const quotesSchema = sp.build({
  shortCode: 'string',
  token: 'string',
  change: 'string',
  ltp: 'string',
  percentChange: 'string',
  askPrice: 'string',
  bidPrice: 'string',
  open: 'string',
  high: 'string',
  low: 'string',
  close: 'string',
  ltq: 'string',
  yearlyHigh: 'string',
  yearlyLow: 'string',
  avgPrice: 'string',
  turnOverData: 'string',
  oi: 'string',
  oiPercentChange: 'string',
  upperCircuit: 'string',
  lowerCircuit: 'string',
  volume: 'string',
  ltt: 'string',
  segment: 'string',
  yearlyHighDate: 'string',
  yearlyLowDate: 'string',
  todayHighTime: 'string',
  todayLowTime: 'string'
});

export const quotesSchemaShort = sp.build({
  shortCode: 'string',
  token: 'string',
  change: 'string',
  ltp: 'string',
  percentChange: 'string',
  askPrice: 'string',
  bidPrice: 'string'
});

export const depthSchema = sp.build({
  token: 'string',
  bidQty1: 'string',
  bidQty2: 'string',
  bidQty3: 'string',
  bidQty4: 'string',
  bidQty5: 'string',
  bidPrice1: 'string',
  bidPrice2: 'string',
  bidPrice3: 'string',
  bidPrice4: 'string',
  bidPrice5: 'string',
  bdNoOrder1: 'string',
  bdNoOrder2: 'string',
  bdNoOrder3: 'string',
  bdNoOrder4: 'string',
  bdNoOrder5: 'string',
  askQty1: 'string',
  askQty2: 'string',
  askQty3: 'string',
  askQty4: 'string',
  askQty5: 'string',
  askPrice1: 'string',
  askPrice2: 'string',
  askPrice3: 'string',
  askPrice4: 'string',
  askPrice5: 'string',
  askNoOrder1: 'string',
  askNoOrder2: 'string',
  askNoOrder3: 'string',
  askNoOrder4: 'string',
  askNoOrder5: 'string',
  totalBuyQty: 'string',
  totalSellQty: 'string',
  openPrice: 'string',
  yrlLow: 'string',
  segment: 'string',
  scripName: 'string',
  symbol: 'string',
  updateOn: 'string'
});
