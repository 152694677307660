import React, { Suspense, lazy, useMemo } from 'react';
// import * as SVG from 'icons/navigation';

export default function SvgComponent(props) {
  const Component = useMemo(() => {
    return lazy(() =>
      import('icons/navigation').then((module) => ({
        default: module[props.iconname]
      }))
    );
  }, [props.iconname]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...props}>
      <Suspense fallback={''}>
        <Component {...props} />
      </Suspense>
    </div>
  );
}