import axios from 'helpers/interceptor';
import { getPayload1 } from "helpers/subscription";
import { isPipe } from "services";
import { reportService } from "services/reports";

export const marketDepthService = {
  getMarketDepth
};

const colMappings = {
  tranCode: 0, exchId: 1, segmentId: 2, token: 3, volumeTT: 4, lastTrdPrice: 5, lastTrdQty: 6, lastTrdTime: 7, avgTrdPrice: 8,
  bidQty1: 9, bidQty2: 10, bidQty3: 11, bidQty4: 12, bidQty5: 13, bidPrice1: 14, bidPrice2: 15, bidPrice3: 16, bidPrice4: 17, bidPrice5: 18,
  bdNoOrder1: 19, bdNoOrder2: 20, bdNoOrder3: 21, bdNoOrder4: 22, bdNoOrder5: 23, askQty1: 24, askQty2: 25, askQty3: 26, askQty4: 27,
  askQty5: 28, askPrice1: 29, askPrice2: 30, askPrice3: 31, askPrice4: 32, askPrice5: 33, askNoOrder1: 34, askNoOrder2: 35, askNoOrder3: 36,
  askNoOrder4: 37, askNoOrder5: 38, totalBuyQty: 39, totalSellQty: 40, closePrice: 41, openPrice: 42, hPrice: 43, lPrice: 44, noOfTrd: 45,
  value: 46, trdValueFlg: 47, wtdAvg: 48, yrlHigh: 49, yrlLow: 50, oi: 51, dayHighOI: 52, dayLowOI: 53, ex: 54, segment: 55, scripName: 56,
  symbol: 57, oiChange: 58, oiPercentChange: 59, previousOi: 60, upperCircuit: 61, lowerCircuit: 62, circuitPercent: 63,
  bseScripCode: 64, exchange: 65, shortCode: 66, updateOn: 67
}

function getMarketDepth(scrip, addToken) {
  const { token, shortCode, exchange, bseScripCode } = scrip;

  const payload = [isPipe ? {
    token: exchange === 'BSE' ? bseScripCode || token : token,
    shortCode
  } : getPayload1(scrip, addToken)];

  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_FEED_URL}${isPipe
      ? '/ms-watchlist-service/watchlist/pipe/fullDepth'
      : '/ms-feed-service/content/market/depth'}`,
    data: payload,
    timeout: 1000
  };

  return axios(requestOptions)
    .then(response => {
      if (response.data.success?.length > 0) {
        const depth = reportService.convertPipeToArray(response.data.success, colMappings);
        return depth[0];
      } else if (response.data.error?.length > 0) {
        return { error: response.data.error[0].error };
      }
    })
    .catch(function (error) {
      console.log(error);
    })
}

function getMarketDepthOld(payload) {
  const USER = `${process.env.REACT_APP_USER}`;

  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_FEED_URL}/ms-feed-service/content/market/depth`,
    data: payload,
    timeout: 1000
  };

  return axios(requestOptions)
    .then(response => {
      // console.log(response.data.success)
      return response.data.success[0];
    })
    .catch(function (error) {
      console.log(error);
    })
}
