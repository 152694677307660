import { store } from 'helpers';
import axios from "helpers/interceptor";

//Actions
const INIT_TOUR = 'INIT_TOUR';
const OPEN_TOUR = 'OPEN_TOUR';
const CLOSE_TOUR = 'CLOSE_TOUR';
const TOGGLE = 'TOGGLE_TOUR';

export const TourActions = {
    initTour,
    openTour,
    closeTour,
    toggle,
};

function initTour() {
    // sendToAPI(true);
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/getappguideflag`
    };

    return axios(requestOptions)
        .then(response => {
            if (response?.data?.success) {
                const { flag } = response.data.success;

                if (!flag) {
                    store.dispatch({ type: INIT_TOUR, section: 'init' });
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        })
}

function toggle() {
    store.dispatch({ type: TOGGLE });
}

function openTour(section) {
    store.dispatch({ type: OPEN_TOUR, section });
}

function closeTour() {
    sendToAPI();
    store.dispatch({ type: CLOSE_TOUR });
}

function sendToAPI(flag) {
    if (flag || store.getState().tour.init) {
        const requestOptions = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/appguide`,
            data: { flag: flag ? !flag : true },
        };

        return axios(requestOptions)
            .then(response => {
                if (response?.data?.success) {
                    console.log('Tour: mark as read', response.data.success);
                } else {
                    console.log('Tour: mark as read error', response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
}

//Reducer
export function tourReducer(state = InitialState, action) {
    switch (action.type) {
        case INIT_TOUR:
            return {
                ...state,
                init: true,
                isOpen: true,
                section: 'init'
            };
        case TOGGLE:
            return {
                ...state,
                isOpen: !state.isOpen
            };
        case OPEN_TOUR:
            return {
                ...state,
                isOpen: true,
                section: action.section
            };
        case CLOSE_TOUR:
            return {
                ...state,
                init: false,
                isOpen: false
            };
        default:
            return state;
    }
}

const InitialState = {
    init: false,
    isOpen: false,
    section: 0,
};