import { setCookieNew } from "helpers/cookies";

export function goToLogin() {
    if(process.env.REACT_APP_USER == 'admin'){
        setCookieNew('redirectTo', btoa(window.location.href));
        window.location.href = process.env.REACT_APP_ENT_URL + "?referer=" + btoa(window.location.href);
    }
    else
        window.location.href = process.env.REACT_APP_LOGIN_URL + "?referer=" + btoa(window.location.href);
}

export function goToChangePass() {
    window.location.href = process.env.REACT_APP_LOGIN_URL + "changepassword/5" + "?referer=" + btoa(window.location.href);
}

export function goToContentWebsite() {
    window.location.href = process.env.REACT_APP_CORPORATE_URL;//"?referer=" + btoa(window.location.href);
}
