import React, { useState, lazy } from "react"
import { Tab, Box, useTheme, useMediaQuery } from "@mui/material"
import styles from "./styles"
import { withStyles } from "@mui/styles";
import { portfolioTabs } from "./data";
import { CssTabs } from "widgets/Portfolio/components/Elements"
import { LazyLoad } from "components/LazyLoad";

const Product = LazyLoad(lazy(() => import("./Product")));
const Segment = LazyLoad(lazy(() => import("./Segment")));

export function TabPanel(props) {
  const { children, value, index, boxClass, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={boxClass}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Portfolio(props) {
  const { classes } = props
  
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.paper}>
      <CssTabs
        value={value}
        onChange={handleChange}
        className={classes.tabs}
        aria-label="full width tabs example"
      >
        {
          portfolioTabs.map((ele, index) => (
            <Tab key={index} className={value === index ? classes.activeTab : classes.tab} tabIndex={index} label={ele} {...a11yProps(index)} />
          ))
        }
      </CssTabs>
      <TabPanel value={value} index={0} boxClass={classes.boxDiv}>
        <Product matchesLg={matchesLg} matchesMd={matchesMd} matchesSm={matchesSm} />
      </TabPanel>
      <TabPanel value={value} index={1} boxClass={classes.boxDiv}>
        <Segment matchesLg={matchesLg} matchesMd={matchesMd} matchesSm={matchesSm}  />
      </TabPanel>
    </div>
  )
}

export default withStyles(styles)(Portfolio)