import { Box, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { DialogActions } from "redux/Dialog";
import { TourActions } from 'redux/Tour';

const styles = (theme) => ({
    button: {
        margin: 5
    }
});

function TourDialog(props) {
    const { classes } = props;

    const _Button = ({ title, section }) => {
        return (
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                    TourActions.openTour(section);
                    DialogActions.dialogClose();
                }}>
                {title}
            </Button>
        );
    };

    return (
        <Box>
            {/* This is Tour Dialog */}
            <_Button title={'Sidebar'} section={1} />
            <_Button title={'Topbar'} section={2} />
            <_Button title={'Watchlist'} section={3} />
            <_Button title={'All'} section={0} />
        </Box>
    );
}

export default withStyles(styles)(TourDialog);
