import React, { useState, useContext } from 'react';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { Button, CircularProgress, Link, Paper, Typography } from '@mui/material';
import getCookie, { setCookie } from 'helpers/cookies';
import axios from "helpers/interceptor"
import { DialogActions } from 'redux/Dialog';
import { UserProfileContext } from "Main";

const data = {
  heading: 'Risk Disclosure on Derivatives',
  sebiLink: 'https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html',
  texts: [
    '9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.',
    'On an average loss makers registered net trading loss close to ₹ 50,000.',
    'Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.',
    'Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.'
  ],
  submit: '/ms-trading-authservice/riskdisclamerlog'
}

function FnoRiskPopup(props) {
  const { classes, onCancel } = props;

  const [submitting, setSubmitting] = useState(false)
  const profile = useContext(UserProfileContext)

  function handleSubmit() {
    setSubmitting(true)
    setCookie('fnorisk', true)
    axios.post(`${process.env.REACT_APP_API_URL}${data.submit}`, { riskconfirmation: "YES" }).then(resp => {
      if (resp.data.success) {
        onCancel();
        let nominee = getCookie('nominee') && new Date(Number(getCookie('nominee'))) || false;
        if (!profile.isNominee && (!nominee || (nominee && (`${nominee.getDate()}-${nominee.getMonth()}` !== `${new Date().getDate()}-${new Date().getMonth()}`)))) {
          DialogActions.openNomineePopup()
        } else if (getCookie("showBanner") === "Yes") {
          DialogActions.openBannersPopup()
        }
      }
      setSubmitting(false)
    }).catch(() => {
      setSubmitting(false)
    })
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.headerDiv}>
        <Typography className={classes.blueText}>{data.heading}</Typography>
      </div>
      <ul className={classes.listBullet}>
        {
          data.texts.map(ele => <li>
            <Typography className={classes.listText}>{ele}</Typography>
          </li>)
        }
      </ul>
      <div className={classes.linkDiv}>
        <Link underline='always' href={data.sebiLink} target="_blank" className={classes.link}>Tap to know more</Link>
      </div>
      <Button id="fnorisk" disabled={submitting} variant='contained' onClick={handleSubmit} className={classes.button} color="primary">
        {submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Ok, got it!
      </Button>
    </Paper>
  )
}

export default withStyles(styles)(FnoRiskPopup);