import { Grid, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import { connect } from "react-redux";
import Chart from "widgets/Portfolio/components/Chart";
import { getArray, getLegendArray } from "widgets/Portfolio/data";
import styles from "./styles";
import React from "react";

const api = {
  getPortfolioData: ""
}

let topSchemes = [
  { schemeName: "HDFC Bank Limited", navValue: 20 },
  { schemeName: "ICICI Bank Limited", navValue: 30 },
  { schemeName: "Axis Bank Limited", navValue: 40 },
  { schemeName: "IDBI Bank Limited", navValue: 80 },
  { schemeName: "Bank of Baroda", navValue: 50 },
  { schemeName: "IndusInd Bank Limited", navValue: 30 },
  { schemeName: "AU Small Finance Bank Limited", navValue: 90 },
  { schemeName: "State Bank of India", navValue: 10 },
  { schemeName: "Kotak Mahindra Bank Limited", navValue: 80 },
  { schemeName: "IDFC Bank", navValue: 50 }
]

let topSectors = [
  { schemeName: "HDFC Bank Limited", navValue: 20 },
  { schemeName: "ICICI Bank Limited", navValue: 30 },
  { schemeName: "Axis Bank Limited", navValue: 40 },
  { schemeName: "IDBI Bank Limited", navValue: 80 },
  { schemeName: "Bank of Baroda", navValue: 50 }
]

let analysis = {
  data: [
    { schemeName: "Cash", navValue: 20 },
    { schemeName: "Equity", navValue: 80 }
  ]
}

let comparison = [
  { schemeName: "HDFC Bank Limited", navValue: 20 },
  { schemeName: "ICICI Bank Limited", navValue: 30 },
  { schemeName: "Axis Bank Limited", navValue: 10 },
  { schemeName: "IDBI Bank Limited", navValue: 20 },
  { schemeName: "Bank of Baroda", navValue: 20 }
]

let colors = ["#27A4DD", "#FAC174", "#F1646C", "#F39CC3", "#9DD5C0"]

const HoldingLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 0,
    [theme.breakpoints.down('xl')]: {
      height: 6,
    },
    [theme.breakpoints.down('lg')]: {
      height: 4,
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.color18.default,
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#56CF66',
  }
}))(LinearProgress);

const SectorLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 0,
    [theme.breakpoints.down('xl')]: {
      height: 6,
    },
    [theme.breakpoints.down('lg')]: {
      height: 4,
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.color18.default,
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#56CBCF',
  }
}))(LinearProgress);

const Legend = withStyles(styles)((props) => {
  const { classes, value, color, text, key } = props
  return (
    <Grid container key={key}>
      <Grid item lg={3} md={2} sm={2}>
        <Typography variant="subtitle2" className={classes.legendValue}>{value || 0}%</Typography>
      </Grid>
      <Grid item xs={2}>
        <div style={{ background: color }} className={classes.legendColor} />
      </Grid>
      <Grid item lg={7} md={8} sm={8}>
        <Typography variant="subtitle2" className={classes.legendValue}>{text || ""}</Typography>
      </Grid>
    </Grid>
  )
})

const ProgressIndicator = withStyles(styles)((props) => {
  const { schemeName, value, classes, sector, valueText, index } = props
  return (
    <div className={[classes.progressDiv, sector ? "divHeight" : ""].join(" ")} key={index}>
      <div className={[classes.headingDiv, "valuePadding"].join(" ")}>
        <Typography variant="h6" className={classes.primaryText}>{schemeName || ""}</Typography>
        <Typography variant="h6" className={classes.greenColor}>{Number(valueText).toFixed(2)}%</Typography>
      </div>
      {
        sector ?
          <div>
            <SectorLinearProgress variant="determinate" value={Number(value)} />
          </div>
          :
          <HoldingLinearProgress variant="determinate" value={Number(value)} />
      }
    </div>
  )
})
let portfolioData = {
  schemes: [],
  sectors: [],
  holdingAnalysis: { data: [] },
  sectorComparison: []
}

function Portfolio(props) {
  const { classes, schemeFundDetails, propSchemeFundDetails, popup } = props

  let fundDetails = propSchemeFundDetails || schemeFundDetails

  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.only('lg'));
  const matchesMd = useMediaQuery(theme.breakpoints.only('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.only('sm'));

  const holdings = () => {
    return (
      <div className={classes.infoGrid} style={popup ? { height: "100%" } : {}}>
        <div className={classes.headingDiv}>
          <Typography variant="h5" style={{ fontWeight: 600 }} className={classes.primaryText}>Top 10 Holdings</Typography>
          {/* <Button className={classes.button} variant="contained" color='primary' onClick={() => { }}>See All</Button> */}
        </div>
        {
          fundDetails && fundDetails.portfolio && fundDetails.portfolio.topHolding && fundDetails.portfolio.topHolding.topHoldingComparisonData &&
            fundDetails.portfolio.topHolding.topHoldingComparisonData.length > 0 ?
            <div>
              {
                fundDetails.portfolio.topHolding.topHoldingComparisonData.map((holding, index) => (
                  <ProgressIndicator index={index} schemeName={holding.companyName} valueText={holding.netAsset} value={index === 0 ? 100 : holding.netAsset && (Number(holding.netAsset) / Number(fundDetails.portfolio.topHolding.topHoldingComparisonData[0].netAsset) * 100) || 0} />
                ))
              }
            </div>
            :
            <div className={[classes.placeholderDiv,
            fundDetails && fundDetails.portfolio && fundDetails.portfolio.topSector && fundDetails.portfolio.topSector.topSectorComparisonData &&
              fundDetails.portfolio.topSector.topSectorComparisonData.length > 0 ? "data" : popup ? "popup" : ""].join(" ")}>
              <Placeholder
                loading={false}
                loadingMsg={""}
                error={"Data Not Found"}
              />
            </div>
        }
      </div >
    )
  }

  const sectors = () => {
    return (
      <div className={classes.infoGrid} style={popup ? { height: "100%" } : {}}>

        <div className={classes.headingDiv}>
          <Typography variant="h5" style={{ fontWeight: 600 }} className={classes.primaryText}>Top Sectors</Typography>
          {/* <Button className={classes.button} variant="contained" color='primary' onClick={() => { }}>See All</Button> */}
        </div>
        {
          fundDetails && fundDetails.portfolio && fundDetails.portfolio.topSector && fundDetails.portfolio.topSector.topSectorComparisonData &&
            fundDetails.portfolio.topSector.topSectorComparisonData.length > 0 ?
            <div>
              {
                fundDetails.portfolio.topSector.topSectorComparisonData.map((sector, index) => (
                  index < 5 &&
                  <ProgressIndicator index={index} schemeName={sector.sectorName} valueText={sector.netAsset} value={index === 0 ? 100 : sector.netAsset && (Number(sector.netAsset) / Number(fundDetails.portfolio.topSector.topSectorComparisonData[0].netAsset) * 100) || 0} sector={true} />
                ))
              }
            </div>
            :
            <div className={[classes.placeholderDiv,
            fundDetails && fundDetails.portfolio && fundDetails.portfolio.topHolding && fundDetails.portfolio.topHolding.topHoldingComparisonData &&
              fundDetails.portfolio.topHolding.topHoldingComparisonData.length > 0 ? "data" : popup ? "popup" : ""].join(" ")}>
              <Placeholder
                loading={false}
                loadingMsg={""}
                error={"Data Not Found"}
              />
            </div>
        }
      </div>
    )
  }

  const getExtraInfo = () => {
    return (
      <Grid container>
        <Grid item xs={4} className={classes.textGridBorder}>
          <Typography align="center" variant="h5" style={{ paddingBottom: "4px" }} className={classes.secondaryText}>{34}</Typography>
          <Typography align="center" variant="h6" style={{ paddingBottom: "8px" }} className={classes.primaryText}>Total Securities</Typography>
        </Grid>
        <Grid item xs={4} className={[classes.textGridBorder, "all"].join(" ")}>
          <Typography align="center" variant="h5" style={{ paddingBottom: "4px" }} className={classes.secondaryText}>{41}%</Typography>
          <Typography align="center" variant="h6" style={{ paddingBottom: "8px" }} className={classes.primaryText}>Top 5</Typography>
        </Grid>
        <Grid item xs={4} className={classes.textGridBorder}>
          <Typography align="center" variant="h5" style={{ paddingBottom: "4px" }} className={classes.secondaryText}>{85}%</Typography>
          <Typography align="center" variant="h6" style={{ paddingBottom: "8px" }} className={classes.primaryText}>Top 20</Typography>
        </Grid>
        <Grid item xs={4} className={classes.textGridBorder} style={{ borderBottom: "0px" }}>
          <Typography align="center" variant="h5" style={{ paddingTop: "8px" }} className={classes.secondaryText}>{37.03}</Typography>
          <Typography align="center" variant="h6" style={{ paddingTop: "4px" }} className={classes.primaryText}>P/E</Typography>
        </Grid>
        <Grid item xs={4} className={[classes.textGridBorder, "all"].join(" ")} style={{ borderBottom: "0px" }}>
          <Typography align="center" variant="h5" style={{ paddingTop: "8px" }} className={classes.secondaryText}>{5.56}</Typography>
          <Typography align="center" variant="h6" style={{ paddingTop: "4px" }} className={classes.primaryText}>P/B</Typography>
        </Grid>
        <Grid item xs={4} className={classes.textGridBorder} style={{ borderBottom: "0px" }}>
          <Typography align="center" variant="h5" style={{ paddingTop: "8px" }} className={classes.secondaryText}>{41}%</Typography>
          <Typography align="center" variant="h6" style={{ paddingTop: "4px" }} className={classes.primaryText}>Turn Over</Typography>
        </Grid>
      </Grid>
    )
  }

  const holdingAnalysis = () => {
    return (
      <div className={classes.infoGrid}>
        <div className={classes.headingDiv}>
          <Typography variant="h5" style={{ fontWeight: 600 }} className={classes.primaryText}>Holding Analysis</Typography>
          <div style={{ display: "flex" }}>
            {
              portfolioData && portfolioData.holdingAnalysis && portfolioData.holdingAnalysis.data && portfolioData.holdingAnalysis.data.length > 0 &&
              portfolioData.holdingAnalysis.data.map((ele, index) => (
                <div style={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
                  <div style={{ background: colors[index], marginRight: "4px", marginTop: 0 }} className={classes.legendColor} />
                  <Typography variant="subtitle2" style={{ marginTop: 0 }} className={classes.legendValue}>{ele.schemeName || ""}</Typography>
                </div>
              ))
            }
          </div>
        </div>
        {
          portfolioData && portfolioData.holdingAnalysis && portfolioData.holdingAnalysis.data && portfolioData.holdingAnalysis.data.length > 0 ?
            <Grid container>
              <Grid item xs={6}>
                <Chart
                  height={matchesSm || matchesMd ? 150 : matchesLg ? 170 : 200}
                  data={getArray(portfolioData.holdingAnalysis.data, "navValue") || [0]}
                  colors={colors}
                  labels={getArray(portfolioData.holdingAnalysis.data, "schemeName")}
                />
              </Grid>
              <Grid item xs={6} className={classes.centerContent} style={{ flexDirection: "column" }}>
                {getExtraInfo()}
              </Grid>
            </Grid>
            :
            <></>
        }
      </div>
    )
  }

  const sectorComparison = () => {
    return (
      <div className={classes.infoGrid}>
        <div className={classes.headingDiv}>
          <Typography variant="h5" style={{ fontWeight: 600 }} className={classes.primaryText}>Sector Comparison</Typography>
        </div>
        {
          portfolioData && portfolioData.sectorComparison && portfolioData.sectorComparison.length > 0 ?
            <Grid container>
              <Grid item xs={7}>
                <Chart
                  height={matchesSm || matchesMd ? 150 : matchesLg ? 170 : 200}
                  data={getArray(portfolioData.sectorComparison, "navValue") || [0]}
                  colors={colors}
                  labels={getArray(portfolioData.sectorComparison, "schemeName")}
                />
              </Grid>
              <Grid item xs={5} className={classes.centerContent} style={{ flexDirection: "column" }}>
                {
                  getLegendArray(portfolioData.sectorComparison, "navValue").map((ele, index) => (
                    <Legend
                      key={index}
                      value={ele.navValue}
                      color={colors[index]}
                      text={ele.schemeName}
                    />
                  ))
                }
              </Grid>
            </Grid>
            :
            <></>
        }
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {
        typeof (fundDetails) === "string" ?
          <Placeholder
            loading={fundDetails === "Loading..." ? true : false}
            loadingMsg={""}
            error={fundDetails === "Loading..." ? "" : fundDetails}
          />
          :
          <Grid container className={classes.rootGrid}>
            <Grid item md={6} sm={12}>
              {holdings()}
            </Grid>
            <Grid item md={6} sm={12}>
              {sectors()}
            </Grid>
            {/* <Grid item md={6} sm={12}>
              {holdingAnalysis()}
            </Grid>
            <Grid item md={6} sm={12}>
              {sectorComparison()}
            </Grid> */}
          </Grid>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { common: { fundDetails } } = state;
  return { schemeFundDetails: fundDetails };
}

export default connect(mapStateToProps)(withStyles(styles)(Portfolio))