import { amber, green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.background.secondary,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
}));
