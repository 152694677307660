export default ({ palette, breakpoints }) => ({
  styleOverrides: {
    root: {
      fontSize: '14px',
      backgroundColor: palette.background.primary,
      borderBottom: `1px solid ${palette.type === 'light' ? '#DEDBDB' : '#3C455A'}`,
      padding: '14px 40px 14px 24px',
      borderRight: `1px solid ${palette.type === 'light' ? '#DEDBDB' : '#3C455A'}`,
      whiteSpace: 'nowrap',
      '&:first-of-type': {
        borderLeft: `1px solid ${palette.type === 'light' ? '#DEDBDB' : '#3C455A'}`
      },
      '&:last-child': {
        paddingRight: 16
      },
      [breakpoints.down('xl')]: {
        fontSize: '12px',
        lineHeight: '16px'
      },
      [breakpoints.down('lg')]: {
        fontSize: '12px',
        lineHeight: '14px'
      },
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '13px'
      }
    },
    head: {
      fontSize: '14px',
      color: palette.type === 'light' ? '#001E3B' : '#8DB1D2',
      borderTop: `1px solid ${palette.type === 'light' ? '#DEDBDB' : '#3C455A'}`,
      backgroundColor: palette.color.default
    },
    body: {
      color: palette.type === 'light' ? '#666666' : '#A3ADCB'
    },
    sizeSmall: {
      padding: '0 15px'
    },
    paddingCheckbox: {
      padding: '0 12px !important'
    }
  }
});
