export default theme => ({
  mainGrid: {
    padding: "12px 0px 6px 20px",
    [theme.breakpoints.down('lg')]: {
      padding: "12px 0px 6px 16px",
    }
  },
  input: {
    padding: "8px",
    // background: "#EBEEF5",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#808080",
      '-webkit-text-fill-color': "#808080"
    }
  },
  paddingGrid: {
    padding: "12px 0px",
    "&.border": {
      borderBottom: `1px dashed ${theme.palette.color10.default}`,
    }
  },
  confirmGridItem: {
    textAlign: "center",
    borderRight: `1px dashed ${theme.palette.color10.default}`,
  },
  leftBorder: {
    borderLeft: `1px dashed ${theme.palette.color10.default}`
  },
  legend: {
    position: "relative",
    marginLeft: "20px",
    [theme.breakpoints.down('lg')]: {
      marginLeft: "16px"
    }
  },
  fieldSet: {
    margin: "0px 8px 4px 0px",
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('xl')]: {
      margin: "0px 6px 4px 0px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "0px"
    },
    position: 'relative',
  },
  orderHelp: {
    position: 'absolute',
    top: `-${theme.spacing(1)}`,
    right: theme.spacing(.5),
  },
  orderHelpCompact: {
    position: 'absolute',
    top: `-${theme.spacing(.5)}`,
    right: `-${theme.spacing(.5)}`,
  },
  infoDiv: {
    backgroundColor: "#EAEAEA",
    border: "1px solid #C7C9CB",
    borderRadius: "4px",
    padding: '4px 18px',
    marginTop: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "3px 18px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px 16px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "2px 14px",
    },
  },
  infoText: {
    color: "#0E6CA8",
  },
  infoTextBold: {
    fontWeight: 'bold',
  },
  confirmFieldSet: {
    margin: "0px",
    borderRadius: "0px 0px 6px 6px",
    borderLeft: `1px solid ${theme.palette.color5.default}`,
    borderRight: `1px solid ${theme.palette.color5.default}`,
    borderBottom: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default
  },
  colorDiv: {
    height: "8px",
    borderRadius: "20px 20px 0px 0px",
    margin: "0px 2px"
  },
  buyButton: {
    width: "75px",
    color: '#000000de',
    borderRadius: "5px 0px 0px 5px",
    // '&:hover': {
    //     backgroundColor: "#d7ffc9ab"
    // },
    '&.active': {
      color: "#fff"
    },
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  sellButton: {
    color: '#000000de',
    whiteSpace: 'nowrap',
    width: "75px",
    borderRadius: "0px 5px 5px 0px",
    // '&:hover': {
    //     backgroundColor: "#ffa4a4c2"
    // },
    '&.active': {
      color: "#fff"
    },
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  sell: {
    backgroundColor: "#f54040",
    color: '#fff',
    '&:hover': {
      backgroundColor: "#ffa4a4c2"
    }
  },
  activeOrder: {
    backgroundColor: '#299D00',
    color: '#fff',
    '&:hover': {
      backgroundColor: "#9fc790"
    }
  },
  blueText: {
    color: "#006CA8",
    fontSize: "11px",
    lineHeight: "16px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px",
      lineHeight: "16px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "9px",
      lineHeight: "14px",
    }
  },
  confirmBlueText: {
    color: "#006CA8",
    fontSize: "14px",
    lineHeight: "16px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px",
      lineHeight: "14px",
    }
  },
  defaultOrder: {
    border: `1px solid ${theme.palette.color18.default}`,
    height: "36px",
    backgroundColor: theme.palette.background.darker,
    color: theme.palette.color18.default
  },
  buttonTab: {
    width: "72px",
    height: theme.spacing(3),
    fontSize: "11px",
    textTransform: "none",
    marginBottom: theme.spacing(1),
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px",
      width: "66px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "62px",
      height: theme.spacing(3),
      padding: "6px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "9px",
    }
  },
  tabLeft: {
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('xl')]: {
      marginLeft: "3px",
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0.25)
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0.5)
    },
  },
  tabRight: {
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('xl')]: {
      marginRight: "3px",
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(0.25),
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  divMargin: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1.5),
    }
  },
  fieldDiv: {
    marginBottom: theme.spacing(1.5),
    width: "100%",
    "&.compact": {
      width: "120px",
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('xl')]: {
        width: "100px",
        paddingRight: theme.spacing(0.75),
      }
    },
    "&.select": {
      width: "80px",
      paddingRight: theme.spacing(1)
    },
    "&.selectLarge": {
      width: "110px",
      paddingRight: theme.spacing(1)
    }
  },
  elementSpacing: {
    display: "flex",
    justifyContent: "space-between"
  },
  fieldText: {
    marginBottom: theme.spacing(0.25),
    color: theme.palette.color15.default,
    fontSize: "12px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px"
    }
  },
  approxDiv: {
    padding: "0px 0px 4px 0px",
    borderBottom: `1px dashed #66788a4d`
  },
  approxMarginDiv: {
    backgroundColor: "#caeffe",
    borderRadius: "4px",
    marginTop: 8,
    marginBottom: 0,
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center"
  },
  activeButton: {
    background: "#BAEEE2",
    color: "#046E57",
    '&:hover': {
      backgroundColor: "#d8f3ed99"
    }
  },
  activeSell: {
    background: "#EEBABA",
    color: "#f54040",
    '&:hover': {
      backgroundColor: "#c9b8b8"
    }
  },
  default: {
    letterSpacing: "0.8px",
    fontSize: theme.spacing(2),
    color: theme.palette.color15.default,
    "&.compact": {
      fontSize: "12px",
      [theme.breakpoints.down('xl')]: {
        fontSize: "10px",
      }
    },
    "&.valid": {
      color: theme.palette.color12.default,
      fontWeight: 600
    }
  },
  defaultButton: {
    background: theme.palette.background.default,
    color: theme.palette.color15.default,
    border: `1px solid ${theme.palette.color14.primary}`
  },
  order: {
    width: "100%",
    textTransform: "none",
    color: "#fff",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    "&:disabled": {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  cancel: {
    width: "100%",
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.color5.default}`,
    textTransform: "none",
    color: `${theme.palette.color15.default} !important`,
    // boxShadow: "0px 3px 5px 0px #006ca863",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    '&:hover': {
      backgroundColor: "#c5c6c826"
    }
  },
  productBorder: {
    borderRight: `1px dashed #66788a4d`
  },
  text: {
    marginTop: 0,
    marginBottom: 0,
    display: "flex",
    color: theme.palette.color15.default,
    fontWeight: 800,
    fontSize: "12px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px"
    }
  },
  contentText: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  approxValue: {
    color: theme.palette.color12.default,
    fontSize: "12px",
    lineHeight: "16px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "11px",
      lineHeight: "14px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  action: {
    fontSize: "11px",
    lineHeight: "14px"
  },
  bottomGrid: {
    marginTop: theme.spacing(1.5)
  },
  infoButton: {
    color: theme.palette.primary.main
  },
  info: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    marginTop: "-2px"
  },
  confirmTop: {
    margin: "12px 16px 0px 16px",
    paddingBottom: "16px",
    borderBottom: `2px dashed ${theme.palette.color15.default}`
  },
  top: {
    padding: "0px 0px 6px 0px"
  },
  errorText: {
    color: "#f54040",
    marginTop: "2px"
  },
  buttonGrid: {
    justifyContent: "center",
    display: "flex"
  },
  searchList: {
    padding: "0px",
    zIndex: 999,
    maxHeight: "200px",
    overflowY: "auto",
    marginRight: "24px",
    position: "absolute",
    background: theme.palette.background.WLselectedItem,
    boxShadow: `0px 1px 6px -1px ${theme.palette.background.WLselectedItem}`,
    '&.compact': {
      maxHeight: 145,
    }
  },
  errorDiv: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    borderColor: "1px solid #f5c6cb",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "4px",
    textAlign: "center",
    marginBottom: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "3px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px",
    }
  },
  errorDivText: {
    color: "#721c24",
  },
  item: {
    // padding: "0px",
    paddingRight: "8px",
    whiteSpace: "unset",
    border: `1px solid ${theme.palette.color10.default}`,
    overflow: "inherit",
    backgroundColor: theme.palette.background.primary,
  },
  listContainer: {
    justifyContent: "flex-end"
  },
  loadingDiv: {
    // width: "570px",
    height: "45vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    /* width: "50vw",
    [theme.breakpoints.down('xl')]: {
      width: "62vw"
    },
    [theme.breakpoints.down('lg')]: {
      width: "75vw"
    },
    [theme.breakpoints.down('md')]: {
      width: "60vw"
    }, */
    '&.compact': {
      width: "100%",
      // width: "42vw",
      height: "20vh",
      [theme.breakpoints.down('xl')]: {
        // width: "55vw",
        height: "30vh"
      },
      [theme.breakpoints.down('lg')]: {
        // width: "68vw"
      },
      [theme.breakpoints.down('md')]: {
        // width: "50vw",
        height: "28vh"
      },
    }
  },
  tooltip: {
    color: theme.palette.color15.default,
    fontSize: "10px",
  },
  viewButton: {
    fontSize: "12px",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.color5.default}`,
    float: "right",
    padding: "2px 0px 2px 6px",
    color: theme.palette.color15.default,
    background: theme.palette.background.default,
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "8px",
    }
  },
  compactConfirm: {
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.color5.default}`,
    padding: '8px 12px',
    borderRadius: theme.spacing(1)
  },
  topScrip: {
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    marginBottom: "6px",
    color: theme.palette.color12.default
  },
  topLtp: {
    color: theme.palette.color12.default,
    fontSize: "18px",
    letterSpacing: "0.8px",
    marginRight: "10px"
  },
  longText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  searchBox: {
    height: "32px",
    display: "flex",
    borderRadius: "4px",
    border: `1px solid #ffffff3b`,
    "&:error": {
      border: `1px solid #f54040`,
    },
    [theme.breakpoints.down('xl')]: {
      height: "27px"
    },
    [theme.breakpoints.down('lg')]: {
      height: "24px"
    }
  },
  itemText: {
    color: theme.palette.text.primary,
  },
  itemSubText: {
    fontSize: 11,
    [theme.breakpoints.down('lg')]: {
      fontSize: 10,
    },
  },
  depthMargin: {
    margin: "18px 0px 5px 0px",
    [theme.breakpoints.down('xl')]: {
      margin: "14px 0px 4px 0px",
    },
  },
  formWidth: {
    width: "1120px",
    [theme.breakpoints.down('xl')]: {
      width: "1100px"
    },
    [theme.breakpoints.between(960, 1110)]: {
      width: "860px"
    },
    [theme.breakpoints.between(1110, 1064)]: {
      width: "900px"
    },
    [theme.breakpoints.between(1065, 1279)]: {
      width: "950px"
    },
    [theme.breakpoints.down('md')]: {
      width: "485px"
    }
  },
  confirmWidth: {
    width: "25vw",
    [theme.breakpoints.down('xl')]: {
      width: "32vw"
    },
    [theme.breakpoints.down('lg')]: {
      width: "40vw"
    },
    [theme.breakpoints.down('md')]: {
      width: "50vw"
    },
    '&.client': {
      [theme.breakpoints.down('xl')]: {
        width: "28vw"
      },
      [theme.breakpoints.down('lg')]: {
        width: "40vw"
      },
      [theme.breakpoints.down('md')]: {
        width: "44vw"
      },
    }
  },
  compactFormWidth: {
    width: 1154,
    [theme.breakpoints.down('xl')]: {
      width: 950
    },
    [theme.breakpoints.down('lg')]: {
      width: 845
    },
    [theme.breakpoints.down('md')]: {
      width: 450
    },
    '&.client': {
      width: 1000,
      [theme.breakpoints.down('xl')]: {
        width: 794
      },
      [theme.breakpoints.down('lg')]: {
        width: 768
      },
      [theme.breakpoints.down('md')]: {
        width: 450
      },

    }
  },
  compactConfirmWidth: {
    width: 1100,
    [theme.breakpoints.down('xl')]: {
      width: 950
    },
    [theme.breakpoints.down('lg')]: {
      width: 864
    },
    [theme.breakpoints.down('md')]: {
      width: 500
    },
    '&.client': {
      width: 870,
      [theme.breakpoints.down('xl')]: {
        width: 794
      },
      [theme.breakpoints.down('lg')]: {
        width: 768
      },
      [theme.breakpoints.down('md')]: {
        width: 400
      },
    },
  },
  shortSell: {
    color: '#000000de',
    width: "116px",
    // padding: "6px 10px",
    [theme.breakpoints.down('xl')]: {
      // padding: "4px",
      width: "100px",
    },
  },
  convertForm: {
    width: "400px",
    [theme.breakpoints.down('xl')]: {
      width: "350px"
    },
    [theme.breakpoints.down('lg')]: {
      width: "350px"
    },
    [theme.breakpoints.down('md')]: {
      width: "300px"
    }
  },
  convertFormFrid: {
    backgroundColor: theme.palette.background.default,
    padding: "16px 4px 12px 4px",
    marginTop: "8px",
    borderRadius: "6px",
    border: `1px solid ${theme.palette.color5.default}`,
    [theme.breakpoints.down('xl')]: {
      padding: "12px 6px 6px 6px"
    }
  },
  convertButton: {
    color: '#FFF',
    backgroundColor: theme.palette.common.blue,
    width: "100%",
    textTransform: "none",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  convertLoadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px"
  },
  heading: {
    color: theme.palette.color15.default,
    textDecoration: "underline",
    marginLeft: "18px",
    marginBottom: "8px",
    [theme.breakpoints.down('xl')]: {
      marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: "13px",
    }
  },
  value: {
    marginLeft: "4px",
    fontSize: "16px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      marginLeft: "6px",
      fontSize: "14px",
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: "4px",
      fontSize: "11px",
    }
  },
  convertFieldText: {
    marginBottom: theme.spacing(0.25),
    color: theme.palette.color15.default,
    fontSize: "12px",
    lineHeight: "18px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px",
      lineHeight: "14px"
    },
    [theme.breakpoints.down('lg')]: {
      lineHeight: "12px"
    }
  },
  quantityValue: {
    marginLeft: "8px",
    fontSize: "16px",
    lineHeight: "32px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      marginLeft: "6px",
      fontSize: "14px",
      lineHeight: "26px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "11px",
      lineHeight: "24px"
    }
  },
  searchErrorDiv: {
    height: "45vh",
    width: "100%",
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    borderRadius: "4px",
    // [theme.breakpoints.down('xl')]: {
    //     width: "62vw"
    // },
    // [theme.breakpoints.down('lg')]: {
    //     width: "75vw"
    // },
    // [theme.breakpoints.down('md')]: {
    //     width: "60vw"
    // },
  },
  commProdType: {
    marginRight: "8px",
    marginLeft: "8px"
  },
  select: {
    border: "1px solid #66788a4d",
    color: theme.palette.color12.default
  },
  mtf: {
    // color: "#9EA0A4",
    fontSize: '14px',
    lineHeight: '21px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '16px',
    }
  },
  buttonDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },
  typeHeading: {
    color: theme.palette.color15.default,
    marginLeft: "18px",
    marginRight: "16px",
    [theme.breakpoints.down('xl')]: {
      marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: "13px",
    }
  },
  button: {
    marginTop: "4px",
    background: "#e0e0e0",
    color: theme.palette.text.secondary,
    textTransform: "none",
    width: "76px",
    "&.active": {
      background: "#006CA8",
      color: "#fff"
    },
    "&:disabled": {
      background: "#c5cbce",
      color: "#7d7d7d"
    },
    "&.hover": {
      background: theme.palette.background.secondary,
    },
    [theme.breakpoints.down('xl')]: {
      width: "65px",
    },
    [theme.breakpoints.down('md')]: {
      width: "50px",
    }
  },
  productGrid: {
    marginLeft: "24px",
    [theme.breakpoints.down('xl')]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: "20px",
    }
  },
  belowText: {
    color: theme.palette.color15.default,
    marginTop: "2px",
    "&.lot": {
      fontSize: 14,
      [theme.breakpoints.down('xl')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 11,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      }
    }
  },
  switchField: {
    margin: 0
  },
  switchIcon: {
    padding: '9px 12px 9px 9px',
    marginLeft: "-4px",
    marginRight: "-6px"
  },
  approxGrid: {
    marginBottom: 8,
    alignItems: "center",
    display: "flex",
    // justifyContent: "space-between"
  },
  switchText: {
    color: theme.paletteType === 'light' ? '#001E3B' : '#000 !important',
    fontSize: 13,
    [theme.breakpoints.down('lg')]: {
      fontSize: "11px",
    }
  },
  refreshIcon: {
    width: 20,
    height: 20,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
  },
  orderButtonGrid: {
    justifyContent: "space-between",
    marginTop: 8,
    display: "flex"
  },
  buttonFixedWidth: {
    width: 120,
    height: 37,
    [theme.breakpoints.down('xl')]: {
      width: 103,
      height: 30,
    },
    [theme.breakpoints.down('lg')]: {
      width: 128,
      height: 29,
    },
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 28,
    }
  },
  compactButtonFixedWidth: {
    width: 180,
    height: 37,
    [theme.breakpoints.down('xl')]: {
      width: 160,
      height: 29,
    },
    [theme.breakpoints.down('lg')]: {
      width: 144,
    },
    [theme.breakpoints.down('md')]: {
      width: 125,
      height: 26
    }
  },
  linearProgress: {
    top: 12,
    bottom: 0,
    opacity: 0.4,
    position: 'absolute'
  }
});