import { getToday } from "helpers/dateHelper";
import axios from 'helpers/interceptor';
import { getFixedValueObject } from "helpers/scripHelper";
import { StorageHelper } from "helpers/storageHelper";
import { store } from 'helpers/store';
import { AlertActions } from 'redux/Alert';
import { CommonActions } from 'redux/Common';
import { DialogActions } from 'redux/Dialog';
import { DialogActions as ConfirmDialogActions } from 'redux/ConfirmDialog';
import { PrefsActions } from "redux/Prefs";
import { PrefsService } from 'services/prefs';
import { sort as fastSort } from 'fast-sort';
import { LayoutActions } from "redux/Layout";

const ADD_LIST = 'ADD_LIST';
const EDIT_LIST = 'EDIT_LIST';
const DELETE_LIST = 'DELETE_LIST';
const CHANGE_LIST = 'CHANGE_LIST';
const SORT_LIST = 'SORT_LIST';
const SORT_END = 'SORT_END';

const GET_LISTS = 'GET_LISTS';
const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
const GET_LISTS_FAILURE = 'GET_LISTS_FAILURE';

const GET_SCRIPS = 'GET_SCRIPS';
const GET_SCRIPS_SUCCESS = 'GET_SCRIPS_SUCCESS';
const GET_SCRIPS_FAILURE = 'GET_SCRIPS_FAILURE';

const ADD_SCRIP_STATUS = 'ADD_SCRIP_STATUS';
const DELETE_SCRIP_STATUS = 'DELETE_SCRIP_STATUS';

const CLEAR_ALERT = 'CLEAR_ALERT';

export const WatchListAction = {
    getLists,
    switchList,
    addList,
    deleteList,
    sortList,
    onSortEnd,
    getScrips,
    addScrip,
    changeScrip,
    deleteScrip,
    deleteSelectedScrip,
    clearAlert,
    addScripToWatchlist
};

//ACTION CREATORS
function clearAlert() {
    store.dispatch({ type: CLEAR_ALERT });
}

function getLists() {
    onRequest();
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/get`
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            if (success) {
                let trading = success.filter(item => item.segment == 'EQ');
                let newList = trading.map(item => {
                    const { watchListId, watchListName } = item;
                    let newItem = {
                        ...item,
                        id: watchListId,
                        name: watchListName
                    };
                    return newItem;
                });
                onSuccess(newList);
                // getScrips(newList[0].id, newList[0].type);
            } else {
                onFailure(error);
                getLists();
            }
        })
        .catch(function (error) {
            console.log('getLists Error: ' + error);
            onFailure(error);
            getLists();
        });
    function onRequest() { store.dispatch({ type: GET_LISTS }); }
    function onSuccess(list) {
        let storedData = StorageHelper.getSelectedList();
        let storedValues = storedData ? storedData.split('_') : null;
        let storedList = storedValues ? { id: storedValues[0], type: storedValues[1] } : null;

        if (!storedList /* || storedList == -1 */) {
            storedList = list && list.length > 0 ? list[0] : -1
        }
        if (storedList.id) {
            let foundIndex = list.findIndex(item => item.id == storedList.id);
            // debugger
            if (foundIndex == -1) {
                storedList = list[0];
            }
            PrefsService.setLastviewedwatchlist({
                id: storedList.id, type: storedList.type
            });
            getScrips(storedList.id, storedList.type);
        }
        store.dispatch({ type: GET_LISTS_SUCCESS, list, storedList });
    }
    function onFailure(error) { store.dispatch({ type: GET_LISTS_FAILURE, error }); }
}

function switchList(watchlistId, byIndex) {
    let currentList, currentIndex;
    let lists = store.getState().watchlist.lists;
    if (byIndex || byIndex == 0) {
        if (byIndex < lists.length) currentIndex = byIndex;
        else currentIndex = lists.length - 1;
    } else {
        currentIndex = lists.map(x => x.id).indexOf(watchlistId);
    }
    currentList = lists[currentIndex];
    // setItem('selectedList', `${currentList.id}_${currentList.type}`);

    if (currentIndex >= 0) {
        store.dispatch({ type: CHANGE_LIST, list: currentList });
        PrefsService.setLastviewedwatchlist({
            id: currentList.id, type: currentList.type
        });
        getScrips(currentList.id, currentList.type);
        // setSorting(currentList.sortType);
    }
    CommonActions.onScripSelected({});
}

function addList({ watchListName, watchListId, newWatchListName }) {
    const endPoint = watchListId
        ? '/ms-feed-service/watchlist/edit'
        : '/ms-feed-service/watchlist/create';
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}${endPoint}`,
        data: {
            segment: 'EQ',
            watchListName,
            watchListId,
            newWatchListName
        }
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            if (success) {
                onSuccess(success.watchListId, newWatchListName);
            } else {
                AlertActions.error(error && error.msg ? error.msg : "Something went wrong...");
            }
        })
        .catch(function (error) {
            console.log('addWatchlist error', error);
        });
    function onSuccess(id, name) {
        const isEdit = watchListId ? true : false;
        id = id || watchListId;
        name = name || watchListName;
        DialogActions.dialogClose();

        PrefsService.setLastviewedwatchlist({ id, type: 'client' });

        store.dispatch({
            type: watchListId ? EDIT_LIST : ADD_LIST,
            item: {
                id,
                name,
                type: 'client',
                watchListId: id,
                watchListName: name,
                sortType: -1,
                sortDesc: true,
                data: []
            },
            selectedList: id
        });
        !isEdit && getScrips(id, 'client');
    }
}

function deleteList(watchListId) {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/remove`,
        data: { watchListId }
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            if (success) {
                onSuccess();
            } else {
                AlertActions.error(error && error.msg ? error.msg : "Something went wrong...");
            }
        })
        .catch(function (error) {
            console.log('deleteWatchlist error', error);
        });
    function onSuccess() {
        let lists = store.getState().watchlist.lists;
        let toDeleteIdx = getIndex(lists, watchListId);

        lists.splice(toDeleteIdx, 1);

        let currList = lists.length > 0 ? lists[0] : null;

        if (currList)
            getScrips(currList.id, currList.type);

        PrefsService.setLastviewedwatchlist({ id: currList.id, type: currList.type });

        store.dispatch({
            type: DELETE_LIST,
            item: {
                id: currList.id,
                type: currList.type,
                name: currList.name,
                lists: [...lists]
            }
        });
    }
}

function getScrips(watchListId, selectedListType, dontRefresh) {
    if (watchListId == -1) return;
    onRequest();
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/custom/watchlist/feed/${selectedListType}/${watchListId}`
        // url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/feed/${selectedListType}/${watchListId}`
    };
    axios(options)
        .then(response => {
            let today = getToday().getTime();
            const { data: { success, error } } = response;
            if (success) {
                const { sortOption, customSort, list } = success;
                let data = list.filter(scrip => {
                    const { expiryDate, scripId } = scrip;
                    let date = Date.parse(expiryDate);
                    if (/* (segment !== 'FNO' && !date) */ scripId || (date && date >= today)) {
                        return {
                            ...scrip,
                            percentChange: Math.round(scrip.percentChange * 100) / 100
                        }
                    }
                })
                let scripData = [...data]
                scripData.map((row, index) => {
                    scripData[index] = getFixedValueObject(row, "watchlist")
                })
                onSuccess(scripData, sortOption, customSort);
            } else {
                onFailure(error);
            }
        })
        .catch(function (error) {
            console.log('getScrips error', error);
        });
    function onRequest() { store.dispatch({ type: GET_SCRIPS, dontRefresh }); }
    function onSuccess(items, sortOption, customSort) {
        PrefsActions.onSortUpdated({ sortOption, customSort });
        const sortingOptions = {
            id: watchListId,
            customSort,
            sortOption
        };
        const sortedScrips = sortScrips(items, sortingOptions);

        store.dispatch({
            type: GET_SCRIPS_SUCCESS,
            items,
            sortOption,
            customSort,
            selectedList: watchListId,
            selectedListType,
            sortedScrips
        });
    }
    function onFailure(error) { store.dispatch({ type: GET_SCRIPS_FAILURE, error }); }
}

function sortList(watchListId, sortType, sortDesc) {
    // PrefsService.setSorting({ id: watchListId, type: sortType, direction: sortDesc });
    const { scrips } = store.getState().watchlist;
    const sortingOptions = {
        id: watchListId,
        customSort: [],
        sortOption: { type: sortType, direction: sortDesc }
    };
    const sortedScrips = sortScrips(scrips, sortingOptions);
    store.dispatch({
        type: SORT_LIST,
        item: { watchListId, sortType, sortDesc, sortedScrips }
    });
}

function onSortEnd(oldIndex, newIndex, selectedList) {
    let scrips = store.getState().watchlist.scrips;
    let swappedScrips = swapScrips(selectedList, scrips, oldIndex, newIndex);

    store.dispatch({
        type: SORT_END,
        // item: { oldIndex, newIndex, selectedList },
        data: swappedScrips
    });
}

function addScrip(payload) {
    const { watchListId, selectedListType, data } = payload;
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/create/scrip`,
        data
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            let statusMsg = "";
            if (success && success.length > 0) {
                const { selectedList } = store.getState().watchlist;
                if (selectedList == watchListId) {
                    getScrips(watchListId, 'client');
                }
                let scripText = success.length == 1 ? 'scrip' : 'scrips';
                statusMsg = `${success.length} ${scripText} added successfully.`;
                onSuccess(statusMsg, 'success');
            }
            if (error && error.length > 0) {
                statusMsg = "";
                let scripText = error.length == 1 ? 'scrip' : 'scrips';
                let existText = error.length == 1 ? 'exists' : 'exist';
                statusMsg = `${error.length} ${scripText} already ${existText} in Watchlist.`;
                setTimeout(() => {
                    onSuccess(statusMsg, 'error');
                }, success && success.length > 0 ? 2500 : 0);
            }
        })
        .catch(function (error) {
            console.log('addScripOptions', error);
        });
    function onSuccess(status, statusType) {
        store.dispatch({
            type: ADD_SCRIP_STATUS,
            status,
            statusType,
        });
    }
}

function changeScrip(action) {
    let nextIndex;
    let scrips = store.getState().watchlist.scrips;
    let selectedScripIndex = store.getState().common.selectedScripIndex;
    if (action.toLowerCase() === 'arrowup' || action.toLowerCase() === 'arrowleft') {
        //Previous scrip
        nextIndex = selectedScripIndex - 1;
        if (nextIndex < 0) {
            nextIndex = scrips.length - 1;
        }
    }
    if (action.toLowerCase() === 'arrowdown' || action.toLowerCase() === 'arrowright') {
        //Next scrip
        nextIndex = selectedScripIndex + 1;
        if (nextIndex > scrips.length - 1) {
            nextIndex = 0;
        }
    }
    if (nextIndex != null) {
        CommonActions.onScripSelected(scrips[nextIndex]);
        LayoutActions.setChartData(scrips[nextIndex])
    }
}

function deleteSelectedScrip() {
    const {
        watchlist: {
            selectedList,
            selectedListType,
        },
        common: {
            selectedScrip
        },
    } = store.getState();
    if (selectedList != -1 && selectedScrip) {
        const {
            scripId, shortCode, symbol
        } = selectedScrip;
        deleteScrip(selectedList, selectedListType, scripId, shortCode, symbol);
    } else {
        console.log("No scrip selected");
    }
}


function deleteScrip(
    watchListId,
    selectedListType,
    scripId,
    shortCode,
    symbol
) {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/remove/scrip`,
        data: { watchListId, scripId, shortCode, symbol }
    };
    axios(options)
        .then(response => {
            const {
                data: { success, error }
            } = response;
            let statusMsg = "";
            if (success && success.length > 0) {
                // PrefsActions.onPendingSortUpdate(true);
                getScrips(watchListId, selectedListType);

                success.forEach(msg => {
                    statusMsg += msg.msg + "\n";
                });
                onSuccess(statusMsg, 'success');
            } else if (error && error.length > 0) {
                statusMsg = "";
                error.forEach(msg => {
                    statusMsg += msg.msg + "\n";
                });
                setTimeout(() => {
                    onSuccess(statusMsg, 'error');
                }, success && success.length > 0 ? 2500 : 0);
            }
            // getScrips(watchListId, selectedListType);
            // AlertActions.success(`Script ${scripId} deleted successfully`);
        })
        .catch(function (error) {
            console.log('addScripOptions error', error);
            // AlertActions.error(`Script ${scripId} delete error${error}`);
        });
    function onSuccess(status, statusType) {
        store.dispatch({
            type: DELETE_SCRIP_STATUS,
            status,
            statusType,
        });
    }
}

function customSortObject(collection, sortCol, order) {

    return collection.sort((a, b) => {
        //console.log("Sorting ",a,b,sortCol,order)
        var aData = a[sortCol] === null || typeof a[sortCol] === 'undefined' ? '' : a[sortCol];
        var bData = b[sortCol] === null || typeof b[sortCol] === 'undefined' ? '' : b[sortCol];
        return isNaN(Number(aData))
            ? new Date(aData) == 'Invalid Date'
                ? (typeof aData.localeCompare === 'function'
                    ? aData.localeCompare(bData)
                    : aData - bData) * (order === 'asc' ? 1 : -1)
                : (new Date(aData) - new Date(bData)) * (order === 'asc' ? 1 : -1)
            : (Number(aData) - Number(bData)) * (order === 'asc' ? 1 : -1);
    }
    )
}

function sortType(colName, data) {
    if (colName === 'ltp' || colName === 'change')
        return Number(data)
    else
        return data
}

function sortScrips(scrips, sortingOptions) {
    if (scrips && scrips.length == 0) return scrips;

    const { id, customSort, sortOption: { type, direction } } = sortingOptions;

    let sortedScrips;

    if (customSort && customSort.length > 0) {
        // if (customSort.length < scrips.length) {
        //     // if previous sort has less values
        //     try {
        //         PrefsService.setCustomSort(id, scrips);
        //     } catch (error) {
        //         console.log('sortScrips', error);
        //         // debugger
        //     }
        //     return scrips;
        // }
        // debugger
        try {
            sortedScrips = customSort.map(seq => {
                const { symbol: seqSymbol, segment: seqSegment, exchange: seqExchange } = seq;
                let foundIndex = -1;
                const found = scrips.filter((scrip, scripIndex) => {
                    const { symbol, scripName, segment, exchange } = scrip;
                    const scripSymbol = segment == 'EQ' ? symbol : scripName;
                    if (scripSymbol == seqSymbol
                        && segment == seqSegment
                        && exchange == seqExchange) {
                        foundIndex = scripIndex;
                        return true;
                    };
                });
                let foundItem = null;
                if (found.length > 0) {
                    foundItem = found[0];
                    scrips.splice(foundIndex, 1);
                }
                return foundItem;
            });

        } catch (error) {
            console.log(error);
            debugger
        }
        // return sortedScrips;
        // let pendingCustomSort = store.getState().prefs.pendingCustomSort;
        // if (pendingCustomSort) {
        //     PrefsActions.onPendingSortUpdate(false);
        //     PrefsService.setCustomSort(id, sortedScrips);
        // }
        // remove nulls if previous sort had more values
        sortedScrips.filter(x => x != null);
        let toReturn = sortedScrips.filter(x => x != null);
        // let toReturn = sortedScrips;
        if (scrips && scrips.length > 0) {
            toReturn = scrips.concat(toReturn);
            try {
                PrefsService.setCustomSort(id, toReturn);
            } catch (error) {
                console.log(error)
                debugger
            }
        }
        return toReturn;
        // return sortedScrips.filter(x => x != null);
    }

    let sortCol;
    switch (type) {
        case 0:
            sortCol = 'scripName';
            break;
        case 1:
            // sortCol = 'change';
            sortCol = 'percentChange';
            break;
        case 2:
            sortCol = 'ltp';
            break;
    }
    if (sortCol) {
        // console.log(fastSort([...scrips]).desc(u => u[sortCol]))
        // console.log(fastSort([...scrips]).asc(u => u[sortCol]))
        // sortedScrips = customSortObject([...scrips],
        //     sortCol,
        //     direction ? 'desc' : 'asc')
        // console.log(sortCol)

        if (direction === 1 || direction) {
            sortedScrips = fastSort([...scrips]).desc(u => sortType(sortCol, type === 0 ? u[sortCol] : Number(u[sortCol])));
            return sortedScrips;
        }
        else {
            sortedScrips = fastSort([...scrips]).asc(u => sortType(sortCol, type === 0 ? u[sortCol] : Number(u[sortCol])));
            return sortedScrips;
        }

        //console.log(sortedScrips)
        //return sortedScrips;
    }
    return scrips;
}

function addScripToWatchlist(scrip) {
    const { scripId, symbol, shortCode, segment, scripName, token, watchlistCall, qty } = scrip;
    let watchlist = store.getState().watchlist;
    let data = {
        scripId,
        symbol: segment == "EQ" ? symbol : scripName,
        shortCode,
        token,
        qty,
        watchListId: 0,
        isPortfolio: true,
    }
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-feed-service/watchlist/create/scrip`,
        data
    };
    axios(options)
        .then(response => {
            let obj = {
                content: response.data.success?.length > 0 ? "Scrip added successfully" : response.data.error?.length > 0 ? response.data.error[0]?.msg || "--" : "--",
                hideCloseButton: true,
                okButtonText: "Ok",
                centerActions: true,
                onClose: () => {
                    ConfirmDialogActions.confirmDialogClose();
                    if (response.data.success?.length > 0 && watchlistCall && watchlist.selectedListName === "MyHolding") {
                        getScrips(watchlist.selectedList, watchlist.selectedListType);
                    }
                }
            }
            ConfirmDialogActions.confirmDialogOpen(null, obj)
        })
        .catch(function (error) {
            console.log('addScripToWatchlist', error);
        });
}

function swapScrips(id, scrips, oldIndex, newIndex) {
    // console.log(`CustomSort: swapping`)
    let swappedScrips = [...scrips];
    let scrip = scrips[oldIndex];
    swappedScrips.splice(oldIndex, 1);
    swappedScrips.splice(newIndex, 0, scrip);
    // console.log(`CustomSort: swapped`)
    PrefsService.setCustomSort(id, swappedScrips);
    return swappedScrips;
}

function getIndex(lists, selectedList) {
    return lists.map(x => parseInt(x.id)).indexOf(parseInt(selectedList));
}

function cloneArray(array) {
    return [...array];
}

//Reducer
export function watchListReducer(state = initialState, action) {
    const { type } = action;
    const { lists } = state;
    switch (type) {
        case GET_LISTS: {
            return { ...state, fetchingLists: true, };
        }
        case GET_LISTS_SUCCESS: {
            const { list, storedList } = action;
            return {
                ...state,
                fetchingLists: false,
                lists: list,
                selectedList: storedList.id,
                selectedListType: storedList.type,
            };
        }
        case GET_LISTS_FAILURE: {
            const { error } = action;
            return {
                ...state,
                fetchingLists: false,
                listsError: error,
                lists: [],
                selectedList: -1,
                alert: { message: "Error fetching WatchLists", type: 'error' }
            };
        }
        case GET_SCRIPS: {
            return {
                ...state,
                fetchingLists: false,
                fetchingScrips: !action.dontRefresh,
            };
        }
        case GET_SCRIPS_SUCCESS: {
            const { items, selectedList, sortOption, customSort, sortedScrips } = action;
            var index = getIndex(lists, selectedList);
            let currentList = lists[index];

            currentList.sortOption = sortOption;
            currentList.customSort = customSort;
            return {
                ...state,
                fetchingScrips: false,
                scrips: sortedScrips,
                // scrips: sortScrips(items, currentList),
                selectedList,
                selectedListName: currentList.name,
                selectedListType: currentList.type,
            };
        }
        case GET_SCRIPS_FAILURE: {
            return {
                ...state,
                alert: { message: action.error.msg, type: 'error' }
            };
        }
        case ADD_SCRIP_STATUS:
        case DELETE_SCRIP_STATUS: {
            return {
                ...state,
                alert: { message: action.status, type: action.statusType }
            };
        }
        case SORT_LIST: {
            const { sortType, sortDesc, sortedScrips } = action.item;
            return {
                ...state,
                sortType,
                sortDesc,
                scrips: sortedScrips,
                // scrips: sortScrips(state.scrips, { customSort, sortOption }),
            };
        }
        case SORT_END: {
            return {
                ...state,
                dragCompleted: true,
                scrips: action.data
            };
        }
        case ADD_LIST: {
            let arr = [...lists];
            arr[arr.length] = action.item;
            return {
                ...state,
                scrips: [],
                selectedList: action.item.id,
                selectedListName: action.item.name,
                selectedListType: 'client',
                lists: arr,
                alert: { message: `Watchlist ${action.item.name} added successfully.`, type: 'success' }
            };
        }
        case EDIT_LIST: {
            let arr = [...lists];
            let idx = arr.findIndex(x => x.id == action.item.id);
            let oldItem = arr[idx];
            let newItem = action.item;
            let lastListName = oldItem.watchListName;
            oldItem.name = newItem.name;
            oldItem.watchListName = newItem.name;
            arr[idx] = oldItem;
            return {
                ...state,
                selectedListName: newItem.name,
                lists: arr,
                alert: { message: `Watchlist ${lastListName} edited successfully.`, type: 'success' }
            };
        }
        case DELETE_LIST: {
            const { selectedListName } = state;
            const { item: { id, type, name, lists: newLists } } = action;
            return {
                ...state,
                selectedList: id,
                selectedListType: type,
                selectedListName: name,
                lists: newLists,
                alert: { message: `Watchlist ${selectedListName} deleted successfully.`, type: 'error' }
            };
        }
        case CHANGE_LIST: {
            const {
                list: { id, name, type }
            } = action;
            // let index = getIndex(state.lists, watchListId);
            // let currentList = state.lists[index];
            return {
                ...state,
                scrips: [],
                selectedList: id,
                selectedListName: name,
                selectedListType: type
            };
        }
        case CLEAR_ALERT: {
            return {
                ...state,
                alert: {}
            };
        }

        default:
            return state;
    }
}

const initialState = {
    fetchingLists: true,
    listsError: null,
    fetchingScrips: false,
    scripsError: null,
    selectedList: -1,
    selectedListType: 'client',
    loading: true,
    lists: [],
    scrips: [],
    alert: {},
    dragCompleted: false,
};
