import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import { getDate } from "../data";
import styles from "../styles";

function ConfirmEquityOrder(props) {
  const { classes, formValues, loading, handleBack, handleStep, sip, lastStep } = props

  const HeaderDiv = () => {
    return (
      <Grid container className={classes.valueHeaderGrid}>
        <Grid item xs={12}>
          <Grid container className={classes.centerContent}>
            <Grid item xs={sip ? 2 : 4}>
              <Typography variant="caption" className={classes.blueText}>Stocks</Typography>
            </Grid>
            <Grid item xs={sip ? 1 : 2} className={classes.centerContent}>
              <Typography variant="caption" className={classes.blueText}>LTP</Typography>
            </Grid>
            <Grid item xs={1} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>Action</Typography>
            </Grid>
            <Grid item xs={1} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>Qty</Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>OrderType</Typography>
            </Grid>
            <Grid item xs={sip ? 1 : 2} className={classes.centerContent}>
              <Typography variant="caption" align="center" className={classes.blueText}>ValidTill</Typography>
            </Grid>
            {
              sip ?
                <>
                  <Grid item xs={2} className={classes.centerContent}>
                    <Typography variant="caption" align="center" className={classes.blueText}>Start Date</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.centerContent}>
                    <Typography variant="caption" align="center" className={classes.blueText}>No of Installments</Typography>
                  </Grid>
                </>
                :
                <></>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const ValueDiv = () => {
    return (
      <Grid container className={classes.confirmGridItem}>
        {
          formValues && formValues.data && formValues.data.length > 0 ?
            formValues.data.map((ele, index) => (
              <Grid item xs={12} className={classes.valueGridItem} key={index} style={index === formValues.data.length - 1 ? { borderBottom: 0 } : {}}>
                <Grid container className={classes.centerContent}>
                  <Grid item xs={sip ? 2 : 4}>
                    <Typography variant="subtitle1" className={classes.primaryText}>{ele.symbol || "--"}</Typography>
                    <Typography variant="caption" className={classes.secondaryText}>
                      {(ele.exchange || "--") + " " + (ele.segment || "EQ")}
                    </Typography>
                  </Grid>
                  <Grid item xs={sip ? 1 : 2}>
                    <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(ele.ltp && Number(ele.ltp).toFixed(2)) || "--"}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle1" align="center" className={[classes.primaryText, formValues.action].join(" ")}>{formValues.action || "--"}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle1" align="center" className={classes.primaryText}>{ele.quantity || "0"}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1" align="center" className={classes.primaryText}>{formValues.orderType || "--"}</Typography>
                  </Grid>
                  <Grid item xs={sip ? 1 : 2}>
                    <Typography variant="subtitle1" align="center" className={classes.primaryText}>{formValues.validTill || "--"}</Typography>
                  </Grid>
                  {
                    sip ?
                      <>
                        <Grid item xs={2}>
                          <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(formValues.startDate && getDate(formValues.startDate)) || "--"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="subtitle1" align="center" className={classes.primaryText}>{formValues.installments || "--"}</Typography>
                        </Grid>
                      </>
                      :
                      <></>
                  }
                </Grid>
              </Grid>
            ))
            :
            <></>
        }
      </Grid>
    )
  }

  return (
    <>
      <Grid container className={classes.confirmValueGrid}>
        <Grid item xs={12}>
          {HeaderDiv()}
        </Grid>
        <Grid item xs={12}>
          {ValueDiv()}
        </Grid>
      </Grid>
      <Grid container className={classes.buttonGrid}>
        <Grid item xs={3}>
          <Button variant="outlined" disabled={loading.submitting} className={classes.cancel} onClick={handleBack}>Back</Button>
        </Grid>
        <Grid item xs={lastStep ? 4 : 3}>
          <Button variant="contained" disabled={loading.submitting} className={lastStep ? classes.sendOrder : classes.order} onClick={handleStep}>
            {
              lastStep ?
                <>
                  {loading && loading.submitting && <CircularProgress size={20} style={{ marginRight: "12px", color: "#7d7d7d" }} />}Confirm and Send Orders
              </>
                :
                "Confirm"
            }
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(ConfirmEquityOrder)