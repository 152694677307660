import { getToken } from "helpers/cookies";
import { goToLogin } from "helpers/navHelpers";
import React from 'react';
import { Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    let accessToken = getToken(true);
    
        return (
            <Route {...rest} render={props => (
                accessToken
                    ? <Component {...props} />
                    : goToLogin()
            )} />
        )

    
}