import React from "react"
import { withStyles } from "@mui/styles"

const styles = theme => ({
  root: {
    background: theme.palette.background.default,
    padding: "16px",
    whiteSpace: "pre-wrap",
    borderRadius: "6px",
    fontSize: "14px",
    lineHeight: "18px",
    margin: "8px 0px",
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '14px',
    }
  }
})

function NewsDialog(props) {
  const { data, classes } = props
  return (
    <pre className={classes.root}>
      {data || "No data found"}
    </pre>
  )
}

export default withStyles(styles)(NewsDialog);
