import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { Button, Paper, Typography } from '@mui/material';
import getCookie, { setCookie } from 'helpers/cookies';
import { DialogActions } from 'redux/Dialog';

const data = {
  heading: 'Update Nominee Details',
  enterprise: `${process.env.REACT_APP_ENT_URL}BO?moduleid=boprocess&name=clientEnquiryEdit&redirectionFor=AddNewNominee`,
  text: `As per SEBI guidelines "All existing eligible trading and demat account holders shall provide choice of nomination on or before 30th September 2023.
  You are therefore requested to update the choice of Nomination on or before 30th September 2023. Failing which the trading/demat accounts shall be frozen.`,
  submit: '/ms-trading-authservice/riskdisclamerlog'
}

function NomineePopup(props) {
  const { classes, onCancel } = props;

  useEffect(() => {
    setCookie('nominee', new Date().getTime())
  }, [])

  return (
    <Paper className={classes.root}>
      <div className={classes.headerDiv}>
        <Typography variant='h3' className={classes.blueText}>{data.heading}</Typography>
      </div>
      <Typography variant='h5' className={classes.text}>{data.text}</Typography>
      <Button
        variant='contained'
        onClick={() => {
          onCancel();
          if (getCookie("showBanner") === "Yes") {
            DialogActions.openBannersPopup()
          }
          window.open(data.enterprise, "_blank")
        }}
        className={classes.button} color="primary">
        Update Now
      </Button>
    </Paper>
  )
}

export default withStyles(styles)(NomineePopup);