import common from "./common";

export default {
  ...common,
  type: 'light',
  background: {
    default: '#ffffff',
    secondary: '#EBEEF5',
    darker: '#ffffff',
    primary: '#EBEEF5',
    WLselectedItem: '#ffffff',
    tableHead: '#F4F7FD'
  },
  color: {
    default: '#ffffff'
  },
  border: {
    default: '#D7DEE3'
  },
  tab_border: {
    default: '#fff'
  },
  color3: {
    default: '#F9F6F6'
  },
  color4: {
    default: '#497CAC'
  },
  color5: {
    default: '#EDF1F4'
  },
  color6: {
    default: '#DBE5EC'
  },
  color7: {
    default: '#EAF6E6',
    primary: '#EAF6E6'
  },
  color8: {
    default: '#FEEBEB',
    primary: '#FEEBEB'
  },
  color9: {
    default: '#333333'
  },
  color10: {
    default: '#A4A4A4'
  },
  color11: {
    default: '#333333',
    primary: '#333333'
  },
  color12: {
    default: '#333333'
  },
  color13: {
    default: '#FFFFFF'
  },
  color14: {
    default: '#DEDBDB',
    primary: '#DEDBDB'
  },
  color15: {
    default: '#999999'
  },
  color16: {
    default: '#999999'
  },
  color17: {
    default: '#F9FAFB'
  },
  color18: {
    default: '#66788ab0'
  },
  color18: {
    default: '#F8F8F8'
  },
  priceColor: {
    green: '#45B880',
    red: '#ED4740'
  },
  text: {
    primary: '#292e3e',
    secondary: '#66788A',
    disabled: '#A6B1BB',
    disclaimer: '#006CA8',
    light: '#000',
    dark: '#ffffff'
  }
};
