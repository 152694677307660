import axios from 'helpers/interceptor';
import { canCall } from "helpers/pollingHelper";
import { getFormattedPayloadWatchlist, getPayload1 } from "helpers/subscription";
import { isPipe } from "services";

const REFRESH_INTERVAL = process.env.REACT_APP_REFRESH_FREQUENCY;

export const feedService = {
    getFeed,
    getWatchlistFeed,
    getFullFeed,
};

const colMappings = {
    partial: {
        shortCode: 0, token: 1, change: 2, ltp: 3, percentChange: 4, askPrice: 5, bidPrice: 6
    },
    full: {
        shortCode: 0, token: 1, change: 2, ltp: 3, percentChange: 4, askPrice: 5, bidPrice: 6, open: 7, high: 8, low: 9, close: 10,
        ltq: 11, yearlyHigh: 12, yearlyLow: 13, avgPrice: 14, turnOverData: 15, oi: 16, oiPercentChange: 17, upperCircuit: 18,
        lowerCircuit: 19, volume: 20, ltt: 21
    },
    all: {
        scripName: 0, symbol: 1, exchange: 2, ltp: 3, change: 4, percentChange: 5, updateOn: 6, volume: 7, avgPrice: 8, close: 9, high: 10,
        low: 11, open: 12, oi: 13, oiChange: 14, marketLot: 15, turnOverData: 16, askPrice: 17, askQty: 18, bidPrice: 19, bidQty: 20,
        totBuy: 21, totSell: 22, ltq: 23, segment: 24, scripId: 25, token: 26, shortCode: 27, yearlyHigh: 28, yearlyLow: 29,
        yearlyHighDate: 30, yearlyLowDate: 31, upperCircuit: 32, lowerCircuit: 33, oiPercentChange: 34, spotPrice: 35, todayHighTime: 36,
        todayLowTime: 37, expiryDate: 38, optionType: 39, strikePrice: 40, ltt: 41, circuitPercent: 42, marketType: 43,
        isTBApproved: 44, scripSeries: 45, bseScripSeries: 46, tbChartExchange: 47, bseScripCode: 48, scripSymbol: 49, isMTFApproved: 50,
        isMarketIndex: 51, marginPercentage: 52, qtyMultiplier: 53, qtyNumerator: 54, qtyDenominator: 55, instType: 56, volumeFreezeQty: 57,
        mtfMargin: 58, nseAsmgsm: 59, bseAsmgsm: 60, nseSurvIndCode: 61/* , isBKTAllowed: 62 */
    }
};

function checkTypeAndConvert(value) {
    if (value === 'true' || value === 'false') {
        return value === 'true';
    }
    // For converting Numbers if required
    // Number.isNaN(parseInt('10'))
    // false
    // Number.isNaN(parseInt('10.100'))
    // false
    // Number.isNaN(parseInt('njn'))
    // true
    // Number.isNaN(parseInt(''))
    // true
    return value;
}

function convertPipeToArray(rows) {
    if (!isPipe) {
        return rows;//Uncomment to get JSON data instead of Pipe separated data
    }
    const { full, partial, all } = colMappings;

    if (rows && rows.length > 0) {
        return rows.map(row => {
            try {
                const rowDataArray = row.split("|");
                const mapNNames = rowDataArray.length <= 7 ? partial : rowDataArray.length <= 22 ? full : all;
                let rowData = {};
                const keys = Object.keys(mapNNames);
                keys.forEach((key, index) => {
                    rowData = { ...rowData, [key]: checkTypeAndConvert(rowDataArray[mapNNames[key]]) };
                });
                return rowData;
            } catch (error) {
                return row;
            }
        });
    }
    return rows;
}

function getFeed(payload) {
    if (payload && payload.length == 0) return [];
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_FEED_URL}/ms-feed-service/content/market/feed`,
        data: payload
    };

    return axios(requestOptions)
        .then((response) => {
            // console.log(response.data.success)
            return response.data.success;
        })
        .catch(function (error) {
            console.log(error);
        });
}

function getFullFeed(scrips, addToken, checkMarket) {
    if (!scrips || scrips?.length === 0) {
        return;
    }

    const payload = [];

    scrips.map(scrip => {
        const { scripId, token, shortCode, segment, exchange, isMarketIndex, bseScripCode } = scrip;
        if (checkMarket && !canCall(shortCode)) return;//Skip if Market Closed
        payload.push(isPipe ?
            {
                token: isMarketIndex ? scripId : exchange === 'BSE' ? bseScripCode || token : token,
                shortCode,
                segment,
                exchange
            }
            :
            getPayload1(scrip, addToken));
    });

    if(payload.length > 0) {
        const requestOptions = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_FEED_URL}${isPipe
                ? '/ms-watchlist-service/watchlist/pipe/fullFeed'
                : '/ms-feed-service/content/market/feed'}`,
            data: payload
        };
    
        return new Promise(function (resolve, reject) {
            axios(requestOptions).then(response => {
                if (response.data.success?.length > 0) {
                    const resData = convertPipeToArray(response.data.success);
    
                    resolve(resData);
                } else if (response.data.error?.length > 0) {
                    resolve(response.data.error)
                }
            }, (error) => {
                resolve([{ error: (error && error.response && error.response.data && error.response.data.message) || error.message }]);
            });
        });
    }

    // return axios(requestOptions)
    //     .then((response) => {
    //         // console.log(response.data.success)
    //         if (response.data.success?.length > 0) {
    //             const resData = convertPipeToArray(response.data.success);

    //             return resData;
    //         } else if (response.data.error?.length > 0) {
    //             throw new Error(response.data.error);
    //         }
    //         return response.data.success;
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //         // console.log('BSENSE error 1', JSON.stringify(error));
    //         // throw new Error([error]);
    //         // return [error];
    //         throw new Error(error);
    //         // throw error;
    //     });
}

function getWatchlistFeed(scrips) {
    let payload = getFormattedPayloadWatchlist(scrips)

    if (payload && payload.length == 0) return [];
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_FEED_URL}/ms-watchlist-service/watchlist${isPipe ? '/pipe' : ''}/feed`,
        data: payload,
        timeout: REFRESH_INTERVAL
    };

    return axios(requestOptions)
        .then((response) => {
            const resData = convertPipeToArray(response.data.success);

            return resData;
            return response.data.success;
        })
        .catch(function (error) {
            console.log(error);
        });
}
