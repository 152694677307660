import { Button, CircularProgress, Grid, Typography, useMediaQuery, LinearProgress } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import buttonHelper from "helpers/buttonHelper";
import React from "react";
import styles from './styles';
import Top from "./top";

const displayValue = {
  type: {
    B: "Buy",
    S: "Sell",
    SS: "Short Sell"
  },
  orderType: {
    LIMIT: "Limit",
    MKT: "Market",
    SL: "Stop Loss"
  },
  validTill: {
    GFD: "Good For Day",
    IOC: "Immediate or Cancel",
    GTD: "Good Till Day",
    GTC: "Good Till Cancel",
  }
}

const USER = `${process.env.REACT_APP_USER}`


function CompactOrderConfirm(props) {
  const { classes, compactView, activeStep, handleExchangeChange, showExchange, selectClient, reduxForm, values, pushOrder, handleBack, scrip, submitting, splitPopup } = props
  const { exchange, type, price, triggerPrice, quantity, disclosedQuantity, product, validTill, orderType, validityDate, lot } = values
  const { segment, volumeFreezeQty } = scrip;
  const { splitQty, remainingQty } = splitPopup;

  const theme = createTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'))

  function splitQuantity() {
    if (volumeFreezeQty) {
      return (
        <>
          {splitQty > 0 && <Typography className={classes.confirmBlueText}>{`${splitQty} ${splitQty > 1 ? "Orders" : "Order"} of ${volumeFreezeQty} Qty`}</Typography>}
          {remainingQty > 0 && <Typography className={classes.confirmBlueText}>{`1 Order of ${remainingQty} Qty`}</Typography>}
        </>
      )
    } else {
      return (
        <Typography className={classes.approxValue}>--</Typography>
      )
    }
  }

  const firstConfirmRow = () => {
    return (
      <>
        {
          /*  showExchange ? */
          <div className={classes.divMargin}>
            <Typography align="center" className={[classes.default, "compact"].join(" ")} >Exchange</Typography>
            <Typography variant="body1" align="center" className={classes.confirmBlueText}>{exchange}</Typography>
          </div>
          /*  :
           <></> */
        }
        <div className={classes.divMargin}>
          <Typography align="center" className={[classes.default, "compact"].join(" ")}>Action</Typography>
          <Typography className={classes.confirmBlueText} variant="body1" align="center" style={{ color: type === "B" ? "#299D00" : "#f54040", fontWeight: 600 }}>{displayValue.type[type]}</Typography>
        </div>
        <div className={classes.divMargin}>
          <Typography align="center" className={[classes.default, "compact"].join(" ")}>Order Type</Typography>
          <Typography variant="body1" align="center" className={classes.confirmBlueText}>{displayValue.orderType[orderType]}</Typography>
        </div>
        <div className={classes.divMargin}>
          <Typography align="center" className={[classes.default, "compact"].join(" ")}>Price</Typography>
          <Typography variant="body1" align="center" className={classes.confirmBlueText}>₹ {Number(price).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
        </div>
        {
          matchesSm && USER === "client" && segment === "FNO" ?
            <></>
            :
            <div className={classes.divMargin}>
              <Typography align="center" className={[classes.default, "compact"].join(" ")}>Trigger Price</Typography>
              <Typography variant="body1" align="center" className={classes.confirmBlueText}>₹ {Number(triggerPrice).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
            </div>
        }
        {
          matchesSm && USER !== "client" ?
            segment === "FNO" ?
              <></>
              :
              <div className={classes.divMargin}>
                <Typography align="center" className={[classes.default, "compact"].join(" ")}>Disclosed Qty</Typography>
                <Typography variant="body1" align="center" className={classes.confirmBlueText}>{disclosedQuantity || 0}</Typography>
              </div>
            :
            <></>
        }
      </>
    )
  }

  const secondConfirmRow = () => {
    return (
      <>
        {
          matchesSm && USER === "client" && segment === "FNO" ?
            <div className={classes.divMargin}>
              <Typography align="center" className={[classes.default, "compact"].join(" ")}>Trigger Price</Typography>
              <Typography variant="body1" align="center" className={classes.confirmBlueText}>₹ {Number(triggerPrice).toFixed(segment === "CURR" ? 4 : 2)}</Typography>
            </div>
            :
            <></>
        }
        <div className={classes.divMargin}>
          <Typography align="center" className={[classes.default, "compact"].join(" ")}>{segment === "CURR" ? "Lot" : "Quantity"}</Typography>
          <Typography variant="body1" align="center" className={classes.confirmBlueText}>{segment === "CURR" ? lot : quantity}</Typography>
        </div>
        {
          matchesSm && USER !== "client" ?
            <></>
            :
            segment === "FNO" ?
              <></>
              :
              <div className={classes.divMargin}>
                <Typography align="center" className={[classes.default, "compact"].join(" ")}>Disclosed Qty</Typography>
                <Typography variant="body1" align="center" className={classes.confirmBlueText}>{disclosedQuantity || 0}</Typography>
              </div>
        }
        {
          validTill === "GTD" &&
          <div className={classes.divMargin}>
            <Typography align="center" className={[classes.default, "compact"].join(" ")}>{"Validity Date"}</Typography>
            <Typography variant="body1" align="center" className={classes.confirmBlueText}>{validityDate || "--"}</Typography>
          </div>
        }
        <div className={classes.divMargin}>
          <Typography className={[classes.default, "compact", "valid"].join(" ")}>Product Type:</Typography>
          <Typography variant="body1" align="center" className={classes.confirmBlueText}>{product}</Typography>
        </div>
        <div className={classes.divMargin}>
          <Typography className={[classes.default, "compact", "valid"].join(" ")}>Valid Till:</Typography>
          <Typography variant="body1" align="center" className={classes.confirmBlueText}>{displayValue.validTill[validTill]}</Typography>
        </div>
        {segment === "FNO" && (splitQty > 1 || (splitQty > 0 && remainingQty > 0)) && <div className={classes.divMargin}>
          <Typography align="center" className={[classes.default, "compact"].join(" ")}>{"Split Order"}</Typography>
          {splitQuantity()}
        </div>}
        {
          USER !== "client" &&
          <div>
            <Typography className={[classes.default, "compact", "valid"].join(" ")}>Client Code:</Typography>
            <Typography variant="body1" align="center" className={classes.confirmBlueText}>
              {Object.keys(selectClient).length > 0 && selectClient.ClientCode !== "" ? selectClient.ClientName !== "" ? `${selectClient.ClientName} - ${selectClient.ClientCode}` : selectClient.ClientCode : "--"}
            </Typography>
          </div>
        }
      </>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: "4px 0px 8px" }}>
        <Top reduxForm={reduxForm} selectedScrip={scrip || {}} activeStep={activeStep} compactView={compactView} values={values} handleExchangeChange={handleExchangeChange} showExchange={showExchange} />
      </Grid>
      <Grid item xs={12} className={classes.compactConfirm}>
        <div className={classes.elementSpacing}>
          {
            matchesSm ?
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                  {firstConfirmRow()}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {secondConfirmRow()}
                </div>
              </div>
              :
              <>
                {firstConfirmRow()}
                {secondConfirmRow()}
              </>
          }
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item lg={2} md={2} sm={3} xs={4} style={{ marginRight: "42px" }}>
            <Button onClick={handleBack} disabled={submitting} className={[classes.cancel, classes.compactButtonFixedWidth].join(" ")}>Back</Button>
          </Grid>
          <Grid item lg={2} md={2} sm={3} xs={4} style={{ position: 'relative' }}>
            <Button autoFocus={true} onClick={(e) => buttonHelper.onSingleClick(e, pushOrder)} disabled={submitting} className={[classes.order, classes.containedBlue].join(" ")}>
              {(submitting ? (splitQty > 1 || (splitQty > 0 && remainingQty > 0)) ? '' : <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} /> : '')}
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(combineStyles(styles, commonStyles))(CompactOrderConfirm)
