import React from "react"
import { FieldArray, Form, Formik } from "formik";
import { withStyles } from '@mui/styles';
import styles from "../styles"
import * as Yup from "yup";
import { Grid, Typography, FormControl, Button, FormControlLabel, Link, MenuItem, Tooltip } from "@mui/material";
import { CssOutlinedInput, CssCheckbox, getDate, CssSelect, SelectOutlinedInput } from "../data";
import { KeyboardArrowDown as Arrow } from "@mui/icons-material";

function MfOrder(props) {
  const { classes, formData, handleFormValues, formValues, handleBack, activeStep, buySell, equitySum, accountData, lot, productName } = props

  const modifyFormData = (data, filled) => {
    let obj = {}
    let arr = []
    data.map((ele) => {
      arr.push({
        amount: filled ?
          lot != formValues.lot ? ele.minAmount * (Number(lot) || 1) : ele.amount
          : ele.current_price * (Number(lot) || 1),
        units: buySell === "Sell" ? filled ? ele.units : ele.free_units : 1
      })
    })
    if (filled) {
      obj.terms = formValues.terms
      obj.accountDetails = formValues.accountDetails
      obj.ifscCode = formValues.ifscCode
      obj.bankId = formValues.bankId
      obj.paymentMode = formValues.paymentMode
    } else {
      obj.terms = false
      obj.accountDetails = accountData?.[0]?.accountNumber || ''
      obj.ifscCode = accountData?.[0]?.ifscCode || ''
      obj.bankId = accountData?.[0]?.netID || ''
      obj.paymentMode = accountData?.[0]?.paymentMode || ''
    }
    obj.mf = [...arr]
    return obj
  }

  const sumOf = (values) => {
    let sum = 0
    if (values.length > 0) {
      values.forEach((ele, index) => {
        if (buySell === "Sell") {
          sum = sum + (Number(formData[index].nav_price) * Number(ele.units))
        } else {
          sum = sum + Number(ele.amount)
        }
      })
    }
    return Number(sum).toFixed(2)
  }

  Yup.addMethod(Yup.number, "amountTest", function (args) {
    const message = args;
    return this.test("amountTest", message, function (value) {
      const { path, createError, options } = this;
      const { originalValue, index } = options
      if (this.parent.amount === undefined || this.parent.amount === 0) {
        return true
      } else if (originalValue) {
        if (formData && formData[index] && formData[index].minAmount && Number(originalValue) < Number(formData[index].minAmount)) {
          return createError({
            path: path,
            message: `Amount should be greater than ${formData && formData[index] && formData[index].minAmount}`,
          })
        } /* else if (formData && formData[index] && formData[index].maxAmount && Number(originalValue) > Number(formData[index].maxAmount)) {
          return createError({
            path: path,
            message: `Amount should be less than ${formData && formData[index] && formData[index].maxAmount}`,
          })
        }  */else {
          return true
        }
      }
    })
  })

  Yup.addMethod(Yup.number, "isDecimal", function (args) {
    const message = args;
    return this.test("isDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        var splitted = String(options.originalValue).split(".");
        if (splitted.length === 2) {
          if (splitted[1].length === 0) {
            return createError({ path: path, message: "Value is expected after decimal" })
          } else if (splitted[1].length > 4) {
            return createError({ path: path, message: "Maximum four decimal places are allowed" })
          } else {
            return true
          }
        } else if (splitted.length === 1) {
          return true;
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "checkRedeemUnits", function (args) {
    const message = args;
    return this.test("checkRedeemUnits", message, function (value) {
      const { path, createError, options } = this;
      const { originalValue, index } = options
      if (originalValue === undefined || Number(originalValue) === 0) {
        return true
      } else
        if (Number(originalValue) < Number(formData[index].minimumRedeemUnits)) {
          return createError({ path: path, message: `Redeem units should be greater than ${formData[index].minimumRedeemUnits}` })
        } else if (Number(originalValue) > Number(formData[index].free_units)) {
          return createError({ path: path, message: `Redeem units should be less than ${formData[index].free_units}` })
        } else {
          return true
        }
    })
  })

  function getAccount(value) {
    let account = (accountData.length > 0 && accountData.find(ele => ele.accountNumber == value)) || {};
    return account || {}
  }

  function onAccountChange(value, setFieldValue) {
    let account = getAccount(value)
    setFieldValue("accountDetails", value)
    setFieldValue("ifscCode", account.ifscCode || '')
    setFieldValue("bankId", account.netID || '')
    setFieldValue("paymentMode", account.paymentMode || '')
  }

  const FormFields = (props) => {
    const { values, handleChange, setFieldTouched, resetForm, errors, touched, setFieldValue } = props
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.headerPadding}>
              <Grid item xs={buySell === "Sell" ? 4 : 6} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Schemes</Typography>
              </Grid>
              <Grid item xs={buySell === "Sell" ? 2 : 3} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>NAV</Typography>
              </Grid>
              {
                buySell === "Sell"
                  ?
                  <>
                    <Grid item xs={2} className={classes.centerContent}>
                      <Typography variant="caption" align="center" className={classes.blueText}>Available Free Units</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.centerContent}>
                      <Typography variant="caption" align="center" className={classes.blueText}>Redeem Units</Typography>
                    </Grid>
                  </>
                  :
                  <></>
              }
              <Grid item xs={buySell === "Sell" ? 2 : 3} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>{buySell === "Sell" ? "Value of Redeem Units" : "One-Time Amount"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FieldArray
          name="mf"
          render={arrayHelpers => {
            return (
              <Grid item xs={12} className={classes.gridHeight}>
                {
                  values && values.mf && values.mf.length > 0
                    ? values.mf.map((user, index) => (
                      <Grid container key={index} className={classes.gridItem}>
                        <Grid item xs={buySell === "Sell" ? 4 : 6}>
                          <Typography variant="subtitle1" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].fund_name) || "--"}</Typography>
                        </Grid>
                        <Grid item xs={buySell === "Sell" ? 2 : 3}>
                          <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].nav_price && Number(formData[index].nav_price).toFixed(4)) || "--"}</Typography>
                        </Grid>
                        {
                          buySell === "Sell" ?
                            <>
                              <Grid item xs={2}>
                                <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].free_units) || "--"}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                  <CssOutlinedInput
                                    id={`mf.${index}.units`}
                                    name={`mf.${index}.units`}
                                    autoFocus={index === 0 ? true : false}
                                    classes={{ input: classes.input }}
                                    onChange={handleChange}
                                    value={values.mf[index].units}
                                    onKeyUp={() => setFieldTouched("units", true)}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                  />
                                </FormControl>
                                {errors && errors.mf && errors.mf.length > 0 && errors.mf[index] && errors.mf[index].units &&
                                  <p className={classes.errorText}>{errors.mf[index].units}</p>}
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="subtitle1" align="right" className={classes.primaryText}>{Number(values.mf[index].units * formData[index].nav_price).toFixed(2) || "--"}</Typography>
                              </Grid>
                            </>
                            :
                            <Grid item xs={buySell === "Sell" ? 2 : 3}>
                              <FormControl variant="outlined" style={{ width: "100%" }}>
                                <CssOutlinedInput
                                  id={`mf.${index}.amount`}
                                  name={`mf.${index}.amount`}
                                  autoFocus={productName === 'buckets' && activeStep === 0 ? false : index === 0}
                                  classes={{ input: classes.input }}
                                  onChange={handleChange}
                                  value={values.mf[index].amount}
                                  onKeyUp={() => setFieldTouched("amount", true)}
                                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                              </FormControl>
                              {errors && errors.mf && errors.mf.length > 0 && errors.mf[index] && errors.mf[index].amount &&
                                <p className={classes.errorText}>{errors.mf[index].amount}</p>}
                            </Grid>
                        }
                      </Grid>
                    ))
                    : null
                }
              </Grid>
            );
          }}
        />
        <Grid item xs={12}>
          <Grid container className={classes.approxDiv}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.secondaryText}>{buySell === "Sell" ? `Total value based on NAV as on ${getDate(new Date())}` : "Total One-Time Amount"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="outlined" className={classes.outlinedButton} style={{ padding: 0 }} onClick={() => resetForm({ values: modifyFormData(formData) })}>Reset</Button>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" className={classes.primaryText}>{(sumOf(values.mf)) || "--"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ paddingTop: 0 }}>
            <Grid item xs={4}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <Typography className={classes.fieldText}>Account Number</Typography>
                <CssSelect
                  labelId="demo-simple-select-filled-label"
                  id="accountDetails"
                  name="accountDetails"
                  value={values.accountDetails}
                  onChange={(e) => onAccountChange(e.target.value, setFieldValue)}
                  error={touched.accountDetails && errors.accountDetails ? true : false}
                  onKeyUp={() => setFieldTouched("accountDetails", true)}
                  className={classes.select}
                  input={<Tooltip title={values.accountDetails} aria-label={values.accountDetails} className={""}><SelectOutlinedInput /></Tooltip>}
                  variant="standard"
                  IconComponent={() => <Arrow style={{ marginRight: "4px" }} />}
                >
                  {
                    accountData.length > 0 ?
                      accountData.map((ele) => (
                        <MenuItem key={ele.accountNumber} value={ele.accountNumber} className={classes.menuItem}>{ele.bankName}({ele.accountNumber})</MenuItem>
                      ))
                      :
                      <MenuItem key={""} value={""} className={classes.menuItem}>{""}</MenuItem>
                  }
                </CssSelect>
              </FormControl>
              {errors && errors.accountDetails && <p className={classes.errorText}>{errors.accountDetails}</p>}
            </Grid>
          </Grid>
        </Grid>
        {
          (buySell === "Sell" || equitySum === 0) ?
            <></>
            :
            <Grid item xs={12}>
              <Grid container className={classes.bottomInfoDiv}>
                <Grid item xs={6}>
                  <Typography variant="caption" className={classes.primaryText}>Approx Total (EQ + MF)</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" style={{ fontWeight: 600, justifyContent: "flex-end" }} className={[classes.primaryText, classes.centerContent].join(" ")}>
                    {Number(Number(sumOf(values.mf)) + Number(equitySum)).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.approxDiv} style={{ background: "#E5FCFF", padding: 6 }}>
                  <Typography variant="caption" style={{ color: "#000", }}>It will take 2-3 business days for MF units to reflect in your Products Portfolio.</Typography>
                </Grid>
              </Grid>
            </Grid>
        }
        <Grid item xs={12} className={classes.termsGrid}>
          <FormControl variant="outlined">
            <div className={classes.termsDiv}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  id="terms"
                  name="terms"
                  control={<CssCheckbox
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    color="primary"
                    checked={values.terms}
                    onKeyUp={() => setFieldTouched("terms", true)}
                    onChange={handleChange}
                  />}
                  label={""}
                  labelPlacement="end"
                />
                <Typography style={{ marginLeft: "-16px" }} variant="subtitle2" className={[classes.secondaryText, classes.checkboxMargin].join(" ")}>By continuing, you agree to &nbsp;
                  <span>
                    <Link
                      className={classes.blueText}
                      target="_blank"
                      href="/tnc/TnCMF.pdf"
                      rel="noopener"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                  &nbsp; of Tradebulls.
                </Typography>
              </div>
              {errors.terms && touched.terms && <p className={classes.errorText}>{errors.terms}</p>}
            </div>
          </FormControl>
        </Grid>
        {
          activeStep !== 0 ?
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="outlined" className={classes.cancel} onClick={() => handleBack(0, "Equity")}>{"Back"}</Button>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="contained" style={{ marginLeft: 0 }} className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
        }
      </>
    )
  }

  const form = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={formValues && formValues && formValues.data && formValues.data.length > 0 ? modifyFormData(formValues.data, true) : modifyFormData(formData)}
        validationSchema={Yup.object({
          mf: buySell === "Sell" ? Yup.array().of(
            Yup.object().shape({
              amount: Yup.number(),
              units: Yup.number().typeError("Invalid number")
                .isDecimal()
                .checkRedeemUnits()
            })
          )
            :
            Yup.array().of(
              Yup.object().shape({
                amount: Yup.number().integer("Decimal is not allowed").typeError("Invalid number")
                  .min(0, "Amount should be greater than or equal to Zero")
                  .max(990000000, "Amount should be less than 990000000")
                  .amountTest()
              })
            ),
          accountDetails: Yup.string()
            .required("Please select account number"),
          terms: Yup.boolean()
            .required("The terms and conditions must be accepted.")
            .oneOf([true], "The terms and conditions must be accepted."),
        })}
        onSubmit={values => {
          let data = []
          values.mf.map((ele, index) => {
            data.push({
              schemeName: formData[index].fund_name,
              amount: buySell === "Sell" ? Number(formData[index].nav_price * ele.units).toFixed(2) : ele.amount ? Number(ele.amount) : 0,
              nav: formData[index].nav_price,
              freeUnits: formData[index].free_units,
              schemeCode: formData[index].schemeCode || "",
              exchange: formData[index].exchange || "",
              isinNumber: formData[index].isinNumber,
              minAmount: formData[index].current_price || 1,
              settlementDate: formData[index].settlementDate || 0,
              units: buySell === "Sell" ? ele.units : 1
            })
          })
          let obj = {
            sumOf: sumOf(values.mf),
            terms: values.terms,
            action: buySell,
            accountDetails: values.accountDetails,
            ifscCode: values.ifscCode,
            bankId: values.bankId,
            paymentMode: values.paymentMode,
            lot: Number(lot || 1),
            data
          }
          if (productName === 'buckets') {
            (lot >= 1 && !String(lot).includes(".")) && handleFormValues(obj)
          } else {
            handleFormValues(obj)
          }
        }}
      >
        {({ values, resetForm, handleChange, errors, touched, setFieldTouched, setFieldValue }) => (
          <Form>
            <FormFields
              errors={errors}
              values={values}
              touched={touched}
              resetForm={resetForm}
              productName={productName}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div>
      {form()}
    </div>
  )
}

export default withStyles(styles)(MfOrder);
