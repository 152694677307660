import { Box, Paper, Tab, Tabs } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import axios from "helpers/interceptor";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { closeConfirmDialog, openConfirmDialog } from 'redux/ConfirmDialog';
import Activation from "./activation";
import Bank from "./bank";
import Basic from "./basic";
import Brokerage from "./brokerage";
import Facility from "./facility";
import Links from "./links";
import styles from "./styles";
import TopInfo from "./topInfo";

const CssTabs = withStyles(theme => ({
  root: {
    minHeight: "30px"
  },
  indicator: {
    backgroundColor: "#8DB1D2"
  },
}))(Tabs)

const CssTab = withStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      color: theme.palette.color4.default
    }
  },
}))(Tab);

const tabs = ["BASIC", "BANK/DP", "BROKERAGE", "ACTIVATION", "FACILITY", "LINKS"]

function TabPanel(props) {
  const { children, value, index, isMaximized, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={!isMaximized ? { height: 'calc(100% - 40px)' } : {}}
      {...other}
    >
      {value === index && (
        <Box className={[classes.box, isMaximized ? "max" : ""].join(" ")} style={{ padding: 0, /* height: isMaximized ? "calc(100vh - 329px)" : "calc(100vh - 680px)", */ overflowY: !isMaximized && index === 0 ? "auto" : "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Profile(props) {
  const { classes, maxedLayout, selectedClient, openDialog, closeDialog } = props

  const [clientData, setClientData] = useState("")
  const [value, setValue] = useState(0)
  const [isMaximized, setIsMaximized] = useState(false)
  const [callStarted, setCallStarted] = useState(false)

  useEffect(() => {
    if (maxedLayout === "profile") {
      setIsMaximized(true)
    } else {
      setIsMaximized(false)
    }
  }, [maxedLayout])

  useEffect(() => {
    setClientData("Loading...")
    if (selectedClient && selectedClient !== null && selectedClient.ClientCode) {
      axios.get(`${process.env.REACT_APP_API_URL}/ms-trading-customer-profile/dnt/profile/${selectedClient.ClientCode}`).then(resp => {
        if (resp.data.success) {
          setClientData(resp.data.success)
        } else {
          setClientData("No Data Found")
        }
      }).catch(error => {
        setClientData("Please try again later")
      })
    } else {
      setClientData("Please Select Customer")
    }
  }, [selectedClient])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleDialCall() {
    if (clientData && clientData.basic && clientData.basic.basicInfo && clientData.basic.basicInfo.mobileNumber) {
      let payload = {
        action: "dial",
        extentype: "remote",
        number: (clientData && clientData.basic && clientData.basic.basicInfo && clientData.basic.basicInfo.mobileNumber) || "",
        uid: selectedClient.ClientCode || ""
      }
      axios.post(`${process.env.REACT_APP_API_URL}/ms-ivr-telecalling/ivr/dial`, payload).then(resp => {
        if (resp.data.success) {
          let obj = {
            content: "Dialed successfully",
            hideCloseButton: true,
            okButtonText: "Ok",
            centerActions: true,
            onClose: closeDialog
          }
          openDialog(obj)
          setCallStarted(true)
        } else {
          //handleError
        }
      }).catch(error => {
        //handle error
      })
    }
  }

  const handleEndCall = () => {
    let obj = {
      content: "Call ended successfully",
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: closeDialog
    }
    openDialog(obj)
    setCallStarted(false)
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {
        typeof (clientData) === "string" ?
          <Placeholder
            loading={clientData === "Loading..." ? true : false}
            loadingMsg={""}
            error={clientData === "Loading..." ? "" : clientData}
          />
          :
          <div style={{ position: "absolute", top: 0, bottom: 0, display: "flex", width: "100%", flexDirection: "column" }}>
            <Paper elevation={2} className={[classes.topPaper, !isMaximized ? "minView" : ""].join(" ")}>
              <TopInfo
                basicInfo={(clientData && clientData.basic && clientData.basic.basicInfo) || {}}
                isMaximized={isMaximized}
                handleEndCall={handleEndCall}
                handleDialCall={handleDialCall}
                callStarted={callStarted}
                clientName={selectedClient && selectedClient.ClientName}
                clientCode={selectedClient && selectedClient.ClientCode}
                showCall={true}
              />
            </Paper>
            <div className={[classes.bottomDiv, !isMaximized ? "min" : ""].join(" ")}>
              <Paper elevation={2} className={classes.paper}>
                <CssTabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  {
                    tabs.map((ele, index) => (
                      <CssTab key={index} className={value === index ? classes.activeTab : classes.tab} label={ele} {...a11yProps(index)} />
                    ))
                  }
                </CssTabs>
                <TabPanel value={value} index={0} isMaximized={isMaximized} classes={classes}>
                  <Basic
                    value={value}
                    clientName={selectedClient && selectedClient.ClientName}
                    isMaximized={isMaximized}
                    basicInfo={(clientData && clientData.basic && clientData.basic.basicInfo) || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} isMaximized={isMaximized} classes={classes}>
                  <Bank
                    isMaximized={isMaximized}
                    bankdp={(clientData && clientData.bankdp) || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} isMaximized={isMaximized} classes={classes}>
                  <Brokerage
                    isMaximized={isMaximized}
                    brokerage={(clientData && clientData.brokerage) || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} isMaximized={isMaximized} classes={classes}>
                  <Activation
                    isMaximized={isMaximized}
                    activation={(clientData && clientData.activation) || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={4} isMaximized={isMaximized} classes={classes}>
                  <Facility
                    isMaximized={isMaximized}
                    facility={(clientData && clientData.facility) || {}}
                  />
                </TabPanel>
                <TabPanel value={value} index={5} isMaximized={isMaximized} classes={classes}>
                  <Links
                    isMaximized={isMaximized}
                  />
                </TabPanel>
              </Paper>
            </div>
          </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { layout: { maxedLayout }, client: { selectedClient } } = state;
  return {
    maxedLayout,
    selectedClient
  };
};

const mapDispatchToProps = dispatch => ({
  openDialog: (data) => dispatch(openConfirmDialog(data)),
  closeDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))