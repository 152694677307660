import { store } from "helpers";

//Actions
const SET_CLIENT = 'SET_CLIENT';

export const ClientActions = {
    setClient,
}

function setClient(data) {
    store.dispatch(setData(data));
}

const InitialState = {
    selectedClient: null,
    isSet: false
};

//Actions Creators
export function setData(data) {
    return { type: SET_CLIENT, data };
}

//Reducer
export function clientReducer(state = InitialState, action) {
    switch (action.type) {
        case SET_CLIENT:
            return {
                ...state,
                selectedClient: action.data || null,
                isSet: !!action.data
            };
        default:
            return state
    }
}
