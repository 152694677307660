import axios from "axios"

export const addBankMandate = (product) => {
  var form = document.createElement("form")
  form.enctype = "application/form-url-encoded"
  form.method = "get"
  form.action = process.env.REACT_APP_BANK_MANDATE_URL
  axios.post(`${process.env.REACT_APP_API_URL}/ms-bank-collection/mandate/generatereq`, {
    fallbackUrl: process.env.REACT_APP_TRADING_URL + 'mf',
    product: product || undefined,
  }).then((resp) => {
    if (resp.data.success) {
      var fieldsNValue = [
        { field: "rid", value: resp.data.success },
        // { field: 'fallbackUrl', value: resp.data.success.fallbackUrl || ''},
        // { field: 'clientCode', value: resp.data.success.clientCode || '' }//TODO: Don't send this
      ];
      for (var i of fieldsNValue) {
        var inp = document.createElement('input');
        inp.name = i.field;
        inp.value = i.value;
        inp.type = 'hidden';
        form.appendChild(inp);
      }
      document.body.appendChild(form);
      form.submit();
    } else {
      //handle error
    }})
  .catch((error) => {});
}

export function addMoneyNew(mf){
  var form = document.createElement("form")
  form.enctype = "application/form-url-encoded"
  form.method = "get"
  form.action = process.env.REACT_APP_FUND_TRANSFER_URL

  axios.post(`${process.env.REACT_APP_API_URL}/ms-bank-collection/addmoney/generatereq`, {
    fallbackUrl: mf ? process.env.REACT_APP_TRADING_URL + "mf" : process.env.REACT_APP_TRADING_URL,
    purpose:'TRADING',
  }).then((resp) => {
    if (resp.data.success) {
      var fieldsNValue = [
        { field: "rid", value: resp.data.success },
      ];
      for (var i of fieldsNValue) {
        var inp = document.createElement("input")
        inp.name = i.field
        inp.value = i.value
        inp.type = "hidden"
        form.appendChild(inp);
        console.log('formRedirect forLoop', JSON.stringify(form), inp);
      }
      console.log('formRedirect', form);
      document.body.appendChild(form)
      // localStorage.setItem("payment",JSON.stringify(fieldsNValue));
      form.submit();
    } else {
      //handle error
    }
  })
    .catch((error) => { });
}

export const onAddMoneyClick = (clientCode, mf) => {
  var form = document.createElement("form")
  form.enctype = "application/form-url-encoded"
  form.method = "get"
  form.action = process.env.REACT_APP_FUND_TRANSFER_URL
  var fieldsNValue = [
    { field: "panNo", value: "" },
    { field: "amount", value: "0" },
    { field: "fallbackUrl", value:  mf ? process.env.REACT_APP_TRADING_URL + "mf" : process.env.REACT_APP_TRADING_URL },
    { field: "purpose", value: "TRADING" },
    { field: "clientCode", value: clientCode || "" }
  ]
  for (var i of fieldsNValue) {
    var inp = document.createElement("input")
    inp.name = i.field
    inp.value = i.value
    inp.type = "hidden"
    form.appendChild(inp);
  }
  document.body.appendChild(form)
  // localStorage.setItem("payment",JSON.stringify(fieldsNValue));
  form.submit();
}

export const handleVerifyTPIN = (response) => {
  var form = document.createElement('form');
  form.enctype = 'application/form-url-encoded';
  form.method = 'post';
  form.action = process.env.REACT_APP_VERIFY_TPIN_URL;
  var fieldsValues = [
    { field: 'Version', value: response.Version },
    { field: 'DPId', value: response.DpId },
    { field: 'ReqId', value: response.ReqId },
    { field: 'TransDtls', value: response.TransDtls }
  ];
  for (var i of fieldsValues) {
    var inp = document.createElement('input');
    inp.name = i.field;
    inp.id = i.field;
    inp.value = i.value;
    inp.type = 'hidden';
    form.appendChild(inp);
  }
  document.body.appendChild(form);
  form.submit();
}