export default theme => ({
  root: {
    padding: theme.spacing(4),
    color: `${theme.palette.text.primary}`,
    
  },
  item: {
    height: '100%'
  },
  flexlayout__layout: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden'
  },
  flexlayout__splitter: {
    backgroundColor: theme.palette.border.default, // '#1F2434',
    '&:hover': {
      backgroundColor: '#ddd'
    }
  },
  flexlayout__splitter_drag: {
    borderRadius: 5,
    backgroundColor: '#444',
    zIndex: 1000
  },
  flexlayout__outline_rect: {
    position: 'absolute',
    cursor: 'move',
    border: '2px solid #cfe8ff',
    boxShadow: 'inset 0 0 60px rgba(0, 0, 0, .2)',
    borderRadius: 5,
    zIndex: 1000,
    boxSizing: 'border-box'
  },
  flexlayout__outline_rect_edge: {
    cursor: 'move',
    border: '2px solid #b7d1b5',
    boxShadow: 'inset 0 0 60px rgba(0, 0, 0, .2)',
    borderRadius: 5,
    zIndex: 1000,
    boxSizing: 'border-box'
  },
  flexlayout__edge_rect: {
    position: 'absolute',
    zIndex: 1000,
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, .2)',
    backgroundColor: 'lightgray'
  },
  flexlayout__drag_rect: {
    position: 'absolute',
    cursor: 'move',
    border: '2px solid #aaaaaa',
    boxShado: 'inset 0 0 60px rgba(0, 0, 0, .3)',
    borderRadius: 5,
    zIndex: 1000,
    boxSizing: 'border-box',
    backgroundColor: '#eeeeee',
    opacity: 0.9,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 10,
    wordWrap: 'break-word'
  },
  flexlayout__tabset: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default, //'#292e3e'
    boxSizing: 'border-box'
  },
  flexlayout__tab: {
    overflow: 'hidden',
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default, //'#292e3e',
    color: 'white'
  },
  flexlayout__tab_button: {
    cursor: 'pointer',
    padding: '2px 8px 3px 8px',
    margin: 0,
    float: 'left',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    
  },
  flexlayout__tab_button_selected:{
    color: theme.palette.color4.default,
    backgroundColor: theme.palette.background.default, //'#292e3e',
    
  },
  flexlayout__tab_button_unselected:{
    color: 'gray',
    backgroundColor: theme.palette.color3.default //'#383E4F'
  },
  flexlayout__tab_button_leading: {
    float: 'left',
    display: 'inline-block',
    paddingTop: 2
  },
  flexlayout__tab_button_content: {
    display: 'inline-block',
    padding: 2
  },
  flexlayout__tab_button_textbox: {
    float: 'left',
    border: 'none',
    color: 'lightgreen',
    backgroundColor: '#292e3e',
    '&:focus':{
      outline: 'none',
    }
  },
  flexlayout__tab_button_trailing: {
    float: 'left',
    display: 'inline-block',
    marginRight: 4,
    marginTop: 6,
    width: 8,
    height: 8,
    background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  },
  // flexlayout__tab_button: {
  //   '&:hover':{
  //    '& flexlayout__tab_button_trailing': {
  //       background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //     }
  //   },
  //   '&--selected':{
  //     '& flexlayout__tab_button_trailing': {
  //        background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //      }
  //    }
  // },
  
  flexlayout__tab_button_overflow: {
    float: 'left',
    width: 20,
    height: 15,
    marginTop: 2,
    paddingLeft: 12,
    border: 'none',
    fontSize: 10,
    color: 'lightgray',
    fontFamily: 'Arial, sans-serif',
    background:' transparent url(/images/flexlayout/more.png) no-repeat left'
  },
  flexlayout__tabset_header: {
    position: 'absolute',
    left: 0,
    right: 0,
    color: '#eee',
    backgroundColor: '#212121',
    padding: '3px 3px 3px 5px',
    boxSizing: 'border-box'
  },
  flexlayout__tab_header_inner: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 10000
  },
  flexlayout__tab_header_outer: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  flexlayout__tabset_selected: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
  },
  flexlayout__tabset_maximized: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
  },
  flexlayout__tab_toolbar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0
  },
  flexlayout__tab_toolbar_button_min: {
    width: 20,
    height: 20,
    border: 'none',
    outlineWidth: 0,
    background: 'transparent url(/images/flexlayout/maximize.png) no-repeat center'
  },
  flexlayout__tab_toolbar_button_max: {
    width: 20,
    height: 20,
    border: 'none',
    outlineWidth: 0,
    background: 'transparent url(/images/flexlayout/restore.png) no-repeat center'
  },
  flexlayout__popup_menu: {
  },
  flexlayout__popup_menu_item: {
    padding: '2px 10px 2px 10px',
    color: '#ddd',
    '&:hover':{
      backgroundColor: '#444444'
    }
  },
  flexlayout__popup_menu_container: {
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, .15)',
    border: '1px solid #555',
    background: theme.palette.background.default, //'#292e3e'
    borderRadius: 3,
    position: 'absolute',
    zIndex: 1000
  },
  flexlayout__border_top: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    borderBottom: '8px solid',
    borderBottomColor:`${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  flexlayout__border_bottom: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    borderTop: '8px solid',
    borderTopColor:`${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  flexlayout__border_left: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    borderRight: '8px solid',
    borderRightColor:`${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  flexlayout__border_right: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    borderLeft: '8px solid',
    borderLeftColor:`${theme.palette.border.default}`,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  flexlayout__border_inner_bottom: {
    display: 'flex'
  },
  flexlayout__border_inner_left: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    right: 23,
    transformOrigin: 'top right',
    transform: 'rotate(-90deg)'
  },
  flexlayout__border_inner_right: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    left: 23,
    transformOrigin: 'top left',
    transform: 'rotate(90deg)'
  },
  flexlayout__border_button: {
    backgroundColor: theme.palette.background.default, //'#292e3e'
    color: 'white',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '2px 8px 3px 8px',
    margin: 2,
    verticalAlign: 'top',
    boxSizing: 'border-box',
    
  },
  flexlayout__border_button_selected: {
    color: '#ddd',
    backgroundColor: theme.palette.background.default, //'#292e3e'
   
  },
  flexlayout__border_button_unselected: {
    color: 'gray'
  },
  flexlayout__border_button_leading: {
    float: 'left',
    display: 'inline'
  },
  flexlayout__border_button_content: {
    display: 'inline-block'
  },
  flexlayout__border_button_textbox: {
    float: 'left',
    border: 'none',
    color: 'green',
    backgroundColor: '#ddd',
    '&:focus':{
      outline: 'none'
    }
  },
  flexlayout__border_button_trailing: {
    display: 'inline-block',
    marginLeft: 5,
    marginTop: 3,
    width: 8,
    height: 8,
    background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  },
  // flexlayout__border_button: {
  //   '&:hover':{
  //    '& flexlayout__border_button_trailing': {
  //       background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //     }
  //   },
  //   '&--selected':{
  //     '& flexlayout__border_button_trailing': {
  //        background: 'transparent url(/images/flexlayout/close_white.png) no-repeat center'
  //      }
  //    }
  // },

  flexlayout__border_toolbar_left: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0
  },
  flexlayout__border_toolbar_right: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0
  },
  flexlayout__border_toolbar_top: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0
  },
  flexlayout__border_toolbar_bottom: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0
  }
});
