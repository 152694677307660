import React, { Fragment } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { flexRender } from "@tanstack/react-table";
import { Button } from "@mui/material";
import { DragIndicator } from '@mui/icons-material';

const DraggableRow = (props) => {
  const { classes, row, draggable, highlightedRowIndex, expand, subComponent, columnHover, setColumnHover } = props;

  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.7 : 1
  };

  return (
    row.depth === 0 ? <Fragment key={row.original.id}>
      <tr ref={setNodeRef} style={style} className={classes.row}>
        {
          row.getVisibleCells().map(cell => (
            cell?.column?.id === 'drag' && draggable) ?
            <td  {...attributes} {...listeners} key={cell.id} className={`${classes.cell}  ${cell.column?.columnDef?.className || ''} ${highlightedRowIndex > -1 && highlightedRowIndex === row?.index ? classes.highlighted : ''}`} style={{ ...cell?.column?.columnDef?.cellStyle || {} }}>
              <Button className={classes.dragButton} onMouseEnter={() => columnHover ? setColumnHover(false) : {}}>
                <DragIndicator className={classes.dragIcon} />
              </Button>
            </td>
            :
            !cell.column?.columnDef?.hideColumn &&
            <td key={cell.id}
              className={`${classes.cell} ${cell.column?.columnDef?.className || ''} ${highlightedRowIndex > -1 && highlightedRowIndex === row?.index ? classes.highlighted : ''}`}
              style={{ ...cell?.column?.columnDef?.cellStyle || {}, width: cell.column.getSize() || 100, maxWidth: cell.column.getSize() || 100 }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )
        }
      </tr>
      {(expand && row.getIsExpanded()) ?
        <div className={classes.subComponentRow}>
          {subComponent && subComponent.renderSubComponent(row)}
        </div>
        :
        null}
    </Fragment>
      : null
  );
};

export default DraggableRow
