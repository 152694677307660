import { Box, Button, Divider, Paper, Switch, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { UserThemeContext } from "Main";
import React from "react";
import { connect } from "react-redux";
import { LayoutActions } from "redux/Layout";
import { PrefsService } from "services/prefs";

// Component styles
const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.secondary,
        padding: theme.spacing(2)
    },
    textHeader: {
        // fontSize: 26,
        flex: 0.1,
        color: theme.palette.text.primary,
    },
    itemRoot: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    itemDivider: {
        width: "100%",
        backgroundColor: theme.palette.text.secondary,
        color: theme.palette.text.secondary,
    },
    textStyle: {
        flex: 1,
        color: theme.palette.text.primary,
    },
    textTitle: {
        flex: 0.1,
        color: theme.palette.text.primary,
    },
    textPrimary: {
        color: theme.palette.text.primary,
    },
    resetButton: {
        color: "#FFF",
        fontSize: 12,
        backgroundColor: theme.palette.danger.main
    },
    rowContainer: {
        padding: "5px 0"
    },
    rowContainerInner: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    actionsContainer: {
        flex: .5,
        display: "flex",
        justifyContent: "center",
    },
});

function GeneralSettings(props) {
    const { classes, layoutType, advisoryNotification } = props;

    const isNextAdmin = layoutType === 'nextadmin';

    const toggleAdvisoryNotification = () => {
        PrefsService.setAdvisoryNotification(advisoryNotification == 1 ? 0 : 1);
    }

    const SettingItem = ({ title, children, noDivider }) => {
        return (
            <Box className={classes.rowContainer}>
                <Box className={classes.rowContainerInner}>
                    <Typography className={classes.textStyle}>{title}</Typography>
                    <Box className={classes.actionsContainer}>
                        {children}
                    </Box>
                </Box>
                {!noDivider && <Divider className={classes.itemDivider} />}
            </Box>
        );
    }

    return (
        <Paper className={classes.root}>
            <Typography variant="h3" className={classes.textHeader}>
                {'General Settings'}
            </Typography>
            <Divider width="100%" style={{ marginTop: '15px' }} />
            <SettingItem title="Theme">
                <Box className={classes.itemRoot}>
                    <Typography className={classes.textPrimary}>Light</Typography>
                    <UserThemeContext.Consumer>
                        {({ theme, toggleTheme }) => (
                            <Switch checked={theme == 1} onClick={toggleTheme} />
                        )}
                    </UserThemeContext.Consumer>
                    <Typography className={classes.textPrimary}>Dark</Typography>
                </Box>
            </SettingItem>
            {!isNextAdmin && (
                <SettingItem title="Advisory Notification">
                    <Box className={classes.itemRoot}>
                        <Typography className={classes.textPrimary}>Off</Typography>
                        <Switch
                            checked={advisoryNotification == 1}
                            onClick={toggleAdvisoryNotification}
                        />
                        <Typography className={classes.textPrimary}>On</Typography>
                    </Box>
                </SettingItem>
            )}
            <SettingItem title="Layout" noDivider>
                <Button
                    className={classes.resetButton}
                    variant="contained"
                    size="small"
                    onClick={() => LayoutActions.onResetLayout(layoutType)}>
                    {'Reset'}
                </Button>
            </SettingItem>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    const { layout: { layoutType }, prefs: { advisoryNotification } } = state;
    return { layoutType, advisoryNotification };
};

export default connect(mapStateToProps)(withStyles(styles)(GeneralSettings));