import { Cancel as CancelIcon } from '@mui/icons-material';
import { Box, debounce, Dialog, DialogContent, Grid, List, ListItem, Tab, Tabs, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from "clsx";
import Placeholder from "components/Placeholder";
import SearchBox from "components/Search/SearchBox/index";
import { canCall } from "helpers/pollingHelper";
import { depthSchema, quotesSchema } from "helpers/schemaHelper";
import { getBseScripCode, getSegment, getUniqueKey, hasScripDataChanged, isSameScrip } from "helpers/scripHelper";
import { getSocketPayload } from "helpers/subscription";
import useInterval from "helpers/useInterval";
import { WebsocketClientContext } from "Main";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CommonActions } from 'redux/Common';
import { closeDialog, openDialog } from 'redux/Dialog';
import { WatchListAction } from "redux/Watchlist";
import { WatchListMFActions } from "redux/WatchlistMF";
import { feedService } from 'services/feed';
import { marketDepthService } from "services/marketdepth";
import { SearchService } from "services/search";
import Contracts from "widgets/contracts";
import DetailQuote from 'widgets/detailquote';
import { MODAL_CHILD } from "widgets/FormDialog";
import MarketDepth from "widgets/marketdepth/index";
import FundDetailsPopup from "widgets/MFwidgets/funddetailspopup";
import { TabPanel } from "widgets/Portfolio";
import ScripInfo from 'widgets/scripinfo';
import WLDropdown from "widgets/watchlist/components/sortable/WLDropdown";
import WLDropdownMF from "widgets/watchlistmf/components/WLDropdown";

const styles = theme => ({
    root: {
        // alignItems: "flex-start",
        // top: "50px",
        // position: "absolute",
    },
    dialogPaper: {
        backgroundColor: "transparent",
    },
    dialogContent: {
        backgroundColor: "transparent",
        minWidth: "50vw",
        overflowY: "hidden",
        paddingTop: '20px !important',
        [theme.breakpoints.down('lg')]: {
            minWidth: "60vw",
        },
        [theme.breakpoints.down('md')]: {
            minWidth: "70vw",
        },
    },
    innerContent: {
        backgroundColor: theme.palette.background.WLselectedItem,
        padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
        borderRadius: theme.spacing(1),
    },
    innerContentFixed: {
        width: "56vw",
        [theme.breakpoints.down('lg')]: {
            width: "67.2vw",
        },
        [theme.breakpoints.down('md')]: {
            width: "78.4vw",
        },
    },
    placeholder: {
        backgroundColor: theme.palette.background.WLselectedItem,
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
    list: {
        padding: 0,
        margin: `${theme.spacing(1 / 4)} ${theme.spacing(1 / 2)}`,
        maxHeight: "calc(100vh - 340px)",
        alignContent: "flex-start",
        overflowY: "auto",
    },
    itemContainer: {
        marginTop: theme.spacing(1 / 4),
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1 / 2),
        border: `1px solid ${theme.palette.border.default}`,
        backgroundColor: theme.palette.background.primary,
    },
    itemContainerHighlighted: {
        backgroundColor: theme.palette.background.WLselectedItem,
    },
    scripInfo: {
        display: 'grid',
        alignContent: 'center',
    },
    itemText: {
        color: theme.palette.text.primary,
    },
    sectionRoot: {
        margin: "5px 5px 0 5px",
        border: `1px solid ${theme.palette.color5.default}`,
        borderRadius: theme.spacing(0.75),
        backgroundColor: theme.palette.background.default,
    },
    legend: {
        position: "relative",
        marginLeft: "1vw",
        fontSize: "14px",
        color: theme.palette.text.primary,
    },
    fieldSet: {
        borderRadius: theme.spacing(0.75),
        border: `1px solid ${theme.palette.background.secondary}`,
    },
    closeButton: {
        width: 30,
        height: 30,
        position: "absolute",
        background: theme.palette.background.secondary,
        color: theme.palette.text.primary,
        top: 7,
        right: 7,
        borderRadius: 15,
        cursor: "pointer",
        [theme.breakpoints.down('lg')]: {
            width: 28,
            height: 28,
            top: 9,
            right: 9,
        },
        [theme.breakpoints.down('md')]: {
            width: 24,
            height: 24,
            top: 11,
            right: 11,
        },
    },
    contentText: {
        color: theme.palette.text.secondary,
    }
});

const StyledTabs = withStyles(theme => ({
    root: {
        height: 25,
        minHeight: 'auto',
        backgroundColor: theme.palette.color6.default,
        borderTopLeftRadius: theme.spacing(0.75),
        borderTopRightRadius: theme.spacing(0.75),
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            height: 1,
            alignSelf: 'flex-end',
            maxWidth: '75%',
            width: '100%',
            backgroundColor: '#8DB1D2',
        },
    },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} textColor='inherit' />);

const StyledTab = withStyles(theme => ({
    root: {
        height: 25,
        minHeight: 'auto',
        borderTopLeftRadius: theme.spacing(0.75),
        borderTopRightRadius: theme.spacing(0.75),
        lineHeight: 1.75,
        padding: '6px 12px',
        '&.Mui-selected': {
            color: theme.palette.text.primary,
        },
    },
    selected: {
        backgroundColor: theme.palette.background.default,
    }
}))(Tab);

function SearchDialog(props) {
    const REFRESH_INTERVAL = 1000;// process.env.REACT_APP_REFRESH_FREQUENCY;

    const topOfList = React.createRef();
    const mounted = useRef();

    const [isMF, setIsMF] = useState(props.pathName == 'mf');
    const [searching, setSearching] = useState(false);
    const [searchKey, setSearchKey] = useState();
    const [scripData, setScripData] = useState([]);
    const [searchScrip, setSearchScrip] = useState(null);
    const [selectedScrip, setSelectedScrip] = useState(null);
    const { formdialog, classes, marketStatus } = props;
    const { itemContainer, itemContainerHighlighted, scripInfo, itemText, list } = classes;
    const socket = useContext(WebsocketClientContext);
    const [rows, setRows] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [highlighted, setHighlighted] = useState(0);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        setIsMF(props.pathName == 'mf');
    }, [props.pathName]);

    useEffect(() => {
        return () => {
            setSearching(false);
            setSearchKey();
            setScripData([]);
            setSearchScrip();
            setSelectedScrip();
        }
    }, [formdialog]);

    useEffect(() => {
        if (topOfList?.current) {
            topOfList.current.scrollIntoViewIfNeeded();
        }
    }, [highlighted]);

    const closeDialog = (event, reason) => {
        if (reason != 'backdropClick') {
            CommonActions.setSearchOpen(false);
        }
    };

    async function getScrip() {
        const feedScrips = await feedService.getFullFeed([searchScrip], true);
        if (feedScrips[0]?.error) return;
        let changedData = feedScrips ? feedScrips[0] : null;
        if (changedData && getUniqueKey(searchScrip) == getUniqueKey(changedData))
            setSelectedScrip(changedData);
        CommonActions.setSearchSelectedScrip(changedData)
    }

    async function getDepth(noEmpty) {
        try {
            const depthData = await marketDepthService.getMarketDepth(searchScrip);
            if (depthData.error) {
                setRows(depthData);
                return;
            }
            if (depthData) {
                setRows(depthData)
            } else {
                !noEmpty && setRows([])
            }
        } catch (error) {
            console.log(error)
            return;
        }
    }

    const onQuotes = data => {
        let msg = quotesSchema.decode(data);
        const feedData = msg;
        if (searchScrip.token === feedData.token && searchScrip.shortCode === feedData.shortCode) {
            if (hasScripDataChanged(feedData, searchScrip)) {
                if (mounted.current) {
                    const updatedData = { ...searchScrip, ...feedData };
                    setSelectedScrip(updatedData);
                    CommonActions.setSearchSelectedScrip(updatedData);
                }
            }
        }
    };

    const onDepth = (data) => {
        const msg = depthSchema.decode(data);
        const depthData = data ? msg : {};
        if (isSameScrip(searchScrip, depthData)) {
            mounted.current && setRows(depthData);
        }
    };

    useEffect(() => {
        if (socket.connected) {
            if (searchScrip) {
                // setSelectedScrip(searchScrip);
                // getScrip();
                // !searchScrip.isMarketIndex && getDepth();
                if (canCall(searchScrip.shortCode)) {//Socket
                    //Quotes
                    const payload = getSocketPayload([searchScrip]);
                    socket.emit('feed:subscribe', {
                        request: 'SUBSCRIBE',
                        message: '',
                        channel: 'feed',
                        topic: 'popupQuotes',
                        payload: payload,
                    });
                    socket.on('feed/popupQuotes', onQuotes);
                    //Quotes
                    //Depth
                    if (!searchScrip.isMarketIndex) {
                        socket.emit('feed:subscribe', {
                            request: 'SUBSCRIBE',
                            message: '',
                            channel: 'feed',
                            topic: 'popupDepth',
                            payload: payload,
                        });
                        socket.on('feed/popupDepth', onDepth);
                    }
                    //Depth
                    return () => {
                        // Clean up the subscription
                        socket.emit('feed:unsubscribe', {
                            request: 'UNSUBSCRIBE',
                            message: '',
                            channel: 'feed',
                            topic: 'popupQuotes',
                            payload: payload,
                        });
                        socket.off('feed/popupQuotes', onQuotes);
                        if (!searchScrip.isMarketIndex) {
                            socket.emit('feed:unsubscribe', {
                                request: 'UNSUBSCRIBE',
                                message: '',
                                channel: 'feed',
                                topic: 'popupDepth',
                                payload: payload,
                            });
                            socket.off('feed/popupDepth', onDepth);
                        }
                    };
                }//Socket
            }
        }
    }, [searchScrip, socket.connected, marketStatus]);

    useEffect(() => {
        if (searchScrip && !selectedScrip) {
            setSelectedScrip(searchScrip);
            getScrip();
            !searchScrip.isMarketIndex && getDepth();
        }
    }, [searchScrip]);

    useInterval(() => {
        if (socket.disconnected) {
            if (searchScrip && canCall(searchScrip.shortCode)) {
                getScrip();
                !selectedScrip.isMarketIndex && getDepth(true);
            }
        }
    }, REFRESH_INTERVAL);

    const onKeyPress = (event) => {
        if (scripData?.length > 0) {
            const lastIndex = scripData?.length - 1;
            const { target: { value }, keyCode } = event;
            switch (keyCode) {
                case 40:
                    setHighlighted(highlighted < lastIndex ? highlighted + 1 : 0);
                    break;
                case 38:
                    setHighlighted(highlighted > 0 ? highlighted - 1 : lastIndex);
                    break;
                case 13:
                    openHighlighted();
                    break;
                default:
                    break;
            }
        }
    }

    const openHighlighted = () => {
        if (scripData?.length > 0) {
            const item = scripData[highlighted];
            setSearchScrip(item);
            if (!isMF) {
                CommonActions.setSearchSelectedScrip(item);
            }
        }
    }

    const getData = (key) => {
        setSearchKey(key);
        setSelectedScrip(null);
        setSearchScrip(null);
        setTabValue(0);
        setHighlighted(0);
        if (key && (isMF && key.length > 2 || !isMF && key.length > 1)) {//TODO: 2-> 1
            if (isMF) {
                getSearchScheme(key);
            } else {
                getSearchScrip(key);
            }
        } else if (key == '') {
            setScripData([]);
        }
    }

    async function getSearchScrip(key) {
        const data = await SearchService.getScripSearch(key);
        setSearching(false);
        setScripData(data);
    }

    async function getSearchScheme(key) {
        const data = await SearchService.getSchemeSearch(key);
        setSearching(false);
        setScripData(data);
    }

    const debounceGetData = useCallback(debounce(getData, 1000), []);

    function getListItem({ item, index }) {
        const {
            exchange,
            scripName,
            symbol,
            bseScripCode,
        } = item;

        const setRef = highlighted === index ? topOfList : null;

        return (
            <ListItem autoFocus={true} key={index} className={[itemContainer, highlighted === index && itemContainerHighlighted]} onClick={() => {
                setSearchScrip(item);
                CommonActions.setSearchSelectedScrip(item)
            }}
                ref={setRef}
            >
                <Grid container>
                    <Grid item xs={10} className={scripInfo}>
                        <Typography
                            variant="subtitle1"
                            className={itemText}>
                            {scripName || symbol}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={itemText}>
                            {exchange} {getSegment(item)} {symbol} {getBseScripCode(item)}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    function getListItemMF({ item, index }) {
        const { schemeName } = item;

        const setRef = highlighted === index ? topOfList : null;

        return (
            <ListItem key={index} className={[itemContainer, highlighted === index && itemContainerHighlighted]} onClick={() => {
                setSearchScrip(item);
                if (!isMF) {
                    CommonActions.setSearchSelectedScrip(item)
                }
            }}
                ref={setRef}
            >
                <Grid container>
                    <Grid item xs={10} className={scripInfo}>
                        <Typography
                            variant="subtitle1"
                            className={itemText}>
                            {schemeName}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={itemText}>
                            {'MF'}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    const getPlaceholder = () => {
        return (
            (searching || (searchKey && scripData?.length <= 0)) &&
            <div className={classes.placeholder}>
                <Placeholder
                    loading={searching}
                    loadingMsg={`Searching ${isMF ? 'Schemes' : 'Scrips'}`}
                    error={'No Data found'} />
            </div>
        )
    }

    const getListView = () => {
        return (
            <>
                {
                    !searching && scripData && scripData.length > 0
                        ? <List dense className={list}>
                            {scripData.map((item, index) => {
                                return !isMF ? getListItem({ item, index }) : getListItemMF({ item, index });
                            })}
                        </List>
                        : getPlaceholder()
                }
            </>
        )
    }

    const handleWatchlistChange = e => {
        const { target: { value } } = e;
        const { scripId, symbol, shortCode, segment, scripName, token, isMarketIndex, exchange, bseScripCode } = selectedScrip;
        let data = [{
            scripId,
            symbol: segment == "EQ" ? symbol : scripName,
            shortCode,
            watchListId: value,
            token: isMarketIndex ? scripId : exchange === 'BSE' ? bseScripCode || token : token,
        }]
        let payload = {
            data,
            watchListId: value,
            selectedListType: 'client'
        }
        WatchListAction.addScrip(payload);
    }

    const handleWatchlistChangeMF = e => {
        const { target: { value } } = e;
        const { id, schemeBseMap, schemeCode, shortCode } = selectedScrip;
        let schemeID = schemeBseMap ? schemeBseMap.schemeID : id ? id : ''

        let data = [{
            schemeId: schemeID,
            schemeCode,
            shortCode: shortCode || 'BMS',
            watchListId: value,
        }]
        let payload = {
            data,
            watchListId: value,
            selectedListType: 'client'
        }
        WatchListMFActions.addScrip(payload);
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const onTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const getScripView = () => {
        let isComm = selectedScrip && selectedScrip.segment === "COMM";
        return (
            <Box>
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingTop: "5px",
                    paddingRight: "5px",
                }}>
                    <Typography variant="h6" className={classes.itemText}
                        style={{ marginRight: "15px" }}>
                        Add to Watchlist
                    </Typography>
                    <WLDropdown noDefault={true} onChange={handleWatchlistChange} hideGlobal={true} hideHolding />
                </Box>
                <Box className={classes.sectionRoot} style={{ maxHeight: "150px" }}>
                    <ScripInfo propScrip={selectedScrip} />
                </Box>
                <Box
                    style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                    <fieldset className={classes.sectionRoot} style={{ flex: 1 }}>
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <StyledTabs
                                value={tabValue}
                                onChange={onTabChange}
                                aria-label="full width tabs example">
                                <StyledTab key={0} label={'ScripInfo'} {...a11yProps(0)} />
                                {isComm && <StyledTab key={1} label={'Contracts'} {...a11yProps(1)} />}
                            </StyledTabs>
                            <TabPanel value={tabValue} index={0} style={{ flex: 1 }}>
                                <DetailQuote propScrip={selectedScrip} />
                            </TabPanel>
                            {isComm && <TabPanel value={tabValue} index={1} style={{ flex: 1 }}>
                                <Contracts propScrip={selectedScrip} />
                            </TabPanel>}
                        </div>
                    </fieldset>
                    <fieldset className={classes.sectionRoot} style={{ flex: 1 }}>
                        <StyledTabs
                            value={0}
                            aria-label="full width tabs example">
                            <StyledTab key={0} label={'Market Depth'} {...a11yProps(0)} />
                        </StyledTabs>
                        <TabPanel value={0} index={0} style={{ flex: 1, height: '100%' }}>
                            <MarketDepth rows={rows} isIndex={selectedScrip.isMarketIndex} depthError={rows?.error || ''} />
                        </TabPanel>
                    </fieldset>
                </Box>
            </Box>
        );
    }

    const getSchemeView = () => {
        const { isin, isinNumber, schemeCode } = selectedScrip;

        return (
            <div>
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingTop: "5px",
                    paddingRight: "5px",
                }}>
                    <Typography variant="h6" className={classes.itemText}
                        style={{ marginRight: "15px" }}>
                        Add to Watchlist
                    </Typography>
                    <WLDropdownMF noDefault={true} onChange={handleWatchlistChangeMF} hideGlobal={true} />
                </Box>
                <div style={{ padding: '6px 10px 0px' }}>
                    <FundDetailsPopup propData={{
                        isinNumber: isinNumber || isin,
                        schemeCode
                    }} />
                </div>
            </div>
        )
    }

    const getContent = () => {
        return (
            <DialogContent className={classes.dialogContent}>
                <div className={clsx({
                    [classes.innerContent]: classes.innerContent,
                    [classes.innerContentFixed]: !isMF,
                })}>
                    <SearchBox
                        onChange={e => {
                            let type = typeof e;
                            if (type === 'string') {
                                setSearching(e && e.length > 2);
                                debounceGetData(e)
                            }
                        }}
                        keyPressEvent={onKeyPress} />
                    {!selectedScrip ? getListView() : isMF ? getSchemeView() : getScripView()}
                </div>
                <CancelIcon onClick={closeDialog} className={classes.closeButton} />
            </DialogContent>
        )
    }

    return (
        <div>
            <Dialog
                classes={{ paper: classes.dialogPaper }}
                PaperProps={{ style: { alignSelf: "baseline", marginTop: "10vh" } }}
                aria-labelledby="form-dialog-title"
                maxWidth={'lg'}
                onClose={closeDialog}
                open={formdialog}
            >
                {getContent()}
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    const { common: { searchOpen, pathName, marketStatus } } = state;
    return { formdialog: searchOpen, pathName, marketStatus };
};

const mapDispatchToProps = dispatch => ({
    openDialog: () => dispatch(openDialog(MODAL_CHILD.ORDER)),
    closeDialog: () => dispatch(closeDialog())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SearchDialog));
