import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import { IconButton, Snackbar, SnackbarContent, Typography } from "@mui/material";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { AlertActions } from "redux/Alert";
import styles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
  const classes = styles();
  const {
    className,
    /* message, */
    onClose,
    variant,
    alert: { message, sType, vertical, horizontal },
    ...other } = props;
  const Icon = variantIcon[variant];
  // debugger

  const handleClose = () => {
    // alert(message);
    onClose && onClose();
    AlertActions.clear();
  }
  if (message) {
    // alert(message);
  }

  // debugger
  return (
    Boolean(message) && <Snackbar
      anchorOrigin={{
        vertical: vertical || 'top',
        horizontal: horizontal || 'center',
      }}
      open={Boolean(message)}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(sType ? classes[sType] : classes['info'], className)}
        aria-describedby="client-snackbar"
        // message={
        //   <span id="client-snackbar" className={classes.message}>
        //     {Icon && <Icon className={clsx(classes.icon, classes.iconVariant)} />}
        //     {message}
        //   </span>
        // }
        message={<Typography className={classes.message}>{message}</Typography>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );
}


// {(responseStatus && responseStatus.message) && <Snackbar
//   anchorOrigin={{
//     vertical: 'top',
//     horizontal: 'center',
//   }}
//   open={snackBarOpen}
//   autoHideDuration={5000}
//   onClose={handleClose}
// >
//   <MySnackbarContentWrapper
//     onClose={handleClose}
//     variant={responseStatus.type}
//     message={responseStatus.message}
//   />
// </Snackbar>
// }

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const mapStateToProps = state => {
  const { alert } = state;
  // if (alert.message) {
  //   debugger
  // }
  return {
    alert
  };
}

export default connect(mapStateToProps)(MySnackbarContentWrapper);
