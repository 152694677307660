import { store } from 'helpers';
import axios from 'helpers/interceptor';
import { getSchemePayload } from "helpers/schemeHelper";
import { StorageHelper } from 'helpers/storageHelper';
import { getPayload1 } from 'helpers/subscription';
import { AlertActions } from 'redux/Alert';
import { CommonActions } from 'redux/Common';
import { PrefsActions } from 'redux/Prefs';

export const PrefsService = {
    getAllPrefs,
    //Trading
    setSortOption,
    setCustomSort,
    setGridList,
    setLastviewedwatchlist,
    setColorTheme,
    setAdvisoryNotification,
    setLayoutJson,
    getLayoutJson,
    setOrderView,
    getOrderView,
    setOrderViewPosition,
    getOrderViewPosition,
    //Trading
    //MF
    setLastviewedwatchlistMF,
    setSortOptionMF,
    setCustomSortMF,
    //MF

    setColumnOrderWatchlist,
    setColumnOrderOrderReport,
    setColumnOrderBracketOrderReport,
    setColumnOrderTradeReport,
    setColumnOrderPositionReport,
    setColumnOrderDPSRReport,
    setColumnOrderULMarginReport,
    setColumnOrderUSPositionReport,

    getColumnOrderWatchlist,
    getColumnOrderOrderReport,
    getColumnOrderBracketOrderReport,
    getColumnOrderTradeReport,
    getColumnOrderPositionReport,
    getColumnOrderDPSRReport,
    getColumnOrderULMarginReport,
    getColumnOrderUSPositionReport

    // setColumnOrder,
};

function getAllPrefs(isMF) {
    PrefsActions.onLayoutLoaded({ success: false }, false);

    const userType = store.getState().layout.layoutType;
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/${isMF ? 'mfclient' : userType}/all`
    };
    axios(options)
        .then((response) => {
            // debugger
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        // console.log(response.data.success)
                        PrefsActions.onPrefsUpdated(response.data.success, isMF);
                        const {
                            sortOption,
                            gridList,
                            customSort,
                            colorTheme,
                            selectedWatchListId,
                            selectedWatchList: { id, type },
                            orderView,
                            orderViewPosition,
                            layoutJson,
                            isWebResearchNotification,
                        } = response.data.success;
                        StorageHelper.compareSub();
                        colorTheme != -1 && StorageHelper.setColorTheme(colorTheme);
                        gridList != -1 && StorageHelper.setGridList(gridList);
                        StorageHelper.setCustomSort(selectedWatchListId, customSort);
                        // StorageHelper.setLayoutJson(layoutJson, true);
                        PrefsActions.onLayoutLoaded({ layoutJson, success: true }, isMF);
                        if (orderView != -1) {
                            StorageHelper.setOrderView(orderView);
                            CommonActions.setOrderFormView(orderView);
                        }
                        if (isMF) {
                            StorageHelper.setSortOptionMF(sortOption);
                            // StorageHelper.setLayoutJson(layoutJson, true);
                            if (id && type) {
                                StorageHelper.setSelectedListMF(`${id}_${type}`);
                            }
                        } else {
                            StorageHelper.setOrderViewPosition(orderViewPosition);
                            StorageHelper.setSortOption(sortOption);
                            // StorageHelper.setCustomSort(selectedWatchListId, customSort);
                            // StorageHelper.setLayoutJson(layoutJson, true);
                            if (id && type) {
                                StorageHelper.setSelectedList(`${id}_${type}`);
                            }
                        }
                        StorageHelper.setAdvisoryNotification(isWebResearchNotification);
                        return;
                    }
                }
            }
            AlertActions.error('Unable to fetch preferences');
            console.log(response);
        })
        .catch((error) => {
            AlertActions.error('Unable to fetch preferences');
            console.log('getAllPrefs', error);
        });
}

function setSortOption({ id, type, direction }) {
    direction = direction ? 1 : 0;
    StorageHelper.setSortOption({ id, type, direction });
    StorageHelper.setCustomSort(id, []);
    PrefsActions.onSortUpdated({ sortOption: { type, direction }, customSort: [] });
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/sort`,
        data: {
            sortOption: {
                id,
                type,
                direction
            }
        }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setSorting success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setSortOptionMF({ id, type, direction }) {
    direction = direction ? 1 : 0;
    StorageHelper.setSortOptionMF({ id, type, direction });
    StorageHelper.setCustomSort(id, []);
    PrefsActions.onSortUpdatedMF({ sortOption: { type, direction }, customSort: [] });
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/mf/sort`,
        data: {
            sortOption: {
                id,
                type,
                direction
            }
        }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setSorting success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setCustomSort(id, custom) {
    let customSeq;
    customSeq = custom.map((scrip) => {
        return getPayload1(scrip);
    });
    StorageHelper.setCustomSort(id, customSeq);
    StorageHelper.setSortOption({ id, type: -1, direction: -1 });
    PrefsActions.onSortUpdated({ sortOption: { type: -1, direction: -1 }, customSort: customSeq });
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/sort`,
        data: { id, list: customSeq }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        // setSortOption({ id, type: -1, direction: -1 })
                        console.log('setCustomSort success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setCustomSortMF(id, custom) {
    let customSeq;
    customSeq = custom.map((scrip) => {
        return getSchemePayload(scrip);
    });
    StorageHelper.setCustomSort(id, customSeq);
    StorageHelper.setSortOptionMF({ id, type: -1, direction: -1 });
    PrefsActions.onSortUpdatedMF({ sortOption: { type: -1, direction: -1 }, customSort: customSeq });
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/mf/sort`,
        data: { id, list: customSeq }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        // setSortOption({ id, type: -1, direction: -1 })
                        console.log('setCustomSort success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setGridList(type) {
    type = type == 'table' ? 0 : 1;
    StorageHelper.setGridList(type);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/grid`,
        data: { type }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setGridList success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setLastviewedwatchlist({ id, type }) {
    PrefsActions.onSelectedListUpdated({ id, type });
    StorageHelper.setSelectedList(`${id}_${type}`);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/lastviewedwatchlist`,
        data: { id, type }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setLastviewedwatchlist success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setLastviewedwatchlistMF({ id, type }) {
    PrefsActions.onSelectedListUpdated({ id, type });
    StorageHelper.setSelectedListMF(`${id}_${type}`);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/mf/lastviewedwatchlist`,
        data: { id, type }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setLastviewedwatchlist success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setColorTheme(theme) {
    PrefsActions.onThemeUpdated(theme);
    // theme = theme == 'light' ? 0 : 1;
    StorageHelper.setColorTheme(theme);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/colortheme`,
        data: { type: theme }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setColorTheme success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setAdvisoryNotification(notification) {
    PrefsActions.onAdvisoryUpdated(notification);
    StorageHelper.setAdvisoryNotification(notification);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/research/notification`,
        data: { isWebResearchNotification: notification }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setAdvisoryNotification success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setLayoutJson(json, type, reload) {
    const pathName = store.getState().common.pathName;
    StorageHelper.setLayoutJson(json);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/layout`,
        data: {
            type: pathName == 'mf' ? 'mfClient' : type == 'admin' ? type : 'client',
            json
        }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setLayoutJson success');
                        if (reload) {
                            window.location.reload();
                        }
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function getLayoutJson(type) {
    type = type == 'admin' ? 'admin' : 'client';
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/type/${type}`
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        if (response.data.success.layoutJson) {
                            StorageHelper.setLayoutJson(
                                response.data.success.layoutJson,
                                true
                            );
                            console.log(
                                'getLayoutJson success',
                                response.data.success.layoutJson
                            );
                        }
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function setOrderView(type) {
    CommonActions.setOrderFormView(type);
    StorageHelper.setOrderView(type);
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/orderview`,
        data: { type }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setOrderView success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function getOrderView() {
    return StorageHelper.getOrderView();
}

function setOrderViewPosition({ x, y }) {
    StorageHelper.setOrderViewPosition({ x, y });
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/orderviewposition`,
        data: { x, y }
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log('setOrderViewPosition success');
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function getOrderViewPosition() {
    return StorageHelper.getOrderViewPosition();
}

function setColumnOrderWatchlist(order) {
    setColumnOrder({ table: 'watchlist', order });
}
function setColumnOrderOrderReport(order) {
    setColumnOrder({ table: 'orderreport', order });
}
function setColumnOrderBracketOrderReport(order) {
    setColumnOrder({ table: 'bracketorderreport', order });
}
function setColumnOrderTradeReport(order) {
    setColumnOrder({ table: 'tradereport', order });
}
function setColumnOrderPositionReport(order) {
    setColumnOrder({ table: 'positionreport', order });
}
function setColumnOrderDPSRReport(order) {
    setColumnOrder({ table: 'holdingsreport', order });
}
function setColumnOrderULMarginReport(order) {
    setColumnOrder({ table: 'marginreport', order });
}
function setColumnOrderUSPositionReport(order) {
    setColumnOrder({ table: 'presettlementreport', order });
}

function setColumnOrder({ table, order }) {
    StorageHelper.saveColumnOrder(table, order);

    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/report/${table}`,
        data: order
    };
    axios(options)
        .then((response) => {
            if (response) {
                if (response.data) {
                    if (response.data.success) {
                        console.log(`setColumnOrder ${table} success`);
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

function getColumnOrderWatchlist() {
    return getColumnOrder({ table: 'watchlist' }).then((order) => {
        return order;
    });
}

function getColumnOrderOrderReport() {
    return getColumnOrder({ table: 'orderreport' }).then((order) => {
        return order;
    });
}

function getColumnOrderBracketOrderReport() {
    return getColumnOrder({ table: 'bracketorderreport' }).then((order) => {
        return order;
    });
}

function getColumnOrderTradeReport() {
    return getColumnOrder({ table: 'tradereport' }).then((order) => {
        return order;
    });
}
function getColumnOrderPositionReport() {
    return getColumnOrder({ table: 'positionreport' }).then((order) => {
        return order;
    });
}
function getColumnOrderDPSRReport() {
    return getColumnOrder({ table: 'holdingsreport' }).then((order) => {
        return order;
    });
}
function getColumnOrderULMarginReport() {
    return getColumnOrder({ table: 'marginreport' }).then((order) => {
        return order;
    });
}
function getColumnOrderUSPositionReport() {
    return getColumnOrder({ table: 'presettlementreport' }).then((order) => {
        return order;
    });
}

function getColumnOrder({ table, order }) {
    // debugger
    let storedOrder = StorageHelper.getColumnOrder(table, order);

    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customizationacc/customview/getreport/${table}`,
        data: order
    };
    return axios(options)
        .then((response) => {
            // debugger
            if (response) {
                if (response.data) {
                    if (response.data.success && response.data.success.length > 0) {
                        console.log(`getColumnOrder ${table} success`);
                        return response.data.success;
                    }
                }
            }
            return storedOrder;
        })
        .catch((error) => {
            // debugger
            console.log(error);
            return storedOrder;
        });
}
