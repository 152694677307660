import {
    Box,
    Button,
    debounce,
    IconButton,
    MenuItem,
    Typography
} from '@mui/material';
import { ArrowBack, Search, Sort } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import commonStyles from 'common/styles';
import combineStyles from 'common/styles/combineStyles';
import SearchBox from 'components/Search/SearchBox';
import SelectBox from 'components/Select';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DialogActions } from 'redux/Dialog';
import FilterSelection from './FilterSelection';

const styles = (theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 5
    },
    searchBox: {
        // flex: 1,
        width: '100%',
        margin: 5
    },
    searchBoxMax: {
        // flex: 1,
        width: 'auto',
        margin: 5
    },
    selectBox: {
        width: '100%',
        maxWidth: 'none',
        margin: 5
    },
    selectBoxMax: {
        width: 'auto'
        // maxWidth: 'none',
        // margin: 5
    },
    custom: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.secondary,
        borderRadius: '50vh',
        width: '100%',
        fontSize: 12,
        padding: '1vh',
        margin: 5
    },
    customMax: {
        width: 'auto'
    },
    hide: {
        visibility: 'hidden',
        opacity: 0
    },
    iconSelected: {
        backgroundColor: theme.palette.background.secondary
    },
    iconBtnNeutral: {
        color: theme.palette.text.primary
    }
});

const customIndex = 4;

const selectItems = [
    // { value: 0, key: 'TopRatedIncomeFunds', label: 'Top Rated Income Funds' },
    { value: 0, key: 'Equity', label: 'Top Equity Funds' },
    { value: 1, key: 'AggressiveFunds', label: 'Top Aggressive Funds' },
    { value: 2, key: 'BalancedFunds', label: 'Top Balanced Funds' },
    { value: 3, key: 'TaxSavings', label: 'Top Tax Saving Funds' },
    { value: 4, key: `${customIndex}`, label: 'Custom' }
];

function ActionBar(props) {
    const {
        classes,
        searchString: propSearchString,
        onSearchTextChanged,
        onSearchSelected,
        onClearSearch,
        onChangeDropdown,
        showFilters,
        setShowFilters,
        filterSelection,
        onFilterChange,
        isMaxed,
        hideSelect,
        isCustom,
        defaultData,
        topFunds,
        setTopFunds,
    } = props;

    const [ddItems, setDdItems] = useState([]);
    const [dropdown, setDropdown] = useState(0);
    const [searchOpen, setSearchOpen] = useState(hideSelect);
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState(
        (propSearchString && propSearchString.value) || null
    );
    const [searchSubmitted, setSearchSubmitted] = useState(false);

    useEffect(() => {
        if (!propSearchString) {
            setSearchString();
            setSearchOpen(hideSelect || false);
        }
    }, [propSearchString]);

    useEffect(() => {
        if (defaultData) {
            let items = defaultData['categories'];
            if (items) {
                items = items
                    .map((item, index) => {
                        const { category } = item;
                        if (category.toLowerCase().indexOf('custom') != -1) return null;
                        return { ...item, value: index, key: category };
                    })
                    .filter((x) => x != null);
                setDdItems(items || []);
            }
        }
    }, [defaultData]);

    const onChangeSelect = (e) => {
        const {
            target: { value }
        } = e;
        onChangeSelection(value);
    };

    const onChangeSelection = (value) => {
        setDropdown(value);
        let selectedItem = ddItems[parseInt(value)];
        if (selectedItem) {
            onChangeDropdown && onChangeDropdown(selectedItem.key);
        }
    };

    const onSearchSubmit = (value) => {
        setSearchSubmitted(true);
        if (onSearchSelected) {
            onSearchSelected({ value, label: value });
        }
    };

    const searchTextChanged = (e) => {
        onSearchTextChanged && onSearchTextChanged(e);
    };

    const debounceSearch = useCallback(debounce(searchTextChanged, 1000), []);

    const getSelectItems = () => {
        return ddItems.map((item, index) => {
            const { category, title } = item;
            return (
                <MenuItem key={category} value={index}>{`Top ${title} Funds`}</MenuItem>
            );
        });
    };

    return (
        <Box className={classes.container}>
            {searchOpen && !hideSelect && (
                <Button
                    style={{ height: 'auto', marginLeft: 5 }}
                    className={`${classes.iconBtn} ${classes.iconBtnNeutral}`}
                    onClick={() => {
                        setSearchOpen(false);
                        setSearchString();
                        onClearSearch && onClearSearch();
                    }}
                // disabled={!isMaxed}
                >
                    <ArrowBack />
                </Button>
            )}
            {!searchOpen && !hideSelect && !isCustom && (
                <SelectBox
                    style={{ width: '100%' }}
                    className={clsx({
                        [classes.selectBox]: classes.selectBox,
                        [classes.selectBoxMax]: isMaxed
                    })}
                    value={dropdown}
                    onChange={onChangeSelect}>
                    {getSelectItems()}
                </SelectBox>
            )}
            {!searchOpen && isCustom && (
                <Typography
                    className={clsx({
                        [classes.custom]: classes.custom,
                        [classes.customMax]: isMaxed
                    })}>
                    {'Custom'}
                </Typography>
            )}
            {searchOpen && (
                <SearchBox
                    placeholder="Type & Press Enter"
                    // style={{ flexGrow: 1 }}
                    propClassName={isMaxed ? classes.searchBoxMax : classes.searchBox}
                    onChange={(e) => {
                        let type = typeof e;
                        if (type === 'string' && e.trim().length > 2) {
                            setSearchString(e);
                            setSearching(e && e.length > 2);
                            debounceSearch(e);
                        }
                    }}
                    onSubmit={onSearchSubmit}
                    isEnabled={!isMaxed}
                    value={searchString}
                />
            )}
            {!searchOpen && (
                <IconButton
                    onClick={() => {
                        // setSearchOpen(true);
                        if (!isMaxed) {
                            setSearchOpen(true);
                        } else {
                            DialogActions.openFundExplorer();
                        }
                    }}
                    className={clsx(classes.iconBtn /* , classes.iconBtnBlue */)}>
                    <Search />
                </IconButton>
            )}
            <IconButton
                onClick={() => {
                    setShowFilters && setShowFilters(!showFilters, searchString);
                    // if (!isMaxed) {
                    //     setShowFilters && setShowFilters(!showFilters, searchString);
                    // } else {
                    //     DialogActions.openFundExplorer();
                    // }
                }}
                className={clsx(classes.iconBtn, {
                    [classes.iconBtnBlue]: showFilters && !isMaxed
                })}>
                <Sort />
            </IconButton>
            {isMaxed && (
                <FilterSelection
                    selection={filterSelection}
                    onFilterChange={onFilterChange}
                    showClear={true}
                    topFunds={topFunds}
                    setTopFunds={setTopFunds}
                />
            )}
        </Box>
    );
}

const mapStateToProps = (state) => {
    const { fundExplorer } = state;
    return { ...fundExplorer };
};

export default connect(mapStateToProps)(
    withStyles(combineStyles(styles, commonStyles))(ActionBar)
);
