import React from 'react';
import styles from './styles';
import { withStyles } from '@mui/styles';
import { Dialog, DialogContent, LinearProgress, DialogTitle, Typography, Grid } from "@mui/material";

function SplitPopup(props) {
  const { classes, onClose, processSplitOrder } = props;
  const { completed, total, show } = processSplitOrder || {};

  return (
    <Dialog open={show} maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.secondaryText}>Split Order</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.contentGrid}>
          <LinearProgress variant="determinate" classes={{ barColorPrimary: classes.barColorPrimary }} className={classes.progressBar} value={(completed / total) * 100} />
          <Typography className={classes.count}>{completed} of {total} orders processed</Typography>
          {/* <Typography className={classes.count}>Processing all orders will take approximately {total} seconds in accordance with exchange regulations. </Typography> */}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(SplitPopup);