// Material helpers
import { withStyles } from '@mui/styles';
import { Select } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const SelectBox = props => {
    const {
        data,
        children,
        classes,
        className,
        value,
        onChange,
        style,
        selectionText,
        addText,
        component,
        dark,
        ...rest
    } = props;
    const { root, rootDark } = classes;

    const rootClassName = classNames(root, className, dark ? rootDark : null);

    return (
        <Select
            displayEmpty
            disableUnderline
            className={rootClassName}
            value={value}
            onChange={onChange}
        // IconComponent={() => (<KeyboardArrowDown />)}
        // {...props}
        >
            {children}
            {/* {selectionText && <MenuItem value="">{selectionText}</MenuItem>}
            {data && data.map(x => component ? <component /> : <MenuItem value={x.id}>{x.name}</MenuItem>)}
            {addText && <MenuItem value="new"><a href="#">{addText}</a></MenuItem>} */}
        </Select>
    );
};

SelectBox.propTypes = {
    data: PropTypes.array,
    selectionText: PropTypes.string,
    addText: PropTypes.string,
};

SelectBox.defaultProps = {
    data: [],
    selectionText: null,
    addText: null,
};

export default withStyles(styles)(SelectBox);
