export default (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  contentText: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'break-spaces'
  }
});