export default ({ breakpoints }) => ({
  styleOverrides: {
    root: {
      color: 'inherit',
      [breakpoints.down('xl')]: {
        width: '20px',
        height: '20px'
      },
      [breakpoints.down('md')]: {
        width: '18px',
        height: '18px'
      }
    }
  }
});
