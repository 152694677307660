import { Close as CloseIcon, DeleteOutline as DeleteIcon, KeyboardArrowDown as Arrow } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, OutlinedInput, Select, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import { useFormik } from "formik";
import { currentBrowser } from "helpers/envHelper";
import axios from "helpers/interceptor";
import SvgComponent from "helpers/svgcomponent";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import styles from "./styles";

const api = {
  submit: "/ms-trading-feedback/feedback/submit",
  module: "/ms-trading-feedback/feedback/getmodule",
  subModule: "/ms-trading-feedback/feedback/getsubmodule"
}

const CssOutlinedInput = withStyles(theme => ({
  root: {
    width: "100%",
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$error $notchedOutline": {
      borderWidth: 1,
      borderColor: "#f54040"
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {
    borderColor: "#66788a4d"
  }
}))(OutlinedInput)

const CssSelect = withStyles(theme => ({
  root: {
    padding: "8px 0px 8px 7px",
    [theme.breakpoints.down('xl')]: {
      padding: "4px 0px 4px 6px"
    },
    [theme.breakpoints.down('xl')]: {
      padding: "3px 0px 3px 6px"
    }
  },
  select: {
    paddingRight: "0px !important",
    paddingLeft: 8
  },
  outlined: {}
}))(Select)

const SelectOutlinedInput = withStyles(theme => ({
  root: {
    fontSize: "14px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
    "& $notchedOutline": {
      borderWidth: 0
    },
    "&:hover $notchedOutline": {
      borderWidth: 0
    },
    "&$focused $notchedOutline": {
      borderWidth: 0
    }
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {}
}))(OutlinedInput)

let emojis = [
  {
    type: "VeryBadEmoji",
    selected: "VeryBadEmojiSelected",
    label: "Very Bad!",
    value: 0
  },
  {
    type: "BadEmoji",
    selected: "BadEmojiSelected",
    label: "Bad!",
    value: 1
  },
  {
    type: "OkOkEmoji",
    selected: "OkOkEmojiSelected",
    label: "Ok Ok!",
    value: 2
  },
  {
    type: "GoodEmoji",
    selected: "GoodEmojiSelected",
    label: "Good!",
    value: 3
  },
  {
    type: "AmazingEmoji",
    selected: "AmazingEmojiSelected",
    label: "Amazing!",
    value: 4
  }
]

function Help(props) {
  const { classes, onCancel, isPopup } = props

  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));

  const upload = React.useRef(null);

  const [activeStep, setActiveStep] = useState(0)
  const [categoryData, setCategoryData] = useState("Loading...")
  const [subCategoryData, setSubCategoryData] = useState("Loading...")
  const [errorObj, setErrorObj] = useState({ error: false, message: "" })
  const [confirmPopup, setConfirmPopup] = useState(false)

  const { handleSubmit, handleChange, handleReset, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
    initialValues: {
      rating: 3,
      category: "",
      subCategory: "",
      note: "",
      image: ""
    },
    validationSchema: Yup.object().shape({
      rating: Yup.mixed()
        .oneOf([0, 1, 2, 3, 4]),
      category: Yup.string()
        .required("Module required"),
      subCategory: Yup.string()
        .required("Sub Module required"),
      note: Yup.string()
        .min(3, 'Minimum 3 characters required')
        .max(450, 'Maximum characters limit exceeded')
        .matches(/^[a-zA-Z .,&0-9-]+$/, 'Invalid input')
        .required("Note is required"),
      image: Yup.mixed(),
      imageSize: Yup.string()
        .test("fileSize", "Screenshot is too large", (value) => {
          if (value) {
            return Number(value) < 10000000
          } else {
            return true
          }
        }),
      imageName: Yup.string(),
      imageFormat: Yup.string()
    }),
    onSubmit: values => {
      submitFeedback()
    }
  })

  function submitFeedback() {
    setErrorObj({ error: false, message: "", submitting: true })
    let base64 = (values.image && values.image.split(",").length === 2 && values.image.split(",")[1]) || ""
    let browser = currentBrowser(window);
    let payload = {
      rating: values.rating,
      module: values.category,
      subModule: values.subCategory,
      remarks: values.note,
      base64Format: base64 || "",
      fileName: values.imageName || "",
      fileFormat: values.imageFormat || "",
      browser: browser.name || "",
      mobileos: ""
    }
    axios.post(`${process.env.REACT_APP_API_URL}${api.submit}`, payload).then(resp => {
      if (resp.data.success) {
        setErrorObj({ error: false, message: "", submitting: false })
        if (isPopup) {
          setActiveStep(1)
        } else {
          setConfirmPopup(true)
        }
      } else {
        setErrorObj({ error: true, message: resp.data.error || "Error", submitting: false })
      }
    }).catch(error => {
      setErrorObj({ error: true, message: error.message || "There is some technical issue, Please try again later", submitting: false })
    })
  }

  function initializeNoteFields() {
    setFieldValue("category", "Trading")
    getSubModules("Trading")
  }

  function getSubModules(module) {
    axios.get(`${process.env.REACT_APP_API_URL}${api.subModule}/${module}`).then(resp => {
      if (resp.data.success && resp.data.success.length > 0) {
        setSubCategoryData(resp.data.success)
        setFieldValue("subCategory", resp.data.success[0])
      } else {
        setSubCategoryData([])
        setErrorObj({ error: true, message: resp.data.error || "SubModule Data Not Found", submitting: false })
      }
    }).catch(error => {
      setSubCategoryData([])
      setErrorObj({ error: true, message: error.message || "SubModule: There is some technical issue, Please try again later", submitting: false })
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}${api.module}`).then(resp => {
      if (resp.data.success && resp.data.success.length > 0) {
        setCategoryData(resp.data.success)
        initializeNoteFields()
      } else {
        setCategoryData(resp.data.error || "Module Data Not Found")
      }
    }).catch(error => {
      setCategoryData(error.message || "There is some technical issue, Please try again later")
    })
  }, [])

  function onModuleChange(e) {
    setFieldValue("category", e.target.value)
    setSubCategoryData("Loading...")
    getSubModules(e.target.value)
  }

  const RatingGrid = () => {
    return (
      <div>
        <Typography variant="h4" className={classes.blueText}>How satisfied are you ?</Typography>
        <div className={classes.emojiDiv}>
          {
            emojis.map((ele) => (
              <div className={classes.centerContent} style={{ flexDirection: "column" }}>
                <SvgComponent iconname={ele.value === values.rating ? ele.selected : ele.type} className={classes.svg} onClick={() => setFieldValue("rating", ele.value)} />
                <Typography align="center" variant="subtitle1" className={classes.defaultText}>{ele.label}</Typography>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  const NoteGrid = () => {
    return (
      <div>
        <Typography variant="h4" className={[classes.blueText, "note"].join(" ")}>Add a Note</Typography>
        <Grid container>
          <Grid item xs={6} style={{ marginBottom: "6px", paddingRight: "3px" }}>
            <FormControl variant="outlined" className={classes.fieldDiv}>
              <CssSelect
                labelId="demo-simple-select-filled-label"
                id="category"
                name="category"
                value={values.category}
                onChange={onModuleChange}
                error={touched.category && errors.category ? true : false}
                onKeyUp={() => setFieldTouched("category", true)}
                className={classes.select}
                input={<SelectOutlinedInput />}
                variant="outlined"
                IconComponent={() => <Arrow />}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              >
                {
                  categoryData && categoryData.length > 0 && categoryData.map((ele) => (
                    <MenuItem key={ele} value={ele} className={classes.menuItem}>{ele}</MenuItem>
                  ))
                }
              </CssSelect>
              {errors.category && touched.category && (<p className={classes.errorText}>{errors.category}</p>)}
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginBottom: "6px", paddingLeft: "3px" }}>
            <FormControl variant="outlined" className={classes.fieldDiv}>
              <CssSelect
                labelId="demo-simple-select-filled-label"
                id="subCategory"
                name="subCategory"
                value={values.subCategory}
                onChange={handleChange}
                error={touched.subCategory && errors.subCategory ? true : false}
                onKeyUp={() => setFieldTouched("subCategory", true)}
                className={classes.select}
                disabled={subCategoryData === "Loading..."}
                input={<SelectOutlinedInput />}
                variant="outlined"
                IconComponent={() => (
                  subCategoryData === "Loading..." ? <CircularProgress size={20} style={{ marginRight: "6px", color: "#7d7d7d" }} /> : <Arrow />
                )}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              >
                {
                  subCategoryData && typeof (subCategoryData) !== "string" && subCategoryData.length > 0 && subCategoryData.map((ele) => (
                    <MenuItem key={ele} value={ele} className={classes.menuItem}>{ele}</MenuItem>
                  ))
                }
              </CssSelect>
              {errors.subCategory && touched.subCategory && (<p className={classes.errorText}>{errors.subCategory}</p>)}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.fieldDiv}>
              <CssOutlinedInput
                id="note"
                name="note"
                multiline={true}
                rows={matchesSm ? 7 : matchesMd ? 8 : matchesLg ? 7 : 11}
                inputProps={{ className: classes.input }}
                style={{ padding: 0 }}
                onChange={handleChange}
                error={touched.note && errors.note ? true : false}
                value={values.note}
                onKeyUp={() => setFieldTouched("note", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={classes.centerContent} style={{ justifyContent: "space-between" }}>
                {errors.note && touched.note && (<p className={classes.errorText}>{errors.note}</p>)}
                <Typography align="right" variant="caption" className={classes.defaultText}>Maximum 450 characters only</Typography>
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }

  function readImage(input) {
    setFieldTouched("image", true)
    setFieldTouched("imageSize", true)
    setFieldValue("image", "Loading")
    let file = {
      content: input.target.files[0],
      name: input.target.files[0].name.split(".")[0],
      format: input.target.files[0].name.split(".")[1],
      size: input.target.files[0].size
    }
    let reader = new FileReader();
    reader.readAsDataURL(file.content);
    reader.onload = function (readBytes) {
      setFieldValue("image", readBytes.currentTarget.result)
      setFieldValue("imageName", file.name)
      setFieldValue("imageSize", file.size)
      setFieldValue("imageFormat", file.format)
    };
  }

  function removeImage() {
    setFieldValue("image", "")
    setFieldValue("imageName", "")
    setFieldValue("imageSize", "")
    setFieldValue("imageFormat", "")
    setFieldTouched("imageSize", false)
  }

  const ImageGrid = () => {
    return (
      <div style={{ height: "100%" }}>
        <Typography variant="h4" className={classes.blueText}>Attach Screenshot</Typography>
        <div className={classes.uploadDiv}>
          {
            values.image && values.image !== "Loading" ?
              <>
                <div>
                  <div className={props.classes.deleteMainDiv}>
                    <div onClick={removeImage} className={classes.deleteButtonDiv}>
                      <DeleteIcon className={classes.deleteIcon} />
                    </div>
                  </div>
                  <div className={classes.imageBorder}>
                    <img src={values.image} alt={values.imageName} className={classes.image} />
                  </div>
                </div>
                <Typography style={{ marginTop: "4px" }} align="right" variant="caption" className={classes.defaultText}>File: {values.imageName}.{values.imageFormat}, Size: {Number(values.imageSize / 100000).toFixed(2)} MB</Typography>
              </>
              :
              <>
                <div className={classes.uploadWrapper} onClick={() => upload.current.click()} style={{ display: 'flex' }}>
                  <input ref={upload} accept={".jpeg, .png"} type={"file"} style={{ display: "none" }} onChange={(e) => readImage(e)} />
                  <div className={classes.centerContent} style={{ width: "100%" }}>
                    {
                      values.image === "Loading" ?
                        <CircularProgress size={24} style={{ marginRight: "12px", color: "#7d7d7d" }} />
                        :
                        <SvgComponent iconname={"Pdf"} style={{ marginRight: "12px" }} />
                    }
                    <Typography align="center" variant="h6" className={classes.primaryText}>{values.image === "Loading" ? "Uploading" : "Drop here or Browse"}</Typography>
                  </div>
                </div>
                <Typography style={{ marginTop: "4px" }} align="center" variant="caption" className={classes.defaultText}>PNG or JPEG less than 10 MB</Typography>
              </>
          }
          {errors.imageSize && touched.imageSize ? <p className={classes.errorText}>{errors.imageSize}</p> :
            errors.image && touched.image && <p className={classes.errorText}>{errors.image}</p>}
        </div>
      </div>
    )
  }

  const form = () => {
    return (
      <form autoComplete={"off"} onSubmit={handleSubmit}>
        {errorObj && errorObj.error && <div className={classes.errorDiv}><Typography>{errorObj.message}</Typography></div>}
        <Grid container>
          <Grid item xs={isPopup ? 6 : 12}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ margin: isPopup ? "0px 4px 4px 0px" : "0px 0px 4px 0px" }} className={classes.grid}>
                  {RatingGrid()}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ margin: isPopup ? "4px 4px 0px 0px" : "4px 0px 0px 0px" }} className={classes.grid}>
                  {NoteGrid()}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isPopup ? 6 : 12}>
            <div style={{ margin: isPopup ? "0px 0px 0px 4px" : "0px", height: "100%" }} className={classes.grid}>
              {ImageGrid()}
            </div>
          </Grid>
          <Grid item xs={12} style={isPopup ? {} : { padding: "0px 12px 0px 10px" }}>
            <Grid container style={{ justifyContent: isPopup ? "flex-end" : "center" }}>
              <Grid item md={isPopup ? 2 : 3} sm={isPopup ? 2 : 3} xs={isPopup ? 3 : 4} style={{ marginRight: "8px" }}>
                <Button disabled={errorObj && errorObj.submitting} onClick={() => { handleReset(); initializeNoteFields() }} className={classes.cancel}>Reset</Button>
              </Grid>
              <Grid item md={isPopup ? 2 : 3} sm={isPopup ? 2 : 3} xs={isPopup ? 3 : 4}>
                <Button disabled={errorObj && errorObj.submitting} type="submit" className={classes.order}>{errorObj && errorObj.submitting && <CircularProgress size={20} style={{ marginRight: "12px", color: "#7d7d7d" }} />}Submit</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form >
    )
  }

  const Confirm = () => {
    return (
      <div className={[classes.centerContent, isPopup ? classes.divHeight : ""].join(" ")} style={isPopup ? { flexDirection: "column" } : { height: "100%", flexDirection: "column" }}>
        <SvgComponent iconname={"ThumbsUp"} className={classes.image1} />
        <div className={classes.lastTextDiv}>
          <Typography align="center" variant="h4" style={{ marginBottom: 0 }} className={classes.blueText}>Thank You!</Typography>
          <Typography align="center" variant="h5" style={{ marginTop: "4px" }} className={classes.defaultText}>for your valuable Feedback</Typography>
        </div>
        <Button variant="contained" className={classes.closeButton} onClick={isPopup ? onCancel : onConfirmPopupClose}>Close</Button>
      </div>
    )
  }

  const onConfirmPopupClose = (event, reason) => {
    if (reason != 'backdropClick') {
      setConfirmPopup(false);
      handleReset();
      initializeNoteFields();
    }
  }

  return (
    <div className={isPopup ? classes.root : classes.root1}>
      <div className={isPopup ? "" : classes.minWidthRoot} style={isPopup ? { width: "100%", height: "100%" } : { height: "100%" }}>
        {
          activeStep === 0 ?
            typeof (categoryData) === "string" ?
              <div style={isPopup ? {} : { height: "100%" }} className={[classes.centerContent, isPopup ? classes.divHeight : ""].join(" ")}>
                <Placeholder
                  loading={categoryData === "Loading..." ? true : false}
                  loadingMsg={""}
                  error={categoryData === "Loading..." ? "" : categoryData}
                />
              </div>
              :
              form()
            : <Confirm />
        }
        {
          <Dialog open={confirmPopup} maxWidth={""} onClose={onConfirmPopupClose}>
            <DialogTitle className={classes.header}>
              <Grid container>
                <Grid item lg={11} md={11} sm={11} xs={11} className={classes.headerGrid}>
                  <Typography className={classes.title}>{"Message"}</Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1} >
                  <Tooltip title="Close" aria-label="close">
                    <IconButton aria-label="close" onClick={onConfirmPopupClose} className={classes.closeIcon}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={[classes.root, classes.divHeight].join(" ")} style={{ margin: 0 }}>
              {Confirm()}
            </DialogContent>
          </Dialog >
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { dialog: { data } } = state
  return { isPopup: (data && data.popup) || false }
}

export default connect(mapStateToProps)(withStyles(styles)(Help))
