import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup";
import { connect } from "react-redux";
import axios from "helpers/interceptor";
import { Button, CircularProgress, FormControl, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import Top from "./top"
import styles from "./styles"
import { CommonActions } from "redux/Common";
import { closeConfirmDialog, openConfirmDialog } from "redux/ConfirmDialog";
import { closeDialog } from "redux/Dialog";
import { feedService } from "services/feed";
import { CssOutlinedInput } from "./customfields"

let productTypeArray = [];

function ConvertPosition(props) {

  const { classes, data, closeConfirmDialog, openConfirmDialog, onCancel } = props

  const [loading, setLoading] = useState({ load: true, error: "Loading..." })
  const [scrip, setScrip] = useState({})

  Yup.addMethod(Yup.number, "lotTest", function (args) {
    const message = args;
    return this.test("lotTest", message, function (value) {
      const { path, createError, options, parent } = this;
      if (options && options.originalValue) {
        if (String(options.originalValue).includes(".")) {
          return createError({ path: path, message: "Decimal is not allowed" })
        } else if (data && data.form && (data.form.segment === "CURR" || data.form.segment === "FNO" || data.form.segment === "COMM")) {
          if (Number(options.originalValue) > Number(parent.conversionQuantity) / scrip.marketLot) {
            return createError({ path: path, message: `Lot size should not be greater than ${Number(parent.conversionQuantity) / scrip.marketLot}` })
          } else {
            return true
          }
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "quantityTest", function (args) {
    const message = args;
    return this.test("quantityTest", message, function (value) {
      const { path, createError, options, parent } = this;
      if (options && options.originalValue) {
        if (String(options.originalValue).includes(".")) {
          return createError({ path: path, message: "Decimal is not allowed" })
        } else {
          if (data && data.form && (data.form.segment === "CURR" || data.form.segment === "FNO" || data.form.segment === "COMM")) {
            if (Number(options.originalValue) % Number(scrip.marketLot) !== 0) {
              return createError({ path: path, message: `Quantity must be multiple of ${scrip.marketLot}` })
            }
            if (Number(options.originalValue) % Number(scrip.marketLot) === 0 && Number(options.originalValue) > parent.conversionQuantity) {
              return createError({ path: path, message: `Quantity should not be greater than ${parent.conversionQuantity}` })
            }
            return true
          } else {
            if (Number(options.originalValue) > Number(parent.conversionQuantity)) {
              return createError({ path: path, message: `Quantity should not be greater than ${parent.conversionQuantity}` })
            }
            return true
          }
        }
      }
      return true
    })
  })

  let schema = Yup.object().shape({
    lot: Yup.number().integer("Decimal is not allowed").typeError("Invalid number")
      .min(1, "Minimum lot is 1")
      .required("Lot is required")
      .lotTest(),
    quantity: Yup.number().integer("Decimal is not allowed").typeError("Invalid number")
      .min(1, "Quantity should be greater than Zero")
      .max(10000000, "Quantity should be less than 10000000")
      .required("Quantity is required")
      .quantityTest()
  })

  useEffect(() => {
    feedService.getFullFeed([data.form]).then(feedScrips => {
      if (feedScrips && feedScrips.length && feedScrips[0].error) {
        setLoading({ load: true, error: (feedScrips[0].error) || "Scrip data not found" })
      } else if (feedScrips && feedScrips.length > 0) {
        setLoading({ load: false, error: "" })
        let scripData = { ...feedScrips[0] }
        setScrip(scripData || {})
        if (scripData.segment === "CURR" || scripData.segment === "FNO" || (scripData.segment === "COMM" && scripData.exchange === "NCDEX")) {
          setFieldValue("quantity", Math.abs(data.form.netPosition))
          setFieldValue("conversionLot", Math.abs(data.form.netPosition) / scripData.marketLot)
          setFieldValue("conversionQuantity", Math.abs(data.form.netPosition))
          setFieldValue("lot", Math.abs(data.form.netPosition) / scripData.marketLot)
        }
        if ((scripData.segment === "COMM") && (scripData.exchange === "MCX")) {
          setFieldValue("quantity", Math.abs(data.form.netPosition) * scripData.marketLot)
          setFieldValue("lot", Math.abs(data.form.netPosition))
          setFieldValue("conversionLot", Math.abs(data.form.netPosition))
          setFieldValue("conversionQuantity", Math.abs(data.form.netPosition) * scripData.marketLot)
        }
        if (data?.form?.productType) {
          if (data.form.productType === "Delivery") {
            productTypeArray = ["Intraday", "MTF"]
          } else if (data.form.productType === "Intraday") {
            productTypeArray = ["Delivery", "MTF"]
          } else {
            productTypeArray = ["Intraday", "Delivery"]
          }
          setFieldValue("productType", (productTypeArray[0] === "Intraday" && !scripData.isTBApproved) ? (productTypeArray[1] === "MTF" && !scripData.isMTFApproved) ? "" : productTypeArray[1] : productTypeArray[0])
        }
      }
    }).catch(error => {
      setLoading({ load: true, error: "There is some technical issue, please try again later" });
    })
  }, [])

  const oddLotPopup = (newValue) => {
    closeConfirmDialog()
    if (newValue === 'cancel') {
      convertPosition()
    }
  }

  const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
    initialValues: {
      lot: 1,
      quantity: Math.abs(data?.form?.netPosition || 0) || 1,
      conversionLot: 1,
      conversionQuantity: Math.abs(data?.form?.netPosition || 0) || 1,
      productType: ""
    },
    validationSchema: schema,
    onSubmit: values => {
      if (!values.productType) {
        setLoading({ showError: true, error: "Conversion Type is required", submitting: false })
      } else if (values.productType === "Delivery" && scrip.marketType === "O") {
        let obj = {
          content: "You will not be able to square off this order on the same day as it is illiquid/trade-to-trade category stock.\n Do you wish to continue ?",
          okButtonText: "No",
          cancelButtonText: "Yes",
          onClose: oddLotPopup
        }
        openConfirmDialog(obj)
      } else if (values.productType === "Intraday" && scrip.marketType === "O") {
        let obj = {
          content: "Intraday orders are blocked for this scrip. Place a delivery order instead.",
          hideCloseButton: true,
          okButtonText: "Ok",
          centerActions: true,
          onClose: oddLotPopup
        }
        openConfirmDialog(obj)
      } else {
        convertPosition()
      }
    }
  })

  function convertPosition() {
    setLoading({ submitting: true, load: false, error: "" })
    let payload = {
      scripid: (data && data.form && data.form.scripId) || "",
      convertPosQty: data && data.form && data.form.segment === "COMM" ? values.lot : values.quantity,
      avaliableQty: (data && data.form && data.form.netPosition && Math.abs(data.form.netPosition)) || 1,
      convertPositionTo: values.productType,
      convertPositionFrom: (data && data.form && data.form.productType) || "",
      segment: (data && data.form && data.form.segment && (data.form.segment).toUpperCase()) || "",
      exchange: (data && data.form && data.form.exchange && (data.form.exchange).toUpperCase()) || "",
      tbMarginOrderID: (data && data.form && data.form.orderId) || "0"
    }
    if (`${process.env.REACT_APP_USER}` !== "client") {
      payload = {
        ...payload,
        clientCode: (data && data.form && data.form.clientCode) || ""
      }
    }
    axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-margincalc/trading/convertpos`, payload).then(resp => {
      if (resp.data.success) {
        CommonActions.setRefreshReport("positionreport");
        setLoading({ showError: false, load: false, error: "", submitting: false })
        setScrip({})
        onCancel()
        let obj = {
          content: resp.data.success || "--",
          hideCloseButton: true,
          okButtonText: "Ok",
          centerActions: true,
          onClose: closeConfirmDialog
        }
        openConfirmDialog(obj)
      }
      else {
        setLoading({ showError: true, error: resp.data.error, submitting: false })
      }
    }).catch(error => {
      setLoading({ showError: true, error: "There is some technical issue, please try again later", submitting: false })
    })
  }

  useEffect(() => {
    if (data && data.form && (data.form.segment === "CURR" || data.form.segment === "FNO" || data.form.segment === "COMM")) {
      if (values.lot && !isNaN(Number(values.lot)) && scrip && scrip.marketLot) {
        if (values.lot === "" || (Number(values.lot) * Number(scrip.marketLot) > values.conversionQuantity)) {
          // setFieldValue("quantity", scrip.marketLot)
        } else {
          setFieldValue("quantity", Number(values.lot) * Number(scrip.marketLot))
        }
      }
    }
  }, [values.lot])

  useEffect(() => {
    if (data && data.form && (data.form.segment === "CURR" || data.form.segment === "FNO" || data.form.segment === "COMM")) {
      if (values.quantity && Number(values.quantity) % Number(scrip.marketLot) === 0 && !isNaN(Number(values.quantity))) {
        if (values.quantity === "" || (Number(values.quantity) / Number(scrip.marketLot) > (Number(values.conversionQuantity) / Number(scrip.marketLot)))) {
        } else {
          setFieldValue("lot", Number(values.quantity) / Number(scrip.marketLot))
        }
      }
    }
  }, [values.quantity])

  const positionType = () => {
    if (data && data.form) {
      if (data.form.netPosition > 0)
        return 'Long'
      else if (data.form.netPosition < 0)
        return 'Short'
      else if (data.form.netPosition == 0)
        return 'Squared Off'
    }
  }

  const formFields = () => {
    return (
      <Grid container style={{ justifyContent: "space-around" }}>
        <Grid item xs={12} className={classes.buttonDiv}>
          <Typography variant="h5" className={classes.typeHeading}>From {data && data.form && data.form.productType} to {/* ${data && data.form && data.form.productType === "Delivery" ? "Intraday" : "Delivery"}${" "}(${positionType()}) : */}</Typography>
          {productToggle()}
        </Grid>
        {
          scrip && scrip.segment && (scrip.segment === "CURR" || scrip.segment === "FNO" || scrip.segment === "COMM") &&
          <Grid item xs={5}>
            <FormControl variant="outlined" className={classes.fieldDiv}>
              <Typography className={classes.convertFieldText}>Conversion Lot</Typography>
              <Typography className={classes.value}>{values && values.conversionLot}</Typography>
            </FormControl>
          </Grid>
        }
        <Grid item xs={5}>
          <FormControl variant="outlined" className={classes.fieldDiv}>
            <Typography className={classes.convertFieldText}>Conversion Quantity</Typography>
            <Typography className={scrip && scrip.segment && (scrip.segment === "CURR" || scrip.segment === "FNO" || scrip.segment === "COMM") ? classes.value : classes.quantityValue}>{values && values.conversionQuantity}</Typography></FormControl>
        </Grid>
        {
          scrip && scrip.segment && (scrip.segment === "CURR" || scrip.segment === "FNO" || scrip.segment === "COMM") &&
          <Grid item xs={5}>
            <FormControl variant="outlined" className={classes.fieldDiv}>
              <Typography className={classes.convertFieldText}>Lot</Typography>
              <CssOutlinedInput
                id="lot"
                name="lot"
                classes={{ input: classes.input }}
                onChange={handleChange}
                error={touched.lot && errors.lot ? true : false}
                value={values.lot}
                onKeyUp={() => setFieldTouched("lot", true)}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              {errors.lot && touched.lot && (<p className={classes.errorText}>{errors.lot}</p>)}
            </FormControl>
          </Grid>
        }
        <Grid item xs={5}>
          <FormControl variant="outlined" className={classes.fieldDiv}>
            <Typography className={classes.convertFieldText}>Quantity</Typography>
            <CssOutlinedInput
              id="quantity"
              name="quantity"
              classes={{ input: classes.input }}
              onChange={handleChange}
              error={touched.quantity && errors.quantity ? true : false}
              value={values.quantity}
              onKeyUp={() => setFieldTouched("quantity", true)}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
            {errors.quantity && touched.quantity && (<p className={classes.errorText}>{errors.quantity}</p>)}
          </FormControl>
        </Grid>
      </Grid >
    )
  }

  const productToggle = () => {
    return (
      <ToggleButtonGroup
        value={values.productType}
        exclusive
        onChange={(e) => setFieldValue("productType", e.currentTarget.value)}
        aria-label="text alignment"
      >
        <ToggleButton size="small"
          disabled={productTypeArray[0] === "Intraday" && !scrip.isTBApproved ? true : false}
          value={productTypeArray[0]} className={[classes.button, values.productType === productTypeArray[0] ? "active" : ""].join(" ")}>
          <span className="text">{productTypeArray[0]}</span>
        </ToggleButton>
        <ToggleButton size="small"
          disabled={productTypeArray[1] === "MTF" ?
            (scrip.segment === "EQ" && scrip.exchange === "NSE" && scrip?.isMTFApproved) ? false : true
            :
            (productTypeArray[1] === "Intraday" && !scrip.isTBApproved) ? true : false}
          value={productTypeArray[1]}
          className={[classes.button, values.productType === productTypeArray[1] ? "active" : ""].join(" ")}/*  disabled={productTypeArray[1] === "MTF" ? (scrip.segment === "EQ" && scrip.exchange === "NSE") ? false : true : false} */>
          <span className="text">{productTypeArray[1]}</span>
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }

  const form = () => {
    return (
      <form autoComplete={"off"} onSubmit={handleSubmit}>
        <Grid container>
          {loading && loading.showError && <div className={classes.errorDiv} style={{ width: '100%' }}><Typography className={classes.errorDivText}>{loading.error || '--'}</Typography></div>}
          <Grid item xs={12}>
            <Top reduxForm={{}} selectedScrip={scrip || {}} activeStep={0} compactView={false} values={{}} showExchange={false} />
          </Grid>
          <Grid item xs={12} className={classes.convertFormFrid}>
            {formFields()}
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container className={classes.buttonGrid}>
                  <Grid item lg={9} sm={10}>
                    <Button onClick={onCancel} className={classes.cancel}>Cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container className={classes.buttonGrid}>
                  <Grid item lg={9} sm={10}>
                    <Button type="submit" disabled={loading && loading.submitting ? true : false} className={classes.convertButton} autoFocus={true}>{loading && loading.submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Convert</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <div className={classes.convertForm}>
      {
        loading.load ?
          <div className={classes.convertLoadingDiv}>
            <Placeholder
              loading={loading.error === "Loading..." ? true : false}
              loadingMsg={""}
              error={loading.error === "Loading..." ? "" : loading.error}
            />
          </div>
          :
          form()
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return { data: state.dialog.data }
};

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(closeDialog()),
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConvertPosition))