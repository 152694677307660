import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import { UserProfileContext } from "Main";
import React, { useEffect, useState } from "react";

const styles = theme => ({
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: "0px",
    height: "25px",
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: "#497CAC",
    fontWeight: 600
  },
  content: {
    backgroundColor: theme.palette.color17.default,
    marginTop: "25px",
    minHeight: '10vh',
    minWidth: '20vw',
  },
  headerGrid: {
    display: "flex",
    alignItems: "center",
    height: "25px"
  },
  closeIcon: {
    float: "right",
    height: "25px",
    marginRight: theme.spacing(1),
    padding: 0
  },
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    border: "0px"
  },
  noClient: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "120px",
    width: "300px"
  }
})

function AddMoney(props) {
  const { classes, open, onClose } = props

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <UserProfileContext.Consumer>
      {userProfile => (
        <Dialog open={open} onClose={(event, reason) => reason != 'backdropClick' && onClose && onClose()} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
          <DialogTitle id="form-dialog-title" className={classes.header}>
            <Grid container>
              <Grid item lg={11} md={11} sm={11} xs={11} className={classes.headerGrid}>
                <Typography className={classes.title}>{"Add Money"}</Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1} >
                <Tooltip title="Close" aria-label="close">
                  <IconButton aria-label="close" onClick={() => onClose()} className={classes.closeIcon}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          {userProfile && userProfile.clientCode ?
            <DialogContent className={classes.content} style={{ background: "#343a40", width: "1200px", height: "600px" }}>
              {
                loading ?
                  <Placeholder
                    loading={true}
                    loadingMsg={""}
                    error={""}
                  />
                  :
                  // <iframe
                  //   src={`${process.env.REACT_APP_FUND_TRANSFER_URL}?clientCode=${userProfile.clientCode}&amount=0&fallbackUrl=""&purpose=TRADING`}
                  //   className={classes.container}>
                  // </iframe>
                  <embed
                    src={`${process.env.REACT_APP_FUND_TRANSFER_URL}?clientCode=${userProfile.clientCode}&amount=0&fallbackUrl=""&purpose=TRADING`}
                    className={classes.container}
                    type="text/html">
                  </embed>
              }
            </DialogContent>
            :
            <DialogContent className={classes.content}>
              <div className={classes.noClient}>
                <Placeholder
                  loading={false}
                  loadingMsg={""}
                  error={"Client Code not found"}
                />
              </div>
            </DialogContent>
          }
        </Dialog>

      )
      }
    </UserProfileContext.Consumer >
  )
}

export default withStyles(styles)(AddMoney)