import { store } from "helpers";

//Actions
const SET_SHOW_GRID = 'SET_SHOW_GRID';

export const AddGridActions = {
  setNewGrid
}

const InitialState = {
  addNewGrid: false,
  gridData: {}
};

function setNewGrid(child, data) {
  store.dispatch(setShowGrid(data));
}

//Actions Creators
export function setShowGrid(data) {
  return { type: SET_SHOW_GRID, data };
}

//Reducer
export function gridReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_SHOW_GRID:
      return {
        ...state,
        addNewGrid: !state.addNewGrid,
        gridData: action.data
      };
    default:
      return state
  }
}
