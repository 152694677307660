import { withStyles } from '@mui/styles';
import {
    List,
    ListItem,
    Paper,
    Typography,
} from '@mui/material';
import React from 'react';

const styles = (theme) => ({
    listItem: {
        padding: '1px 8px',
        cursor: 'pointer'
    },
    itemContainer: {
        width: '100%',
        borderRadius: 2,
        padding: '4px 8px'
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: 14
        // whiteSpace: 'nowrap'
    },
    subTitle: {
        color: theme.palette.text.secondary,
        fontSize: 12
    }
});

function SearchResultsPanel(props) {
    const { classes, data, onSearchSelected } = props;
    return (
        <List>
            {data.map((result, index) => {
                return (
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            onSearchSelected && onSearchSelected({ ...result, oldValue: result.value, value: result.label });
                        }}>
                        <Paper className={classes.itemContainer}>
                            <Typography className={classes.title}>{result.label}</Typography>
                            <Typography className={classes.subTitle}>
                                {result.type || 'MF'}
                            </Typography>
                        </Paper>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default withStyles(styles)(SearchResultsPanel);
