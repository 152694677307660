import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// import { Notifications, Password } from './components';
import General from "./components/General";

// Component styles
const styles = theme => ({
  root: {
    width: "30vw",
    padding: theme.spacing(4)
  },
  itemRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textTitle: {
    flex: 0.1,
    color: theme.palette.text.primary,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
});

class Settings extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid item xs={12} className={classes.itemRoot}>
            <General />
          </Grid>
          {/* <Grid
            item
            md={7}
            xs={12}
          >
            <Notifications />
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
          >
            <Password />
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
