import { Step, StepConnector, Stepper, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Event } from "components/Event";
import axios from "helpers/interceptor";
import { UserProfileContext } from "Main";
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import Description from "./description";
import EnabledTotp from "./enabledTotp";
import SendOtp from "./sendOtp";
import SendTotp from "./sendTotp";
import styles from './styles';
import VerifyTotp from "./verifyTotp";

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 0,
    left: 'calc(-50%)',
    right: 'calc(50%)',
  },
  active: {
    '& $line': {
      borderColor: '#FF3B1C',
      backgroundColor: '#FF3B1C'
    },
  },
  completed: {
    '& $line': {
      borderColor: '#FF3B1C',
      backgroundColor: '#FF3B1C'
    },
  },
  line: {
    borderColor: theme.palette.background.default,
    borderTopWidth: 5,
    borderRadius: 0
  },
}))(StepConnector)

const steps = [1, 2, 3, 4, 5];

function Totp(props) {
  const { classes, onCancel, enableTotp } = props;
  const [loading, setLoading] = useState({})
  const [activeStep, setActiveStep] = useState(1)
  const [data, setData] = useState({})
  const profile = useContext(UserProfileContext)
  const otpSuccessEvent = new Event("OTP_SUCCESS");

  function handleStep(step) {
    setActiveStep(step)
  }

  useEffect(() => {
    if (enableTotp === 'menu') {
      setActiveStep(2)
    }
  }, [])

  function sendOtp(load) {
    /* load &&  */setLoading({ step: 2, submit: true })
    axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-authservice/sendOtp`, { product: "TOTPReg" }).then(resp => {
      if (resp.data.success) {
        setActiveStep(3)
        setLoading({})
        setData(resp.data.success || {})
        /* !load && otpSuccessEvent.sendMessage(); */
      } else {
        setLoading({ step: 2, error: resp.data?.error?.msg || 'There is some technical issue, please try again later' })
      }
    }).catch(error => {
      setLoading({ step: 2, error: error?.message || 'There is some technical issue, please try again later' })
    })
  }

  function resendOtp() {
    axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-authservice/resendotp`, { otpToken: data.otpToken || '' }).then(response => {
      const { data: { success, error } } = response;
      if (success) {
        otpSuccessEvent.sendMessage();
      } else {
        const { error_description } = error;
        setLoading({ step: 3, error: error_description || error.error || (typeof (error) === 'string' ? error : 'There is some technical issue, please try again later') })
      }
    }).catch((error) => {
      setLoading({ step: 3, error: error?.message || 'There is some technical issue, please try again later' })
    });
  }

  function verifyOtp(otpDetails) {
    setLoading({ step: 3, submit: true })
    axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-authservice/verifyOtpRegistration`, { otp: otpDetails.otp, otpToken: data.otpToken || '' }).then(resp => {
      if (resp.data.success) {
        setActiveStep(4)
        setLoading({})
        setData(resp.data.success || {})
      } else {
        setLoading({ step: 3, error: resp.data?.error?.msg || 'There is some technical issue, please try again later' })
      }
    }).catch(error => {
      setLoading({ step: 3, error: error?.message || 'There is some technical issue, please try again later' })
    })
  }

  function verifyTotp(totp) {
    setLoading({ step: 4, submit: true })
    axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-authservice/validatetotppass`, { totp: totp.otp, password: totp.password }).then(resp => {
      if (resp.data.success) {
        setActiveStep(5)
        setLoading({})
        setData({})
      } else {
        setLoading({ step: 4, error: resp.data?.error?.msg || 'There is some technical issue, please try again later' })
      }
    }).catch(error => {
      setLoading({ step: 4, error: error?.message || 'There is some technical issue, please try again later' })
    })
  }

  function getStepContent(step) {
    switch (step) {
      case 1:
        return <Description
          classes={classes}
          handleStep={handleStep}
        />
      case 2:
        return <SendOtp
          classes={classes}
          sendOtp={sendOtp}
          loading={loading || {}}
        />
      case 3:
        return <SendTotp
          classes={classes}
          resendOtp={resendOtp}
          loading={loading || {}}
          verifyOtp={verifyOtp}
          emailId={profile?.maskEmailId || ''}
          mobileNumber={profile?.maskMobileNumber || ''}
        />
      case 4:
        return <VerifyTotp
          data={data}
          classes={classes}
          onCancel={onCancel}
          verifyTotp={verifyTotp}
          loading={loading || {}}
        />
      case 5:
        return <EnabledTotp
          onCancel={onCancel}
        />
      default:
        return <></>
    }
  }

  return (
    <div className={[classes.root, activeStep === 5 ? 'step5' : ''].join(' ')}>
      <Stepper style={{ padding: 0, display: "none" }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label} />
        ))}
      </Stepper>
      {loading?.step === activeStep && loading?.error && <div className={classes.errorDiv}><Typography className={classes.errorDivText}>{loading.error || '--'}</Typography></div>}
      {getStepContent(activeStep)}
    </div>
  )
}

const mapStateToProps = (state) => {
  return { enableTotp: state.dialog.data || 'profile' }
};

export default connect(mapStateToProps)(withStyles(styles)(Totp));