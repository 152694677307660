export default theme => ({
  tab: {
    color: theme.palette.color15.default,
    borderBottom: `1px solid ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    }
  },
  activeTab: {
    color: "#8DB1D2",
    borderBottom: `1px solid #8DB1D2`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    }
  },
  list: {
    margin: "8px 0px",
    overflowY: "auto"
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.color5.default}`,
    paddingLeft: 0,
    height: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(5)
    }
  },
  text: {
    fontSize: "16px",
    lineHeight: "20px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "14px",
      lineHeight: "18px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "12px",
      lineHeight: "16px"
    }
  },
  value: {
    color: theme.palette.color12.default,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
    // lineHeight: "20px"
  },
  callIcon: {
    width: "80px",
    height: "80px",
    cursor: "pointer",
    [theme.breakpoints.down('xl')]: {
      width: "70px",
      height: "70px",
    },
    [theme.breakpoints.down('md')]: {
      height: '54px',
      width: '54px',
    }
  },
  iconDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  infoBorder: {
    borderLeft: `1px dashed ${theme.palette.color5.default}`
  },
  name: {
    color: "#006CA8",
    fontWeight: 600,
    // fontSize: "22px"
  },
  profileAvatar: {
    height: '85px',
    width: '85px',
    [theme.breakpoints.down('xl')]: {
      height: '60px',
      width: '60px',
    },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
    }
  },
  topPaper: {
    width: "calc(100% - 20px)",
    margin: "18px 10px",
    padding: "8px 0px 8px 12px",
    border: `1px solid ${theme.palette.color14.primary}`,
    background: theme.palette.background.default,
    [theme.breakpoints.down('xl')]: {
      padding: 0
    },
    '&.minView': {
      [theme.breakpoints.down('xl')]: {
        padding: "8px 0px 0px 8px",
      },
      [theme.breakpoints.down('lg')]: {
        padding: "6px 0px 6px 6px"
      },
    }
  },
  bottomDiv: {
    padding: "0px 10px",
    '&.min': {
      height: "calc(100% - 175px)",
      // background: 'red',
      [theme.breakpoints.down('xl')]: {
        height: "calc(100% - 149px)",
      },
      [theme.breakpoints.down('md')]: {
        height: "calc(100% - 131px)",
      },
    }
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "0px 12px 12px 12px",
    border: `1px solid ${theme.palette.color14.primary}`,
    background: theme.palette.background.default
  },
  topGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "20px",
    borderLeft: `1px dashed ${theme.palette.color5.default}`,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: "16px"
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: "12px"
    }
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  minTopGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  infoText: {
    color: theme.palette.color15.default,
    // lineHeight: "20px"
  },
  color1: {
    color: "#FF5520",
    // lineHeight: "20px"
  },
  color2: {
    color: "#254ED2",
    // lineHeight: "20px"
  },
  color3: {
    // lineHeight: "20px",
    color: "#299D00"
  },
  bottomGrid: {
    borderBottom: `1px dashed ${theme.palette.color5.default}`
  },
  color4: {
    color: "#FF549F",
    // lineHeight: "20px"
  },
  color5: {
    color: "#1785C9",
    // lineHeight: "20px"
  },
  color6: {
    color: "#8166F9",
    // lineHeight: "20px"
  },
  icon: {
    width: "32px",
    height: "32px"
  },
  avatar: {
    justifyContent: "flex-start",
    background: "none"
  },
  listIcon: {
    width: "32px",
    height: "32px",
    [theme.breakpoints.down('xl')]: {
      width: "28px",
      height: "28px",
    },
    [theme.breakpoints.down('md')]: {
      width: "28px",
      height: "28px",
    },
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    color: "#9EA0A4",
    fontSize: theme.spacing(2)
  },
  tabPanel: {
    padding: "20px 16px 4px",
    [theme.breakpoints.down('lg')]: {
      padding: "12px 0px",
    },
  },
  heading: {
    // fontSize: theme.spacing(3),
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  tableDiv: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  noDataDiv: {
    height: "75px",
    color: theme.palette,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.color15.default,
    fontSize: theme.spacing(2),
    border: `1px solid ${theme.palette.color5.default}`,
    borderRadius: theme.spacing(0.5)
  },
  detailDiv: {
    border: `1px solid ${theme.palette.color5.default}`,
    borderRadius: theme.spacing(0.5),
    padding: "20px",
    [theme.breakpoints.down('xl')]: {
      padding: "16px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "8px"
    }
  },
  nameDiv: {
    paddingLeft: "2px",
    paddingTop: "4px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    '&.account': {
      marginLeft: "20px"
    }
  },
  firstRow: {
    paddingBottom: '16px',
    [theme.breakpoints.down('xl')]: {
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '8px'
    }
  },
  lastRow: {
    paddingTop: '16px',
    [theme.breakpoints.down('xl')]: {
      paddingTop: '12px'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '8px'
    }
  },
  rowPadding: {
    paddingBottom: '16px',
    paddingTop: '16px',
    [theme.breakpoints.down('xl')]: {
      paddingTop: '12px',
      paddingBottom: '12px'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  },
  textPadding: {
    paddingLeft: '16px',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '12px'
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '8px'
    }
  },
  textPadding1: {
    paddingLeft: '6px',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '4px'
    }
  },
  listItemAvatar: {
    width: 40,
    minWidth: 40,
    [theme.breakpoints.down('xl')]: {
      width: 36,
      minWidth: 36,
    }
  },
  mainGrid: {
    marginLeft: "30px",
    [theme.breakpoints.down('xl')]: {
      marginLeft: '24px'
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: '4px'
    },
    [theme.breakpoints.down('md')]: {
      margin: '12px 4px 12px 0px'
    }
  },
  smGrid: {
    disapley: "flex",
    padding: "8px",
    borderLeft: `1px dashed ${theme.palette.color5.default}`
  },
  box: {
    height: "100%",
    '&.max': {
      height: "calc(100vh - 329px)",
      [theme.breakpoints.down('xl')]: {
        height: "calc(100vh - 288px)",
      },
      [theme.breakpoints.down('md')]: {
        height: "calc(100vh - 265px)",
      },
    },
    '&.min': {
      // height: "calc(100vh - 690px)",
      // [theme.breakpoints.down('xl')]: {
      //   height: "calc(100vh - 650px)",
      // },
      // [theme.breakpoints.down('lg')]: {
      //   height: "calc(100vh - 675px)",
      // },
      // [theme.breakpoints.down('md')]: {
      //   height: "calc(100vh - 640px)",
      // },
    },
  },
  editDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "4px"
  },
  editIcon: {
    width: "20px",
    height: "20px",
    marginLeft: "8px",
    cursor: "pointer",
    [theme.breakpoints.down('xl')]: {
      width: "16px",
      height: "16px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "14px",
      height: "14px",
    },
    [theme.breakpoints.down('md')]: {
      width: "12px",
      height: "12px",
      marginLeft: "4px"
    },
  },
  note: {
    marginTop: "6px",
    color: theme.palette.color15.default,
    [theme.breakpoints.down('md')]: {
      marginTop: "4px"
    }
  },
  spaceContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
  }
})