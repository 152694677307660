import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import TourDialog from 'components/Tour/TourDialog';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { closeDialog, openDialog } from 'redux/Dialog';
import { WatchListAction } from "redux/Watchlist";
import { PrefsService } from "services/prefs";
import Account from 'views/Account';
import SearchFilterDialog from 'views/MutualFund/fundexplorer/SearchFilterDialog';
import Settings from 'views/Settings';
import AddMoney from 'widgets/addMoney';
import Help from 'widgets/help';
import KeyboardShortcuts from 'widgets/keyboardShortcuts';
import ConvertPosition from 'widgets/order/ConvertPosition';
import PortfolioDisclaimer from 'widgets/Portfolio/Segment/Disclaimer';
import ImportPortfolio from 'widgets/Portfolio/Segment/ImportPortfolio';
import OrderHistory from 'widgets/reports/orderreport/components/OrderHistory';
import AddLimit from '../addLimit';
import BulkOrder from '../bulkorder';
import LimitStatement from '../limitStatement';
import FundDetails from '../MFwidgets/funddetailspopup';
import MfOrder from '../MFwidgets/mforder';
import NewsDialog from '../news';
import Order from '../order';
import CancelOrder from '../order/cancel';
import CreateWatchlist from '../watchlist/components/modals/create';
import PayNow from 'views/MutualFund/reports/order/paynow';
import AddMandate from 'widgets/addMandate';
import Totp from 'widgets/totp';
import AdminProfile from 'widgets/adminProfile';
import PriceAlert from 'widgets/priceAlert';
import EDISAuthorization from "widgets/edisAuthorization";
import FnoRiskPopup from 'widgets/fnorisk';
import NomineePopup from 'widgets/nomineepopup';
import BannersPopup from 'widgets/banners';
import { DialogActions as ReduxDialogActions } from 'redux/Dialog';
import CancelSipPopup from 'views/MutualFund/reports/registeredsip/cancelsip';
import getCookie, { setCookieNew } from "helpers/cookies";

const styles = (theme) => ({
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: "0px",
    height: "25px",
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: "#497CAC",
    fontWeight: 600
  },
  content: {
    backgroundColor: theme.palette.color17.default,
    marginTop: "25px",
    minHeight: '10vh',
    minWidth: '20vw',
  },
  headerGrid: {
    display: "flex",
    alignItems: "center",
    height: "25px"
  },
  closeIcon: {
    float: "right",
    height: "25px",
    marginRight: theme.spacing(1),
    padding: 0
  }
})

export const MODAL_CHILD = {
  ORDER: 'order',
  CANCEL_ORDER: 'cancel_order',
  MFORDER: 'mf_order',
  ORDER_HISTORY: 'order_history',
  NEWS: 'news',
  LIMIT_STATEMENT: 'limit_statement',
  SETTINGS: 'settings',
  ACCOUNT: 'account',
  KEYBOARD_SHORTCUTS: 'keyboard_shortcuts',
  ADD_MONEY: 'add_money',
  WATCHLIST: {
    CREATE: 'createWatchlist',
    EDIT: 'editWatchlist'
  },
  FUND_EXPLORER: 'fund_explorer',
  FUND_DETAILS: 'fund_details',
  CONVERT_POSITION: 'convert_position',
  IMPORT_PORTFOLIO: 'import_portfolio',
  BULK_ORDER: 'bulk_order',
  HELP: 'help',
  TOUR_DIALOG: 'tour_dialog',
  ADD_LIMIT: 'add_limit',
  PORTFOLIO_DISCLAIMER: 'portfolio_disclaimer',
  PAY_NOW: 'pay_now',
  ADD_MANDATE: 'add_mandate',
  TOTP: 'totp',
  ADMIN_PROFILE: 'admin_profile',
  PRICE_ALERT: 'price_alert',
  ALERT: 'alert',
  EDIS_AUTHORIZATION: 'edis_authorization',
  FNO_RISK_POPUP: 'fno_risk_popup',
  NOMINEE_POPUP: 'nominee_popup',
  BANNERS_POPUP: 'banners_popup',
  CANCEL_SIP_POPUP: 'cancel_sip_popup'
};

function PaperDraggableComponent(props) {
  return (
    <Draggable bounds="body" defaultPosition={PrefsService.getOrderViewPosition()} onStop={(e, data) => PrefsService.setOrderViewPosition({ x: data.x, y: data.y })} handle="#form-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function PaperComponent(props) {
  return (
    <Paper  {...props} />
  )
}

function FormDialog(props) {
  const { formdialog, closeDialog, modalChild, classes, data } = props;
  const { ORDER, CANCEL_ORDER, MFORDER, ORDER_HISTORY, NEWS, LIMIT_STATEMENT, SETTINGS, ACCOUNT, KEYBOARD_SHORTCUTS, ADD_MONEY, WATCHLIST: { CREATE, EDIT }, FUND_EXPLORER, FUND_DETAILS, CONVERT_POSITION, IMPORT_PORTFOLIO, BULK_ORDER, HELP, TOUR_DIALOG, ADD_LIMIT,
    PORTFOLIO_DISCLAIMER, PAY_NOW, ADD_MANDATE, TOTP, ADMIN_PROFILE, PRICE_ALERT, ALERT, EDIS_AUTHORIZATION, FNO_RISK_POPUP, NOMINEE_POPUP, BANNERS_POPUP, CANCEL_SIP_POPUP } = MODAL_CHILD;
  // if (props.searchOpen && formdialog) CommonActions.setSearchOpen();
  const [Custom, setCustom] = useState('');
  const [meta, setMeta] = useState({});

  const onCreateWatchlist = data => {
    WatchListAction.addList({ watchListName: data.name });
    closeDialog();
  }

  useEffect(() => {
    setModalChild();
  }, [modalChild, data]);

  function setModalChild() {
    let Component = () => <></>,
      dialogTitle, customActions = false, onSubmit, draggable, hideCloseButton, disableEscapeKeyDown, customClose = false;
    if (modalChild) {
      switch (modalChild) {
        case ORDER:
          Component = Order;
          dialogTitle = 'Order';
          customActions = true;
          draggable = true;
          break;
        case CANCEL_ORDER:
          Component = CancelOrder;
          dialogTitle = 'Cancel Order';
          customActions = true;
          draggable = false;
          break;
        case MFORDER:
          Component = MfOrder;
          dialogTitle = 'Order';
          customActions = true;
          draggable = true;
          break;
        case BULK_ORDER:
          Component = BulkOrder;
          dialogTitle = 'Bulk Order';
          customActions = true;
          draggable = true;
          break;
        case ORDER_HISTORY:
          Component = OrderHistory;
          dialogTitle = 'Order History';
          customActions = true;
          break;
        case CREATE:
          Component = CreateWatchlist;
          dialogTitle = 'Create Watchlist';
          customActions = true;
          onSubmit = onCreateWatchlist;
          break;
        case EDIT:
          Component = CreateWatchlist;
          dialogTitle = 'Edit Watchlist';
          customActions = true;
          onSubmit = onCreateWatchlist;
          break;
        case NEWS:
          Component = NewsDialog;
          dialogTitle = 'News';
          customActions = true;
          // onSubmit = onSubmitOrderForm;
          break;
        case LIMIT_STATEMENT:
          Component = LimitStatement;
          dialogTitle = 'Limit Statement';
          customActions = true;
          // onSubmit = onSubmitOrderForm;
          break;
        case SETTINGS:
          Component = Settings;
          dialogTitle = 'Settings';
          // customActions = true;
          // onSubmit = onSubmitOrderForm;
          break;
        case ACCOUNT:
          Component = Account;
          dialogTitle = 'Account';
          customActions = true;
          break;
        case KEYBOARD_SHORTCUTS:
          Component = KeyboardShortcuts;
          dialogTitle = 'Keyboard Shortcuts';
          customActions = true;
          break;
        case ADD_MONEY:
          Component = AddMoney;
          dialogTitle = 'Add Money';
          customActions = true;
          break;
        case FUND_EXPLORER:
          Component = SearchFilterDialog;
          dialogTitle = 'Explore Funds';
          customActions = true;
          break;
        case FUND_DETAILS:
          Component = FundDetails
          dialogTitle = 'Fund Details';
          customActions = true;
          break;
        case CONVERT_POSITION:
          Component = ConvertPosition;
          dialogTitle = 'Convert Order';
          customActions = true;
          break;
        case IMPORT_PORTFOLIO:
          Component = ImportPortfolio;
          dialogTitle = 'Import Portfolio';
          customActions = true;
          break;
        case HELP:
          Component = Help;
          dialogTitle = 'Share your feedback';
          customActions = true;
          break;
        case TOUR_DIALOG:
          Component = TourDialog;
          dialogTitle = 'Tour Guide';
          customActions = true;
          break;
        case ADD_LIMIT:
          Component = AddLimit;
          dialogTitle = 'Client Limit Statement';
          customActions = true;
          break;
        case PORTFOLIO_DISCLAIMER:
          Component = PortfolioDisclaimer;
          dialogTitle = 'Portfolio Disclaimer';
          customActions = true;
          break;
        case PAY_NOW:
          Component = PayNow;
          dialogTitle = 'Pay Now';
          customActions = true;
          break;
        case ADD_MANDATE:
          Component = AddMandate;
          dialogTitle = 'E-Mandate';
          customActions = true;
          break;
        case TOTP:
          Component = Totp;
          dialogTitle = '';
          customActions = true;
          draggable = true;
          break;
        case ADMIN_PROFILE:
          Component = AdminProfile;
          dialogTitle = 'Profile';
          customActions = true;
          break;
        case PRICE_ALERT:
          Component = PriceAlert;
          dialogTitle = 'Price Alert';
          customActions = true;
          break;
        case EDIS_AUTHORIZATION:
          Component = EDISAuthorization;
          dialogTitle = 'EDIS Authorization';
          customActions = true;
          // onSubmit = onSubmitOrderForm;
          break;
        case FNO_RISK_POPUP:
          Component = FnoRiskPopup;
          dialogTitle = 'Risk Disclosure on Derivatives';
          customActions = true;
          hideCloseButton = true;
          disableEscapeKeyDown = true;
          break;
        case NOMINEE_POPUP:
          Component = NomineePopup;
          dialogTitle = 'Nominee Details';
          customActions = true;
          customClose = () => {
            onCancel()
            if (getCookie("showBanner") === "Yes") {
              ReduxDialogActions.openBannersPopup()
            }
          };
          break;
        case BANNERS_POPUP:
          Component = BannersPopup;
          dialogTitle = '';
          customActions = true;
          customClose = () => {
            if (getCookie("showBanner") === "Yes") {
              setCookieNew("showBanner", "No")
            }
            onCancel()
          };
          break;
        case CANCEL_SIP_POPUP:
          Component = CancelSipPopup;
          dialogTitle = 'SIP Cancellation';
          customActions = true;
          break;
        default:
          Component = <div>This is default content</div>
          break;
      }
      setCustom(Component);
      setMeta({
        dialogTitle,
        customActions,
        draggable,
        hideCloseButton,
        disableEscapeKeyDown,
        onSubmit,
        customClose
      });
    }
  }

  const onCancel = () => {
    closeDialog();
  }

  const { draggable, customActions, dialogTitle, onSubmit, hideCloseButton, disableEscapeKeyDown, customClose } = meta;

  return (
    <div>
      <Dialog disableEscapeKeyDown={disableEscapeKeyDown} open={formdialog} onClose={(event, reason) => reason != 'backdropClick' && closeDialog()} aria-labelledby="form-dialog-title" maxWidth={"lg"}
        PaperComponent={draggable ? PaperDraggableComponent : PaperComponent} modalcomponent={modalChild}
      >
        {formdialog &&
          <>
            <DialogTitle id="form-dialog-title" className={classes.header}>
              <Grid container>
                <Grid item lg={11} md={11} sm={11} xs={11} className={classes.headerGrid}>
                  <Typography className={classes.title}>{dialogTitle || ''}</Typography>
                </Grid>
                {!hideCloseButton && <Grid item lg={1} md={1} sm={1} xs={1} >
                  <Tooltip title="Close" aria-label="close">
                    <IconButton aria-label="close" onClick={customClose ? customClose : () => closeDialog()} className={classes.closeIcon}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>}
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.content}>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <Custom onSubmit={onSubmit} onCancel={onCancel} modalChild={modalChild} data={data} />
              {/* </Suspense> */}
            </DialogContent>
            {!customActions &&
              <DialogActions>
                <Button onClick={() => closeDialog()} color="primary">
                  Cancel
                </Button>
              </DialogActions>}
          </>}
      </Dialog>
    </div>
  );

  // return (
  //   <div>
  //     <Dialog open={formdialog} onClose={() => closeDialog()} aria-labelledby="form-dialog-title">
  //       {formdialog &&
  //         <>
  //           <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
  //           <DialogContent>
  //             <Suspense fallback={<div>Loading...</div>}>
  //               <Component onSubmit={onSubmit} onCancel={onCancel} />
  //             </Suspense>

  //           </DialogContent>
  //           {!customActions &&
  //             <DialogActions>
  //               <Button onClick={() => closeDialog()} color="primary">
  //                 Cancel
  //             </Button>
  //             </DialogActions>}
  //         </>
  //       }
  //     </Dialog>
  //   </div>
  // );
}

const mapStateToProps = (state) => {
  return { formdialog: state.dialog.isOpen, modalChild: state.dialog.modalChild,/*  searchOpen: state.common.searchOpen, */ data: state.dialog.data }
};

const mapDispatchToProps = dispatch => ({
  openDialog: () => dispatch(openDialog()),
  closeDialog: () => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormDialog));
