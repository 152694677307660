import styled from "styled-components";

export default styled.button`
  background: transparent;
  color: #ccc;

  cursor:pointer;

  font-size: 18px;
  padding: 4px;
  border: 1px solid transparent;
`;