import axios from 'helpers/interceptor';
import { handleVerifyTPIN } from 'helpers/formRedirect';

export const EDISService = {
  verifyTPIN,
  generateTPIN
};

function verifyTPIN(selectedRows) {
  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/ms-backoffice-transaction-details/backoffice/edis/transaction/add`,
    data: selectedRows
  };

  return axios(requestOptions)
    .then((response) => {
      console.log(response.data);
      if (response.data.success) {
        handleVerifyTPIN(response.data.success);
      } else {
        return response.data || '';
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function generateTPIN() {
  let USER = `${process.env.REACT_APP_USER}`;

  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/ms-backoffice-transaction-details/backoffice/edis/beneficiary/otp`,
    data: { boid: '' }
  };

  return axios(requestOptions)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}