export default (theme) => ({
  root: {
    padding: '24px 12px',
    margin: '8px 0px',
    backgroundColor: theme.palette.background.default,
    width: '800px',
    borderRadius: '4px',
    height: '500px',
    [theme.breakpoints.down('xl')]: {
      width: '700px',
      height: '400px',
      padding: '20px 10px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '600px'
    },
    [theme.breakpoints.down('md')]: {
      width: '500px'
    }
  },
  loadingDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pagingDotsContainer: {
    width: '100%',
    justifyContent: 'space-evenly',
    top: '50px !important'
  },
  pagingDots: {
    fill: `${theme.palette.text.secondary} !important`,
    backgroundColor: 'transparent !important',
    '& svg': {
      width: 16,
      height: 16,
      marginLeft: 1,
      marginRight: 1,
      [theme.breakpoints.down('xl')]: {
        width: 14,
        height: 14
      },
      [theme.breakpoints.down('lg')]: {
        width: 12,
        height: 12
      }
    }
  },
  image: {
    height: 400,
    width: 700,
    cursor: 'pointer',
    [theme.breakpoints.down('xl')]: {
      width: 600,
      height: 300
    },
    [theme.breakpoints.down('lg')]: {
      width: 500
    },
    [theme.breakpoints.down('md')]: {
      width: 400
    }
  },
  imageDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrow: {
    width: 28,
    height: 28,
    cursor: 'pointer',
    backgroundColor: theme.palette.color17.default,
    borderRadius: '50%',
    "&.disabled": {
      display: 'none'
    }
  },
  svg: {
    width: 24,
    height: 24,
    marginLeft: 1,
    marginTop: 1,
    '&.right': {
      marginLeft: 3
    },
    "&.disabled": {
      // backgroundColor: theme.palette.color17.default,
    }
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
