import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Typography, Tooltip, IconButton, Grid, Button, CircularProgress, DialogActions } from "@mui/material"
import { withStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import axios from "helpers/interceptor";
import styles from "./styles";
import ResendButton from "components/ResendButton";
import { DialogActions as ReduxDialogActions } from "redux/ConfirmDialog";
import { Event } from "components/Event";
import NumericTextBox from "components/NumericTextBox";

function OtpPopup(props) {
  const { classes, otpPopup, onClose, resendOtp, closeAllPopups } = props;
  const [otp, setOtp] = useState({ error: false, message: "", number: "", load: false });

  function updateFacility(data) {
    axios.post(`${process.env.REACT_APP_API_URL}/ms-backoffice-client-master/backoffice/clientmaster/updatefacilities`, {
      isMTF: true,
      otp: data,
      product: "MTFActivation"
    }).then(resp => {
      if (resp.data.success) {
        setOtp({ ...otp, error: false, message: "", number: "", load: false })
        localStorage.setItem("mtfStatus", JSON.stringify({ status: "Yes" }))
        closeAllPopups(resp.data.success)
      } else {
        setOtp({ ...otp, error: true, message: resp.data.error, load: false })
      }
    }).catch(error => {
      setOtp({ ...otp, error: true, message: error.message || "There is some technical issue, please try again later", number: "", load: false })
    })
  }

  function submit() {
    if (!otp.number.match(/^[0-9]+$/i)) {
      setOtp({ ...otp, error: true, message: "Please enter valid input" })
    } else if (otp.number?.length < 6) {
      setOtp({ ...otp, error: true, message: "Enter 6 digits" })
    } else {
      setOtp({ ...otp, error: false, message: "", load: true })
      updateFacility(otp.number)
    }
  }

  function handleSubmitFocus() {
    document.getElementById("submitFocus").disabled = false;
    document.getElementById("submitFocus").focus();
  }

  return (
    <Dialog open={otpPopup} maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography className={classes.title}>
              {"OTP Confirmation"}
            </Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <Tooltip title="Close" aria-label="close">
              <IconButton aria-label="close" onClick={onClose} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={[classes.content, "otp"].join(" ")}><div className={classes.centerContent}>
        <Typography variant="h6" className={classes.headingText}>Enter the OTP sent on the registered Mobile No./Email Id to confirm.</Typography>
        <div className={classes.numericTextBoxPadding}>
          <NumericTextBox maxLength={6} setVal={(setOldDataOtp) => setOtp({ ...otp, number: setOldDataOtp })} val={otp.number || ""} count={6} value={otp.number || ""} setFocus={handleSubmitFocus} />
          {otp && otp.error && <p className={classes.errorText}>{otp.message}</p>}
        </div>
        <Grid container className={classes.resendDiv}>
          <Grid item xs={5}>
            <Button style={{ width: "100%" }} className={classes.button} disabled={!otp.number || otp?.number?.length < 6 || otp.load} id={"submitFocus"} variant={"contained"} onClick={submit}>
              {otp.load && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Submit
            </Button>
            <div style={{ marginTop: 12, textAlign: "center" }}>
              <ResendButton onClick={resendOtp} resendLimit={1000} timeOut={59} />
            </div>
          </Grid>
        </Grid>
      </div>
      </DialogContent>
    </Dialog>
  )
}

function MTFActivation(props) {
  const { classes, mtfPopup, onClose, orderPopupClose } = props;
  const [otpPopup, setOtpPopup] = useState({ show: false, load: false });

  function sendOtp(resend) {
    resend === false && setOtpPopup({ show: false, load: true })
    axios.post(`${process.env.REACT_APP_API_URL}/ms-backoffice-smsemail/backoffice/generic/sendOtp`, {
      product: "MTFActivation"
    }).then(resp => {
      if (resp.data.success) {
        if (resend === true) {
          const otpSuccessEvent = new Event("OTP_SUCCESS");
          otpSuccessEvent.sendMessage()
        }
        resend === false && setOtpPopup({ show: true, load: false })
      } else {
        resend === false && setOtpPopup({ show: false, load: false })
        let obj = {
          content: resp.data.error || "",
          hideCloseButton: true,
          okButtonText: "Ok",
          centerActions: true,
          onClose: () => ReduxDialogActions.confirmDialogClose()
        }
        ReduxDialogActions.confirmDialogOpen(null, obj);
      }
    }).catch(error => {
    })
  }

  const handleOtpPopupClose = () => {
    setOtpPopup({ show: false, load: false })
  }

  const closeAllPopups = (message) => {
    setOtpPopup({ show: false, load: false })
    onClose()
    orderPopupClose()
    let obj = {
      content: message || "",
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: () => ReduxDialogActions.confirmDialogClose()
    }
    ReduxDialogActions.confirmDialogOpen(null, obj);
  }

  return (
    <>
      <Dialog open={mtfPopup} maxWidth={"lg"}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose();
          }
        }}
      >
        <DialogTitle className={classes.header}>
          <Grid container>
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography className={classes.title}>
                {"RIGHTS & OBLIGATIONS OF STOCK BROKERS & CLIENTS FOR MARGIN TRADING FACILITY (MTF)"}
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Tooltip title="Close" aria-label="close">
                <IconButton aria-label="close" onClick={onClose} className={classes.closeIcon}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={[classes.content, classes.pText].join(" ")}>
          <Typography variant="h6" className={classes.headingText}>CLIENT RIGHTS</Typography>
          <p>1.Client shall receive all communications in a mode mutually agreed between the broker and the client regarding confirmation of orders/trades, margin calls, decision to liquidate the position/security.</p>
          <p>2.Client shall be free to take the delivery of the securities at any time by repaying the amounts that was paid by the Stock Broker to the Exchange towards securities after paying all dues.</p>
          <p>3.Client has a right to change the securities collateral offered for Margin Trading Facility at any time so long as the securities so offered are approved for margin trading facility.</p>
          <p>4.Client may close / terminate the Margin Trading Account at any time after paying the dues.</p>
          <br />
          <Typography variant="h6" className={classes.headingText}>CLIENT OBLIGATIONS</Typography>
          <p>1.Client shall, in writing in his own hand or in any irrefutable electronic method, agree to avail of Margin Trading Facility in accordance with the terms and conditions of Margin Trading Facility offered by the broker, method of communication for confirmation of orders/trades, margin calls and calls for liquidation of collateral/security/position.</p>
          <p>2.Client shall inform the broker of its intent to shift the identified transaction under Margin Trading Facility within the time lines specified by the broker failing which the transaction will be treated under the normal trading facility.</p>
          <p>3.Client shall place the margin amounts as the Stock Broker may specify to the client from time to time.</p>
          <p>4.On receipt of &lsquo;margin call&rsquo;, the client shall make good such deficiency in the amount of margin placed with the Stock Broker within such time as the Stock Broker may specify.</p>
          <p>5.By agreeing to avail Margin Trading Facility with the broker, client is deemed to have authorized the broker to retain and/or pledge the securities provided as collateral or purchased under the Margin Trading Facility till the amount due in respect of the said transaction including the dues to the broker is paid in full by the client.</p>
          <p>6.Client shall lodge protest or disagreement with any transaction done under the margin trading facility within the timelines as may be agreed between the client and broker.</p>
          <br />
          <Typography variant="h6" className={classes.headingText}>STOCK BROKER RIGHTS</Typography>
          <p>1.Stock Broker and client may agree between themselves the terms and condition including commercial terms if any before commencement of MTF.</p>
          <p>2.Stock broker may set up its own risk management policy that will be applicable to the transactions done under the Margin Trading Facility. Stock broker may make amendments there to at any time but give effect to such policy after the amendments are duly communicated to the clients registered under the Margin Trading Facility.</p>
          <p>3.The broker has a right to retain and/or pledge the securities provided as collateral or the securities bought by the client under the Margin Trading Facility.</p>
          <p>4.The broker may liquidate the securities if the client fails to meet the margin call made by the broker as mutually agreed of liquidation terms but not exceeding 5 working days from the day of margin call.</p>
          <br />
          <Typography variant="h6" className={classes.headingText}>STOCK BROKER OBLIGATIONS</Typography>
          <p>1.Stock broker shall agree with the client the terms and condition before extending Margin Trading Facility to such client. However, for clients who already have existing trading relationship and want to avail of Margin Trading Facility, stock broker may take consent in writing in his own hand or in any irrefutable electronic method after stock broker has communicated the terms and conditions of Margin Trading Facility to such existing clients.</p>
          <p>2.The terms and conditions of Margin Trading Facility shall be identified separately, in a distinct section if given as a part of account opening agreement.</p>
          <p>3.The mode of communication of order confirmation, margin calls or liquidation of position/security shall be as agreed between the broker and the client and shall be in writing in his own hand or in any irrefutable electronic method. Stock broker shall prescribe and communicate its margin policies on haircuts/ VAR margins subject to minimum requirements specified by SEBI and exchanges from time to time.</p>
          <p>4.The Stock Broker shall monitor and review on a continuous basis the client&apos;s positions with regard to MTF. It is desirable that appropriate alert mechanism is set up through which clients are alerted on possible breach of margin requirements.</p>
          <p>5.Any transaction to be considered for exposure to MTF shall be determined as per the policy of the broker provided that such determination shall happen not later than T + 1 day.</p>
          <p>6.If the transaction is entered under margin trading account, there will not be any further confirmation that it is margin trading transaction other than contract note.</p>
          <p>7.In case the determination happens after the issuance of contract, the broker shall issue appropriate records to communicate to Client the change in status of transaction from Normal to Margin trading and should include information like the original contract number and the margin statement and the changed data.</p>
          <p>8.The Stock Broker shall make a &lsquo;margin call&rsquo; requiring the client to place such margin; any such call shall clearly indicate the additional / deficient margin to be made good.</p>
          <p>9.Time period for liquidation of position/security shall be in accordance declared policy of the broker as applicable to all MTF clients consistently. However, the same should not be later than 5 working (trading) days from the day of &apos;margin call&apos;. If securities are liquidated, the contract note issued for such margin call related transactions shall carry an asterisk or identifier that the transaction has arisen out of margin call.</p>
          <p>10.The daily margin statements sent by broker to the client shall identify the margin/collateral for Margin Trading separately.</p>
          <p>11.Margin Trading Accounts where there was no transactions for 90 days shall be settled immediately.</p>
          <p>12.The stocks deposited as collateral with the stock broker for availing margin trading facility (Collaterals) and the stocks purchased under the margin trading facility (Funded stocks) shall be identifiable separately and there shall not be any comingling for the purpose of computing funding amount;</p>
          <p>13.Stock Broker shall close/terminate the account of the client forthwith upon receipt of such request from the client subject to the condition that the client has paid dues under Margin Trading Facility.</p>
          <br />
          <Typography variant="h6" className={classes.headingText}>TERMINATION OF RELATIONSHIP</Typography>
          <p>1.The margin trading arrangement between the stock broker and the client shall be terminated; if the Stock Exchange, for any reason, withdraws the margin trading facility provided to the Stock Broker or the Stock Broker surrenders the facility or the Stock Broker ceases to be a member of the stock exchange.</p>
          <p>2.The MTF facility may be withdrawn by the broker, in the event of client committing any breach of any terms or conditions therein or at anytime after due intimation to client allowing such time to liquidate the MTF position as per the agreed liquidation terms without assigning any reason. Similarly, client may opt to terminate the margin trading facility in the event of broker committing any breach of any terms or conditions therein or for any other reason.</p>
          <p>3.In the event of termination of this arrangement, the client shall forthwith settle the dues of the Stock Broker. The Stock Broker shall be entitled to immediately adjust the Margin Amount against the dues of the client, and the client hereby authorizes the Stock Broker to make such adjustment.</p>
          <p>4.After such adjustment, if any further amount is due from the client to the Stock Broker, the client shall settle the same forthwith. Upon full settlement of all the dues of the client to the Stock Broker, the Stock Broker shall release the balance amount to the client.</p>
          <p>5.If the client opts to terminate the margin trading facility, broker shall forthwith return to the client all the collaterals provided and funded securities retained on payment of all the dues by clients.</p>
          <br />
          <Typography variant="h6" className={classes.headingText}>TRADEBULLS SECURITIES PRIVATE LIMITED - TERMS &amp; CONDITIONS</Typography>
          <p>1.Tradebulls Securities (P) Limited (Tradebulls) is eligible to provide Margin Trading Facility (MTF) in accordance with SEBI &amp; Exchange Guidelines as specified from time to time.</p>
          <p>2.Tradebulls is desirous of extending MTF to their Clients and has obtained prior permission of BSE and NSE for providing MTF to the Clients. The Exchanges have the right to withdraw the permission at anytime for sufficient reasons.</p>
          <p>3.Tradebulls is permitted to extend MTF to the Clients on such terms and conditions as specified by the Stock Exchanges / SEBI from time to time and as mutually agreed by and between Tradebulls and the Clients. This Rights and Obligation comprises the terms and conditions applicable to MTF and Tradebulls and Clients shall abide by the same and any other requirements of the margin trading framework, including other rights and obligations, if any, prescribed by the Stock Exchange/ SEBI/ Tradebulls from time to time. Any modifications to the terms and conditions, other than those prescribed by SEBI/Stock Exchanges, shall be intimated to the Clients giving 15 days notice in advance.</p>
          <p>4.Equity Shares that are classified as &ldquo;Group I Security&rdquo; by SEBI only shall be eligible for MTF. Tradebulls, at its discretion, may not provide funding under MTF to certain equity shares though classified to be &ldquo;Group I Security&rdquo; by SEBI. Equity shares shortlisted by Tradebulls for funding (Approved List) shall be as displayed on Tradebulls website www.tradebulls.in from time to time.</p>
          <p>5.Initial margin, increased margin, margin shortage, margin calls, maximum allowable exposure, maximum stock specific exposure, trade confirmation, square off intimation and such other information in relation to MTF shall be communicated electronically through one or many or all of the following modes, viz email, SMS, WhatsApp, mobile notifications, and additionally through telephone calls.</p>
          <p>6.In order to avail of margin facility, the minimum initial margin required to be provided by the Clients, as prescribed by SEBI/Stock Exchanges, is as under:</p>
          <pre>     oVaR + 3 times of applicable ELM in case of F &amp; O Stocks (i.e. stocks available for trading in the F&amp;O Segment)</pre>
          <pre>     oVaR + 5 times of applicable ELM in case of stocks other than F &amp; O Stocks.</pre>
          <pre>     VaR and ELM shall mean VaR and ELM as applicable to respective stocks in the cash segment.</pre>
          <p>7.Client shall be required to provide the minimum initial margin as applicable for a particular stock to buy that stock under MTF. The margin shall never be lower than that prescribed by the Stock Exchange/SEBI. However, Tradebulls shall have the right to demand a higher initial margin than the margin prescribed by SEBI/Stock Exchanges.</p>
          <p>8.Subject to the initial margin as aforesaid, Tradebulls may, at its sole and absolute discretion, revise and increase from time to time the margin required for any stock permitted to be traded under MTF. Where Client has exposure in the stock in respect of which margin has been revised but does not already have sufficient credit in the account to meet increase in margin, Client shall pay margin found short within the time prescribed for making margin payment.</p>
          <p>9.Applicable minimum initial margin, increased margin, margin shortfall, if any, can be paid in the form of cash, cash equivalent, or Group I equity shares with appropriate hair cut as specified in SEBI Master Circular No. SEBI/HO/MRD/DP / CIR/P/2016/135, DTD. 16/12/2016. Client shall have the right to change collateral securities provided under the MTF with other collateral securities provided that such other collateral securities are approved and sufficient to meet the margin required.</p>
          <p>10.Margin requirement on shares purchased under MTF shall be computed by grossing applicable margin i.e., minimum initial margin plus increased margin, if any, on each stock and shortage computed accordingly by deducting available margin from gross margin. Collateral shares and shares purchased under MTF (Funded Shares) shall be marked to market daily for the purpose of computing the margin/shortage of margin.</p>
          <p>11.Applicable minimum initial margin and increased margin, if any, shall be kept supplied at all times by the Clients in respect of the stocks purchased under the MTF. Client shall pay any shortage in the required margin immediately on receiving demand (margin call) and in any case not later than 11.00 P.M on the trade day following the day of making the margin call (prescribed time) failing which Tradebulls shall be at liberty to liquidate the funded shares and/or collateral shares to recover the dues outstanding in the account of the Clients. In case of extreme volatility in the market, Tradebulls may demand payment of margin forthwith and prescribed time for making margin payment shall be construed accordingly. Decision of Tradebulls in relation to market volatility shall be final and binding without Tradebulls having to provide any reason for the decision to the client.</p>
          <p>12.If required margin is not provided within the prescribed time, client shall be treated as client in margin default. Tradebulls shall not be obliged to notify the client in margin default of the liquidation of shares, ahead of liquidation. Tradebulls shall not be obliged to liquidate shares proportionate to the shortage in margin.</p>
          <p>13.Client in margin default shall continue to be in margin default, until the required margin is furnished in full to eliminate the shortage. Partial payment of margin or a change in the required margin shall not extend the time stipulated for making margin payment which will run from the time of making margin call to the client.</p>
          <p>14.In case margin is reduced by an amount equal to applicable ELM component of the total margin due to market volatility within a trading day (i.e. available margin becomes equal to or less than applicable VAR margin), Tradebulls reserves the right to liquidate the collaterals and/or funded shares forthwith without prior notice to the client.</p>
          <p>15.MTF clients purchasing shares not specified in Tradebulls Approved List of Group 1 securities shall be required to 100% margin upfront for such purchases.</p>
          <p>16.If any shares are delisted from Tradebulls Approved List, client shall be required to make payment of full purchase consideration against such shares on receiving margin call within the prescribed time, failing which Tradebulls shall be at liberty sell such shares without further notice to the client.</p>
          <p>17.If a client is debarred by orders of lawful authority from trading in the securities market, Tradebulls shall liquidate collateral and funded shares of the client to recover its dues to the full extent forthwith.</p>
          <p>18.In case of death of a client, Tradebulls shall be entitled to liquidate the collateral and funded shares under MTF and recover the unpaid outstanding due.</p>
          <p>19.Any loss arising from liquidation of the shares shall to be account of the client. Client shall forthwith pay Tradebulls any unpaid dues outstanding in the account after liquidation of the shares.</p>
          <p>20.Tradebulls reserves the right to withdraw MTF with respect to any client without assigning any reason after giving a reasonable notice to the client in which case dues if any outstanding in the account of the client shall become payable immediately. Failure to make payment of the outstanding dues shall result in liquidation of collateral and/or funded shares held in client&rsquo;s account.</p>
          <p>21.Client may terminate the MTF account after paying all dues in the MTF account.</p>
          <p>22.Tradebulls shall not use the funds and securities of one client to provide MTF to another client, even on the authority of the client.</p>
          <p>23.The stocks deposited as margin collateral and funded stock shall be identifiable separately and no comingling shall be permitted for the purpose of computing funding amount.</p>
          <p>24.Tradebulls may at its option allow Client to buy further shares under MTF on the basis of increase in the value of collateral shares, subject to applicable hair cut. Further purchase shall not be permitted on the basis of increase in the market value of funded shares.</p>
          <p>25.IPF shall not be available for transactions done on the Stock Exchange, through MTF, in case of any losses suffered in connection with the MTF availed by the client.</p>
          <p>26.Tradebulls shall restrict the maximum gross exposure as well as individual stock-wise exposure of a client under the MTF at any point in time according to its internal policies and market views without assigning any reasons to the client. Furnishing applicable margin shall not by itself entitle the Client to seek exposure beyond the limit restricted by Tradebulls.</p>
          <p>27.Admitting Clients for MTF shall be at the discretion of the Stock Broker/Trading Member. Clients request for admission to MTF may be disallowed without assigning any reason.</p>
          <p>28.By agreeing to avail of MTF, the Client shall be deemed to have authorized Tradebulls to retain and/or pledge the shares purchased under MTF (funded shares) and collateral shares provided as margin till the amount due in respect of the purchase and all other dues are paid in full by the client.</p>
          <p>29.Tradebulls shall maintain separate ledgers for funds and securities of the client availing of MTF.</p>
          <p>30.All outstanding dues under MTF shall carry interest 18% p.a + applicable taxes, compounded at fortnightly rest.</p>
          <p>31.Outstanding dues shall not be carried in the books beyond 90 days from the date of accrual and in case Client fails to pay up the dues within the said 90 days, collateral and/or funded shares shall be sold to liquidate the dues, even though applicable margin is available in the MTF account of the Client. For this purpose, 90 days shall be computed with respect to each debit entry in respect of purchases under MTF separately and liquidation shall be carried out accordingly. Tradebulls shall have discretion to sell any stock/stocks to liquidate the outstanding dues older than 90 days.</p>
          <p>32.Client shall be free to take delivery of the shares purchased under MTF anytime, but not later than 90 days, from the date of funding by making full payment of the outstanding dues in relation to the shares purchased.</p>
          <p>33.Until full payment of the outstanding dues in the MTF A/c is made by the Client, collateral shares and funded shares, as far as may be required, shall be retained in the Demat A/c of Tradebulls, separately identified as collateral shares and funded shares.</p>
          <p>34.Daily margin statement sent to the MTF Clients shall identify margin/collateral for MTF transaction separately.</p>
          <p>35.MTF account where there is no transaction under MTF for more than 90 days shall be settled immediately on expiry of said 90 days provided there are no dues outstanding in the MTF account. Dues if any outstanding in the normal trading account shall be first adjusted against the settlement amount and the remainder shall be paid to the client.</p>
          <p>36.Client admitted to MTF shall be deemed to have opted to treat unpaid shares held in client&rsquo;s account with Tradebulls as shares purchased in terms of the MTF and corresponding accrued outstanding dues shall be treated accordingly. In this case, Client shall furnish additional collateral shares, cash and/or cash equivalent required to bring up available margin to the level of initial minimum margin required under MTF, within the prescribed time after receiving margin call. Option to treat accrued outstanding dues as funding made under MTF shall be available only where the outstanding due is less than 90 days old.</p>
          <p>37.In case Client does not opt for MTF, all dues on account of purchase of shares, accrued/accruing, shall be payable immediately/on or before T+7 failing which Tradebulls shall sell the shares to liquidate/reduce the dues and remaining unpaid dues, if any, shall become payable forthwith.</p>
          <p>38.Tradebulls shall declare and communicate to the client risk management policies that it will follow with respect to MTF transactions. Tradebulls may amend the policies from time to time according to its risk perceptions and inform the Clients of the amendments made.</p>
          <p>39.Any disputes arising between the client and Tradebulls in connection with the margin trading facility shall be resolved through the investor grievance redressal mechanism and/or arbitration mechanism of the stock exchanges as in the case of normal trades.</p>
          <p>40.The Rights and Obligations prescribed hereinabove shall be read in conjunction with the rights and obligations as prescribed under SEBI circular No. CIR/MIRSD/16/2011 dated August 22, 2011, SEBI Circular No. CIR/MRD/DP/54/ 2017 Dtd. June 13, 2017, the Circulars relating to MTF issued by the respective Stock Exchanges, any modifications thereto from time to time and the Policies and Procedures prescribed by Tradebulls and the terms and conditions of client&rsquo;s agreement with Tradebulls. In case of any inconsistencies between the Rights and Obligations herein and the provisions in the aforesaid SEBI and/or Stock Exchange Circulars, the later shall prevail to the extent of such inconsistencies.</p>
        </DialogContent>
        <DialogActions className={classes.actionGrid}>
          <Button variant="outlined" disabled={otpPopup.load} className={classes.button} onClick={onClose}>Disagree</Button>
          <Button variant="contained" disabled={otpPopup.load} className={classes.button} onClick={() => sendOtp(false)}>
            {otpPopup.load && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Agree
          </Button>
        </DialogActions>
      </Dialog>
      <OtpPopup
        classes={classes}
        otpPopup={otpPopup.show}
        resendOtp={() => sendOtp(true)}
        onClose={handleOtpPopupClose}
        closeAllPopups={closeAllPopups}
      />
    </>
  )
}

export default withStyles(styles)(MTFActivation);