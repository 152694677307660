import styled from "styled-components";

export default styled.text`
    font-size: 18px;
    color: white;
    white-space: break-spaces;
    line-height: 18px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 11px;
    border: 1px solid #FFF;
`;
