export default theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "auto"
  },
  rootDiv: {
    flexGrow: 1,
    height: 'calc(100% - 12px)',
    margin: "6px",
    [theme.breakpoints.down('xl')]: {
      margin: "4px",
      height: "calc(100% - 8px)"
    },
  },
  grid: {
    height: '100%',
    alignSelf: "center",
    minWidth: "1110px",
    width: "100%",
    [theme.breakpoints.down('xl')]: {
      minWidth: "920px",
      padding: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: "470px"
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "390px"
    },
  },
  mainGrid: {
    padding: "18px 24px",
    [theme.breakpoints.down('xl')]: {
      padding: "0px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "0px"
    },
  },
  valueContainer: {
    margin: "20px 0px",
    [theme.breakpoints.down('xl')]: {
      margin: "12px 0px 8px 0px",
    },
    [theme.breakpoints.down('lg')]: {
      margin: "14px 0px"
    },
  },
  firstGrid: {
    paddingRight: "16px",
    [theme.breakpoints.down('xl')]: {
      paddingRight: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: "12px",
    }
  },
  secondGrid: {
    paddingLeft: "16px",
    paddingRight: "16px",
    borderLeft: `1px dashed ${theme.palette.color15.default}`,
    borderRight: `1px dashed ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: "0px",
      borderRight: "0px",
      paddingLeft: "12px",
    }
  },
  thirdGrid: {
    paddingLeft: "16px",
    [theme.breakpoints.down('xl')]: {
      paddingLeft: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: "0px"
    }
  },
  infoTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 0px",
    [theme.breakpoints.down('xl')]: {
      padding: "6px 0px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "4px 0px",
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: "4px 0px"
    }
  },
  weeklyInfo: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "4px",
    padding: "14px 8px",
    textAlign: "center",
    marginTop: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "8px 0px",
      marginTop: "0px"
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "2px",
      padding: "8px",
      marginBottom: "0px"
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "2px",
      padding: "6px",
      marginBottom: "0px"
    }
  },
  primaryText: {
    color: theme.palette.text.primary
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  greenText: {
    color: "#338603"
  },
  redText: {
    color: "#E83800"
  },
  rankText: {
    color: "#F2952B",
    fontWeight: 600
  },
  doubledMoneyText: {
    color: "#788BC4",
    fontWeight: 600
  },
  fundManagerDiv: {
    backgroundColor: theme.palette.background.secondary,
    marginTop: "16px",
    padding: "25px 20px", // 24px 16px
    borderRadius: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "18px 16px", // 14px 16px
      marginTop: "24px" // 22px
    },
    [theme.breakpoints.down('lg')]: {
      padding: "12px",
    }
  },
  returnsDiv: {
    display: "flex",
    padding: "6px 0px",
    // borderRadius: "4px",
    // backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.down('xl')]: {
      padding: "4px 0px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: "16px"
    },
  },
  returnsValueDiv: {
    textAlign: "center",
    padding: "0px 16px",
    borderLeft: `1px dashed ${theme.palette.color15.default}`
  },
  rankGrid: {
    background: theme.palette.background.secondary,
    padding: "20px",
    borderRadius: "4px",
    marginTop: "20px",
    [theme.breakpoints.down('xl')]: {
      marginTop: "16px",
      padding: "12px 0px",
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: "12px"
    }
  },
  rankValueDiv: {
    textAlign: "center",
    borderRight: `1px dashed ${theme.palette.color15.default}`
  },
  button: {
    backgroundColor: theme.palette.common.blue,
    textTransform: "none",
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    }
  },
  benchMarkDiv: {
    display: "flex",
    width: "100%",
    padding: "8px 0px",
    height: "56px",
    justifyContent: "space-between",
    [theme.breakpoints.down('xl')]: {
      padding: "6px 0px",
      height: "60px"
    },
    [theme.breakpoints.down('lg')]: {
      padding: "4px 0px",
      height: "40px"
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: "4px 0px"
    }
  }
})