import { FiberManualRecord, FiberManualRecordOutlined } from "@mui/icons-material";
import { Box, Link, List, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from 'react';

const data = [
  {
    text: "For stocks listed in both NSE and BSE - The rate as per NSE is taken."
  },
  {
    text: "For stocks listed in BSE only - The rate as per BSE is taken."
  },
  {
    text: "For Delivery based trades, Hold Price is calculated based on the First-in First-out (FIFO) methodology of accounting."
  },
  {
    text: "Holding Cost is calculated based on the weighted average rate of all the trades of a particular stock for a day rounded off to 2 decimal points. It includes brokerage, stamp duty, SEBI Fees, GST, transaction charges & all other statutory charges (including STT)."
  },
  {
    text: "Corporate Action Adjustments are reflected on ‘Record Date’ or as and when updated by the depository."
  },
  {
    text: "For scrips allotted through primary market such as IPO, ETF, FPO, Rights Issue, etc., Allotment price is considered as buy price whereas for Bonds, face value is considered as Buy price."
  },
  {
    text: "Any transaction / position updated prior to 01/04/2021 are not shown in this report."
  },
  {
    text: "Primarily manual update/edit of purchase price and date by the Client may be required for the following types of transactions to calculate the actual Hold Price",
    subText: [
      "Transfer-in /Transfer-out: Share(s) transferred from other DP's.",
      "Discounted IPO Transactions: Stocks received at retail discounted price directly offered by the issuing company.",
      "One time update/edit of Price(s) for a few exceptional transactions summarized as on 31st March 2021."
    ]
  },
  {
    text: "System availability and response times are subject to system and application function and also that of the client’s internet/mobile connectivity."
  },
  {
    text: "The client is required to periodically reconcile the holdings shown in the portfolio with actual holdings including effective cost price, date of purchase/acquisition etc. and take necessary action where required."
  },
  {
    text: "This report is generated on trade day basis and not on settlement day basis; hence there will be a difference when matched against DP holding statement to the extent of transactions which are pending for settlement and auction transactions."
  }
]

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    margin: "-8px -24px",
    padding: "24px",
    width: 900,
    [theme.breakpoints.down('xl')]: {
      width: 700,
      padding: "16px 20px"
    },
    [theme.breakpoints.down('lg')]: {
      width: 700,
    },
    [theme.breakpoints.down('md')]: {
      width: 500,
      padding: "10px 16px"
    }
  },
  dot: {
    color: theme.palette.common.blue,
    height: 12,
    width: 12,
    marginTop: 3,
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down('md')]: {
      marginLeft: 6,
    },
    "&.outlined": {

    }
  },
  textContent: {
    color: theme.palette.type === 'light' ? '#333' : '#A5AAB7',
    textAlign: 'justify',
    fontSize: "14px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
    }
  },
  list: {
    height: 300,
    overflowY: 'scroll',
    marginBottom: 12,
    [theme.breakpoints.down('lg')]: {
      height: 250,
    },
    [theme.breakpoints.down('md')]: {
      height: 200,
    }
  },
  textBox: {
    paddingTop: 8,
    paddingRight: 12,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 6,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 4,
    }
  },
  blueText: {
    color: theme.palette.common.blue,
  },
  belowDiv: {
    borderTop: `2px solid ${theme.palette.color14.primary}`,
  },
  contactText: {
    paddingTop: 12,
    textAlign: 'center',
    color: theme.palette.type === 'light' ? '#333' : '#A5AAB7',
  }
});

function Disclaimer(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.textContent}>
        Portfolio summary report provided above is based on the methodology as provided below. Tradebulls may depend on third party applications and data sources for providing the portfolio information and  provides on the same on as "as is" basis and Tradebulls does not provide any warranty on the accuracy and completeness of the information provided.
      </Typography>
      <List className={classes.list}>
        {
          data.map(ele => (
            <Box display="flex" flexDirection="row" className={classes.textBox}>
              <FiberManualRecord className={classes.dot} />
              <div>
                <Typography className={classes.textContent}>
                  {ele.text}
                </Typography>
                {
                  ele.subText?.length > 0 && ele.subText.map(item => (
                    <Box display="flex" flexDirection="row" className={classes.textBox}>
                      <FiberManualRecordOutlined className={classes.dot } />
                      <Typography className={classes.textContent}>
                        {item}
                      </Typography>
                    </Box>
                  ))
                }
              </div>
            </Box>
          ))
        }
      </List>
      <div className={classes.belowDiv}>
        <Typography className={classes.contactText}>
          For any further queries you can write to us at <span><Link to="#" href="mailto:wecare@tradebulls.in">wecare@tradebulls.in</Link></span> or call on <span className={classes.blueText}>+91 79 4000 1000</span>
        </Typography>
      </div>
    </div>
  )
}

export default (withStyles(styles)(Disclaimer));