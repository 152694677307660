import React, { useEffect } from "react"
import { FieldArray, Form, Formik } from "formik";
import { withStyles } from '@mui/styles';
import styles from "../styles"
import * as Yup from "yup";
import { Grid, Typography, FormControl, Button, FormControlLabel, Link, MenuItem } from "@mui/material";
import { getDate, SelectOutlinedInput, CssOutlinedInput, CssCheckbox, CssSelect, CssTooltip } from "../data";
import { KeyboardArrowDown as Arrow, InfoOutlined as InfoIcon } from '@mui/icons-material';

const ValidTillInfo = (props) => {
  const { classes } = props
  return (
    <>
      <Typography className={classes.tooltip}>GFD: Good for Day stays active in the market until the end of the trading day.</Typography>
      <Typography className={classes.tooltip}>IOC: Immediate or Cancel is an order wherein it is executed immediately ( buy or sell) or cancelled, if it cannot be executed immediately.</Typography>
    </>
  )
}

function EquitySIP(props) {
  const { classes, formData, handleFormValues, formValues, handleBack, activeStep, sipData, buySell } = props

  const modifyFormData = (data, filled) => {
    let arr = []; let obj = {};
    let frequency = filled ? formValues.frequency : (sipData && Object.keys(sipData).length > 0 && Object.keys(sipData)[0]) || ""
    obj = {
      startDate: filled ? formValues.startDate :
        (sipData[frequency] && sipData[frequency].sipStartDate && sipData[frequency].sipStartDate.length > 0 && sipData[frequency].sipStartDate[0]) || "",
      installments: filled ? formValues.installments : (sipData[frequency] && sipData[frequency].installments) || 0,
      frequency: frequency,
      bankMandate: filled ? formValues.bankMandate :
        (sipData[frequency] && sipData[frequency].mandate && sipData[frequency].mandate.length > 0 && sipData[frequency].mandate[0] && sipData[frequency].mandate[0].mandateNumber) || "",
      terms: filled ? formValues.terms : false,
      productType: filled ? formValues.productType : "Delivery",
      validTill: filled ? formValues.validTill : "GFD"
    }
    data.map((ele) => {
      arr.push({ quantity: filled ? ele.quantity : ele.min_qty })
    })
    obj.eqSip = [...arr]
    return obj
  }

  const sumOf = (values) => {
    let sum = 0
    if (values.length > 0) {
      formData.forEach((ele, index) => {
        sum = sum + (Number(ele.stock_price) * Number(values[index].quantity))
      })
    }
    return Number(sum).toFixed(2)
  }

  function onFrequencyChange(value, data, setFieldValue) {
    setFieldValue('frequency', value)
    if (data.mandate && data.mandate.length > 0) {
      setFieldValue('bankMandate', (data.mandate[0] && data.mandate[0].mandateNumber) || 0)
    } else {
      setFieldValue('bankMandate', "")
    }
    if (data.sipStartDate && data.sipStartDate.length > 0) {
      setFieldValue('startDate', data.sipStartDate[0])
    } else {
      setFieldValue('startDate', "")
    }
    setFieldValue("installments", data.installments)
  }

  const FormFields = (props) => {
    const { values, handleChange, setFieldTouched, resetForm, errors, touched, setFieldValue } = props
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.headerPadding}>
              <Grid item lg={4} sm={5} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Stocks</Typography>
              </Grid>
              <Grid item xs={3} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>LTP</Typography>
              </Grid>
              <Grid item lg={3} sm={2} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>SIP Quantity</Typography>
              </Grid>
              <Grid item xs={2} className={classes.centerContent}>
                <Typography variant="caption" align="center" className={classes.blueText}>Approx Value</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <FieldArray
          name="eqSip"
          render={arrayHelpers => {
            return (
              <Grid item xs={12} className={classes.gridHeight}>
                {
                  values && values.eqSip && values.eqSip.length > 0
                    ? values.eqSip.map((user, index) => (
                      <Grid container key={index} className={classes.gridItem}>
                        <Grid item lg={4} sm={5}>
                          <Typography variant="subtitle1" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].stock_name) || "--"}</Typography>
                          <Typography variant="caption" className={classes.secondaryText}>
                            {((formData && formData.length > 0 && formData[index] && formData[index].exchange) || "--") + " " + ((formData && formData.length > 0 && formData[index] && formData[index].segment) || "EQ")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1" align="center" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].stock_price && Number(formData[index].stock_price).toFixed(2)) || "--"}</Typography>
                        </Grid>
                        <Grid item lg={3} sm={2}>
                          <FormControl variant="outlined">
                            <CssOutlinedInput
                              id={`eqSip.${index}.quantity`}
                              name={`eqSip.${index}.quantity`}
                              autoFocus={index === 0 ? true : false}
                              classes={{ input: classes.input }}
                              onChange={handleChange}
                              value={values.eqSip[index].quantity}
                              onKeyUp={() => setFieldTouched("quantity", true)}
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            />
                          </FormControl>
                          {errors && errors.eqSip && errors.eqSip.length > 0 && errors.eqSip[index] && errors.eqSip[index].quantity &&
                            <p className={classes.errorText}>{errors.eqSip[index].quantity}</p>}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="subtitle1" align="right" className={classes.primaryText}>{(formData && formData.length > 0 && formData[index] && formData[index].stock_price && Number(Number(formData[index].stock_price) * Number(values.eqSip[index].quantity)).toFixed(2)) || "--"}</Typography>
                        </Grid>
                      </Grid>
                    ))
                    : null
                }
              </Grid>
            );
          }}
        />
        <Grid item xs={12}>
          <Grid container className={classes.approxDiv}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.secondaryText}>Total SIP Amount</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="outlined" className={classes.outlinedButton} style={{ padding: 0 }} onClick={() => resetForm({ values: modifyFormData(formData) })}>Reset</Button>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" className={classes.primaryText}>{(sumOf(values.eqSip)) || "--"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ borderBottom: "1px solid #66788a4d", paddingBottom: 12 }}>
          <Grid container>
            <Grid item xs={/* 5 */ 8}>
              <Grid container>
                <Grid item xs={3}>
                  <FormControl variant="outlined" className={classes.formField}>
                    <Typography className={classes.fieldText}>Start Date</Typography>
                    <CssSelect
                      id={'startDate'}
                      name={'startDate'}
                      value={values.startDate}
                      onChange={handleChange}
                      onKeyUp={() => setFieldTouched("startDate", true)}
                      className={classes.select}
                      input={<SelectOutlinedInput />}
                      variant="standard"
                      IconComponent={() => <Arrow />}
                    >
                      {
                        sipData && Object.keys(sipData).length > 0 && sipData[values.frequency].sipStartDate && sipData[values.frequency].sipStartDate.length > 0 &&
                        sipData[values.frequency].sipStartDate.map((ele) => (
                          <MenuItem key={ele} value={ele} className={classes.menuItem}>{getDate(ele)}</MenuItem>
                        ))
                      }
                    </CssSelect>
                  </FormControl>
                  {errors && errors.startDate && <p className={classes.errorText}>{errors.startDate}</p>}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <FormControl variant="outlined" className={classes.formField}>
                    <Typography className={classes.fieldText}>No. of Installments</Typography>
                    <CssOutlinedInput
                      id={"installments"}
                      name={"installments"}
                      classes={{ input: classes.input }}
                      onChange={handleChange}
                      disabled={true}
                      value={values.installments}
                      onKeyUp={() => setFieldTouched("installments", true)}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    />
                  </FormControl>
                  {errors && errors.installments && <p className={classes.errorText}>{errors.installments}</p>}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <FormControl variant="outlined" className={classes.formField}>
                    <Typography className={classes.fieldText}>Frequency</Typography>
                    <CssSelect
                      id={'frequency'}
                      name={'frequency'}
                      value={values.frequency}
                      onChange={(e) => { onFrequencyChange(e.target.value, sipData[e.target.value], setFieldValue) }}
                      onKeyUp={() => setFieldTouched("frequency", true)}
                      className={classes.select}
                      input={<SelectOutlinedInput />}
                      variant="standard"
                      IconComponent={() => <Arrow />}
                    >
                      {
                        sipData && Object.keys(sipData).length > 0 &&
                        Object.keys(sipData).map((ele) => (
                          <MenuItem key={ele} value={ele} className={classes.menuItem}>{ele}</MenuItem>
                        ))
                      }
                    </CssSelect>
                  </FormControl>
                  {errors && errors.frequency && <p className={classes.errorText}>{errors.frequency}</p>}
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <Typography className={classes.fieldText}>Bank Mandate</Typography>
                <CssSelect
                  id={'bankMandate'}
                  name={'bankMandate'}
                  value={values.bankMandate}
                  onChange={handleChange}
                  onKeyUp={() => setFieldTouched("bankMandate", true)}
                  className={classes.select}
                  input={<SelectOutlinedInput />}
                  variant="standard"
                  IconComponent={() => <Arrow />}
                >
                  {
                    sipData && Object.keys(sipData).length > 0 ?
                      sipData[values.frequency].mandate.map((ele) => (
                        <MenuItem key={ele.mandateNumber} value={ele.mandateNumber} className={classes.menuItem}>{ele.bankName}({ele.mandateNumber})</MenuItem>
                      ))
                      :
                      <MenuItem key={""} value={""} className={classes.menuItem}>{""}</MenuItem>
                  }
                </CssSelect>
              </FormControl>
              {errors && errors.bankMandate && <p className={classes.errorText}>{errors.bankMandate}</p>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.bottomInfoDiv}>
            <Grid item xs={4}>
              <Typography variant="caption" style={{ fontWeight: 600 }} className={classes.primaryText}>Product : {" "}<span className={classes.span}>{values.productType || "--"}</span></Typography>
            </Grid>
            <Grid item xs={4} className={classes.spacedContent} style={{ justifyContent: "flex-start" }}>
              <Typography variant="caption" style={{ fontWeight: 600 }} className={classes.primaryText}>ValidTill : {" "}<span className={classes.span}>{values.validTill || "--"}</span></Typography>
              <CssTooltip title={<ValidTillInfo classes={classes} />} arrow>
                <InfoIcon className={classes.info} />
              </CssTooltip>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.termsGrid}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <div className={classes.termsDiv}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  id="terms"
                  name="terms"
                  control={<CssCheckbox
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    color="primary"
                    checked={values.terms}
                    onKeyUp={() => setFieldTouched("terms", true)}
                    onChange={handleChange}
                  />}
                  label={""}
                  labelPlacement="end"
                />
                <Typography style={{ marginLeft: "-16px" }} variant="subtitle2" className={[classes.secondaryText, classes.checkboxMargin].join(" ")}>By continuing, you agree to &nbsp;
                  <span>
                    <Link
                      className={classes.blueText}
                      target="_blank"
                      href="/tnc/TnCMF.pdf"
                      rel="noopener"
                    >
                      Terms and Conditions
                  </Link>
                  </span>
                  &nbsp; of Tradebulls.
                </Typography>
              </div>
              {errors.terms && touched.terms && <p className={classes.errorText}>{errors.terms}</p>}
            </div>
          </FormControl>
        </Grid>
        {
          activeStep !== 0 ?
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="outlined" className={classes.cancel} onClick={() => handleBack(0, "Equity")}>{"Back"}</Button>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Grid item xs={3}>
                  <Button variant="contained" style={{ marginLeft: 0 }} className={classes.order} type="submit">{"Next"}</Button>
                </Grid>
              </Grid>
            </Grid>
        }
      </>
    )
  }

  const form = () => {
    return (
      <Formik
        initialValues={formValues && formValues && formValues.data && formValues.data.length > 0 ? modifyFormData(formValues.data, true) : modifyFormData(formData)}
        validationSchema={Yup.object({
          eqSip: Yup.array().of(
            Yup.object().shape({
              quantity: Yup.number().integer("Decimal is not allowed").typeError("Invalid number")
                .min(0, "Quantity should be greater than or equal to Zero")
                .max(10000000, "Quantity should be less than 10000000")
            })
          ),
          terms: Yup.boolean()
            .required("The terms and conditions must be accepted.")
            .oneOf([true], "The terms and conditions must be accepted."),
          bankMandate: Yup.string(),
          startDate: Yup.number().typeError("Invalid date")
            .required("Start date is required"),
          installments: Yup.number().typeError("Invalid number")
            .required("No. of Installments is required"),
          frequency: Yup.mixed()
            .required("Frequency is required")
        })}
        onSubmit={values => {
          let data = []
          values.eqSip.map((ele, index) => {
            data.push({
              scripName: formData[index].stock_name,
              ltp: formData[index].stock_price,
              scripId: formData[index].scripId,
              symbol: formData[index].symbol,
              segment: formData[index].segment,
              exchange: formData[index].exchange,
              quantity: ele.quantity ? Number(ele.quantity) : 0,
              shortCode: formData[index].shortCode,
              approxValue: formData[index].stock_price * Number(ele.quantity)
            })
          })
          let obj = {
            sumOf: sumOf(values.eqSip),
            terms: values.terms,
            startDate: values.startDate || "",
            installments: Number(values.installments || 0),
            frequency: values.frequency || "",
            bankMandate: values.bankMandate || "",
            productType: "Delivery",
            validTill: "GFD",
            orderType: "Market",
            action: buySell,
            data
          }
          handleFormValues(obj)
        }}
      >
        {({ values, resetForm, handleChange, errors, touched, setFieldTouched, setFieldValue }) => (
          <Form>
            <FormFields
              errors={errors}
              values={values}
              touched={touched}
              resetForm={resetForm}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div>
      {
        form()
      }
    </div>
  )
}

export default withStyles(styles)(EquitySIP)