import { store } from 'helpers';
import { FundExplorerService } from 'views/MutualFund/fundexplorer/service';

const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
const SET_FILTER_SELECTION = 'SET_FILTER_SELECTION';

const GET_DEFAULT = 'GET_DEFAULT';
const GET_DEFAULT_SUCCESS = 'GET_DEFAULT_SUCCESS';
const GET_DEFAULT_FAILURE = 'GET_DEFAULT_FAILURE';

const GET_AUTO_COMPLETE = 'GET_AUTO_COMPLETE';
const GET_AUTO_COMPLETE_SUCCESS = 'GET_AUTO_COMPLETE_SUCCESS';
const GET_AUTO_COMPLETE_FAILURE = 'GET_AUTO_COMPLETE_FAILURE';

const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';

const GET_FILTERS = 'GET_FILTERS';
const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';

const GET_FILTER_RESULTS = 'GET_FILTER_RESULTS';
const GET_FILTER_RESULTS_SUCCESS = 'GET_FILTER_RESULTS_SUCCESS';
const GET_FILTER_RESULTS_FAILURE = 'GET_FILTER_RESULTS_FAILURE';

const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
const TOGGLE_SEARCH_DIALOG = 'TOGGLE_SEARCH_DIALOG';

export const FundExplorerActions = {
    toggleFilters,
    toggleSearchDialog,
    getDefaultData,
    setSearchString,
    setFilterSelection,
    getAutoComplete,
    getFilters,
    getFilterResults,
};

//ACTION CREATORS
function getDefaultData() {
    onRequest();

    FundExplorerService.getDefaultData().then(response => {
        onSuccess(response);
    });

    function onRequest() { store.dispatch({ type: GET_DEFAULT }); }
    function onSuccess(data) { store.dispatch({ type: GET_DEFAULT_SUCCESS, data }); }
    function onFailure() { store.dispatch({ type: GET_DEFAULT_FAILURE }); }
}

function setSearchString(data, isSubmitted, searchbyCode) {
    // debugger
    data = typeof data === 'string' ? { label: data, value: data } : data;

    store.dispatch({ type: SET_SEARCH_STRING, data });

    const { showFilters, filterSelection } = store.getState().fundExplorer;
    if (data && data.value.length > 2) {
        if (showFilters) {
            getFilters();
        } else {
            if (isSubmitted) {
                getSearchResults(searchbyCode ? data.oldValue : data.value, searchbyCode);
            } else {
                getAutoComplete(data.value);
            }
        }
    } else {
        if (showFilters) {
            getFilters();
        } else if (filterSelection) {
            getFilterResults(filterSelection);
        }
    }
}

function setFilterSelection(data, topFunds) {
    store.dispatch({ type: SET_FILTER_SELECTION, data });

    const { searchString } = store.getState().fundExplorer;
    const filterPayload = getFilterPayload(data, searchString, topFunds);

    if (filterPayload) {
        getFilterResults(filterPayload);
    } else {
        if (searchString && searchString.value) {
            getSearchResults(searchString.value);
        } else {
            // debugger
        }
    }
    // if (data && data.value.length > 2) {
    //     const { showFilters } = store.getState().fundExplorer;
    //     if (showFilters) {
    //         getFilters();
    //     } else {
    //         if (isSubmitted) {
    //             getSearchResults(data.value);
    //         } else {
    //             getAutoComplete(data.value);
    //         }
    //     }
    // }
}

function getAutoComplete(key) {
    onRequest();

    FundExplorerService.getSearchAutoComplete(key).then(response => {
        onSuccess(response);
    })

    function onRequest() { store.dispatch({ type: GET_AUTO_COMPLETE }); }
    function onSuccess(data) { store.dispatch({ type: GET_AUTO_COMPLETE_SUCCESS, data }); }
    function onFailure() { store.dispatch({ type: GET_AUTO_COMPLETE_FAILURE }); }
}

function getSearchResults(key, searchByCode) {
    onRequest();

    if (searchByCode) {
        FundExplorerService.getFundByCode(key).then(response => { onSuccess(response); })
    } else {
        FundExplorerService.getFundByText(key).then(response => { onSuccess(response); })
    }

    function onRequest() { store.dispatch({ type: GET_SEARCH_RESULTS }); }
    function onSuccess(data) { store.dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, data }); }
    function onFailure() { store.dispatch({ type: GET_SEARCH_RESULTS_FAILURE }); }
}

function getFilters(key) {
    onRequest();
    FundExplorerService.getFundFilters(key).then(response => { onSuccess(response); })

    function onRequest() { store.dispatch({ type: GET_FILTERS }); }
    function onSuccess(data) { store.dispatch({ type: GET_FILTERS_SUCCESS, data }); }
    function onFailure() { store.dispatch({ type: GET_FILTERS_FAILURE }); }
}

function getFilterResults(payload) {
    onRequest();
    FundExplorerService.getFundByFilters(payload).then(response => { onSuccess(response); })

    function onRequest() { store.dispatch({ type: GET_FILTER_RESULTS, data: payload ? payload[0] : {} }); }
    function onSuccess(data) { store.dispatch({ type: GET_FILTER_RESULTS_SUCCESS, data }); }
    function onFailure() { store.dispatch({ type: GET_FILTER_RESULTS_FAILURE }); }
}

function getFilterPayload(filter, search, topFunds) {
    if (filter) {
        const {
            amc,
            category,
            returns,
            age,
            fundSize,
            risk,
            ratings,
            options
        } = filter;
        if (
            (
                (amc && amc.length > 0) ||
                (category && category.length > 0) ||
                (returns && returns.length > 0) ||
                (age && age.length > 0) ||
                (fundSize && fundSize.length > 0) ||
                (risk && risk.length > 0) ||
                (ratings && ratings.length > 0) ||
                (options && options.length > 0) ||
                topFunds
                // ||
                // (search && search.value)
            )
        ) {
            // debugger
            const payload = [
                {
                    amccode: amc && amc.length > 0 ? amc : undefined,
                    category: category && category.length > 0 ? category : undefined,
                    text: search ? search.value : undefined,
                    returns: returns && returns.length > 0 ? returns : undefined,
                    age: age && age.length > 0 ? age : undefined,
                    fundSize: fundSize && fundSize.length > 0 ? fundSize : undefined,
                    risk: risk && risk.length > 0 ? risk : undefined,
                    ratings: ratings && ratings.length > 0 ? ratings : undefined,
                    // options: options,
                    topFunds: topFunds || undefined,
                }
            ];
            return payload;
        }
    } else if (topFunds) {
        return [{ topFunds }];
    }
    return;
}

function toggleFilters(data) {
    store.dispatch({ type: TOGGLE_FILTERS, data });
    if (data) {
        getFilters();
    }
}

function toggleSearchDialog(data) {
    store.dispatch({ type: TOGGLE_SEARCH_DIALOG, data });
}
//ACTION CREATORS

//Reducer
export function fundExplorerReducer(state = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case SET_SEARCH_STRING:
            return { ...state, searchString: data, autoCompleteResults: null };

        case SET_FILTER_SELECTION:
            return { ...state, filterSelection: data, showFilters: false };

        case TOGGLE_FILTERS:
            return { ...state, showFilters: data, autoCompleteResults: !data ? state.autoCompleteResults : null };

        case TOGGLE_SEARCH_DIALOG:
            return { ...state, searchDialog: data };

        case GET_DEFAULT:
            return { ...state, ...fetchingFalse, fetchingDefault: true };
        case GET_DEFAULT_SUCCESS:
            return { ...state, fetchingDefault: false, defaultData: data };
        case GET_DEFAULT_FAILURE:
            return { ...state, fetchingDefault: false, defaultData: [] };

        case GET_AUTO_COMPLETE:
            return { ...state, ...fetchingFalse, fetchingAutoComplete: true, autoCompleteResults: null, searchResults: null };
        case GET_AUTO_COMPLETE_SUCCESS:
            return {
                ...state,
                fetchingAutoComplete: false,
                autoCompleteResults: state.fetchingAutoComplete ? data : null
            };
        case GET_AUTO_COMPLETE_FAILURE:
            return { ...state, fetchingAutoComplete: false, autoCompleteResults: [] };

        case GET_SEARCH_RESULTS:
            return { ...state, ...fetchingFalse, fetchingSearch: true, autoCompleteResults: null, searchResults: null };
        case GET_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                fetchingSearch: false,
                searchResults: state.fetchingSearch ? data : null
            };
        case GET_SEARCH_RESULTS_FAILURE:
            return { ...state, fetchingSearch: false, searchResults: [] };

        case GET_FILTERS:
            return { ...state, ...fetchingFalse, fetchingFilters: true, filterResults: null };
        case GET_FILTERS_SUCCESS:
            return {
                ...state,
                fetchingFilters: false,
                filterResults: state.fetchingFilters ? data : null
            };
        case GET_FILTERS_FAILURE:
            return { ...state, fetchingFilters: false, filterResults: [] };

        case GET_FILTER_RESULTS:
            return { ...state, ...fetchingFalse, fetchingFilterData: true, filterResultsData: null, searchCriteria: data };
        case GET_FILTER_RESULTS_SUCCESS:
            return {
                ...state,
                fetchingFilterData: false,
                filterResultsData: state.fetchingFilterData ? data : null
            };
        case GET_FILTER_RESULTS_FAILURE:
            return { ...state, fetchingFilterData: false, filterResultsData: [] };

        default:
            return state;
    }
}

const fetchingFalse = {
    fetchingDefault: false,
    fetchingAutoComplete: false,
    fetchingSearch: false,
    fetchingFilters: false,
    fetchingFilterData: false,
};

const initialState = {
    showSearch: false,
    showFilters: false,
    ...fetchingFalse,
    fetchingDefault: true,
    defaultData: null,
    autoCompleteResults: null,
    searchCriteria: null,
};
