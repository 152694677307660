import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

const styles = theme => ({
    header: {
        backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
        padding: '0px',
        height: '25px',
        backgroundColor: theme.palette.background.default, //'#292e3e'
        position: 'absolute',
        left: 0,
        right: 0,
        overflow: 'hidden',
        borderBottom: '1px solid #cccccc24',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        fontSize: 15,
        marginLeft: theme.spacing(1),
        color: theme.palette.danger.main,
        fontWeight: 600
    },
    content: {
        backgroundColor: theme.palette.color17.default,
        marginTop: '25px'
    },
    message: {
        width: '100%',
        textAlign: 'center',
        fontSize: 18,
        color: theme.palette.text.primary,
        paddingTop: 25,
        paddingBottom: 25,
    }
});

const NO_NET_DELAY = 5000;

function NoInternetDialog(props) {
    const { classes } = props;
    const [connected, setConnected] = useState(true);

    let timer = null;

    useEffect(() => {
        window.onoffline = e => {
            timer = setTimeout(() => {
                setConnected(false);
            }, NO_NET_DELAY);
        };

        window.ononline = e => {
            setConnected(true);
            clearTimeout(timer);
        };
    }, []);

    return (
        <Dialog
            aria-labelledby="Edit"
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            open={!connected}
        // open
        >
            <DialogTitle
                className={classes.header}
                id="no-internet">
                <Typography className={classes.title}>
                    No Internet Connection
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography className={classes.message}>
                    Please check your network connection and try again!!!
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(styles)(NoInternetDialog);
