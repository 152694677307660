import styled from "styled-components";

export default styled.text`
    flex: 1;
    font-size: 22px;
    color: white;
    white-space: break-spaces;
    line-height: 22px;
    align-self: flex-end;
    padding: 0 10px;
`;
