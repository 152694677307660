//import config from '../../config';
import axios from 'helpers/interceptor';


export const profileService = {
    getProfile
};

function getProfile() {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-trading-customer-profile/loggedinuser/profile`,
    };

   return axios(requestOptions)
     .then(profile => {
        //console.log(profile.data.success)
        if(profile && profile.data && profile.data.success) {
          return profile.data.success;
        } else {
          return {}
        }
        
    })
      .catch(function (error) {
        console.log(error);
        return {}
      })

      
}


