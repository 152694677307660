export default ({ palette }) => ({
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      color: palette.text.secondary,
      '&:hover': {
        backgroundColor: palette.common.neutral
      }
    }
  }
});
