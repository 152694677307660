import { Check } from '@mui/icons-material';
import { Grid, Step, StepLabel, Stepper, Typography, FormControl } from "@mui/material";
import { withStyles } from '@mui/styles';
import ConfirmationDialog from "components/ConfirmationDialog";
import Placeholder from "components/Placeholder";
import axios from "helpers/interceptor";
import { UserProfileContext } from "Main";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { closeConfirmDialog, openConfirmDialog } from "redux/ConfirmDialog";
import { feedService } from "services/feed";
import { MODAL_CHILD } from "widgets/FormDialog";
import { openDialog } from "redux/Dialog";
import { ConfirmEquityOrder, ConfirmMfOrder } from "./ConfirmOrder";
import { api, CssOutlinedInput, productName, QontoConnector, stepperTabs, TabPanel } from "./data";
import { EquityOrder, EquitySIP, MfOrder, MfSIP } from "./OrderForm";
import OrderSuccess from "./OrderSuccess";
import styles from "./styles";

let tabs = []

function BulkOrder(props) {
  const { classes, reduxState, onCancel, openAddMandateDialog } = props;
  const [formData, setFormData] = useState("Loading...")
  const [formValues, setFormValues] = useState({})
  const [activeStep, setActiveStep] = useState({ formStep: 0, step: 0, name: "", completed: false })
  const [errorObj, setErrorObj] = useState({ load: true, error: false, submitting: false, message: "Loading...", pushOrder: true })
  const [response, setResponse] = useState({})
  const [lot, setLot] = useState(reduxState?.lot || 1)

  const profile = useContext(UserProfileContext)

  useEffect(() => {
    apiCalls()
    return (() => {
      tabs = []
    })
  }, [])

  function fetchGoalId(payload) {
    if (payload) {
      return axios.post(`${process.env.REACT_APP_API_URL}${api.goal[(formData && formData.productDetails && formData.productDetails.product_name) || ""]}`, payload).then(resp => {
        if (resp.data.success) {
          let data = resp.data.success
          if (formData?.productDetails?.product_name === "buckets" || formData?.productDetails?.product_name === "baskets") {
            data = {
              goalId: Object.keys(resp.data.success)[0]
            }
          }
          return data
        } else {
          setTimeout(() => {
            setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })
          }, 15000);
          setErrorObj({ load: false, error: true, submitting: false, message: resp.data.error || "", pushOrder: true })
          setActiveStep({ formStep: 0, step: 0, name: tabs[0], completed: false })
        }
      }, (error) => {
        setTimeout(() => {
          setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })
        }, 15000);
        setErrorObj({ load: false, error: true, submitting: false, message: error.message || "", pushOrder: true })
        setActiveStep({ formStep: 0, step: 0, name: tabs[0], completed: false })
      });
    } else {
      setTimeout(() => {
        setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })
      }, 15000);
      setErrorObj({ load: false, error: true, submitting: false, message: "Goal id payload not found", pushOrder: true })
      setActiveStep({ formStep: 0, step: 0, name: tabs[0], completed: false })
    }
  }

  function fetchFeed(payload) {
    return feedService.getFullFeed(payload);
    // return new Promise(function (resolve, reject) {
    //   axios.post(`${process.env.REACT_APP_API_FEED_URL}/ms-feed-service/content/market/feed`, payload).then(resp => {
    //     if (resp.data.success && resp.data.success.length > 0) {
    //       resolve(resp.data.success)
    //     } else {
    //       resolve([])
    //     }
    //   }, (error) => {
    //     reject(error);
    //   });
    // });
  }

  async function getAccountAndMandateData() {
    try {
      let accountDetails = await axios.get(`${process.env.REACT_APP_API_URL}${api.accountDetails}`)
      return accountDetails.data || { data: {} }
    } catch (error) {
      console.log(error)
      return;
    }
  }

  const checkToHitAccountDetailsApi = async (productDataKeys, data) => {
    if (productDataKeys.includes("BuyMF") || productDataKeys.includes("SellMF") || productDataKeys.includes("BuyMFSIP")) {
      let details = await getAccountAndMandateData()
      if (details.success) {
        setFormData({ ...data, accountDetails: details.success.accountDetails || [], mandate: details.success.mandate || [] })
      } else {
        setFormData(data)
      }
      setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })
    } else {
      setFormData(data)
      setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })
    }
  }

  const modifyScripData = async (productData, data) => {
    let productDataKeys = Object.keys(productData);
    productDataKeys.map((ele) => {
      if (ele === "BuyMFSIP") {
        let obj = {}
        productData && productData.productDetails && productData.productDetails.mfSipData &&
          productData.productDetails.mfSipData.length > 0 && productData.productDetails.mfSipData.map((ele) => {
            obj[ele.frequency] = ele
          })
        if (Array.isArray(productData.productDetails.mfSipData)) {
          data.productDetails.mfSipData = obj
        }
        productData[ele].map((item, index) => {
          let freqObj = {}
          if (item.sip && item.sip.length > 0) {
            item.sip.map((freq) => {
              freqObj[freq.frequency] = freq
            })
          }
          if (Array.isArray(item.sip)) {
            data[ele][index].sip = freqObj
          }
        })
      } else if (ele === "BuyEQSIP") {
        let obj = {}
        productData && productData.productDetails && productData.productDetails.eqSipData &&
          productData.productDetails.eqSipData.length > 0 && productData.productDetails.eqSipData.map((ele) => {
            obj[ele.frequency] = ele
          })
        if (Array.isArray(productData.productDetails.eqSipData)) {
          data.productDetails.eqSipData = obj
        }
      }
    })
    checkToHitAccountDetailsApi(productDataKeys, data)
  }

  const getScripData = (keys, productData) => {
    let data = { ...productData }
    if (keys.length > 0) {
      let arr = [];
      keys.map((ele) => {
        let payload = []
        productData[ele].map((item) => {
          payload.push({
            symbol: item.symbol,
            segment: "EQ",
            exchange: item.exchange,
            token: item.token
          })
        })
        let scripData = fetchFeed(payload)
        arr.push(scripData)
      })
      Promise.all(arr).then(response => {
        keys.map((ele, keyIndex) => {
          let arr = []
          productData[ele].map((ele, index) => {
            let scrip = response[keyIndex].find(scp => (scp.symbol === ele.symbol && scp.exchange === ele.exchange))
            if (scrip) {
              arr.push({
                ...ele,
                scripId: scrip.scripId,
                shortCode: scrip.shortCode
              })
            } else {
              arr.push({
                ...ele,
                scripId: "",
                shortCode: ""
              })
            }
          })
          data[ele] = arr
        })
        modifyScripData(productData, data)
      })
    } else {
      modifyScripData(productData, data)
    }
  }

  function getFeedData(productData) {
    let feedKeys = []
    Object.keys(productData).map((ele) => {
      if (ele !== "productDetails" && productData[ele].length > 0) {
        tabs.push(ele)
        if (productData[ele] && productData[ele].length > 0 && (ele === "BuyEQ" || ele === "SellEQ" || ele === "BuyEQSIP")) {
          feedKeys.push(ele)
        }
      }
    })
    getScripData(feedKeys, productData)
    setActiveStep({ formStep: 0, step: 0, name: tabs[0], completed: false })
  }

  function getTargetData() {
    axios.post(`${process.env.REACT_APP_API_URL}${api.data[reduxState.product_name]}`, reduxState.data || {}).then(resp => {
      if (resp.data.success) {
        let data = resp.data.success.recommendationsV2 || ""
        let obj = {
          productDetails: { ...reduxState }
        }
        if (data && data.Equity && data.Equity.initial && data.Equity.initial.length > 0) {
          let arr = []
          data.Equity.initial.forEach(ele => {
            arr.push({
              stock_name: ele.stockName || "",
              symbol: ele.NSEcode || "",
              exchange: ele.default_exchange || "",
              segment: ele.segmentName || "",
              stock_price: ele.currentPrice || 1,
              min_qty: ele.initialQty || 1,
              token: ele.NSEToken || ""
            })
          })
          obj["BuyEQ"] = arr
        }
        if (data && data.MutualFunds && data.MutualFunds.initial && data.MutualFunds.initial.length > 0) {
          let arr = []
          data.MutualFunds.initial.forEach(ele => {
            arr.push({
              fund_name: ele.stockName || "",
              symbol: ele.NSEcode || "",
              nav_price: ele.currentPrice || 1,
              free_units: "",
              current_price: ele.initailAmount || 1,
              schemeCode: (ele.BSEStarDMATCodes && ele.BSEStarDMATCodes.length > 0 && ele.BSEStarDMATCodes[0]) || "",
              isinNumber: ele.ISIN || "",
              minAmount: (ele.minimumAmount && ele.minimumAmount.oneTime && ele.minimumAmount.oneTime.initial) || 1,
              maxAmount: ele.maximumAmount || 1,
              settlementDate: ele.settlementDate || 0
            })
          })
          obj["BuyMF"] = arr
        }
        if (data && data.Equity && data.Equity.SIP && data.Equity.SIP.length > 0) {
          let arr = []
          data.Equity.SIP.forEach((ele, index) => {
            if (index === 0) {
              obj.productDetails["eqSipData"] = []
              obj.productDetails["eqSipData"].push({
                frequency: "Monthly",
                mandate: (resp.data.success.bankMandate && resp.data.success.bankMandate.length > 0 && resp.data.success.bankMandate) || [],
                sipStartDate: data.Equity.frequencyList || [],
                installments: resp.data.success.numberOfInstallments || 0
              })
            }
            arr.push({
              stock_name: ele.stockName || "",
              symbol: ele.NSEcode || "",
              exchange: ele.default_exchange || "",
              segment: ele.segmentName || "",
              stock_price: ele.currentPrice || 1,
              min_qty: ele.sipQty || 1,
              token: ele.NSEToken || ""
            })
          })
          obj["BuyEQSIP"] = arr
        }
        if (data && data.MutualFunds && data.MutualFunds.SIP && data.MutualFunds.SIP.length > 0) {
          let arr = []
          data.MutualFunds.SIP.forEach((ele, index) => {
            let sipArr = [];
            if (ele.sipStartDateData && ele.sipStartDateData.length > 0) {
              ele.sipStartDateData.forEach(item => {
                if (item.frequencyList && item.frequencyList.length > 0) {
                  sipArr.push({
                    frequency: item.Frequency,
                    sipStartDate: item.frequencyList,
                    minAmount: item.MinAmount
                  })
                  if (index === 0) {
                    obj.productDetails["mfSipData"] = []
                    obj.productDetails["mfSipData"].push({
                      mandate: (resp.data.success.bankMandate && resp.data.success.bankMandate.length > 0 && resp.data.success.bankMandate) || [],
                      installments: resp.data.success.numberOfInstallments || 0,
                      frequency: item.Frequency
                    })
                  }
                }
              })
            }
            arr.push({
              fund_name: ele.stockName || "",
              symbol: ele.NSEcode || "",
              nav_price: ele.currentPrice || 1,
              free_units: "",
              current_price: ele.sipAmount || 1,
              schemeCode: (ele.BSEStarDMATCodes && ele.BSEStarDMATCodes.length > 0 && ele.BSEStarDMATCodes[0]) || "",
              isinNumber: ele.ISIN || "",
              sip: sipArr.length > 0 ? sipArr : []
            })
          })
          obj["BuyMFSIP"] = arr
        }
        if (Object.keys(obj).length > 1) {
          getFeedData(obj)
        } else {
          setErrorObj({ load: true, error: false, submitting: false, message: "No Products Found", pushOrder: true })
        }
      } else {
        setErrorObj({ load: true, error: false, submitting: false, message: resp.data.error || "", pushOrder: true })
      }
    }).catch(error => {
      setErrorObj({ load: true, error: false, submitting: false, message: error.message || "", pushOrder: true })
    })
  }

  function getBucketData() {
    axios.get(`${process.env.REACT_APP_API_URL}${api.data[reduxState.product_name]}/${(reduxState.data && reduxState.data.bucketId) || 0}`).then(resp => {
      if (resp.data.success) {
        let obj = {
          productDetails: { ...reduxState }
        }
        if (resp.data.success.constituents && resp.data.success.constituents.eq && resp.data.success.constituents.eq.length > 0) {
          let arr = []
          resp.data.success.constituents.eq.forEach(ele => {
            arr.push({
              stock_name: ele.stockName || "",
              symbol: ele.NSEcode || "",
              exchange: ele.default_exchange || "",
              segment: ele.segmentName || "",
              stock_price: ele.currentPrice || 1,
              min_qty: ele.minInvestmentQuantity || 1,
              token: ele.NSEToken || ""
            })
          })
          obj["BuyEQ"] = arr
        }
        if (resp.data.success.constituents && resp.data.success.constituents.eq && resp.data.success.constituents.mf.length > 0) {
          let arr = []
          resp.data.success.constituents.mf.forEach(ele => {
            arr.push({
              fund_name: ele.stockName || "",
              symbol: ele.NSEcode || "",
              nav_price: ele.currentPrice || 1,
              free_units: "",
              current_price: ele.minInvestmentAmount || 1,
              schemeCode: (ele.BSEStarDMATCodes && ele.BSEStarDMATCodes.length > 0 && ele.BSEStarDMATCodes[0]) || "",
              isinNumber: ele.ISIN || "",
              minAmount: (ele.minimumAmount && ele.minimumAmount.oneTime && ele.minimumAmount.oneTime.initial) || 1,
              maxAmount: ele.maximumAmount || 1,
              settlementDate: ele.settlementDate || 0
            })
          })
          obj["BuyMF"] = arr
        }
        if (Object.keys(obj).length > 1) {
          getFeedData(obj)
        } else {
          setErrorObj({ load: true, error: false, submitting: false, message: "No Products Found", pushOrder: true })
        }
      } else {
        setErrorObj({ load: true, error: false, submitting: false, message: resp.data.error || "", pushOrder: true })
      }
    }).catch(error => {
      setErrorObj({ load: true, error: false, submitting: false, message: error.message || "", pushOrder: true })
    })
  }

  function getOptimizerData() {
    axios.post(`${process.env.REACT_APP_API_URL}${api.data[reduxState.product_name]}`, reduxState.data || {}).then(resp => {
      if (resp.data.success) {
        let obj = {
          productDetails: { ...reduxState }
        }
        if (resp.data.success.stockActions && resp.data.success.stockActions.length > 0) {
          let arr = []
          resp.data.success.stockActions.forEach(ele => {
            arr.push({
              stock_name: (ele.stockData && ele.stockData.stockName) || "",
              symbol: (ele.stockData && ele.stockData.NSEcode) || "",
              exchange: (ele.stockData && ele.stockData.default_exchange) || "",
              segment: (ele.stockData && ele.stockData.segmentName) || "",
              stock_price: (ele.stockData && ele.stockData.currentPrice) || 1,
              min_qty: ele.totalQty || 1,
              token: ele.NSEToken || ""
            })
          })
          obj["SellEQ"] = arr
        }
        if (resp.data.success.mfActions && resp.data.success.mfActions.length > 0) {
          let arr = []
          resp.data.success.mfActions.forEach(ele => {
            arr.push({
              fund_name: (ele.mfData && ele.mfData.stockName) || "",
              symbol: (ele.mfData && ele.mfData.NSEcode) || "",
              nav_price: ele.mfData.currentPrice,
              free_units: ele.totalQty,
              current_price: (ele.mfData && ele.mfData.currentPrice && ele.totalQty * ele.mfData.currentPrice) || 1,
              schemeCode: (ele.mfData && ele.mfData.BSEStarDMATCodes && ele.mfData.BSEStarDMATCodes.length > 0 && ele.mfData.BSEStarDMATCodes[0]) || "",
              isinNumber: ele.ISIN || "",
              minAmount: (ele.mfData && ele.mfData.MinimumRedemptionAmount) || 0,
              maxAmount: ele.totalQty * ele.mfData.currentPrice || 1,
              minimumRedeemUnits: (ele.mfData && ele.mfData.BSEStarDMAT && Object.keys(ele.mfData.BSEStarDMAT).length > 0 && ele.mfData.BSEStarDMAT[Object.keys(ele.mfData.BSEStarDMAT)[0].MinimumRedemptionQty]) || 0,
            })
          })
          obj["SellMF"] = arr
        }
        if (resp.data.success.recommendations && resp.data.success.recommendations.initial && resp.data.success.recommendations.initial.length > 0) {
          let equityArr = [], mfArr = [];
          resp.data.success.recommendations.initial.forEach(ele => {
            if (ele.default_exchange === "NSE") {
              equityArr.push({
                stock_name: ele.stockName || "",
                symbol: ele.NSEcode || "",
                exchange: ele.default_exchange || "",
                segment: ele.segmentName || "",
                stock_price: ele.currentPrice || 1,
                min_qty: ele.initialQty || 1,
                token: ele.NSEToken || ""
              })
            } else {
              mfArr.push({
                fund_name: ele.stockName || "",
                symbol: ele.NSEcode || "",
                nav_price: ele.currentPrice || 1,
                free_units: "",
                current_price: ele.initailAmount,
                schemeCode: (ele.BSEStarDMATCodes && ele.BSEStarDMATCodes.length > 0 && ele.BSEStarDMATCodes[0]) || "",
                isinNumber: ele.ISIN || "",
                minAmount: (ele.minimumAmount && ele.minimumAmount.oneTime && ele.minimumAmount.oneTime.initial) || 1,
                maxAmount: ele.maximumAmount || 1,
                settlementDate: ele.settlementDate || 0
              })
            }
          })
          if (equityArr.length > 0) {
            obj["BuyEQ"] = equityArr
          }
          if (mfArr.length > 0) {
            obj["BuyMF"] = mfArr
          }
        }
        if (Object.keys(obj).length > 1) {
          getFeedData(obj)
        } else {
          setErrorObj({ load: true, error: false, submitting: false, message: "No Products Found", pushOrder: true })
        }
      } else {
        setErrorObj({ load: true, error: false, submitting: false, message: resp.data.error || "", pushOrder: true })
      }
    }).catch(error => {
      setErrorObj({ load: true, error: false, submitting: false, message: error.message || "", pushOrder: true })
    })
  }

  function apiCalls() {
    if (reduxState && reduxState.product_name) {
      if (reduxState.portfolio) {
        let data = { ...reduxState }
        delete data.product_name
        Object.keys(data).forEach((ele) => {
          if (ele !== "productDetails" && ele !== "portfolio" && data[ele].length > 0) {
            tabs.push(ele)
          }
        })
        setActiveStep({ formStep: 0, step: 0, name: tabs[0], completed: false })
        checkToHitAccountDetailsApi(tabs, data)
      } else if (reduxState.product_name === "target") {
        getTargetData()
      } else if (reduxState.product_name === "buckets" || reduxState.product_name === "baskets") {
        getBucketData()
      } else if (reduxState.product_name === "optimizer") {
        getOptimizerData()
      } else {
        setErrorObj({ load: true, error: false, submitting: false, message: "Product api does not exist", pushOrder: true })
      }
    } else {
      setErrorObj({ load: true, error: false, submitting: false, message: "Invalid Product Name", pushOrder: true })
    }
  }

  const QontoStepIcon = (stepProps) => {
    const { completed, icon } = stepProps;
    return (
      <div className={classes.tabDiv}>
        <div className={classes.tabTitleDiv}>
          {completed ? <Check className={classes.checkIcon} /> : <div className={[classes.square, activeStep.step === (icon - 1) ? "active" : ""].join(" ")} />}
          <p className={[classes.tabText, activeStep.step === (icon - 1) ? "active" : completed ? "completed" : ""].join(" ")}>{formData && formData.productDetails && formData.productDetails.product_name && stepperTabs[formData.productDetails.product_name][tabs[icon - 1]].tabLabel}</p>
        </div>
        <div className={[classes.tabBorder, activeStep.step === (icon - 1) ? "active" : completed ? "completed" : ""].join(" ")} />
      </div>
    )
  }

  const steps = () => {
    return (
      tabs.length > 0 ?
        <Grid item xs={12} style={{ width: "100%" }}>
          <Stepper
            activeStep={activeStep.step}
            connector={<QontoConnector />}
            className={classes.quontoStepper}
          >
            {tabs.map((label, index) => (
              <Step key={label} style={{ padding: 0 }}>
                <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        :
        <></>
    )
  }

  const TopInfo = () => {
    return (
      <Grid container className={classes.grid} style={{ marginBottom: "0px", paddingBottom: "0px" }}>
        <Grid item xs={6} className={classes.productTitle}>
          <Typography variant="h6" className={classes.blueText}>{(formData && formData.productDetails && formData.productDetails.product_name && formData.productDetails.product_name && productName[formData.productDetails.product_name]) || "--"}</Typography>
          <Typography variant="h5" style={{ marginLeft: "8px" }} className={classes.primaryText}>{(formData && formData.productDetails && formData.productDetails.name) || "--"}</Typography>
        </Grid>
        {(reduxState?.product_name === 'buckets' || reduxState?.product_name === 'baskets') &&
          (activeStep?.name === 'BuyEQ' || activeStep?.name === 'BuyMF') &&
          <Grid item xs={6} className={classes.productTitle}>
            <FormControl variant="outlined" className={classes.fieldDiv}>
              <Typography className={classes.fieldText} style={{ paddingRight: 8 }}>Lot</Typography>
              <div>
                <CssOutlinedInput
                  id="lot"
                  name="lot"
                  classes={{ input: classes.input }}
                  onChange={(e) => setLot(e.target.value)}
                  autoFocus={true}
                  error={Number(lot) ? false : true}
                  value={lot}
                  defaultValue={1}
                  helperText={'Lot is required'}
                  onKeyUp={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  disabled={activeStep?.step > 0}
                />
                <p className={classes.errorText}>
                  {
                    lot < 1 ? 'Minimum lot is 1' : String(lot).includes(".") ?
                      'Decimal is not allowed' : ''
                  }
                </p>
              </div>
            </FormControl>
          </Grid>}
        {steps()}
      </Grid>
    )
  }

  const handleFormValues = (data) => {
    let obj = { ...formValues }
    obj[stepperTabs[formData.productDetails.product_name][tabs[activeStep.step]].payloadKey] = data
    setFormValues(obj)
    if (activeStep.step + 1 === tabs.length) {
      let totalAmount = 0;
      Object.keys(obj).forEach(ele => {
        totalAmount = totalAmount + Number(obj[ele].sumOf)
      })
      if (totalAmount > 0) {
        setActiveStep({ formStep: 1, step: 0, name: tabs[0], completed: false })
      } else {
        setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: false })
      }
    } else {
      setActiveStep({ formStep: 0, step: activeStep.step + 1, name: tabs[activeStep.step + 1], completed: true })
    }
  }

  const handleBack = () => {
    if (activeStep.step === 0 && activeStep.formStep === 1) {
      setActiveStep({ formStep: 0, step: 0, name: tabs[0], completed: false })
    } else {
      setActiveStep({ formStep: activeStep.formStep, step: activeStep.step - 1, name: tabs[activeStep.step - 1], completed: false })
    }
  }

  const handleStep = () => {
    if (activeStep.step === tabs.length - 1 && activeStep.formStep === 1) {
      confirmOrder()
    } else {
      setActiveStep({ formStep: activeStep.formStep, step: activeStep.step + 1, name: tabs[activeStep.step + 1], completed: true })
    }
  }

  function pushApi(url, payload) {
    return new Promise(function (resolve, reject) {
      axios.post(`${process.env.REACT_APP_API_URL}${url}`, payload).then(resp => {
        if (resp.data) {
          resolve(resp.data)
        }
      }, (error) => {
        resolve({ error: (error && error.response && error.response.data && error.response.data.message) || error.message });
      });
    });
  }

  const getPayload = (product, dpAccNo, hasProduct, goalId) => {
    let values = {}
    /* Getting values only with quantity or amount more than zero */
    Object.keys(formValues).forEach(ele => {
      let orderData = { ...formValues[ele] }
      orderData.data = []
      formValues[ele].data.forEach(item => {
        if (ele === "equity" || ele === "sellEq" || ele === "equitySIP") {
          if (item.quantity > 0) {
            orderData.data.push(item)
          }
        } else {
          if (item.amount > 0) {
            orderData.data.push(item)
          }
        }
      })
      values[ele] = orderData
    })
    let payload = {}, response = {};
    /* Checking whether to hit eqmf bulk push api */
    let findEquity = Object.keys(values).find((ele) => ((ele !== "equitySIP" && ele !== "mfSIP") && values[ele].data && values[ele].data.length > 0))
    if (findEquity) {
      payload = { eqMf: {} }
    }
    /* Modifying payload based on each order key */
    Object.keys(values).forEach((ele) => {
      if (values[ele].data && values[ele].data.length > 0) {
        if (ele === "mfSIP") {
          payload[ele] = {}
          payload[ele] = {
            goalId: goalId || "",
            product: product || "",
            frequency: values[ele].frequency,
            bankMandate: values[ele].bankMandate || "",
            installmentNo: values[ele].installments,
            goalName: reduxState && reduxState.goal && reduxState.goal.name || "",
            orderSIPDetails: []
          }
          response["BuyMFSIP"] = {
            totalCount: values[ele].data.length,
            label: stepperTabs[reduxState.product_name]["BuyMFSIP"].tabLabel,
            type: "Buy"
          }
        } else if (ele === "equitySIP") {
          payload[ele] = {}
          payload[ele] = {
            noOfInstall: values[ele].installments,
            frequency: values[ele].frequency,
            startDate: values[ele].startDate,
            goalId: goalId || "",
            productName: product || "",
            goalName: reduxState && reduxState.goal && reduxState.goal.name || "",
            orders: []
          }
          response["BuyEQSIP"] = {
            totalCount: values[ele].data.length,
            label: stepperTabs[reduxState.product_name]["BuyEQSIP"].tabLabel,
            type: "Buy"
          }
        } else if (ele === "equity" || ele === "sellEq") {
          if (!payload.eqMf["equity"]) {
            payload.eqMf["equity"] = []
          }
          if(reduxState?.clientCode) {
            payload.eqMf["userId"] = reduxState.clientCode || ''
          }
          response[ele === "equity" ? "BuyEQ" : "SellEQ"] = {
            totalCount: values[ele].data.length,
            label: stepperTabs[reduxState.product_name][ele === "equity" ? "BuyEQ" : "SellEQ"].tabLabel,
            type: ele === "equity" ? "Buy" : "Sell"
          }
        } else if (ele === "mfLumpsum" || ele === "sellMf") {
          if (!payload.eqMf["mfLumpsum"]) {
            payload.eqMf["mfLumpsum"] = []
          }
          if (!payload.eqMf["accountDetails"]) {
            payload.eqMf["accountDetails"] = {
              logOutURL: "",
              accountNumber: values[ele].accountDetails,
              ifscCode: values[ele].ifscCode,
              paymentMode: values[ele].paymentMode,
              bankID: values[ele].bankId
            }
          }
          response[ele === "mfLumpsum" ? "BuyMF" : "SellMF"] = {
            totalCount: values[ele].data.length,
            label: stepperTabs[reduxState.product_name][ele === "mfLumpsum" ? "BuyMF" : "SellMF"].tabLabel,
            type: ele === "mfLumpsum" ? "Buy" : "Sell"
          }
        }
        values[ele].data.forEach((item) => {
          let obj = {}
          if (ele === "equity" || ele === "sellEq") {
            obj = {
              ex: item.shortCode,
              sId: item.scripId,
              dpAccNo: dpAccNo,
              buySell: values[ele].action === "Buy" ? "B" : "S",
              qty: item.quantity,
              price: 0,
              type: "MKT",
              disQty: 0,
              tPrice: 0,
              val: values[ele].validTill,
              pId: values[ele].productType,
              requestStatus: "New",
              goalId: goalId,
              productName: product || "",
              scripName: item.symbol
            }
            payload.eqMf["equity"].push(obj)
          } else if (ele === "mfLumpsum" || ele === "sellMf") {
            obj = {
              ex: "BMF",
              orderAmount: ele === "sellMf" ? item.units : item.amount,
              schemeCode: item.schemeCode,
              goalId: goalId,
              buySell: values[ele].action === "Buy" ? "B" : "S",
              productName: product || ""
            }
            payload.eqMf["mfLumpsum"].push(obj)
          } else if (ele === "equitySIP") {
            obj = {
              scripId: item.scripId,
              shortCode: item.shortCode,
              sipAmount: (item.approxValue && Number(item.approxValue).toFixed(2)) || 0,
            }
            payload[ele].orders.push(obj)
          } else if (ele === "mfSIP") {
            obj = {
              amount: item.amount,
              schemeCode: item.schemeCode,
              startDate: item.startDate
            }
            payload[ele].orderSIPDetails.push(obj)
          }
        })
      }
    })
    return { payload, response }
  }

  const modifyPayload = async () => {
    let goalId = ""
    let product = (formData && formData.productDetails && formData.productDetails.product_name) || "";
    let dpAccNo = (profile && profile.dematAcc) || "";
    let hasProduct = (product === "target" || product === "buckets" || product === "baskets") ? true : false
    /* Fetching goalId */
    if (hasProduct) {
      if (formData.portfolio) {
        goalId = (formData && formData.productDetails && formData.productDetails.id) || "";
        return getPayload(product, dpAccNo, hasProduct, goalId)
      } else {
        let goalData = await fetchGoalId(formData.productDetails.goal)
        if (goalData && goalData.goalId) {
          goalId = goalData.goalId || ""
          return getPayload(product, dpAccNo, hasProduct, goalId)
        } else {
          return null
        }
      }
    } else {
      return getPayload(product, dpAccNo, hasProduct, goalId)
    }
  }

  const confirmOrder = async () => {
    setErrorObj({ load: false, error: false, submitting: true, message: "", pushOrder: true })
    let data = await modifyPayload()
    if (data.payload) {
      let apiPayloadArray = []
      Object.keys(data.payload).map((ele) => {
        let submitData = pushApi(api.submit[ele], data.payload[ele])
        apiPayloadArray.push(submitData)
      })
      Promise.all(apiPayloadArray).then(resp => {
        let respData = {}
        Object.keys(data.payload).map((ele, index) => {
          respData[ele] = resp[index] || {}
        })
        Object.keys(data.response).map((ele) => {
          if (ele === "BuyEQ" || ele === "SellEQ") {
            data.response[ele].count = (respData.eqMf.EQResponse && respData.eqMf.EQResponse.success) ? data.response[ele].totalCount : 0
            data.response[ele].message = (respData.eqMf.EQResponse && respData.eqMf.EQResponse.success) ?
              (respData.eqMf.EQResponse && respData.eqMf.EQResponse.success && respData.eqMf.EQResponse.success.msg) || "Orders Taken Successfully"
              :
              (respData.eqMf.EQResponse && respData.eqMf.EQResponse.error && respData.eqMf.EQResponse.error.msg) || respData.eqMf.error || "Error"
          } else if (ele === "BuyMF" || ele === "SellMF") {
            data.response[ele].count = (respData.eqMf.MFResponse && respData.eqMf.MFResponse.success) ? data.response[ele].totalCount : 0
            data.response[ele].message = (respData.eqMf.MFResponse && respData.eqMf.MFResponse.success) ?
              (respData.eqMf.MFResponse && respData.eqMf.MFResponse.success && respData.eqMf.MFResponse.success.msg) || "Orders Taken Successfully"
              :
              (respData.eqMf.MFResponse && respData.eqMf.MFResponse.error && respData.eqMf.MFResponse.error.msg) || respData.eqMf.error || "Error"
          } else if (ele === "BuyEQSIP") {
            data.response[ele].count = respData.equitySIP.success ? data.response[ele].totalCount : 0
            data.response[ele].message = respData.equitySIP.success ?
              (respData.equitySIP.success && respData.equitySIP.success.TBRemark) || "Orders Taken Successfully"
              :
              (respData.equitySIP.error && respData.equitySIP.error.Message) || respData.equitySIP.error || "Error"
          } else if (ele === "BuyMFSIP") {
            data.response[ele].bankMandate = formValues["mfSIP"].bankMandate || []
            data.response[ele].product = (reduxState && reduxState.product_name) || ""
            data.response[ele].count = respData.mfSIP.success ? respData.mfSIP.success.length : data.response[ele].totalCount - respData.mfSIP.error.length
            data.response[ele].message = respData.mfSIP.success ?
              respData.mfSIP.success && respData.mfSIP.success.length > 0 && "Orders Taken Successfully"
              :
              (respData.mfSIP.error && respData.mfSIP.error.length > 0 && respData.mfSIP.error[0] && respData.mfSIP.error[0].error) || respData.mfSIP.error || "Error"
          }
        })
        setResponse(data.response || {})
        setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })
        setActiveStep({ formStep: 2, step: 0, name: "", completed: false })
      })
    }
  }

  function addBankMandate() {
    onCancel();
    openAddMandateDialog()
  }

  function getOrderForm(tab) {
    switch (tab) {
      case "SellEQ":
        return <EquityOrder
          buySell={"Sell"}
          reduxState={reduxState || {}}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.sellEq : []}
          handleBack={handleBack}
          activeStep={activeStep.step}
          handleFormValues={handleFormValues}
          formData={formData && formData.SellEQ && formData.SellEQ.length > 0 ? formData.SellEQ : []}
        />
      case "SellMF":
        return <MfOrder
          buySell={"Sell"}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.sellMf || [] : []}
          handleBack={handleBack}
          activeStep={activeStep.step}
          handleFormValues={handleFormValues}
          formData={formData && formData.SellMF && formData.SellMF.length > 0 ? formData.SellMF : []}
          accountData={(formData && formData.accountDetails) || []}
        />
      case "BuyEQ":
        return <EquityOrder
          lot={lot}
          buySell={"Buy"}
          productName={reduxState?.product_name || ''}
          reduxState={reduxState || {}}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.equity || [] : []}
          handleBack={handleBack}
          activeStep={activeStep.step}
          handleFormValues={handleFormValues}
          formData={formData && formData.BuyEQ && formData.BuyEQ.length > 0 ? formData.BuyEQ : []}
        />
      case "BuyMF":
        return <MfOrder
          lot={lot}
          buySell={"Buy"}
          productName={reduxState?.product_name || ''}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.mfLumpsum || [] : []}
          equitySum={tabs && tabs.length > 0 ? formValues && formValues.equity && formValues.equity.sumOf || 0 : 0}
          handleBack={handleBack}
          activeStep={activeStep.step}
          handleFormValues={handleFormValues}
          formData={formData && formData.BuyMF && formData.BuyMF.length > 0 ? formData.BuyMF : []}
          accountData={(formData && formData.accountDetails) || []}
        />
      case "BuyMFSIP":
        return <MfSIP
          buySell={"Buy"}
          sipData={formData && formData.productDetails && formData.productDetails.mfSipData || {}}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.mfSIP || [] : []}
          handleBack={handleBack}
          activeStep={activeStep.step}
          handleFormValues={handleFormValues}
          formData={formData && formData.BuyMFSIP && formData.BuyMFSIP.length > 0 ? formData.BuyMFSIP : []}
          mandateData={(formData && formData.mandate) || []}
          addBankMandate={addBankMandate}
        />
      case "BuyEQSIP":
        return <EquitySIP
          buySell={"Buy"}
          sipData={formData && formData.productDetails && formData.productDetails.eqSipData || {}}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.equitySIP || [] : []}
          handleBack={handleBack}
          activeStep={activeStep.step}
          handleFormValues={handleFormValues}
          formData={formData && formData.BuyEQSIP && formData.BuyEQSIP.length > 0 ? formData.BuyEQSIP : []}
        />
      default:
        break;
    }
  }

  function getConfirmScreen(tab) {
    switch (tab) {
      case "SellEQ":
        return <ConfirmEquityOrder
          loading={errorObj}
          handleBack={handleBack}
          handleStep={handleStep}
          lastStep={activeStep.step === tabs.length - 1}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.sellEq || [] : []}
        />
      case "SellMF":
        return <ConfirmMfOrder
          loading={errorObj}
          handleBack={handleBack}
          handleStep={handleStep}
          lastStep={activeStep.step === tabs.length - 1}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.sellMf || [] : []}
        />
      case "BuyEQ":
        return <ConfirmEquityOrder
          loading={errorObj}
          handleBack={handleBack}
          handleStep={handleStep}
          lastStep={activeStep.step === tabs.length - 1}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.equity || [] : []}
        />
      case "BuyMF":
        return <ConfirmMfOrder
          loading={errorObj}
          orderType={"One-Time"}
          handleBack={handleBack}
          handleStep={handleStep}
          lastStep={activeStep.step === tabs.length - 1}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.mfLumpsum || [] : []}
        />
      case "BuyMFSIP":
        return <ConfirmMfOrder
          sip={true}
          orderType={"SIP"}
          loading={errorObj}
          handleBack={handleBack}
          handleStep={handleStep}
          lastStep={activeStep.step === tabs.length - 1}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.mfSIP || [] : []}
        />
      case "BuyEQSIP":
        return <ConfirmEquityOrder
          sip={true}
          loading={errorObj}
          handleBack={handleBack}
          handleStep={handleStep}
          lastStep={activeStep.step === tabs.length - 1}
          formValues={tabs && tabs.length > 0 ? formValues && formValues.equitySIP || [] : []}
        />
      default:
        break;
    }
  }

  const content = () => {
    return (
      activeStep.formStep === 2 ?
        <OrderSuccess
          data={response}
          onCancel={onCancel}
        />
        :
        tabs.length > 0 ?
          <div className={activeStep.formStep === 1 ? classes.confirmGrid : ""}>
            {activeStep.formStep === 1 ? <div style={{ padding: "0px 10px" }}>{steps()}</div> : <></>}
            {
              tabs.map((ele, index) => (
                <TabPanel key={index} value={activeStep.step} index={index} boxClass={classes.form}>
                  {activeStep.formStep === 1 ? getConfirmScreen(ele) : getOrderForm(ele)}
                </TabPanel>
              ))
            }
          </div >
          :
          <></>
    )
  }

  return (
    <div className={classes.formRoot}>
      {errorObj && errorObj.error && <div className={classes.errorDiv}><Typography style={{ color: '#721c24' }}>{errorObj.message}</Typography></div>}
      {
        errorObj.load ?
          <div className={[classes.centerContent, classes.loadingDiv].join(" ")}>
            <Placeholder
              loading={errorObj.message === "Loading..." ? true : false}
              loadingMsg={""}
              error={errorObj.message === "Loading..." ? "" : errorObj.message}
            />
          </div>
          :
          formData && Object.keys(formData).length > 0 ?
            <>
              {activeStep.formStep === 0 ? <TopInfo /> : <></>}
              <div className={classes.grid} style={activeStep.formStep === 1 ? { padding: 0, background: "none", border: 0 } : { padding: 0 }}>
                <ConfirmationDialog
                  id="Information"
                  content={"Please select atleast one order."}
                  centerActions={true}
                  hideCloseButton={true}
                  okButtonText={"Ok"}
                  open={!errorObj.pushOrder}
                  onClose={() => setErrorObj({ load: false, error: false, submitting: false, message: "", pushOrder: true })}
                />
                {content()}
              </div>
            </>
            :
            <></>
      }</div >
  )
}

const mapStateToProps = (state) => {
  return { reduxState: state.dialog.data || {} }
};

const mapDispatchToProps = dispatch => ({
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog()),
  openAddMandateDialog: () => dispatch(openDialog(MODAL_CHILD.ADD_MANDATE)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BulkOrder))
