import { Close, Search } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import { withStyles } from '@mui/styles';
import clsx from "clsx";
import React, { useState } from "react";
import styles from "./styles";

function SearchBox(props) {
    const { classes, onChange, onSubmit, placeholder, propClassName, isEnabled, value, keyPressEvent } = props;
    const [textValue, setTextValue] = useState(value || '');

    const onKeyDown = event => {
        keyPressEvent && keyPressEvent(event);
        const { target: { value }, keyCode } = event;
        setTextValue(value);
        if (keyCode === 13) {
            if (value && value.length > 2) {
                event.preventDefault();
                onSubmit && onSubmit(value);
            }
        }
    }

    const onTextChange = event => {
        let key = event.target.value;
        // console.log('onTextChange', key);
        setTextValue(key);
        onChange && onChange(key);
    }

    const onClear = () => {
        setTextValue('');
        onChange && onChange('');
    }

    return (
        <div
            className={clsx(classes.search, propClassName)}
            {...props}
        >
            <InputBase
                autoFocus
                placeholder={placeholder || "Search…"}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={textValue}
                onKeyDown={onKeyDown}
                onChange={onTextChange}
            // disabled={!isEnabled}
            >
                {/* {textValue} */}
            </InputBase>
            {textValue.length > 0 &&
                <div className={classes.searchIcon}>
                    <IconButton onClick={onClear}>
                        <Close />
                    </IconButton>
                </div>}
            {textValue.length <= 0 &&
                <div className={classes.searchIcon}>
                    <IconButton disabled>
                        <Search />
                    </IconButton>
                </div>}
        </div>
    )
}

export default withStyles(styles)(SearchBox);
