import { Stepper } from "@mui/material";
import { useFormik } from "formik";
import axios from "helpers/interceptor";
import { PPRDataContext, UserProfileContext } from "Main";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { feedService } from "services/feed";
import { getModifiedScrip } from ".";
import { getSchema } from "./ValidationSchema";
import getCookie, { setCookie } from "helpers/cookies";

// let selectClient = {};
const exchangeValues = ["EQ", "NCM", "BCM",/* "CURR", "COMM" */]

function OrderStates(Component) {
    return (props) => <HOC {...props} Component={Component} />
}

const USER = `${process.env.REACT_APP_USER}`

const HOC = (props => {
    const { Component, reduxState, onCancel, selectedClient, orderView, openConfirmDialog, closeConfirmDialog, marketStatus } = props

    const [activeStep, setActiveStep] = useState(0)
    const [scrip, setScrip] = useState({})
    const [loading, setLoading] = useState({ show: true, error: "Loading..." })
    const [error, setError] = useState({ error: false, message: "" })
    const [showClientError, setClientError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [validityDateData, setValidityDateData] = useState("Loading")
    const [termsPopup, setTermsPopup] = useState({ show: false, value: false, step: 0, submitted: false })
    const [infoPopup, setInfoPopup] = useState({ show: false, message: "" })
    const profile = useContext(UserProfileContext)
    const pprData = useContext(PPRDataContext)
    const [currentScrip, setCurrentScrip] = useState({})
    const [mtfPopup, setMtfPopup] = useState(false)
    const [showBrokerageCharges, setShowBrokerageCharges] = useState(false)
    const [selectClient, setSelectClient] = useState({})
    const [splitPopup, setSplitPopup] = useState({ show: false, splitQty: 0, remainingQty: 0 })
    const [processSplitOrder, setProcessSplitOrder] = useState({ show: false, completed: 0, total: 0 })
    const { splitQty, remainingQty } = splitPopup

    async function getClientAccessDetails(clientCode) {
        setSubmitting(true)
        return axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-clientmapping/getClientAcessDetails`, { clientCode }).then(resp => {
            if (resp.data.success && resp.data.success.length > 0) {
                setSubmitting(false)
                let data = resp.data.success[0];
                return data
            } else {
                setSubmitting(false)
                setError({ error: true, message: resp.data.error || "Client access details not found" })
                return null;
            }
        }).catch(error => {
            setSubmitting(true)
            setError({ error: true, message: error.message || "Client access details not found" })
            return null;
        })
    }

    function initializeFormValues() {
        const { lot, orderStatus, buySell, orderType, orderQty, orderPrice, trigPrice, productType, validity, valDate,
            disclosedQty, status, type, approxMargin, bracketOrder, triggerOrderPrice, targetPrice } = reduxState?.form || {};
        return {
            exchange: "",
            lot: lot || 1,
            orderStatus: status === "Modify" ? orderStatus : "",
            type: buySell || "B",
            orderType: orderType || "LIMIT",
            quantity: Number(orderQty || 0) || /* (reduxState?.scrip?.segment === "FNO" || reduxState?.scrip?.segment === "CURR")) ? reduxState?.scrip?.marketLot :  */1/* ) */,
            disclosedQuantity: Number(disclosedQty || 0) || 0,
            price: Number(orderPrice || 0) || /* reduxState?.scrip.?ltp ||  */1.00,
            triggerPrice: Number(trigPrice || 0) || 0.00,
            product: productType || "Delivery",
            validTill: validity || "GFD",
            validityDate: validity === "GTD" && (status === "Modify" || type === "sell" || type === "squareOff") ? valDate : "",
            approxMargin: approxMargin || false,
            // bracketOrder: bracketOrder || false,
            // targetPrice: targetPrice || 0,
            // stopLossPrice: triggerOrderPrice || 0
        }
    }

    const { handleSubmit, handleChange, values, setFieldValue, setFieldTouched, errors, touched } = useFormik({
        initialValues: initializeFormValues(),
        validationSchema: getSchema(scrip, currentScrip, reduxState && reduxState.form && reduxState.form.status === "New"),
        onSubmit: async values => {
            setError({ error: false, message: "" })
            setShowBrokerageCharges(false)
            if (!loading.searchError) {
                if (marketStatus[scrip.shortCode].isAfterHourMarket && scrip.segment !== "FNO" && values.disclosedQuantity && Number(values.disclosedQuantity) > 0) {
                    setInfoPopup({ show: true, message: "Order with disclosed quantity not allowed in AMO." })
                } else if (scrip?.segment === "COMM" && scrip?.exchange === "MCX" && values.orderType === "SL" && values.validTill === "IOC") {
                    setInfoPopup({ show: true, message: "Stop Loss not allowed for IOC order." })
                } else if (USER !== "client" && (Object.keys(selectClient).length === 0 || (Object.keys(selectClient).length > 0 && (selectClient.ClientCode === undefined || selectClient.ClientCode === "")))) {
                    setClientError(true)
                } else {
                    setClientError(false)
                    if (USER !== "client") {
                        const accessDetails = await getClientAccessDetails(selectClient.ClientCode);
                        if (accessDetails && Object.keys(accessDetails).length > 0) {
                            if (scrip && scrip.segment === "EQ" && values.product === "MTF" && ((values.type === "S" && reduxState?.form?.status === "New" && reduxState?.form?.type !== "squareOff") || (values.type === "B" && scrip.exchange === "BSE"))) {
                                setInfoPopup({ show: true, message: "MTF is not allowed for Sell orders and for orders in BSE exchange" })
                            } else if (scrip && scrip.segment === "EQ" && scrip.exchange === "NSE" && values.type === "B" && values.product === "MTF" && accessDetails.mtfStatus === "No") {
                                setInfoPopup({ show: true, message: "MTF facility not active for selected client. Kindly ask client to initiate activation from Tradebulls Touch 2.0 or Tradebulls Pro application." })
                            } else if (scrip && scrip.segment === "COMM" && !accessDetails.CommodityProduct) {
                                setInfoPopup({ show: true, message: "Commodity segment not Active, kindly contact RM." })
                            } else if (!accessDetails.IntraDayProduct && values.product === "Intraday") {
                                setTermsPopup({ show: true, value: termsPopup.value, step: 0, submitted: true })
                            } else {
                                oddLotPopup(scrip)
                            }
                        } else {
                            return null
                        }
                    } else {
                        let getMtfStatus = (localStorage.getItem("mtfStatus") && JSON.parse(localStorage.getItem("mtfStatus"))) || null
                        if (profile && Object.keys(profile).length > 0) {
                            const { isPOA, isDDPISettlement, isDDPIPledge } = profile || {};
                            if (scrip && scrip.segment === "EQ" && values.product === "MTF" && ((values.type === "S" && reduxState?.form?.status === "New" && reduxState?.form?.type !== "squareOff") || (values.type === "B" && scrip.exchange === "BSE"))) {
                                setInfoPopup({ show: true, message: "MTF is not allowed for Sell orders and for orders in BSE exchange" })
                            } else if (scrip && scrip.segment === "EQ" && values.product === "MTF" && values.type === "B" && scrip.exchange === "NSE" &&
                                (isPOA === 0 && ((isDDPISettlement && !isDDPIPledge) || (!isDDPISettlement && isDDPIPledge) || (!isDDPISettlement && !isDDPIPledge)))) {
                                setInfoPopup({ show: true, message: "This feature is only allowed for POA clients" })
                            } else if (scrip && scrip.segment === "EQ" && values.product === "MTF" && values.type === "B" && scrip.exchange === "NSE" && profile.isPOA === 1 && profile.mtfStatus === "No" && !getMtfStatus) {
                                let obj = {
                                    content: "MTF is not activated for your account. Do you wish to activate it now ?",
                                    okButtonText: "No",
                                    centerActions: true,
                                    cancelButtonText: "Yes",
                                    onClose: handleMTFActivation
                                }
                                openConfirmDialog(obj)
                            } else if (scrip && scrip.segment === "COMM" && profile.commodityProduct === "No") {
                                setInfoPopup({ show: true, message: "Commodity segment not Active, kindly contact RM." })
                            } else if (!termsPopup.submitted && values.product === "Intraday") {
                                setTermsPopup({ show: true, value: termsPopup.value, step: 0, submitted: true })
                            } else {
                                oddLotPopup(scrip)
                            }
                        } else {
                            oddLotPopup(scrip)
                        }
                    }
                }

            }
        }
    })

    const handleMTFActivation = (newValue) => {
        closeConfirmDialog()
        if (newValue === 'cancel') {
            setMtfPopup(true)
        }
    }

    useEffect(() => {
        if (values.lot && !isNaN(Number(values.lot)) && scrip && scrip.marketLot) {
            if (values.lot === "") {
                setFieldValue("quantity", scrip.marketLot)
            } else {
                setFieldValue("quantity", Number(values.lot) * Number(scrip.marketLot))
            }
        }
    }, [values.lot])

    useEffect(() => {
        if (scrip && (scrip.segment === "CURR" || scrip.segment === "FNO" || scrip.segment === "COMM") && values.quantity && Number(values.quantity) % Number(scrip.marketLot) === 0 && !isNaN(Number(values.quantity))) {
            if (values.quantity === "") {
                // setFieldValue("lot", reduxState.scrip.marketLot)
            } else {
                setFieldValue("lot", Number(values.quantity) / Number(scrip.marketLot))
            }
        }
    }, [values.quantity])

    useEffect(() => {
        setFieldValue("product", values.approxMargin ? "MTF" : values.product === "MTF" ? "Delivery" : values.product)
    }, [values.approxMargin])

    useEffect(() => {
        USER === "client" && setFieldValue("approxMargin", values.product === "MTF" ? true : false)
    }, [values.product])

    useEffect(() => {
        if (values.validTill === "GTD" && currentScrip && Object.keys(currentScrip).length > 0) {
            axios.post(`${process.env.REACT_APP_API_URL}/ms-feed-service/content/market/gtdList`, { shortCode: currentScrip.shortCode, contract: currentScrip.scripName }).then(resp => {
                if (resp.data.success && resp.data.success.length > 0) {
                    setValidityDateData(resp.data.success)
                    if (reduxState && reduxState.form && (reduxState.form.status === "Modify" || reduxState.form.type === "squareOff" || reduxState.form.type === "sell")) {
                        setFieldValue("validityDate", (reduxState.form.valDate && reduxState.form.valDate !== "0" && reduxState.form.valDate) || resp.data.success[0])
                    } else {
                        setFieldValue("validityDate", resp.data.success[0])
                    }
                } else {
                    setValidityDateData([])
                }
            }).catch(error => {
                setValidityDateData([])
            })
        }
    }, [values.validTill, currentScrip])

    function checkIntraday() {
        let getIntradayProduct = (localStorage.getItem("intradayProduct") && JSON.parse(localStorage.getItem("intradayProduct"))) || {}
        if (USER === "client" && profile) {
            if (profile.intraDayProduct === "Yes") {
                setTermsPopup({ show: false, value: false, step: 0, submitted: true })
            } else if (profile.intraDayProduct === "No") {
                if (getIntradayProduct && getIntradayProduct.status && getIntradayProduct.status === "Yes") {
                    setTermsPopup({ show: false, value: false, step: 0, submitted: true })
                } else {
                    setTermsPopup({ show: false, value: false, step: 0, submitted: false })
                }
            } else {
                setTermsPopup({ show: false, value: false, step: 0, submitted: true })
            }
        } else {
            setTermsPopup({ show: false, value: false, step: 0, submitted: true })
        }
    }

    function setValidTillFromPreference(value, scrip) {
        if ((value === 'GTD' || value === 'GTC') && reduxState?.scrip?.segment !== 'COMM') {
            return 'GFD'
        } else if (value === 'IOC' && marketStatus && marketStatus[scrip.shortCode] && marketStatus[scrip.shortCode].isAfterHourMarket) {
            return 'GFD'
        } else {
            return value
        }
    }

    function setProductFromPreference(value) {
        if (value === 'MTF') {
            if (reduxState?.scrip?.segment !== 'EQ' || (reduxState?.scrip?.segment === 'EQ' && (!reduxState?.scrip?.isMTFApproved || reduxState?.scrip?.isMTFApproved === "false"))) {
                return 'Delivery'
            } else {
                return value
            }
        } else if (value === 'Intraday' && (!reduxState?.scrip?.isTBApproved || reduxState?.scrip?.isTBApproved === "false")) {
            return 'Delivery'
        } else {
            return value
        }
    }

    useEffect(() => {
        checkIntraday()
        if (reduxState && reduxState.form && (reduxState.form.status === "Modify" || reduxState.form.type === "squareOff" || reduxState.form.type === "sell")) {
            feedService.getFullFeed([reduxState.form]).then((response) => {
                if (response[0]?.error) {
                    setLoading({ show: true, error: "Scrip data not found" });
                } else if (response.length > 0) {
                    if (response[0].isMarketIndex) {
                        setLoading({ searchError: true, error: "This index is not tradeable." })
                    } else {
                        setScrip({ ...response[0] })
                        setCurrentScrip(response[0])
                        if (reduxState.form.status === "New") {
                            setFieldValue("price", reduxState?.form?.orderType == "MKT" ? 0.00 : (reduxState.form?.orderPrice || handlePrice(response[0], reduxState.form.buySell) || 0.00))
                            if (response[0].segment === "FNO" || response[0].segment === "CURR") {
                                setFieldValue("quantity", reduxState.form.orderQty || 1)
                                setFieldValue("lot", reduxState.form.orderQty / response[0].marketLot || 0)
                            } else if (response[0].segment === "COMM") {
                                if (response[0].exchange === "MCX") {
                                    setFieldValue("lot", reduxState.form.orderQty || 0)
                                    setFieldValue("quantity", reduxState.form.orderQty * response[0].marketLot || 0)
                                } else {
                                    setFieldValue("lot", reduxState.form.orderQty / response[0].marketLot || 0)
                                    setFieldValue("quantity", reduxState.form.orderQty || 1)
                                }
                            } else {
                                setFieldValue("quantity", reduxState.form.orderQty || 1)
                                setFieldValue("lot", 1)
                            }
                        }
                        if (reduxState.form.status === "Modify") {
                            if (response[0].segment === "FNO" || response[0].segment === "CURR") {
                                setFieldValue("lot", reduxState.form.orderQty / response[0].marketLot || 0)
                            } else if (response[0].segment === "COMM") {
                                if (response[0].exchange === "MCX") {
                                    setFieldValue("lot", reduxState.form.orderQty || 0)
                                } else {
                                    setFieldValue("lot", reduxState.form.orderQty / response[0].marketLot || 0)
                                }
                            } else {
                                setFieldValue("lot", 1)
                            }
                            // reduxState.form.orderType == "MKT" && setFieldValue("price", 0.00)
                            if (reduxState.form.orderType == "MKT") {
                                setFieldValue("price", 0.00)
                            } else {
                                setFieldValue("price", reduxState.form.orderPrice)
                            }
                            if ((response[0].segment === "COMM") && (response[0].exchange === "MCX")) {
                                setFieldValue("quantity", reduxState.form.orderQty * response[0].marketLot || 0)
                            } else {
                                setFieldValue("quantity", reduxState.form.orderQty)
                            }
                        }
                        if (USER !== "client") {
                            setSelectClient({ ClientCode: reduxState.form.clientCode || "", ClientName: reduxState.form.clientName || "" })
                        }
                        setFieldValue("exchange", response[0].exchange)
                        setLoading({ show: false, error: "" })
                    }
                } else {
                    setLoading({ show: true, error: "Scrip data not found" })
                }
            }).catch(function (err) { });
        } else if (reduxState.form.status === "New") {
            if (reduxState && reduxState.scrip && reduxState.scrip.isMarketIndex) {
                setLoading({ searchError: true, error: "This index is not tradeable." })
            } else {
                setFieldValue("price", (reduxState && reduxState.scrip && handlePrice(reduxState.scrip, reduxState.form.buySell)) || 0.00)
                if (reduxState && reduxState.scrip && (reduxState.scrip.segment === "FNO" || reduxState.scrip.segment === "CURR" || reduxState.scrip.segment === "COMM")) {
                    setFieldValue("quantity", reduxState.scrip.marketLot || 1)
                }
                setFieldValue("exchange", reduxState.scrip.exchange)
                setLoading({ show: false, error: "" })
                if (USER != "client" && selectedClient !== null) {
                    setSelectClient({ ClientCode: selectedClient.ClientCode, ClientName: selectedClient.ClientName })
                }
            }
            let orderPreference = getCookie("orderFormdata") && JSON.parse(getCookie("orderFormdata"))
            if (orderPreference) {
                setFieldValue("orderType", orderPreference.orderType || 'LIMIT')
                setFieldValue("product", setProductFromPreference(orderPreference.product || 'Delivery'))
                setFieldValue("validTill", setValidTillFromPreference(orderPreference.validTill || 'GFD', reduxState.scrip))
                handleTypeChange({ ...values, ...orderPreference }, reduxState.scrip)
            }
            setScrip({ ...reduxState.scrip })
            setCurrentScrip(reduxState.scrip)
        }
        return (() => {
            onCancel()
            setScrip({})
            // selectClient = {}
            setSelectClient({})
            setCurrentScrip({})
            setLoading({ show: true, error: "Loading..." })
        })
    }, [])

    function onSelectClientChange(ele) {
        if (ele.ClientCode) {
            // selectClient = { ...ele }
            setSelectClient(ele)
        }/*  else {
            selectClient = {}
        } */
    }

    function initializeSelectClient() {
        // selectClient = {}
        setSelectClient({})
    }

    const onScripDataChange = (ele) => {
        setScrip({ ...ele })
    }

    const onScripChange = (ele) => {
        setScrip(ele)
        setCurrentScrip(ele)
        if (reduxState.form.type === "sell" || reduxState.form.type === "squareOff") {
            return;
        }
        if (ele.isMarketIndex) {
            setLoading({ searchError: true, error: "This index is not tradeable." })
        } else if (reduxState.form && reduxState.form.status !== "Modify" && (scrip.scripName !== ele.scripName || scrip.exchange !== ele.exchange)) {
            loading.searchError && setLoading({ searchError: false, error: "" })
            setFieldValue("price", values.orderType === "MKT" ? 0.00 : handlePrice(ele, "B") || 0.00)
            if (ele.segment === "CURR" || ele.segment === "FNO" || ele.segment === "COMM") {
                setFieldValue("lot", 1)
                setFieldValue("quantity", ele.marketLot || 1)
            } else {
                setFieldValue("quantity", (reduxState && reduxState.form && reduxState.form.orderQty) || 1)
            }
            setFieldValue("type", "B")
            setFieldValue("disclosedQuantity", 0)
            setFieldValue("triggerPrice", 0)
            setFieldValue("product", "Delivery")
            setFieldValue("validTill", "GFD")
            setFieldValue("orderType", "LIMIT")
            setFieldValue("exchange", ele.exchange)
            errors && Object.keys(errors).length > 0 && Object.keys(errors).forEach(ele => (
                setFieldTouched(ele, false)
            ))
        }
    }

    const handleExchangeChange = (value) => {
        let scripData = Object.assign({}, scrip)
        scripData.exchange = value
        setFieldValue("exchange", value)
        if (reduxState && reduxState.form && reduxState.form.type === "squareOff" && reduxState.form.netQty) {
            values.product === "MTF" && setFieldValue("product", "")
        } else {
            values.product === "MTF" && setFieldValue("product", "Delivery")
        }
        setFieldValue("approxMargin", false)
        feedService.getFullFeed([getModifiedScrip(scripData, value)]).then(feedScrips => {
            if (feedScrips.length > 0) {
                if (feedScrips[0].error) {
                    setLoading({ searchError: true, error: (feedScrips[0].error) || "Scrip data not found" });
                } else {
                    setCurrentScrip({ ...feedScrips[0] })
                    setScrip({ ...feedScrips[0] })
                    setFieldValue("price", values.orderType === "MKT" ? 0.00 : handlePrice(feedScrips[0], values.type) || 0.00)
                    setLoading({ searchError: false, error: "" })
                }
            }
        }).catch(error => {
            setLoading({ searchError: true, error: (error.length > 0 && error[0].error) || "Scrip data not found" })
        })
    }

    /*  const modifyDate = (date) => {
         if (date) {
             let dt = new Date()
             dt = (dt.getDate() <= 9 ? "0" + dt.getDate() : dt.getDate()) + "-" + (dt.getMonth() <= 9 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) + "-" + dt.getFullYear()
             return dt
         }
     } */

    function getOrderPayload(qty) {
        return {
            requestStatus: (reduxState && reduxState.form && reduxState.form.status) || "New",
            ex: reduxState && reduxState.form && reduxState.form.status === "Modify" ? reduxState.form.shortCode || "" : (scrip && scrip.shortCode) || "",
            seg: (scrip && scrip.segment) || "",
            sId: reduxState && reduxState.form && (reduxState.form.status === "Modify" || reduxState.form.type === "squareOff" || reduxState.form.type === "sell") ? reduxState.form.scripId || "" : (scrip && scrip.scripId) || "",
            tk: reduxState && reduxState.form && reduxState.form.status === "Modify" ? reduxState.form.token || "" : (scrip && scrip.token) || "",
            dpAccNo: (profile && profile.dematAcc) || "",
            buySell: values.type || "",
            qty: /*scrip && scrip.segment === "CURR" ? Number(values.lot) : */ scrip && scrip.segment === "COMM" && scrip.exchange === "MCX" ? Number(values.lot) : Number(qty || values.quantity) || 0,
            price: scrip && scrip.segment === "CURR" ? Number(values.price || 0).toFixed(4) : Number(values.price || 0).toFixed(2),
            type: values.orderType || "",
            disQty: Number(values.disclosedQuantity) || 0,
            tPrice: scrip && scrip.segment === "CURR" ? Number(values.triggerPrice || 0).toFixed(4) : Number(values.triggerPrice || 0).toFixed(2),
            val: values.validTill || "",
            pId: values.product || "",
            goalId: (reduxState && reduxState.form && reduxState.form.goalId) || "",
            orderId: reduxState && reduxState.form && reduxState.form.status === "Modify" ? reduxState && reduxState.form && reduxState.form.tbOrderId : "",
            valDate: scrip && scrip.segment === "COMM" ? values.validTill === "GTD" ? values.validityDate : /* modifyDate(new Date()) */ 0 : 0,
            // gtdDate:scrip && scrip.segment === "COMM" ? values.validTill === "GTD" ? values.validityDate : /* modifyDate(new Date()) */ 0 : 0,
            userId: USER !== "client" ? selectClient.ClientCode : "",
            productName: (reduxState && reduxState.form && reduxState.form.productName) || "",
            askPrice: scrip && scrip.askPrice || 0,
            bidPrice: scrip && scrip.bidPrice || 0,
            spltOrdQty: qty ? values.quantity : 0,
        }
    }

    const pushSplitOrders = async () => {
        let apiArray = [];
        for (let i = 0; i < splitPopup?.splitQty; i++) {
            apiArray.push(getOrderPayload(scrip.volumeFreezeQty))
        }
        if (splitPopup?.remainingQty > 0) {
            apiArray.push(getOrderPayload(splitPopup?.remainingQty))
        }
        setSubmitting(true)
        for (let i = 0; i < apiArray.length; i++) {
            // setTimeout(async () => {
            const order = await pushOrderApi(apiArray[i]);
            if (order) {
                setProcessSplitOrder({ show: true, completed: i + 1, total: apiArray.length })
                if (i === apiArray.length - 1) {
                    if (apiArray[0].requestStatus === 'New' && (reduxState.form.type !== "squareOff" && reduxState.form.type !== "sell")) {
                        setCookie('orderFormdata', JSON.stringify({ orderType: values.orderType, product: values.product, validTill: values.validTill }));
                    }
                    setTimeout(() => {
                        setSubmitting(false)
                        setProcessSplitOrder({ show: false, completed: 0, total: 0 })
                        onCancel()
                        let obj = {
                            content: "Orders has been taken by Tradebulls.",
                            hideCloseButton: true,
                            okButtonText: "Ok",
                            centerActions: true,
                            onClose: closeConfirmDialog
                        }
                        openConfirmDialog(obj)
                    }, 100)
                }
            }
            // }, 1000 * i);
        }
    }

    const closeSplitPopup = (step) => {
        setSplitPopup({ ...splitPopup, show: false })
        step && setActiveStep(activeStep + 1)
    }

    const pushOrderApi = (payload) => {
        return new Promise(function (resolve, reject) {
            axios.post(`${process.env.REACT_APP_API_URL}/ms-order-placement/push`, payload).then(resp => {
                if (resp.data) {
                    resolve(resp.data)
                }
            }).catch(error => {
                resolve({})
            })
        });
    }

    const callOrderApi = () => {
        if (splitQty > 1 || (splitQty > 0 && remainingQty > 0)) {
            pushSplitOrders()
        } else {
            setSubmitting(true)
            let payload = getOrderPayload()
            axios.post(`${process.env.REACT_APP_API_URL}/ms-order-placement/push`, payload).then(resp => {
                if (resp.data.success) {
                    setScrip({})
                    setSubmitting(false)
                    onCancel()
                    if (payload.requestStatus === 'New' && (reduxState.form.type !== "squareOff" && reduxState.form.type !== "sell")) {
                        setCookie('orderFormdata', JSON.stringify({ orderType: values.orderType, product: values.product, validTill: values.validTill }));
                    }
                    let obj = {
                        content: resp.data.success.orderId && resp.data.success.orderId !== "" ? resp.data.success.msg + " Order Id is " + resp.data.success.orderId + "." : resp.data.success.msg || "Order has been placed successfully",
                        hideCloseButton: true,
                        okButtonText: "Ok",
                        centerActions: true,
                        onClose: closeConfirmDialog
                    }
                    if ((window.location.href).split("/").includes("portfolio")) {
                        obj["reportsButton"] = {
                            show: true,
                            onClick: () => window.top.location.href = `${process.env.REACT_APP_TRADING_URL}dashboard`,
                            buttonText: "Go To Reports"
                        }
                    }
                    openConfirmDialog(obj)
                } else {
                    setSubmitting(false)
                    setActiveStep(activeStep - 1)
                    setError({ error: true, message: resp.data.error.msg })
                }
            }).catch(error => {
                setSubmitting(false)
                setActiveStep(activeStep - 1)
                setError({ error: true, message: "There is some technical issue, please try again later" })
            })
        }
    }

    const pushOrder = () => {
        let status = (marketStatus && marketStatus[scrip.shortCode]) || {};
        if (status.isAfterHourMarket) {
            if (values.orderType === "MKT") {
                setActiveStep(activeStep - 1)
                setError({ error: true, message: "Market orders are not allowed in After Market Orders. Kindly try to place a limit order instead." })
            } else {
                callOrderApi()
            }
        } else {
            callOrderApi()
        }
    }

    const handleOddLotPopup = (newValue) => {
        closeConfirmDialog()
        if (newValue === 'cancel') {
            checkPriceRange()
        }
    }

    const handlePriceRangePopup = (newValue) => {
        closeConfirmDialog()
        if (newValue === 'cancel') {
            splitOrder()
        }
    }

    const oddLotPopup = (scrip) => {
        if (scrip && Object.keys(scrip).length > 0 && scrip.marketType && scrip.marketType === "O") {
            if (reduxState && reduxState.form && reduxState.form.status === "New" && reduxState.form.type !== "sell" &&
                (reduxState.form.type !== "squareOff" || (reduxState.form.type === "squareOff" && !reduxState.form.netQty))) {
                let obj = {
                    content: "You will not be able to square off this order on the same day as it is illiquid/trade-to-trade category stock.\n Do you wish to continue ?",
                    onClose: handleOddLotPopup
                }
                if (values?.product === "Delivery") {
                    obj = {
                        ...obj,
                        okButtonText: "No",
                        cancelButtonText: "Yes",
                    }
                } else {
                    obj = {
                        ...obj,
                        content: "Intraday orders are blocked for this scrip. Place a delivery order instead.",
                        hideCloseButton: true,
                        okButtonText: "Ok",
                        centerActions: true,
                    }
                }
                openConfirmDialog(obj)
            } else {
                checkPriceRange()
            }
        } else {
            checkPriceRange()
        }
    }

    const handleAsmGsmPopup = (newValue) => {
        closeConfirmDialog()
        if (newValue === 'cancel') {
            setActiveStep(activeStep + 1)
        }
    }

    const splitOrder = () => {
        if (scrip.segment === "FNO" /* && scrip.exchange === "NSE" *//*  && values.quantity > scrip.volumeFreezeQty */) {
            let splitQuantity = 0, remainingQuantity = 0;
            splitQuantity = parseInt(Number(values.quantity) / Number(scrip.volumeFreezeQty)) || 0;
            remainingQuantity = values.quantity - (splitQuantity * scrip.volumeFreezeQty)
            if (splitQuantity > 1 || (splitQuantity > 0 && remainingQuantity > 0)) {
                setSplitPopup({ show: true, splitQty: splitQuantity, remainingQty: remainingQuantity || 0 })
            } else {
                setSplitPopup({ show: false, splitQty: 0, remainingQty: 0 })
                setActiveStep(activeStep + 1)
            }
        } else if (scrip.segment === "EQ" && ((values.exchange === "NSE" && scrip.nseAsmgsm != 0 && scrip.nseAsmgsm != 100) || (values.exchange === "BSE" && scrip.bseAsmgsm != 0 && scrip.bseAsmgsm != 100))) {
            let obj = {
                contentHeading: values.exchange === "NSE" ?
                    `Security is under surveillance measure ${scrip.nseSurvIndCode || ''}.`
                    :
                    `${scrip.exchange} ${scrip.scripName} ${scrip.segment} is in Graded Surveillance Measure ${(scrip.bseAsmgsm) || 0}.`,
                content: 'Would you like to continue ?',
                okButtonText: "No",
                cancelButtonText: "Yes",
                onClose: handleAsmGsmPopup
            }
            openConfirmDialog(obj)
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    const checkPriceRange = () => {
        if (values.orderType === "LIMIT") {
            let priceData = pprData && pprData.length > 0 && pprData.find(ele => (ele.ExchangeShortCode === scrip.shortCode && Number(scrip.ltp) >= ele.FromRate && Number(scrip.ltp) <= ele.ToRate))
            if (priceData) {
                let percRange = (Number(scrip.ltp) * priceData.RangePercentage) / 100
                let minRange = Number(scrip.ltp) - percRange
                let maxRange = Number(scrip.ltp) + percRange
                let obj = {
                    okButtonText: "No",
                    cancelButtonText: "Yes",
                    onClose: handlePriceRangePopup
                }
                if (Number(values.price) > maxRange || Number(values.price) < minRange) {
                    obj.content = `You are trying to place a limit order which is ${Number(values.price) > maxRange ? "more" : "less"} than ${priceData.RangePercentage}% of LTP. Do you want to place the order ?`
                    openConfirmDialog(obj)
                } else {
                    splitOrder()
                }

            } else {
                splitOrder()
            }
        } else {
            splitOrder()
        }
    }

    const handlePrice = (scripObj, type) => {
        if (type === "B") {
            return Number(scripObj.askPrice || 0)
        } else {
            return Number(scripObj.bidPrice || 0)
        }
    }

    const handleTypeChange = (values, scrip) => {
        if (values.orderType === "MKT") {
            setFieldValue("triggerPrice", 0)
            setFieldValue("price", 0.00)
            setFieldTouched("triggerPrice", false)
            setFieldTouched("price", false)
        } else if (values.orderType === "LIMIT") {
            setFieldValue("triggerPrice", 0)
            setFieldTouched("price", false)
            setFieldTouched("triggerPrice", false)
            setFieldValue("price", reduxState ?
                reduxState.form && reduxState.form.status === "Modify" ?
                    reduxState.form.orderPrice :
                    scrip && Object.keys(scrip).length > 0 && handlePrice(scrip, values.type) :
                1.00)
        } else {
            setFieldValue("price", reduxState ?
                reduxState.form && reduxState.form.status === "Modify" ?
                    reduxState.form.orderPrice :
                    scrip && Object.keys(scrip).length > 0 && handlePrice(scrip, values.type) : 1.00)
            setFieldTouched("price", false)
            setFieldTouched("triggerPrice", false)
        }
    }

    const [state, setState] = useReducer((state, action) => {
        switch (action) {
            case "MKT":
                setFieldValue("triggerPrice", 0)
                setFieldValue("price", 0.00)
                setFieldTouched("triggerPrice", false)
                setFieldTouched("price", false)
                setFieldValue("orderType", action)
                break;

            case "LIMIT":
                setFieldValue("triggerPrice", 0)
                setFieldValue("orderType", action)
                setFieldTouched("price", false)
                setFieldTouched("triggerPrice", false)
                setFieldValue("price", reduxState ?
                    reduxState.form && reduxState.form.status === "Modify" ?
                        reduxState.form.orderPrice :
                        scrip && Object.keys(scrip).length > 0 && handlePrice(scrip, values.type) :
                    1.00)
                break;

            case "SL":
                setFieldValue("orderType", action)
                setFieldTouched("price", false)
                setFieldValue("price", reduxState ?
                    reduxState.form && reduxState.form.status === "Modify" ?
                        reduxState.form.orderPrice :
                        scrip && Object.keys(scrip).length > 0 && handlePrice(scrip, values.type) :
                    1.00)
                break;

            case "B":
                setFieldValue("type", action)
                handleTypeChange(values, scrip)
                break;

            case "S":
                setFieldValue("type", action)
                if (values.product === "MTF") {
                    setFieldValue("product", "Delivery")
                    setFieldValue("approxMargin", false)
                }
                handleTypeChange(values, scrip)
                break;

            case "SS":
                setFieldValue("type", action)
                setFieldValue("product", "Delivery")
                handleTypeChange(values, scrip)
                break;

            // case "bracket":
            //     setFieldValue("orderType", values.orderType === "SL" ? "LIMIT" : values.orderType)
            //     setFieldValue("product", "Intraday")
            //     setFieldValue("validTill", "GFD")
            //     setFieldValue("approxMargin", false)
            //     // setFieldValue("price", handlePrice(scrip || {}, values.type))
            //     break;
            default:
                break;
        }
        return state;
    }, { ...values })

    return <>
        <Stepper activeStep={activeStep} children={<></>} style={{ display: "none" }} />
        <Component
            activeStep={activeStep}
            handleBack={() => setActiveStep(activeStep - 1)}
            pushOrder={pushOrder}
            handleSubmit={handleSubmit}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onScripChange={onScripChange}
            handleChange={handleChange}
            setOrderType={setState}
            values={values}
            errors={errors}
            touched={touched}
            reduxState={reduxState}
            errorObj={error}
            loading={loading}
            showExchange={scrip && scrip.segment && exchangeValues.includes(scrip.segment)}
            handleExchangeChange={handleExchangeChange}
            showClientError={showClientError}
            setClientError={setClientError}
            selectedClient={selectedClient}
            selectClient={selectClient || {}}
            setSelectClient={(data) => onSelectClientChange(data)}
            termsPopup={termsPopup}
            setTermsPopup={setTermsPopup}
            setActiveStep={setActiveStep}
            onScripDataChange={onScripDataChange}
            scrip={scrip}
            submitting={submitting}
            orderView={orderView}
            initializeSelectClient={initializeSelectClient}
            market={props.market}
            validityDateData={validityDateData}
            currentScrip={currentScrip}
            infoPopup={infoPopup}
            setInfoPopup={setInfoPopup}
            marketStatus={marketStatus}
            mtfPopup={mtfPopup}
            setMtfPopup={setMtfPopup}
            onCancel={onCancel}
            profile={profile}
            showBrokerageCharges={showBrokerageCharges}
            setShowBrokerageCharges={setShowBrokerageCharges}
            splitPopup={splitPopup}
            closeSplitPopup={closeSplitPopup}
            processSplitOrder={processSplitOrder}
        />
    </>
})

export default OrderStates;