import React from "react";
import { Typography, Button } from "@mui/material";
import SvgComponent from "helpers/svgcomponent";
import { setCookieNew } from "helpers/cookies";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  step5DoneText: {
    padding: '12px 0px 20px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      padding: '8px 0px 12px'
    }
  },
  step5Description: {
    padding: '4px 0px',
    color: theme.palette.color15.default
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  doneButton: {
    padding: '8px 48px',
    marginTop: 36,
    color: '#ffffff',
    backgroundColor: '#497CAC',
    '&:hover': {
      backgroundColor: '#497CACB5'
    }
  },
  root: {
    width: 600,
    height: 400,
    padding: '8px 24px',
    [theme.breakpoints.down('xl')]: {
      width: 400
    },
    [theme.breakpoints.down('lg')]: {
      height: 350
    },
  },
})

function EnabledTotp(props) {
  const { classes, onCancel, enabled } = props;

  return (
    <div className={`${classes.centerContent} ${enabled ? classes.root : ''}`}>
      <SvgComponent iconname={"GraphicsDone"} />
      <Typography variant='h2' className={classes.step5DoneText}>Done</Typography>
      <Typography variant='h4' className={classes.secondaryText}>{`TOTP authentication is now ${enabled ? 'disabled' : 'enabled'} on your account.`}</Typography>
      {/* <Typography variant='h4' className={classes.step5Description}>You will need to re-login to all your Tradebulls</Typography>
      <Typography variant='h4' className={classes.secondaryText}>sessions across all devices</Typography> */}
      <Button autoFocus={true} className={classes.doneButton} variant="contained"
        onClick={() => {
          setCookieNew('totp', enabled ? '0' : '1');
          onCancel()
        }}
      >Ok</Button>
    </div>
  )
}

export default withStyles(styles)(EnabledTotp);