export default (theme) => ({
  root: {
    padding: 24,
    margin: '8px 0px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    width: '500px',
    [theme.breakpoints.down('xl')]: {
      width: 450,
      padding: 20
    },
    [theme.breakpoints.down('lg')]: {
      width: 400,
      padding: 16
    },
    [theme.breakpoints.down('md')]: {
      width: 350
    }
  },
  headerDiv: {
    borderBottom: `2px solid #666666`,
    marginBottom: 16
  },
  text: {
    textAlign: 'justify',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    }
  },
  blueText: {
    color: theme.palette.common.blue,
    marginBottom: 8,
    [theme.breakpoints.down('xl')]: {
      marginBottom: 6
    }
  },
  button: {
    color: '#fff',
    backgroundColor: theme.palette.common.blue,
    padding: '4px 40px',
    textTransform: 'none',
    marginTop: 20,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    '&:disabled': {
      backgroundColor: '#c5cbce',
      color: '#7d7d7d'
    }
  }
});
