import axios from 'helpers/interceptor';

export const fundDetailsService = {
  getFundDetails
};

function getFundDetails(data) {
  const requestOptions = {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ms-mf-funddetail/mffundDetail/${data.isinNumber}`
  };

  return axios(requestOptions)
    .then(response => {
      return response.data.success;
    })
    .catch(function (error) {
      console.log(error);
    })
}


