export default (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    backgroundColor: theme.palette.color3.default,
    [theme.breakpoints.down('xl')]: {
      padding: '6px 14px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '4px 12px'
    }
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 8,
  },
  title: {
    // flex: 1,
    marginRight: 8,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#24B7CC'
  },
  infoDiv: {
    display: 'flex',
    borderRadius: 2,
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#474c5c'
    }`,
    margin: '4px 12px 0px',
    width: 'calc(100% - 24px)',
  },
  valueDiv: {
    width: '100%',
    borderRight: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#474c5c'
    }`
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 0px'
  },
  mainDiv: {
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#474c5c'
    }`,
    borderRadius: 2,
    margin: '8px 0px',
  },
  heading: {
    padding: '12px 12px 4px 12px',
    display: 'flex',
    flexDirection: 'column',
  },
  spaceContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  primaryText: {
    color: theme.palette.text.primary,
    paddingBottom: 4
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    '&.text': {
      whiteSpace: 'nowrap',
      paddingLeft: 8
    }
  },
  blueText: {
    color: theme.palette.common.blue,
    fontWeight: 600,
    paddingBottom: 6
  },
  typeText: {
    color: '#45B880',
    fontWeight: 600
  },
  riskDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 12px',
    padding: '4px 8px',
    borderTop: 0,
    borderRadius: '0px 0px 2px 2px',
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#474c5c'
    }`,
    [theme.breakpoints.down('lg')]: {
      padding: '8px 14px'
    }
  },
  msgText: {
    padding: '6px 12px 12px',
    color: theme.palette.text.secondary 
  },
  closeIconGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
});
