import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle } from "@mui/material";
import React from 'react';

export default function ModelPopup(props) {
    //console.log("proposoodio", props);
    const handleClose = (event, reason) => {
        if (reason != 'backdropClick') {
            props.toogleDialog && props.toogleDialog();
        }
    }

    return (
        <Dialog fullWidth={true}
            open={props.openDialog}
            onClose={handleClose}
            disableEscapeKeyDown
            aria-labelledby="Edit">
            <DialogTitle id="Edit">{props.title}<div onClick={() => { props.closeModal() }} style={{ float: "right", cursor: "pointer" }}>
                {!props.customClose ? <Close></Close> : <></>}
            </div></DialogTitle>
            {props.children}
        </Dialog>
    )
}