export default (theme) => ({
  cancelled: { color: theme.palette.orderStatusColor.cancelled },
  executed: { color: theme.palette.orderStatusColor.executed },
  expired: { color: theme.palette.orderStatusColor.expired },
  freezed: { color: theme.palette.orderStatusColor.freezed },
  inProcess: { color: theme.palette.orderStatusColor.inProcess },
  partiallyExecuted: { color: theme.palette.orderStatusColor.partiallyExecuted },
  pending: { color: theme.palette.orderStatusColor.pending },
  rejected: { color: theme.palette.orderStatusColor.rejected },
  triggered: { color: theme.palette.orderStatusColor.triggered },
  highlighted: { backgroundColor: theme.palette.background.darker, },
  icon: {
    marginLeft: 5,
  },
  arrow: {
    color: theme.palette.background.secondary,
  },
  tooltip: {
    color: theme.palette.text.primary,
    fontSize: 14,
    backgroundColor: theme.palette.background.secondary,
  },
  iconButtonText: {
    fontSize: 12,
    color: theme.palette.text.primary,
    marginLeft: -24,
  },
  cellText: {
    color: 'inherit',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  cellLink: {
    color: '#006CA8',//Link Color
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
  cancelButton: {
    color: theme.palette.common.blue,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.common.blue}`,
    padding: '2px 12px',
    marginLeft: 10,
    textTransform: 'none',
    '&:hover': {
      border: `1px solid ${theme.palette.common.blue}80`,
      backgroundColor: theme.palette.common.blue + 12
    }
  }
})