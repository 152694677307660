export default ({ palette }) => ({
  styleOverrides: {
    fixedHeader: {
      color: palette.type === 'light' ? '#001E3B' : '#8DB1D2',
      fontSize: '14px',
      backgroundColor: palette.background.tableHead,
      whiteSpace: 'nowrap',
      zIndex: 90,
      top: -1,
    },
    fixedHeaderCommon: {
      color: palette.type === 'light' ? '#001E3B' : '#8DB1D2',
      fontSize: '14px',
      backgroundColor:
        palette.background.tableHead,
      whiteSpace: 'nowrap',
      zIndex: 90
    }
  }
});
