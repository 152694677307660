import {
    debounce,
    ClickAwayListener,
    IconButton,
    InputBase,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { store } from 'helpers';
import axios from 'helpers/interceptor';
import { UserProfileContext } from 'Main';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ClientActions } from "redux/Client";
import { closeConfirmDialog, openConfirmDialog } from 'redux/ConfirmDialog';
import styles from './styles';

let enterPressed = false
let mounted = false;

function ClientSearchBox({ classes, placeholder, onSelect, textSize, disabled, setClient, noAutoFocus, selectedClient, clientChange, style, ...rest }) {
    const inputRef = useRef(null);
    const rootRef = useRef(null);
    const listItemRef = useRef(null);
    const [searching, setSearching] = useState(false);
    const [textValue, setTextValue] = useState('');
    const [hintValue, setHintValue] = useState(placeholder || 'Client Search…');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    const userProfile = useContext(UserProfileContext);

    const { isHOUser } = userProfile;

    useEffect(() => {
        try {
            if (mounted && selectedClient === null) {
                mounted && setTextValue('');
                mounted && setHintValue(placeholder || 'Client Search…');
            }
        } catch (error) {
            console.log('Errrrrrrrrrrrrrr', error);
        }
    }, [selectedClient]);

    useEffect(() => {
        mounted = true;
        return () => {
            mounted = false;
        }
    }, []);

    const closeConfirm = () => {
        store.dispatch(closeConfirmDialog())
    }

    const getClients = (key, isHOUser) => {
        setSearching(true);
        const options = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/ms-trading-clientmapping/getEmpClientList1`,
            data: {
                type: 'clients',
                key, /* : "a" */
                isHOUser
            }
        };
        axios(options)
            .then(response => {
                const {
                    data: { success, error }
                } = response;
                if (enterPressed) {
                    if (success && success.length > 0) {
                        onItemSelected(success[0]);
                    } else {
                        let obj = {
                            content: 'No Client Results Found',
                            hideCloseButton: true,
                            okButtonText: 'Ok',
                            centerActions: true,
                            onClose: closeConfirm
                        };
                        store.dispatch(openConfirmDialog(obj));
                    }
                    enterPressed = false;
                } else {
                    if (success && success.length > 0) {
                        setOptions(success);
                    } else {
                        setOptions([]);
                    }
                }
                setSearching(false);
            })
            .catch(function (error) {
                console.log('getEmpClientList1 Error: ' + error);
                setSearching(false);
            });
    };

    const onOptionKeyDown = (event, index) => {
        const { keyCode } = event;
        if (keyCode == 38 && index == 0) {
            inputRef && inputRef.current.focus();
        }
    }

    const onInputKeyDown = event => {
        const { keyCode } = event;
        if (keyCode == 40 && options.length > 0) {
            listItemRef && listItemRef.current.focus();
        }
        if (keyCode == 13) {
            if (options.length > 0) {
                onItemSelected(options[0])
            } else {
                enterPressed = true
            }
        }
    }

    const onTextChange = event => {
        let key = event.target.value;
        setTextValue(key);
        if (clientChange) {
            clientChange()
            setHintValue('Client Search…')
        }
        if (key && key.length > 2) {
            setSearching(true);
            debounceGetData(key, isHOUser);
            setOpen(true);
        } else {
            setOptions([]);
            setOpen(false);
        }
    };

    const debounceGetData = useCallback(debounce(getClients, 500), []);

    const handleSortClose = () => {
        setOpen(false);
    };

    const onClear = () => {
        setTextValue('');
    };

    const onItemSelected = selectedItem => {
        handleSortClose();
        setHintValue(getDisplayValue(selectedItem));
        setTextValue('');
        onSelect && onSelect(selectedItem);
        if (setClient) {
            ClientActions.setClient(selectedItem);
        }
    };

    const getDisplayValue = item => {
        const { ClientCode, ClientName } = item;
        return `${ClientCode} - ${ClientName}`;
    }

    const getMenuItem = item => {
        const { index } = item;
        return (
            <MenuItem
                key={index}
                onClick={() => onItemSelected(item)}
                ref={index == 0 ? listItemRef : null}
                onKeyDown={event => onOptionKeyDown(event, index)}>
                {getDisplayValue(item)}
            </MenuItem>
        );
    };

    const getPopper = () => {
        return (
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={rootRef.current}
                disablePortal>
                <Paper>
                    <ClickAwayListener onClickAway={handleSortClose}>
                        <MenuList
                            ref={listItemRef}
                            id="menu-list-grow"
                            style={{
                                maxHeight: 175,
                                overflow: 'auto'
                            }}>
                            {options.map((option, index) => {
                                return getMenuItem({ ...option, index });
                            })}
                            {options.length == 0 &&
                                <MenuItem>
                                    {searching ? 'Searching' : 'No Results found'}
                                </MenuItem>}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        );
    };

    return (
        <div className={classes.search} style={style} ref={rootRef}>
            <InputBase
                ref={inputRef}
                autoFocus={!noAutoFocus}
                onKeyDown={onInputKeyDown}
                placeholder={hintValue}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={textValue}
                onChange={onTextChange}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                disabled={disabled || false}
                style={{ textSize: textSize }}>
            </InputBase>
            {textValue.length > 0 && (
                <div className={classes.searchIcon}>
                    <IconButton onClick={onClear}>
                        <Close />
                    </IconButton>
                </div>
            )}
            {textValue.length <= 0 && (
                <div className={classes.searchIcon}>
                    <IconButton disabled>
                        <Search />
                    </IconButton>
                </div>
            )}
            {getPopper()}
        </div>
    );
}

ClientSearchBox.propTypes = {
    setClient: PropTypes.bool,
};

ClientSearchBox.defaultProps = {
    setClient: true,
};

export default withStyles(styles)(ClientSearchBox);
