import * as Yup from "yup";

export const getSchema = (scrip, currentScrip, newOrder) => {
  Yup.addMethod(Yup.number, "isNotDecimal", function (args) {
    const message = args;
    return this.test("isNotDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        if (String(options.originalValue).includes(".")) {
          return createError({ path: path, message: "Decimal is not allowed" })
        } else {
          return true
        }
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "isDecimal", function (args) {
    const message = args;
    return this.test("isDecimal", message, function (value) {
      const { path, createError, options } = this;
      if (options.parent.orderType === "SL" && (options.originalValue === 0 /* || options.originalValue === undefined*/)) {
        return true
      } else if (options && options.originalValue) {
        var splitted = String(options.originalValue).split(".");
        if (splitted.length === 2) {
          if (splitted[1].length === 0) {
            return createError({ path: path, message: "Value is expected after decimal" })
          } else if (scrip && scrip.segment === "CURR") {
            if (splitted[1].length > 4) {
              return createError({ path: path, message: "Maximum four decimal places are allowed" })
            } else {
              return true
            }
          } else if (splitted[1].length > 2) {
            return createError({ path: path, message: "Maximum two decimal places are allowed" })
          } else {
            return true
          }
        } else if (splitted.length === 1) {
          return true;
        }
      }
      return createError({ path: path, message: message })
    })
  })

  Yup.addMethod(Yup.number, "quantityTest", function (args) {
    const message = args;
    return this.test("quantityTest", message, function (value) {
      const { path, createError, options } = this;
      if (options && options.originalValue) {
        /* if ((reduxState.form.type === "squareOff" || reduxState.form.type === "sell") && reduxState.form.netQty && reduxState.form.netQty > 0 && Number(options.originalValue) > reduxState.form.netQty) {
            return createError({ path: path, message: "Quantity should not be greater than Net Qty" })
        } */
        if (String(options.originalValue).includes(".")) {
          return createError({ path: path, message: "Decimal is not allowed" })
        } else if (currentScrip && scrip && currentScrip.scripName === scrip.scripName && (scrip.segment === "CURR" || scrip.segment === "FNO" || scrip.segment === "COMM")) {
          if (Number(options.originalValue) % Number(scrip.marketLot) !== 0) {
            return createError({ path: path, message: `Order quantity must be multiple of ${scrip.marketLot}` })
          }
          return true
        }
        return true
      }
    })
  })

  Yup.addMethod(Yup.number, "disclosedQtyTest", function (args) {
    const message = args;
    return this.test("disclosedQtyTest", message, function (value) {
      const { path, createError, parent } = this;
      if (parent.disclosedQuantity === undefined || parent.disclosedQuantity === 0) {
        return true
      } else {
        let qty = parent.quantity * (scrip?.segment === "COMM" ? 0.25 : 0.10)
        if ((parent.validTill === "IOC" && parent.disclosedQuantity > 0) || parent.disclosedQuantity === undefined) {
          return createError({ path: path, message: "Disclosed quantity and IOC combination not allowed" })
        } else if (parent.disclosedQuantity < Math.ceil(qty) || parent.disclosedQuantity > parent.quantity) {
          return createError({ path: path, message: `Disclosed quantity should be atleast ${scrip?.segment === "COMM" ? 25 : 10}% of quantity and less than quantity` })
        } else if (scrip?.segment !== "FNO" && Number(parent.disclosedQuantity) % Number(scrip.marketLot || 1) !== 0) {
          return createError({ path: path, message: `Disclosed quantity should be in multiples of ${scrip?.marketLot}` })
        } else if (scrip?.segment !== "COMM" && Number(parent.triggerPrice) > 0 && Number(this.parent.disclosedQuantity) > 0) {
          return createError({ path: path, message: "Trigger Order cannot have Disclosed quantity" })
        }
        return true
      }
    })
  })

  Yup.addMethod(Yup.number, "triggerPriceTest", function (args) {
    const message = args;
    return this.test("triggerPriceTest", message, function (value) {
      const { path, createError } = this;
      if (this.parent.type === "B") {
        if (this.parent.price > 0 && value > this.parent.price) {
          return createError({ path: path, message: "Trigger price should be less than Price" })
        }/*  else if(value < scrip.ltp) {
                return createError({ path: path, message: "Trigger price for stop loss buy orders should be higher than the Last Traded Price .try Limit Order to Buy At lower price." })
            } */
      } else if (this.parent.type === "S") {
        if (this.parent.price > 0 && value < this.parent.price) {
          return createError({ path: path, message: "Trigger price should be greater than Price" })
        }/*  else if(value > scrip.ltp) {
                return createError({ path: path, message: "Trigger price for stop loss sell  orders should be Lower than the Last Traded Price .try Limit Order to Sell  At higher price" })
            } */
      }
      return true
    })
  })

  Yup.addMethod(Yup.number, "priceTest", function (args) {
    const message = args;
    return this.test("priceTest", message, function (value) {
      const { path, createError, parent } = this;
      if (scrip) {
        if (scrip.segment === "CURR" && parent.price < 0.0001) {
          return createError({ path: path, message: "Limit Price should be greater than 0.0001" })
        } else if (scrip.segment !== "CURR" && parent.price < 0.01) {
          return createError({ path: path, message: "Limit Price should be greater than 0.01" })
        }
      }
      return true
    })
  })

  return newOrder ?
    Yup.object().shape({
      type: Yup.mixed().oneOf(['B', 'S', 'SS']),
      orderType: Yup.mixed().oneOf(['LIMIT', 'MKT', 'SL']),
      product: Yup.mixed()
        .oneOf(['Delivery', 'Intraday', 'MTF'])
        .required('Product Type is required'),
      validTill: Yup.mixed().oneOf(['GFD', 'IOC', 'GTC', 'GTD']),
      lot: Yup.number()
        .integer('Decimal is not allowed')
        .typeError('Invalid number')
        .min(1, 'Minimum lot is 1')
        .required('Lot is required')
        .isNotDecimal(scrip, currentScrip),
      quantity: Yup.number()
        .integer('Decimal is not allowed')
        .typeError('Invalid number')
        .min(1, 'Quantity should be greater than Zero')
        .max(scrip?.segment === "FNO" ? Number(scrip?.volumeFreezeQty || 0) > 100000 ? scrip?.volumeFreezeQty : 100000 : 10000000,
          `Quantity should be less than ${scrip?.segment === "FNO" ? Number(scrip?.volumeFreezeQty || 0) > 100000 ? scrip?.volumeFreezeQty : 100000 : 10000000}`)
        .required('Quantity is required')
        .quantityTest(),
      disclosedQuantity: Yup.number()
        .integer('Decimal is not allowed')
        .typeError('Invalid number')
        .min(0, 'Disclosed Quantity should be greater than or equal to Zero')
        .max(10000000, 'Maximum disclosed quantity is 10000000')
        // .required("Disclosed Quantity should be greater than or equal to Zero")
        .disclosedQtyTest()
        .isNotDecimal(),
      price: Yup.number()
        .when('orderType', {
          is: 'LIMIT',
          then: Yup.number()
            .typeError('Invalid number')
            // .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Limit Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
            .max(10000000, 'Limit Price should be less than 10000000')
            .priceTest()
            .isDecimal()
            .required('Price is required')
        })
        .when('orderType', {
          is: 'MKT',
          then: Yup.number()
            .typeError('Invalid number')
            .min(0, 'Minimum price is 0')
            .max(0, 'Maximum price is 0')
        })
        .when('orderType', {
          is: 'SL',
          then: Yup.number()
            .when('type', {
              is: 'B',
              then: Yup.number()
                .typeError('Invalid number')
                .min(0, 'Minimum price is 0')
                .max(1000000, 'Maximum price is 10000000')
                .isDecimal()
            })
            .when('type', {
              is: 'S',
              then: Yup.number()
                .typeError('Invalid number')
                .min(0, 'Minimum price is 0')
                .max(1000000, 'Maximum price is 10000000')
                .isDecimal()
            })
        }),
      triggerPrice: Yup.number()
        .when('orderType', {
          is: 'LIMIT',
          then: Yup.number().notRequired()
        })
        .when('orderType', {
          is: 'MKT',
          then: Yup.number().notRequired()
        })
        .when('orderType', {
          is: 'SL',
          then: Yup.number()
            .typeError('Invalid number')
            .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Trigger Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
            .max(1000000, 'Maximum price is 10000000')
            .triggerPriceTest()
            .isDecimal()
        }),
      validityDate: Yup.string().when('validTill', {
        is: 'GTD',
        then: Yup.string().required('Validity date is required')
      }),
      approxMargin: Yup.boolean()
    })
    :
    Yup.object().shape({
      type: Yup.mixed().oneOf(['B', 'S', 'SS']),
      orderType: Yup.mixed().oneOf(['LIMIT', 'MKT', 'SL']),
      product: Yup.mixed()
        .oneOf(['Delivery', 'Intraday', 'MTF'])
        .required('Product Type is required'),
      validTill: Yup.mixed().oneOf(['GFD', 'IOC', 'GTC', 'GTD']),
      lot: Yup.number()
        .integer('Decimal is not allowed')
        .typeError('Invalid number')
        .min(1, 'Minimum lot is 1')
        .required('Lot is required')
        .isNotDecimal(),
      quantity: Yup.number()
        .integer('Decimal is not allowed')
        .typeError('Invalid number')
        .min(1, 'Quantity should be greater than Zero')
        .max(scrip?.segment === "FNO" ? Number(scrip?.volumeFreezeQty) || 100000 /* > 100000 ? scrip?.volumeFreezeQty : 100000 */ : 10000000,
          `Quantity should be less than ${scrip?.segment === "FNO" ? Number(scrip?.volumeFreezeQty) || 100000 /* > 100000 ? scrip?.volumeFreezeQty : 100000 */ : 10000000}`)
        .required('Quantity is required')
        .quantityTest(),
      disclosedQuantity: Yup.number()
        .integer('Decimal is not allowed')
        .typeError('Invalid number')
        .min(0, 'Disclosed Quantity should be greater than or equal to Zero')
        .max(10000000, 'Maximum disclosed quantity is 10000000')
        // .required("Disclosed Quantity should be greater than or equal to Zero")
        .disclosedQtyTest()
        .isNotDecimal(),
      price: Yup.number()
        .when('orderType', {
          is: 'LIMIT',
          then: Yup.number()
            .typeError('Invalid number')
            // .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Limit Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
            .max(10000000, 'Limit Price should be less than 10000000')
            .priceTest()
            .isDecimal()
            .required('Price is required')
        })
        .when('orderType', {
          is: 'MKT',
          then: Yup.number()
            .typeError('Invalid number')
            .min(0, 'Minimum price is 0')
            .max(0, 'Maximum price is 0')
        })
        .when('orderType', {
          is: 'SL',
          then: Yup.number()
            .when('type', {
              is: 'B',
              then: Yup.number()
                .typeError('Invalid number')
                .min(0, 'Minimum price is 0')
                .max(1000000, 'Maximum price is 10000000')
                .isDecimal()
            })
            .when('type', {
              is: 'S',
              then: Yup.number()
                .typeError('Invalid number')
                .min(0, 'Minimum price is 0')
                .max(1000000, 'Maximum price is 10000000')
                .isDecimal()
            })
        }),
      triggerPrice: Yup.number()
        .when('orderType', {
          is: 'LIMIT',
          then: Yup.number().notRequired()
        })
        .when('orderType', {
          is: 'MKT',
          then: Yup.number().notRequired()
        })
        .when('orderType', {
          is: 'SL',
          then: Yup.number()
            .when('orderStatus', {
              is: 'Triggered',
              then: Yup.number()
                .typeError('Invalid number')
                .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Trigger Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
                .max(1000000, 'Maximum price is 10000000')
                .triggerPriceTest()
                .isDecimal()
            })
            .when('orderStatus', {
              is: 'PartiallyExecuted',
              then: Yup.number()
                .typeError('Invalid number')
                .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Trigger Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
                .max(1000000, 'Maximum price is 10000000')
                .triggerPriceTest()
                .isDecimal()
            })
            .when('orderStatus', {
              is: 'Pending',
              then: Yup.number()
                .typeError('Invalid number')
                .min(scrip && scrip.segment === "CURR" ? 0.0001 : 0.01, `Trigger Price should be greater than ${scrip && scrip.segment === "CURR" ? "0.0001" : "0.01"}`)
                .max(1000000, 'Maximum price is 10000000')
                .triggerPriceTest()
                .isDecimal()
            })
        }),
      validityDate: Yup.string().when('validTill', {
        is: 'GTD',
        then: Yup.string().required('Validity date is required')
      }),
      approxMargin: Yup.boolean()
    });
}