import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@mui/styles';

import * as FlexLayout from 'flexlayout-react';

// import TVChartContainer from 'widgets/charts/TVChartContainer';
// import DepthHOC from 'widgets/marketdepth/DepthHOC';
// import DetailQuote from 'widgets/detailquote';
// import ScripInfo from 'widgets/scripinfo';
// import WatchList from 'widgets/watchlist';
// import ScripAnalysis from 'widgets/scripanalysis';
// import OrderReport from 'widgets/reports/orderreport';
// import PositionReport from 'widgets/reports/positionreport';
// import DPSRReport from 'widgets/reports/dpsrreport';
// import TradesReport from 'widgets/reports/tradesreport';
// import ULMarginReport from 'widgets/reports/ulmarginreport';
// import USPositionReport from 'widgets/reports/unsettledpositionreport';
// import Announcements from 'widgets/announcement';
// import MarketSummary from 'widgets/marketsummary';
// import OptionChainHOC from 'widgets/optionchain/OptionChainHOC';
// import Disclaimer from 'widgets/disclaimer';
// import ExchangeMessages from 'widgets/exchangeMessages';
// import TradeMsgs from "widgets/tradeMessages";
// import TradebullsMsgs from "widgets/tradebullsMessages";
import SearchDialog from "widgets/SearchDialog";
// import Contracts from "widgets/contracts"
// import Feedback from "widgets/help"

import {
	TVChartContainer,
	DepthHOC,
	DetailQuote,
	ScripInfo,
	WatchList,
	ScripAnalysis,
	OrderReport,
	BracketOrderReport,
	PositionReport,
	DPSRReport,
	TradesReport,
	ULMarginReport,
	USPositionReport,
	Announcements,
	Indices,
	MarketSummary,
	OptionChainHOC,
	Disclaimer,
	ExchangeMessages,
	TradeMsgs,
	Contracts,
	Feedback,
	AdvisoryMsgs,
	ScripOverview,
	Alerts,
	TradebullsMsgs
} from 'widgets';

//import 'assets/css/dark.css';

// Component styles
import styles from './style';

import { getLayout, setLayout, loadLayout } from "./layouts/layoutHelper";
import { connect } from "react-redux";
import { LayoutActions } from "redux/Layout";
import FormDialog from "widgets/FormDialog";
import ConfirmDialog from 'widgets/ConfirmDialog';
import Snackbars from "components/Snackbars";
import Profile from "widgets/profile"
import Popper from "components/Popper";
import { UpdatesActions } from "redux/Updates";
import { AlertActions } from "redux/Alert";
import { ClientActions } from "redux/Client";
import { WebsocketClientContext } from "Main";
import { PrefsService } from 'services/prefs';
import { CommonActions } from "redux/Common";
import { MODAL_CHILD } from 'widgets/FormDialog';
import { DialogActions } from 'redux/Dialog';
import tourSteps from 'components/Tour/tourSteps';
import DataTut from "components/Tour/DataTut";
import getCookie, { deleteCookie, setCookie } from 'helpers/cookies';
import Loader from "components/Loader";
// import AdvisoryMsgs from "widgets/advisoryMsgs";
import { AddGridActions } from 'redux/NewGrid';
import Rejected from "widgets/reports/orderreport/status/Rejected";
import FullyExecuted from "widgets/reports/orderreport/status/FullyExecuted";
import Inprocess from "widgets/reports/orderreport/status/Inprocess";
import Pending from "widgets/reports/orderreport/status/Pending";
import Cancelled from "widgets/reports/orderreport/status/Cancelled";
import Expired from "widgets/reports/orderreport/status/Expired";
import Freezed from "widgets/reports/orderreport/status/Freezed";
import PartiallyExecuted from "widgets/reports/orderreport/status/PartiallyExecuted";
import Triggered from "widgets/reports/orderreport/status/Triggered";
import AMO from "widgets/reports/orderreport/status/AMO";
import { ReportsActions } from 'redux/Reports';
import { closeConfirmDialog, openConfirmDialog } from 'redux/ConfirmDialog';
import { store } from 'helpers';
import { history } from 'helpers';

let json = loadLayout(`${process.env.REACT_APP_USER}`);

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.layoutRef = React.createRef();
		this.state = { saveVersion: 0, layoutType: props.layoutType };

		let modelFromJson = FlexLayout.Model.fromJson(loadLayout(props.layoutType));
		this.FlexLayoutState = { model: modelFromJson };
		//TODO: read nodeIds from model here
		modelFromJson.visitNodes(this.registerNodes)
		// UpdatesActions.getExchangeMsg();
		let userType = `${process.env.REACT_APP_USER}`;
		LayoutActions.loadLayout(props.layoutType)
		// LayoutActions.loadLayout(userType == 'admin' ? 'ctclClient' : userType);

		this.checkMaxed(modelFromJson);
		CommonActions.setPathName('dashboard');

		//The below code will be used for place order for products
		// let params = new URLSearchParams(props.location.search);
		// let moduleId = params.get('module')
		// let orderDetails = params.get('orderdetails')
		// if (moduleId === "placeorder") {
		// 	DialogActions.dialogOpen(MODAL_CHILD.BULK_ORDER, JSON.parse(atob(orderDetails)));
		// }
		if (getCookie("tbBulkOrder")) {
			DialogActions.dialogOpen(MODAL_CHILD.BULK_ORDER, JSON.parse(atob(getCookie("tbBulkOrder"))));
		} else if (getCookie("tbEqSingleOrder")) {
			DialogActions.dialogOpen(MODAL_CHILD.ORDER, JSON.parse(atob(getCookie("tbEqSingleOrder"))));
		}
	}

	checkMaxed(model) {
		let maximizedTabset = model.getMaximizedTabset()
		if (maximizedTabset) {
			let maximizedTab = maximizedTabset.getSelectedNode().getComponent()
			LayoutActions.onTabMaxed(maximizedTab);
		} else {
			LayoutActions.onTabMaxed('');
		}
	}

	registerNodes = node => {
		if (node.getType() == "tab") {
			let nodeId = node.getId();
			let component = node.getComponent();
			let parentNode = node.getParent();
			let parentNodeType = parentNode.getType();
			if (parentNodeType == "tabset" /* || parentNodeType == "border" */) {
				let isActive = parentNode.isActive();
				if (isActive) {
					LayoutActions.setActiveTab(parentNode.getSelectedNode().getComponent());
				}
			}
			if (component !== "button" && component !== "text" && component !== "sub") {
				LayoutActions.setNodeId(component, nodeId);
			}
			//Do this if the node is inner node
			if (component == 'sub') {
				let subModel = node.getExtraData().model;
				if (subModel == null) {
					subModel = FlexLayout.Model.fromJson(node.getConfig().model);
				}
				subModel.visitNodes(this.registerNodes);
			}
			//For inner node
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.layoutType != this.props.layoutType
			|| nextProps.layoutJson != this.props.layoutJson) {
			LayoutActions.toggleScripInfo(false);
			ClientActions.setClient();
			this.FlexLayoutState = { model: FlexLayout.Model.fromJson(loadLayout(nextProps.layoutType)) };
			this.FlexLayoutState.model.visitNodes(this.registerNodes);
			this.checkMaxed(this.FlexLayoutState.model);
		}
		if (!this.props.chartOpen && nextProps.chartOpenCounter > this.props.chartOpenCounter) {
			this.toggleChart(nextProps.chartOpen);
		}
		if (!this.props.advisoryMsgsOpen && nextProps.advisoryMsgsOpenCounter > this.props.advisoryMsgsOpenCounter) {
			this.toggleAdvisoryMsgs(nextProps.advisoryMsgsOpen);
		}
		if (!this.props.tradeMsgsOpen && nextProps.tradeMsgsOpenCounter > this.props.tradeMsgsOpenCounter) {
			this.toggleTradeMsgs(nextProps.tradeMsgsOpen);
		}
		if (!this.props.priceAlertsOpen && nextProps.priceAlertsOpenCounter > this.props.priceAlertsOpenCounter) {
			this.togglePriceAlerts(nextProps.priceAlertsOpen);
		}
		if (!this.props.tradebullsMsgsOpen && nextProps.tradebullsMsgsOpenCounter > this.props.tradebullsMsgsOpenCounter) {
			this.toggleTradebullsMsgs(nextProps.tradebullsMsgsOpen);
		}
		if (nextProps.exchMsgs.length > this.props.exchMsgs.length) {
			let newCount = nextProps.exchMsgs.length - this.props.exchMsgs.length;
			// AlertActions.info(`${newCount} new exchange messages...`);
		}
		if (nextProps.activeTab !== this.props.activeTab) {
			// AlertActions.success('Active Tab: ' + nextProps.activeTab);
			this.setActiveTab(nextProps.activeTabId);
		}
		if (nextProps.maximizedView !== this.props.maximizedView) {
			if (nextProps.activeTabId) {
				let parentNode = this.FlexLayoutState.model.getNodeById(nextProps.activeTabId).getParent();
				this.FlexLayoutState.model.doAction(FlexLayout.Actions.maximizeToggle(parentNode.getId()));
			}
		}
		if (this.props.isOpen && !nextProps.isOpen) {
			if (this.props.modalChild === "bulk_order" && getCookie("tbBulkOrder")) {
				deleteCookie("tbBulkOrder")
			} else if (this.props.modalChild === "order" && getCookie("tbEqSingleOrder")) {
				deleteCookie("tbEqSingleOrder")
			}
		}
		if (nextProps.addNewGrid) {
			this.layoutRef.current.addTabWithDragAndDropIndirect(`Add ${(nextProps.gridData && nextProps.gridData.label) || "grid"}\n(Drag to location)`, {
				component: (nextProps.gridData && nextProps.gridData.name) || "grid",
				name: (nextProps.gridData && nextProps.gridData.label) || "Grid",
				enableClose: true
			}, this.onAdded);
			AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, {});
		}
	}

	componentDidMount() {
		this.setState({ saveVersion: this.props.saveVersion });
		PrefsService.getAllPrefs();
		if (this.props.layoutType == 'nextadmin') {
			// console.log('loadOrderReport');
			ReportsActions.getReportsOrder();
		}
		let params = new URLSearchParams(this.props.location.search);
		if (params?.get('success')) {
			let obj = {
				content: params.get('success') || 'success',
				hideCloseButton: true,
				okButtonText: 'Ok',
				centerActions: true,
				onClose: () => {
					store.dispatch(closeConfirmDialog()),
					history.push('/dashboard');
					LayoutActions.setActiveTab('dpsrreport');
				}
			};
			store.dispatch(openConfirmDialog(obj));
		} else if (params?.get('error')) {
			let obj = {
				content: params.get('error') || 'error',
				hideCloseButton: true,
				okButtonText: 'Ok',
				centerActions: true,
				onClose: () => {
					store.dispatch(closeConfirmDialog());
					history.push('/dashboard');
				}
			};
			store.dispatch(openConfirmDialog(obj));
		}
	}

	setActiveTab(activeTabId) {
		this.FlexLayoutState.model.doAction(FlexLayout.Actions.selectTab(activeTabId));
	}

	toggleTradeMsgs(open) {
		let cModel = this.FlexLayoutState.model;
		cModel.visitNodes((node, level) => this.toggleNode('tradeMsgs', open, node, level));
	}

	toggleAdvisoryMsgs(open) {
		let cModel = this.FlexLayoutState.model;
		cModel.visitNodes((node, level) => this.toggleNode('advisoryMsgs', open, node, level));
	}

	toggleChart(open) {
		let cModel = this.FlexLayoutState.model;
		cModel.visitNodes((node, level) => this.toggleNode('chart', open, node, level));
	}

	togglePriceAlerts(open) {
		let cModel = this.FlexLayoutState.model;
		cModel.visitNodes((node, level) => this.toggleNode('alerts', open, node, level));
	}

	toggleTradebullsMsgs(open) {
		let cModel = this.FlexLayoutState.model;
		cModel.visitNodes((node, level) => this.toggleNode('tradebullsMsgs', open, node, level));
	}

	toggleNode(nodeToToggle, toggleState, node, level) {
		if (node.getType() == "tab") {
			// let name = node.getName();
			// let visibility = node.isVisible();
			let component = node.getComponent();
			//Do this if the node is inner node
			// if (component == 'sub') {
			// 	let subModel = node.getExtraData().model;
			// 	if (subModel == null) {
			// 		subModel = FlexLayout.Model.fromJson(node.getConfig().model);
			// 	}
			// 	subModel.visitNodes((node, level) => this.toggleNode('chart', open, node, level));
			// }
			//For inner node

			if (/* toggleState && */ component == nodeToToggle) {
				node.getModel().doAction(FlexLayout.Actions.selectTab(node.getId()));
			}
		}
	}

	// static getDerivedStateFromProps(props, state){
	// 	//TODO: make layout changes here
	// 	console.log('getDerivedStateFromProps');
	// 	if(props.saveVersion>state.saveVersion){
	// 		return{saveVersion:props.saveVersion};
	// 	}
	// 	return null;
	// }

	getLayoutModel = (saveVersion) => {
		//   if (saveVersion>0){
		if (!this.props.isEditing) {
			this.FlexLayoutState = { model: FlexLayout.Model.fromJson(getLayout(this.props.isNew)) };
			// this.setState({saveVersion:this.props.saveVersion})
		}
		if (this.props.isNew) {
			this.FlexLayoutState = { model: FlexLayout.Model.fromJson(getLayout(this.props.isNew)) };
		}
		return this.FlexLayoutState.model;
	}
	//   save() {
	// 	var jsonStr = JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t");
	// 	localStorage.setItem("Layout", jsonStr);
	//   }

	classMappingtoJSS = className => {
		let newClassName
		//console.log(className);
		if (className == 'flexlayout__tab_button--selected') {
			newClassName = this.props.classes['flexlayout__tab_button_selected'];
		}
		else if (className == 'flexlayout__tab_button--unselected') {
			newClassName = this.props.classes['flexlayout__tab_button_unselected'];
		}
		else if (className == 'flexlayout__tabset-selected') {
			newClassName = this.props.classes['flexlayout__tabset_selected'];
		}
		else if (className == 'flexlayout__tabset-maximized') {
			newClassName = this.props.classes['flexlayout__tabset_maximized'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-min') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_min'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-max') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_max'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-float') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_float'];
		}
		else if (className == 'flexlayout__border_button--selected') {
			newClassName = this.props.classes['flexlayout__border_button_selected'];
		}
		else if (className == 'flexlayout__border_button--unselected') {
			newClassName = this.props.classes['flexlayout__border_button_unselected'];
		}
		else {
			// if (!this.props.classes[className]) {
			// 	console.log('classMappingtoJSS', className);
			// }
			newClassName = this.props.classes[className];
		}

		if (newClassName) {
			return newClassName;
		}
		else {
			// console.log('classMappingtoJSS', className);
			return className;
		}
		// return <FlexLayout.Layout model={model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} />;
	}

	factory = (node) => {
		if (!node.isVisible()) {
			// console.log('Node is not visible: '+node.getComponent())
			return null;
		}
		// let nodeId = node.getId();
		var component = node.getComponent();
		// if (component !== "button" && component !== "text" && component !== "sub") {
		// 	LayoutActions.setNodeId(component, nodeId);
		// }
		if (component === "button") {
			return <button>{node.getName()}</button>;
		}
		else if (component === "text") {
			return (<div className="panel">Panel {node.getName()}</div>);
		}
		else if (component === "sub") {
			var model = node.getExtraData().model;
			if (model == null) {
				model = FlexLayout.Model.fromJson(node.getConfig().model);
				//console.log(model)
			}
			return this.getFlexLayout(model);
		}
		else if (component === "scripoverview") {
			return <ScripOverview />;
		}
		else if (component === "chart") {

			return <TVChartContainer />;
		}
		else if (component === "marketdepth") {
			return <DepthHOC />
		}
		else if (component === "detailquote") {
			return <DetailQuote />
		}
		else if (component === "scripinfo") {
			return <ScripInfo />
		}
		else if (component === "watchlist") {
			return <DataTut tourKey={tourSteps.watchlistMain.key} Component={WatchList} />
		}
		else if (component === "scripanalysis") {
			return <ScripAnalysis />
		}
		else if (component === "orderreport") {
			return <DataTut tourKey={tourSteps.reports.key} Component={OrderReport} />
		}
		// else if (component === "bracketorderreport") {
		// 	return <DataTut tourKey={tourSteps.reports.key} Component={BracketOrderReport} />
		// }
		else if (component === "orderscancelled") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Cancelled} />
		}
		else if (component === "ordersexecuted") {
			return <DataTut tourKey={tourSteps.reports.key} Component={FullyExecuted} />
		}
		else if (component === "ordersexpired") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Expired} />
		}
		else if (component === "ordersfreezed") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Freezed} />
		}
		else if (component === "ordersinprocess") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Inprocess} />
		}
		else if (component === "orderspartiallyexecuted") {
			return <DataTut tourKey={tourSteps.reports.key} Component={PartiallyExecuted} />
		}
		else if (component === "orderspending") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Pending} />
		}
		else if (component === "ordersrejected") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Rejected} />
		}
		else if (component === "orderstriggered") {
			return <DataTut tourKey={tourSteps.reports.key} Component={Triggered} />
		}
		else if (component === "ordersamo") {
			return <DataTut tourKey={tourSteps.reports.key} Component={AMO} />
		}
		else if (component === "positionreport") {
			return <DataTut tourKey={tourSteps.reports.key} Component={PositionReport} />
		}
		else if (component === "dpsrreport") {
			return <DataTut tourKey={tourSteps.reports.key} Component={DPSRReport} />
		}
		else if (component === "tradereport") {
			return <DataTut tourKey={tourSteps.reports.key} Component={TradesReport} />
		} else if (component === "ulmarginreport") {
			return <DataTut tourKey={tourSteps.reports.key} Component={ULMarginReport} />
		}
		else if (component === "announcement") {
			return <Announcements />
		}
		else if (component === "indices") {
			return <Indices />
		}
		else if (component === "marketSummary") {
			return <MarketSummary />
		}
		else if (component === "optionChain") {
			return <OptionChainHOC />
		}
		else if (component === "disclaimer") {
			return <Disclaimer />
		}
		else if (component === "profile") {
			return <Profile />
		}
		else if (component === "exchangeMsgs") {
			return <ExchangeMessages />
		}
		else if (component === "tradeMsgs") {
			return <TradeMsgs />
		}
		else if (component === "tradebullsMsgs") {
			return <TradebullsMsgs />
		}
		else if (component === "uspositionreport") {
			return <DataTut tourKey={tourSteps.reports.key} Component={USPositionReport} />
		}
		else if (component === "contracts") {
			return <Contracts />
		}
		else if (component === "feedback") {
			return <Feedback />
		}
		else if (component === "advisoryMsgs") {
			return <AdvisoryMsgs />
		}
		else if (component === "alerts") {
			return <Alerts />
		}
	}

	onShowLayoutClick = (event) => {
		console.log(JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
	}

	onAction = action => {
		const { scripInfoNodeId, chartNodeId, chartOpen } = this.props;
		const { data: { fromNode, toNode, tabNode, tabsetNode }, type } = action;
		if (fromNode == scripInfoNodeId && type == FlexLayout.Actions.MOVE_NODE) {
			if (toNode == "border_right") {
				LayoutActions.toggleScripInfo(false);
			}
		}
		if (type == FlexLayout.Actions.SELECT_TAB) {
			if (tabNode == chartNodeId) {
				// LayoutActions.setChartState(!chartOpen);
			} else {
				let activeTab = this.FlexLayoutState.model.getNodeById(tabNode);
				try {
					let parentNode = activeTab.getParent();
					let parentNodeType = parentNode.getType();
					if (parentNodeType != 'border') {
						LayoutActions.setActiveTab(activeTab.getComponent());
					}
				} catch (error) {
					console.log(error);
				}
			}
		}
		if (type == FlexLayout.Actions.SET_ACTIVE_TABSET) {
			try {
				let activeTabSet = this.FlexLayoutState.model.getNodeById(tabsetNode);
				let activeTab = activeTabSet.getSelectedNode();
				LayoutActions.setActiveTab(activeTab.getComponent());
			} catch (error) {
				console.log(error);
			}
			// debugger
		}
		return action;
	}

	onRenderTab = model => {
		let name = model.getName();
		let nodeId = model.getId();
		let component = model.getComponent();
		let nodeParent = model.getParent();
		if (name == 'Overview' && nodeParent.type != "border_right") {
			if (nodeParent.type == 'border') {
				// debugger
				LayoutActions.toggleScripInfo(false);
			} else {
				// debugger
				LayoutActions.toggleScripInfo(true, nodeId);
			}
		}
		if (component == 'chart') {
			// debugger
			LayoutActions.setChartNodeId(nodeId);
		}
	}

	onRenderTabSet = tabset => {
		// console.log('onRenderTabSet: ')
		// console.log(tabset)
	}

	onModelChange = model => {
		//console.log("Action Called",JSON.stringify(model.toJson(), null, "\t"));
		//console.log("Model Changed" + model)

		//model.doAction(FlexLayout.Actions.maximizeToggle("Watchlist"));

		LayoutActions.layoutChanged(model.toJson());
		// setLayout(model.toJson());

		let maximizedTabset = model.getMaximizedTabset()
		if (maximizedTabset) {
			let maximizedTab = maximizedTabset.getSelectedNode().getComponent()
			LayoutActions.onTabMaxed(maximizedTab);
			LayoutActions.setActiveTab(maximizedTab);
		} else {
			LayoutActions.onTabMaxed('');
		}
	}

	// onAction = () =>{
	// 	console.log("Action Called",JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
	// 	return <FlexLayout.Layout model={this.FlexLayoutState.model} factory={this.factory} onModelChange={this.onModelChange} onAction = {this.onAction} classNameMapper={this.classMappingtoJSS}/>
	// }

	getFlexLayout = (model) => {
		return (
			<FlexLayout.Layout
				ref={this.layoutRef}
				model={model}
				factory={this.factory}
				onModelChange={this.onModelChange}
				classNameMapper={this.classMappingtoJSS}
				onRenderTab={this.onRenderTab}
				onRenderTabSet={this.onRenderTabSet}
				onAction={this.onAction} />
		)
	}

	render() {
		const { classes, saveVersion, layoutLoaded } = this.props;
		//   const { saveVersion, isNew } = this.state;
		//console.log(JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));

		return (
			// <DashboardLayout title="Dashboard">
			<WebsocketClientContext.Consumer>
				{client => {
					UpdatesActions.init(client);
					return (<div className={classes.root}>
						{/* <div><Button style={{ float: "right" }} onClick={this.onShowLayoutClick}>Show Layout JSON in Console</Button></div> */}
						{layoutLoaded /* && !layoutLoaded */ ?
							<div>{this.getFlexLayout(this.FlexLayoutState.model)}</div>
							: <Loader style={{ height: "100%" }} />}
						<SearchDialog />
						<FormDialog />
						<ConfirmDialog />
						<Snackbars />
						<Popper />
					</div>)
				}}
			</WebsocketClientContext.Consumer>
			// </DashboardLayout>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	const { activeTab, activeTabId, layoutType, layoutJson, tradeMsgsOpen, tradeMsgsOpenCounter, advisoryMsgsOpen, advisoryMsgsOpenCounter, chartOpen, chartOpenCounter, priceAlertsOpen, priceAlertsOpenCounter, chartNodeId, scripInfoNodeId, maximizedView, tradebullsMsgsOpen, tradebullsMsgsOpenCounter } = state.layout;
	const { layoutLoaded } = state.prefs;
	const { exchMsgs } = state.updates;
	const { isOpen, modalChild } = state.dialog;
	const { addNewGrid, gridData } = state.newGrid
	return { activeTab, activeTabId, layoutType, layoutJson, tradeMsgsOpen, tradeMsgsOpenCounter, advisoryMsgsOpen, advisoryMsgsOpenCounter, chartOpen, chartOpenCounter, priceAlertsOpen, priceAlertsOpenCounter, chartNodeId, scripInfoNodeId, exchMsgs, maximizedView, isOpen, modalChild, layoutLoaded, addNewGrid, gridData, tradebullsMsgsOpen, tradebullsMsgsOpenCounter };
};
export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
