import { Box, Checkbox, OutlinedInput, Select, StepConnector, Tooltip } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";

export function getDate(date) {
  if (date) {
    return new Date(date).getDate() + " " + new Date(date).toLocaleString('default', { date: 'numeric', month: 'short', year: 'numeric' })
  }
  return ''
}

export const QontoConnector = withStyles({
  root: {
    display: "none"
  }
})(StepConnector);

export const SelectOutlinedInput = withStyles(theme => ({
  root: {
    fontSize: "14px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
    "& $notchedOutline": {
      borderWidth: 0
    },
    "&:hover $notchedOutline": {
      borderWidth: 0
    },
    "&$focused $notchedOutline": {
      borderWidth: 0
    }
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {}
}))(OutlinedInput)

export const CssSelect = withStyles(theme => ({
  root: {
    padding: "8px 0px 8px 7px",
    [theme.breakpoints.down('xl')]: {
      padding: "4px 0px 4px 6px"
    },
    [theme.breakpoints.down('xl')]: {
      padding: "3px 0px 3px 6px"
    }
    /*  "&:hover $notchedOutline": {
       borderWidth: 2,
       borderColor: "#fff"
     },
     "&$focused $notchedOutline": {
       borderWidth: 2,
       borderColor: "#fff"
     },
     "&$error $notchedOutline": {
       borderWidth: 2,
       borderColor: "#fff"
     } */
  },
  select: {
    paddingRight: "0px !important"
  },
  outlined: {}
}))(Select)

export const CssOutlinedInput = withStyles(theme => ({
  root: {
    width: "100%",
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.color12.default
    },
    "&$error $notchedOutline": {
      borderWidth: 1,
      borderColor: "#f54040"
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    },
  },
  input: {},
  error: {},
  focused: {},
  notchedOutline: {
    borderColor: "#66788a4d"
  }
}))(OutlinedInput)

export const CssCheckbox = withStyles({
  root: {
    '&$checked': {
      color: "#497CAC",
    },
  },
  checked: {},
})(Checkbox)

export const CssTooltip = withStyles((theme) => ({
  arrow: {
    fontSize: "10px"
  },
  tooltip: {
    backgroundColor: theme.palette.background.darker,
    color: theme.palette.color15.default,
    padding: "8px",
    maxWidth: "150px",
    fontSize: "10px",
    border: `1px solid #66788a4d`,
    marginRight: "-50px"
  }
}))(Tooltip)

export function TabPanel(props) {
  const { children, value, index, boxClass, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={boxClass}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const stepperTabs = {
  "buckets": {
    BuyEQ: {
      tabLabel: "Buy Stocks",
      payloadKey: "equity"
    },
    BuyMF: {
      tabLabel: "Buy MF One-Time",
      payloadKey: "mfLumpsum"
    },
    SellEQ: {
      tabLabel: "Sell Stocks",
      payloadKey: "sellEq"
    },
    SellMF: {
      tabLabel: "Sell Mf",
      payloadKey: "sellMf"
    },
  },
  "baskets": {
    BuyEQ: {
      tabLabel: "Buy Stocks",
      payloadKey: "equity"
    },
    SellEQ: {
      tabLabel: "Sell Stocks",
      payloadKey: "sellEq"
    },
  },
  "optimizer": {
    SellEQ: {
      tabLabel: "Sell Stocks",
      payloadKey: "sellEq"
    },
    SellMF: {
      tabLabel: "Sell Mf",
      payloadKey: "sellMf"
    },
    BuyEQ: {
      tabLabel: "Buy Stocks",
      payloadKey: "equity"
    },
    BuyMF: {
      tabLabel: "Buy MF One-Time",
      payloadKey: "mfLumpsum"
    },
    BuyMFSIP: {
      tabLabel: "Buy MF SIP",
      payloadKey: "mfSIP"
    }
  },
  "target": {
    BuyEQ: {
      tabLabel: "Buy Stocks",
      payloadKey: "equity"
    },
    BuyEQSIP: {
      tabLabel: "Buy Stocks SIP",
      payloadKey: "equitySIP"
    },
    BuyMF: {
      tabLabel: "Buy MF One-Time",
      payloadKey: "mfLumpsum"
    },
    BuyMFSIP: {
      tabLabel: "Buy MF SIP",
      payloadKey: "mfSIP"
    },
    SellEQ: {
      tabLabel: "Sell Stocks",
      payloadKey: "sellEq"
    },
    SellMF: {
      tabLabel: "Sell Mf",
      payloadKey: "sellMf"
    }
  }
}

export const productName = {
  "buckets": "Investment Bucket",
  "baskets": "Tradebulls Basket",
  "optimizer": "Portfolio Optimizer",
  "target": "Target"
}

export const api = {
  accountDetails: "/ms-mf-lumpsumorder/lumpsum/getAccountAndMandateDetails",
  goal: {
    target: "/ms-content-data-parser/Goals/target/save/goal",
    buckets: "/ms-content-data-parser/analyzer/portfolio/bulk/create",
    baskets: "/ms-content-data-parser/analyzer/portfolio/bulk/create"
  },
  submit: {
    equitySIP: "/ms-order-placement/eqsip/push",
    mfSIP: "/ms-mf-sip/mfsip/bulk/placeSipOrder/new",
    eqMf: "/ms-order-placement/bulk/push"
  },
  data: {
    target: "/ms-content-data-parser/Goals/getGoalRecommendation",
    buckets: "/ms-content-data-parser/basket",
    baskets: "/ms-content-data-parser/basket",
    optimizer: "/ms-content-data-parser/analyzer/holding/analyzerOptimizer"
  }
}