import React, { useState, useEffect } from 'react';
import { Button, Paper, Tooltip, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { connect } from "react-redux";
import ReactTable from 'components/ReactTable';
import styles from './styles';
import { EDISService } from './edisServices';
import { DialogActions } from 'redux/ConfirmDialog';

const useStyles = makeStyles(styles);

function EDISAuthorization(props) {
  const classes = useStyles();
  const { data } = props;

  const [errorObj, setErrorObj] = useState({ error: false, loading: false, message: "", type: "" })
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState({})

  const { error, loading, message, type, otpGenerated } = errorObj || {};

  useEffect(() => {
    if (data?.length > 0)
      setSelectAllRows(true)
  }, [data])

  async function verifyTPIN() {
    let securitiesList = []
    Object.keys(selectedRowIds).forEach(i => {
      securitiesList.push({
        scripId: data[i].scripId,
        qtyAvailable: data[i].dpQty,
        qtyToBeTransferred: data[i].dpQty,
        exchange: data[i].exchange
      });
    })
    if (securitiesList?.length > 0) {
      setErrorObj({ ...errorObj, loading: true, type: "verify" })
      const verify = await EDISService.verifyTPIN({ redirectURI: location.href, ReqType: 'Pre', security: securitiesList })
      if (verify.error) {
        setErrorObj({ ...errorObj, loading: false, error: true, message: verify.error })
        setTimeout(() => {
          setErrorObj({ ...errorObj, loading: false, error: false, message: "" })
        }, 60000)
      }
    } else {
      let obj = {
        content: "Please select atleast one scrip",
        hideCloseButton: true,
        okButtonText: "Ok",
        centerActions: true,
        onClose: () => DialogActions.confirmDialogClose()
      }
      DialogActions.confirmDialogOpen(null, obj)
    }
  }

  async function generateTPIN() {
    setErrorObj({ ...errorObj, loading: true, type: "generate" })
    const verify = await EDISService.generateTPIN()
    if (verify.error) {
      setErrorObj({ ...errorObj, loading: false, error: true, message: verify.error })
      setTimeout(() => {
        setErrorObj({ ...errorObj, loading: false, error: false, message: "" })
      }, 60000)
    } else {
      setErrorObj({ ...errorObj, otpGenerated: true })
    }
  }

  function isSelectable(rowData) {
    return rowData.netQty != 0
  }

  const tableOptions = {
    width: 100,
    selectAllRows,
    sorting: false,
    draggable: false,
    columnResizing: false,
    selectableRows: true,
    isSelectable: isSelectable,
    selectedRowIds,
    onRowSelectionChange: (data) => {
      setSelectedRowIds(data)
    },
  }

  const tableColumns = [
    {
      id: 'scriptName',
      header: 'Instrument',
      accessorKey: 'scriptName',
      size: 396,
      cell: ({ row: { original: values } }) => (
        <Tooltip arrow title={values.scriptName}>
          <Typography className={classes.cellText}>
            {values.scriptName}
          </Typography>
        </Tooltip>
      )
    },
    {
      id: 'dpQty',
      header: 'Qty',
      accessorKey: 'dpQty',
      size: 150,
    }
  ]

  return (
    <Paper className={classes.root}>
      <Typography variant="h2" className={classes.textDefault}>Authorize Sell Transaction</Typography>
      <Typography variant="h6" style={{ paddingTop: 0 }} className={classes.description}>Electronic Delivery Instruction Slip, or e-DIS, is a facility which allows you to sell shares even if you have not submitted your power of attorney (POA). You’ll now have to use the CDSL TPIN and OTP to authorise the debit of securities from your demat account against a delivery sale transaction.</Typography>
      <Typography variant="h6" className={classes.description}>Also, you can pre-authorise the sale of your stocks at the beginning of each trading day so that you don't have to do it each time when you place a delivery sell order during the day. Pre-authorisation is valid for a maximum of one day.</Typography>
      {
        error &&
        <div className={classes.errorDiv}>
          <Typography className={classes.errorDivText}>{message}</Typography>
        </div>
      }
      <div style={{ height: (data.length * 30) + 42, maxHeight: 450 }}>
        <ReactTable
          options={tableOptions}
          columns={tableColumns}
          data={data?.length > 0 ? data : []}
        />
      </div>
      <div className={classes.buttonDiv}>
        <Button variant="contained" disabled={loading} onClick={verifyTPIN} className={classes.buttonColor}>
          {loading && type === "verify" && <CircularProgress size={20} style={{ marginRight: "12px", color: "#7d7d7d" }} />}Verify TPIN
        </Button>
        <Button variant="contained" disabled={loading || otpGenerated} onClick={generateTPIN} className={classes.buttonColor}>
          {loading && type === "generate" && <CircularProgress size={20} style={{ marginRight: "12px", color: "#7d7d7d" }} />}Generate TPIN
        </Button>
      </div>

    </Paper>
  );
}

const mapStateToProps = (state) => {
  const { client: { selectedClient }, layout: { layoutType }, dialog: { data } } = state;
  return { selectedClient, layoutType, data };
}

export default connect(mapStateToProps)(EDISAuthorization);
