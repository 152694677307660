import { lazy } from 'react';
import { LazyLoad } from 'components/LazyLoad';

export const TVChartContainer = LazyLoad(lazy(() => import('./charts/TVChartContainer')));
export const DepthHOC = LazyLoad(lazy(() => import('./marketdepth/DepthHOC')));
export const DetailQuote = LazyLoad(lazy(() => import('./detailquote')));
export const ScripInfo = LazyLoad(lazy(() => import('./scripinfo')));
export const WatchList = LazyLoad(lazy(() => import('./watchlist')));
export const WatchlistMF = LazyLoad(lazy(() => import('./watchlistmf')));
export const ScripAnalysis = LazyLoad(lazy(() => import('./scripanalysis')));
export const OrderReport = LazyLoad(lazy(() => import('./reports/orderreport')));
export const BracketOrderReport = LazyLoad(lazy(() => import('./reports/bracketorderreport')));
export const PositionReport = LazyLoad(lazy(() => import('./reports/positionreport')));
export const DPSRReport = LazyLoad(lazy(() => import('./reports/dpsrreport')));
export const TradesReport = LazyLoad(lazy(() => import('./reports/tradesreport')));
export const ULMarginReport = LazyLoad(lazy(() => import('./reports/ulmarginreport')));
export const USPositionReport = LazyLoad(lazy(() => import('./reports/unsettledpositionreport')));
export const Announcements = LazyLoad(lazy(() => import('./announcement')));
export const Indices = LazyLoad(lazy(() => import('./indices')));
export const MarketSummary = LazyLoad(lazy(() => import('./marketsummary')));
export const OptionChainHOC = LazyLoad(lazy(() => import('./optionchain/OptionChainHOC')));
export const Disclaimer = LazyLoad(lazy(() => import('./disclaimer')));
export const ExchangeMessages = LazyLoad(lazy(() => import('./exchangeMessages')));
export const TradeMsgs = LazyLoad(lazy(() => import('./tradeMessages')));
export const TradebullsMsgs = LazyLoad(lazy(() => import('./tradebullsMessages')));
export const Contracts = LazyLoad(lazy(() => import('./contracts')));
export const Feedback = LazyLoad(lazy(() => import('./help')));
export const AdvisoryMsgs = LazyLoad(lazy(() => import('./advisoryMsgs')));
export const ScripOverview = LazyLoad(lazy(() => import('./scripOverview')));
export const Alerts = LazyLoad(lazy(() => import('./alerts')));
