export default (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  hoverSection: {
    display: 'flex',
    color: theme.palette.color15.default,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    backgroundColor: theme.palette.common.blue,
    color: "#fff",
    fontWeight: 500,
    fontSize: "12px",
    padding: "4px 10px",
    margin: "0px 4px",
    borderRadius: "4px",
    textTransform: "none",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    },
    '&:hover': {
      backgroundColor: "#006CA880"
    },
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px",
      margin: "0px 2px"
    }
  }
});