export default ({ palette }) => ({
  styleOverrides: {
    root: {
      padding: '10px',
      color: palette.text.secondary,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)'
      },
      '&.Mui-disabled': {
        color: palette.common.muted
      }
    }
  }
});
