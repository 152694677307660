import axios from 'axios';
import { get2FA, getToken, setTokens } from './cookies';
import { goToLogin } from "./navHelpers";
import { store } from './store';

let USER = `${process.env.REACT_APP_USER}`

function generateRequestID(){
  let platform = USER == 'admin' ? 'N' : 'P' 
  let timestamp = new Date().getTime();
  let userID = localStorage.getItem('sub')

  return platform+userID.trim()+timestamp
}

var configReq = false;
var reqQueue = [];
function hitBack() {
  reqQueue.every(config => {
    axios(config)
    return true;
  })
  reqQueue = [];
}

axios.interceptors.request.use(function (config) {
  //console.log(config)
  // const Token = localStorage.getItem('access_token');
  const Token = getToken(true);
  const twoFA = get2FA();
  let Authorization;
  if (!config.auth && configReq) {
    reqQueue.push(config)
  }
  if (Token)
    Authorization = "Bearer " + Token;
  const req_info = {
    "rit": generateRequestID(),
    "cver": "1.0v",
    "ch": "WEB",
    //"info": { "2faToken": twoFA || null },
    "info": { "2faToken": '' },
    "reqts": '12345678',
    "payload": [],
  
  }

  config.headers = {
    "request-info": JSON.stringify(req_info),
    'Content-Type': 'application/json',
    Authorization,
    ...config.headers
  }
  if (!config.auth)
    if (!isFormData(config.data)) {
      config.data = {
        "payload": Array.isArray(config.data) ? config.data : [config.data]
      }
    }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {

  if (response.status === 400 || response.status === 401) {
    if (response.data && response.data.error)
      store.dispatch({ type: "USERS_LOGIN_FAILURE", error: response.data.error.error_description })
    // deleteCookie("token")
    localStorage.removeItem('isFirstLogin')
    // history.push('/login');
    goToLogin();
  }
  //return undefined;
  return response.data ? response.data : response;

}, function (error) {
  if (error.response && error.response.status === 401) {
    // auto logout if 401 response returned from api
    if (error.response && error.response.data.error)
      store.dispatch({ type: "USERS_LOGIN_FAILURE", error: error.response.data.error.error_description })
    //deleteCookie("token")
    localStorage.removeItem('isFirstLogin')
    // history.push('/login');
    goToLogin();
  }
  console.log(error)
  //return undefined;
  return Promise.reject(error);
});

const getTimeoutDelay = (access_token) => {
  var tkDetail = JSON.parse(atob(access_token.split(".")[1]));
  // let expiry = tkDetail.exp - tkDetail.iat;
  let expiry = tkDetail.exp - Math.ceil(new Date().getTime() / 1000);
  let delay = expiry - 100;
  let timeoutDelay = delay * 1000;
  console.log(`expiry: ${expiry}, delay: ${delay}, timeoutDelay: ${timeoutDelay}`);
  return timeoutDelay;
}

export const tokenReInitializer = function (expiry) {
  const aToken = getToken(true);
  if (aToken)
    configReq = true
  if (getTimeoutDelay(aToken) <= 0) {
    console.log('expiry: Refreshing Token');
    if (getToken(false)) {
      reInit().then(({ data: { success } }) => {
        configReq = false;
        localStorage.setItem('access_token', JSON.stringify(success));
        /* setCookie("token", success.access_token, "/") */
        // document.cookie="token="+success.access_token+";"

        setTokens(success.access_token);

        // setCookie("refresh_token", success.refresh_token, "/")
        // console.log("Cookie Set:", resp.access_token)
        initRefresh(success.access_token);
        // var tkDetail = JSON.parse(atob(success.access_token.split(".")[1]))
        // setTimeout(tokenReInitializer.bind(null, tkDetail.exp - tkDetail.iat - 100), (tkDetail.exp - tkDetail.iat - 100) * 1000)
        //setTimeout(tokenReInitializer.bind(null,tkDetail.exp-tkDetail.iat-10) , expiry)
        hitBack();
      })
    }
  } else {
    console.log('expiry: Token aleady refreshed');
    initRefresh(aToken);
  }
}
function isFormData(val) {
  return typeof FormData !== 'undefined' && val instanceof FormData;
}
function reInit() {
  return axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SERVER_AUTH_URL}${process.env.REACT_APP_AUTH_LOGIN}`, "grant_type=refresh_token&refresh_token=" + getToken(false) + "&clientId=tbsenterpriseweb", { auth: true, headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Basic dHJhZGVidWxsczp0cmFkZWJ1bGxz' } });
}

// window.onoffline = e => {
//   alert("Please check your network connection and try again")
//   history.push("/settings")
// }
// window.ononline = e => {
//   alert("You are online now.")
//   history.push("/dashboard")
// }

export default axios;
function initRefresh(access_token) {
  if (access_token) {
    // var tkDetail = JSON.parse(atob(access_token.split(".")[1]));
    // // let expiry = tkDetail.exp - tkDetail.iat;
    // let expiry = tkDetail.exp - Math.ceil(new Date().getTime() / 1000);
    // let delay = expiry - 100;
    // let timeoutDelay = delay * 1000;
    let timeoutDelay = getTimeoutDelay(access_token);
    //console.log(`expiry: ${expiry}, delay: ${delay}, timeoutDelay: ${timeoutDelay}`);
    setTimeout(tokenReInitializer.bind(null, timeoutDelay), timeoutDelay /* 10 * 1000 */);
  }
}
initRefresh(getToken(true));

export const payload = () => {
  const Token = getToken(true);
  let Authorization;
  if (Token)
    Authorization = "Bearer " + Token;
  return {
    "request-info": JSON.stringify({
      "rit": "123",
      "cver": "1.0v",
      "ch": "WEB",
      "info": "",
      "reqts": "12345678",
      "payload": []
    }),
    Authorization
  }
}

const fetch = window.fetch;
window.fetch = (...args) => (async(args) => {
    //console.log("Fetch Args ", args)
    // let chart_url = `${process.env.REACT_APP_API_URL}/ms-feed-service/trading/chart/symbols`
    // let test = args[0].toString().split('?');
    // if(test && test[0] == chart_url)
    //  {
    //   let params = (new URL(args[0])).searchParams;
    //   let symbol = params.get('symbol');
    //   console.log("Symbol ",symbol)
    //   let test1 = symbol.split(":")
    //   if(test1.length == 2){
    //     localStorage.setItem('chart-symbol',symbol)
    //   }
    //   else if(test1.length != 2){
    //    symbol = localStorage.getItem('chart-symbol');
    //    args[0] = test[0]+"?symbol="+encodeURIComponent(symbol)
    //   }
        
    //  } 
    const Token = getToken(true);
  const twoFA = get2FA();
  let Authorization;
  
  if (Token)
    Authorization = "Bearer " + Token;
  const req_info = {
    "rit": "123",
    "cver": "1.0v",
    "ch": "WEB",
    //"info": { "2faToken": twoFA || null },
    "info": { "2faToken": '' },
    "reqts": "12345678",
    "payload": []
  }

   args[1].headers = {
    "request-info": JSON.stringify(req_info),
    'Content-Type': 'application/json',
    Authorization,
    ...args[1].headers
  }
    var result = await fetch(args[0],args[1]);
    //console.log(result); // intercept response here
    return result;
})(args);
