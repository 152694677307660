import { withStyles } from '@mui/styles';
import React from 'react';

const Styles = theme => ({
    root: {
        background: theme.palette.background.primary,
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loader: {
        height: '12vh',
        borderRadius: '50%',
        // marginTop: '-18vh'
    }
});

function TBLoader(props) {
    return (
        <div className={props.classes.root} style={props.style}>
            <img
                src={'images/loading.gif'}
                // src={'images/tb_loader.gif'}
                alt="Loader"
                className={props.classes.loader}
            />
        </div>
    );
}

export default withStyles(Styles)(TBLoader);
