import React from "react";
import { Box, Rating, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { NumberFormatter } from "widgets/Portfolio/data";
import ActionsRow from "./ActionsRow";
import styles from "./styles";
import ReactTable from 'components/ReactTable';

function Peers(props) {
  const { classes, schemeFundDetails, propSchemeFundDetails } = props;

  let fundDetails = propSchemeFundDetails || schemeFundDetails;

  const columns = [
    {
      id: 'fundName',
      header: 'Scheme Name',
      accessor: 'fundName',
      size: 400,
      cell: ({ row: { original: values } }) => (
        <Tooltip arrow title={values.fundName}>
          <Typography className={classes.cellText}>
            {values.fundName}
          </Typography>
        </Tooltip>
      )
    },
    {
      id: 'actions',
      header: 'Actions',
      accessor: 'actions',
      size: 100,
      disableSortBy: true,
      disableFilter: true,
      cell: ({ row: { index } }) => (
        <ActionsRow rowData={fundDetails?.peers?.peersComparisonData?.length > 0 ? fundDetails.peers.peersComparisonData[index] : {}} isVisible={true} />
      )
    },
    {
      id: 'rating',
      header: 'Morningstar® rating',
      accessor: 'rating',
      disableSortBy: true,
      disableFilter: true,
      size: 200,
      cell: ({ row: { original: values } }) => (
        <Rating style={{ marginLeft: "4px" }} name="read-only" value={Number(values.rating)} readOnly />
      )
    },
    {
      id: 'oneYearRating',
      header: 'Returns 1Y',
      accessor: 'oneYearRating',
      className: classes.alignRight,
      size: 150,
      accessorFn: row => {
        return (row.oneYearRating && (Number(row.oneYearRating).toFixed(2)) || '0.00') + '%';
      }
    },
    {
      id: 'threeYearChange',
      header: 'Returns 3Y',
      accessor: 'threeYearChange',
      className: classes.alignRight,
      size: 150,
      accessorFn: row => {
        return (row.threeYearChange && (Number(row.threeYearChange).toFixed(2)) || '0.00') + '%';
      }
    },
    {
      id: 'fiveYearChange',
      header: 'Returns 5Y',
      accessor: 'fiveYearChange',
      className: classes.alignRight,
      size: 150,
      accessorFn: row => {
        return (row.fiveYearChange && (Number(row.fiveYearChange).toFixed(2)) || '0.00') + '%';
      }
    },
    {
      // header: 'Returns Since Inception{ ₹ }',
      id: 'returnSinceInception',
      header: 'Returns SI',
      accessor: 'returnSinceInception',
      className: classes.alignRight,
      size: 150,
      accessorFn: row => {
        return (row.returnSinceInception && (Number(row.returnSinceInception).toFixed(2)) || '0.00') + '%';
      }
    },
    {
      id: 'risk',
      header: 'Risk',
      accessor: 'risk',
      size: 150,
    },
    {
      id: 'fundSize',
      header: 'Fund Size',
      accessor: 'fundSize',
      className: classes.alignRight,
      size: 212,
      accessorFn: row => {
        return '₹ ' + ((row.fundSize && NumberFormatter(Number(row.fundSize))) || '0.00');
      }
    },
    {
      id: 'expenseRatio',
      header: 'Expense Ratio',
      accessor: 'expenseRatio',
      className: classes.alignRight,
      size: 150,
      accessorFn: row => {
        return Number(row.expenseRatio).toFixed(2) || "0.00";
      }
    }
  ]

  const options = {
    width: 150,
    sorting: true,
    draggable: false,
    columnResizing: true,
    selectableRows: false,
    refreshing: fundDetails === "Loading..." ? true : false
  };

  return (
    <div className={classes.root}>
      <Box style={{ paddingTop: 14, height: '100%' }}>
        <ReactTable
          options={options}
          columns={columns}
          data={fundDetails?.peers?.peersComparisonData || []}
        />
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { common: { fundDetails } } = state;
  return { schemeFundDetails: fundDetails };
}

export default connect(mapStateToProps)(withStyles(styles)(Peers))