import { Grid, Typography, Collapse } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';
import ChargesPopup from "./chargespopup";
import ChargeGrid from "./chargegrid";

function BrokerageCharges(props) {
  const { classes, brokerageCharges, compactView, open, onClose, showBrokerageCharges } = props;

  const { brokAmt, otherChargeAmt, totalAmt } = brokerageCharges || {}

  return (
    compactView ?
      <Collapse in={showBrokerageCharges}>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.mainGrid}>
            <Typography variant="h5" className={classes.heading}>
              Brokerage, Statutory & Regulatory Charges
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.chargesGrid}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2} className={classes.gridBorder}>
                    <ChargeGrid type={"Brokerage"} charge={brokAmt || 0} />
                  </Grid>
                  <Grid item xs={1} className={classes.centerContent}>
                    <Typography variant="h4" className={classes.primaryText}>+</Typography>
                  </Grid>
                  <Grid item xs={5} className={classes.gridBorder}>
                    <ChargeGrid type={"Other Statutory & Regulatory Charges"} charge={otherChargeAmt || 0} />
                  </Grid>
                  <Grid item xs={1} className={classes.centerContent}>
                    <Typography variant="h4" className={classes.primaryText}>=</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.gridBorder}>
                    <ChargeGrid type={"Total Charges"} charge={totalAmt || 0} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.secondaryText}>
              Note: The above charges are approximate. For actual transaction related charges, please refer your contact note for particular transaction on T + 1
              day. In case of market orders, brokerage charges are calculated as per LTP.
            </Typography>
          </Grid>
        </Grid>
      </Collapse >
      :
      <ChargesPopup
        open={open}
        onClose={onClose}
        brokerageCharges={brokerageCharges}
      />
  )
};

export default withStyles(styles)(BrokerageCharges);