import { MenuItem } from "@mui/material";
import { withStyles } from '@mui/styles';
import SelectBox from "components/Select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { openDialog } from "redux/Dialog";
import { MODAL_CHILD } from "widgets/FormDialog";

const styles = theme => ({
    paper: {
        padding: '6px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 0
    },
    formControl: {
        width: '100%',
        minWidth: 120
    },
    menuItem: {
        minWidth: '8vw'
    },
});

function WLDropdown(props) {
    const [wlList, setWlList] = useState([]);

    const { classes, lists, selectedList, onChange, noDefault, canAdd, createList, hideGlobal, hideHolding } = props;

    useEffect(() => {
        if (lists && lists.length > 0) {
            let newLists = [...lists];
            if (noDefault) {
                newLists.unshift({ id: "select", name: "Select Watchlist" });
            }
            setWlList(newLists);
        }
    }, [lists]);

    const handleWatchlistChange = e => {
        const { target: { value } } = e;
        if (value == 'new') {
            createList && createList();
        } else {
            onChange && onChange(e);
        }
    }

    const getWatchListItems = () => {
        return wlList && wlList.map(x => {
            if ((hideGlobal && x.type == "global") || (hideHolding && x.watchListName === "MyHolding"))
                return;
            return (
                <MenuItem className={classes.menuItem} value={x.id} key={x.id}>
                    {x.name}
                </MenuItem>
            )
        });
    }

    function watchlistCount() {
        let holding = lists.find(ele => ele.watchListName === "MyHolding")
        if(holding) {
            return 7
        } else {
            return 6
        }
    }

    return (
        <SelectBox
            selectionText='Select Watchlist'
            addText='Create New WatchList'
            value={noDefault ? wlList.length > 0 && wlList[0].id : selectedList != -1 ? selectedList : ''}
            onChange={handleWatchlistChange}
            data={wlList}>
            {getWatchListItems()}
            {canAdd && (lists && lists.length < watchlistCount()) && <MenuItem value="new">Create WatchList</MenuItem>}
        </SelectBox>
    )
}

const mapStateToProps = (state) => {
    const { watchlist: { selectedList, lists } } = state;
    return { selectedList, lists };
};

const mapDispatchToProps = dispatch => ({
    createList: () => dispatch(openDialog(MODAL_CHILD.WATCHLIST.CREATE)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WLDropdown));