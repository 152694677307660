import axios from 'helpers/interceptor';

export const contractDetailsService = {
  getContractDetails
};

function getContractDetails(payload) {
  const requestOptions = {
    method: 'POST',   
    url: `${process.env.REACT_APP_API_URL}/ms-feed-service/content/market/contractDetails`,
    data: payload
  };
  return axios(requestOptions)
    .then(response => {
      return response.data.success;
    })
    .catch(function (error) {
      console.log(error);
    })
}