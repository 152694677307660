import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography, Tooltip, IconButton, Grid } from "@mui/material"
import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from "@mui/icons-material";
import styles from "./styles";
import ChargeGrid from "./chargegrid";

function ChargesPopup(props) {
  const { classes, open, onClose, brokerageCharges } = props;
  const { brokAmt, otherChargeAmt, totalAmt } = brokerageCharges || {};

  return (
    <Dialog open={open} maxWidth={"sm"}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}>
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography className={classes.title}>
              {"Brokerage, Statutory & Regulatory Charges"}
            </Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <Tooltip title="Close" aria-label="close">
              <IconButton aria-label="close" onClick={onClose} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.contentGrid}>
          <Grid item xs={12} className={classes.gridItem}>
            <ChargeGrid type={"Brokerage"} charge={brokAmt || 0} />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <ChargeGrid type={"Other Statutory & Regulatory Charges"} charge={otherChargeAmt || 0} />
          </Grid>
          <Grid item xs={12} className={classes.totalGrid}>
            <ChargeGrid type={"Total Charges"} charge={totalAmt || 0} />
          </Grid>
          <Grid item xs={12} className={classes.gridItem} style={{ marginTop: 16, textAlign: 'justify', paddingBottom: 0 }}>
            <Typography variant="body2" className={classes.secondaryText}>
              Note: The above charges are approximate. For actual transaction related charges, please refer your contact note for particular transaction on T + 1
              day. In case of market orders, brokerage charges are calculated as per LTP.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  )
}

export default withStyles(styles)(ChargesPopup);