export default ({ palette }) => ({
  styleOverrides: {
    root: {
      color: palette.text.secondary,
      lineHeight: '1.1876em',
      '&.MuiInput-root:before': {
        borderBottom: `1px solid ${palette.text.primary}`
      }
    },
    input: {
      height: '1.1876em',
      '&.MuiInput-input': {
        padding: '6px 0 7px'
      }
    }
  }
});
