import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import Placeholder from 'components/Placeholder';
import { getFixedValue } from 'helpers/scripHelper';
import React, { useEffect, useState } from 'react';
import { customBreakpoints } from 'theme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    // minWidth: "435px", 
    // minHeight: "275px",
    // overflow: "auto"
  },
  paper: {
    width: '100%',
    height: '100%',
    overflowX: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    padding: 12,
    [theme.breakpoints.down('xl')]: {
      padding: 8,
    },
    [theme.breakpoints.down('md')]: {
      padding: 6,
    },
  },
  table: {
    textAlign: 'center',
    height: '100%',
  },
  greenColor: {
    backgroundColor: theme.palette.color7.primary,
    color: theme.palette.priceColor.green,
  },
  redColor: {
    backgroundColor: theme.palette.color8.primary,
    color: theme.palette.priceColor.red,
  },

  heading: {
    backgroundColor: theme.palette.color3.default,
  },
  head: {
    color: theme.palette.color11.default,
  },
  body: {
    color: theme.palette.color9.default,
  },
  ltt: {
    color: theme.palette.color11.primary,
  },
}));

const theme = createTheme({ breakpoints: customBreakpoints });

const marketdepththeme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: '6px 5px 6px 5px',
          '&:last-child': {
            paddingRight: 16
          },
          [theme.breakpoints.down('lg')]: {
            padding: '4px 3px 4px 3px'
          },
        },
        root: {
          borderBottom: '0px',
          fontSize: "14px",
          lineHeight: "20px",
          [theme.breakpoints.down('xl')]: {
            fontSize: '12px',
            lineHeight: "16px",
          },
          [theme.breakpoints.down('lg')]: {
            fontSize: '10px',
            lineHeight: "14px",
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '10px',
            lineHeight: "12px",
          }
        },
        head: {
          [theme.breakpoints.down('xl')]: {
            lineHeight: "16px",
          },
          [theme.breakpoints.down('lg')]: {
            lineHeight: "14px",
          },
          [theme.breakpoints.down('md')]: {
            lineHeight: "12px",
          },
        },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 30,
          backgroundColor: 'transparent',
          [theme.breakpoints.down('xl')]: {
            height: 28
          },
          [theme.breakpoints.down('lg')]: {
            height: 24
          },
          [theme.breakpoints.down('md')]: {
            height: 22
          },
        },
      }
    }
  },
  palette: {
    text: {
      secondary: '#66788A'
    }
  }
});

function MarketDepth(props) {
  const classes = useStyles();
  // let {rows:propRows, propScrip} = props;
  const { rows: propRows, isIndex, depthError } = props;
  const [rows, setRows] = useState(propRows || {});
  // const client = useContext(WebsocketClientContext);

  useEffect(() => {
    setRows(propRows || {});
  }, [propRows]);

  //   function getFormattedPayload(state) {
  //     var formattedPayload = []
  //     state.map(company => {
  //         formattedPayload.push(getPayload2(company));
  //     })
  //     // debugger
  //     return formattedPayload
  // }

  // useEffect(() => {
  //   if(propRows)
  //   setRows(propRows);
  // }, [propRows])

  //  useEffect(() => {
  //   if (propScrip) {
  //       const subscription = client.subscribe("/user/queue/globalSearchdepth", (message) => {
  //           if (message.body) {
  //               //console.log("Market Depth , got message with body " + message.body)
  //               if (!JSON.parse(message.body).data.error[0]){
  //                 setRows(JSON.parse(message.body).data.success[0])
  //                 //rows=JSON.parse(message.body).data.success[0];
  //               }
  //           } else {
  //               console.log("got empty message");
  //           }
  //       });
  //       client.send('/app/globalSearchdepth', { 'request-info': JSON.stringify({ 'rit': '123', 'cver': '1.0v', 'ch': 'WEB', 'info': {}, 'reqts': '' }) }, JSON.stringify({ 'payload': getFormattedPayload([propScrip]) }));
  //       return () => {
  //           // Clean up the subscription
  //           subscription.unsubscribe();
  //       };
  //   }
  // }, [propScrip]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={marketdepththeme}>
        <div className={classes.root}>
          {
            (isIndex || depthError) ?
              <Placeholder
                loading={false}
                loadingMsg={""}
                error={depthError || "No Depth Available"}
              />
              :
              <Paper className={classes.paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead className={classes.heading}>
                    <TableRow className={classes.tableHead}>
                      <TableCell className={classes.head} align="center">Buyers</TableCell>
                      <TableCell className={classes.head} align="center">Qty</TableCell>
                      <TableCell className={classes.head} align="center">Buy Price</TableCell>
                      <TableCell className={classes.head} align="center">Sell Price</TableCell>
                      <TableCell className={classes.head} align="center">Qty</TableCell>
                      <TableCell className={classes.head} align="center">Sellers</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.body} align="center">{rows.bdNoOrder1 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.bidQty1 || 0}</TableCell>
                      <TableCell className={classes.greenColor} align="center">{getFixedValue(rows.bidPrice1, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.redColor} align="center">{getFixedValue(rows.askPrice1, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askQty1 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askNoOrder1 || 0}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.body} align="center">{rows.bdNoOrder2 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.bidQty2 || 0}</TableCell>
                      <TableCell className={classes.greenColor} align="center">{getFixedValue(rows.bidPrice2, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.redColor} align="center">{getFixedValue(rows.askPrice2, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askQty2 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askNoOrder2 || 0}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.body} align="center">{rows.bdNoOrder3 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.bidQty3 || 0}</TableCell>
                      <TableCell className={classes.greenColor} align="center">{getFixedValue(rows.bidPrice3, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.redColor} align="center">{getFixedValue(rows.askPrice3, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askQty3 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askNoOrder3 || 0}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.body} align="center">{rows.bdNoOrder4 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.bidQty4 || 0}</TableCell>
                      <TableCell className={classes.greenColor} align="center">{getFixedValue(rows.bidPrice4, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.redColor} align="center">{getFixedValue(rows.askPrice4, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askQty4 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askNoOrder4 || 0}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.body} align="center">{rows.bdNoOrder5 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.bidQty5 || 0}</TableCell>
                      <TableCell className={classes.greenColor} align="center">{getFixedValue(rows.bidPrice5, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.redColor} align="center">{getFixedValue(rows.askPrice5, rows.segment) || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askQty5 || 0}</TableCell>
                      <TableCell className={classes.body} align="center">{rows.askNoOrder5 || 0}</TableCell>
                    </TableRow>

                    <TableRow className={classes.heading}>
                      <TableCell className={classes.body} align="center"></TableCell>
                      <TableCell className={classes.body} align="center">{rows.totalBuyQty || 0}</TableCell>
                      <TableCell className={classes.ltt} align="center" colSpan={2}>Total Qty</TableCell>
                      <TableCell className={classes.body} align="center">{rows.totalSellQty || 0}</TableCell>
                      <TableCell className={classes.body} align="center"></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.ltt} colSpan={6}>Last Updated Time : {rows.updateOn || "-"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


export default MarketDepth;
