// import { store } from 'helpers/store';
import { canCall } from "./pollingHelper";
import { getUniqueKey } from "./scripHelper";
import { store } from "./store";

export function getPayload1(scrip, addToken) {
    const { symbol, scripName, segment, exchange, token, isMarketIndex, scripId, bseScripCode, scriptName, shortCode } = scrip;
    return {
        symbol: segment == 'EQ' ? symbol : (scripName || scriptName),
        segment,
        exchange,
        shortCode,
        scripId,
        token: addToken ? (isMarketIndex ? scripId :
            exchange === 'BSE' ? (bseScripCode || token) : token)
            : undefined
    };
}

export function getFeedPayloadForPosition(data, addToken) {
    var formatted = [];
    data.map((scrip) => {
        const { scripId, exchange, segment, token } = scrip;
        const payload = {
            scripId: scripId,
            exchange: (exchange).toUpperCase(),
            segment: (segment).toUpperCase(),
            token: addToken ? token : undefined
        };
        if (payload) formatted.push(payload);
    });
    return formatted;
}

export function getPayload2(scrip) {
    const { symbol, scripName, segment, shortCode } = scrip;
    return {
        // symbol,
        symbol: segment == 'EQ' ? symbol : scripName,
        shortCode
    };
}

function getPayloadForFormatting(scrip, addToken) {
    // const {
    //     common: { market }
    // } = store.getState();
    const { symbol, scripName, segment, exchange, shortCode, token } = scrip;
    // if (market[shortCode]) {
    if (canCall(shortCode)) {
        return {
            symbol: segment == 'EQ' ? symbol : scripName,
            segment,
            exchange,
            token: addToken ? token : undefined
        };
    }
    return null;
}

export function getFormattedPayload1(data, addToken) {
    var formatted = [];
    data.map((scrip) => {
        const payload = getPayloadForFormatting(scrip, addToken);
        if (payload) formatted.push(payload);
    });
    return formatted;
}

function getPayloadForWatchlist(scrip, selectedScrip) {
    const isSelected = getUniqueKey(scrip) === getUniqueKey(selectedScrip);
    const { shortCode, token } = scrip;
    if (canCall(shortCode)) {
        return {
            shortCode,
            token,
            isSel: isSelected || undefined,
        };
    }
    return null;
}

export function getFormattedPayloadWatchlist(data) {
    const { selectedScrip } = store.getState().common;
    var formatted = [];
    data.map((scrip) => {
        const payload = getPayloadForWatchlist(scrip, selectedScrip);
        if (payload) formatted.push(payload);
    });
    // console.log('NewFormattedPayload', formatted);
    // debugger
    return formatted;
}

export const getSocketPayload = scrips => {
    if (!scrips) return [];
    if (scrips.length == 0) return [];
    const payload = [];
    scrips.map((scrip) => {
        const { shortCode, token } = scrip;
        payload.push({ shortCode, token });
    });
    return payload;
}
