export default theme => ({
  formRoot: {
    width: "900px",
    margin: "0px -6px",
    [theme.breakpoints.down('xl')]: {
      width: "720px",
    },
    [theme.breakpoints.down('md')]: {
      width: "600px",
    }
  },
  loadingDiv: {
    height: "400px"
  },
  input: {
    padding: "8px",
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xl')]: {
      padding: "6px"
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#808080",
      '-webkit-text-fill-color': "#808080"
    }
  },
  primaryText: {
    color: theme.palette.text.primary,
    "&.Buy": {
      color: "#299D00",
    },
    "&.Sell": {
      color: "#f54040",
    },
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  blueText: {
    color: theme.palette.common.blue
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  grid: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.color5.default}`,
    backgroundColor: theme.palette.background.default,
    padding: "10px 12px",
    marginBottom: "4px"
  },
  form: {
    padding: "8px"
  },
  termsDiv: {
    marginBottom: "8px",
    marginTop: "6px"
  },
  checkboxRoot: {
    color: theme.palette.text.primary,
    padding: "0px 8px"
  },
  checkboxMargin: {
    marginLeft: "-16px",
    [theme.breakpoints.down('lg')]: {
      marginLeft: "-20px",
    }
  },
  order: {
    width: "100%",
    color: "#fff",
    background: theme.palette.common.blue,
    textTransform: "none",
    marginLeft: "12px",
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  outlinedButton: {
    color: theme.palette.common.blue,
    border: `1px solid ${theme.palette.common.blue}`,
    textTransform: "none",
    padding: "4px",
    fontSize: "10px",
    '&:disabled': {
      background: "#c5c6c8",
      color: "#808080",
    },
    '&.selected': {
      color: theme.palette.color15.default,
      border: `1px solid ${theme.palette.color15.default}`,
    }
  },
  errorText: {
    color: "#f54040",
    marginTop: "3px"
  },
  approxDiv: {
    borderRadius: "4px",
    background: theme.palette.background.secondary,
    padding: "8px",
    margin: "4px 0px"
  },
  bottomInfoDiv: {
    marginBottom: "6px",
    padding: "12px 8px",
    borderBottom: `1px dashed ${theme.palette.color15.default}`
  },
  gridItem: {
    padding: "6px 8px",
    marginBottom: "1px",
    border: `1px solid #66788a4d`,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headerPadding: {
    padding: "0px 8px 4px"
  },
  gridHeight: {
    maxHeight: "188px",
    overflowY: "auto"
  },
  termsGrid: {
    margin: "8px 0px",
    paddingLeft: "8px"
  },
  dashedGrid: {
    borderRight: `1px dashed ${theme.palette.color15.default}`
  },
  span: {
    fontSize: "14px",
    color: theme.palette.common.blue,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
  },
  tooltip: {
    color: theme.palette.color15.default,
    fontSize: "10px",
  },
  info: {
    marginLeft: theme.spacing(2.5),
    cursor: "pointer",
    marginTop: "-2px",
    color: theme.palette.color15.default,
  },
  spacedContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  valueGridItem: {
    padding: "12px",
    borderBottom: "1px dashed #66788a4d",
    [theme.breakpoints.down('lg')]: {
      padding: "12px 10px"
    },
    [theme.breakpoints.down('md')]: {
      padding: "10px 8px"
    }
  },
  valueHeaderGrid: {
    padding: "8px 18px",
    borderBottom: `1px dashed ${theme.palette.color15.default}`
  },
  confirmGridItem: {
    maxHeight: "290px",
    overflowY: "auto",
    [theme.breakpoints.down('xl')]: {
      maxHeight: "290px",
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: "214px",
    },
  },
  sendOrder: {
    color: "#fff",
    background: "#f54040",
    textTransform: "none",
    width: "100%",
    marginLeft: "12px",
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  cancel: {
    width: "100%",
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.color5.default}`,
    textTransform: "none",
    color: theme.palette.color15.default,
    '&:hover': {
      backgroundColor: "#c5c6c826",
      border: `1px solid ${theme.palette.color5.default}`,
    },
    '&:disabled': {
      backgroundColor: "#c5cbce",
      color: "#7d7d7d"
    }
  },
  buttonGrid: {
    display: "flex",
    alignItems: "center"
  },
  confirmGrid: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.color5.default}`,
    borderRadius: "4px"
  },
  confirmValueGrid: {
    padding: "10px 12px 0px 12px",
    marginBottom: "8px",
    borderRadius: theme.spacing(0.5),
    border: `1px solid #66788a4d`,
    [theme.breakpoints.down('lg')]: {
      padding: "10px 10px 0px 10px"
    },
    [theme.breakpoints.down('md')]: {
      padding: "10px 8px 0px 8px"
    }
  },
  confirmTitle: {
    borderBottom: "1px solid #66788a4d",
    paddingBottom: "12px",
    paddingTop: "6px",
    borderRadius: "4px"
  },
  /* confirmAll: {
    background: theme.palette.common.blue,
    color: "#fff",
    textTransform: "none",
    width: "100%",
    padding: "2px",
    "&.all": {
      background: "none",
      border: `1px solid ${theme.palette.common.blue}`,
      color: theme.palette.common.blue
    },
    '&:disabled': {
      background: "#c5c6c8",
      color: "#808080",
    }
  }, */
  toggleButton: {
    width: "120px",
    [theme.breakpoints.down('xl')]: {
      width: "100px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "80px",
    },
  },
  productTitle: {
    paddingTop: "8px",
    display: "flex"
  },
  quontoStepper: {
    // marginBottom: "32px",
    padding: "0px",
    backgroundColor: theme.palette.background.default,
    paddingTop: "20px",
    [theme.breakpoints.down('xl')]: {
      paddingTop: "18px",
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: "16px",
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: "12px",
    },
  },
  square: {
    width: "10px",
    height: "10px",
    backgroundColor: theme.palette.color15.default,
    "&.active": {
      backgroundColor: theme.palette.common.blue
    },
    [theme.breakpoints.down('xl')]: {
      width: "8px",
      height: "8px"
    }
  },
  tabDiv: {
    width: "160px",
    marginRight: "-8px",
    [theme.breakpoints.down('xl')]: {
      width: "130px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "120px",
    },
  },
  tabBorder: {
    height: "6px",
    backgroundColor: theme.palette.color5.default,
    [theme.breakpoints.down('xl')]: {
      height: "4px",
    },
    "&.active": {
      backgroundColor: theme.palette.common.blue,
    },
    "&.completed": {
      backgroundColor: "#299D00",
    }
  },
  tabText: {
    color: theme.palette.color15.default,
    fontSize: "14px",
    paddingLeft: "8px",
    "&.active": {
      color: theme.palette.common.blue,
    },
    "&.completed": {
      color: "#299D00",
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
    }
  },
  checkIcon: {
    color: "#299D00",
    width: "16px",
    height: "16px",
    [theme.breakpoints.down('xl')]: {
      width: "12px",
      height: "12px",
    }
  },
  tabTitleDiv: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "12px",
    [theme.breakpoints.down('xl')]: {
      paddingBottom: "8px",
    },
  },
  select: {
    border: "1px solid #66788a4d",
    // width: "220px",
    color: theme.palette.color12.default
  },
  menuItem: {
    padding: "6px 12px",
    // backgroundColor: theme.palette.background.default
  },
  checkboxMargin: {
    marginLeft: "-16px",
    [theme.breakpoints.down('lg')]: {
      marginLeft: "-20px",
    }
  },
  checkboxRoot: {
    color: theme.palette.text.primary,
    // padding: "0px 8px"
  },
  formField: {
    width: "calc(100% - 8px)",
    margin: "0px 4px",
    [theme.breakpoints.down('xl')]: {
      width: "calc(100% - 4px)",
      margin: "0px 2px",
    }
  },
  fieldText: {
    color: theme.palette.common.blue,
    fontSize: "12px",
    textAlign: "center",
    marginTop: "8px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "10px"
    }
  },
  spanValue: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    paddingLeft: "10px",
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px"
    }
  },
  endElement: {
    display: "flex",
    justifyContent: "flex-end"
  },
  errorDiv: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    borderColor: "1px solid #f5c6cb",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "4px",
    textAlign: "center",
    marginBottom: "4px",
    [theme.breakpoints.down('xl')]: {
      padding: "3px",
    },
    [theme.breakpoints.down('lg')]: {
      padding: "2px",
    }
  },
  mandateButton: {
    color: "#fff",
    background: theme.palette.common.blue,
    textTransform: "none",
    marginTop: 24,
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.common.blue + 80
    },
    [theme.breakpoints.down('xl')]: {
      marginTop: 22,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 20,
    }
  },
  fieldDiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  clientGrid: {
    display: 'flex',
    padding: '8px 0px 0px 8px',
    alignItems: 'center'
  }
})