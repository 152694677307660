import { store } from 'helpers';
import { StorageHelper } from 'helpers/storageHelper';

const PREFS_ALL_UPDATED = 'ALL_PREFS_UPDATED';
const PREFS_SORT_UPDATED = 'PREFS_SORT_UPDATED';
const PREFS_SORT_UPDATED_MF = 'PREFS_SORT_UPDATED_MF';
const PREFS_PENDING_CUSTOM_SORT = 'PREFS_PENDING_CUSTOM_SORT';
const PREFS_THEME_UPDATED = 'PREFS_THEME_UPDATED';
const PREFS_ADVISORY_UPDATED = 'PREFS_ADVISORY_UPDATED';
const PREFS_LIST_UPDATED = 'PREFS_LIST_UPDATED';
const LAYOUT_LOAD_SUCCESS = 'LAYOUT_LOAD_SUCCESS';

export const PrefsActions = {
    onPrefsUpdated,
    onSortUpdated,
    onSortUpdatedMF,
    onPendingSortUpdate,
    onThemeUpdated,
    onAdvisoryUpdated,
    onSelectedListUpdated,
    onLayoutLoaded
};

function onLayoutLoaded(data, isMF) {
    store.dispatch({ type: LAYOUT_LOAD_SUCCESS, data, isMF });
}

function onPrefsUpdated(data, isMF) {
    store.dispatch({ type: PREFS_ALL_UPDATED, data, isMF });
}

function onSortUpdated(data) {
    store.dispatch({ type: PREFS_SORT_UPDATED, data });
}

function onSortUpdatedMF(data) {
    store.dispatch({ type: PREFS_SORT_UPDATED_MF, data });
}

function onPendingSortUpdate(data) {
    store.dispatch({ type: PREFS_PENDING_CUSTOM_SORT, data });
}

function onThemeUpdated(data) {
    store.dispatch({ type: PREFS_THEME_UPDATED, data });
}

function onAdvisoryUpdated(data) {
    store.dispatch({ type: PREFS_ADVISORY_UPDATED, data });
}

function onSelectedListUpdated(data) {
    store.dispatch({ type: PREFS_LIST_UPDATED, data });
}

export function prefsReducer(state = initialState, action) {
    const { type, data } = action;
    switch (action.type) {
        case PREFS_ALL_UPDATED:
            let { colorTheme, layoutJson, isWebResearchNotification: advisoryNotification } = data;
            let { isMF } = action;
            let newState = {
                ...state,
                ...data,
                advisoryNotification: advisoryNotification == null
                    ? StorageHelper.getAdvisoryNotification() : advisoryNotification,
                colorTheme: colorTheme != -1 ? colorTheme : state.colorTheme,
                layoutJson: !isMF ? layoutJson : state.layoutJson,
                layoutJsonMF: isMF ? layoutJson : state.layoutJsonMF
            };
            return newState;

        case LAYOUT_LOAD_SUCCESS:
            let { success } = data;
            return {
                ...state,
                layoutLoaded: success,
                layoutJson: !action.isMF ? data.layoutJson : state.layoutJson,
                layoutJsonMF: action.isMF ? data.layoutJson : state.layoutJsonMF
            };

        case PREFS_SORT_UPDATED:
            let {
                sortOption: { type, direction },
                customSort
            } = data;
            return {
                ...state,
                sortOption: {
                    type,
                    direction
                },
                customSort
            };

        case PREFS_SORT_UPDATED_MF:
            let {
                sortOption: { type: typeMF, direction: directionMF },
                customSort: customSortMF
            } = data;
            return {
                ...state,
                sortOptionMF: {
                    type: typeMF,
                    direction: directionMF
                },
                customSortMF
            };

        case PREFS_PENDING_CUSTOM_SORT:
            return {
                ...state,
                pendingCustomSort: data
            };

        case PREFS_THEME_UPDATED:
            return {
                ...state,
                colorTheme: action.data
            };

        case PREFS_ADVISORY_UPDATED:
            return {
                ...state,
                advisoryNotification: action.data
            };

        case PREFS_LIST_UPDATED:
            return {
                ...state,
                selectedWatchList: action.data,
                selectedWatchListId: action.data.type
            };

        default:
            return state;
    }
}

const initialState = {
    layoutLoaded: false,
    sortOption: StorageHelper.getSortOption(),
    gridList: StorageHelper.getGridList(),
    customSort: StorageHelper.getCustomSort(),
    pendingCustomSort: false,
    colorTheme: StorageHelper.getColorTheme(),
    selectedWatchListId: StorageHelper.getSelectedList(),
    orderView: StorageHelper.getOrderView(),
    advisoryNotification: StorageHelper.getAdvisoryNotification(),
    layoutJson: StorageHelper.getLayoutJson(),
    layoutJsonMF: StorageHelper.getLayoutJson(true),
    selectedWatchListMF: StorageHelper.getSelectedListMF(),
    sortOptionMF: StorageHelper.getSortOptionMF(),
};
