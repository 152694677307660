import { withStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import { Event } from 'components/Event';
import { EventConstants } from 'helpers/eventHelper';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { DialogActions } from 'redux/Dialog';
import { FundExplorerActions } from "redux/FundExplorer";
import ActionBar from './ActionBar';
import FilterPanel from './FilterPanel';
import SearchResultsPanel from './SearchResultsPanel';
import { FundExplorerService } from './service';

const styles = (theme) => ({
    container: {
        width: '45vw',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.color14.default}`,
        padding: `4px 8px`,
        marginLeft: -15,
        marginRight: -15,
        borderRadius: 5
    },
    listContainer: {
        overflowY: 'auto'
    }
});

let searchSubmitted = false;

const event = new Event(EventConstants.SEARCH_FILTER_SUBMIT);

function SearchFilterDialog(props) {
    const { classes, /* autoCompleteResults, */ onSearchSelected, showFilters: propsShowFilters, searchString: propSearchString } = props;

    const [loading, setLoading] = useState(true);
    const [showFilters, setShowFilters] = useState(propsShowFilters);
    const [isResult, setIsResult] = useState(false);
    const [filterSelection, setFilterSelection] = useState(props.filterSelection);
    const [defaultData, setDefaultData] = useState();
    // const [defaultList, setDefaultList] = useState(/* scrips ||  */[]);
    const [filterResults, setFilterResults] = useState();
    const [searchResults, setSearchResults] = useState();
    const [autoCompleteResults, setAutoCompleteResults] = useState();
    const [searchString, setSearchString] = useState(propSearchString);

    useEffect(() => {
        FundExplorerActions.toggleSearchDialog(true);
        return () => {
            // debugger
            if (propsShowFilters) {
                FundExplorerActions.toggleFilters(false);
                // setShowFilters(false);
            }
            // if (!searchSubmitted) {
            //     FundExplorerActions.setSearchString(null, true);
            // }
            FundExplorerActions.toggleSearchDialog(false);
        }
    }, []);

    // const setShowFilters = (value) => {
    //     if (value != showFilters) {
    //         FundExplorerActions.toggleFilters(value);
    //     }
    // }

    // const setSearchString = (value, submitted) => {
    //     FundExplorerActions.setSearchString(value, submitted);
    // }

    useEffect(() => {
        if (showFilters) {
            getFilters();
        }
    }, [showFilters]);

    const setSearchSubmitted = (value) => {
        searchSubmitted = value;
    };

    async function getFilters() {
        setLoading(true);
        setSearchSubmitted(false);
        const response = await FundExplorerService.getFundFilters(searchString && searchString.value ? searchString.value : null);
        setFilterResults(response);
        // debugger
    }

    async function getAutoCompleteSearchResults(text) {
        setLoading(true);
        setSearchSubmitted(false);
        const response = await FundExplorerService.getSearchAutoComplete(text);
        if (!searchSubmitted) {
            setLoading(false);
            setAutoCompleteResults(response || []);
        }
    }

    const onSearchTextChanged = (text) => {
        // setSearchString(text, false);
        // return;
        if (text && text.length > 2) {
            setSearchString({ label: text, value: text });
            getAutoCompleteSearchResults(text);
        }
    };

    const onSearchSubmit = (selection, searchByCode) => {
        // debugger
        FundExplorerActions.setSearchString(selection, true, searchByCode);
        DialogActions.dialogClose();
        return;
        // debugger
        if (selection && selection.label) {
            // debugger;
            setFilterSelection();
            setSearchString(selection);
            // setAutoCompleteResults();
            setShowFilters(false);
            // getSearchResults(selection.label);
            // onSearchSelected(selection);
            event.sendMessage({
                search: selection,
                filter: filterSelection
            });
            DialogActions.dialogClose();
        }
    };

    const onClearSearch = () => {
        setSearchString(null, true);
        return;
        // debugger
        setIsResult(false);
        setSearchString();
        // setAutoCompleteResults();
        setShowFilters(false);
        setSearchResults();
        // getSearchResults(selection.label);
    };

    const onFilterChange = (selection, topFunds) => {
        FundExplorerActions.setFilterSelection(selection, topFunds);
        DialogActions.dialogClose();
        return;
        event.sendMessage({ search: searchString, filter: selection });
        DialogActions.dialogClose();
    };

    const showClearAll = !showFilters && (searchString || filterSelection);

    return (
        <Box className={classes.container}>
            <ActionBar
                hideSelect={true}
                showFilters={showFilters}
                setShowFilters={(showHide, searchStr) => {
                    // debugger
                    setShowFilters(showHide);
                    // setSearchResults()
                    // setAutoCompleteResults();
                    setSearchString({ value: searchStr, label: searchStr });
                }}
                onSearchTextChanged={onSearchTextChanged}
                onSearchSelected={onSearchSubmit}
                onClearSearch={onClearSearch}
                // onChangeDropdown={onChangeDropdown}
                selection={filterSelection}
                searchString={searchString}
            // isMaxed={true}
            />
            <Box style={{ flex: 1 }}>
                {!showFilters && autoCompleteResults && (
                    <SearchResultsPanel
                        data={autoCompleteResults}
                        onSearchSelected={(data) => onSearchSubmit(data, true)}
                    />
                )}
                {showFilters && (
                    <FilterPanel
                        searchString={searchString}
                        onFilterChange={onFilterChange}
                        splitView={true}
                        propFilterResults={filterResults}
                    />
                )}
            </Box>
            {showClearAll && <Button
                className={classes.outlinedBlue}
                style={{ alignSelf: 'flex-end', height: 30, margin: 5 }}
                onClick={() => {
                    FundExplorerActions.setSearchString(null);
                    onFilterChange && onFilterChange();
                }}>
                {'Clear All'}
            </Button>}
        </Box>
    );
}

const mapStateToProps = (state) => {
    const {
        watchlistMF: { scrips, selectedList },
        common: { selectedScrip, selectedScripIndex, showFilter },
        layout: { maxedLayout, layoutType },
        fundExplorer
    } = state;
    return { scrips, selectedList, showFilter, maxedLayout, ...fundExplorer };
};

export default connect(mapStateToProps)(withStyles(combineStyles(styles, commonStyles))(SearchFilterDialog));
