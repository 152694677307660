export function getToday() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getDate(timeString) {
    const date = new Date();
    if (!timeString) return date;
    const timeParts = timeString.split(':');
    if (timeParts.length != 3) return date;
    date.setHours(timeParts[0]);
    date.setMinutes(timeParts[1]);
    date.setSeconds(timeParts[2]);
    return date;
}
