import { Grid, Paper } from '@mui/material';
import { getFixedValue } from 'helpers/scripHelper';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import styles from './styles';

let label = {
  open: 'Open',
  high: 'High',
  low: 'Low',
  close: 'Close',
  marketLot: 'Lot Size',
  ltq: 'Traded Qty',
  avgPrice: 'Avg. Price',
  turnOverData: 'Turnover in lakhs',
  oi: 'Open Interest (OI)',
  oiPercentChange: 'OI %',
  upperCircuit: 'Upper Band',
  lowerCircuit: 'Lower Band',
  volume: 'Volume',
  yearlyHigh: '52 Week High',
  yearlyLow: '52 Week Low',
  yearlyHighDate: '52 Week High Date',
  yearlyLowDate: '52 Week Low Date'
}
function DetailQuote(props) {
  const { classes, selectedScrip, propScrip } = props;

  let scrip = propScrip || selectedScrip || {};
  let isEQ = (scrip?.segment == "EQ"), isCOMM = (scrip?.segment == "COMM");

  function getDate(date) {
    let dateString = String(date).substring(0, 10).split('-')
    return `${dateString[0]} ${new Date(`${dateString[1]}-${dateString[0]}-${dateString[2]}`).toLocaleString('default', { month: 'short', year: "numeric" })}`
  }

  function numericValue(value, numericFixed, key) {
    if (numericFixed) {
      return parseInt(value) != NaN && getFixedValue(value, key === 'oiPercentChange' ? null : scrip?.segment)
    } else {
      return parseInt(value) != NaN && value
    }
  }

  function valueDiv(props) {
    const { sm, hideVerticalDivider, hideHorizontalDivider, key, color, date, numeric, numericFixed } = props;

    return (
      <Grid item xs={6} sm={sm || 4} className={`${classes.item} ${hideHorizontalDivider ? '' : classes.horizontalDivider}`}>
        <Paper className={`${classes.paper} ${hideVerticalDivider ? '' : classes.verticalDivider}`}>
          <div className={classes.text}>
            {
              scrip[key] ?
                date ? getDate(scrip[key]) :
                  numeric ? numericValue(scrip[key], numericFixed, key) :
                    getFixedValue(scrip[key], scrip?.segment)
                :
                '--'
            }
          </div>
          <div className={`${classes.text} ${classes.textMargin}`} style={{ color }}>{label[key]}</div>
        </Paper>
      </Grid>
    )
  }

  return (
    <div className={classes.mainDiv}>
      <div className={classes.root}>
        <Grid container className={[classes.grid, isCOMM ? "commGrid" : !isEQ ? "gridHeight" : "gridHeight1"].join(" ")}>
          {valueDiv({ sm: 3, key: 'open', color: '#00BBFE' })}
          {valueDiv({ sm: 3, key: 'high', color: '#8166F9' })}
          {valueDiv({ sm: 3, key: 'low', color: '#FF549F' })}
          {valueDiv({ sm: 3, key: 'close', color: '#00BBFE', hideVerticalDivider: true })}
          {!isEQ && <>
            {valueDiv({ key: 'marketLot', color: '#8166F9', numeric: true })}
            {valueDiv({ key: 'ltq', color: '#FF549F', numeric: true })}
            {valueDiv({ key: 'avgPrice', color: '#FF770D', hideVerticalDivider: true })}
            {valueDiv({ key: 'turnOverData', color: '#FF770D', numeric: true })}
            {valueDiv({ key: 'oi', color: '#00BBFE', numeric: true })}
            {valueDiv({ key: 'oiPercentChange', color: '#7785E3', hideVerticalDivider: true, numeric: true, numericFixed: true })}
          </>}
          {valueDiv({ key: 'upperCircuit', color: '#49BC75', hideHorizontalDivider: !isEQ, numeric: true, numericFixed: true })}
          {valueDiv({ key: 'lowerCircuit', color: '#E28F15', hideHorizontalDivider: !isEQ, numeric: true, numericFixed: true })}
          {valueDiv({ key: 'volume', color: '#8166F9', hideHorizontalDivider: !isEQ, hideVerticalDivider: true, numeric: true })}
          {isEQ && <>
            {valueDiv({ key: 'yearlyHigh', color: '#00BBFE' })}
            {valueDiv({ key: 'yearlyLow', color: '#FF549F' })}
            {valueDiv({ key: 'avgPrice', color: '#FF770D', hideVerticalDivider: true })}
            {valueDiv({ sm: 6, key: 'yearlyHighDate', color: '#8166F9', date: true, hideHorizontalDivider: true })}
            {valueDiv({ sm: 6, key: 'yearlyLowDate', color: '#49BC75', hideVerticalDivider: true, hideHorizontalDivider: true, date: true })}
          </>}
        </Grid>
      </div>
    </div>
  );
}

DetailQuote.propTypes = {
  selectedScrip: PropTypes.object.isRequired
};

DetailQuote.defaultProps = {
  selectedScrip: {}
};

const mapStateToProps = (state) => {
  return { selectedScrip: state.common.selectedScrip }
};

export default connect(mapStateToProps)(withStyles(styles)(DetailQuote))
