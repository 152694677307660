import common from "./common";

export default {
  ...common,
  type: 'dark',
  background: {
    default: '#14171E',
    secondary: '#1B1E26',
    darker: '#1f2434',
    primary: '#111217',
    WLselectedItem: '#292e3e',
    tableHead: '#292E3E'
  },
  color: {
    default: '#292e3e'
  },
  border: {
    default: '#000'
  },
  tab_border: {
    default: '#232E33'
  },
  color3: {
    default: '#292E3E'
  },
  color4: {
    default: '#97B5D1'
  },
  color5: {
    default: '#484e63'
  },
  color6: {
    default: '#292e3e'
  },
  color7: {
    default: '#344130',
    primary: '#080C09'
  },
  color8: {
    default: '#2A0B0B',
    primary: '#080C09'
  },
  color9: {
    default: '#fff'
  },
  color10: {
    default: '#3C455A'
  },
  color11: {
    default: '#8DB1D2',
    primary: '#A2ACC5'
  },
  color12: {
    default: '#FFFFFF'
  },
  color13: {
    default: '#383E4F'
  },
  color14: {
    default: '#394056',
    primary: '#232E33'
  },
  color15: {
    default: '#A5AAB7'
  },
  color16: {
    default: '#FFFFFF'
  },
  color17: {
    default: '#292e3e'
  },
  color18: {
    default: '#718292'
  },
  color18: {
    default: '#394056'
  },
  priceColor: {
    green: '#41F900',
    red: '#ED4740'
  },
  text: {
    primary: '#ffffff',
    secondary: '#66788A',
    disabled: '#A6B1BB',
    disclaimer: '#A3ADCB',
    light: '#000',
    dark: '#ffffff'
  }
};
