import React, { useContext, useEffect, useRef, useState } from 'react';
import { Error as ErrorIcon } from "@mui/icons-material";
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import ReactTable from 'components/ReactTable';
import { store } from 'helpers';
import { getFixedValueObject } from 'helpers/scripHelper';
import { StorageHelper } from 'helpers/storageHelper';
import useInterval from "helpers/useInterval";
import { UserProfileContext } from 'Main';
import { connect } from 'react-redux';
import { CommonActions } from 'redux/Common';
import { closeConfirmDialog, openConfirmDialog } from 'redux/ConfirmDialog';
import { DialogActions } from "redux/Dialog";
import { LayoutActions } from "redux/Layout";
import { PrefsService } from 'services/prefs';
import { reportService } from 'services/reports';
import ActionsRow, { modifyTableData, repeatOrderStatus, status } from "./components/ActionsRow";
import CustomToolbarSelect from "./components/CustomToolbarSelect";
import styles from './styles'

const USER = `${process.env.REACT_APP_USER}`;
const CUSTOM_INTERVAL = 5000;
const INPROCESS_INTERVAL = 60000;

function OrderReport(props) {

  const cClientCode = useRef();

  const [columnOrder, setColumnOrder] = useState(StorageHelper.getColumnOrder('order'));
  const [refreshing, setRefreshing] = useState(true)
  const [fetching, setFetching] = useState(false);
  const [orderReportData, setOrderReportData] = useState([])
  const [dataIndex, setDataIndex] = useState(-1)
  // const [filterList, setFilterList] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(0);
  const profile = useContext(UserProfileContext)
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState({})

  const { classes, selectedClient, keyUpPressCount, keyDownPressCount, activeTab, showFilter, tradeMsgs, marketStatus, filterStatus, layoutType, colorTheme, orders, openDialog, closeDialog } = props;

  const isActive = activeTab === "orderreport" || activeTab === `orders${filterStatus}`;
  const isNextAdmin = layoutType === 'nextadmin';
  const isInProcess = filterStatus === 'inprocess';
  const isNotClient = USER !== 'client';

  function setActiveTabScrip(rowData) {
    let obj = {
      ...(!status(rowData, marketStatus) ? {
        modify: modifyTableData("Modify", rowData) || {},
        cancel: rowData || {}
      } : {}),
      ...(!repeatOrderStatus(rowData) ? {
        repeat: modifyTableData("New", rowData || {}, false, true)
      } : {}),
    }
    CommonActions.onActiveTabScrip(obj)
  }

  useEffect(() => {
    if (filterStatus) {
      LayoutActions.setActiveTab(`orders${filterStatus}`);
    }
  }, []);

  useEffect(() => {
    if (orders && orders.length > 0) {
      const clientCode = getClientCode();
      const filtered = clientCode == null || clientCode == 'all'
        ? orders.filter(row => row.orderStatus.toLowerCase() == filterStatus)
        : orders.filter(row => row.orderStatus.toLowerCase() == filterStatus && row.clientCode == clientCode);
      setOrderReportData(filtered);
      setRefreshing(false);
    }
  }, [orders, selectedClient]);

  const onDataIndexChange = (index) => {
    if (orderReportData && orderReportData.length > 0) {
      setDataIndex(index);
      !isNotClient && setActiveTabScrip(orderReportData[index]);
    }
  }

  useEffect(() => {
    // PrefsService.setColumnOrderOrderReport([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]);
    PrefsService.getColumnOrderOrderReport().then(order => {
      setColumnOrder(order);
    })
  }, []);

  useEffect(() => {
    if (isActive) {
      onDataIndexChange(0)
      CommonActions.setFilter(false)
    }
  }, [activeTab])

  useEffect(() => {
    if (isActive && orderReportData && orderReportData.length > 0) {
      if (dataIndex == 0) {
        onDataIndexChange(orderReportData.length - 1)
      } else {
        onDataIndexChange(dataIndex - 1)
      }
    }
  }, [keyUpPressCount])

  useEffect(() => {
    if (isActive && orderReportData && orderReportData.length > 0) {
      if (dataIndex == orderReportData.length - 1) {
        onDataIndexChange(0)
      } else {
        onDataIndexChange(dataIndex + 1)
      }
    }
  }, [keyDownPressCount])

  const getConcatenatedRecords = ({ data, newData }) => {
    if (newData && newData.length === 0) return data;
    for (let i = newData.length; i > 0; i--) {
      const object = newData[i - 1];
      data.unshift(object);
    }
    return data;
  }

  const getClientCode = () => {
    let selectedClientCode = 'all';
    if (USER == 'client')
      selectedClientCode = undefined;
    if (USER == 'admin' || isNextAdmin) {
      if (selectedClient)
        selectedClientCode = selectedClient.ClientCode;
    }
    return selectedClientCode ? `${selectedClientCode}` : undefined;
  }

  async function getOrderReport(shouldRefresh, lastReportTime) {
    if (fetching) return;
    if (layoutType == 'nextadmin') return;
    if (selectedClient !== null && layoutType == 'admin') {
      return;
    }
    if (selectedClient == null && layoutType == 'ctclClient') {
      setRefreshing(false);
      setFetching(false);
      return;
    }
    setRefreshing(shouldRefresh);
    setFetching(true);
    const clientCode = getClientCode();
    reportService.getOrderReports(clientCode, shouldRefresh ? 0 : lastReportTime, filterStatus).then(response => {
      const { data: orderReports, requestCode } = response;
      if (orderReports) {
        const { common: { serverTime: currentTime } } = store.getState();
        setLastUpdated(orderReports[0] && orderReports[0].VarCurrentTime || currentTime);

        if (!lastReportTime || USER == 'client') {//Full data
          orderReports.length > 0 && orderReports.map((row, index) => {
            orderReports[index] = getFixedValueObject(row, "order");
          });
          setOrderReportData(orderReports);
        } else {//Incremental data
          let splicedOldReports = orderReportData;
          if (orderReports.length > 0) {
            orderReports.map((newRow, index) => {
              newRow = getFixedValueObject(newRow, "order");
              splicedOldReports = splicedOldReports.filter(oldRow => {
                return newRow.tbOrderId != oldRow.tbOrderId;
              });
              orderReports[index] = newRow;
            });
          }
          const concatenatedOrderReports = getConcatenatedRecords({ data: splicedOldReports, newData: orderReports });
          setOrderReportData(concatenatedOrderReports);
        }
      }
      setRefreshing(false);
      setFetching(false);
      if (orderReports && orderReports.length > 0) {
        setDataIndex(0)
        !isNotClient && setActiveTabScrip(orderReports[0]);
      }
    }).catch(error => { console.log(error); setRefreshing(false); setFetching(false); });
  }

  const getStatusColor = status => {
    const { executed, partiallyExecuted, pending, rejected, cancelled, inProcess, freezed, triggered, expired } = classes;
    switch (status) {
      case 'Executed': case 'FullyExecuted': return executed;
      case 'Partially Executed': case 'PartiallyExecuted': return partiallyExecuted;
      case 'Pending': return pending;
      case 'Rejected': return rejected;
      case 'Cancelled': return cancelled;
      case 'InProcess': return inProcess;
      case 'Freezed': case 'Freeze': return freezed;
      case 'Triggered': return triggered;
      case 'Expired': return expired;
    }
  }

  useEffect(() => {
    if (!isNextAdmin) {
      setOrderReportData([]);
      setLastUpdated(0);
      getOrderReport(true, 0);
      cClientCode.current = getClientCode();
    }
  }, [selectedClient]);

  useEffect(() => {
    if (tradeMsgs?.length > 0) {
      getOrderReport(!lastUpdated, lastUpdated)
    }
  }, [tradeMsgs?.length]);

  useInterval(() => {
    if (isNextAdmin && (lastUpdated || isInProcess)) {
      const shouldRefresh = cClientCode.current !== getClientCode();
      getOrderReport(shouldRefresh || !lastUpdated,
        shouldRefresh || isInProcess || orderReportData.length === 0 ? 0 : lastUpdated);
    }
  }, isInProcess ? INPROCESS_INTERVAL : CUSTOM_INTERVAL);

  function handleNoData() {
    let obj = {
      content: "No orders available",
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: closeDialog
    }
    openDialog(obj)
  }

  function handleCancelAll() {
    let indexes = [];
    orderReportData.forEach((ele, index) => {
      (ele.orderStatus == 'PartiallyExecuted' || ele.orderStatus == 'Pending' || ele.orderStatus == 'AMO') && indexes.push(index)
    })
    if (indexes.length > 0) {
      setSelectAllRows(true)
    } else {
      handleNoData()
    }
  }

  function getColumns(history) {
    return [
      {
        id: 'clientCode',
        header: 'Client Code',
        accessorKey: 'clientCode',
        size: 120,
        hideColumn: USER != 'admin',
        autoFocus: USER == 'admin'
      },
      {
        id: 'exchange',
        size: 90,
        header: 'Exchange',
        accessorKey: 'exchange',
        autoFocus: USER != 'admin'
      },
      {
        id: 'displaySegment',
        size: 90,
        header: 'Segment',
        accessorKey: 'displaySegment',
      },
      {
        id: 'actions',
        header: 'Actions',
        accessorKey: 'actions',
        size: 350,
        disableSortBy: true,
        disableFilter: true,
        hideColumn: history,
        cell: ({ row: { index } }) => (
          <ActionsRow
            isNotClient={isNotClient}
            rowData={(orderReportData && orderReportData.length > 0 && orderReportData[index]) || {}}
            marketStatus={marketStatus}
          />
        )
      },
      {
        id: 'scriptName',
        header: 'Scrip Name',
        accessorKey: 'scriptName',
        size: 200,
        cell: (row) => (
          <Tooltip arrow title={row.getValue()}>
            <Typography className={classes.cellText}>
              {row.getValue() || ''}
            </Typography>
          </Tooltip>
        )
      },
      {
        id: 'buySell',
        size: 80,
        header: 'Buy/Sell',
        accessorKey: 'buySell'
      },
      {
        id: 'productType',
        size: 110,
        header: 'Product Type',
        accessorKey: 'productType'
      },
      {
        id: 'orderType',
        size: 100,
        header: 'Order Type',
        accessorKey: 'orderType',
      },
      {
        id: 'orderStatus',
        header: 'Order Status',
        accessorKey: 'orderStatus',
        cell: ({ row: { original: values } }) => (
          <Box display="flex" alignItems="center" style={{ width: 'inherit' }} className={getStatusColor(values.orderStatus)}>
            {values.orderStatus}
            {values.orderStatus === 'Rejected' &&
              <Tooltip arrow title={values ? values.remark : ''} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
                <ErrorIcon className={classes.icon} />
              </Tooltip>}
          </Box>
        )
      },
      {
        accessorKey: 'orderQty',
        size: 100,
        header: 'Order Qty',
        id: 'orderQty',
        className: classes.alignRight
      },
      {
        accessorKey: 'orderPrice',
        size: 100,
        header: 'Order Price',
        id: 'orderPrice',
        className: classes.alignRight
      },
      {
        id: 'execQty',
        size: 90,
        header: 'Exec Qty',
        accessorKey: 'execQty',
        className: classes.alignRight
      },
      {
        id: 'execPrice',
        size: 100,
        header: 'Exec Price',
        accessorKey: 'execPrice',
        className: classes.alignRight
      },
      {
        id: 'trigPrice',
        size: 100,
        header: 'Trig. Price',
        accessorKey: 'trigPrice',
        className: classes.alignRight
      },
      {
        id: 'disclosedQty',
        size: 120,
        header: 'Disclosed Qty',
        accessorKey: 'disclosedQty',
        className: classes.alignRight
      },
      {
        id: 'tbOrderId',
        size: 80,
        header: 'OrderId',
        accessorKey: 'tbOrderId',
      },
      {
        id: 'tbOrderDatetime',
        header: 'Order DateTime',
        accessorKey: 'tbOrderDatetime',
        size: 200
      },
      {
        id: 'requestStatus',
        header: 'Request Status',
        accessorKey: 'requestStatus',
      },
      {
        id: 'validity',
        size: 75,
        header: 'Validity',
        accessorKey: 'validity',
      },
      {
        id: 'valDate',
        header: 'Validity Date',
        accessorKey: 'valDate',
      },
      {
        id: 'exchOrderId',
        header: 'Exch OrderId',
        accessorKey: 'exchOrderId',
        size: 175,
        ...(history ? {} : {
          cell: ({ row: { original: values } }) => (
            <Link underline="always" onClick={() => DialogActions.openOrderHistory({ orderRow: values, columns: getColumns(true) })}>
              {values.exchOrderId}
            </Link>
          )
        })
      },
      {
        id: 'exehDateTime',
        header: 'Exch DateTime',
        accessorKey: 'exehDateTime',
        size: 175
      },
      {
        id: 'remark',
        header: 'Remark',
        size: 200,
        accessorKey: 'remark',
        cell: ({ row: { original: values } }) => (
          values.remark?.startsWith('Insuffcient funds') && isNextAdmin
            ? <Tooltip arrow title={values.remark}>
              <Link
                className={`${classes.cellText} ${classes.cellLink}`}
                underline="always"
                onClick={() => { DialogActions.openAddLimit({ rowClientCode: values.clientCode }); }}>
                {values.remark}
              </Link>
            </Tooltip>
            : <Tooltip arrow title={values.remark}>
              <Typography className={classes.cellText}>
                {values.remark}
              </Typography>
            </Tooltip>
        )
      },
      {
        id: 'OrdChannel',
        header: 'Channel',
        accessorKey: 'OrdChannel',
        hideColumn: USER != 'admin'
      },
      {
        id: 'UserID',
        header: 'User',
        accessorKey: 'UserID',
        // size: 120,
        hideColumn: USER != 'admin'
      },
    ]
  }

  function isSelectable(rowData) {
    return (rowData.orderStatus == 'PartiallyExecuted' || rowData.orderStatus == 'Pending' || rowData.orderStatus == 'AMO')
  }

  const options = {
    width: 100,
    sorting: true,
    draggable: false,
    columnResizing: true,
    selectableRows: true,
    refreshing: refreshing,
    isSelectable: isSelectable,
    highlightedRowIndex: dataIndex,
    getActiveTabScrip: setActiveTabScrip,
    selectedRowIds,
    initialColumnOrder: columnOrder || [],
    onColumnOrderChange: (order) => PrefsService.setColumnOrderOrderReport(order),
    selectAllRows,
    toolbar: {
      filter: true,
      refresh: true,
      showTotalRecords: true,
      showTotalSelectedRecords: true,
      onRefresh: () => getOrderReport(true),
      toolbarRightContent: () => (
        <CustomToolbarSelect
          isNotClient={isNotClient}
          isNextAdmin={isNextAdmin}
          selectedRows={selectedRowIds || {}}
          tableData={orderReportData || []}
          profile={profile}
          getOrderReport={() => getOrderReport()}
          openConfirmDialog={props.openDialog}
          closeConfirmDialog={props.closeDialog}
          handleCancelAll={handleCancelAll}
          selectAllRows={selectAllRows}
          setSelectAllRows={setSelectAllRows}
          setSelectedRows={() => setSelectedRowIds({})}
        />
      )
    },
    onRowSelectionChange: (data) => {
      setSelectedRowIds(data)
    },
    activeTabFilter: activeTab === "orderreport" && showFilter,
  };

  return (
    <ReactTable
      options={options}
      columns={getColumns()}
      data={orderReportData}
    />
  )
}

const mapStateToProps = (state) => {
  const { client: { selectedClient }, common: { keyUpPressCount, keyDownPressCount, showFilter, marketStatus }, layout: { activeTab, layoutType }, updates: { tradeMsgs }, prefs: { colorTheme }, reports: { orders } } = state;
  return { selectedClient, keyUpPressCount, keyDownPressCount, activeTab, showFilter, tradeMsgs, marketStatus, layoutType, colorTheme, orders };
};

const mapDispatchToProps = dispatch => ({
  openDialog: (data) => dispatch(openConfirmDialog(data)),
  closeDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderReport))
