import { Button, Grid, Typography } from "@mui/material";
import SvgComponent from "helpers/svgcomponent";
import React from "react";

function Description(props) {
  const { classes, handleStep } = props

  return (
    <div>
      <Typography variant='h1' className={[classes.blueText, classes.heading].join(' ')}>Enable TOTP</Typography>
      <Grid container className={classes.containerGrid}>
        <Grid item xl={4} lg={3} xs={4} className={classes.imageGrid}>
          <SvgComponent iconname={"GraphicsTotp"} />
        </Grid>
        <Grid item xl={8} lg={9} xs={8}>
          <Typography variant="h4" className={classes.blueText}>Advantage of TOTP ?</Typography>
          <Typography className={classes.description}>For security purpose it is mandatory for all our users to use a mobile or email OTP to login. But there is no guarantee that these OTPs are delivered on time. Since time is critical in trading, TOTP is made mandatory for users to login, as TOTP is generated on your App you don't have to wait like incase of traditional OTP.</Typography>
          <Typography variant="h4" className={classes.blueText}>What is TOTP ?</Typography>
          <Typography className={classes.description}>TOTP stands for "time-based one-time password". Unlike a traditional OTP that is delivered to you via email or SMS, a TOTP is generated by a TOTP app that is already on your phone. This TOTP is valid only for a short duration (usually 30 seconds) and is regenerated every 30 seconds.</Typography>
          <Button autoFocus={true} className={classes.button} onClick={() => handleStep(2)} variant="contained">Get Started</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default Description;