import axios from 'helpers/interceptor';

export const pprService = {
  getPprData
};

function getPprData() {
  const requestOptions = {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ms-trading-masters-loader/load/PPRData`,
  };

  return axios(requestOptions)
    .then(response => {
      if (response && response.data && response.data.success) {
        return response.data.success;
      } else {
        return []
      }
    })
    .catch(function (error) {
      console.log(error);
      return {}
    })
}