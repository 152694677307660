import { DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';

const styles = theme => ({
    listItem: {
        marginLeft: "40px",
        fontSize: "14px",
        lineHeight: "25px"
    }
});

class NoInternet extends Component {

    render() {
        const { classes } = this.props;
        return <>
            <div>
                <DialogContent dividers>
                    {/* <Typography gutterBottom>
                            Please Chech the internet connection.
                            
            

            </Typography> */}

                    {/* <Typography gutterBottom> Try:</Typography> */}

                    <ul className={classes.listItem}>
                        <li>Please check your network connection and try again</li>
                        {/* <li>Reconnecting to Wi-Fi</li>
                        <li>Running Windows Network Diagnostics</li> */}
                    </ul>

                </DialogContent>
            </div>
        </>
    }


}



export default withStyles(styles)(NoInternet);
