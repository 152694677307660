import { CircularProgress, Grid, Tabs, Tooltip, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";
import { NumberFormatter, NumberSeparator } from "../../data";
import styles from "./styles";

export const CssTabs = withStyles(theme => ({
  root: {
    minHeight: "30px"
  },
  indicator: {
    backgroundColor: "#8DB1D2"
  },
}))(Tabs)

export const CssTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.darker,
    color: theme.palette.color15.default,
    border: `1px solid #66788a4d`,
    marginTop: "-4px"
  }
}))(Tooltip)

export const Legend = withStyles(styles)((props) => {
  const { classes, value, color, text, key, showTotalValue, totalValue, flexStart, showCount, countData, totalValueLabel } = props
  return (
    <Grid container /* style={{ marginBottom: "4px" }} */ key={key}>
      <Grid item lg={3} md={2} sm={2} style={{ display: "flex", justifyContent: flexStart ? "flex-start" : "flex-end" }}>
        <Typography variant="subtitle2" className={classes.legendValue}>{value && Number(value).toFixed(2) || '0.00'}%</Typography>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ background: color }} className={classes.legendColor} />
      </Grid>
      <Grid item lg={7} md={8} sm={8}>
        <Typography variant="subtitle2" className={classes.legendValue}>{text || ""}</Typography>
      </Grid>
      {
        showCount &&
        <Grid item xl={9} lg={10} md={10} sm={10} xs={12} className={classes.countGrid}>
          <Typography variant="caption" className={classes.secondaryText}>Stocks <span className={classes.count}>{countData.count1 || 0}</span></Typography>
          <Typography variant="caption" className={classes.countBorder} />
          <Typography variant="caption" className={classes.secondaryText}>Schemes <span className={classes.count}>{countData.count2 || 0}</span></Typography>
        </Grid>
      }
      {
        showTotalValue &&
        <Grid item xs={12} style={{ marginTop: "2px", marginBottom: "4px" }}>
          <Typography variant="caption" className={classes.secondaryText}>{totalValueLabel || "Total Value"} <span className={classes.totalValue}>{NumberFormatter(totalValue)}</span></Typography>
        </Grid>
      }
    </Grid>
  )
})

export const ValueGrid = withStyles(styles)((props) => {
  const { classes, matchesSm, matchesMd, matchesLg, currentValue, purchaseValue, overall, loading, showRightBorder } = props
  return (
    <Grid container>
      <Grid item xs={6} className={[classes.centerContent, classes.textGrid].join(" ")}>
        {
          loading ?
            <div className={classes.loaderDiv}>
              <CircularProgress className={classes.loader} size={matchesSm ? 12 : matchesLg ? 16 : 20} />
            </div>
            :
            overall ?
              <CssTooltip title={currentValue ? NumberSeparator(currentValue) : "0.00"}>
                <Typography variant="h4" className={classes.value}>{<span className={classes.rupee}>&#x20b9; </span>}{NumberFormatter(currentValue) || "0.00"}</Typography>
              </CssTooltip>
              :
              <Typography variant="h4" className={classes.value}>{<span className={classes.rupee}>&#x20b9; </span>}{(currentValue && NumberSeparator(currentValue)) || "0.00"}</Typography>
        }
        <Typography variant="subtitle1" className={classes.valueText}>Current Value</Typography>
      </Grid>
      <Grid item xs={6} className={[classes.centerContent, showRightBorder ? classes.textGrid : ""].join(" ")} style={{ flexDirection: "column" }}>
        {
          loading ?
            <div className={classes.loaderDiv}>
              <CircularProgress className={classes.loader} size={matchesSm ? 12 : matchesLg ? 16 : 20} />
            </div>
            :
            overall ?
              <CssTooltip title={purchaseValue ? NumberSeparator(purchaseValue) : "0.00"}>
                <Typography variant="h4" className={classes.value}>{<span className={classes.rupee}>&#x20b9; </span>}{NumberFormatter(purchaseValue) || "0.00"}</Typography>
              </CssTooltip>
              :
              <Typography variant="h4" className={classes.value}>{<span className={classes.rupee}>&#x20b9; </span>}{(purchaseValue && NumberSeparator(purchaseValue)) || "0.00"}</Typography>
        }
        <Typography variant="subtitle1" className={classes.valueText}>Purchase Value</Typography>
      </Grid>
    </Grid>
  )
})

export const ProfitLossGrid = withStyles(styles)((props) => {
  const { classes, matchesSm, matchesMd, matchesLg, todaysProfit, totalProfit, todaysProfitInPerc, totalProfitInPerc, purchaseValue, overall, loading } = props

  return (
    <Grid container>
      <Grid item xs={6} className={[classes.centerContent, classes.textGrid].join(" ")} style={{ flexDirection: "column" }}>
        <Typography variant="subtitle1" className={classes.valueText} style={matchesSm ? { paddingTop: 0 } : { paddingTop: 4 }}>Today's P/L</Typography>
        {
          loading ?
            <div className={classes.loaderDiv}>
              <CircularProgress className={classes.loader} size={matchesSm ? 12 : matchesLg ? 16 : 20} style={{ marginTop: "4px" }} />
            </div>
            :
            overall ?
              <CssTooltip title={todaysProfit ? NumberSeparator(Math.abs(todaysProfit)) : "0.00"}>
                <Typography variant="h4" style={matchesSm ? { padding: 0, display: "flex" } : { padding: "4px 0px 0px 0px", display: "flex" }} className={[classes.value, Math.sign(todaysProfit) === -1 ? "negative" : Math.sign(todaysProfit) === 1 ? "positive" : "neutral"].join(" ")}><span className={classes.rupee}>&#x20b9; </span>{(todaysProfit && NumberFormatter(Math.abs(todaysProfit))) || "0.00"}
                  <span className={classes.span}>{(todaysProfitInPerc && (Math.abs(todaysProfitInPerc).toFixed(2))) || "0.00"}%</span>
                </Typography>
              </CssTooltip>
              :
              <Typography variant="h4" style={matchesSm ? { padding: 0, display: "flex" } : { padding: "4px 0px 0px 0px", display: "flex" }} className={[classes.value, Math.sign(todaysProfit) === -1 ? "negative" : Math.sign(todaysProfit) === 1 ? "positive" : "neutral"].join(" ")}><span className={classes.rupee}>&#x20b9; </span>{(todaysProfit && NumberSeparator(Math.abs(todaysProfit))) || "0.00"}
                <span className={classes.span}>{(todaysProfitInPerc && (Math.abs(todaysProfitInPerc)).toFixed(2)) || "0.00"}%</span>
              </Typography>
        }
      </Grid>
      <Grid item xs={6} className={classes.centerContent} style={{ flexDirection: "column" }}>
        <Typography variant="subtitle1" className={classes.valueText} style={matchesSm ? { paddingTop: 0 } : { paddingTop: 4 }}>Total P/L</Typography>
        {
          loading ?
            <div className={classes.loaderDiv}>
              <CircularProgress className={classes.loader} size={matchesSm ? 12 : matchesLg ? 16 : 20} style={{ marginTop: "4px" }} />
            </div>
            :
            overall ?
              <CssTooltip title={totalProfit ? NumberSeparator(Math.abs(totalProfit)) : "0.00"}>
                <Typography variant="h4" style={matchesSm ? { padding: 0, display: "flex" } : { padding: "4px 0px 0px 0px", display: "flex" }} className={[classes.value, Math.sign(totalProfit) === -1 ? "negative" : Math.sign(totalProfit) === 1 ? "positive" : "neutral"].join(" ")}><span className={classes.rupee}>&#x20b9; </span>{(totalProfit && NumberFormatter(Math.abs(totalProfit))) || "0.00"}
                  <span className={classes.span}>{(totalProfitInPerc && (Math.abs(totalProfitInPerc).toFixed(2))) || "0.00"}%</span>
                </Typography>
              </CssTooltip>
              :
              <Typography variant="h4" style={matchesSm ? { padding: 0, display: "flex" } : { padding: "4px 0px 0px 0px", display: "flex" }} className={[classes.value, Math.sign(totalProfit) === -1 ? "negative" : Math.sign(totalProfit) === 1 ? "positive" : "neutral"].join(" ")}><span className={classes.rupee}>&#x20b9; </span>{(totalProfit && NumberSeparator(Math.abs(totalProfit))) || "0.00"}
                <span className={classes.span}>{(totalProfitInPerc && (Math.abs(totalProfitInPerc).toFixed(2))) || "0.00"}%</span>
              </Typography>
        }
      </Grid>
    </Grid>
  )
})