import { OutlinedInput } from '@mui/material';
import { withStyles } from '@mui/styles';

export const CssOutlinedInput = withStyles(theme => ({
    root: {
        width: "100%",
        height: theme.spacing(5),
        "&:hover $notchedOutline": {
            borderWidth: 1,
            borderColor: theme.palette.color12.default
        },
        "&$focused $notchedOutline": {
            borderWidth: 1,
            borderColor: theme.palette.color12.default
        },
        "&$error $notchedOutline": {
            borderWidth: 1,
            borderColor: "#f54040"
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: "12px"
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "10px"
        },
    },
    input: {},
    error: {},
    focused: {},
    notchedOutline: {
        borderColor: "#66788a4d"
    }
}))(OutlinedInput);
