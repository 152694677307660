import { Box, Button, Input, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import commonStyles from 'common/styles';
import combineStyles from "common/styles/combineStyles";
import { useState } from "react";
import { connect } from 'react-redux';
import { WatchListAction } from "redux/Watchlist";
import { WatchListMFActions } from "redux/WatchlistMF";
import { MODAL_CHILD } from "widgets/FormDialog";

const maxLen = 50;

const styles = theme => ({
    input: {
        fontSize: 18,
        '&::placeholder': {
            color: theme.palette.text.primary,
        },
        color: theme.palette.text.primary,
    },
    disabledButton: {
        backgroundColor: "#c5cbce !important",
        color: "#7d7d7d !important"
    }
});

function CreateWatchlist(props) {
    const { classes, onSubmit, onCancel, modalChild, watchlist, watchlistMF, pathName } = props;
    // debugger
    const isEdit = modalChild == MODAL_CHILD.WATCHLIST.EDIT;

    const isMF = pathName == 'mf';

    const [input, setInput] = useState({ value: isEdit ? (isMF ? watchlistMF.selectedListName : watchlist.selectedListName) : '', valid: true });

    const ACTIONS = pathName != 'mf' ? WatchListAction : WatchListMFActions;


    const onInputChange = (e) => {
        const { target: { value } } = e;
        const reg = new RegExp(/^[a-zA-Z .,&0-9-]+$/);
        if (value.length <= maxLen) {
            setInput({ value, valid: reg.test(value) });
        } else {
            setInput({ value: value.substring(0, maxLen), valid: reg.test(value) });
        }
    }

    const onSave = () => {
        if (isEdit) {
            ACTIONS.addList({
                isEdit: true,
                watchListName: isMF ? watchlistMF.selectedListName : watchlist.selectedListName,
                newWatchListName: input?.value || '',
                watchListId: isMF ? watchlistMF.selectedList : watchlist.selectedList
            });
        } else {
            ACTIONS.addList({ watchListName: input?.value || '' });
        }
        // if (onSubmit) {
        //     onSubmit({ name });
        // }
    }

    return (
        <Box
            display='flex'
            flexDirection='column'
            // width='30vw'
            justifyContent="center"
            width='350px'
            height='20vh'
        >
            <Input
                autoFocus
                className={classes.input}
                color='primary'
                textColor='primary'
                placeholder={'Watchlist name'}
                onChange={onInputChange}
                value={input?.value} />
            <div style={{ height: '2%' }} />
            <Typography
                variant="body2"
                color='textSecondary'>
                {maxLen - input?.value.length} characters left
            </Typography>
            {!(input?.valid) && <Typography
                variant="body2"
                color='primary'>
                Invalid input
            </Typography>}
            <div style={{ flex: 1 }} />
            <Box
                display='flex'
            //   justifyContent='space-between'
            >
                <Button
                    style={{ flex: 1, color: '#000000de' }}
                    variant='contained'
                    color="inherit"
                    onClick={onCancel}>
                    {'Cancel'}
                </Button>
                <div style={{ width: '5%' }} />
                <Button
                    style={{ flex: 1 }}
                    className={classes.containedBlue}
                    classes={{ disabled: classes.disabledButton }}
                    variant='contained'
                    disabled={!input?.valid}
                    onClick={onSave}>
                    {'Save'}
                </Button>
            </Box>
        </Box >
    )
}

const mapStateToProps = (state) => {
    const { watchlist, watchlistMF, common: { pathName } } = state;
    return { watchlist, watchlistMF, pathName };
};

const mapDispatchToProps = dispatch => ({
    // openDialog: () => dispatch(openDialog()),
    // closeDialog: () => dispatch(closeDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combineStyles(commonStyles, styles))(CreateWatchlist));
