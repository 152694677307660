import { MenuItem } from "@mui/material";
import { withStyles } from '@mui/styles';
import SelectBox from "components/Select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { openDialog } from "redux/Dialog";
import { MODAL_CHILD } from "widgets/FormDialog";

const styles = theme => ({
    paper: {
        padding: '6px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 0
    },
    formControl: {
        width: '100%',
        minWidth: 120
    },
    // selectEmpty: {
    //     backgroundColor: theme.palette.color3.default,
    //     borderColor: 'transparent',
    //     borderRadius: 20,
    //     paddingLeft: 5
    // }
});

function WLDropdown(props) {
    const [wlList, setWlList] = useState([]);

    const { lists, selectedList, onChange, noDefault, canAdd, createList, hideGlobal, conditionalSelection, selectedRowIds } = props;

    useEffect(() => {
        if (lists && lists.length > 0) {
            let newLists = [...lists];
            if (noDefault) {
                newLists.unshift({ id: "select", name: "Select Watchlist" });
            }
            setWlList(newLists);
        }
    }, [lists]);

    const handleWatchlistChange = e => {
        const { target: { value } } = e;
        if (value == 'new') {
            createList && createList();
        } else {
            onChange && onChange(e);
        }
    }

    const getWatchListItems = () => {
        // debugger
        return wlList && wlList.map(x => {
            if (hideGlobal && x.type == "global")
                return;
            return (
                <MenuItem value={x.id} key={x.id}>
                    {x.name}
                </MenuItem>
            )
        });
    }

    const selection = () => {
        return (
            <SelectBox
                selectionText='Select Watchlist'
                addText='Create New WatchList'
                value={noDefault ? wlList.length > 0 && wlList[0].id : selectedList}
                onChange={handleWatchlistChange}
                data={wlList}>
                {/* {noDefault && <MenuItem value="select">Select Watchlist</MenuItem>} */}
                {getWatchListItems()}
                {canAdd && <MenuItem value="new">Create WatchList</MenuItem>}
            </SelectBox>
        )
    }

    return (
        conditionalSelection ? Object.keys(selectedRowIds)?.length > 0 && selection() : selection()
    )
}

const mapStateToProps = (state) => {
    const { watchlistMF: { selectedList, lists } } = state;
    return { selectedList, lists };
};

const mapDispatchToProps = dispatch => ({
    createList: () => dispatch(openDialog(MODAL_CHILD.WATCHLIST.CREATE)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WLDropdown));