import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { flexRender } from '@tanstack/react-table';
import Filter from './Filter';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";

// const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
//   columnOrder.splice(columnOrder.indexOf(targetColumnId), 0, columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0])
//   return [...columnOrder]
// }

function DraggableHeader(props) {
  const { classes, header, table, showFilter, sorting, columnResizing, setColumnHover, columnResizeMode } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: header.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  };

  const { column } = header || {};
  const { columnDef } = column || {}

  const headerProp = columnDef?.disableDrag ? {} : { onMouseDown: () => setColumnHover(true) }

  return (
    columnDef?.hideColumn ?
      <></>
      :
      <th
        key={header.id}
        colSpan={header.colSpan}
        style={{
          cursor: sorting && !columnDef.disableSortBy ? 'pointer' : 'context-menu',
          opacity: isDragging ? 0.5 : 1,
          ...header.column.columnDef.cellStyle || {},
          ...style || {},
          width: header.getSize() || 100,
          maxWidth: header.getSize() || 100
        }}
        className={classes.head}
        ref={columnDef?.disableDrag ? null : setNodeRef}
        {...headerProp}
      >
        <div>
          <div
            style={{ whiteSpace: 'nowrap', lineHeight: '30px' }}
            {...(!columnDef.disableSortBy ? {
              className: !columnDef.disableSortBy && header.column.getCanSort() ? `cursor-pointer select-none ${classes.sort}` : '',
              onClick: header.column.getToggleSortingHandler()
            } : {})}
            {...attributes} {...listeners}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {
              (sorting && !columnDef.disableSortBy) ?
                <>
                  {{ asc: <ArrowDropUp className={classes.sortIcon} />, desc: <ArrowDropDown className={classes.sortIcon} /> }[header.column.getIsSorted()] ?? null}
                </>
                :
                <></>
            }
          </div>
          {
            (showFilter && !columnDef.disableFilter) ?
              <Filter
                classes={classes}
                column={header.column}
              />
              :
              <></>
          }
        </div>
        {
          (columnResizing && !columnDef.disableResizing) ?
            <div {...{
              onMouseDown: header.getResizeHandler(),
              onTouchStart: header.getResizeHandler(),
              className: `${classes.resizer} ${header.column.getIsResizing() ? classes.isResizing : ''}`,
              style: {
                transform: columnResizeMode === 'onChange' && header.column.getIsResizing() ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)` : ''
              }
            }} />
            :
            <></>
        }
      </th>
  )
}

export default DraggableHeader;