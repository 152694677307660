import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';

import { Provider } from 'react-redux'
import { store } from './helpers';

// Styles
import './assets/scss/index.scss';

// Routes
import Main from './Main';


import { history } from './helpers';
import { SignIn } from './views/Login/login';
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from './components/PrivateRoute';

import NoInternet from './components/NoInternet'
import ModelPopup from 'components/ModelPopUp'
import HotKeysComp from 'helpers/globalHotKeys';
import { isSupported, currentBrowser } from "helpers/envHelper";
import UnsupportedBrowser from "components/UnsupportedBrowser";

export default function App() {

  const [state, setState] = useState({
    modelstate: false
  })

  const isSupporting = isSupported(currentBrowser(window));

  useEffect(() => {
    window.onoffline = e => {
      //alert("Please check your network connection and try again");
      setState({ modelstate: true });

      // history.push("/not-found")
    };

    window.ononline = e => {
      //alert("You are online now.")
      setState({ modelstate: false });
      // history.push("/dashboard")
    }
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      console.log = () => { };
    }
  }, []);



  return (
    <>
      <ModelPopup openDialog={false} title={"No Internet Connection"} customClose disableEscapeKeyDown>
        <NoInternet></NoInternet>
        {/* <NotFound /> */}
      </ModelPopup>
      <HotKeysComp store={store} />
      <Provider store={store}>
        
          <Router history={history}>
            <Switch>
              <Route path="/login" component={SignIn} />
              {/* <Route component={Main} /> */}
              {isSupporting ?
                <PrivateRoute component={Main} /> :
                <UnsupportedBrowser />}
            </Switch>
          </Router>
        
      </Provider>

    </>
  );

}
