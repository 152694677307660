import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  header: {
    backgroundImage: `linear-gradient(${theme.palette.color5.default},${theme.palette.color6.default})`,
    padding: "0px",
    height: "25px",
    backgroundColor: theme.palette.background.default, //'#292e3e'
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    borderBottom: '1px solid #cccccc24'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: "#497CAC",
    fontWeight: 600
  },
  contentContainer: {
    backgroundColor: theme.palette.background.secondary,
    padding: `${theme.spacing(3)} !important`,
  },
  actionsContainer: {
    backgroundColor: theme.palette.background.secondary,
    padding: `0 0 ${theme.spacing(1)} 0`,
  },
  contentText: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  headerGrid: {
    display: "flex",
    alignItems: "center",
    height: "25px"
  },
  closeIcon: {
    float: "right",
    height: "25px",
    marginRight: theme.spacing(1),
    padding: 0
  },
  contentAlign: {
    justifyContent: "center",
    display: "flex"
  }
});

function ConfirmationDialogRaw(props) {
  const {
    classes, onClose, open, content, dialogTitle, hideCloseButton, okButtonText, cancelButtonText, centerActions, Component/* , ...other */
  } = props;
  const { header, headerGrid, title, closeIcon, contentContainer, contentText, actionsContainer } = classes;

  function handleCancel(event, reason) {
    if (reason !== 'backdropClick') {
      onClose("cancel");
    }
  }

  function handleOk() {
    onClose("confirm");
  }

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={handleCancel}
    // {...other}
    >
      {
        dialogTitle &&
        <DialogTitle id="form-dialog-title" className={header}>
          <Grid container>
            <Grid item lg={11} md={11} sm={11} xs={11} className={headerGrid}>
              <Typography className={title}>{dialogTitle}</Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1} >
              <Tooltip title="Close" aria-label="close">
                <IconButton aria-label="close" onClick={() => handleCancel()} className={closeIcon}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
      }
      <DialogContent className={contentContainer} style={{ marginTop: dialogTitle ? "25px" : null }}>
        {!Component ?
          <Typography className={contentText}>{content || 'Do you want to Logout ?'}</Typography>
          : Component}
      </DialogContent>
      <DialogActions className={[actionsContainer, centerActions ? classes.contentAlign : ""].join(" ")}>
        {hideCloseButton ? <></> : <Button onClick={handleCancel} color="secondary">{cancelButtonText ? cancelButtonText : "Cancel"}</Button>}
        <Button onClick={handleOk} color="secondary">{okButtonText ? okButtonText : "Ok"}</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(styles)(ConfirmationDialogRaw);
