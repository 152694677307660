import { withStyles } from '@mui/styles';
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    Typography,
} from '@mui/material';
import classNames from 'classnames';
import commonStyles from 'common/styles';
import combineStyles from 'common/styles/combineStyles';
import axios from 'helpers/interceptor';
import React, { useEffect, useState } from 'react';
import { DialogActions } from 'redux/Dialog';
import { CssOutlinedInput } from './CssOutlinedInput';

const styles = (theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column'
        // height: 100,
    },
    content: {
        backgroundColor: theme.palette.background.default,
        padding: 10,
        maxWidth: '20vw',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '25vw'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '30vw'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '50vw'
        }
    },
    actions: {
        display: 'flex',
        width: '50%',
        marginTop: theme.spacing(1.5)
    },
    actionsDivider: {
        width: theme.spacing(1.5),
        backgroundColor: 'transparent'
    },
    customButton: {
        flex: 1
    },
    fieldDiv: {
        marginBottom: theme.spacing(1.5),
        width: '100%',
        '&.compact': {
            width: '120px',
            paddingRight: theme.spacing(1),
            [theme.breakpoints.down('xl')]: {
                width: '100px',
                paddingRight: theme.spacing(0.75)
            }
        },
        '&.select': {
            width: '80px',
            paddingRight: theme.spacing(1)
        },
        '&.selectLarge': {
            width: '110px',
            paddingRight: theme.spacing(1)
        }
    },
    fieldDivPaddingLeft: {
        paddingLeft: theme.spacing(0.75)
    },
    fieldDivPaddingRight: {
        paddingRight: theme.spacing(0.75)
    },
    elementSpacing: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    fieldText: {
        marginBottom: theme.spacing(0.25),
        color: theme.palette.color15.default,
        fontSize: '12px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '10px'
        }
    },
    errorText: {
        color: '#f54040',
        marginTop: '2px'
    }
});

function AddLimit(props) {
    const {
        classes,
        data: { columns, rowData, rowClientCode }
    } = props;

    const [clientCode, setClientCode] = useState();
    const [clientData, setClientData] = useState({});
    const [newLimit, setNewLimit] = useState();
    const [updateError, setUpdateError] = useState();

    const {
        clientId,
        id,
        intradayFundTransfer,
        ledgerBalance,
        nonCashLimit,
        stockExposure
    } = clientData;

    useEffect(() => {
        if (!rowClientCode) {
            let clientCodeIdx;
            columns.map((column, index) => {
                if (column.name === 'clientCode') {
                    clientCodeIdx = index;
                }
            });
            setClientCode(rowData[clientCodeIdx]);
        } else {
            setClientCode(rowClientCode);
        }
    }, []);

    useEffect(() => {
        if (clientCode) {
            const requestOptions = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/ms-backoffice-masters/backoffice/clientlimitstatement/readselection/1/10`,
                data: { clientCode: clientCode /* '144978' */ }
            };
            axios(requestOptions)
                .then((response) => {
                    console.log(response.data.success);
                    const { columns, rows } = response.data.success;
                    if (rows && rows.length > 0) {
                        setClientData(rows[0]);
                        setNewLimit(rows[0]?.nonCashLimit || 0);
                        // const { clientId, id, intradayFundTransfer, ledgerBalance, stockExposure } = rows[0];
                        // debugger
                    } else {
                        setUpdateError('No data found');
                    }
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                    setUpdateError('No data found');
                });
        }
    }, [clientCode]);

    const closeDialog = () => {
        DialogActions.dialogClose();
    };

    const updateLimit = () => {
        setUpdateError();
        const requestOptions = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/ms-backoffice-masters/backoffice/clientlimitstatement/update`,
            data: { id: id, clientId: clientId, nonCashLimit: newLimit }
        };
        axios(requestOptions)
            .then((response) => {
                console.log(response.data.success);
                if (response.data.success) {
                    closeDialog();
                } else {
                    setUpdateError(response.data.error);
                }
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
                setUpdateError(
                    error.message || 'Something went wrong, try again later...'
                );
            });
    };

    const InputField = ({
        label,
        name,
        autoFocus,
        onChange,
        error,
        errorText,
        value,
        disabled,
        paddingLeft,
        paddingRight,
        fullWidth
    }) => {
        return (
            <Grid item xs={fullWidth ? 12 : 6}>
                <FormControl
                    variant="outlined"
                    className={classNames(
                        classes.fieldDiv,
                        { [classes.fieldDivPaddingLeft]: paddingLeft },
                        { [classes.fieldDivPaddingRight]: paddingRight }
                    )}>
                    <Typography className={classes.fieldText}>{label}</Typography>
                    <CssOutlinedInput
                        id={name}
                        name={name}
                        classes={{ input: classes.input }}
                        autoFocus={autoFocus}
                        onChange={onChange}
                        error={error}
                        // value={value}
                        defaultValue={value}
                        disabled={disabled}
                    // onKeyUp={() => setFieldTouched("quantity", true)}
                    // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    />
                    {error && <p className={classes.errorText}>{errorText}</p>}
                </FormControl>
            </Grid>
        );
    };

    return (
        <Box className={classes.main}>
            <Grid container className={classes.content}>
                <InputField
                    label={'Add-on Limit*'}
                    value={newLimit}
                    autoFocus={true}
                    paddingRight
                    onChange={(event) => {
                        const {
                            currentTarget: { value }
                        } = event;
                        // debugger
                        if (value && value !== '') {
                            setNewLimit(value);
                        }
                    }}
                />
                <InputField
                    label={'Stock Exposure'}
                    value={stockExposure}
                    disabled={true}
                    paddingLeft
                />
                <InputField
                    label={'Intraday Funds Transfer'}
                    value={intradayFundTransfer}
                    disabled={true}
                    paddingRight
                />
                <InputField
                    label={'Ledger Balance'}
                    value={ledgerBalance}
                    disabled={true}
                    paddingLeft
                />
                <InputField
                    label={'Client Code'}
                    value={clientCode}
                    disabled={true}
                    fullWidth
                />
                {updateError && (
                    <Typography className={classes.errorText}>{updateError}</Typography>
                )}
            </Grid>
            <Box className={classes.actions}>
                <Button
                    className={classNames(classes.outlinedBlue, classes.customButton)}
                    onClick={() => closeDialog()}>
                    {'Cancel'}
                </Button>
                <Divider className={classes.actionsDivider} />
                <Button
                    className={classNames(classes.containedBlue, classes.customButton)}
                    onClick={() => updateLimit()}>
                    {'Update'}
                </Button>
            </Box>
        </Box>
    );
}

export default withStyles(combineStyles(styles, commonStyles))(AddLimit);
