import React, { useEffect, useMemo, useState, useRef } from "react";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useReactTable, getCoreRowModel, getSortedRowModel, getExpandedRowModel, getFilteredRowModel, flexRender } from "@tanstack/react-table";
import DraggableRow from "./components/DraggableRow";
import DraggableHeader from "./components/DraggableHeader";
import { withStyles } from '@mui/styles';
import styles from './styles';
import { Checkbox, Toolbar } from "./components";
import { CommonActions } from "redux/Common";
import Placeholder from "components/Placeholder";
import { Add, Remove } from '@mui/icons-material';
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { Table as MaterialTable } from "@mui/material";
import Footer from "./components/Footer";

const restrictToVerticalAxis = ({ transform }) => {
  return { ...transform, x: 0 };
};

// const restrictToHorizontalAxis = ({ transform }) => {
//   return { ...transform, y: 0 };
// };

function Table(props) {
  const { classes, columns, data: records, options } = props;

  const { sorting, draggable, columnResizing, selectableRows, highlightedRowIndex, refreshing, selectAllRows, expand, subComponent, tableFooter, onRowSelectionChange, isSelectable,
    activeTabFilter, toolbar, getActiveTabScrip, onVisibleRowsChanged, initialColumnOrder, onColumnOrderChange, selectedRowIds, customFooter } = options || {};

  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState([])
  const [showFilter, setShowFilter] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState()
  const [totalSelectedRecords, setTotalSelectedRecords] = useState(0);
  const [columnOrder, setColumnOrder] = useState(modifyColumns())
  const [rowSelection, setRowSelection] = useState({})
  const [totalSelectableRecords, setTotalSelectableRecords] = useState(0);
  const [columnHover, setColumnHover] = useState(false)
  const listRef = useRef(null);

  const scrollbarWidth = () => {
    const scrollDiv = document.createElement('div')
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
  }

  const scrollBarSize = useMemo(() => scrollbarWidth(), [])

  function onExpand(row) {
    listRef.current && listRef.current.resetAfterIndex(0);
    row.toggleExpanded();
  }

  const table = useReactTable({
    data,
    columns: [
      {
        id: 'drag',
        accessorKey: 'drag',
        size: 30,
        disableDrag: true,
        disableSortBy: true,
        disableFilter: true,
        disableResizing: true,
        hideColumn: draggable ? false : true,
        className: classes.draggableCell,
        cellStyle: {
          padding: 0,
          justifyContent: 'center',
          maxWidth: 30
        },
        header: () => <></>,
      },
      {
        id: 'selection',
        accessorKey: 'selection',
        size: 30,
        disableDrag: true,
        disableSortBy: true,
        disableFilter: true,
        disableResizing: true,
        hideColumn: selectableRows ? false : true,
        cellStyle: {
          padding: 0,
        },
        header: () => {
          if (selectableRows) {
            let rows = table.getRowModel()?.rows || [];
            return (
              <Checkbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                  disabled: isSelectable ? rows?.filter(ele => isSelectable(ele.original))?.length == 0 : false
                }}
              />
            )
          } else {
            return (
              <></>
            )
          }
        },
        cell: ({ row }) => {
          return (
            selectableRows ?
              <Checkbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: isSelectable && !isSelectable(row.original) || false,
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
              :
              <></>
          )
        }
      },
      {
        id: 'expand',
        accessorKey: 'expand',
        size: 30,
        disableDrag: true,
        disableSortBy: true,
        disableFilter: true,
        disableResizing: true,
        hideColumn: expand ? false : true,
        cellStyle: {
          padding: '0px 7px',
          justifyContent: 'center'
        },
        header: () => <></>,
        cell: ({ row }) => {
          return (
            <span
              onClick={!row.getCanExpand() ? () => { } : () => onExpand(row)}
              {...{
                style: {
                  cursor: !row.getCanExpand() ? '' : 'pointer',
                  paddingLeft: `${row.depth * 2}rem`
                },
              }}
            >
              {row.getIsExpanded() ? <Remove /> : <Add style={!row.getCanExpand() ? { color: "#66666666" } : {}} />}
            </span>
          )
        }
      },
      ...columns,
    ],
    state: { columnOrder, columnFilters, rowSelection },
    enableColumnResizing: true,
    enableRowSelection: true,
    columnResizeMode: 'onChange',
    autoResetSortBy: false,
    autoResetResize: false,
    autoResetFilters: false,
    autoResetSelectedRows: false,
    autoResetHiddenColumns: false,
    autoResetExpanded: false,
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getRowCanExpand: (row) => expand && row?.original?.subRows?.length > 0 ? true : false,
    getCanResize: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 1, },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(KeyboardSensor, {})
  );

  const { toggleAllRowsSelected } = table;
  const { rows } = table.getRowModel();
  const items = useMemo(() => rows?.map(({ id }) => id), [rows]);
  const columnItems = useMemo(() => columns?.map(({ id }) => id), [columns]);

  useEffect(() => {
    if (records?.length) {
      setData(records)
      if (table.getState()?.expanded && Object.keys(table.getState()?.expanded).length > 0) {
        table.toggleAllRowsExpanded(false)
        listRef.current && listRef.current.resetAfterIndex(0);
      }
    }
  }, [records])

  useEffect(() => {
    setColumnVisibility(hideColumns())
  }, [columns.length])

  useEffect(() => {
    setShowFilter(activeTabFilter)
  }, [activeTabFilter])

  useEffect(() => {
    if (sorting && (highlightedRowIndex > -1 || sortBy)) {
      rows[highlightedRowIndex] && getActiveTabScrip && getActiveTabScrip(rows[highlightedRowIndex].original)
      // onVisibleRowsChanged && onVisibleRowsChanged({ tableRows: rows })
    }
  }, [sortBy, highlightedRowIndex])

  useEffect(() => {
    if (initialColumnOrder?.length > 0) {
      modifyColumnOrder()
    }
  }, [initialColumnOrder])

  useEffect(() => {
    if (columnOrder?.length > 0) {
      modifyColumnOrder(true)
    }
  }, [columnOrder])

  function hideColumns() {
    let obj = {};
    columns.filter(ele => ele.hideColumn)?.forEach(col => {
      obj[col.accessorKey] = false
    })
    return {
      ...(draggable ? {} : { drag: false }),
      ...(selectableRows ? {} : { selection: false }),
      ...(expand ? {} : { expand: false }),
      ...obj,
    }
  }

  function modifyColumnOrder(change) {
    const extraColumns = (draggable ? [{ accessorKey: 'drag' }] : [])
      .concat(selectableRows ? [{ accessorKey: 'selection' }] : [])
      .concat(expand ? [{ accessor: 'expand' }] : [])
      .concat(columns).filter(ele => !ele.hideColumn);
    const columnOrderIndexes = columnOrder.map(ele =>
      extraColumns.findIndex(col =>
        typeof col.accessorKey === 'string' ? col.accessorKey === ele : col.id === ele
      ))
    if (JSON.stringify(initialColumnOrder) !== JSON.stringify(columnOrderIndexes)) {
      if (initialColumnOrder?.length > 0) {
        setColumnOrder(change ? columnOrder : initialColumnOrder.map(ele => extraColumns[ele]?.accessorKey))
        change && onColumnOrderChange && onColumnOrderChange(columnOrderIndexes)
      }
    }
  }

  function modifyColumns() {
    if (columns?.length > 0) {
      let column = (draggable ? ['drag'] : [])
        .concat(selectableRows ? ['selection'] : [])
        .concat(expand ? ['expand'] : [])
        .concat(columns.filter(ele => !ele.hideColumn).map(ele => ele.accessorKey));
      return column || []
    }
  }

  useEffect(() => {
    if (!showFilter) {
      setColumnFilters([])
    }
  }, [showFilter])

  useEffect(() => {
    setShowFilter(activeTabFilter)
  }, [activeTabFilter])

  useEffect(() => {
    if (selectAllRows) {
      toggleAllRowsSelected(true);
    } else {
      rowSelection && Object.keys(rowSelection).length > 0 && toggleAllRowsSelected(false);
    }
  }, [selectAllRows])

  useEffect(() => {
    if (JSON.stringify(rowSelection) !== JSON.stringify(selectedRowIds)) {
      setRowSelection(selectedRowIds)
    }
  }, [selectedRowIds])

  useEffect(() => {
    setShowFilter(activeTabFilter)
  }, [activeTabFilter])

  useEffect(() => {
    if (selectableRows && rows && rows.length > 0) {
      let records = rows.filter(ele => isSelectable && isSelectable(ele.original))
      setTotalSelectableRecords(records.length)
    }
  }, [rows?.length])

  useEffect(() => {
    selectableRows && onRowSelectionChange && onRowSelectionChange(rowSelection)
    if (rowSelection && Object.keys(rowSelection).length > 0) {
      let records = Object.keys(rowSelection).filter(ele => rows[ele] && isSelectable && isSelectable(rows[ele].original))
      setTotalSelectedRecords(records.length)
    } else {
      setTotalSelectedRecords(0)
    }
  }, [rowSelection])

  useEffect(() => {
    if (sorting && sortBy) {
      rows[highlightedRowIndex] && getActiveTabScrip && getActiveTabScrip(rows[highlightedRowIndex].original)
      onVisibleRowsChanged && onVisibleRowsChanged({ tableRows: rows })
    }
  }, [sortBy])

  useEffect(() => {
    if (sorting && highlightedRowIndex > -1) {
      rows[highlightedRowIndex] && getActiveTabScrip && getActiveTabScrip(rows[highlightedRowIndex].original)
    }
  }, [highlightedRowIndex])

  function reorderRow(draggedRowIndex, targetRowIndex) {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0])
    setData([...data])
  }

  function handleToggleFilter() {
    CommonActions.setFilter(!showFilter)
    setShowFilter(!showFilter)
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      columnHover
        ?
        setColumnOrder((data) => {
          const oldIndex = data.indexOf(active.id);
          const newIndex = data.indexOf(over.id);
          return arrayMove(data, oldIndex, newIndex);
        })
        :
        setData((data) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          return arrayMove(data, oldIndex, newIndex);
        });
    }
  }

  const Item = ({ index, isScrolling, style }) => {
    return <div style={style}>
      {
        isScrolling ?
          "Loading..."
          :
          <DraggableRow
            classes={classes}
            draggable={draggable}
            reorderRow={reorderRow}
            highlightedRowIndex={highlightedRowIndex}
            key={rows[index]?.original?.id}
            row={rows[index] || {}}
            expand={expand}
            subComponent={subComponent}
            columnHover={columnHover}
            setColumnHover={setColumnHover}
          />
      }
    </div>;
  };

  const reactTable = () => {
    return (
      <table {...{ style: refreshing ? {} : { height: '100%' } }} className={classes.table}>
        <SortableContext
          items={columnHover ? columnItems : items}
          strategy={columnHover ? horizontalListSortingStrategy : verticalListSortingStrategy}
        >
          <thead style={{ height: showFilter ? 67 : 30 }}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} style={{ display: 'flex' }}>
                {
                  headerGroup.headers.map(header => (
                    <DraggableHeader
                      table={table}
                      header={header}
                      key={header.id}
                      sorting={sorting}
                      classes={classes}
                      showFilter={showFilter}
                      columnResizing={columnResizing}
                      setColumnHover={setColumnHover}
                      columnResizeMode={table?.options?.columnResizeMode}
                    />
                  ))
                }
              </tr>
            ))}
          </thead>
          <tbody style={{ height: `calc(100% - ${showFilter ? 67 : 30}px)` }}>
            {
              refreshing || data.length == 0 ?
                <div className={classes.loadingDiv}>
                  <Placeholder
                    loading={refreshing}
                    error={refreshing ? '' : 'Sorry, no matching records found'}
                  />
                </div>
                :
                <AutoSizer>
                  {({ height, width }) => (
                    <VariableSizeList
                      itemSize={(i) => (rows[i] && rows[i].getIsExpanded() ? (rows[i]?.original?.subRows?.length * 30) + 90 : 30)}
                      itemCount={rows.length}
                      onItemsRendered={props => onVisibleRowsChanged && onVisibleRowsChanged({ ...props, tableRows: rows })}
                      height={height}
                      width={width + scrollBarSize}
                      overscanCount={1}
                      ref={listRef}
                    >
                      {Item}
                    </VariableSizeList>
                  )}
                </AutoSizer>
            }
          </tbody>
          {
            tableFooter && !refreshing && !customFooter ? <tfoot>
              <Footer
                classes={classes}
                table={table}
              />
            </tfoot>
              :
              <></>
          }
        </SortableContext>
      </table>
    )
  }

  return (
    <div className={classes.tableRoot}/*  id={tableId || ""} */>
      <Toolbar
        toolbarProps={toolbar}
        refreshing={refreshing}
        toggleFilter={handleToggleFilter}
        totalSelectedRecords={totalSelectedRecords}
        totalRecords={(data && data.length > 0) ? data.length : null}
      />
      <div className={classes.container} style={{
        height: `calc(100% - ${(toolbar ? (toolbar.toolbarHeight || 40) : 0) + ((tableFooter && customFooter) ? 31 : 0)}px)`
      }}>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          collisionDetection={closestCenter}
          modifiers={columnHover ? [] : [restrictToVerticalAxis]}
        >
          {reactTable()}
        </DndContext>
      </div>
      {
        tableFooter && !refreshing && customFooter ?
          <MaterialTable>
            {customFooter()}
          </MaterialTable>
          :
          <></>
      }
    </div>
  );
}

export default withStyles(styles)(Table);