import { Close as CloseIcon, KeyboardArrowDown as Arrow } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import commonStyles from "common/styles";
import combineStyles from "common/styles/combineStyles";
import axios from "helpers/interceptor";
import React, { useState } from "react";
import { CssCheckbox } from "../customfields";
import { conditionsData, faqData } from "./data";
import styles from "./styles";

const USER = `${process.env.REACT_APP_USER}`

function TermsAndConds(props) {
  const { classes, onClose, setTermsPopup, termsPopup, setActiveStep, selectClient } = props
  const [submit, setSubmit] = useState(false)

  function handleSubmit() {
    let payload = {}
    axios.post(`${process.env.REACT_APP_API_URL}/ms-trading-customer-profile/profile/tncflagintraday`, payload).then(resp => {
      if (resp.data.success) {
        setTermsPopup({ show: true, value: termsPopup.value, step: 2, submitted: true })
        localStorage.setItem("intradayProduct", JSON.stringify({ status: "Yes" }))
        // setFieldValue("product", "Intraday")
      } else {
        // handle error
      }
    }).catch(error => {
      // handle error
    })
  }

  function onSendLink() {
    setSubmit(true)
    let payload = {
      clientCode: selectClient.ClientCode
    }
    axios.post(`${process.env.REACT_APP_API_URL}/ms-user-profile/profile/dealer/sendTncFlagRequest`, payload).then(resp => {
      if (resp.data.success) {
        setSubmit(false)
        setTermsPopup({ show: true, value: termsPopup.value, step: 1, submitted: true })
      } else {
        setSubmit(false)
      }
    }).catch(error => {
      setSubmit(false)
    })
  }

  function handleOk() {
    if (USER !== "client") {
      setTermsPopup({ show: false, value: termsPopup.value, step: termsPopup.step, submitted: true })
    } else {
      setTermsPopup({ show: false, value: termsPopup.value, step: 0, submitted: true })
      setActiveStep(1)
    }
  }

  return (
    <Dialog open={termsPopup.show} maxWidth={/* USER !== "client" ? "sm" :  */"lg"}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(termsPopup.value);
        }
      }}
    >
      <DialogTitle className={classes.header}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11} className={classes.headerGrid}>
            <Typography className={classes.title}>{USER !== "client" ? "Message" : termsPopup.step === 0 ? "Terms & Conditions" : termsPopup.step === 1 ? "FAQ" : "Message"}</Typography>
          </Grid>
          {
            (termsPopup.step === 2 || (USER !== "client" && termsPopup.step === 1)) ?
              <></>
              :
              <Grid item lg={1} md={1} sm={1} xs={1} >
                <Tooltip title="Close" aria-label="close">
                  <IconButton aria-label="close" onClick={() => onClose(termsPopup.value)} className={classes.closeIcon}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
          }
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={[classes.paper, ((USER === "client" && termsPopup.step === 2) || USER !== "client") ? "message" : ""].join(" ")}>
          {
            (termsPopup.step === 0 || termsPopup.step === 1) && USER === "client" ?
              <>
                <h1 className={classes.heading}>{termsPopup.step === 0 ? "Terms & Conditions" : "FAQ Intraday"}</h1>
                <div className={classes.textDiv}>
                  <div className={classes.innerTextDiv}>
                    {
                      termsPopup.step === 0 ?
                        <>
                          {
                            conditionsData.map((ele, index) => (
                              <p key={index} className={classes.pText} style={index === conditionsData.length - 1 ? { paddingBottom: "0px" } : {}}>
                                {ele}
                              </p>
                            ))
                          }
                        </>
                        :
                        <>
                          {
                            faqData.map((ele, index) => (
                              <div key={index} className={classes.questionDiv} style={index === faqData.length - 1 ? { paddingBottom: "0px" } : {}}>
                                <div className={classes.questionNumber}>
                                  Q{index + 1}
                                </div>
                                <div>
                                  <Typography className={classes.question}>{ele.question}</Typography>
                                  <Typography className={classes.answer}>{ele.answer}</Typography>
                                </div>
                              </div>
                            ))
                          }
                        </>
                    }
                  </div>
                </div>
                <div className={classes.bottomDiv}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {
                      termsPopup.step === 0 ?
                        <>
                          <FormControlLabel
                            id="terms"
                            name="terms"
                            control={<CssCheckbox
                              classes={{
                                root: classes.checkboxRoot,
                                checked: classes.checked,
                                disabled: classes.disabled,
                              }}
                              color="primary"
                              checked={termsPopup.value}
                              onChange={() => setTermsPopup({ show: true, value: !termsPopup.value, step: 0, submitted: false })}
                            />}
                            label={""}
                          />
                          <Typography className={classes.termsText}>I accept terms & conditions</Typography>
                        </>
                        :
                        <></>
                    }
                  </div>
                  <div>
                    <IconButton className={classes.button} variant="outlined" onClick={() => setTermsPopup({ show: true, value: !termsPopup.value, step: termsPopup.step === 0 ? 1 : 0, submitted: false })} >{termsPopup.step === 0 ? "FAQ" : "Terms & Conditions"}<Arrow style={{ color: "#006ca84a" }} /></IconButton>
                  </div>
                </div>
                {
                  termsPopup.step === 0 && <Button disabled={!termsPopup.value} className={[classes.submitButton, classes.containedBlue]} onClick={handleSubmit}>{"Submit"}</Button>
                }
              </>
              :
              <div className={[classes.question, USER !== "client" && termsPopup.step === 0 ? classes.textPadding : classes.textPadding1].join(" ")}>
                {
                  USER !== "client" ?
                    termsPopup.step === 0 ?
                      "Intraday product is not activated for the selected customer. Please click on send activation link to mail the customer Intraday activation." :
                      "Activation link has been sent successfully!" :
                    "Your Intraday Product is activated successfully!"
                }
              </div >
          }
        </div>
        {
          (termsPopup.step === 2 || USER !== "client") &&
          <div style={{ display: "flex", justifyContent: "center"/* , paddingBottom: "8px"  */ }}>
            <Button style={{ marginBottom: "8px" }} autoFocus={true} disabled={submit} className={[classes.containedBlue, USER !== "client" && termsPopup.step === 0 ? classes.linkButton : ""].join(" ")} onClick={USER !== "client" && termsPopup.step === 0 ? () => onSendLink() : () => handleOk()}>
              {USER !== "client" && termsPopup.step === 0 ? <>{submit && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}{"Send Link"}</> : "Ok"}
            </Button>
          </div>
        }
      </DialogContent>
    </Dialog >
  )
}

export default withStyles(combineStyles(styles, commonStyles))(TermsAndConds) 