export default (theme) => ({
  tableRoot: {
    padding: '12px 12px 0px 12px',
    height: '100%'
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0
  },
  container: {
    // height: 'calc(100% - 40px)',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto hidden'
  },
  head: {
    margin: 0,
    padding: '0px 14px',
    backgroundColor: theme.palette.background.tableHead,
    color: theme.palette.type === 'light' ? '#001E3B' : '#8DB1D2',
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'
    }`,
    // borderBottom: 0,
    position: 'sticky',
    top: -1,
    zIndex: 999,
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    borderRight: 0,
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    '&:last-of-type':{
      borderRight: `1px solid ${theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'}`
    }
  },
  row: {
    height: 30,
    display: 'flex',
    // backgroundColor: theme.palette.type === 'light' ? '#ebeef5' : '#111217'
  },
  cell: {
    margin: 0,
    padding: '0px 14px',
    color: theme.palette.type === 'light' ? '#666666' : '#A3ADCB',
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'
    }`,
    borderTop: 0,
    borderRight: 0,
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '&:last-of-type': {
      borderRight: `1px solid ${theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'}`
    },
    backgroundColor: theme.palette.type === 'light' ? '#ebeef5' : '#111217'
  },
  highlighted: {
    backgroundColor: theme.palette.background.darker
  },
  dragIcon: {
    fill: theme.palette.type === 'light' ? '#666666' : '#A3ADCB',
    background: 'transparent'
  },
  resizer: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: 5,
    cursor: 'col-resize',
    userSelect: 'none',
    touchAction: 'none'
  },
  isResizing: {
    opacity: 1
  },
  sort: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dragButton: {
    background: 'transparent',
    border: 'none',
    minWidth: 30,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  draggableCell: {
    padding: 0,
    justifyContent: 'center'
  },
  textbox: {
    marginTop: 8
  },
  loadingDiv: {
    padding: '4px 0px',
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'
    }`,
    borderTop: 0
  },
  icon: {
    width: 20,
    height: 20,
    cursor: 'pointer'
  },
  adornedEnd: {
    paddingRight: 6
  },
  input: {
    marginBottom: 6
  },
  subComponentRow: {
    border: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'
    }`,
    padding: '14px 14px 14px 35px',
    borderTop: 0,
    width: 'calc(100% - 7px)'
  },
  sortIcon: {
    width: 20,
    height: 20
  },
  footerCell: {
    borderTop: `1px solid ${
      theme.palette.type === 'light' ? '#DEDBDB' : '#3C455A'
    }`,
    backgroundColor: theme.palette.background.default
  }
});
