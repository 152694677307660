import { store } from "helpers";
import { StorageHelper } from "helpers/storageHelper";
import { LayoutActions } from "redux/Layout";
import { PrefsService } from "services/prefs";

const TYPES = {
    ADMIN_DEFAULT: './admin_default',
    CTCL_CLIENT_DEFAULT: 'ctcl_client_default',
    CLIENT_DEFAULT: 'default',
    ADMIN_CUSTOM: "admin_custom",
    CTCL_CLIENT_CUSTOM: 'ctcl_client_custom',
    CLIENT_CUSTOM: 'custom',
    NEXTADMIN_DEFAULT: 'nextadmin_default',
    NEXTADMIN_CUSTOM: 'nextadmin_default'
};

let customLayout;

const getCustomName = (userType) => {
    const { ADMIN_CUSTOM, CTCL_CLIENT_CUSTOM, CLIENT_CUSTOM, NEXTADMIN_CUSTOM } = TYPES;

    let customName;
    switch (userType) {
        case 'admin':
            customName = ADMIN_CUSTOM;
            break;
        case 'ctclClient':
            customName = CTCL_CLIENT_CUSTOM;
            break;
        case 'nextadmin':
            customName = NEXTADMIN_CUSTOM;
            break;
        default:
            customName = CLIENT_CUSTOM;
            break;
    }
    return customName;
}

export function loadLayout(userType) {
    let toLoad;

    //Check custom saved layout first
    let customName = getCustomName(userType);

    let customLayout;
    if (!customLayout) {
        // customLayout = localStorage.getItem(customName);
        customLayout = StorageHelper.getLayoutJson(userType == 'mf');
    }
    if (customLayout) {
        LayoutActions.setCustom();
        return JSON.parse(customLayout);
    }
    //Check custom saved layout first

    if (!customLayout) {
        toLoad = getDefaultLayout(userType);
    }
    return toLoad;
}

function getDefaultLayout(userType) {
    let toLoad;
    switch (userType) {
        case 'admin':
            toLoad = require('./admin_default');
            break;
        case 'ctclClient':
            toLoad = require('./ctcl_client_default');
            break;
        case 'nextadmin':
            toLoad = require('./nextadmin_default');
            break;    
        case 'mf':
            toLoad = require('views/MutualFund/layouts/default.json');
            break;
        default:
            toLoad = require('./default');
            break;
    }
    return toLoad;
}

export function getLayout(isNew) {
    if (!isNew) {
        let layout = localStorage.getItem('newLayout');
        if (!(!layout)) {
            LayoutActions.setCustom();
            return JSON.parse(layout);
        }
        // return require('./empty');
        return require('./default');
    }
    return JSON.parse('{}');
};

export function setLayout(json) {
    try {
        customLayout = JSON.stringify(json);
        // newLayout = JSON.stringify(json);
        // localStorage.setItem('newLayout', newLayout);
    } catch (error) {
        alert(`Error saving layout\n${JSON.stringify(error)}`);
    }
}

export function saveLayout(userType) {
    let customName = getCustomName(userType);
    try {
        if (customLayout) {
            PrefsService.setLayoutJson(customLayout, userType);
            // localStorage.setItem(customName, customLayout);
        } else {
            alert('Nothing to save...');
        }
        // if (!(!newLayout)) {
        //     localStorage.setItem('newLayout', newLayout);
        // } else {
        //     alert('Nothing to save...');
        // }
    } catch (error) {
        alert(`Error saving layout\n${JSON.stringify(error)}`);
    }
}

export function resetLayout(userType) {
    const pathName = store.getState().common.pathName;
    const defaultJson = getDefaultLayout(pathName == 'mf' ? 'mf' : userType);
    PrefsService.setLayoutJson(JSON.stringify(defaultJson), userType, true);
    return;
    // let customName = getCustomName(userType);
    // localStorage.removeItem(customName);
    // // localStorage.removeItem('newLayout');
    // window.location.reload();
}

export function setOrderViewPosition(coordinates) {
    try {
        if (coordinates) {
            localStorage.setItem('orderViewPosition', JSON.stringify(coordinates));
        } else {
            alert('Nothing to set default order view position...');
        }
    } catch (error) {
        alert(`Error saving default order view position \n${JSON.stringify(error)}`);
    }
}

export function getOrderViewPosition() {
    let position = localStorage.getItem('orderViewPosition');
    if (position) {
        return JSON.parse(position)
    } else {
        return { x: 0, y: 0 }
    }
}
