import React from "react";
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import styles from './styles';

function MaterialTable(props) {
  const { classes, data, columns } = props;

  return (
    <div className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {
              columns?.length > 0 && columns.map((ele, index) => (
                !ele.hideColumn && <TableCell size="small" key={index}>
                  {ele.label}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data?.length > 0 ?
              <>
                {
                  data.map((row, index) => (
                    <TableRow key={index}>
                      {
                        columns.map((ele, index) => (
                          !ele.hideColumn && <TableCell key={index} size="small" className={ele.className || ''}>
                            {
                              ele.cell ? ele.cell({ row: { values: row } }) : ele.accessor ? ele.accessor(row) : row[ele.columnKey]
                            }
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  ))
                }
              </>
              :
              <TableRow>
                <TableCell colSpan={columns.length + 1} size="small">
                  <Typography variant="subtitle1" className={classes.contentText}>Sorry, no matching records found</Typography>
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  )
}

export default withStyles(styles)(MaterialTable) 