export default theme => ({
  loadingDiv: {
    height: "700px",
    [theme.breakpoints.down('xl')]: {
      height: "500px",
    }
  },
  secondCard: {
    padding: "1px 0"
  },
  addBank: {
    color: theme.palette.common.blue,
    fontSize: 14,
    cursor: "pointer",
    textDecoration: "underline",
  },
  changePlan: {
    color: "#999999",
    fontSize: 16,
  },
  changePlanNumber: {
    color: theme.palette.common.blue,
    fontSize: 16,
    fontWeight: "600"
  },
  root: {
    width: 1200,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('xl')]: {
      width: 1100,
    },
    [theme.breakpoints.down('lg')]: {
      width: 800,
    },
    [theme.breakpoints.down('md')]: {
      width: 500,
    }
  },
  topPaper: {
    margin: "0px 0px 12px",
    padding: "4px 12px",
    backgroundColor: theme.palette.background.default
  },
  paper: {
    width: "100%",
    padding: "0px 12px 8px 12px",
    backgroundColor: theme.palette.background.default
  },
  tab: {
    color: theme.palette.color15.default,
    borderBottom: `1px solid ${theme.palette.color5.default}`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "75px"
    }
  },
  activeTab: {
    color: "#8DB1D2 !important",
    borderBottom: `1px solid ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "75px"
    }
  },
});