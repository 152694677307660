import React, { useContext, useState } from "react"
import { withStyles } from "@mui/styles"
import { connect } from "react-redux"
import { Button, CircularProgress, Grid, Typography } from "@mui/material"
import { closeDialog } from "redux/Dialog"
import { openConfirmDialog, closeConfirmDialog } from "redux/ConfirmDialog"
import { UserProfileContext } from 'Main'
import axios from "helpers/interceptor"
import styles from "./styles"

const OrderType = {
  "Limit": "LIMIT",
  "Market": "MKT",
  "StopLoss": "SL"
}

const ValidTill = {
  GFD: "Good For Day",
  IOC: "Immediate or Cancel",
  GTD: "Good Till Day",
  GTC: "Good Till Cancel",
}

const ValueDiv = withStyles(styles)(props => {
  const { classes, title, value, noPaddingBottom, color } = props
  return (
    <Grid container className={classes.valueDiv} style={noPaddingBottom ? { paddingBottom: 0 } : {}}>
      <Grid item xs={6}>
        <Typography variant="caption" className={classes.secondaryText}>{title || ""}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" className={classes.primaryText} style={color ? { color: color } : {}}>{value || ""}</Typography>
      </Grid>
    </Grid >
  )

})

const USER = `${process.env.REACT_APP_USER}`

function CancelOrder(props) {
  const profile = useContext(UserProfileContext)
  const [submitting, setSubmitting] = useState(false)

  const { classes, reduxState: { form }, closeDialog, openConfirmDialog, closeConfirmDialog } = props

  function handleCancelConfirm() {
    closeConfirmDialog()
  }

  function cancelOrder() {
    setSubmitting(true)
    let payload = {
      requestStatus: "Cancel",
      ex: form.shortCode || "",
      seg: form.segment || "",
      sId: form.scripId || "",
      tk: form.token || "",
      dpAccNo: (profile && profile.dematAcc) || "",
      buySell: form.buySell || "",
      qty: Number(form.remQTY) || 0,
      price: form.orderPrice | 0,
      type: (form.orderType && OrderType[form.orderType]) || "",
      orderId: form.tbOrderId || "",
      disQty: Number(form.disclosedQty) || 0,
      tPrice: form.trigPrice || 0,
      val: form.validity || "",
      pId: form.productType || "",
      goalId: "",
      valDate: (form.valDate && form.valDate !== "0" && form.valDate) || 0,
      userId: USER !== "client" ? form.clientCode : ""
    }
    let obj = {
      content: "Your order has been cancelled successfully",
      hideCloseButton: true,
      okButtonText: "Ok",
      centerActions: true,
      onClose: handleCancelConfirm
    }
    axios.post(`${process.env.REACT_APP_API_URL}/ms-order-placement/push`, payload).then(resp => {
      setSubmitting(false)
      closeDialog()
      if (resp.data.success) {
        obj.content = resp.data.success.orderId && resp.data.success.orderId != "0" ? resp.data.success.msg + " Order Id is " + resp.data.success.orderId + "." : resp.data.success.msg || "Your order has been cancelled successfully."
        openConfirmDialog(obj)
      } else {
        obj.content = (resp.data.error && resp.data.error.msg) || "Error in cancelling order, Please try again after some time."
        openConfirmDialog(obj)
      }
    }).catch(error => {
      obj.content = "There is some technical issue, please try again later"
      openConfirmDialog(obj)
    })
  }

  return (
    <div className={classes.mainDiv}>
      <Grid container className={[classes.root, USER === "client" ? "client" : ""].join(" ")}>
        <div className={classes.scripDiv}>
          <Typography component="div" display="inline" variant="h3" className={classes.highlight}>{form && form.scriptName || form && form.symbol || '--'}</Typography>
        </div>
        <Grid item xs={12}>
          <Grid container className={classes.firstGrid}>
            <Grid item xs={6}>
              <div className={classes.firstDiv}>
                <ValueDiv title={"Exchange"} value={(form && form.exchange) || "--"} />
                <ValueDiv title={"Action"} color={form && form.buySell === "B" ? "#299D00" : "#f54040"} value={form && form.buySell ? form.buySell === "B" ? "Buy" : form.buySell === "S" ? "Sell" : "Short Sell" : "--"} />
                <ValueDiv title={"Order Type"} value={(form && form.orderType) || "--"} />
                <ValueDiv title={"Price"} value={(form && form.orderPrice && `₹ ${Number(form.orderPrice).toFixed(form.segment === "CURR" ? 4 : 2)}`) || "0.00"} />
                <ValueDiv title={"Trigger Price"} value={(form && form.trigPrice && `₹ ${Number(form.trigPrice).toFixed(form.segment === "CURR" ? 4 : 2)}`) || "0.00"} noPaddingBottom />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.secondDiv}>
                <ValueDiv title={"Quantity"} value={(form && form.remQTY) || "0"} />
                <ValueDiv title={"Disclosed Quantity"} value={(form && form.disclosedQty) || "0"} />
                <ValueDiv title={"Product Type"} value={(form && form.productType) || "--"} />
                <ValueDiv title={"Validity"} value={form && form.validity ? ValidTill[form.validity] : "--"} />
              </div>
            </Grid>
          </Grid>
          {
            USER !== "client" &&
            <Grid item xs={12} className={classes.secondGrid}>
              <Typography variant="caption" style={{ marginRight: "16px" }} className={classes.secondaryText}>Client Code</Typography>
              <Typography variant="h6" className={classes.primaryText}>{(form && form.clientCode) || "--"}</Typography>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item lg={6} xs={8} style={{ marginRight: "16px" }}>
              <Button onClick={() => closeDialog()} disabled={submitting} className={classes.cancel}>Close</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container style={{ display: "flex", justifyContent: "flex-start" }}>
            <Grid item lg={6} xs={8} style={{ marginLeft: "16px" }}>
              <Button autoFocus={true} onClick={cancelOrder} disabled={submitting} className={[classes.order, classes.containedBlue].join(" ")}>{submitting && <CircularProgress size={20} color="inherit" style={{ marginRight: "12px" }} />}Confirm</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return { reduxState: state && state.dialog && state.dialog.data || {} }
}

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(closeDialog()),
  openConfirmDialog: (data) => dispatch(openConfirmDialog(data)),
  closeConfirmDialog: () => dispatch(closeConfirmDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CancelOrder))
