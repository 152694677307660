export default ({ palette }) => ({
  styleOverrides: {
    paper: {
      backgroundColor: palette.background.default,
      height: '100%',
      margin: '0 10px',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    responsiveScrollMaxHeight: {
      flexGrow: 1
    },
    responsiveBase: {
      flexGrow: 1
    }
  }
});
