import Toast from 'components/Toast';
import { store } from 'helpers';
import axios from 'helpers/interceptor';
import { isAnyMarketOpen } from "helpers/pollingHelper";
import { CommonActions } from 'redux/Common';
import { LayoutActions } from "redux/Layout";
import ResearchToast from './research';
import { toast, Slide } from 'react-toastify';
import './toast.css'

// import { AlertActions } from "redux/Alert";

//Actions
const EXCHANGE_MESSAGES = 'EXCHANGE_MESSAGES';
const EXCHANGE_MESSAGE_FAILURE = 'EXCHANGE_MESSAGE_FAILURE';
const TRADE_MESSAGES = 'TRADE_MESSAGES';
const TRADE_MESSAGES_FAILURE = 'TRADE_MESSAGES_FAILURE';
const ADVISORY_MESSAGES = 'ADVISORY_MESSAGES';
const ADVISORY_MESSAGES_FAILURE = 'ADVISORY_MESSAGES_FAILURE';
const PRICE_ALERTS = 'PRICE_ALERTS';
const TRADEBULLS_MESSAGES = 'TRADEBULLS_MESSAGES';
const TRADEBULLS_MESSAGES_FAILURE = 'TRADEBULLS_MESSAGES_FAILURE';

let wsClient;
let socket;

const REFRESH_INTERVAL = process.env.REACT_APP_REFRESH_FREQUENCY;

export const UpdatesActions = {
    init,
    subscribe,
    unsubscribe,
};

function subscribe(client) {
    socket = client;
    const { layout: { layoutType } } = store.getState();
    const isNextAdmin = layoutType === 'nextadmin';
    if (!isNextAdmin) {
        socket.emit('notification:subscribe', {
            request: 'SUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'research',
            // payload: [],
        });
        socket.on('notification/research', onResearch);
        socket.emit('notification:subscribe', {
            request: 'SUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'tradeMsg',
            payload: [{ clientCode: localStorage.getItem('sub') }],
        });
        socket.on('notification/tradeMsg', onTradeMsg);
        socket.emit('notification:subscribe', {
            request: 'SUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'prcAlert',
            payload: [{ clientCode: localStorage.getItem('sub') }],
        });
        socket.on('notification/prcAlert', onPriceAlert);
        socket.emit('notification:subscribe', {
            request: 'SUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'tbMsg',
            // payload: [],
        });
        socket.on('notification/tbMsg', onTradebullsMsg);
    }
}

function onResearch(data) {
    // console.log('SocketOnMessage: notification/research - ', data);
    const research = data ? JSON.parse(data) : null;
    // console.log(`notificationStore Socket: research - ${JSON.stringify(research)}`, new Date().getTime());
    if (research) {
        const { time } = research;
        const dateTime = getFormattedDate(time);
        let { prefs: { advisoryNotification }, updates: { advisoryMsgs: { desk, filter, msgList, nextMsgCount, products } }, } = store.getState();
        if (advisoryNotification) {
            toast(({ closeToast }) => <ResearchToast
                data={research}
                getFormattedDate={getFormattedDate}
                closeToast={closeToast}
                onAction={() => LayoutActions.openAdvisoryMsgs()}
            />,
                {
                    toastId: `research_${time}`,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    pauseOnFocusLoss: false,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeButton: false,
                    className: "toastify-container",
                    bodyClassName: "toastify-body",
                    transition: Slide,
                    style: { width: 500 }
                });
        }
        let index = msgList.findIndex(ele => ele.id === research.id)
        let [newMsgList, newCount] = [[...msgList], nextMsgCount];
        if (index > -1) {
            newMsgList.splice(index, 1, research)
            newCount = nextMsgCount
        } else {
            newMsgList.push(research)
            newCount = nextMsgCount++
        }
        store.dispatch({ type: ADVISORY_MESSAGES, list: { msgList: newMsgList, nextMsgCount: newCount, desk, filter, products } });
    }
}

function onTradeMsg(data) {
    // console.log('SocketOnMessage: notification/tradeMsg - ', data);
    const tradeMsg = data ? JSON.parse(data) : null;
    if (tradeMsg) {
        // const status = getStatus(tradeMsg);
        const { title: status, message } = getFormattedTradeMsg(tradeMsg);
        const dateTime = getFormattedDate();
        toast(({ closeToast }) => <Toast
            title={status}
            message={message}
            // message={tradeMsg}
            status={status}
            dateTime={dateTime}
            type={"default"}
            closeToast={closeToast}
            onAction={() => LayoutActions.openTradeMsgs()}
        />,
            {
                toastId: `trade_${new Date().getTime()}`,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
                className: "toastify-container",
                bodyClassName: "toastify-body",
                transition: Slide,
                style: { width: 325 }
            })
        store.dispatch({ type: TRADE_MESSAGES, msg: tradeMsg });
    }
}

function onPriceAlert(data) {
    const alert = data ? JSON.parse(data) : null;
    // console.log('SocketOnMessage: notification/prcAlert socket response ', alert)
    if (alert) {
        // console.log("SocketOnMessage: notification/prcAlert Store:", store.getState())

        const { updates: { priceAlerts: { nextMsgCount } }, layout } = store.getState();
        const { priceAlertsOpen } = layout || {};
        const { webMsg, scripName, additionalInfo } = alert;
        const dateTime = getFormattedDate();
        toast(({ closeToast }) => <Toast
            toastStyle={{ backgroundColor: "#ab0808" }}
            title={"Price Alert"}
            message={<div>
                <div style={{ color: "#63fd06" }}>{scripName}</div>
                <div>{webMsg}</div>
                {additionalInfo && <div>Note: {additionalInfo}</div>}
            </div>}
            type={""}
            dateTime={dateTime || ''}
            closeToast={closeToast}
            onAction={() => LayoutActions.openPriceAlerts()}
        />,
            {
                toastId: `alert_${new Date().getTime()}`,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 5000,
                hideProgressBar: true,
                closeButton: false,
                className: "toastify-container",
                bodyClassName: "toastify-body",
                transition: Slide,
                style: { width: 325 }
            });
        // console.log("SocketOnMessage: notification/prcAlert socket nextMsgCount:", nextMsgCount + 1)
        priceAlertsOpen && CommonActions.setPriceAlert()
        store.dispatch({ type: PRICE_ALERTS, list: { nextMsgCount: nextMsgCount + 1 } });
    }
}

function onTradebullsMsg(data) {
    const tradebullsMsg = data ? JSON.parse(data) : null;
    if (tradebullsMsg) {
        const { msg, time } = tradebullsMsg;
        toast(({ closeToast }) => <Toast
            title={'Tradebulls'}
            message={msg}
            dateTime={getFormattedDate(time)}
            type={"default"}
            closeToast={closeToast}
            onAction={() => LayoutActions.openTradebullsMsgs()}
        />,
            {
                toastId: `tradebulls_${time}`,
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
                className: "toastify-container",
                bodyClassName: "toastify-body",
                transition: Slide,
                style: { width: 325 }
            });
        store.dispatch({ type: TRADEBULLS_MESSAGES, list: { msgList: [{ msg, time }], nextMsgCount: 1 } });
    }
}

function unsubscribe(client) {
    const { layout: { layoutType } } = store.getState();
    const isNextAdmin = layoutType === 'nextadmin';
    if (!isNextAdmin) {
        client.emit('notification:unsubscribe', {
            request: 'UNSUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'research',
            // payload: [],
        });
        client.off('notification/research', onResearch);
        client.emit('notification:unsubscribe', {
            request: 'UNSUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'tradeMsg',
            payload: [{ clientCode: localStorage.getItem('sub') }],
        });
        client.off('notification/tradeMsg', onTradeMsg);
        client.emit('notification:unsubscribe', {
            request: 'UNSUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'prcAlert',
            payload: [{ clientCode: localStorage.getItem('sub') }],
        });
        client.off('notification/prcAlert', onPriceAlert);
        client.emit('notification:unsubscribe', {
            request: 'UNSUBSCRIBE',
            message: '',
            channel: 'notification',
            topic: 'tbMsg',
            // payload: [],
        });
        client.off('notification/tbMsg', onTradebullsMsg);
    }
}

function init(client) {
    if (!client) {
        if (!wsClient) {
            wsClient = {};
            getInitialData();
        }
    } else {
        if (!wsClient) {
            wsClient = client;
            getInitialData();
        }
    }
}

function getInitialData() {
    getTradeMsgs();
    getAdvisoryMsgs('f');
    getPriceAlerts('f')
    getFullTradebullsMsgs()
    setInterval(() => {
        if (!socket || (socket && socket.disconnected)) {
            if (isAnyMarketOpen()) {
                const { layout: { layoutType } } = store.getState();
                const isNextAdmin = layoutType === 'nextadmin';
                !isNextAdmin && getTradeMsgs();
                !isNextAdmin && getAdvisoryMsgs('i');
                !isNextAdmin && getPriceAlerts('i')
                !isNextAdmin && getTradebullsMsgs('i')
            }
        }
    }, REFRESH_INTERVAL);
}

// function getStatus(str) {
//     let status = "";
//     if (str && str.length > 0) {
//         let strArray = str.split("|")
//         if (strArray && strArray.length > 0) {
//             status = strArray[strArray.length - 2]
//         }
//     }
//     return status
// }

const getFormattedDate = (timestamp) => {
    const date = timestamp ? new Date(timestamp) : new Date();

    return new Intl.DateTimeFormat('default', {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }).format(date);
}

function getFormattedTradeMsg(status) {
    const idx = status.indexOf(":");
    const title = status.substring(0, idx).trim();
    const message = status.substring(idx + 1).trim();
    return { title, message };
}

function getTradeMsgs() {
    // setInterval(() => {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_FEED_URL}/ms-watchlist-service/tradeMsgs`,
        // url: `${process.env.REACT_APP_API_URL}/ms-order-report/tradeMsgs`,
        timeout: REFRESH_INTERVAL,
        data: [{
            clientCode: localStorage.getItem('sub'),
            ch: "WEB"
        }]
    };
    axios(options)
        .then(response => {
            const { data: { success, error } } = response;
            // const success = 'a|b|c|d|e|f|g|Pending|i'
            if (success) {
                // const status = getStatus(success);
                const { title: status, message } = getFormattedTradeMsg(success);
                const dateTime = getFormattedDate();
                toast(({ closeToast }) => <Toast
                    title={status}
                    message={message}
                    // message={success}
                    status={status}
                    dateTime={dateTime}
                    type={"default"}
                    closeToast={closeToast}
                    onAction={() => LayoutActions.openTradeMsgs()}
                />,
                    {
                        toastId: `trade_${new Date().getTime()}`,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        pauseOnFocusLoss: false,
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeButton: false,
                        className: "toastify-container",
                        bodyClassName: "toastify-body",
                        transition: Slide,
                        style: { width: 325 }
                    })
                // store.dispatch(onSuccess(success));
                onSuccess(success);
            } else {
                if (error) {
                    // notificationStore.addNotification({
                    //     content: <Toast
                    //         message={error || ""}
                    //         type="error"
                    //     />,
                    //     insert: "top",
                    //     container: "bottom-right",
                    //     dismiss: {
                    //         duration: 5000
                    //     }
                    // });
                }
                onFailure(error);
                // AlertActions.error(`Get Scrips error\n ${JSON.stringify(error)}`);
            }
        })
        .catch(function (error) {
            // AlertActions.success(`Get Scrips error\n ${JSON.stringify(error)}`);
        });
    // }, REFRESH_INTERVAL);

    // const subscription = wsClient.subscribe("/user/queue/tradeMsgs", (message) => {
    //     // console.log('/user/queue/tradeMsgs', message);
    //     // debugger
    //     let body = JSON.parse(message.body);
    //     if (body && body.success) {
    //         // AlertActions.success(`New Trade Messages...`);
    //         onSuccess(body.success);
    //     } else {
    //         console.log("got empty message");
    //     }
    // });
    function onSuccess(msg) {
        store.dispatch({ type: TRADE_MESSAGES, msg });
    }
    function onFailure(error) {
        store.dispatch({ type: TRADE_MESSAGES_FAILURE, error });
    }
}

function getAdvisoryMsgs(type) {
    const { updates: { advisoryMsgs: { nextMsgCount } } } = store.getState();
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_FEED_URL}/ms-watchlist-service/research`,
        timeout: REFRESH_INTERVAL,
        data: [{
            loadType: nextMsgCount && nextMsgCount == 1 ? 'f' : type,
            nextMsgCount
        }]
    };
    axios(options)
        .then(response => {
            const { data: { success, error } } = response;

            if (success?.msgList?.length > 0) {
                // console.log('notificationStore Polling', success.msgList);
                let { prefs: { advisoryNotification }, updates: { advisoryMsgs: { desk, filter, msgList, nextMsgCount, products } } } = store.getState();
                let list = [...msgList];
                success.msgList.map((message, index) => {
                    if (advisoryNotification) {
                        if (type == 'i' && nextMsgCount > 1) {
                            // console.log(`notificationStore Polling: ${msg}-${time}`, new Date().getTime());
                            toast(({ closeToast }) => <ResearchToast
                                data={message}
                                getFormattedDate={getFormattedDate}
                                closeToast={closeToast}
                                onAction={() => LayoutActions.openAdvisoryMsgs()}
                            />,
                                {
                                    toastId: `research_${message?.time}`,
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    pauseOnFocusLoss: false,
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeButton: false,
                                    className: "toastify-container",
                                    bodyClassName: "toastify-body",
                                    transition: Slide,
                                    style: { width: 500 }
                                });
                        }
                    }
                    if (type == 'i' && nextMsgCount > 1) {
                        let index = list.findIndex(ele => ele.id === message.id)
                        index > -1 ? list.splice(index, 1, message) : list.push(message)
                    } else {
                        list.push(message);
                    }
                })
                onSuccess({ msgList: list, nextMsgCount: success.nextMsgCount, resetData: nextMsgCount == 1, desk: success.desk || desk, filter: success.filter || filter, products: success.products || products });
            } else {
                // onSuccess({ msgList: [{ content: 'Test1', dateTime: getFormattedDate() }], nextMsgCount: success.nextMsgCount });
                if (error) {
                    // notificationStore.addNotification({
                    //     content: <Toast
                    //         message={error || ""}
                    //         type="error"
                    //     />,
                    //     insert: "top",
                    //     container: "bottom-right",
                    //     dismiss: {
                    //         duration: 5000
                    //     }
                    // });
                }
                onFailure(error);
            }
        })
        .catch(function (error) {
            // debugger
            //handle error
        });
    function onSuccess(list) {
        store.dispatch({ type: ADVISORY_MESSAGES, list });
    }
    function onFailure(error) {
        store.dispatch({ type: ADVISORY_MESSAGES_FAILURE, error });
    }
}

function getPriceAlerts(type) {
    const { updates: { priceAlerts: { nextMsgCount }, layout } } = store.getState();
    const { priceAlertsOpen } = layout || {};
    // console.log("SocketOnMessage: notification/prcAlert polling nextMsgCount:", nextMsgCount)
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_FEED_URL}/ms-watchlist-service/prcAlts`,
        timeout: REFRESH_INTERVAL,
        data: [{
            clientCode: localStorage.getItem('sub'),
            loadType: nextMsgCount && nextMsgCount == 1 ? 'f' : type,
            nextMsgCount: nextMsgCount
        }]
    };
    axios(options)
        .then(response => {
            // console.log("SocketOnMessage: notification/prcAlert polling response:", response)
            const { data: { success } } = response;
            if (success?.msgList?.length > 0) {
                success.msgList.map((message) => {
                    const { webMsg, scripName, additionalInfo } = message;
                    const dateTime = getFormattedDate();
                    if (type == 'i') {
                        toast(({ closeToast }) => <Toast
                            toastStyle={{ backgroundColor: "#ab0808" }}
                            title={"Price Alert"}
                            message={<div>
                                <div style={{ color: "#63fd06" }}>{scripName}</div>
                                <div>{webMsg}</div>
                                {additionalInfo && <div>Note: {additionalInfo}</div>}
                            </div>}
                            type={""}
                            dateTime={dateTime || ''}
                            closeToast={closeToast}
                            onAction={() => LayoutActions.openPriceAlerts()}
                        />,
                            {
                                toastId: `alert_${new Date().getTime()}`,
                                position: toast.POSITION.BOTTOM_RIGHT,
                                pauseOnFocusLoss: false,
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeButton: false,
                                className: "toastify-container",
                                bodyClassName: "toastify-body",
                                transition: Slide,
                                style: { width: 325 }
                            });
                        priceAlertsOpen && CommonActions.setPriceAlert()
                    }
                })
                onSuccess({ nextMsgCount: success.nextMsgCount });
            }
        }).catch(function (error) {
        });
    function onSuccess(list) {
        store.dispatch({ type: PRICE_ALERTS, list });
    }
    /* function onFailure(error) {
        store.dispatch({ type: PRICE_ALERTS_FAILURE, error });
    } */
}

function getFullTradebullsMsgs() {
    const { updates: { tradebullsMsgs: { nextMsgCount } } } = store.getState();
    const options = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/ms-client-notification/notify/get/GEN`,
        timeout: REFRESH_INTERVAL,
    };
    axios(options)
        .then(response => {
            const { data: { success, error } } = response;
            if (success?.length > 0) {
                let list = [];
                success.map((ele) => {
                    const { message, createdOn } = ele;
                    list.push({ msg: message, time: createdOn })
                    /* notificationStore.addNotification({
                        content: <Toast
                            title={"Tradebulls"}
                            message={message}
                            type={"default"}
                            dateTime={getFormattedDate(createdOn) || 'dateTime here'}
                            onAction={() => LayoutActions.openTradebullsMsgs()}
                        />,
                        insert: "top",
                        container: "bottom-right",
                        dismiss: {
                            duration: 5000
                        }
                    }) */
                })
                onSuccess({ msgList: list, nextMsgCount: success.length + 1, resetData: nextMsgCount == 1 });
            }
        }).catch(function (error) {
        });
    function onSuccess(list) {
        store.dispatch({ type: TRADEBULLS_MESSAGES, list });
    }
    function onFailure(error) {
        store.dispatch({ type: TRADEBULLS_MESSAGES_FAILURE, error });
    }
}

function getTradebullsMsgs(type) {
    const { updates: { tradebullsMsgs: { nextMsgCount } } } = store.getState();
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_FEED_URL}/ms-watchlist-service/genMsgs`,
        timeout: REFRESH_INTERVAL,
        data: [{
            loadType: type,
            nextMsgCount
        }]
    };
    axios(options)
        .then(response => {
            const { data: { success, error } } = response;
            if (success?.msgList?.length > 0) {
                success.msgList.map((ele) => {
                    const { time, msg } = ele;
                    toast(({ closeToast }) => <Toast
                        title={"Tradebulls"}
                        message={msg}
                        type={"default"}
                        dateTime={getFormattedDate(time) || 'dateTime here'}
                        closeToast={closeToast}
                        onAction={() => LayoutActions.openTradebullsMsgs()}
                    />,
                        {
                            toastId: `tradebulls_${time}`,
                            position: toast.POSITION.BOTTOM_RIGHT,
                            pauseOnFocusLoss: false,
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeButton: false,
                            className: "toastify-container",
                            bodyClassName: "toastify-body",
                            transition: Slide,
                            style: { width: 325 }
                        });
                })
                onSuccess({ msgList: success.msgList, nextMsgCount: success.nextMsgCount });
            }
        }).catch(function (error) {
        });
    function onSuccess(list) {
        store.dispatch({ type: TRADEBULLS_MESSAGES, list });
    }
    function onFailure(error) {
        store.dispatch({ type: TRADEBULLS_MESSAGES_FAILURE, error });
    }
}

const InitialState = {
    exchMsgs: [],
    tradeMsgs: [],
    tradebullsMsgs: {
        msgList: [],
        nextMsgCount: 1
    },
    advisoryMsgs: {
        msgList: [],
        nextMsgCount: 1
    },
    priceAlerts: {
        nextMsgCount: 1
    }
};

//Reducer
export function updatesReducer(state = InitialState, action) {
    switch (action.type) {
        case EXCHANGE_MESSAGES:
            return {
                ...state,
                exchMsgs: action.list,
                exchMsgsError: null
            };
        case EXCHANGE_MESSAGE_FAILURE:
            return {
                ...state,
                exchMsgsError: action.error
            };
        case TRADE_MESSAGES:
            return {
                ...state,
                // tradeMsgs: state.tradeMsgs[state.tradeMsg.length] = action.msg,
                tradeMsgs: [action.msg].concat(state.tradeMsgs),
                tradeMsgsError: null
            };
        case TRADE_MESSAGES_FAILURE:
            return {
                ...state,
                tradeMsgsError: action.error
            };
        case ADVISORY_MESSAGES:
            // debugger
            return {
                ...state,
                advisoryMsgs: {
                    msgList: action.list.resetData ? action.list.msgList //polling full call
                        : action.list && action.list.msgList && action.list.msgList.length > 0
                            ? action.list.msgList/* .concat(state.advisoryMsgs.msgList) */ //concat with previous data
                            : state.advisoryMsgs.msgList,
                    nextMsgCount: action.list && action.list.nextMsgCount,
                    desk: action?.list?.desk || [],
                    filter: action?.list?.filter || {},
                    products: action?.list?.products || {},
                },
                advisoryMsgsError: null
            };
        case ADVISORY_MESSAGES_FAILURE:
            return {
                ...state,
                advisoryMsgsError: action.error
            };
        case PRICE_ALERTS:
            return {
                ...state,
                priceAlerts: {
                    nextMsgCount: action.list && action.list.nextMsgCount
                }
            };
        case TRADEBULLS_MESSAGES:
            return {
                ...state,
                tradebullsMsgs: {
                    msgList: action.list.resetData ? action.list.msgList //polling full call
                        : action.list && action.list.msgList && action.list.msgList.length > 0
                            ? action.list.msgList.concat(state.tradebullsMsgs.msgList) //concat with previous data
                            : state.tradebullsMsgs.msgList,
                    nextMsgCount: action.list && action.list.nextMsgCount
                },
                tradebullsMsgsError: null
            };
        case TRADEBULLS_MESSAGES_FAILURE:
            return {
                ...state,
                tradebullsMsgsError: action.error
            };
        default:
            return state;
    }
}
