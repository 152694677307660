import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import axios from 'helpers/interceptor';
import { reportService } from "services/reports";
import ReactTable from 'components/ReactTable';

const historyUrl = '/ms-order-report/thistory';

const USER = `${process.env.REACT_APP_USER}`;
const isNextUser = (USER !== 'client') ? true : false;

const styles = theme => ({
  paper: {
    top: '35px',
    border: `1px solid ${theme.palette.background.secondary}`,
    padding: 0,
    height: 'auto',
  },
  container: {
    background: theme.palette.background.default,
    width: '50vw',
    height: '50vh',
    padding: 0,
    marginTop: -12,
    marginBottom: -8,
    marginLeft: -24,
    marginRight: -24,
    padding: '10px 0'
  },
  tableTitle: {
    color: theme.palette.text.primary,
    color: theme.palette.text.secondary,
    fontSize: 14,
    margin: '5px 15px'
  }
});

const colMappings = {
  exchange: 0, segment: 1, clientCode: 2, scripId: 3, scriptaccessorKey: 4, buySell: 5, orderType: 6, orderQty: 7, orderPrice: 8, orderStatus: 9,
  execQty: 10, execPrice: 11, requestStatus: 12, trigPrice: 13, disclosedQty: 14, productType: 15, exchOrderId: 16, exehDateTime: 17,
  tbOrderId: 18, tbOrderDatetime: 19, validity: 20, shortCode: 21, remQTY: 22, modiQty: 23, modiPrice: 24, remark: 25
}

function OrderHistory(props) {
  const { classes, data: { columns, orderRow } } = props;
  const { exchOrderId, shortCode, tbOrderId, clientCode } = orderRow;

  const [history, setHistory] = useState();
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    if (!history) {
      axios.post(`${process.env.REACT_APP_API_URL}${historyUrl}`, {
        shortCode,
        exOrderNo: exchOrderId, /* tbOrderId */
        userId: clientCode,
        isPipeSeparated: 'Y',
      }).then(response => {
        if (response.data.success) {
          const historyReport = reportService.convertPipeToArray(response.data.success, colMappings);
          setHistory(historyReport);
        } else {
          setHistory(response.data.success);
        }
        setRefreshing(false);
      }).catch(error => {
        setRefreshing(false);
      });
    }
  }, []);

  const options = {
    // width: 100,
    sorting: false,
    draggable: false,
    columnResizing: false,
    selectableRows: false,
    refreshing: refreshing
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.tableTitle}>Order Details</Typography>
      <div style={{ height: 88 }}>
        <ReactTable
          options={options}
          columns={columns}
          data={[orderRow || {}]}
        />
      </div>
      <Divider orientation="horizontal" style={{ height: '5px', backgroundColor: 'transparent' }} />
      <Typography className={classes.tableTitle}>Order History</Typography>
      <div style={{ height: ((history?.length || 1) * 30) + 56 }}>
        <ReactTable
          options={options}
          columns={historyColumns}
          data={history?.length > 0 ? history : []}
        />
      </div>
    </Box>
  );
}

const historyColumns = [
  {
    id: 'requestStatus',
    header: 'Request',
    accessorKey: 'requestStatus',
    size: 180
  },
  {
    id: 'exehDateTime',
    header: 'Exchange Date Time',
    accessorKey: 'exehDateTime',
    size: 180
  },
  {
    id: 'orderQty',
    header: 'Order Qty.',
    accessorKey: 'orderQty'
  },
  {
    id: 'orderPrice',
    header: 'Order Price',
    accessorKey: 'orderPrice'
  },
  {
    id: 'trigPrice',
    header: 'Trigger Price',
    accessorKey: 'trigPrice'
  },
  {
    id: 'disclosedQty',
    header: 'Disclosed Qty.',
    accessorKey: 'disclosedQty',
    hideColumn: !isNextUser
  },
  {
    id: 'execQty',
    header: 'Exec. Qty.',
    accessorKey: 'execQty',
    hideColumn: !isNextUser
  },
  {
    id: 'execPrice',
    header: 'Exec. Avg. Price',
    accessorKey: 'execPrice',
    hideColumn: !isNextUser
  },
  {
    id: 'remQTY',
    header: 'Rem. Qty.',
    accessorKey: 'remQTY',
    hideColumn: !isNextUser,
  },
  {
    id: 'modiQty',
    header: 'Modi. Qty.',
    accessorKey: 'modiQty',
    hideColumn: !isNextUser
  },
  {
    id: 'modiPrice',
    header: 'Modi. Price',
    accessorKey: 'modiPrice',
    hideColumn: !isNextUser
  },
  {
    id: 'orderStatus',
    header: 'Order Status',
    accessorKey: 'orderStatus'
  },
  {
    id: 'remark',
    header: 'Remarks',
    accessorKey: 'remark',
    hideColumn: !isNextUser,
  }
];

export default withStyles(styles)(OrderHistory);