import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from "./styles";

function ChargeGrid(props) {
  const { classes, type, charge } = props;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" className={classes.primaryText}>{type}</Typography>
        <Typography variant="h6" className={classes.primaryText}>&#x20b9; {charge}</Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(ChargeGrid);