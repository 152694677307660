//import config from '../../config';
import axios, { tokenReInitializer } from 'helpers/interceptor';
import { setCookie, deleteCookie, getCookie } from 'helpers/cookies'

import { history, store } from 'helpers';

export const userService = {
    login,
    logout,
    register
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SERVER_AUTH_URL}${process.env.REACT_APP_AUTH_LOGIN}`,
        data: { username, password, "clientId": "tbsenterpriseweb", "appId": "1" },
        auth: true
    };


    /*return fetch(`${process.env.REACT_APP_API_URL}/user/signin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });*/

    return axios(requestOptions)
        .then(({ data: { success } }) => {
            var resp = success
            //console.log("login resp", resp)
            var loginKey = localStorage.getItem('isFirstLogin');
            if (loginKey != null) {
                localStorage.removeItem('isFirstLogin');
            }
            // login successful if there's a jwt token in the response
            if (resp.access_token) {
               // store.dispatch({ type: "USERS_LOGIN_FAILURE", error: "" })
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('access_token',resp.access_token);
                //setCookie("token", resp.access_token, 24 * 60 * 60 , "/",window.location.host)
                //setCookie("refresh_token", resp.refresh_token, 24 * 60 * 60 , "/",window.location.host)
                document.cookie = "token=" + resp.access_token+";"// + ";secure;"
                document.cookie = "refresh_token=" + resp.refresh_token + ";"// + ";secure;"
                /*  setCookie("refresh_token", user.refresh_token, 24 * 60 * 60, "/", '192.168.207.218') */
                var tkDetail = JSON.parse(atob(resp.access_token.split(".")[1]))
                //setTimeout(tokenReInitializer.bind(null, tkDetail.exp - tkDetail.iat - 100), (tkDetail.exp - tkDetail.iat - 100) * 1000);
                // setTimeout(tokenReInitializer.bind(null,tkDetail.exp-tkDetail.iat-10) , 20000);
               

            }


            return resp;
        })
        .catch(function (error) {
            console.log(error);
            if (error.response)
                store.dispatch({ type: "USERS_LOGIN_FAILURE", error: error.response.data.data.error.error_description })
            else
                store.dispatch({ type: "USERS_LOGIN_FAILURE", error: "Technical Issue. Try Again Later" })
        })


}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    //   deleteCookie('token')
    //   deleteCookie('refresh_token')
    /*  if(getCookie('token') || getCookie('refresh_token')){ */
    axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SERVER_AUTH_URL}${process.env.REACT_APP_AUTH_LOGOUT}`, "").then(resp => {
        if (resp.data.success) {
           // document.cookie = "token="
           // deleteCookie('token')
          //  document.cookie = "refresh_token="
          //  deleteCookie('refresh_token')
            localStorage.removeItem('isFirstLogin')
        }
    }).catch(exception => {

    })
    /* } */


}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/users/register`, requestOptions).then(handleResponse);
}


function handleResponse(response) {

    const data = response.data

    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
    }

    return data;

}