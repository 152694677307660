import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import styles from './styles';

function ResearchToast(props) {
  const { classes, getFormattedDate, onAction, data, closeToast } = props;
  const { desk, scripName, recoStatus, researchProdName, buySell, currentMktPrice, remark, message, riskReward, time, shortCode,
    recoPriceFrom, recoPriceTo, target1Price, target2Price, stopLossPrice, stopLossExitPrice, fullExitPrice, bppExitPrice } = data;

  const priceValue = (label, value, maxValue) => {
    return (
      <div className={classes.flexContent}>
        <Typography variant="subtitle2" className={classes.primaryText}>
          {((Number(value) > 0) && Number(value).toFixed(shortCode === 'NCD' ? 4 : 2)) || '--'}
          {maxValue && ` - ${((Number(maxValue) > 0) && Number(maxValue).toFixed(shortCode === 'NCD' ? 4 : 2))}`}
        </Typography>
        <Typography variant="body2" className={classes.secondaryText}>{label}</Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div style={{ width: '100%' }}>
        <Grid container>
          <Grid item lg={11} md={11} sm={11} xs={11} className={classes.titleBar} onClick={onAction}>
            <Typography variant="h6" className={classes.title}>
              {desk || 'Tradebulls Research'}
            </Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1} className={classes.closeIconGrid}>
            <CloseIcon onClick={closeToast} />
          </Grid>
        </Grid>
        <div className={classes.mainDiv} onClick={onAction}>
          <div className={classes.heading}>
            <div className={classes.spaceContent}>
              <Typography variant="h5" className={classes.blueText}>{scripName}</Typography>
              <Typography variant="subtitle2" className={[classes.secondaryText, "text"].join(" ")}>Status: <span className={classes.primaryText}>{recoStatus}</span></Typography>
            </div>
            <div className={classes.spaceContent}>
              <div style={{ display: 'flex' }}>
                <Typography variant="subtitle2" className={classes.secondaryText} style={{ marginRight: 8 }}>Product: <span className={classes.primaryText}>{researchProdName}</span></Typography>
                <Typography variant="subtitle2" className={classes.secondaryText}>Reco: <span className={classes.typeText}>{buySell}</span></Typography>
              </div>
              <Typography variant="subtitle2" className={classes.secondaryText}>CMP: <span className={classes.primaryText}>{currentMktPrice}</span></Typography>
            </div>
          </div>
          <Grid container className={classes.infoDiv}>
            <Grid item xs={7}>
              <Grid container>
                <Grid item xs={8} className={classes.valueDiv}>
                  {priceValue('RecoPrice', recoPriceFrom, recoPriceTo)}
                </Grid>
                <Grid item xs={4} className={classes.valueDiv}>
                  {priceValue('Target1', target1Price)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                {/* <Grid item xs={4} className={classes.valueDiv}>
                  {priceValue('Target2', target2Price)}
                </Grid> */}
                <Grid item xs={6} className={classes.valueDiv}>
                  {priceValue('StopLoss', stopLossPrice)}
                </Grid>
                <Grid item xs={6} className={classes.valueDiv} style={{ borderRight: 0 }}>
                  {priceValue('Exit Price', Number(fullExitPrice) || Number(bppExitPrice) || Number(stopLossExitPrice))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.riskDiv}>
            <Typography variant="body2" className={classes.secondaryText}>Risk Reward Ratio <span className={classes.primaryText}>{riskReward}</span></Typography>
            <Typography variant="body2" className={classes.secondaryText}>Remark: <span className={classes.primaryText}>{remark}</span></Typography>
          </div>
          <Typography variant="body2" className={classes.msgText}>Message: <span className={classes.primaryText}>{message}</span></Typography>
        </div>
      </div>
      <Typography variant="caption" className={classes.primaryText} style={{ textAlign: 'right' }}>
        {getFormattedDate(time) || ''}
      </Typography>
    </div>
  )
}

export default (withStyles(styles)(ResearchToast));