import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@mui/styles';

import * as FlexLayout from 'flexlayout-react';
import SearchDialog from "widgets/SearchDialog";
import FormDialog from "widgets/FormDialog";
import ConfirmDialog from 'widgets/ConfirmDialog';
import Snackbars from "components/Snackbars";
import Popper from "components/Popper";
//import 'assets/css/dark.css';

// Component styles
import styles from './style';

// import { Button } from '@mui/material';
import { LayoutActions } from "redux/Layout";
import MyPortfolio from "widgets/Portfolio";
import { DialogActions, openConfirmDialog } from 'redux/ConfirmDialog';
import { connect } from 'react-redux';
import { store } from "helpers";

var json = {
	"global": {
		"tabSetTabStripHeight": 25,
		"tabEnableClose": false,
		"enableRename": false
	},
	"layout": {
		"type": "row",
		"children": [
			{
				"type": "tabset",
				"weight": 100,
				"selected": 0,
				"enableTabStrip": true,
				"children": [
					{
						"type": "tab",
						"name": "My Portfolio",
						"component": "portfolio",
						"config": {},
						"enableDrag": true
					}
				],
				"active": true
			}
		]
	},
	// "borders": [
	//   {
	// 	"type": "border",
	// 	"location": "right",
	// 	"size": 200,
	// 	"children": [],
	// 	"barsize": 20
	//   },
	//   {
	// 	"type": "border",
	// 	"location": "bottom",
	// 	"size": 200,
	// 	"children": [],
	// 	"barsize": 20
	//   }
	// ]
}

class Portfolio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saveVersion: 0, selectClientError: false
		};
		this.FlexLayoutState = { model: FlexLayout.Model.fromJson(json) };
	}

	componentDidMount() {
		this.setState({ saveVersion: this.props.saveVersion });
		if (this.props.layoutType === "ctclClient" && !this.props.selectedClient) {
			this.setState({ selectClientError: true });
			let obj = {
				content: 'Kindly select client.',
				hideCloseButton: true,
				okButtonText: 'Ok',
				centerActions: true,
				onClose: () => {
					DialogActions.confirmDialogClose()
				}
			};
			store.dispatch(openConfirmDialog(obj));
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// console.log("Called componentWillReceiveProps")
		if (nextProps.selectedClient) {
			this.setState({ selectClientError: false });
		}
	}

	// static getDerivedStateFromProps(props, state){
	// 	//TODO: make layout changes here
	// 	console.log('getDerivedStateFromProps');
	// 	if(props.saveVersion>state.saveVersion){
	// 		return{saveVersion:props.saveVersion};
	// 	}
	// 	return null;
	// }

	// save() {
	// 	var jsonStr = JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t");
	// 	localStorage.setItem("Layout", jsonStr);
	// }

	classMappingtoJSS = className => {
		let newClassName
		//console.log(className);
		if (className == 'flexlayout__tab_button--selected') {
			newClassName = this.props.classes['flexlayout__tab_button_selected'];
		}
		else if (className == 'flexlayout__tab_button--unselected') {
			newClassName = this.props.classes['flexlayout__tab_button_unselected'];
		}
		else if (className == 'flexlayout__tabset-selected') {
			newClassName = this.props.classes['flexlayout__tabset_selected'];
		}
		else if (className == 'flexlayout__tabset-maximized') {
			newClassName = this.props.classes['flexlayout__tabset_maximized'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-min') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_min'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-max') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_max'];
		}
		else if (className == 'flexlayout__border_button--selected') {
			newClassName = this.props.classes['flexlayout__border_button_selected'];
		}
		else if (className == 'flexlayout__border_button--unselected') {
			newClassName = this.props.classes['flexlayout__border_button_unselected'];
		}
		else {
			newClassName = this.props.classes[className];
		}

		if (newClassName) {
			return newClassName;
		}
		else {
			return className;
		}
		// return <FlexLayout.Layout model={model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} />;
	}

	factory = (node) => {
		var component = node.getComponent();
		if (component === "button") {
			return <button>{node.getName()}</button>;
		}
		else if (component === "text") {
			return (<div className="panel">Panel {node.getName()}</div>);
		}
		else if (component === "sub") {
			var model = node.getExtraData().model;
			if (model == null) {
				model = FlexLayout.Model.fromJson(node.getConfig().model);
				//console.log(model)
			}
			return <FlexLayout.Layout model={model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} />;
		}
		else if (component === "portfolio") {
			return <MyPortfolio />
		}
	}

	onShowLayoutClick = (event) => {
		console.log(JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
	}

	onModelChange = () => {
		//console.log("Action Called",JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
		// console.log("Model Changed" + this.FlexLayoutState.model)
		//this.FlexLayoutState.model.doAction(FlexLayout.Actions.maximizeToggle("Watchlist"));

		LayoutActions.layoutChanged(this.FlexLayoutState.model.toJson());
		// setLayout(this.FlexLayoutState.model.toJson());

		let maximizedTabset = this.FlexLayoutState.model.getMaximizedTabset()
		if (maximizedTabset) {
			let maximizedTab = maximizedTabset.getSelectedNode().getComponent()
			LayoutActions.onTabMaxed(maximizedTab);
		} else {
			LayoutActions.onTabMaxed('');
		}
	}

	// onAction = () =>{
	// 	console.log("Action Called",JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
	// 	return <FlexLayout.Layout model={this.FlexLayoutState.model} factory={this.factory} onModelChange={this.onModelChange} onAction = {this.onAction} classNameMapper={this.classMappingtoJSS}/>
	// }

	render() {
		const { classes } = this.props;
		const { selectClientError } = this.state;

		return (
			// <DashboardLayout title="Dashboard">
			<div className={classes.root}>
				{/* <div><Button style={{ float: "right" }} onClick={this.onShowLayoutClick}>Show Layout JSON in Console</Button></div> */}
				<div>
					{
						!selectClientError &&
						<FlexLayout.Layout model={this.FlexLayoutState.model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} />
					}
				</div>
				<SearchDialog />
				<FormDialog />
				<ConfirmDialog />
				<Snackbars />
				<Popper />
			</div>
			// </DashboardLayout>
		);
	}
}

Portfolio.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	const { layout: { layoutType }, client: { selectedClient } } = state;
	return { layoutType, selectedClient };
};

export default connect(mapStateToProps)(withStyles(styles)(Portfolio));