export default ({ palette, breakpoints }) => ({
  styleOverrides: {
    root: {
      color: palette.text.secondary,
      minWidth: '32px',
      [breakpoints.down('xl')]: {
        minWidth: '28px'
      },
      [breakpoints.down('md')]: {
        minWidth: '24px'
      }
    }
  }
});
