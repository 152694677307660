import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@mui/styles';

import * as FlexLayout from 'flexlayout-react';
// import WatchlistMF from 'widgets/watchlistmf';
import SearchDialog from "widgets/SearchDialog";
import FormDialog from "widgets/FormDialog";
import ConfirmDialog from 'widgets/ConfirmDialog';
import Snackbars from "components/Snackbars";
import Popper from "components/Popper";
//import 'assets/css/dark.css';

// Component styles
import styles from './style';

import { Button } from '@mui/material';

import { connect } from "react-redux";
import { LayoutActions } from "redux/Layout";
// import FundDetails from "widgets/MFwidgets/funddetails";
// import OrderReportMF from "./reports/order";
// import RegisteredSIPReportMF from "./reports/registeredsip";
// import StatementsMF from "./reports/statements";
// import FundTransferReportMF from "./reports/fundtransfer";
// import Portfolio from "widgets/MFwidgets/portfolio";
// import Overview from "widgets/MFwidgets/funddetailsoverview";
// import Peers from "widgets/MFwidgets/peers";
// import TransactionHistoryMF from "./reports/transactionhistory";
// import ScheduledSIPMF from "./reports/scheduledsip";
// import BankMandateMF from "./reports/bankmandate";
import { CommonActions } from "redux/Common";
import { FundExplorer } from "./fundexplorer";
import { loadLayout } from "views/Dashboard/layouts/layoutHelper";
import { PrefsService } from "services/prefs";
// import MfCalendar from "widgets/MFwidgets/mfcalendar";
// import DematHoldingsMF from "./reports/dematholdings";
import { getCookie, deleteCookie, setCookie } from 'helpers/cookies';
import { MODAL_CHILD } from 'widgets/FormDialog';
import { DialogActions } from 'redux/Dialog';
import Loader from "components/Loader";

import { WatchlistMF } from 'widgets';
import { Portfolio, Overview, Peers, FundDetails, FundDetailsMain, MfCalendar } from 'widgets/MFwidgets'
import { OrderReportMF, RegisteredSIPReportMF, StatementsMF, FundTransferReportMF, TransactionHistoryMF, ScheduledSIPMF, BankMandateMF, DematHoldingsMF, BseBankMandateMF } from './reports'

class MutualFund extends Component {

	constructor(props) {
		super(props);

		this.state = { saveVersion: 0 };

		// let modelFromJson = FlexLayout.Model.fromJson(json);
		let modelFromJson = FlexLayout.Model.fromJson(loadLayout('mf'));
		this.FlexLayoutState = { model: modelFromJson };
		CommonActions.setPathName('mf');
		LayoutActions.clearLayout();
		modelFromJson.visitNodes(this.registerNodes);
		this.checkMaxed(modelFromJson);
		// this.maxWatchlist();
		if (getCookie("tbMfSingleOrder")) {
			DialogActions.dialogOpen(MODAL_CHILD.MFORDER, JSON.parse(atob(getCookie("tbMfSingleOrder"))));
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.layoutJson != this.props.layoutJson) {
			this.FlexLayoutState = { model: FlexLayout.Model.fromJson(loadLayout('mf')) };
			this.FlexLayoutState.model.visitNodes(this.registerNodes);
			this.checkMaxed(this.FlexLayoutState.model);
		}
		if (nextProps.activeTab !== this.props.activeTab) {
			this.setActiveTab(nextProps.activeTabId);
		}
		if (nextProps.maximizedView !== this.props.maximizedView) {
			if (nextProps.activeTabId) {
				this.toggleMaximize(nextProps.activeTabId);
			}
		}
		if (this.props.isOpen && !nextProps.isOpen) {
			if (this.props.modalChild === "mf_order" && getCookie("tbMfSingleOrder")) {
				deleteCookie("tbMfSingleOrder")
			}
		}
	}

	maxWatchlist() {
		let parentNode = this.FlexLayoutState.model.getNodeById('#3').getParent();
		this.FlexLayoutState.model.doAction(FlexLayout.Actions.maximizeToggle(parentNode.getId()));
		this.checkMaxed(this.FlexLayoutState.model);
	}

	checkMaxed(model) {
		let maximizedTabset = model.getMaximizedTabset()
		if (maximizedTabset) {
			let maximizedTab = maximizedTabset.getSelectedNode().getComponent()
			LayoutActions.onTabMaxed(maximizedTab);
		} else {
			LayoutActions.onTabMaxed('');
		}
	}

	registerNodes = node => {
		if (node.getType() == "tab") {
			let nodeId = node.getId();
			let component = node.getComponent();
			let parentNode = node.getParent();
			let parentNodeType = parentNode.getType();
			if (parentNodeType == "tabset" /* || parentNodeType == "border" */) {
				let isActive = parentNode.isActive();
				if (isActive) {
					LayoutActions.setActiveTab(parentNode.getSelectedNode().getComponent());
				}
			}
			if (component !== "button" && component !== "text" && component !== "sub") {
				LayoutActions.setNodeId(component, nodeId);
			}
			//Do this if the node is inner node
			if (component == 'sub') {
				let subModel = node.getExtraData().model;
				if (subModel == null) {
					subModel = FlexLayout.Model.fromJson(node.getConfig().model);
				}
				subModel.visitNodes(this.registerNodes);
			}
			//For inner node
		}
	}
	componentDidMount() {
		this.setState({ saveVersion: this.props.saveVersion });
		PrefsService.getAllPrefs(true);
	}

	setActiveTab(activeTabId) {
		this.FlexLayoutState.model.doAction(FlexLayout.Actions.selectTab(activeTabId));
	}

	toggleMaximize(activeTabId) {
		let parentNode = this.FlexLayoutState.model.getNodeById(activeTabId).getParent();
		this.FlexLayoutState.model.doAction(FlexLayout.Actions.maximizeToggle(parentNode.getId()));
	}

	classMappingtoJSS = className => {
		let newClassName
		//console.log(className);
		if (className == 'flexlayout__tab_button--selected') {
			newClassName = this.props.classes['flexlayout__tab_button_selected'];
		}
		else if (className == 'flexlayout__tab_button--unselected') {
			newClassName = this.props.classes['flexlayout__tab_button_unselected'];
		}
		else if (className == 'flexlayout__tabset-selected') {
			newClassName = this.props.classes['flexlayout__tabset_selected'];
		}
		else if (className == 'flexlayout__tabset-maximized') {
			newClassName = this.props.classes['flexlayout__tabset_maximized'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-min') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_min'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-max') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_max'];
		}
		else if (className == 'flexlayout__tab_toolbar_button-float') {
			newClassName = this.props.classes['flexlayout__tab_toolbar_button_float'];
		}
		else if (className == 'flexlayout__border_button--selected') {
			newClassName = this.props.classes['flexlayout__border_button_selected'];
		}
		else if (className == 'flexlayout__border_button--unselected') {
			newClassName = this.props.classes['flexlayout__border_button_unselected'];
		}
		else {
			newClassName = this.props.classes[className];
		}

		if (newClassName) {
			return newClassName;
		}
		else {
			return className;
		}
		// return <FlexLayout.Layout model={model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} />;
	}

	factory = (node) => {
		var component = node.getComponent();
		// if (!node.isVisible() && component != 'watchlist') {
		// 	// for unmounting every time
		// 	// console.log('Node is not visible: '+node.getComponent())
		// 	return null;
		// }
		if (component === "button") {
			return <button>{node.getName()}</button>;
		}
		else if (component === "text") {
			return (<div className="panel">Panel {node.getName()}</div>);
		}
		else if (component === "sub") {
			var model = node.getExtraData().model;
			if (model == null) {
				model = FlexLayout.Model.fromJson(node.getConfig().model);
				//console.log(model)
			}
			return <FlexLayout.Layout model={model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} />;
		}
		else if (component === "watchlist") {
			return <WatchlistMF />
		}
		else if (component === "funddetailsmain") {
			return <FundDetailsMain />
		}
		else if (component === "funddetails") {
			return <FundDetails />
		}
		else if (component === "orderreportmf") {
			return <OrderReportMF />
		}
		else if (component === "registeredsip") {
			return <RegisteredSIPReportMF />
		}
		else if (component === "statements") {
			return <StatementsMF />
		}
		else if (component === "fundtransfer") {
			return <FundTransferReportMF />
		}
		else if (component === "portfolio") {
			return <Portfolio />
		}
		else if (component === "overview") {
			return <Overview />
		}
		else if (component === "peers") {
			return <Peers />
		}
		else if (component === "transactionhistory") {
			return <TransactionHistoryMF />
		}
		else if (component === "scheduledsip") {
			return <ScheduledSIPMF />
		}
		else if (component === "dematholdings") {
			return <DematHoldingsMF />
		}
		else if (component === "bankmandate") {
			return <BankMandateMF />
		}
		else if (component === "fundexplorer") {
			return <FundExplorer />
		}
		else if (component === "mfcalendar") {
			return <MfCalendar />
		}
		else if (component === "bsebankmandate") {
			return <BseBankMandateMF />
		}
	}

	onShowLayoutClick = (event) => {
		console.log(JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
	}



	onModelChange = () => {
		//console.log("Action Called",JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
		// console.log("Model Changed"+this.FlexLayoutState.model)
		//this.FlexLayoutState.model.doAction(FlexLayout.Actions.maximizeToggle("Watchlist"));

		LayoutActions.layoutChanged(this.FlexLayoutState.model.toJson());
		// setLayout(this.FlexLayoutState.model.toJson());

		let maximizedTabset = this.FlexLayoutState.model.getMaximizedTabset()
		if (maximizedTabset) {
			let maximizedTab = maximizedTabset.getSelectedNode().getComponent()
			LayoutActions.onTabMaxed(maximizedTab);
		} else {
			LayoutActions.onTabMaxed('');
		}
	}



	// onAction = () =>{
	// 	console.log("Action Called",JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));
	// 	return <FlexLayout.Layout model={this.FlexLayoutState.model} factory={this.factory} onModelChange={this.onModelChange} onAction = {this.onAction} classNameMapper={this.classMappingtoJSS}/>
	// }

	render() {
		const { classes, saveVersion, layoutLoaded } = this.props;
		//   const { saveVersion, isNew } = this.state;
		//console.log(JSON.stringify(this.FlexLayoutState.model.toJson(), null, "\t"));

		return (
			// <DashboardLayout title="Dashboard">

			<div className={classes.root}>
				{/* <div>
              <h2><Translate id="greeting" data={{ name: 'Mangesh' }}>{'Welcome ${name}!'}</Translate></h2>
              <h2><Translate id="home.greeting"/></h2>
          </div> */}
				<div><Button style={{ float: "right" }} onClick={this.onShowLayoutClick}>Show Layout JSON in Console</Button></div>
				{layoutLoaded ?
					<div><FlexLayout.Layout model={this.FlexLayoutState.model} factory={this.factory} onModelChange={this.onModelChange} classNameMapper={this.classMappingtoJSS} /></div>
					: <Loader style={{ height: "100%" }} />}
				<SearchDialog />
				<FormDialog />
				<ConfirmDialog />
				<Snackbars />
				<Popper />
			</div>

			// </DashboardLayout>
		);
	}
}

MutualFund.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	const { activeTab, activeTabId, layoutJson, maximizedView } = state.layout;
	const { layoutLoaded } = state.prefs;
	const { isOpen, modalChild } = state.dialog;
	return { activeTab, activeTabId, maximizedView, layoutJson, isOpen, modalChild, layoutLoaded };
};

export default connect(mapStateToProps)(withStyles(styles)(MutualFund));
