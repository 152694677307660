import { Button, CircularProgress, Grid, Link, Typography } from "@mui/material";
import SvgComponent from "helpers/svgcomponent";
import React from "react";

function SendOtp(props) {
  const { classes, loading, sendOtp } = props;
  return (
    <div className={classes.stepRoot}>
      <Typography variant='h1' className={[classes.blueText, classes.heading].join(' ')}>2 Factor TOTP - <span className={classes.secondaryText} style={{ fontWeight: 500 }}>Disabled</span></Typography>
      <Grid container className={classes.containerGrid}>
        <Grid item xs={4} className={classes.imageGrid} style={{ paddingRight: 20 }}>
          <SvgComponent iconname={"GraphicsOtp"} style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.description}>Enable 2 Factor TOTP in place of PIN for added security. Use apps like Google® Authenticator on your mobile phone to generate 6 digit TOTPs for every login. <span><Link underline="always" target="_blank" href={`${process.env.REACT_APP_CORPORATE_URL}/enable-totp`}>Learn more</Link></span></Typography>
          <Typography className={classes.description}>We will send an OTP to your registered Mobile No. and E-mail ID for verification first.</Typography>
          <Button autoFocus={true} className={classes.button} onClick={() => sendOtp(true)} disabled={loading.step === 2 && loading.submit} variant="contained">
            {loading.submit && <CircularProgress size={20} style={{ marginRight: "12px", color: '#999999' }} />}
            Enable 2FA TOTP
          </Button>
        </Grid>
      </Grid>
    </div >
  )
}

export default SendOtp;