export default (theme) => ({
  list: {
    width: '600px',
    overflowY: 'auto',
    padding: 12
  },
  loadingDiv: {
    height: 200
  },
  paper: {
    margin: '10px 0px',
    backgroundColor: theme.palette.background.default
  },
  secondaryText: {
    color: theme.palette.color15.default
  },
  primaryText: {
    color: theme.palette.text.primary
  }
});