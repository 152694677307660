import { store } from "helpers";

export const POPPERS = {
    QUICK_QUOTE: 'popper_quick_quote'
}

//Actions
const OPEN_POPPER = 'OPEN_POPPER';
const CLOSE_POPPER = 'CLOSE_POPPER';

export const PopperActions = {
    openQuickQuote,
    closeQuickQuote,
}

function openQuickQuote(data) {
    store.dispatch(openPopper(POPPERS.QUICK_QUOTE, data));
}

function closeQuickQuote() {
    store.dispatch(closePopper(POPPERS.QUICK_QUOTE));
}

const InitialState = {
    data: null,
    isOpen: false
};

//Actions Creators
export function openPopper(child, data) {
    return { type: OPEN_POPPER, child, data };
}

export function closePopper(child) {
    return { type: CLOSE_POPPER, child };
}


//Reducer
export function popper(state = InitialState, action) {
    switch (action.type) {
        case OPEN_POPPER:
            return {
                ...state,
                [action.child]: true,
                [action.child + '_data']: action.data,
            };
        case CLOSE_POPPER:
            return {
                ...state,
                [action.child]: false,
            };;
        default:
            return state
    }
}
