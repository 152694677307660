import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

export const STATE = { LOADING: 'loading', NO_DATA: 'no_data', ERROR: 'error' };

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    content: {
        marginTop: '150px',
        textAlign: 'center'
    },
    spinner: {
        color: theme.palette.common.blue
    },
    contentText: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'break-spaces'
    },
    image: {
        display: 'inline-block',
        marginTop: '50px',
        maxWidth: '100%',
        width: '554px'
    }
});

function Placeholder(props) {
    const theme = useTheme();
    const matchesLg = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        classes: { contentText, root, spinner },
        error,
        loading,
        loadingMsg,
    } = props;
    let pText;
    if (loading) {
        pText = loadingMsg || 'Loading...';
    } else {
        pText = error || 'Empty Error!!!';
    }
    return (
        <Box className={root}>
            {loading && <CircularProgress size={matchesSm || matchesXs ? 20 : (matchesMd || matchesLg) ? 30 : 40} className={spinner} />}
            <Typography variant="subtitle1" className={contentText}>
                {pText}
            </Typography>
        </Box>
    );
}

export default withStyles(styles)(Placeholder);
