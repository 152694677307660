export default theme => ({
  paper: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.default
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.color5.default}`,
    width: "100%"
  },
  tab: {
    color: theme.palette.color15.default,
    // borderBottom: `1px solid ${theme.palette.color15.default}`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    }
  },
  activeTab: {
    color: "#8DB1D2 !important",
    borderBottom: `2px solid #8DB1D2`,
    [theme.breakpoints.down('xl')]: {
      fontSize: "12px",
      padding: "10px",
      height: "40px",
      minHeight: "40px",
      minWidth: "120px"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
      padding: "8px",
      height: "30px",
      minHeight: "30px",
      minWidth: "100px"
    }
  },
  boxDiv: {
    height: "calc(100% - 50px)",
    [theme.breakpoints.down('xl')]: {
      height: "calc(100% - 40px)",
    },
  }
})