import { Event } from 'components/Event';
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { CommonActions } from 'redux/Common';
import { DialogActions } from 'redux/Dialog';
import { LayoutActions } from 'redux/Layout';
import { AddGridActions } from 'redux/NewGrid';
import { PopperActions } from 'redux/Popper';
import { WatchListAction } from 'redux/Watchlist';
import { PrefsService } from 'services/prefs';
import { MODAL_CHILD } from 'widgets/FormDialog/index';
import { EventConstants } from './eventHelper';
import { store } from './store';
import { addMoneyNew } from './formRedirect';
import { setCookieNew } from './cookies';

const reports = [
  'dpsrreport',
  'orderreport',
  'positionreport',
  'tradereport',
  'ulmarginreport',
  'uspositionreport',
  'watchlist',
  'bracketorderreport'
];

const keyMap = {
  ORDER_BUY: ['f1'],
  ORDER_SELL: ['f2'],
  SEARCH_DIALOG: ['S', 's', 'shift+S'],
  SHORTCUTS: ['H', 'h', 'shift+h'],
  QUICK_QUOTE: ['Q', 'q', 'shift+q'],
  ORDER: ['O', 'o', 'shift+o'],
  POSITION: ['P', 'p', 'shift+p'],
  DPSR: ['D', 'd', 'shift+d'],
  WATCHLIST: ['W', 'w', 'shift+w'],
  CREATE_WATCHLIST: ['f4'],
  DELETE_SCRIP: ['del'],
  WATCHLIST_CHANGE: ['alt+1', 'alt+2', 'alt+3', 'alt+4', 'alt+5'],
  ARROW_ACTION: ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'],
  PROFILE: ['alt+P', 'alt+p', 'alt+shift+p'],
  LIMIT: ['L', 'l', 'shift+l'],
  DPSR_SELL: ['-'],
  MODIFY: ['shift+f2'],
  CANCEL: ['shift+f1'],
  REPEAT: ['R', 'r', 'shift+r'],
  SQUARE_OFF: ['*', 'shift+*'],
  ADD_POSITION: ['+', 'shift++'],
  VIEW: ['alt+M', 'alt+m', 'alt+shift+m'],
  FILTER: ['F', 'f'],
  ORDER_VIEW: ['C', 'c', 'shift+c'],
  TRADE: ['T', 't', 'shift+t'],
  MARGIN: ['M', 'm', 'shuft+m'],
  PREVIOUS_SETTLEMENT: ['U', 'u', 'shift+u'],
  // ADD_NEW_ORDER_REPORT: ['ctrl+o'],
  ADD_NEW_ORDER_REPORT_CANCELLED: ['ctrl+c'],
  ADD_NEW_ORDER_REPORT_FULLY_EXECUTED: ['f5'],
  ADD_NEW_ORDER_REPORT_EXPIRED: ['f6'],
  ADD_NEW_ORDER_REPORT_FREEZED: ['f7'],
  ADD_NEW_ORDER_REPORT_INPROCESS: ['f9'],
  ADD_NEW_ORDER_REPORT_PARTIALLY_EXECUTED: ['ctrl+e'],
  ADD_NEW_ORDER_REPORT_PENDING: ['f3'],
  ADD_NEW_ORDER_REPORT_REJECTED: ['shift+f4'],
  ADD_NEW_ORDER_REPORT_TRIGGERED: ['f10'],
  ADD_NEW_ORDER_REPORT_AMO: ['ctrl+a'],
  ADD_NEW_TRADE_REPORT: ['f8'],
  ADD_NEW_POSITION_REPORT: ['ctrl+p'],
  ADD_NEW_HOLDING_REPORT: ['ctrl+h'],
  ADD_NEW_MARGIN_REPORT: ['ctrl+m'],
  ADD_NEW_PREVIOUS_SETTLEMENT_REPORT: ['ctrl+u'],
};

const USER = `${process.env.REACT_APP_USER}`;

function HotKeys() {
  const handleCancelOrder = () => {
    let obj = store.getState() && store.getState().common.activeTabScrip.cancel;
    if (obj) {
      DialogActions.dialogOpen(MODAL_CHILD.CANCEL_ORDER, { form: obj });
    }
  };

  const checkOpenPopup = () => {
    if (
      store.getState() &&
      !store.getState().common.searchOpen &&
      !store.getState().popper.popper_quick_quote &&
      !store.getState().dialog.isOpen
    ) {
      return true;
    } else {
      return false;
    }
  };

  const openOrder = (tab, key) => {
    if (checkOpenPopup()) {
      let scrip = store.getState() && store.getState().common.activeTabScrip;
      let activeTab = store.getState() && store.getState().layout.activeTab;
      if (activeTab === tab && scrip && Object.keys(scrip).length > 0 /*  && checkOpenPopup() */) {
        let orderData = { form: key ? { ...scrip[key] } : { ...scrip } }
        if(key === 'repeat') {
            if (scrip[key]?.remark.includes("Insuffcient funds")) {
              setCookieNew("tbEqSingleOrder", btoa(JSON.stringify(orderData)))
              addMoneyNew()
            } else {
              DialogActions.openOrderForm(orderData);
            }
        } else {
          DialogActions.openOrderForm(orderData);
        }
      }
    }
  };

  const openOrderPopup = (scripKey, action) => {
    let scrip = (store.getState() && store.getState().common[scripKey]) || {};
    DialogActions.openOrderForm({
      form: { status: 'New', buySell: action },
      scrip
    });
  };

  const handleOpenPopup = (action) => {
    if (store.getState().common.searchOpen) {
      CommonActions.setSearchOpen(false);
      openOrderPopup('searchSelectedScrip', action);
    }
    if (store.getState().popper.popper_quick_quote) {
      PopperActions.closeQuickQuote();
      openOrderPopup('quoteSelectedScrip', action);
    }
  };

  let handlers = {
    ORDER_BUY: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        openOrderPopup('selectedScrip', 'B');
      } else {
        handleOpenPopup('B');
      }
    },
    ORDER_SELL: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        openOrderPopup('selectedScrip', 'S');
      } else {
        handleOpenPopup('S');
      }
    },
    SEARCH_DIALOG: (event) => {
      event.preventDefault();
      if (store.getState().popper.popper_quick_quote) {
        PopperActions.closeQuickQuote();
      }
      if (store.getState() && !store.getState().dialog.isOpen)
        CommonActions.setSearchOpen(true);
    },
    SHORTCUTS: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        DialogActions.dialogOpen(MODAL_CHILD.KEYBOARD_SHORTCUTS);
      }
    },
    ORDER: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType === 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('orderreport');
      }
    },
    QUICK_QUOTE: (event) => {
      event.preventDefault();
      if (store.getState().common.searchOpen) {
        CommonActions.setSearchOpen(false);
      }
      if (store.getState() && !store.getState().dialog.isOpen)
        PopperActions.openQuickQuote();
    },
    POSITION: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType === 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('positionreport');
      }
    },
    DPSR: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType === 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('dpsrreport');
      }
    },
    WATCHLIST: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('watchlist');
      }
    },
    CREATE_WATCHLIST: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        DialogActions.openCreateWatchList();
      }
    },
    DELETE_SCRIP: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        WatchListAction.deleteSelectedScrip();
      }
    },
    WATCHLIST_CHANGE: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        const { key } = event;
        WatchListAction.switchList(null, key - 1);
        new Event(EventConstants.SWITCH_WATCHLIST).sendMessage(key - 1);
      }
    },
    ARROW_ACTION: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        const { key } = event;
        let activeTab = store.getState() && store.getState().layout.activeTab;
        switch (activeTab) {
          case 'watchlist':
            WatchListAction.changeScrip(key);
            break;
          default:
            CommonActions.onKeyPressed(key);
            return;
        }
        new Event(EventConstants.ARROW_ACTION).sendMessage(key);
      }
    },
    LIMIT: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        DialogActions.dialogOpen(MODAL_CHILD.LIMIT_STATEMENT);
      }
    },
    PROFILE: (event) => {
      event.preventDefault();
      if (checkOpenPopup() && USER === 'client') {
        DialogActions.dialogOpen(MODAL_CHILD.ACCOUNT);
      }
    },
    DPSR_SELL: (event) => {
      event.preventDefault();
      openOrder('dpsrreport');
    },
    MODIFY: (event) => {
      event.preventDefault();
      let activeTab = store.getState() && store.getState().layout.activeTab;
      if (activeTab !== 'dpsrreport' && activeTab !== 'positionreport') {
        openOrder(activeTab || 'orderreport', 'modify');
      }
    },
    CANCEL: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        let scrip = store.getState() && store.getState().common.activeTabScrip;
        let activeTab = store.getState() && store.getState().layout.activeTab;
        if (
          activeTab === 'orderreport' &&
          scrip &&
          Object.keys(scrip).length > 0
        ) {
          handleCancelOrder();
        }
      }
    },
    REPEAT: (event) => {
      event.preventDefault();
      let activeTab = store.getState() && store.getState().layout.activeTab;
      if (activeTab !== 'dpsrreport' && activeTab !== 'positionreport') {
        openOrder(activeTab || 'orderreport', 'repeat');
      }
    },
    SQUARE_OFF: (event) => {
      event.preventDefault();
      openOrder('positionreport', 'squareOff');
    },
    ADD_POSITION: (event) => {
      event.preventDefault();
      openOrder('positionreport', 'addPosition');
    },
    VIEW: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        let maximize =
          store.getState() && store.getState().layout.maximizedView;
        LayoutActions.setFullView(!maximize);
        new Event(EventConstants.LAYOUT_EVENT).sendMessage({
          type: 'maximize'
        });
      }
    },
    FILTER: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        let activeTab = store.getState() && store.getState().layout.activeTab;
        if (reports.includes(activeTab)) {
          let filter = store.getState() && store.getState().common.showFilter;
          CommonActions.setFilter(!filter);
        }
      }
    },
    ORDER_VIEW: (event) => {
      event.preventDefault();
      if (store.getState() && store.getState().dialog.isOpen) {
        let view = store.getState() && store.getState().common.orderView;
        if (view === 2) {
          PrefsService.setOrderView(1);
        } else {
          PrefsService.setOrderView(2);
        }
      }
    },
    TRADE: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType === 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('tradereport');
      }
    },
    MARGIN: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType === 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('ulmarginreport');
      }
    },
    PREVIOUS_SETTLEMENT: (event) => {
      event.preventDefault();
      if (checkOpenPopup()) {
        LayoutActions.setActiveTab('uspositionreport');
      }
    },
    ADD_NEW_ORDER_REPORT: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "orderreport", label: "Orders" });
      }
    },
    ADD_NEW_ORDER_REPORT_CANCELLED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "orderscancelled", label: "OrdersCancelled" });
      }
    },
    ADD_NEW_ORDER_REPORT_FULLY_EXECUTED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ordersexecuted", label: "OrdersExecuted" });
      }
    },
    ADD_NEW_ORDER_REPORT_EXPIRED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ordersexpired", label: "OrdersExpired" });
      }
    },
    ADD_NEW_ORDER_REPORT_FREEZED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ordersfreezed", label: "OrdersFreezed" });
      }
    },
    ADD_NEW_ORDER_REPORT_INPROCESS: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ordersinprocess", label: "OrdersInProcess" });
      }
    },
    ADD_NEW_ORDER_REPORT_PARTIALLY_EXECUTED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "orderspartiallyexecuted", label: "OrdersPartiallyExecuted" });
      }
    },
    ADD_NEW_ORDER_REPORT_PENDING: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "orderspending", label: "OrdersPending" });
      }
    },
    ADD_NEW_ORDER_REPORT_REJECTED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ordersrejected", label: "OrdersRejected" });
      }
    },
    ADD_NEW_ORDER_REPORT_TRIGGERED: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "orderstriggered", label: "OrdersTriggered" });
      }
    },
    ADD_NEW_ORDER_REPORT_AMO: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ordersamo", label: "OrdersAMO" });
      }
    },
    ADD_NEW_MARGIN_REPORT: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "ulmarginreport", label: "Margin Report" });
      }
    },
    ADD_NEW_TRADE_REPORT: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "tradereport", label: "Trade Report" });
      }
    },
    ADD_NEW_POSITION_REPORT: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "positionreport", label: "Position Report" });
      }
    },
    ADD_NEW_HOLDING_REPORT: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "dpsrreport", label: "Holdings Report" });
      }
    },
    ADD_NEW_PREVIOUS_SETTLEMENT_REPORT: (event) => {
      const { layout: { layoutType } } = store.getState();
      if (layoutType !== 'nextadmin') return;
      event.preventDefault();
      if (checkOpenPopup()) {
        AddGridActions.setNewGrid(MODAL_CHILD.NEW_GRID, { name: "uspositionreport", label: "Previous Settlement Report" });
      }
    },
  };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>;
}

export default HotKeys;
