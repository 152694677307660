import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import { Grid, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import Placeholder from "components/Placeholder";
import React from "react";
import { connect } from "react-redux";
import styles from "./styles";

export function formatDate(date) {
  if (date) {
    let newDate = (date).toString()
    let dateArray = newDate.split("-")
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]
  }
  return "--"
}

const InfoText = withStyles(styles)((props) => {
  const { text, value, classes } = props
  return (
    <div className={classes.infoTextDiv}>
      <Typography variant="h6" className={classes.secondaryText}>{text}</Typography>
      <Typography variant="h6" className={classes.primaryText}>{value}</Typography>
    </div>
  )
})

function getDoubledMoney(value) {
  if (value) {
    let newValue = value.toString()
    newValue = newValue.split(".")
    if (newValue.length === 1) {
      return newValue[0] + "yrs"
    } else if (newValue.length === 2) {
      let month = String(newValue[1]).slice(0, 1)
      if (newValue[0] == "0") {
        return month + "m"
      } else if (month == "0") {
        return newValue[0] + "yrs"
      } else {
        return newValue[0] + "yrs" + " " + month + "m"
      }
    }
  }
  return "--"
}

const getLockInPeriod = (period) => {
  if (period && Number(period) > 0) {
    if (Number(period) === 1) {
      return period + " yr"
    } else {
      return period + " yrs"
    }
  }
  return "--"
}

const WeeklyInfo = withStyles(styles)((props) => {
  const { text, value, date, type, classes } = props
  return (
    <div className={classes.weeklyInfo}>
      <Typography variant="h6" style={{ paddingBottom: "6px" }} className={classes.secondaryText}>{text}</Typography>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className={type === "high" ? classes.greenText : classes.redText}>
        <Typography variant="h6" className={type === "high" ? classes.greenText : classes.redText}>{`₹ ${Number(value).toFixed(2)}` || "--"}</Typography>
        {type === "high" ? <ArrowUpwardIcon style={{ marginLeft: "4px" }} /> : <ArrowDownwardIcon className={classes.arrow} />}
      </div>
      <Typography variant="caption" className={type === "high" ? classes.greenText : classes.redText}>({(date && formatDate(date)) || "--"})</Typography>
    </div>
  )
})

function Overview(props) {
  const { classes, schemeFundDetails, propSchemeFundDetails } = props

  let fundDetails = propSchemeFundDetails || schemeFundDetails

  const getReturnsData = () => {
    return (
      <div className={classes.returnsDiv}>
        <div className={classes.returnsValueDiv} style={{ borderLeft: 0, textAlign: "left", paddingLeft: "0px" }}>
          <Typography variant="h6" className={classes.primaryText} style={{ fontWeight: 600, paddingBottom: "4px" }}>Durations</Typography>
          <Typography variant="h6" className={classes.primaryText} style={{ fontWeight: 600, paddingBottom: "4px" }}>Annualised Returns</Typography>
          <Typography variant="h6" className={classes.primaryText} style={{ fontWeight: 600 }}>Absolute Returns</Typography>
        </div>
        <div className={classes.returnsValueDiv}>
          <Typography variant="h6" className={classes.secondaryText} style={{ fontWeight: 600, paddingBottom: "4px" }}>1 Yr</Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600, paddingBottom: "4px" }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].oneYearRet && Number(fundDetails.overview.overviewBottom[0].oneYearRet).toFixed(2)) || "--"}
          </Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600 }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].oneYearCAGRRet && Number(fundDetails.overview.overviewBottom[0].oneYearCAGRRet).toFixed(2)) || "--"}
          </Typography>
        </div>
        <div className={classes.returnsValueDiv}>
          <Typography variant="h6" className={classes.secondaryText} style={{ fontWeight: 600, paddingBottom: "4px" }}>3 Yr</Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600, paddingBottom: "4px" }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].threeYeaRet && Number(fundDetails.overview.overviewBottom[0].threeYeaRet).toFixed(2)) || "--"}
          </Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600 }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].threeYearCAGRRet && Number(fundDetails.overview.overviewBottom[0].threeYearCAGRRet).toFixed(2)) || "--"}
          </Typography>
        </div>
        <div className={classes.returnsValueDiv}>
          <Typography variant="h6" className={classes.secondaryText} style={{ fontWeight: 600, paddingBottom: "4px" }}>5 Yr</Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600, paddingBottom: "4px" }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].fiveYearRet && Number(fundDetails.overview.overviewBottom[0].fiveYearRet).toFixed(2)) || "--"}
          </Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600 }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].fiveYearCAGRRet && Number(fundDetails.overview.overviewBottom[0].fiveYearCAGRRet).toFixed(2)) || "--"}
          </Typography>
        </div>
        <div className={classes.returnsValueDiv}>
          <Typography variant="h6" className={classes.secondaryText} style={{ fontWeight: 600, paddingBottom: "4px" }}>Since Inception</Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600, paddingBottom: "4px" }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].returnsSinceInception && Number(fundDetails.overview.overviewBottom[0].returnsSinceInception).toFixed(2)) || "--"}
          </Typography>
          <Typography variant="h6" className={classes.greenText} style={{ fontWeight: 600 }}>
            {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
              && fundDetails.overview.overviewBottom[0].cagrReturnsSinceInception && Number(fundDetails.overview.overviewBottom[0].cagrReturnsSinceInception).toFixed(2)) || "--"}
          </Typography>
        </div>
      </div>
    )
  }

  const rankGrid = () => {
    return (
      <Grid container className={classes.rankGrid}>
        <Grid item xl={3} lg={3} md={4} sm={6}>
          <div className={classes.rankValueDiv}>
            <Typography variant="h4" className={classes.primaryText} style={{ fontWeight: 600 }}>
              {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].fiveYearAbsRank) || "--"}
            </Typography>
            <Typography variant="h4" className={classes.rankText}>
              Rank (of {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].fiveYearCatRank) || "--"})
              </Typography>
            <Typography variant="caption" className={classes.secondaryText}>
              (As per 5 yrs data)
            </Typography>
          </div>
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={6}>
          <div className={classes.rankValueDiv} style={{ borderRight: 0 }}>
            <Typography variant="h4" className={classes.primaryText} style={{ fontWeight: 600 }}>
              {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].returnsSinceInception && getDoubledMoney(72 / fundDetails.overview.overviewBottom[0].returnsSinceInception)) || "--"}
            </Typography>
            <Typography variant="h4" className={classes.doubledMoneyText}>Doubled Money</Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      {
        typeof (fundDetails) === "string" ?
          <div style={{ width: "100%", height: "100%" }}>
            <Placeholder
              loading={fundDetails === "Loading..." ? true : false}
              loadingMsg={""}
              error={fundDetails === "Loading..." ? "" : fundDetails}
            />
          </div>
          :
          <div className={classes.rootDiv}>
            <div className={classes.grid}>
              <Grid className={classes.mainGrid}>
                <Typography variant="h4" className={classes.primaryText} style={{ fontWeight: 600, marginBottom: "6px" }}>Investment Objective</Typography>
                <Typography variant="h6" className={classes.secondaryText}>
                  {(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].investmentObjective) || ""}
                </Typography>
                <Grid container className={classes.valueContainer}>
                  <Grid item lg={3} md={6} sm={6} className={classes.firstGrid}>
                    <InfoText
                      text={"Min SIP Amount"}
                      value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
                        && fundDetails.overview.overviewBottom[0].minSIPAmt && `₹ ${Number(fundDetails.overview.overviewBottom[0].minSIPAmt).toFixed(2)}`) || "--"}
                    />
                    <InfoText
                      text={"Expense Ratio"}
                      value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
                        && fundDetails.overview.overviewBottom[0].expenseRatio && Number(fundDetails.overview.overviewBottom[0].expenseRatio) !== NaN &&
                        Number(fundDetails.overview.overviewBottom[0].expenseRatio).toFixed(2)) || "--"}
                    />
                    <InfoText
                      text={"Min Lumpsum Amount"}
                      value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
                        && fundDetails.overview.overviewBottom[0].minInvAmt && `₹ ${Number(fundDetails.overview.overviewBottom[0].minInvAmt).toFixed(2)}`) || "--"}
                    />
                    <InfoText
                      text={"Lock in Period"}
                      value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0]
                        && fundDetails.overview.overviewBottom[0].lockInPeriod && getLockInPeriod(fundDetails.overview.overviewBottom[0].lockInPeriod)) || "--"}
                    />
                    <InfoText
                      text={"Risk"}
                      value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].risk) || "--"}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} className={classes.secondGrid}>
                    <InfoText
                      text={"Launch Date"}
                      value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && formatDate(fundDetails.overview.overviewBottom[0].inceptionDate)) || "--"}
                      noBorder />
                    <div className={classes.benchMarkDiv}>
                      <Typography variant="h6" className={classes.secondaryText} style={{ marginRight: "10px" }}>{"BenchMark Name"}</Typography>
                      <Typography variant="h6" className={classes.primaryText}>{(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].benckMarkName) || "--"}</Typography>
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <WeeklyInfo
                          text={"52 Week High"}
                          value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].nav52wkHigh) || 0}
                          type={"high"}
                          date={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].nav52wkHighDate) || "--"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <WeeklyInfo
                          text={"52 Week Low"}
                          value={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].nav52wkLow) || 0}
                          type={"low"}
                          date={(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].nav52wkLowDate) || "--"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} className={classes.thirdGrid}>
                    {getReturnsData()}
                    <div className={classes.fundManagerDiv}>
                      <Typography variant="h6" className={classes.secondaryText}>Fund Manager : </Typography>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography component="div" display="block">
                          <Typography component="div" display="inline" variant="h4" className={classes.primaryText}>
                            <span>{(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].fundManager) || "--"}</span>
                          </Typography>
                          <Typography component="div" display="inline" variant="caption" className={classes.secondText}><span style={{ marginLeft: 6 }}>
                            ({(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && formatDate(fundDetails.overview.overviewBottom[0].fundManagerDate)) || "--"})
                            </span></Typography>
                        </Typography>
                        {/* <Button className={classes.button} variant="contained" color="primary">Read More</Button> */}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ display: "flex" }}>
                  <Typography variant="h5" className={classes.primaryText} style={{ fontWeight: 600, marginRight: "8px" }}>Exit Load :</Typography>
                  <Typography variant="h6" className={classes.secondaryText}>{(fundDetails && fundDetails.overview && fundDetails.overview.overviewBottom && fundDetails.overview.overviewBottom[0] && fundDetails.overview.overviewBottom[0].exitLoad) || "--"}</Typography>
                </div>
                {rankGrid()}
              </Grid>
            </div>
          </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { common: { fundDetails } } = state;
  return { schemeFundDetails: fundDetails };
}

export default connect(mapStateToProps)(withStyles(styles)(Overview))